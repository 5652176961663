import { useState, useCallback, useEffect, useReducer, useRef } from 'react';

export type SetTrueCallback = () => void;
export type SetFalseCallback = () => void;
export type ToggleCallback = () => void;

export type LoadCallback = <TResult>(promise: Promise<TResult>) => Promise<TResult>;

export const useToggle = (
  initialValue: boolean = false
): [boolean, SetTrueCallback, SetFalseCallback, ToggleCallback] => {
  const [value, setValue] = useState<boolean>(initialValue);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue(!value), [value]);
  return [value, setTrue, setFalse, toggle];
};

function usePagination(maxPage: number, itemsPerPage: number = 25) {
  const [currentPage, setCurrentPage] = useState(1);

  function next() {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentPage, maxPage };
}

export function useDelayMount(isMounted: boolean, delayTime: number) {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isMounted && !shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(true), delayTime);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
}
export const useLoading = (startingValue: boolean = false): { isLoading: boolean; load: LoadCallback } => {
  const [isLoading, setLoading] = useState(startingValue);

  const load: LoadCallback = useCallback(promise => {
    setLoading(true);
    return promise.finally(() => setLoading(false));
  }, []);

  return { isLoading, load };
};

type RecaptchaState = {
  gRecaptchaResponse?: any;
  gRecaptchaError?: boolean;
};

type RecaptchaAction = { type: 'SET_RESPONSE'; payload: any } | { type: 'SET_ERROR'; payload: boolean };

function recaptchaReducer(state: RecaptchaState, action: RecaptchaAction) {
  switch (action.type) {
    case 'SET_RESPONSE':
      return { ...state, gRecaptchaResponse: action.payload };
    case 'SET_ERROR':
      return { ...state, gRecaptchaError: action.payload };
    default:
      throw new Error('Error in recaptcha reducer');
  }
}

export function useCaptcha() {
  const [captchaState, dispatch] = useReducer(recaptchaReducer, { gRecaptchaResponse: '', gRecaptchaError: false });
  const recaptchaInstance = useRef<any>();

  const verifyCallback = async (response: any) => {
    await dispatch({ type: 'SET_RESPONSE', payload: response });
    await dispatch({ type: 'SET_ERROR', payload: false });
  };

  const executeCaptcha = async () => {
    if (recaptchaInstance.current) {
      recaptchaInstance.current.reset();
      let token = recaptchaInstance.current.getValue();
      if (!token) {
        token = await recaptchaInstance.current.executeAsync();
      }
      return token;
    }
  };

  const resetCaptcha = async () => {
    if (Boolean(recaptchaInstance.current)) {
      recaptchaInstance.current.reset();
      dispatch({ type: 'SET_RESPONSE', payload: null });
    }
  };

  const onloadCallback = () => {
    setTimeout(executeCaptcha, 0);
  };

  return { recaptchaInstance, executeCaptcha, resetCaptcha, verifyCallback, onloadCallback, captchaState };
}

export default usePagination;
