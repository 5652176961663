import React from 'react';
import { t } from '../../../../localization';
import cn from 'classnames';

import classes from './AiWriter.module.scss';

export const AiWriterPlaceholder = () => {
  return (
    <div className={classes.AiWriter__empty}>
      <ol>
        <li className={cn(classes['AiWriter__empty-text'], 'tp-3')}>{t('AiWriter:Response:Placeholder:Step1')}</li>
        <li className={cn(classes['AiWriter__empty-text'], 'tp-3')}>{t('AiWriter:Response:Placeholder:Step2')}</li>
      </ol>
      <div className={classes.AiWriter__placeholder}>
        <div className={classes['AiWriter__placeholder-top']}>
          <div className={classes['AiWriter__placeholder-small']}></div>
          <div className={classes['AiWriter__placeholder-selected']}></div>
        </div>
        <div className={classes['AiWriter__placeholder-textarea']}></div>
      </div>
      <div className={classes.AiWriter__placeholder}>
        <div className={classes['AiWriter__placeholder-top']}>
          <div className={classes['AiWriter__placeholder-small']}></div>
          <div className={classes['AiWriter__placeholder-selected']}></div>
        </div>
        <div className={classes['AiWriter__placeholder-textarea']}></div>
      </div>
    </div>
  );
};
