import React from 'react';
import { getDaysInMonth, intervalToDuration } from 'date-fns';
import { groupBy } from 'lodash';

import './ReplicaHistory.scss';
import { useProjectContext } from '../../../context/ProjectsContext';
import useDefaultAlert from '../../../../../utils/useAlert';
import { ReplicasHistoryView } from '../../../../../api/dubber/client';
import { UnsavedHistoryItem } from './HistoryItem/UnsavedHistoryItem';
import { ReplicaHistoryItem } from './HistoryItem/ReplicaHistoryItem';
import moment from 'moment';
import { useAppContext } from '../../../../../AppContext';
import { t } from '../../../../../localization';
import { capitalize, pluralize } from '../../../../../utils';
import { useCallback } from 'react';
import cn from 'classnames';
import { useReplicas } from '../../../context/ProjectDataContex';

type HistoryContentProps = {
  history: ReplicasHistoryView;
  unsavedSynth: () => void;
  isAudioLoading: boolean;
  savedSynth: (historyId: string) => void;
  historySelect: (historyId: string) => void;
  isModal?: boolean;
  setAudioLoading: (value: boolean) => void;
  historyPlaying: string;
  getData: () => Promise<ReplicasHistoryView | undefined>;
};

const calendar = {
  lastDay: function () {
    return `[${t('yesterday')}]`;
  },
  sameDay: function () {
    return `[${t('today')}]`;
  },
  nextWeek: 'DD MMMM',
  lastWeek: 'DD MMMM',
  sameElse: 'DD MMMM',
};

export const HistoryContent = ({
  history,
  unsavedSynth,
  isAudioLoading,
  savedSynth,
  historySelect,
  isModal,
  setAudioLoading,
  historyPlaying,
  getData,
}: HistoryContentProps) => {
  const { getUserLanguage } = useAppContext();
  const { downloadHistoryAudio } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();
  const { getSelectedReplica } = useReplicas();
  const selectedReplica = getSelectedReplica();

  const sortedHistory = groupBy(isModal ? history?.history : history?.history.slice(0, 10), history => {
    return moment(moment.utc(history.createdAt)).startOf('day');
  });

  const daysToDelete = useCallback(deleteDate => {
    const intervalToDelete = intervalToDuration({ start: Date.now(), end: new Date(deleteDate) });
    const daysInMonth = getDaysInMonth(new Date());
    const daysToDelete = (intervalToDelete.months || 0) * daysInMonth + (intervalToDelete.days || 0);
    return daysToDelete;
  }, []);

  const downloadHistoryItem = useCallback(
    async (historyId: string) => {
      setAudioLoading(true);
      try {
        const audiorUrl = await downloadHistoryAudio(historyId, 'history');
        getData();
        const a = document.createElement('a');
        a.href = audiorUrl;
        a.click();
      } catch (error) {
        defaultErrorAlert(error);
      } finally {
        setAudioLoading(false);
      }
    },
    [setAudioLoading, downloadHistoryAudio, getData, defaultErrorAlert]
  );

  return (
    <>
      {history.unsaved && selectedReplica && (
        <div className='replica-history__actual'>
          <p className={cn('replica-history__item-date', { 'tp-3': isModal, 'tp-4': !isModal })}>
            {t('ProjectPage:HistorySidebar:ActualVersion')}
          </p>
          <UnsavedHistoryItem
            id={selectedReplica?.id}
            history={history?.unsaved}
            handlePlayerClick={unsavedSynth}
            isLoading={isAudioLoading}
            downloadHistoryItem={downloadHistoryItem}
            isModal={isModal}
            isActual
            historyPlaying={historyPlaying}
          />
        </div>
      )}
      <div className='replica-history__others'>
        {Array.from(Object.keys(sortedHistory)).map(group => (
          <React.Fragment key={`group-${group}`}>
            <p className={cn('replica-history__item-date', { 'tp-3': isModal, 'tp-4': !isModal })}>
              {capitalize(
                moment(group)
                  .locale(getUserLanguage() === 'ru' ? 'ru' : 'en')
                  .calendar(calendar)
              )}{' '}
              &bull;{' '}
              {t(
                'ProjectPage:History:DaysToDelete',
                pluralize(getUserLanguage(), daysToDelete(sortedHistory[group][0].expiredAt), [
                  t('oneDay'),
                  t('fewDays'),
                  t('manyDays'),
                  t('otherDays'),
                ])
              )}
            </p>
            {sortedHistory[group].map((historyItem, index) => (
              <ReplicaHistoryItem
                key={historyItem.id}
                history={historyItem}
                handlePlayerClick={savedSynth}
                isLoading={isAudioLoading}
                downloadHistoryItem={downloadHistoryItem}
                isActual={!history.unsaved && Array.from(Object.keys(sortedHistory))[0] === group && index === 0}
                selectHistory={historySelect}
                isModal={isModal}
                historyPlaying={historyPlaying}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
