import React from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { SpeakerBlockProps } from '../../../../components/VoiceLineSynthBlock/VoiceLineBlock';
import { ExtendedVoiceLine } from './VoiceLinesBlock';
import DragIcon from '../../../../img/DragIcon.svg';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_TABLET } from '../../../Header/constants';
import classNames from 'classnames';

const DRAGGABLE_STYLES = {
  backgroundColor: 'white',
  borderRadius: '15px',
  boxShadow: '0px 1px 20px rgba(44, 46, 113, 0.12)',
  cursor: 'grabbing',
};
type DraggableVoiceLineProps = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  line: ExtendedVoiceLine;
  children: React.ReactElement<SpeakerBlockProps, any>;
  selectedVoiceLine?: ExtendedVoiceLine;
};

export const DraggableVoiceLine = (props: DraggableVoiceLineProps) => {
  const { provided, snapshot, line, children } = props;
  const isTablet = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  let style = { ...provided.draggableProps.style };
  let transform = provided.draggableProps?.style?.transform;
  if (snapshot.isDragging && transform) {
    transform = transform.replace(/\(.+,/, '(0,');
    style = {
      ...style,
      transform,
      ...DRAGGABLE_STYLES,
    };
  }
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...(isTablet ? provided.dragHandleProps : {})}
      className={classNames('projects-page__draggable-line', {
        'selected-for-markup': props.selectedVoiceLine?.id === line.id,
      })}
      style={style}
    >
      {!line.id.includes('placeholder') && !isTablet && (
        <div
          {...provided.dragHandleProps}
          className='projects-page__drag-handle'
          data-test-id={`voiceLine.dragHandle-${line.id}`}
        >
          <span className='justui__icon-wrapper' style={snapshot.isDragging ? { visibility: 'visible' } : {}}>
            <img src={DragIcon} alt='Drag replica icon' />
          </span>
        </div>
      )}
      {children}
    </div>
  );
};
