import React, { useState, useEffect, useCallback } from 'react';
import { Icon } from '@just-ai/just-ui';
import { useParams, useHistory, Link, useRouteMatch } from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import moment from 'moment';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { t } from '../../../../localization';
import { useLibraryContext } from '../../context/LibraryContext';
import { GenderEnum, PublicModelView, PublishedVoiceViewWithUsedVersion } from '../../../../api/facade/client';
import { SCREEN_WIDTH_PHONE } from '../../../Header/constants';
import { PublicVoicePageCard } from './PublicVoicePageCard';
import { DynamicTitle } from '../../components/DynamicTitle/DynamicTitle';
import { languagesMap } from '../../constants';
import { useAppContext } from '../../../../AppContext';

import './PublicVoicePage.scss';

export default function PublicVoicePage() {
  const [voice, setVoice] = useState<PublishedVoiceViewWithUsedVersion & { price: string }>();
  const [releasedVersions, setReleasedVersions] = useState<PublicModelView[]>([]);
  const { voiceId } = useParams<{ voiceId: string }>();
  const { getPublicVoice, getPublicVoiceModels, getVoicePrice } = useLibraryContext();
  const { logEvent } = useAmplitude();
  const { getUserLanguage, productName } = useAppContext();
  const history = useHistory();
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const match = useRouteMatch();

  const fetchPublicVoice = useCallback(async () => {
    const data = await getPublicVoice(+voiceId);
    const price = await getVoicePrice(+voiceId);
    if (!data) {
      return history.push('/catalog');
    }
    if (data && !data.tags) data.tags = [];
    setVoice({ ...data, price });
  }, [getPublicVoice, getVoicePrice, history, voiceId]);

  const fetchReleasedVersions = useCallback(async () => {
    const data = await getPublicVoiceModels(+voiceId);
    setReleasedVersions(data || []);
  }, [getPublicVoiceModels, voiceId]);

  useEffect(() => {
    fetchPublicVoice();
    fetchReleasedVersions();
    logEvent('Page opened', {
      page_url: match.url,
      page_type: match.path,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPublicVoice, fetchReleasedVersions]);

  if (!voice) return null;
  return (
    <div className='public-voice'>
      <DynamicTitle title={t('documentTitle.publicVoice', voice.name, productName)} />
      <div className='public-voice__wrapper'>
        <PublicVoicePageCard voice={voice} fetchPublicVoice={fetchPublicVoice} />
        <div className='public-voice__column'>
          <div
            className={classnames('public-voice__connected', { open: voice.usedModel })}
            data-test-id='connectedVersion'
          >
            <Icon name='faCheck' size='sm' color='success' />
            {t('connectedVersion', voice.usedModel && voice.usedModel.version)}
          </div>

          <div className='public-voice__desc'>
            <h3>{t('description')}</h3>
            <p className={isPhone ? 'tp-4' : 'tp-3'}>{voice.description}</p>
            <div className='public-voice__atributes'>
              {voice?.tags.includes('supervoice') && (
                <div className='public-voice__supervoice'>
                  <div />
                  <p className={isPhone ? 'tp-4' : 'tp-3'}>{t('supervoice')}</p>
                </div>
              )}

              <div className='public-voice__gender tp-3'>
                <Icon name={voice?.gender === GenderEnum.MALE ? 'falMars' : 'falVenus'} size='md' />
                {voice?.gender === GenderEnum.MALE ? t('male') : t('female')}
              </div>
              <div className='public-voice__language tp-3'>
                <Icon name='falFlagAlt' size='md' />
                {t(languagesMap[voice.language])}
              </div>
            </div>
          </div>

          <div className='public-voice__actual'>
            <div>
              <h3>{t('actualVersion')}</h3>
              <div className='public-voice__actual-version'>
                <p className='tp-4'>
                  {`${t(
                    'versionFromDate',
                    t('versionNumber', voice.actualModel.version),
                    moment(voice.actualModel.trainedAt)
                      .locale(getUserLanguage() === 'ru' ? 'ru' : 'en')
                      .format('DD MMMM YYYY')
                  )}`}
                </p>

                {voice.usedModel && voice.usedModel.id === voice.actualModel.id && (
                  <div className='public-voice__actual-connected'>{t('connected')}</div>
                )}
              </div>
            </div>
            {releasedVersions.length > 1 && !voice.accessRestricted && (
              <Link to={`${voice.id}/released`} data-test-id='publicVoice.allVersions'>
                <p>{t('allVersion')}</p>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
