import React, { useCallback, useRef, useState } from 'react';
import { DropdownItem, Icon } from '@just-ai/just-ui';
import { Spinner } from '@just-ai/just-ui';
import classNames from 'classnames';

import { SynthesizableVoice } from '../../../../api/facade/client';
import { useAppContext } from '../../../../AppContext';
import { VoiceAvatar } from '../VoiceAvatar';

import './style.scss';
import { languagesMap } from '../../constants';
import { t } from '../../../../localization';

export default function SpeakerSelectModal(props: {
  handleSubmit: (id: number, modelId?: number) => void;
  speakersList: SynthesizableVoice[];
  usageType?: 'modal' | 'dropdown';
  voicesLoading?: boolean;
}) {
  const { handleSubmit, speakersList, usageType = 'modal' } = props;
  const [playingId, setPlayingId] = useState(-1);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { configData } = useAppContext();

  const handleFormSubmit = useCallback(
    (id: number, modelId?: number) => {
      handleSubmit(id, modelId);
    },
    [handleSubmit]
  );

  const handlePlayerClick = (id: number) => {
    if (!audioRef.current) return;
    if (id === playingId) {
      audioRef.current.paused ? audioRef.current.play() : audioRef.current.pause();
      return setPlayingId(-1);
    }
    audioRef.current.src = `${configData.basePublicVoicePath}/${id}/sample/audio.wav`;
    setPlayingId(id);
    audioRef.current.play();
  };

  if (usageType === 'dropdown')
    return (
      <div className='dialog-synth__speaker-modal__speaker-list'>
        <audio ref={audioRef} controls={false} preload='metadata' onEnded={() => setPlayingId(-1)} />
        {speakersList.map(speaker => (
          <DropdownItem
            className='dialog-synth__speaker-block dialog-synth__player'
            onClick={() => handleFormSubmit(speaker.id, speaker.modelId)}
            key={speaker.id}
          >
            <div className='speaker-avatar add-speaker-avatar'>
              <VoiceAvatar voiceId={speaker.id} size='small' />
            </div>
            <p className='tp-4'>{speaker.name}</p>
            <p className='tp-4 ml-4 speaker-language'>{t(languagesMap[speaker.language])}</p>
            <div
              className={classNames('play-button justui_icon-button btn btn-primary btn-square', {
                playing: playingId === speaker.id,
              })}
              onClick={e => {
                e.stopPropagation();
                handlePlayerClick(speaker.id);
              }}
            >
              <Icon name={playingId === speaker.id ? 'faPause' : 'faPlay'} color='primary' />
            </div>
          </DropdownItem>
        ))}
        {props.voicesLoading && <Spinner style={{ position: 'static' }} size='4x' color='secondary' />}
      </div>
    );

  return (
    <div className='dialog-synth__speaker-modal__speaker-list'>
      <audio ref={audioRef} controls={false} preload='metadata' onEnded={() => setPlayingId(-1)} />
      {speakersList.map(speaker => (
        <div
          className='dialog-synth__speaker-block dialog-synth__player'
          onClick={() => handleFormSubmit(speaker.id, speaker.modelId)}
          key={speaker.id}
          data-test-id='landing.dialog-speaker-block'
        >
          <div className='speaker-avatar add-speaker-avatar' data-test-id='landing.dialog-speaker-avatar'>
            <VoiceAvatar voiceId={speaker.id} size='small' />
          </div>

          <p className='tp-4'>{speaker.name}</p>
          <p className='tp-4 ml-4 speaker-language'>{t(languagesMap[speaker.language])}</p>
          <div
            className={classNames('play-button justui_icon-button btn btn-primary btn-square', {
              playing: playingId === speaker.id,
            })}
            onClick={e => {
              e.stopPropagation();
              handlePlayerClick(speaker.id);
            }}
            data-test-id='landign.dialog-speaker-synth-button'
          >
            <Icon name={playingId === speaker.id ? 'faPause' : 'faPlay'} color='primary' />
          </div>
        </div>
      ))}
    </div>
  );
}
