import React from 'react';
import { ModalBody } from 'reactstrap';
import { t } from '../../../../localization';
import CustomModal from './CustomModal';

type StartTrainingProps = {
  handleModalAction: () => void;
  cancelButtonClick: () => void;
};
export const CustomModalStartTraining = ({ handleModalAction, cancelButtonClick }: StartTrainingProps) => {
  return (
    <>
      <ModalBody className='custom-modal__body'>{t('startTrainingModal')}</ModalBody>
      <CustomModal.Footer actionButtonTitle={t('start')} actionButtonClick={handleModalAction} />
    </>
  );
};
