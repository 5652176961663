import React, { useCallback } from 'react';
import { useAppContext } from '../AppContext';
import { confirmEmailPath } from '../modules/Library/constants';
import {
  getErrorCodeFromReason,
  getErrorMessageFromReason,
  NO_FUNDS_ERROR_CODE,
  UNKNOWN_ERROR_MESSAGE_CODE,
} from './error';
import { useAccountContext } from '../modules/Header/context/AccountContext';

export type DefaultAlertReturnType = {
  defaultErrorAlert: (error: unknown) => void;
  successAlert: (message: string | React.ReactElement) => void;
};

export default function useDefaultAlert(): DefaultAlertReturnType {
  const { addAlert } = useAppContext();
  const { setNoFundsModal } = useAccountContext();

  const defaultErrorAlert = useCallback(
    (error: unknown | React.ReactElement) => {
      const errorCode = getErrorCodeFromReason(error);
      const alertMessage = getErrorMessageFromReason(error);
      let message = typeof error === 'string' ? error : alertMessage || error;

      if (errorCode === 'email.verification.not.verified') {
        window.location.href = confirmEmailPath;
      }
      if (
        errorCode === NO_FUNDS_ERROR_CODE ||
        (errorCode === UNKNOWN_ERROR_MESSAGE_CODE && alertMessage === NO_FUNDS_ERROR_CODE)
      ) {
        setNoFundsModal(true);
        return;
      }
      addAlert({
        type: 'error',
        message: message,
        time: Date.now(),
        showed: true,
        temporal: true,
      });
    },
    [addAlert, setNoFundsModal]
  );
  const successAlert = (message: string | React.ReactElement) => {
    addAlert({
      type: 'success',
      message: message,
      time: Date.now(),
      showed: true,
      temporal: true,
    });
  };
  return { defaultErrorAlert, successAlert };
}
