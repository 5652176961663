import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Icon, Button, InputText } from '@just-ai/just-ui';
import classNames from 'classnames';

import { t } from '../../../../../localization';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE } from '../../../../Header/constants';
import { useAppContext } from '../../../../../AppContext';
import { minVoicePrices } from '../../../../../utils';
import { SynthBlock } from '../../SynthArea/SynthBlock';

type BannerOpenProps = {
  modalToggle: () => void;
  handleSynth: (text: string) => Promise<any>;
  isPublished: boolean;
  handlePublishVoice: () => void;
  handlePublicationUpdate: () => void;
  loading: boolean;
  publishDisabled: boolean;
  textWatcher?: (text: string) => void;
  voiceTextPreview?: string;
  handlePriceChange: (value: string) => void;
  price: string | undefined;
  recaptchaInstance: React.MutableRefObject<any>;
};

export default function BannerOpen({
  modalToggle,
  handleSynth,
  isPublished,
  handlePublishVoice,
  handlePublicationUpdate,
  publishDisabled,
  loading,
  textWatcher,
  voiceTextPreview,
  handlePriceChange,
  price,
  recaptchaInstance,
}: BannerOpenProps) {
  const { id: voiceId } = useParams<{ id: string }>();
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const { isUSDInstance } = useAppContext();

  const minimumCost = isUSDInstance ? minVoicePrices.USD : minVoicePrices.RUB;

  return (
    <>
      <div className='add-to-catalog__header'>
        <h4 className={isPhone ? 'st-1' : ''}>{t('catalogPlacement')}</h4>
        {isPublished && (
          <Link to={`/catalog/${voiceId}`} data-test-id='controlPage.goToPublication'>
            <p className={isPhone ? 'tp-4' : 'tp-3'}>{t('publicPage')}</p>
            <Icon name='faArrowUp' />
          </Link>
        )}
      </div>
      {isPublished && (
        <div className='add-to-catalog__publication_info'>
          <div className='add-to-catalog__remove'>
            <p className={isPhone ? 'tp-3' : 'tp-4'}>{t('voiceAvailabilities')}</p>
            <Button outline color='secondary' onClick={modalToggle} data-test-id='controlPage.removePublication'>
              {t('hideFromCatalog')}
            </Button>
          </div>
        </div>
      )}
      <div className='add-to-catalog__price'>
        {isPhone ? <p className='tp-4'>{t('voicePricePerMinute')}</p> : <p className='tp-3'>{t('voicePrice')}</p>}
        <div className='add-to-catalog__price-input'>
          <InputText
            type='number'
            placeholder={t('minVoicePricePlaceholder', minimumCost)}
            value={price}
            onChange={handlePriceChange}
            data-test-id='controlPage.priceInput'
          />
          <small
            className={classNames('add-to-caalog__price-input_small tp-5', { warning: price && +price < minimumCost })}
          >
            {price && +price < minimumCost ? (
              <div>
                <Icon name='faExclamationCircle' color='danger' size='sm' />
                {t('minVoicePrice', isUSDInstance ? `${minVoicePrices.USD}$` : `${minVoicePrices.RUB}₽`)}
              </div>
            ) : (
              t('usuallyVoicePrice', isUSDInstance ? '0.01 to 0.05$' : '0.7 to 2.9 ₽')
            )}
          </small>
        </div>
        {!isPhone && <p className='tp-3'>{isUSDInstance ? t('dollarsPerMinute') : t('rublesPerMinute')}</p>}
      </div>
      <div className={isPhone ? 'add-to-catalog__warning tp-5' : 'add-to-catalog__warning tp-4'}>
        {t('priceWarning', isUSDInstance ? `${minVoicePrices.USD}$` : `${minVoicePrices.RUB}₽`)}
      </div>
      <div className='add-to-catalog__synth-area'>
        <p className={isPhone ? 'mb-2 tp-4' : 'mb-2 tp-3'}>{t('publishSynthHeader')}</p>
        <p className={isPhone ? 'text-muted mb-3 tp-5' : 'text-muted tp-4'}>{t('publishSynthDetails')}</p>
        <SynthBlock
          handler={handleSynth}
          textChangeWatcher={textWatcher}
          initialText={voiceTextPreview}
          recaptchaInstance={recaptchaInstance}
        />
      </div>
      <div className='add-to-catalog__btn-block'>
        <Button
          onClick={isPublished ? handlePublicationUpdate : handlePublishVoice}
          color='primary'
          disabled={publishDisabled || loading}
          data-test-id='controlPage.publishBtn'
        >
          {t(isPublished ? 'saveChanges' : 'publishVoice: Button')}
        </Button>
      </div>
    </>
  );
}
