import React, { useEffect, useState } from 'react';
import { Icon, IconButton, Tooltip, useToggle } from '@just-ai/just-ui';
import { RouteProps, useRouteMatch } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
import { useAmplitude } from 'react-amplitude-hooks';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { useAppContext } from '../../../../AppContext';
import { SCREEN_WIDTH_TABLET } from '../../constants';
import { t } from '../../../../localization';
import { useAccountContext } from '../../context/AccountContext';
import { PackagePrice, PaymentSystemEnum } from '../../../../api/supervisor/client';
import useAlert from '../../../../utils/useAlert';
import withDocumentData from '../../../../utils/withDocumentData';
import { normalizeCurrencyByCode } from '../../../../utils';
import { CustomModal } from '../../../Library/components/CustomModal';
import { RequestModal } from '../../../Library/components/CustomModal/RequestModal';

import './style.scss';

type AccountPageProps = RouteProps;

function AccountPage(props: AccountPageProps) {
  const { name, logoutHandler, id, email, configData, isUSDInstance } = useAppContext();
  const { wallet, getPackages, buyPackage } = useAccountContext();
  const { defaultErrorAlert } = useAlert();
  const [packages, setPackages] = useState<PackagePrice[]>([]);
  const [requestModal, , , toggleRequestModal] = useToggle();
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const data = await getPackages();
        return setPackages(data);
      } catch (error) {
        defaultErrorAlert(error);
      }
    };
    fetchPackages();
  }, [defaultErrorAlert, getPackages]);

  useEffect(() => {
    logEvent('Page opened', {
      page_type: match.path,
      page_url: match.url,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buyPackageRequest = async (name: string) => {
    try {
      const paymentInfo = await buyPackage(name);
      if (paymentInfo.paymentSystem === PaymentSystemEnum.YOOMONEY && paymentInfo.yoomoneyRedirectUrl) {
        return window.open(new URL(paymentInfo.yoomoneyRedirectUrl));
      }
      if (
        paymentInfo.paymentSystem === PaymentSystemEnum.STRIPE &&
        paymentInfo.stripePublicKey &&
        paymentInfo.stripeSessionId
      ) {
        let stripe = loadStripe(paymentInfo.stripePublicKey);
        stripe.then(payload => {
          payload && payload.redirectToCheckout({ sessionId: paymentInfo.stripeSessionId || '' });
        });
      }
      logEvent('Buy package', {
        userId: id,
        packageName: name,
        result: 'success',
      });
    } catch (error) {
      logEvent('Buy package', {
        userId: id,
        packageName: name,
        result: 'failed',
      });
      defaultErrorAlert(error);
    }
  };

  const tabletDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  if (!wallet) return null;

  return (
    <div className='central-column'>
      <div className='account-page__container'>
        <div className={cn('account-page__content', { usd: isUSDInstance, rouble: !isUSDInstance })}>
          <div className='account-page__header'>
            <div className='account-page__header-wrapper'>
              <div className='account-page__header-text'>
                <p className={classnames('grey-text-1', tabletDown ? 'tp-4' : 'tp-2')}>{t('account')}</p>
                <p className={tabletDown ? 'tp-4' : 'tp-2'}>{name}</p>
                <p className={tabletDown ? 'tp-5' : 'tp-3'}>{`ID: ${id}`}</p>
              </div>
            </div>
            <div className='account-page__header-bg-img' />
            <IconButton
              name='falSignOut'
              id='logoutButton'
              size={tabletDown ? 'md' : 'lg'}
              className='account-page__logout-btn grey-text-2'
              onClick={async () => {
                await logoutHandler();
              }}
            />
            <Tooltip placement='top' target='logoutButton'>
              {t('logout')}
            </Tooltip>
          </div>
          <div className='account-page__info'>
            <div className='account-page__info__balance'>
              <p className={tabletDown ? 'tp-4' : 'tp-2'}>
                {t('myBalance')} / <Link to='/account/history'>{t('ProjectsSidebar:Tab:replicaHistory')}</Link>
              </p>
              {tabletDown ? (
                <h2>{normalizeCurrencyByCode(configData.currency, Number(Number(wallet.balance).toFixed(2)))}</h2>
              ) : (
                <h1>{normalizeCurrencyByCode(configData.currency, Number(Number(wallet.balance).toFixed(2)))}</h1>
              )}
            </div>
            <Icon name='faWallet' size='2x' color='secondary' wrapperClassName='wallet-icon' />
          </div>
        </div>
        <div className='account-page__plans'>
          <p className='tp-2'>{t('buyPackage')}:</p>
          <div className='account-page__packages-block'>
            {packages.map(plan => (
              <div
                onClick={() => buyPackageRequest(plan.name)}
                key={plan.name}
                className='package-block'
                data-test-id='accountPage.package'
              >
                <p className='tp-1'>{normalizeCurrencyByCode(configData.currency, plan.price)}</p>
                <Icon name='falArrowRight' />
              </div>
            ))}
          </div>
        </div>
        <div className='request-block__wrapper'>
          <div className='request-block-placeholder' />
          <div className='request-server-block' onClick={toggleRequestModal}>
            <h4>{t('serverRent')}</h4>
            <p className='tp-3'>{t('serverRentInfo')}</p>
            <p className='tp-4'>{t('requestAccess')}</p>
          </div>
        </div>
      </div>
      <CustomModal
        customClassName='success-request-info'
        isOpen={requestModal}
        toggle={toggleRequestModal}
        position='center'
        modalCard
      >
        <RequestModal userId={String(id)} emailPlaceholder={email} handleModalAction={toggleRequestModal} />
      </CustomModal>
    </div>
  );
}

export default withDocumentData(AccountPage, 'account');
