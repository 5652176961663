import React, { useCallback, useEffect } from 'react';
import { Link, useLocation, matchPath, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { Icon, Tooltip, useToggle } from '@just-ai/just-ui';
import { useAmplitude } from 'react-amplitude-hooks';

import Logo from './components/Logo';
import HeaderTabs from './components/Tabs';
import UserMenu from './components/UserMenu';
import { ProjectHeader } from './components/ProjectHeader';
import { useAppContext } from '../../AppContext';
import { SCREEN_WIDTH_MD, SCREEN_WIDTH_PHONE, SCREEN_WIDTH_TABLET } from './constants';
import { t } from '../../localization';
import OfflineModeBlock from './components/OfflineModeIndicator';
import { useProjectContext } from '../Projects/context/ProjectsContext';
import { useReplicas } from '../Projects/context/ProjectDataContex';
import useDefaultAlert from '../../utils/useAlert';
import { getCCLinkWithNavigate } from '../../utils';

import './style.scss';

export default function Header() {
  const { isLoggedIn, isUSDInstance, configData } = useAppContext();
  const { isDirty } = useReplicas();
  const location = useLocation();
  const { defaultErrorAlert } = useDefaultAlert();
  const { createProject, offlineMode, loading, getProjects } = useProjectContext();
  const [isLoading, setLoadingTrue, setLoadingFalse] = useToggle(false);

  const history = useHistory();
  const loginStatus = isLoggedIn;
  const mdScreenDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_MD}px)` });
  const tabletDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  const phoneOnly = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const { logEvent } = useAmplitude();

  const darkTheme = location.pathname === '/';

  const isProjectPage = matchPath(location.pathname, { path: '/my/project/:id' });

  const fetchProjectCount = useCallback(async () => {
    try {
      const { projects, count } = await getProjects(0, 1);
      return {
        projects,
        count,
      };
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [defaultErrorAlert, getProjects]);

  const afterLoginNavigate = useCallback(async () => {
    if (isLoading) return;
    setLoadingTrue();
    const projects = await fetchProjectCount();

    switch (projects?.count) {
      case 0: {
        try {
          const createdProject = await createProject(
            projects.count > 0 ? `${t('untitled')} (${projects.count})` : t('untitled')
          );
          logEvent('project created', {
            project_id: createdProject.id,
            result: 'success',
          });
          history.push(`/my/project/${createdProject.id}`);
        } catch (error) {
          defaultErrorAlert(error);
          logEvent('project created', {
            result: 'failed',
          });
        }
        break;
      }
      case 1: {
        history.push(`/my/project/${projects.projects[0].id}`);
        break;
      }
      default: {
        history.push('/my');
      }
    }
    setLoadingFalse();
  }, [
    createProject,
    defaultErrorAlert,
    fetchProjectCount,
    history,
    isLoading,
    logEvent,
    setLoadingFalse,
    setLoadingTrue,
  ]);

  const handleMyProjectsClick = useCallback(() => {
    if (!isLoggedIn) {
      window.location.href = getCCLinkWithNavigate(configData.authPath, history.location.pathname);
    } else {
      afterLoginNavigate();
    }
  }, [afterLoginNavigate, configData.authPath, history.location.pathname, isLoggedIn]);

  useEffect(() => {
    if (new URLSearchParams(history.location.search).has('navigate')) {
      window.history.replaceState({}, document.title, window.location.href.slice(0, window.location.href.indexOf('?')));
      afterLoginNavigate();
    }
  }, [afterLoginNavigate, history.location]);

  return (
    <header
      className={classNames('header-navbar', {
        dark: darkTheme,
        project: isProjectPage,
        'offline-header': offlineMode && phoneOnly,
      })}
    >
      <div>
        {isProjectPage && tabletDown && !phoneOnly && (
          <a
            href={isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}
            target='_blank'
            rel='noreferrer'
            className='project__header-help'
          >
            <Icon name='falQuestionCircle' size='md' />
          </a>
        )}
        {isProjectPage && tabletDown && <OfflineModeBlock loading={loading} isDirty={isDirty} offline={offlineMode} />}
        {isProjectPage ? (
          <Link to='/my' className='project__header-back' data-test-id='projectPage.backToMyPage'>
            <Icon name='falArrowLeft' /> {!mdScreenDown && <p className='tp-3'>{t('myVoices')}</p>}
          </Link>
        ) : (
          <Link to={loginStatus ? '/catalog' : '/'} data-test-id='catalogLink'>
            <Logo dark={darkTheme} />
          </Link>
        )}
        {isProjectPage && <ProjectHeader />}

        <HeaderTabs
          darkTheme={darkTheme}
          isLoggedIn={loginStatus}
          isProjectPage={Boolean(isProjectPage)}
          onClick={handleMyProjectsClick}
        />
        <UserMenu isLoggedIn={loginStatus} />
      </div>
      {isProjectPage && phoneOnly && offlineMode && (
        <div id='offlineModeHeader' className='offline-block--mobile'>
          <p className='tp-3 grey-text-1'>Offline</p>
          <Tooltip placement='bottom' target='offlineModeHeader' className='offline-mode-tooltip' fade={false}>
            {isDirty && <p className='st-3 mb-2'>{t('projectDirty')}</p>}
            {t('offlineModeInfo')}
          </Tooltip>
        </div>
      )}
    </header>
  );
}
