import React from "react";
export default function createHistogram(id) {
    return (React.createElement("svg", { width: "0", height: "0", style: { position: 'absolute' }, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("defs", null,
            React.createElement("clipPath", { id: id },
                React.createElement("rect", { x: "38.6182", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "263.618", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "133.297", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "358.297", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "101.893", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "326.893", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "35.1074", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "260.107", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "129.786", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "354.786", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "98.3826", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "323.383", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "42.1289", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "267.129", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "136.807", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "361.807", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "105.404", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "330.404", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "31.5967", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "256.597", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "126.275", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "351.275", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "94.8718", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "319.872", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "189.55", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "414.55", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "214.064", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "439.064", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "45.6396", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "270.64", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "140.318", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "365.318", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "108.915", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "333.915", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "52.6611", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "277.661", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "147.34", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "372.34", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "115.936", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "340.936", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "49.1504", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "274.15", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "143.829", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "368.829", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "112.426", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "337.426", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "56.1719", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "281.172", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "150.85", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "375.85", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "119.447", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "344.447", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "21.0645", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "246.064", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "84.3396", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "309.34", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "179.018", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "404.018", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "203.532", y: "4", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "428.532", y: "4", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "7.02148", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "232.021", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "221.511", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "70.2966", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "295.297", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "164.975", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "389.975", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "17.5537", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "242.554", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "80.8289", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "305.829", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "175.507", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "400.507", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "200.021", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "425.021", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "10.5322", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "235.532", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "73.8074", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "298.807", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "168.486", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "393.486", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "193", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "418", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "14.043", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "239.043", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "77.3181", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "302.318", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "171.997", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "396.997", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "196.511", y: "9.76196", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "421.511", y: "9.76196", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "225", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "63.2751", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "288.275", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "157.954", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "382.954", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "24.5752", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "249.575", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "87.8503", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "312.85", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "182.529", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "407.529", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "207.043", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "432.043", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "28.0859", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "253.086", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "91.3611", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "316.361", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "186.04", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "411.04", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "210.554", y: "10.2858", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "435.554", y: "10.2858", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "3.51074", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "228.511", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "218", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "66.7859", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "291.786", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "161.464", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "386.464", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "59.6826", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "284.683", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "154.361", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "379.361", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "122.958", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "347.958", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "478.6182", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "703.6179999999999", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "573.297", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "798.297", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "541.893", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "766.893", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "475.1074", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "700.107", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "569.7860000000001", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "794.7860000000001", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "538.3826", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "763.383", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "482.1289", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "707.129", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "576.807", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "801.807", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "545.404", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "770.404", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "471.5967", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "696.597", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "566.275", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "791.275", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "534.8718", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "759.8720000000001", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "629.55", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "854.55", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "654.064", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "879.0640000000001", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "485.6396", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "710.64", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "580.318", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "805.318", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "548.915", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "773.915", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "492.6611", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "717.6610000000001", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "587.34", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "812.3399999999999", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "555.936", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "780.9359999999999", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "489.1504", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "714.15", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "583.829", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "808.829", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "552.426", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "777.4259999999999", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "496.1719", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "721.172", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "590.85", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "815.85", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "559.447", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "784.447", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "461.0645", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "686.064", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "524.3396", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "749.3399999999999", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "619.018", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "844.018", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "643.532", y: "4", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "868.5319999999999", y: "4", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "447.02148", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "672.021", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "661.511", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "510.2966", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "735.297", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "604.975", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "829.975", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "457.5537", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "682.554", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "520.8289", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "745.829", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "615.5070000000001", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "840.5070000000001", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "640.021", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "865.021", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "450.5322", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "675.532", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "513.8074", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "738.807", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "608.486", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "833.486", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "633", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "858", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "454.043", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "679.043", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "517.3181", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "742.318", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "611.9970000000001", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "836.9970000000001", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "636.511", y: "9.76196", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "861.511", y: "9.76196", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "665", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "503.2751", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "728.275", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "597.954", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "822.954", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "464.5752", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "689.575", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "527.8503000000001", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "752.85", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "622.529", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "847.529", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "647.043", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "872.043", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "468.0859", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "693.086", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "531.3611", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "756.361", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "626.04", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "851.04", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "650.554", y: "10.2858", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "875.554", y: "10.2858", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "443.51074", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "668.511", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "658", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "506.78589999999997", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "731.7860000000001", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "601.4639999999999", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "826.4639999999999", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "499.6826", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "724.683", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "594.361", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "819.361", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "562.958", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "787.9580000000001", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "918.6182", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1143.618", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1013.297", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1238.297", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "981.893", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1206.893", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "915.1074", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1140.107", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1009.7860000000001", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1234.786", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "978.3826", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1203.383", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "922.1289", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1147.129", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1016.807", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1241.807", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "985.404", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1210.404", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "911.5967", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1136.597", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1006.275", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1231.275", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "974.8718", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1199.872", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1069.55", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1294.55", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1094.064", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1319.064", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "925.6396", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1150.6399999999999", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1020.318", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1245.318", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "988.915", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1213.915", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "932.6611", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1157.661", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1027.34", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1252.34", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "995.936", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1220.936", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "929.1504", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1154.15", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1023.829", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1248.829", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "992.426", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1217.426", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "936.1719", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1161.172", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1030.85", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1255.85", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "999.447", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1224.4470000000001", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "901.0645", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1126.064", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "964.3396", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1189.34", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1059.018", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1284.018", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1083.532", y: "4", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1308.532", y: "4", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "887.02148", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1112.021", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1101.511", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "950.2966", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1175.297", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1044.975", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1269.975", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "897.5537", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1122.554", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "960.8289", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1185.829", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1055.507", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1280.507", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1080.021", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1305.021", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "890.5322", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1115.532", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "953.8074", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1178.807", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1048.4859999999999", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1273.4859999999999", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1073", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1298", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "894.043", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1119.0430000000001", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "957.3181", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1182.318", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1051.997", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1276.997", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1076.511", y: "9.76196", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1301.511", y: "9.76196", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1105", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "943.2751", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1168.275", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1037.954", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1262.954", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "904.5752", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1129.575", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "967.8503000000001", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1192.85", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1062.529", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1287.529", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1087.0430000000001", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1312.0430000000001", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "908.0859", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1133.086", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "971.3611", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1196.3609999999999", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1066.04", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1291.04", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1090.554", y: "10.2858", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1315.554", y: "10.2858", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "883.51074", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1108.511", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1098", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "946.7859", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1171.786", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1041.464", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1266.464", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "939.6826", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1164.683", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1034.3609999999999", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1259.3609999999999", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1002.958", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1227.958", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1358.6182", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1583.618", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1453.297", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1678.297", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1421.893", width: "1.51079", height: "22", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1646.893", width: "1.51079", height: "22", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1355.1074", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1580.107", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1449.786", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1674.786", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1418.3826", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1643.383", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.75539", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1362.1289", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1587.129", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1456.807", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1681.807", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1425.404", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1650.404", y: "4.71423", width: "1.51078", height: "12.5714", rx: "0.755389", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1351.5967", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1576.597", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1446.275", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1671.275", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1414.8718", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1639.872", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1509.55", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1734.55", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1534.064", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1759.064", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755397", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1365.6396", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1590.6399999999999", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1460.318", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1685.318", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1428.915", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1653.915", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1372.6611", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1597.661", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1467.34", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1692.34", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1435.936", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1660.936", y: "5.23804", width: "1.51075", height: "12.0476", rx: "0.755377", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1369.1504", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1594.15", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1463.829", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1688.829", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1432.426", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1657.426", y: "8.90479", width: "1.51079", height: "4.19048", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1376.1719", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1601.172", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1470.85", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1695.85", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1439.4470000000001", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1664.4470000000001", y: "8.38098", width: "1.51078", height: "5.7619", rx: "0.755392", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1341.0645", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1566.064", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1404.3396", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1629.34", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1499.018", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1724.018", y: "3.66663", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1523.532", y: "4", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1748.532", y: "4", width: "1.51079", height: "15.1905", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1327.02148", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1552.021", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1541.511", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1390.2966", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1615.297", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1484.975", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1709.975", y: "5.23804", width: "1.51079", height: "12.0476", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1337.5537", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1562.554", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1400.8289", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1625.829", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1495.507", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1720.507", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1520.021", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1745.021", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755394", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1330.5322", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1555.532", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1393.8074", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1618.807", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1488.4859999999999", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1713.4859999999999", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1513", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1738", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1334.043", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1559.0430000000001", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1397.3181", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1622.318", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1491.997", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1716.997", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1516.511", y: "9.76196", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1741.511", y: "9.76196", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1545", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1383.2751", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1608.275", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1477.954", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1702.954", y: "9.42859", width: "1.51079", height: "3.14285", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1344.5752", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1569.575", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1407.8503", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1632.85", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1502.529", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1727.529", y: "8.38098", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1527.0430000000001", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1752.0430000000001", y: "8.71436", width: "1.51079", height: "5.7619", rx: "0.755395", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1348.0859", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1573.086", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1411.3611", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1636.3609999999999", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1506.04", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1731.04", y: "9.95239", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1530.554", y: "10.2858", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1755.554", y: "10.2858", width: "1.51078", height: "2.09524", rx: "0.755391", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1323.51074", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1548.511", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1538", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1386.7859", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1611.786", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1481.464", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1706.464", y: "9.95239", width: "1.51079", height: "2.09524", rx: "0.755396", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1379.6826", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1604.683", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1474.3609999999999", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1699.3609999999999", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" }),
                React.createElement("rect", { x: "1442.958", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#FF5B00" }),
                React.createElement("rect", { x: "1667.958", y: "9.95239", width: "1.5108", height: "2.09524", rx: "0.7554", fill: "#CFD8DC" })))));
}
