// tslint:disable
// @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface BalanceHistoryView
 */
export interface BalanceHistoryView {
  /**
   *
   * @type {Date}
   * @memberof BalanceHistoryView
   */
  date: Date;
  /**
   *
   * @type {number}
   * @memberof BalanceHistoryView
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof BalanceHistoryView
   */
  duration: number;
}
/**
 *
 * @export
 * @interface LimitUpdateWebHook
 */
export interface LimitUpdateWebHook {
  /**
   *
   * @type {number}
   * @memberof LimitUpdateWebHook
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof LimitUpdateWebHook
   */
  balance: number;
}
/**
 *
 * @export
 * @interface PackagePrice
 */
export interface PackagePrice {
  /**
   *
   * @type {string}
   * @memberof PackagePrice
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PackagePrice
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof PackagePrice
   */
  currency: CurrencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CurrencyEnum {
  RUB = 'RUB',
  USD = 'USD',
}

/**
 *
 * @export
 * @interface PaymentInfo
 */
export interface PaymentInfo {
  /**
   *
   * @type {string}
   * @memberof PaymentInfo
   */
  paymentSystem: PaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentInfo
   */
  yoomoneyRedirectUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentInfo
   */
  stripeSessionId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentInfo
   */
  stripePublicKey?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentSystemEnum {
  YOOMONEY = 'YOOMONEY',
  STRIPE = 'STRIPE',
}

/**
 *
 * @export
 * @interface SynthesizeDialogRequest
 */
export interface SynthesizeDialogRequest {
  /**
   *
   * @type {Array<SynthesizeTextRequest>}
   * @memberof SynthesizeDialogRequest
   */
  synthesizeTextRequests: Array<SynthesizeTextRequest>;
}
/**
 *
 * @export
 * @interface SynthesizeTextRequest
 */
export interface SynthesizeTextRequest {
  /**
   *
   * @type {number}
   * @memberof SynthesizeTextRequest
   */
  voiceId: number;
  /**
   *
   * @type {number}
   * @memberof SynthesizeTextRequest
   */
  modelId: number;
  /**
   *
   * @type {string}
   * @memberof SynthesizeTextRequest
   */
  text: string;
}
/**
 *
 * @export
 * @interface WalletView
 */
export interface WalletView {
  /**
   *
   * @type {number}
   * @memberof WalletView
   */
  userId: number;
  /**
   *
   * @type {number}
   * @memberof WalletView
   */
  balance: number;
}

/**
 * BalanceHistoryControllerApi - axios parameter creator
 * @export
 */
export const BalanceHistoryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balanceHistory(page?: number, pageSize?: number, options: any = {}): RequestArgs {
      const localVarPath = `/api/user/balanceHistory`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BalanceHistoryControllerApi - functional programming interface
 * @export
 */
export const BalanceHistoryControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balanceHistory(
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BalanceHistoryView>> {
      const localVarAxiosArgs = BalanceHistoryControllerApiAxiosParamCreator(configuration).balanceHistory(
        page,
        pageSize,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * BalanceHistoryControllerApi - factory interface
 * @export
 */
export const BalanceHistoryControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    balanceHistory(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<BalanceHistoryView>> {
      return BalanceHistoryControllerApiFp(configuration).balanceHistory(page, pageSize, options)(axios, basePath);
    },
  };
};

/**
 * BalanceHistoryControllerApi - object-oriented interface
 * @export
 * @class BalanceHistoryControllerApi
 * @extends {BaseAPI}
 */
export class BalanceHistoryControllerApi extends BaseAPI {
  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BalanceHistoryControllerApi
   */
  public balanceHistory(page?: number, pageSize?: number, options?: any) {
    return BalanceHistoryControllerApiFp(this.configuration).balanceHistory(
      page,
      pageSize,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * InferenceControllerApi - axios parameter creator
 * @export
 */
export const InferenceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} text
     * @param {string} apiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInference(text: string, apiKey: string, options: any = {}): RequestArgs {
      // verify required parameter 'text' is not null or undefined
      if (text === null || text === undefined) {
        throw new RequiredError('text', 'Required parameter text was null or undefined when calling apiInference.');
      }
      // verify required parameter 'apiKey' is not null or undefined
      if (apiKey === null || apiKey === undefined) {
        throw new RequiredError('apiKey', 'Required parameter apiKey was null or undefined when calling apiInference.');
      }
      const localVarPath = `/api/v1/synthesize`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (text !== undefined) {
        localVarQueryParameter['text'] = text;
      }

      if (apiKey !== undefined && apiKey !== null) {
        localVarHeaderParameter['api-key'] = String(apiKey);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SynthesizeDialogRequest} synthesizeDialogRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiDialogInference(
      synthesizeDialogRequest: SynthesizeDialogRequest,
      gRecaptchaResponse?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'synthesizeDialogRequest' is not null or undefined
      if (synthesizeDialogRequest === null || synthesizeDialogRequest === undefined) {
        throw new RequiredError(
          'synthesizeDialogRequest',
          'Required parameter synthesizeDialogRequest was null or undefined when calling uiDialogInference.'
        );
      }
      const localVarPath = `/api/voices/dialog`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (gRecaptchaResponse !== undefined && gRecaptchaResponse !== null) {
        localVarHeaderParameter['g-recaptcha-response'] = String(gRecaptchaResponse);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof synthesizeDialogRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(synthesizeDialogRequest !== undefined ? synthesizeDialogRequest : {})
        : synthesizeDialogRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {number} modelId
     * @param {string} text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiInference(voiceId: number, modelId: number, text: string, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling uiInference.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling uiInference.'
        );
      }
      // verify required parameter 'text' is not null or undefined
      if (text === null || text === undefined) {
        throw new RequiredError('text', 'Required parameter text was null or undefined when calling uiInference.');
      }
      const localVarPath = `/api/voices/{voiceId}/models/{modelId}/synthesize`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (text !== undefined) {
        localVarQueryParameter['text'] = text;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InferenceControllerApi - functional programming interface
 * @export
 */
export const InferenceControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} text
     * @param {string} apiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInference(
      text: string,
      apiKey: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = InferenceControllerApiAxiosParamCreator(configuration).apiInference(
        text,
        apiKey,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {SynthesizeDialogRequest} synthesizeDialogRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiDialogInference(
      synthesizeDialogRequest: SynthesizeDialogRequest,
      gRecaptchaResponse?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = InferenceControllerApiAxiosParamCreator(configuration).uiDialogInference(
        synthesizeDialogRequest,
        gRecaptchaResponse,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {number} modelId
     * @param {string} text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiInference(
      voiceId: number,
      modelId: number,
      text: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = InferenceControllerApiAxiosParamCreator(configuration).uiInference(
        voiceId,
        modelId,
        text,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * InferenceControllerApi - factory interface
 * @export
 */
export const InferenceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} text
     * @param {string} apiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiInference(text: string, apiKey: string, options?: any): AxiosPromise<Array<string>> {
      return InferenceControllerApiFp(configuration).apiInference(text, apiKey, options)(axios, basePath);
    },
    /**
     *
     * @param {SynthesizeDialogRequest} synthesizeDialogRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiDialogInference(
      synthesizeDialogRequest: SynthesizeDialogRequest,
      gRecaptchaResponse?: string,
      options?: any
    ): AxiosPromise<Array<string>> {
      return InferenceControllerApiFp(configuration).uiDialogInference(
        synthesizeDialogRequest,
        gRecaptchaResponse,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {number} modelId
     * @param {string} text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uiInference(voiceId: number, modelId: number, text: string, options?: any): AxiosPromise<Array<string>> {
      return InferenceControllerApiFp(configuration).uiInference(voiceId, modelId, text, options)(axios, basePath);
    },
  };
};

/**
 * InferenceControllerApi - object-oriented interface
 * @export
 * @class InferenceControllerApi
 * @extends {BaseAPI}
 */
export class InferenceControllerApi extends BaseAPI {
  /**
   *
   * @param {string} text
   * @param {string} apiKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceControllerApi
   */
  public apiInference(text: string, apiKey: string, options?: any) {
    return InferenceControllerApiFp(this.configuration).apiInference(text, apiKey, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {SynthesizeDialogRequest} synthesizeDialogRequest
   * @param {string} [gRecaptchaResponse]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceControllerApi
   */
  public uiDialogInference(
    synthesizeDialogRequest: SynthesizeDialogRequest,
    gRecaptchaResponse?: string,
    options?: any
  ) {
    return InferenceControllerApiFp(this.configuration).uiDialogInference(
      synthesizeDialogRequest,
      gRecaptchaResponse,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {number} modelId
   * @param {string} text
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InferenceControllerApi
   */
  public uiInference(voiceId: number, modelId: number, text: string, options?: any) {
    return InferenceControllerApiFp(this.configuration).uiInference(
      voiceId,
      modelId,
      text,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * PackageEndpointApi - axios parameter creator
 * @export
 */
export const PackageEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} packageName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buyPackage(packageName: string, options: any = {}): RequestArgs {
      // verify required parameter 'packageName' is not null or undefined
      if (packageName === null || packageName === undefined) {
        throw new RequiredError(
          'packageName',
          'Required parameter packageName was null or undefined when calling buyPackage.'
        );
      }
      const localVarPath = `/api/packages/{packageName}`.replace(
        `{${'packageName'}}`,
        encodeURIComponent(String(packageName))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPackages(options: any = {}): RequestArgs {
      const localVarPath = `/api/packages`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PackageEndpointApi - functional programming interface
 * @export
 */
export const PackageEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} packageName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buyPackage(
      packageName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInfo> {
      const localVarAxiosArgs = PackageEndpointApiAxiosParamCreator(configuration).buyPackage(packageName, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPackages(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PackagePrice>> {
      const localVarAxiosArgs = PackageEndpointApiAxiosParamCreator(configuration).getPackages(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PackageEndpointApi - factory interface
 * @export
 */
export const PackageEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} packageName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buyPackage(packageName: string, options?: any): AxiosPromise<PaymentInfo> {
      return PackageEndpointApiFp(configuration).buyPackage(packageName, options)(axios, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPackages(options?: any): AxiosPromise<Array<PackagePrice>> {
      return PackageEndpointApiFp(configuration).getPackages(options)(axios, basePath);
    },
  };
};

/**
 * PackageEndpointApi - object-oriented interface
 * @export
 * @class PackageEndpointApi
 * @extends {BaseAPI}
 */
export class PackageEndpointApi extends BaseAPI {
  /**
   *
   * @param {string} packageName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackageEndpointApi
   */
  public buyPackage(packageName: string, options?: any) {
    return PackageEndpointApiFp(this.configuration).buyPackage(packageName, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackageEndpointApi
   */
  public getPackages(options?: any) {
    return PackageEndpointApiFp(this.configuration).getPackages(options)(this.axios, this.basePath);
  }
}

/**
 * WalletEndpointApi - axios parameter creator
 * @export
 */
export const WalletEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet(options: any = {}): RequestArgs {
      const localVarPath = `/api/wallet`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WalletEndpointApi - functional programming interface
 * @export
 */
export const WalletEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletView> {
      const localVarAxiosArgs = WalletEndpointApiAxiosParamCreator(configuration).getWallet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * WalletEndpointApi - factory interface
 * @export
 */
export const WalletEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallet(options?: any): AxiosPromise<WalletView> {
      return WalletEndpointApiFp(configuration).getWallet(options)(axios, basePath);
    },
  };
};

/**
 * WalletEndpointApi - object-oriented interface
 * @export
 * @class WalletEndpointApi
 * @extends {BaseAPI}
 */
export class WalletEndpointApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletEndpointApi
   */
  public getWallet(options?: any) {
    return WalletEndpointApiFp(this.configuration).getWallet(options)(this.axios, this.basePath);
  }
}

/**
 * WebHookEndpointApi - axios parameter creator
 * @export
 */
export const WebHookEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {LimitUpdateWebHook} limitUpdateWebHook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletTransactionsWebhook(limitUpdateWebHook: LimitUpdateWebHook, options: any = {}): RequestArgs {
      // verify required parameter 'limitUpdateWebHook' is not null or undefined
      if (limitUpdateWebHook === null || limitUpdateWebHook === undefined) {
        throw new RequiredError(
          'limitUpdateWebHook',
          'Required parameter limitUpdateWebHook was null or undefined when calling walletTransactionsWebhook.'
        );
      }
      const localVarPath = `/webhook/wallet`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof limitUpdateWebHook !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(limitUpdateWebHook !== undefined ? limitUpdateWebHook : {})
        : limitUpdateWebHook || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WebHookEndpointApi - functional programming interface
 * @export
 */
export const WebHookEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {LimitUpdateWebHook} limitUpdateWebHook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletTransactionsWebhook(
      limitUpdateWebHook: LimitUpdateWebHook,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = WebHookEndpointApiAxiosParamCreator(configuration).walletTransactionsWebhook(
        limitUpdateWebHook,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * WebHookEndpointApi - factory interface
 * @export
 */
export const WebHookEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {LimitUpdateWebHook} limitUpdateWebHook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    walletTransactionsWebhook(limitUpdateWebHook: LimitUpdateWebHook, options?: any): AxiosPromise<void> {
      return WebHookEndpointApiFp(configuration).walletTransactionsWebhook(limitUpdateWebHook, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * WebHookEndpointApi - object-oriented interface
 * @export
 * @class WebHookEndpointApi
 * @extends {BaseAPI}
 */
export class WebHookEndpointApi extends BaseAPI {
  /**
   *
   * @param {LimitUpdateWebHook} limitUpdateWebHook
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebHookEndpointApi
   */
  public walletTransactionsWebhook(limitUpdateWebHook: LimitUpdateWebHook, options?: any) {
    return WebHookEndpointApiFp(this.configuration).walletTransactionsWebhook(limitUpdateWebHook, options)(
      this.axios,
      this.basePath
    );
  }
}
