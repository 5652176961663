import React from 'react';
import { useAppContext } from '../../../../AppContext';
import Recaptcha from 'react-google-recaptcha';
import SynthArea from '.';

type SynthBlockProps = {
  handler: (text: string) => Promise<any>;
  placeholderText?: string;
  textChangeWatcher?: (text: string) => void;
  initialText?: string;
  recaptchaInstance: React.MutableRefObject<any>;
};

export const SynthBlock = ({
  handler,
  textChangeWatcher,
  initialText,
  placeholderText,
  recaptchaInstance,
}: SynthBlockProps) => {
  const { configData, getUserLanguage } = useAppContext();
  return (
    <>
      <SynthArea
        handler={handler}
        textChangeWatcher={textChangeWatcher}
        initialText={initialText}
        placeholderText={placeholderText}
      />

      {configData?.captcha?.siteKey && configData?.captcha?.enabled && (
        <Recaptcha
          data-test-id='LandingPage.recaptcha'
          ref={recaptchaInstance}
          sitekey={configData?.captcha?.siteKey || '6Lek0PwUAAAAAFuLtQW3TnEBCddnkmV95IdGY6FE'}
          className='captcha'
          size='invisible'
          badge='bottomleft'
          hl={getUserLanguage().substr(0, 2).toUpperCase()}
        />
      )}
    </>
  );
};
