import React, { useCallback, useEffect, useRef, useReducer } from 'react';
import { useParams, Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useLibraryContext } from '../../../context/LibraryContext';
import { CustomModal, CustomModalRemoveFromCatalog } from '../../CustomModal';
import { t } from '../../../../../localization';
import { Voice } from '../../../model/Voice';
import BannerClosed from './BannerClosed';
import BannerOpen from './BannerOpen';
import useAlert from '../../../../../utils/useAlert';
import { useCaptcha, useLoading } from '../../../../../utils/hooks';
import { useAmplitude } from 'react-amplitude-hooks';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { Glass } from '../../CatalogPage';
import './styles.scss';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE } from '../../../../Header/constants';
import { useAppContext } from '../../../../../AppContext';
import { minVoicePrices } from '../../../../../utils';

type BannerProps = {
  fetchVoice: () => Promise<void>;
  voice: Voice;
  type?: 'usage';
};

type BannerTypes = {
  price: string;
  modalOpen: boolean;
  panelOpen: boolean;
  publicSynthText: string;
  testText: string;
  publicationInProgress: boolean;
};

type BannerAction =
  | {
      type: 'SET_VOICE_PRICE';
      payload: string;
    }
  | { type: 'SET_MODAL_OPEN'; payload: boolean }
  | { type: 'TOGGLE_MODAL_OPEN' }
  | { type: 'SET_PANEL_OPEN'; payload: boolean }
  | { type: 'SET_PUBLIC_SYNTH_TEXT'; payload: string }
  | { type: 'SET_TEST_TEXT'; payload: string }
  | { type: 'TOGGLE_PUBLICATION_PROGRESS' };

const bannerReducer = (state: BannerTypes, action: BannerAction) => {
  switch (action.type) {
    case 'SET_MODAL_OPEN': {
      return { ...state, modalOpen: action.payload };
    }
    case 'TOGGLE_MODAL_OPEN': {
      return { ...state, modalOpen: !state.modalOpen };
    }
    case 'SET_VOICE_PRICE': {
      return { ...state, price: action.payload };
    }
    case 'SET_PANEL_OPEN': {
      return { ...state, panelOpen: action.payload };
    }
    case 'SET_PUBLIC_SYNTH_TEXT': {
      return { ...state, publicSynthText: action.payload };
    }
    case 'SET_TEST_TEXT': {
      return { ...state, testText: action.payload };
    }
    case 'TOGGLE_PUBLICATION_PROGRESS': {
      return { ...state, publicationInProgress: !state.publicationInProgress };
    }
  }
};

const BannerClosedVariant: Variants = {
  initial: { height: '0', opacity: 0 },
  animate: {
    height: 'auto',
    opacity: 1,
  },
  exit: { height: 0, opacity: 0 },
};

export default function Banner({ voice, fetchVoice, type }: BannerProps) {
  const [state, dispatch] = useReducer(bannerReducer, {
    price: '',
    modalOpen: false,
    panelOpen: false,
    publicSynthText: '',
    testText: '',
    publicationInProgress: false,
  } as BannerTypes);

  const { price, modalOpen, panelOpen, testText } = state;

  const { isLoading, load } = useLoading(false);
  const { publishVoice, unpublishVoice, getVoiceModels, setVoicePrice, getVoicePrice } = useLibraryContext();
  const { id, isUSDInstance, configData, synthesizeDialog } = useAppContext();
  const { id: voiceId } = useParams<{ id: string }>();
  const { defaultErrorAlert, successAlert } = useAlert();
  const { logEvent } = useAmplitude();
  const history = useHistory<{ open?: boolean }>();
  const match = useRouteMatch();
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const startedPriceRef = useRef('');
  const openedBannerRef = useRef<HTMLDivElement>(null);
  const { executeCaptcha, recaptchaInstance } = useCaptcha();

  const BannerOpenVariant: Variants = {
    initial: { height: 0, opacity: 0, padding: isPhone ? '1rem' : '1.5rem' },
    animate: { height: 'auto', padding: isPhone ? '1rem' : '1.5rem', opacity: 1 },
    exit: { height: 0, opacity: 0 },
  };

  const fetchVoicePrice = useCallback(async () => {
    const price = await getVoicePrice(voice.id);
    startedPriceRef.current = price;
    dispatch({ type: 'SET_VOICE_PRICE', payload: price });
  }, [getVoicePrice, voice.id]);

  useEffect(() => {
    fetchVoicePrice();
  }, [fetchVoicePrice]);

  useEffect(() => {
    dispatch({ type: 'SET_TEST_TEXT', payload: voice.previewText || '' });
    if (history.location.state && history.location.state.open) {
      dispatch({ type: 'SET_PANEL_OPEN', payload: true });
      window.history.replaceState({}, '');
    } else {
      dispatch({ type: 'SET_PANEL_OPEN', payload: voice.isPublished });
    }
  }, [history.location.state, voice]);

  const handleUnpublishVoice = async () => {
    try {
      await unpublishVoice(+voiceId);
      logEvent('Voice unpublished ', {
        page_url: match.url,
        result: 'success',
        member_role: voice?.currentVoiceMemberRole.name,
      });
      successAlert(t('removeFromCatalog'));
      await fetchVoice();
      dispatch({ type: 'SET_VOICE_PRICE', payload: startedPriceRef.current });
      dispatch({ type: 'SET_MODAL_OPEN', payload: false });
      dispatch({ type: 'SET_PANEL_OPEN', payload: false });
    } catch (error) {
      logEvent('Voice unpublished ', {
        page_url: match.url,
        result: 'failed',
        member_role: voice?.currentVoiceMemberRole.name,
        user_id: id,
      });
      defaultErrorAlert(error);
    }
  };

  const handlePublishVoice = async () => {
    dispatch({ type: 'TOGGLE_PUBLICATION_PROGRESS' });
    try {
      await publishVoice(+voiceId, testText);
      await setVoicePrice(voice.id, { price: +price });
      logEvent('Voice published', {
        page_url: match.url,
        result: 'success',
        member_role: voice?.currentVoiceMemberRole.name,
        user_id: id,
      });
      successAlert(
        type ? (
          <div>
            {`${t('voicePublished')}. ${t('voicePublishedFromUsage')} `}
            <Link to={`/my/voice/${voiceId}/control`}>{t('onControlPage').toLowerCase()}</Link>
          </div>
        ) : (
          `${t('voicePublished')}. ${t('voicePublishedFromControl')}`
        )
      );
      fetchVoice();
      fetchVoicePrice();
      dispatch({ type: 'SET_TEST_TEXT', payload: '' });
    } catch (error) {
      logEvent('Voice published', {
        page_url: match.url,
        result: 'failed',
        member_role: voice?.currentVoiceMemberRole.name,
        user_id: id,
      });
      defaultErrorAlert(error);
    } finally {
      dispatch({ type: 'TOGGLE_PUBLICATION_PROGRESS' });
    }
  };

  const handlePublicationUpdate = async () => {
    dispatch({ type: 'TOGGLE_PUBLICATION_PROGRESS' });

    try {
      await load(publishVoice(+voiceId, testText));
      await setVoicePrice(voice.id, { price: +price });
      successAlert(`${t('publicationUpdate')}.`);
      fetchVoice();
      fetchVoicePrice();
      dispatch({ type: 'SET_TEST_TEXT', payload: '' });
      logEvent('Voice publish update', {
        userId: id,
        result: 'success',
        member_role: voice?.currentVoiceMemberRole.name,
        page_rul: match.url,
      });
    } catch (error) {
      defaultErrorAlert(error);
      logEvent('Voice publish update', {
        userId: id,
        result: 'failed',
        member_role: voice?.currentVoiceMemberRole.name,
        page_rul: match.url,
      });
    } finally {
      dispatch({ type: 'TOGGLE_PUBLICATION_PROGRESS' });
    }
  };

  const handleSynth = async text => {
    try {
      const actualModel = (await getVoiceModels(voice.id, true)).find(model => model.isActual);
      if (!actualModel) return;
      const token = configData.captchaEnabled ? await executeCaptcha() : undefined;
      const audioUrl = await load(synthesizeDialog([{ voiceId: voice.id, modelId: actualModel.id, text }], token));
      dispatch({ type: 'SET_PUBLIC_SYNTH_TEXT', payload: text });
      logEvent('synthesize', {
        result: 'success',
        page_type: match.path,
        page_url: match.url,
        type: 'voice_publication',
        user_id: id,
      });
      return audioUrl;
    } catch (error) {
      logEvent('synthesize', {
        result: 'failed',
        page_type: match.path,
        page_url: match.url,
        type: 'voice_publication',
        user_id: id,
      });
      defaultErrorAlert(error);
      throw error;
    }
  };

  const handleBannerOpen = useCallback(() => {
    dispatch({ type: 'SET_PANEL_OPEN', payload: true });
    setTimeout(() => {
      openedBannerRef.current && openedBannerRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 700);
  }, []);

  const usageBannerOpen = useCallback(() => {
    history.push(`/my/voice/${voiceId}/control`, { open: true });
  }, [history, voiceId]);

  const getDecimalPart = useCallback(
    (decimal: string) => {
      if (isUSDInstance) {
        return decimal.substring(0, 3);
      }
      return (decimal && decimal[0]) || decimal;
    },
    [isUSDInstance]
  );

  const handlePriceChange = useCallback(
    (value: string) => {
      const priceFormat = /^\.|[^\d.]|\.(?=.*\.)|^0+(?=\d)/g;

      const replaced = value.replace(priceFormat, '');
      const decimal = replaced.split('.')[1];
      const shortDecimal = (decimal && getDecimalPart(decimal)) || '';
      dispatch({
        type: 'SET_VOICE_PRICE',
        payload: `${replaced.split('.')[0]}${shortDecimal ? '.' + shortDecimal : ''}`,
      });
    },
    [getDecimalPart]
  );

  const allowPublish =
    testText.length > 50 && Number(price) >= (isUSDInstance ? minVoicePrices.USD : minVoicePrices.RUB);

  const modalToggle = useCallback(() => {
    dispatch({ type: 'TOGGLE_MODAL_OPEN' });
  }, []);

  const allowUpdate = () => {
    if (Number(price) !== Number(startedPriceRef.current) || testText !== voice.previewText) {
      if (Number(price) < 0.5 || testText.length < 50) {
        return false;
      }
      return true;
    }
  };
  const setTestText = useCallback((value: string) => {
    dispatch({ type: 'SET_TEST_TEXT', payload: value });
  }, []);

  if (!voice) return null;
  if (type === 'usage')
    return (
      <div className='voice-page__banner'>
        <BannerClosed voice={voice} handlePanelOpen={usageBannerOpen} />
      </div>
    );
  return (
    <div>
      <AnimatePresence initial={false} exitBeforeEnter>
        {panelOpen ? (
          <motion.div
            variants={BannerOpenVariant}
            animate='animate'
            exit='exit'
            key='open'
            initial='initial'
            className='voice-page__add-to-catalog'
            ref={openedBannerRef}
          >
            <Glass isLoading={state.publicationInProgress} />
            <BannerOpen
              isPublished={voice.isPublished}
              handleSynth={handleSynth}
              modalToggle={modalToggle}
              handlePublishVoice={handlePublishVoice}
              handlePublicationUpdate={handlePublicationUpdate}
              loading={isLoading}
              publishDisabled={voice.isPublished ? !allowUpdate() : !allowPublish}
              textWatcher={setTestText}
              voiceTextPreview={voice.previewText}
              handlePriceChange={handlePriceChange}
              price={price}
              recaptchaInstance={recaptchaInstance}
            />
          </motion.div>
        ) : (
          <motion.div
            variants={BannerClosedVariant}
            initial='initial'
            animate='animate'
            exit='exit'
            className='voice-page__banner'
            key='closed'
          >
            <BannerClosed voice={voice} handlePanelOpen={handleBannerOpen} />
          </motion.div>
        )}
      </AnimatePresence>

      <CustomModal
        isOpen={modalOpen}
        title=''
        position='center'
        toggle={modalToggle}
        customClassName='unpublish-voice'
        modalCard
      >
        <CustomModalRemoveFromCatalog
          cancelButtonClick={modalToggle}
          handleModalAction={handleUnpublishVoice}
          data={{ price: startedPriceRef.current, voice }}
        />
      </CustomModal>
    </div>
  );
}
