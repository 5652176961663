import React, { useCallback } from 'react';
import { Button } from '@just-ai/just-ui';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';

import { SCREEN_WIDTH_TABLET, SCREEN_WIDTH_MD, SCREEN_WIDTH_LG } from '../../../../Header/constants';
import { t } from '../../../../../localization';
import { useAppContext } from '../../../../../AppContext';

import './ManagePlaceholder.scss';

export const ManagePlaceholder = () => {
  const { language } = useAppContext();
  const history = useHistory();
  const isTablet = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });

  const handleCreateLink = useCallback(() => {
    history.push('/my/new_voice');
  }, [history]);

  return (
    <div className='my-page__manage-placeholder placeholder'>
      <div className='placeholder__text-block'>
        <h1>
          <span className='orange'>{t('createVerb')}</span> {t('yourUnique').toLowerCase()}{' '}
          <span className='orange'>{t('voice').toLowerCase()}</span>
          {` ${t('and').toLowerCase()} ${t('earn').toLowerCase()}`}
        </h1>
        {!isTablet && (
          <Button color='primary' size='lg' data-test-id='createVoiceBtn' onClick={handleCreateLink}>
            {t('createVoice')}
          </Button>
        )}
      </div>
      <div className='placeholder__img-block'>
        <picture>
          <source
            srcSet={`img/MyPage/myPageMicrophonePhone${language === 'eng' ? '--usd' : ''}.png`}
            media={`(max-width: ${SCREEN_WIDTH_TABLET}px)`}
          />
          <source
            srcSet={`img/MyPage/myPageMicrophoneMD${language === 'eng' ? '--usd' : ''}.png`}
            media={`(max-width: ${SCREEN_WIDTH_MD}px)`}
          />
          <source
            srcSet={`img/MyPage/myPageMicrophoneLG${language === 'eng' ? '--usd' : ''}.png`}
            media={`(max-width: ${SCREEN_WIDTH_LG}px)`}
          />
          <img src={`'img/MyPage/myPageMicrophoneMain${language === 'eng' ? '--usd' : ''}.png'`} alt='Microphone' />
        </picture>
      </div>
      {isTablet && (
        <Button color='primary' size='lg' onClick={handleCreateLink} data-test-id='createVoiceBtn'>
          {t('createVoice')}
        </Button>
      )}
    </div>
  );
};
