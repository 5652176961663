import React, { useEffect, useState } from 'react';
import { Card } from '@just-ai/just-ui/dist/Card';
import { useAmplitude } from 'react-amplitude-hooks';
import { Link, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import SimpleHystogramPlayer from '../../components/HystoPlayer';
import { PublishedVoiceView } from '../../../../api/facade/client';
import { t } from '../../../../localization';
import { VoiceAvatar } from '../VoiceAvatar';
import './style.scss';
import { SCREEN_WIDTH_PHONE } from '../../../Header/constants';
import VoiceLicenseBlock from './LicenseBlock';
import { useAppContext } from '../../../../AppContext';
import { normalizeCurrencyByCode } from '../../../../utils';
import { languagesMap } from '../../constants';

//TODO return all content as soon as backend can provide data to show it

export type PublicVoiceCardProps = PublishedVoiceView & {
  inFavouritePage?: boolean;
  hoursRecorded?: number;
  audioSampleUrl: string;
  playingId?: number | null;
  price: string;
  setPlayingId: (id: number | null) => void;
  openPhoneModal: (e: React.SyntheticEvent) => void;
};

export default function PublicVoiceCard(props: PublicVoiceCardProps) {
  const { name, tags = [], id, audioSampleUrl, playingId, setPlayingId, price, accessRestricted, language } = props;

  const [playing, setPlaying] = useState(true);
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const { configData } = useAppContext();

  useEffect(() => {
    if (playingId !== id) {
      setPlaying(false);
    }
  }, [id, playingId]);

  const handlePlayer = ({ event, playing }: { event: React.SyntheticEvent; playing: boolean }) => {
    event.stopPropagation();
    if (event.type !== 'ended') {
      logEvent('voice_sample_audio', {
        page_type: match.path,
        page_url: match.url,
        action: playing ? 'pause' : 'play',
        type: 'catalog voice',
        voice_id: playingId,
      });
    }
    setPlaying(playing);
    setPlayingId(playing ? id : null);
  };

  // const handleLikeClick = useCallback(() => {
  //   return inFavouritePage ? setOpenRemovePopover(true) : handleVoiceLike(id);
  // }, [inFavouritePage, handleVoiceLike, id]);
  return (
    <Link style={{ display: 'contents' }} to={`/catalog/${id}`} data-test-id='allVoices.voiceCard'>
      <div className='card__wrapper'>
        <Card className='voice-card__container' padding='lg'>
          {tags.includes('supervoice') && <div className='super-medal'>{t('supervoice')}</div>}
          <div className='block-center'>
            <div className='image-container'>
              <VoiceAvatar size='main' voiceId={id} />
            </div>
            {/* <UncontrolledTooltip placement='left' fade={false} trigger='hover' target={`heart-voice-${id}`}>
        {t('add to favorite')}
      </UncontrolledTooltip> */}
            {/* <Icon
        className='voice-card-icon'
        id={`heart-voice-${id}`}
        name={favorite ? 'faHeart' : 'falHeart'}
        color={favorite ? 'danger' : 'none'}
        onClick={e => {
          e.stopPropagation();
          setFavorite(!favorite);
        }}
      /> */}
            <div className='voice-card__name-block'>
              <p className='st-1'>{name}</p>
              <p className='tp-5 voice-card__language'>{t(languagesMap[language])}</p>
              {/* <p className='tp-4 grey-text-2'>{t('hours amount recorded', hoursRecordedFormatted)}</p> */}
            </div>
            <SimpleHystogramPlayer handlePlayer={handlePlayer} audioUrl={audioSampleUrl} playing={playing} />
          </div>
          {accessRestricted ? (
            <VoiceLicenseBlock isPhone={isPhone} openPhoneModal={props.openPhoneModal} />
          ) : (
            <div className='voice-card__price-block' data-test-id='allVoices.voiceCardPrice'>
              <p className='tp-3'>{normalizeCurrencyByCode(configData.currency, Number(price))} </p>
              <span>/ {t('minute')}</span>
            </div>
          )}
        </Card>
      </div>
    </Link>
  );
}
