import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Button, Icon, useToggle } from '@just-ai/just-ui';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';

import { t } from '../../../../../localization';
import { useLibraryContext } from '../../../context/LibraryContext';
import useAlert from '../../../../../utils/useAlert';
import { CustomModal, CustomModalDataLoss } from '../../CustomModal';
import { useAppContext } from '../../../../../AppContext';

import './CreateSample.scss';

type Inputs = {
  title: string;
  description: string;
  text: string;
};

type CreateSampleType = {
  fetchCatalogData: () => void;
  fetchSamples: () => void;
  catalogId: number;
  voiceId: number;
  currentTab: string;
  pageBlocked: boolean;
  changeTab: (value: string) => void;
  setPageBlocked: (value: boolean) => void;
};

export const CreateSample = ({
  catalogId,
  voiceId,
  fetchCatalogData,
  fetchSamples,
  changeTab,
  currentTab,
  pageBlocked,
  setPageBlocked,
}: CreateSampleType) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { createSample } = useLibraryContext();
  const { successAlert, defaultErrorAlert } = useAlert();
  const [creating, , , toggleCreating] = useToggle();
  const history = useHistory();
  const { register, handleSubmit, errors, watch } = useForm<Inputs>();
  const sampleTitle = watch('title');
  const sampleText = watch('text', '');
  const unblockHandle = useRef<any>();
  const targetLink = useRef<any>();
  const { logEvent } = useAmplitude();
  const { id } = useAppContext();
  const match = useRouteMatch();

  const handleResize = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.target.style.height = '32px';
    const borderHeight = 1;
    if (event.target.value.length > 61) {
      event.target.style.height = event.target.scrollHeight + borderHeight + 'px';
    }
  }, []);

  useEffect(() => {
    return () => {
      if (unblockHandle.current) {
        unblockHandle.current();
      }
    };
  }, []);

  useEffect(() => {
    if (sampleText.length > 0 && !unblockHandle.current) {
      !pageBlocked && !modalOpen && setPageBlocked(true);
      unblockHandle.current = history.block((targetLocation: any) => {
        targetLink.current = targetLocation;
        if (sampleText.length > 0) {
          setModalOpen(true);
        }
        return false;
      });
    }
  }, [sampleText, modalOpen, pageBlocked, setPageBlocked, history]);

  useEffect(() => {
    if (/^(blocked)/g.test(currentTab)) {
      setModalOpen(true);
    }
  }, [currentTab, pageBlocked]);

  const onSubmit = async (data: Inputs) => {
    if (creating) return;
    toggleCreating();
    try {
      const createdSampleId = await createSample(catalogId, {
        sampleTitle: data.title,
        sampleComment: data.description,
        sampleText: data.text,
      });
      if (createdSampleId) {
        successAlert(t('addSample', data.title || t('untitled')));
        logEvent('Create sample', {
          userId: id,
          sampleId: createdSampleId,
          catalogId,
          title: data.title,
          description: data.description,
          text: data.text,
          url: match.url,
          result: 'success',
        });
        await setPageBlocked(false);
        setModalOpen(false);
        await Promise.all([fetchCatalogData(), fetchSamples()]);
        if (unblockHandle.current) unblockHandle.current();
        if (/^(blocked)/g.test(currentTab)) {
          changeTab(currentTab.split('blocked')[1] === 'voiced' ? 'notVoiced' : 'voiced');
        }
        if (currentTab === 'voiced') {
          changeTab('notVoiced');
        }
        history.push(`/my/voice/${voiceId}/training/new_version/${catalogId}/sample/${createdSampleId}`);
      } else {
        throw new Error(t('createSampleError'));
      }
    } catch (error) {
      defaultErrorAlert(error);
      logEvent('Create sample', {
        userId: id,
        catalogId,
        title: data.title,
        description: data.description,
        text: data.text,
        url: match.url,
        result: 'failed',
      });
    } finally {
      toggleCreating();
    }
  };

  const toggleModal = async () => {
    await setPageBlocked(false);
    setModalOpen(prevState => !prevState);
    unblockHandle.current();
    if (/^(blocked)/g.test(currentTab)) {
      changeTab(currentTab.split('blocked')[1]);
    } else {
      history.push(targetLink.current);
    }
  };

  return (
    <form className='catalog-page__sample-create-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='catalog-page__sample-create'>
        <div className='catalog-page__sample-create-title'>
          <Icon name='falCheckCircle' color='secondary' />
          <input
            name='title'
            placeholder={t('enterTitle')}
            maxLength={20}
            ref={register({ maxLength: 20 })}
            data-test-id='createSample.title'
          />
        </div>
        <div className='catalog-page__sample-create-description'>
          <textarea
            name='description'
            placeholder={`${t('addDescription')}...`}
            maxLength={150}
            onChange={handleResize}
            ref={register({ maxLength: 150 })}
            data-test-id='createSample.description'
          />
        </div>
        <div className='catalog-page__sample-create-container'>
          <div className={classNames('catalog-page__sample-create-text', { error: errors.text })}>
            <p>
              {t('textForRecord')} <span>*</span>
            </p>
            <textarea placeholder='' name='text' ref={register({ required: true })} data-test-id='createSample.text' />
            {errors.text && <small>{t('enterTextForRecord')}</small>}
          </div>
          <Button color='primary' type='submit' onSubmit={handleSubmit(onSubmit)} data-test-id='createSample.submit'>
            {t('create')}
          </Button>
        </div>
      </div>

      <CustomModal
        isOpen={modalOpen}
        toggle={toggleModal}
        title={`${t('saveSample', sampleTitle || t('untitled'))}?`}
        modalCard
      >
        <CustomModalDataLoss cancelButtonClick={toggleModal} actionButtonClick={handleSubmit(onSubmit)} />
      </CustomModal>
    </form>
  );
};
