import React from 'react';
import { ModalBody } from 'reactstrap';
import { t } from '../../../../localization';
import CustomModal from './CustomModal';

type PublishVoiceProps = {
  handleModalAction: () => void;
  cancelButtonClick: () => void;
};

export const CustomModalPublishVoice = ({ handleModalAction }: PublishVoiceProps) => {
  return (
    <>
      <ModalBody className='custom-modal__body'>{t('publishVoiceDesc')}</ModalBody>
      <CustomModal.Footer actionButtonTitle={t('publish')} actionButtonClick={handleModalAction} />
    </>
  );
};
