import { Button } from '@just-ai/just-ui';
import React from 'react';
import { ModalBody } from 'reactstrap';
import { ReplicasHistoryView } from '../../../../../api/dubber/client';
import { t } from '../../../../../localization';
import { HistoryContent } from '../../../../Projects/components/ProjectsSidebar/History/HistoryContent';
import './ReplicaHistory.scss';

type HistoryContentProps = {
  history: ReplicasHistoryView;
  unsavedSynth: () => void;
  isAudioLoading: boolean;
  savedSynth: (historyId: string) => void;
  historySelect: (historyId: string) => void;
  isModal: boolean;
  fetchMore: () => void;
  setAudioLoading: (value: boolean) => void;
  historyPlaying: string;
  getData: () => Promise<ReplicasHistoryView | undefined>;
};

export const CustomModalReplicaHistory = ({
  history,
  unsavedSynth,
  isAudioLoading,
  savedSynth,
  historySelect,
  isModal,
  fetchMore,
  setAudioLoading,
  historyPlaying,
  getData,
}: HistoryContentProps) => {
  return (
    <ModalBody className='custom-modal__body replica-history__modal'>
      <HistoryContent
        history={history}
        unsavedSynth={unsavedSynth}
        isAudioLoading={isAudioLoading}
        savedSynth={savedSynth}
        historySelect={historySelect}
        isModal={isModal}
        setAudioLoading={setAudioLoading}
        historyPlaying={historyPlaying}
        getData={getData}
      />
      {history.totalElements > 20 && history.history.length !== history.totalElements && (
        <Button outline color='secondary' onClick={fetchMore} className='replica-history__show-more'>
          {t('ProjectPage:History:ShowMore')}
        </Button>
      )}
    </ModalBody>
  );
};
