import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useAmplitude } from 'react-amplitude-hooks';
import { Icon, InputText } from '@just-ai/just-ui';
import { useRouteMatch } from 'react-router';

import { useAppContext } from '../../../../AppContext';
import { PlayButton } from '../../../../components/VoiceLineSynthBlock/PlayButton';
import { SynthBuffer } from '../../../Projects/model/VoiceLines';
import useDefaultAlert from '../../../../utils/useAlert';
import { t } from '../../../../localization';

type ReplicaBlockProps = {
  voiceId?: number;
  text: string;
  lineId: string;
  modelId?: number;
  playingId: string;
  handleAvatarClick: (lineId: string, voiceId?: number, modelId?: number) => void;
  handlePlayerClick: ({ lineId, phraseUrl, voiceId }: { lineId: string; phraseUrl?: string; voiceId?: number }) => void;
  handleText: ({ lineId, value }: { lineId: string; value: string }) => void;
  synthBuffer?: Omit<SynthBuffer, 'id'>;
  executeCaptcha?: () => Promise<string>;
  setSynthBuffer: ({ lineId, payload }: { lineId: string; payload: Omit<SynthBuffer, 'id'> }) => void;
};

export const ReplicaBlock = ({
  voiceId,
  text,
  lineId,
  playingId,
  handleAvatarClick,
  handlePlayerClick,
  modelId,
  handleText,
  synthBuffer,
  executeCaptcha,
  setSynthBuffer,
}: ReplicaBlockProps) => {
  const { configData, synthesizeDialog } = useAppContext();
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();
  const { defaultErrorAlert } = useDefaultAlert();
  const [loading, setLoading] = useState(false);
  const [playerDisabled, setPlayerDisabled] = useState(true);

  const avatarClickCallback = useCallback(() => {
    if (!lineId) return;
    if (playingId === lineId) handlePlayerClick({ lineId });
    handleAvatarClick(lineId, voiceId, modelId);
  }, [handleAvatarClick, handlePlayerClick, lineId, modelId, playingId, voiceId]);

  const handleTextChange = (value: string) => {
    if (playingId === lineId && lineId) handlePlayerClick({ lineId });
    if (value !== text) {
      logEvent('dialog_phrase_text_edited');
    }
    handleText({ lineId, value });
  };

  const localSynthesize = useCallback(async () => {
    let phraseUrl = '';
    if (executeCaptcha) {
      if (!voiceId || !modelId) return;
      const token = await executeCaptcha();
      phraseUrl = await synthesizeDialog(
        [
          {
            modelId,
            text,
            voiceId,
          },
        ],
        token
      );
    }
    setSynthBuffer({
      lineId,
      payload: {
        synthText: text,
        synthSpeakerId: String(voiceId),
        synthUrl: phraseUrl || '',
      },
    });
    return phraseUrl;
  }, [lineId, executeCaptcha, setSynthBuffer, voiceId, modelId, synthesizeDialog, text]);

  const handlePlayerClickLocal = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!text) return;

      if (voiceId === -1 || !lineId) return;

      if (!(!synthBuffer || synthBuffer.synthText !== text || Number(synthBuffer.synthSpeakerId) !== voiceId)) {
        return handlePlayerClick({ lineId, voiceId, phraseUrl: synthBuffer?.synthUrl });
      }

      try {
        setLoading(true);
        const phraseUrl = await localSynthesize();
        handlePlayerClick({ lineId, phraseUrl, voiceId });

        logEvent('synthesize', {
          result: 'success',
          page_type: match.path,
          page_url: match.url,
          type: 'dialog_phrase',
        });
      } catch (error) {
        logEvent('synthesize', {
          result: 'failed',
          page_type: match.path,
          page_url: match.url,
          type: 'dialog_phrase',
        });

        defaultErrorAlert(error);
      } finally {
        setLoading(false);
      }
    },
    [
      text,
      voiceId,
      lineId,
      synthBuffer,
      handlePlayerClick,
      localSynthesize,
      logEvent,
      match.path,
      match.url,
      defaultErrorAlert,
    ]
  );

  useEffect(() => {
    if (!text || !voiceId || voiceId === -1) {
      return setPlayerDisabled(true);
    }
    setPlayerDisabled(false);
  }, [voiceId, text, lineId]);
  return (
    <div className={cn('dialog-synth__speaker-block')} data-test-id={`voiceLine.wrapper-${lineId}`}>
      <div className='add-speaker-avatar' onClick={avatarClickCallback} data-test-id='voiceLine.add-speaker-landing'>
        {voiceId === -1 ? (
          <>+</>
        ) : (
          <>
            <img src={`${configData.baseAvatarPath}/voice/${voiceId}/landing/image.jpeg`} alt='avatar' />
            <div className='avatar-edit-overlay'>
              <Icon name='faPen' color='none' />
            </div>
          </>
        )}
      </div>
      <div className={cn('voice-line__state-wrapper', 'non-selectable')}>
        <>
          <InputText
            value={text}
            maxLength={100}
            onChange={handleTextChange}
            placeholder={t('enterPhrase') + '...'}
            data-test-id={`voiceLine.phraseInput-${lineId}`}
          />
        </>
      </div>
      {!!text && (
        <PlayButton
          onClick={handlePlayerClickLocal}
          isPlaying={playingId === lineId}
          isLoading={loading}
          disabled={playerDisabled}
          wasSynthed={Boolean(synthBuffer?.synthUrl)}
          id={lineId}
        />
      )}
    </div>
  );
};
