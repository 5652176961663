import { Color } from '@just-ai/just-ui/dist/Common';
import { SampleStateEnum } from '../../../api/facade/client';
import { VoiceModel } from '../model/Voice';
import { t } from '../../../localization';
export const MAX_SAMPLES_PER_PAGE = 25;

export const ALLOWED_TAGS_COUNT = 3;

export const ALLOWED_VOICES_PER_PUBLIC_PAGE = 12;
export const ALLOWED_VOICES_PER_PAGE = 6;
export const ALLOWED_LOGS_PER_PAGE = 14;
export const MAX_LOGS_PER_PAGE = 12;
export const MIN_SYNTH_TEXT_LENGTH = 1;
export const MAX_PRESETS_PER_PAGE = 10;
export const MAX_VOICE_DESCRIPTION_LEGHT = 250;

export const slackChannelURL =
  window.origin.includes('localhost') || window.origin.includes('test-ai')
    ? 'https://hooks.slack.com/services/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'
    : 'https://hooks.slack.com/services/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';

/* чтобы залогиниться в эймивоис локально, нужно запустить фронт клауда локально, 
залогиниться там, чтобы кука писалась на локалхост, таким образом на 
запущенном эймивоис на 3000 порте также будет кука сессии */

export const baseCloudPath = window.origin.includes('localhost') ? 'http://localhost:3001/c/' : `${window.origin}/c/`;

export const baseAuthPath = baseCloudPath + 'login?redirectUrl=';
export const logoutPath = baseCloudPath + `logout?redirectUrl=${window.location.origin}/catalog`;
export const confirmEmailPath = baseCloudPath + 'verify-email';

export const MAX_AVATAR_SIZE = 5e6;

export const statusesInSampleTableGenerator: (
  localization: any
) => {
  [Key in SampleStateEnum]: {
    color: 'none' | Color;
    name: string;
    text: string;
  };
} = localization => ({
  FINISHED: {
    color: 'success',
    name: 'farCheckCircle',
    text: localization.translate('processed'),
  },
  PROCESSING: {
    color: 'warning',
    name: 'falClock',
    text: localization.translate('processing') + '...',
  },
  COMMON_ERROR: {
    color: 'danger',
    name: 'falExclamationCircle',
    text: localization.translate('error'),
  },
  NO_AUDIO: {
    color: 'secondary',
    name: 'falCheckCircle',
    text: localization.translate('noAudioError'),
  },
  NOISE_ERROR: {
    color: 'danger',
    name: 'falExclamationCircle',
    text: localization.translate('noiseError'),
  },
  NOT_RECOGNIZED_ERROR: {
    color: 'danger',
    name: 'falExclamationCircle',
    text: localization.translate('notRecognizedError'),
  },
});

export const statusesInModelTableGenerator: (
  localization: any
) => {
  [Key in VoiceModel['status']]: {
    color: 'none' | Color;
    name: string;
    text: string;
    value: number;
  };
} = localization => ({
  ERROR: {
    color: 'danger',
    name: 'faTimes',
    text: localization.translate('error'),
    value: 100,
  },
  TRAINED: {
    color: 'success',
    name: 'faCheck',
    text: localization.translate('trained'),
    value: 100,
  },
  TRAINING: {
    color: 'none',
    name: '',
    text: localization.translate('training'),
    value: 0,
  },
  IN_QUEUE: {
    color: 'none',
    name: '',
    text: localization.translate('inQueue'),
    value: 0,
  },
});

export const ROLES = {
  MANAGER: { id: 2, name: 'manager' },
  SPEAKER: { id: 3, name: 'speaker' },
  ADMIN: { id: 4, name: 'administrator' },
  OWNER: { id: 5, name: 'owner' },
};

export const calendarStrings = {
  lastDay: function () {
    return `[${t('yesterday')}]`;
  },
  sameDay: function () {
    return `[${t('today')}]`;
  },
  lastWeek: 'DD.MM.YYYY',
  sameElse: 'DD.MM.YYYY',
};

export const calendarStringsWithFullMonth = {
  lastDay: function () {
    return `[${t('yesterday')}]`;
  },
  sameDay: function () {
    return `[${t('today')}]`;
  },
  sameElse: function () {
    return 'DD MMMM YYYY';
  },
};

export const emphasisRegex = /[\wёа-яЁА-Я]\+/;
export const redWordRegex = /#[\wёа-яЁА-Я|+]+/;
export const emailRegex = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const languagesMap = {
  ru_RU: 'languageRuRU',
  en_GB: 'languageEnGB',
  en_US: 'languageEnUS',
  pt_PT: 'languagePtPT',
  hi_IN: 'languageHiIN',
  es_ES: 'languageEsES',
  pt_BR: 'languagePtBR',
  fr_FR: 'languageFrFR',
  it_IT: 'languageItIT',
  de_DE: 'languageDeDE',
  zh_CN: 'languageZhCN',
};

export enum PermissionsEnum {
  VoiceCreate = 'VOICE_CREATE',
  VoiceConnect = 'VOICE_CONNECT_MODEL',
}
