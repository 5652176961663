import React from 'react';
import cn from 'classnames';

import { t } from '../../../../localization';

import styles from './Dictionary.module.scss';

export const DictionaryPlaceholder = () => {
  return (
    <div className={styles.dictionary__placeholder__wrapper}>
      <div className={styles.dictionary__placeholder__content}>
        <p className='tp-3'>{t('Dictionary:Placeholder:Text')}</p>
        <div className={styles.dictionary__placeholder__blocks}>
          <div className={styles.dictionary__placeholder__block}>
            <div className={styles.dictionary__placeholder__circle} />
            <div className={styles.dictionary__placeholder__rect} />
            <div className={styles.dictionary__placeholder__rect} />
          </div>
          <div className={styles.dictionary__placeholder__block}>
            <div className={cn(styles.dictionary__placeholder__circle, styles.red)} />
            <div className={styles.dictionary__placeholder__rect} />
            <div className={styles.dictionary__placeholder__rect} />
          </div>
        </div>
      </div>
    </div>
  );
};
