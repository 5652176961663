import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import React, { createContext, useContext, Component } from 'react';
import { SynthesizeTextRequest } from './api/supervisor/client';
import ModelService from './service/ModelService';
import {
  DedicatedServerRequest,
  EnvironmentConfig,
  LicensedVoiceRequest,
  VoiceCreationRequest,
} from './api/facade/client';
import { confirmEmailPath } from './modules/Library/constants';
import AccountService, { SlackRequestTypes } from './service/AccountService';
import { getErrorCodeFromReason, getErrorMessageFromReason } from './utils/error';
import localization from './localization';

export type ExtendedConfig = EnvironmentConfig & {
  authPath: string;
  logoutPath: string;
  baseAvatarPath: string;
  basePublicVoicePath: string;
  captcha?: {
    enabled: boolean;
    siteKey: string;
  };
};

export type AppContextType = {
  id: number;
  email?: string;
  appLoading: boolean;
  isLoggedIn: boolean;
  accountLoading: boolean;
  name?: string;
  voicesCount?: number;
  alerts: AlertNotificationItemProps[];
  configData: ExtendedConfig;
  language: string;
  productName: string | null;
  isUSDInstance: boolean | null;
  permissions: Array<string>;
  supportedVoiceLanguages: string[];
  setAccountDataFromLogin: (accData: { id: number; name?: string; email?: string }) => void;
  logoutHandler: () => Promise<void>;
  dismissAllAlerts: () => void;
  addAlert: (alert: AlertNotificationItemProps) => void;
  defaultAlert: (error: any) => void;
  successAlert: (message: string) => void;
  getUserData: () => Promise<void>;
  synthesizeDialog: (speakersArr: SynthesizeTextRequest[], captchaResponse?: string) => Promise<string>;
  synthesizePhrase: (text: string, modelId: number, voiceId: number) => Promise<string | undefined>;
  makeSlackRequest: (data: {
    type: SlackRequestTypes;
    recaptchaResponse: string;
    requestBody: DedicatedServerRequest | LicensedVoiceRequest | VoiceCreationRequest;
  }) => Promise<void>;
  getUserLanguage: (userCountryIsoCode?: string) => 'ru' | 'eng';
};

export const AppContext = createContext({} as AppContextType);

class State {
  appLoading: boolean = true;
  accountLoading: boolean = false;
  id: number = 0;
  isLoggedIn: boolean = false;
  email?: string = '';
  name?: string = '';
  language: string = '';
  alerts: AlertNotificationItemProps[] = [];
  productName: string | null = null;
  isUSDInstance: boolean | null = null;
  permissions: Array<string> = [];
  configData: ExtendedConfig = {
    hostname: window.origin,
    publicBucketUrl: '',
    basePublicVoicePath: window.origin,
    logoutPath: window.origin,
    authPath: window.origin,
    baseAvatarPath: window.origin,
    captchaKey: '',
    captchaEnabled: false,
    currency: '',
    supportedVoiceLanguages: [],
    minPrice: 0,
    dictionaryVoiceId: 0,
    dictionaryModelId: 0,
  };
}

export class AppContextProvider extends Component<{}, State> {
  accountService: AccountService = new AccountService(0);
  modelService: ModelService = new ModelService();
  state = new State();
  loadingTimeout: NodeJS.Timeout | null = null;

  async componentDidMount() {
    await Promise.all([this.getCurrentAccountData(), this.getEnvironmentConfig()]);
  }

  componentWillUnmount() {
    this.loadingTimeout && clearTimeout(this.loadingTimeout);
    this.loadingTimeout = null;
  }

  getEnvironmentConfig = async () => {
    this.setState({ appLoading: true });
    try {
      const configData = await this.accountService.getConfig();
      const hostname = window.origin.includes('localhost') ? 'http://localhost:3001' : window.location.origin;
      const baseAvatarPath = configData.publicBucketUrl;
      const basePublicVoicePath = configData.publicBucketUrl + '/voice';
      const authPath = hostname + '/c/login?redirectUrl=';
      const logoutPath = hostname + `/c/logout?redirectUrl=${window.location.origin}/catalog`;
      this.setState(
        {
          configData: {
            ...configData,
            captcha: { enabled: configData.captchaEnabled, siteKey: configData.captchaKey },
            baseAvatarPath,
            basePublicVoicePath,
            authPath,
            logoutPath,
            hostname,
          },
          productName: process.env.REACT_APP_PRODUCT_NAME || null,
          isUSDInstance: String(process.env.REACT_APP_TOVIEVOICE) === 'true' ? true : false,
        },
        () => {
          localization.setLocale(this.getUserLanguage());
          document.title = process.env.REACT_APP_TITLE!;
          let link = document.querySelector("link[rel*='icon']");
          if (!Boolean(link)) {
            link = document.createElement('link');
            // @ts-ignore
            link.type = 'image/x-icon';
            // @ts-ignore
            link.rel = 'shortcut icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }
          // @ts-ignore
          link.href = this.state.isUSDInstance ? '/img/t-voice.svg' : '/img/a-voice.svg';
        }
      );
    } catch (error) {
      console.error('Error getting config data in context');
    } finally {
      this.setState({ appLoading: false });
    }
  };

  getCurrentAccountData = async () => {
    this.setState({ accountLoading: true });

    try {
      const accountData = await this.accountService.getCurrentUser();
      if (!accountData.currentUser) return;
      return this.setState({
        id: accountData.currentUser.id,
        email: accountData.currentUser.email || '',
        name: accountData.currentUser.name || '',
        isLoggedIn: true,
        permissions: accountData.currentUser.permissions,
      });
    } catch (error) {
      console.error('Error getting account data in context');
    } finally {
      this.setState({ accountLoading: false, appLoading: false });
    }
  };

  setAccountDataFromLogin = (accData: { id: number; name?: string; email?: string }) => {
    if (!accData) return;
    return this.setState({ ...accData, accountLoading: false });
  };

  logoutHandler = async () => {
    if (!this.state.configData) {
      throw new Error('No config data present');
    }
    window.location.href = `${this.state.configData.logoutPath}`;
    this.accountService = new AccountService(0);
    localStorage.removeItem('CURRENT_USER');
    this.setState({ ...new State(), appLoading: false });
  };

  dismissAllAlerts = () => {
    this.setState({ alerts: [] });
  };

  addAlert = (alert: AlertNotificationItemProps) => this.setState({ alerts: [...this.state.alerts, alert] });

  defaultAlert = (error: Error | string) => {
    if (getErrorCodeFromReason(error) === 'email.verification.not.verified') {
      window.location.href = confirmEmailPath;
    }
    if (typeof error === 'string') {
      return this.addAlert({
        type: 'error',
        message: error,
        time: Date.now(),
        showed: true,
        temporal: true,
      });
    }
    const alertMessage = getErrorMessageFromReason(error);
    this.addAlert({
      type: 'error',
      message: alertMessage || (error as any).message,
      time: Date.now(),
      showed: true,
      temporal: true,
    });
  };

  successAlert = (message: string) => {
    this.addAlert({
      type: 'success',
      message: message,
      time: Date.now(),
      showed: true,
      temporal: true,
    });
  };

  synthesizePhrase = async (text: string, modelId: number, voiceId: number) => {
    const phraseUrl = await this.modelService.synthesize(voiceId, modelId, text);
    return phraseUrl;
  };

  synthesizeDialog = async (speakersArr: SynthesizeTextRequest[], captchaResponse?: string) => {
    const dialogUrl = await this.modelService.synthesizeDialog(speakersArr, captchaResponse);
    return dialogUrl;
  };

  makeSlackRequest = async ({
    type,
    recaptchaResponse,
    requestBody,
  }: {
    type: SlackRequestTypes;
    recaptchaResponse: string;
    requestBody: DedicatedServerRequest | LicensedVoiceRequest | VoiceCreationRequest;
  }) => {
    await this.accountService.sendSlackRequest({ type, recaptchaResponse, requestBody });
  };

  getUserLanguage = (userCountryIsoCode?: string) => {
    if (this.state.isUSDInstance) return 'eng';

    if (userCountryIsoCode) {
      switch (userCountryIsoCode.toLowerCase()) {
        case 'ru':
          return 'ru';
        case 'en':
          return 'eng';
        default:
          return 'eng';
      }
    }

    let lang = window.navigator.language.split('-')[0];

    switch (lang) {
      case 'ru': {
        return 'ru';
      }
      default:
        return 'eng';
    }
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          id: this.state.id,
          name: this.state.name,
          email: this.state.email,
          appLoading: this.state.appLoading,
          configData: this.state.configData,
          setAccountDataFromLogin: this.setAccountDataFromLogin,
          logoutHandler: this.logoutHandler,
          alerts: this.state.alerts,
          dismissAllAlerts: this.dismissAllAlerts,
          addAlert: this.addAlert,
          accountLoading: this.state.accountLoading,
          defaultAlert: this.defaultAlert,
          successAlert: this.successAlert,
          isLoggedIn: this.state.isLoggedIn,
          getUserData: this.getCurrentAccountData,
          synthesizeDialog: this.synthesizeDialog,
          synthesizePhrase: this.synthesizePhrase,
          makeSlackRequest: this.makeSlackRequest,
          getUserLanguage: this.getUserLanguage,
          productName: this.state.productName,
          isUSDInstance: this.state.isUSDInstance,
          supportedVoiceLanguages: this.state.configData.supportedVoiceLanguages,
          language: this.state.language,
          permissions: this.state.permissions,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export const useAppContext = () => useContext(AppContext);

export const withAppContext = (Component: any) => (props: any) => (
  <AppContext.Consumer>{contexts => <Component {...props} {...contexts} />}</AppContext.Consumer>
);
