import React from 'react';
import { Tabs, IconButton, Icon, Tooltip } from '@just-ai/just-ui';
import { UncontrolledTooltip } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { matchPath } from 'react-router';
import { t } from '../../../../../localization';
import { CatalogView } from '../../../../../api/facade/client';
import { CatalogHeaderLoader } from '../';
import './CatalogHeader.scss';

type CatalogHeaderProps = {
  isLoading: boolean;
  currentTab: string;
  catalogData: CatalogView | undefined;
  isEmptyCatalog: boolean;
  toggleUploadModal: () => void;
  changeTab: (value: string) => void;
};

export const CatalogHeader = ({
  isLoading,
  currentTab,
  catalogData,
  changeTab,
  toggleUploadModal,
}: CatalogHeaderProps) => {
  const { voiceId, catalogId } = useParams<{ voiceId: string; catalogId: string }>();
  const history = useHistory();

  if (!catalogData) return null;
  if (isLoading) return <CatalogHeaderLoader />;
  return (
    <div className='catalog-page__catalog-header'>
      <Tabs
        activeTab={currentTab}
        closePlace='right'
        tabs={[
          {
            name: (
              <div className='catalog-page__catalog-tab'>
                {t('notVoiced')} <span data-test-id='samplePage.notVoiceCount'>{catalogData.noAudioSamples}</span>
              </div>
            ),
            value: 'notVoiced',
          },
          {
            name: (
              <div className='catalog-page__catalog-tab'>
                {t('voiced')}{' '}
                <span data-test-id='samplePage.voicedCount'>{catalogData.samples - catalogData.noAudioSamples}</span>
                {catalogData.errorCount && <Icon name='falExclamationCircle' color='danger' />}
              </div>
            ),
            value: 'voiced',
          },
        ]}
        onChange={changeTab}
      />
      <div className='catalog-page__catalog-buttons'>
        <IconButton
          flat
          name='falInboxOut'
          size='lg'
          color='secondary'
          onClick={toggleUploadModal}
          id='uploadArchive'
        />
        <UncontrolledTooltip placement='top' target='uploadArchive' trigger='hover'>
          {t('uploadArchiveTooltip')}
        </UncontrolledTooltip>
        <IconButton
          flat
          name='falPlusSquare'
          size='lg'
          color='secondary'
          onClick={() => history.push(`/my/voice/${voiceId}/training/new_version/${catalogId}/sample/create`)}
          disabled={!!matchPath(history.location.pathname, { path: '*/create' })}
          id='createSample'
        />
        <Tooltip placement='top' target='createSample'>
          {t('createSample')}
        </Tooltip>
      </div>
    </div>
  );
};
