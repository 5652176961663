import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon, Tooltip, useToggle } from '@just-ai/just-ui';
import isNumber from 'lodash/isNumber';

import { useProjectContext } from '../../../../context/ProjectsContext';
import { PresetView } from '../../../../../../api/dubber/client';
import { t } from '../../../../../../localization';
import useDefaultAlert from '../../../../../../utils/useAlert';
import { PresetSelectMenu } from './PresetSelectMenu';
import { MAX_PRESETS_PER_PAGE } from '../../../../../Library/constants';
import { useReplicas } from '../../../../context/ProjectDataContex';
import { useSettings } from '../../../../context/ReplicaSettingsContext';
import { usePlayer } from '../../../../context/PlayerContext';

import './PresetSelect.scss';

export const PresetSelect = ({
  selectedPreset,
  setSelectedPreset,
}: {
  selectedPreset?: PresetView;
  setSelectedPreset: (value?: PresetView) => void;
}) => {
  const [isMenuOpen, , , toggleMenu] = useToggle();
  const [presets, setPresets] = useState<{ presets: PresetView[]; count: number }>(
    {} as { presets: PresetView[]; count: number }
  );
  const selectRef = useRef<HTMLDivElement>(null);
  const { getPresets, updateVoiceLine } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();
  const ref = useRef<HTMLParagraphElement>(null);
  const { getSelectedReplica, setPresetToSelectedLine, replicas, getReplicaById } = useReplicas();
  const { getSsmlText } = useSettings();
  const { deleteSynthBufferRecord } = usePlayer();

  const selectedReplica = getSelectedReplica();

  const isOverflowing = ref.current && ref.current.clientWidth < ref.current.scrollWidth;

  const getData = useCallback(
    async (page?: number, pageSize?: number) => {
      const data = await getPresets(page, pageSize);
      setPresets(data);
    },
    [getPresets]
  );

  useEffect(() => {
    if (!isMenuOpen) return;
    getData(0, MAX_PRESETS_PER_PAGE);
  }, [getData, isMenuOpen]);

  const getMoreData = async (page?: number, pageSize?: number) => {
    const data = await getPresets(page, pageSize);
    setPresets(prevState => ({ presets: [...(prevState.presets || []), ...data.presets], count: data.count }));
  };

  const handlePresetSelect = (preset: PresetView) => {
    if (!selectedReplica) return setSelectedPreset();
    const newReplicasArr = [...replicas];
    const { replicaIndex } = getReplicaById(selectedReplica.id);
    newReplicasArr[replicaIndex].markup = preset.markup;
    newReplicasArr[replicaIndex].presetId = preset.id;
    setSelectedPreset(preset);
    updateLineWithPreset(preset.id);
    deleteSynthBufferRecord(newReplicasArr[replicaIndex].id);
    setPresetToSelectedLine(preset.id);
  };

  const updateLineWithPreset = useCallback(
    async (selectedPresetId: string) => {
      if (!selectedReplica?.id || !isNumber(selectedReplica.index)) return;
      try {
        await updateVoiceLine(selectedReplica.id, {
          index: selectedReplica.index,
          text: selectedReplica.text,
          voiceId: selectedReplica.voiceId,
          modelId: selectedReplica.modelId,
          presetId: selectedPresetId,
          ssmlText: getSsmlText(selectedReplica.text) || selectedReplica.text,
        });
      } catch (error) {
        defaultErrorAlert(error);
      }
    },
    [defaultErrorAlert, getSsmlText, selectedReplica, updateVoiceLine]
  );

  const handleToggleMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      toggleMenu();
    },
    [toggleMenu]
  );

  return (
    <div className='preset-select' ref={selectRef}>
      <div
        className='preset-select__input'
        onClick={handleToggleMenu}
        id={selectedPreset ? `preset-${selectedPreset.id}` : ''}
      >
        {selectedPreset && selectedReplica?.presetId ? (
          <>
            <p className='tp-4' ref={ref}>
              {selectedPreset.name}
            </p>
            {isOverflowing && (
              <Tooltip
                placement='bottom'
                target={`preset-${selectedPreset.id}`}
                className='preset-select__tooltip'
                autohide={false}
              >
                {selectedPreset.name}
              </Tooltip>
            )}
          </>
        ) : (
          <p className='tp-4 preset-select__input--placeholder'>{t('ProjectPage:Presets:Input:Placeholder')}</p>
        )}
        <Icon name={isMenuOpen ? 'falChevronUp' : 'falChevronDown'} size='sm' />
      </div>
      {isMenuOpen && (
        <PresetSelectMenu
          toggleMenu={toggleMenu}
          isMenuOpen={isMenuOpen}
          presets={presets}
          getData={getData}
          selectedPreset={selectedPreset}
          handlePresetSelect={handlePresetSelect}
          updateVoiceLine={handlePresetSelect}
          setSelectedPreset={setSelectedPreset}
          getMoreData={getMoreData}
          selectRef={selectRef}
        />
      )}
    </div>
  );
};
