import React from 'react';
import { Icon } from '@just-ai/just-ui';

import { t } from '../../../../../localization';
import { useAppContext } from '../../../../../AppContext';

type StepTypes = {
  title: string;
  subTitle: string;
  link: string;
  number: number;
};

export const Step = ({ title, subTitle, link, number }: StepTypes) => {
  const { productName, isUSDInstance } = useAppContext();
  return (
    <div className='training-page__steps-step'>
      <h1>{number}</h1>
      <div className='training-page__steps-text'>
        <h4>{t(title)}</h4>
        <p className='tp-3'>{t(subTitle, productName)}</p>
        {link && (
          <a
            className='tp-3'
            href={`${isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}${link}`}
            rel='noreferrer'
            target='_blank'
          >
            {t('readMore')} <Icon name='falArrowRight' color='secondary' size='sm' />
          </a>
        )}
      </div>
    </div>
  );
};
