import React, { useEffect } from 'react';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { registerPreloadedIcons } from '@just-ai/just-ui/dist/Icon';
import CreateHistogram from '@just-ai/just-ui/dist/utils/histogram';
import amplitude from 'amplitude-js';
import { Amplitude, AmplitudeProvider } from 'react-amplitude-hooks';
import { Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { createBrowserHistory } from 'history';
import { AppRouter } from './routes';
import './App.scss';
import { AppContextProvider, AppContext } from './AppContext';
import { getGoogleTag } from './utils';
import GlobalAlerts from './components/GlobalAlerts';
import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

export const history = createBrowserHistory();
export const WAVEFORM_SVG_ID = 'histogramID';

const AMPLITUDE_API_KEY = window.origin.includes('test-ai')
  ? 'c0073f69dedde544ca0439585dc68afe'
  : '11f29edf5432f9d64c8f3a0873e5d637';

registerPreloadedIcons([
  'faCircleNotch',
  'faPause',
  'faPlay',
  'farPlus',
  'faPen',
  'falEllipsisH',
  'faGripVertical',
  'farPodcast',
]);

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: getGoogleTag(),
    };
    TagManager.initialize(tagManagerArgs);
    async function init() {
      await register(await connect());
    }
    init();
  }, []);
  return (
    <main className='main-page__container'>
      {CreateHistogram(WAVEFORM_SVG_ID)}
      <AppContextProvider>
        <AppContext.Consumer>
          {context => {
            if (context.appLoading) return <Spinner />;
            return window.origin.includes('localhost') ? (
              <Router history={history}>
                <AppRouter />
                <GlobalAlerts alerts={context.alerts} dismissAllAlerts={context.dismissAllAlerts} locale='ru' />
              </Router>
            ) : (
              <AmplitudeProvider
                amplitudeInstance={amplitude.getInstance()}
                apiKey={AMPLITUDE_API_KEY}
                userId={context.id ? `${context.id}` : undefined}
                config={{ includeUtm: true, saveParamsReferrerOncePerSession: false }}
              >
                <Amplitude>
                  <Router history={history}>
                    <AppRouter />
                    <GlobalAlerts alerts={context.alerts} dismissAllAlerts={context.dismissAllAlerts} locale='ru' />
                  </Router>
                </Amplitude>
              </AmplitudeProvider>
            );
          }}
        </AppContext.Consumer>
      </AppContextProvider>
    </main>
  );
}

export default App;
