import React from 'react';
import { t } from '../../../../localization';
import { Icon } from '@just-ai/just-ui';

type PurposeItemTypes = {
  purpose: number;
};

const purposeItemsObject = [
  {
    title: 'LandingPage:Purpose:VoiceContent',
    subtitle: 'LandingPage:Purpose:VoiceContentDescription',
  },
  { title: 'LandingPage:Purpose:ForCalls', subtitle: 'LandingPage:Purpose:ForCallsDescription' },
  { title: 'LandingPage:Purpose:BrandVoice', subtitle: 'LandingPage:Purpose:BrandVoiceDescription' },
];

export const PurposeItem = ({ purpose }: PurposeItemTypes) => {
  return (
    <div className='landing-page__purpose-item'>
      <div className='check'>
        <Icon name='falCheck' color='primary' size='lg' />
      </div>
      <div className='landing-page__purpose-title'>
        <h3>{t(`${purposeItemsObject[purpose].title}`)}</h3>
        <p>{t(`${purposeItemsObject[purpose].subtitle}`)}</p>
      </div>
    </div>
  );
};
