import React, { useEffect } from 'react';
import { Button } from '@just-ai/just-ui';
import { Link, useRouteMatch } from 'react-router-dom';
import { t } from '../../../../localization';
import { useMediaQuery } from 'react-responsive';
import { useAmplitude } from 'react-amplitude-hooks';
import './Errors.scss';

export const Error = ({ status }: { status?: number }) => {
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();
  const isTabletDown = useMediaQuery({ query: `(max-width: 840px)` });
  useEffect(() => {
    window.history.replaceState({}, '');
    logEvent('Page opened', { page_url: match.url, page_type: match.path });
  }, [logEvent, match.path, match.url]);
  return (
    <div className='route-error'>
      <p>{status ? t('oops') : 404}</p>
      {isTabletDown ? (
        <h3>{status ? `${t('oopsTitle')}...` : t('404Title')}</h3>
      ) : (
        <h1>{status ? `${t('oopsTitle')}...` : t('404Title')}</h1>
      )}
      <p className={isTabletDown ? 'tp-4' : 'tp-2'}>{status ? t('oopsDesc') : t('404Desc')}</p>
      <Link to='/catalog'>
        <Button color='secondary' outline>
          {t('goMain')}
        </Button>
      </Link>
    </div>
  );
};
