import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { Icon, IconButton, useToggle } from '@just-ai/just-ui';

import { VoiceAvatar } from '../../../../../Library/components/VoiceAvatar';
import { PlayButton } from '../../../../../../components/VoiceLineSynthBlock/PlayButton';
import { t } from '../../../../../../localization';
import { useVoices } from '../../../../context/VoicesContext';
import { usePlayer } from '../../../../context/PlayerContext';
type HistoryItemContentProps = {
  voiceId: number;
  text: string;
  id: string;
  handlePlayerClick: (historyId: string) => void;
  isLoading: boolean;
  downloadHistoryItem: (historyId: string) => void;
  isActual: boolean;
  selectHistory?: (historyId: string) => void;
  hasAudio?: boolean;
  isModal?: boolean;
  isUnsaved?: boolean;
  historyPlaying: string;
};

export const HistoryItemContent = ({
  voiceId,
  text,
  handlePlayerClick,
  isLoading,
  downloadHistoryItem,
  isActual,
  selectHistory,
  hasAudio,
  isModal,
  isUnsaved,
  id,
  historyPlaying,
}: HistoryItemContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, , , toggleExpand] = useToggle(false);
  const [isTextClamped, setIsTextClamped] = useState<boolean>();
  const { availableVoices } = useVoices();
  const { playingId, projectLoading } = usePlayer();
  const historyVoice = availableVoices.find(voice => voice.id === voiceId);

  const handlePlay = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      handlePlayerClick(id);
    },
    [handlePlayerClick, id]
  );

  useLayoutEffect(() => {
    if (!contentRef.current) return;

    setIsTextClamped(contentRef.current.scrollHeight > contentRef.current.clientHeight);
  }, []);

  const handleSelect = useCallback(() => {
    selectHistory && selectHistory(id);
  }, [id, selectHistory]);

  const handleDownloadClick = useCallback(() => {
    downloadHistoryItem(id);
  }, [downloadHistoryItem, id]);

  if (!id) return null;
  return (
    <div className='replica-history__item'>
      <div className='replica-history__item-avatar' id={`${isModal ? 'modal-' : ''}-history-voice-${voiceId}`}>
        <VoiceAvatar voiceId={voiceId} size='small' />
        <UncontrolledTooltip target={`${isModal ? 'modal-' : ''}-history-voice-${voiceId}`} placement='bottom'>
          {historyVoice?.name}
        </UncontrolledTooltip>
      </div>
      <div className='replica-history__item-content'>
        <div className='replica-history__item-text-container'>
          <div className={cn('replica-history__item-text tp-4', { expanded: isExpanded })} ref={contentRef}>
            {text}
          </div>
          {isTextClamped && (
            <div
              className='replica-history__item-expand'
              id={`${isModal ? 'modal-' : ''}-replica-history-expand-${id}`}
              onClick={toggleExpand}
            >
              <IconButton name={isExpanded ? 'falChevronUp' : 'falChevronDown'} />
              <UncontrolledTooltip
                target={`${isModal ? 'modal-' : ''}-replica-history-expand-${id}`}
                placement='bottom'
              >
                {isExpanded ? t('ProjectPage:History:HideText') : t('ProjectPage:History:ExpandText')}
              </UncontrolledTooltip>
            </div>
          )}
        </div>
        <div className='replica-history__item-synth'>
          <div className='replica-history__item-synth-play' id={`${isModal ? 'modal-' : ''}-play-button-${id}`}>
            <PlayButton
              id={id}
              onClick={handlePlay}
              isPlaying={isUnsaved ? playingId === id : historyPlaying === id}
              isLoading={isUnsaved ? projectLoading === id : historyPlaying === id && isLoading}
            />
            {!isModal && (hasAudio ? t('ProjectPage:History:Play') : t('ProjectPage:History:Synth'))}
            {isModal && (
              <UncontrolledTooltip target={`${isModal ? 'modal-' : ''}-play-button-${id}`} placement='bottom'>
                {hasAudio ? t('ProjectPage:History:Play') : t('ProjectPage:History:Synth')}
              </UncontrolledTooltip>
            )}
          </div>
          {!isUnsaved && (
            <>
              <Icon
                color='secondary'
                name='falArrowToBottom'
                className='replica-history__item-icons'
                id={`${isModal ? 'modal-' : ''}-download-history-${id}`}
                onClick={handleDownloadClick}
              />
              <UncontrolledTooltip target={`${isModal ? 'modal-' : ''}-download-history-${id}`} placement='bottom'>
                {t('download')}
              </UncontrolledTooltip>
            </>
          )}
          <Icon
            color={isActual ? 'success' : 'secondary'}
            name={isActual ? 'faCheckCircle' : 'falRetweet'}
            className='replica-history__item-icons'
            id={`${isModal ? 'modal-' : ''}-select-history-${id}`}
            onClick={handleSelect}
          />
          <UncontrolledTooltip target={`${isModal ? 'modal-' : ''}-select-history-${id}`} placement='bottom'>
            {isActual ? t('ProjectPage:History:ActualVersion') : t('ProjectPage:History:ChooseVersion')}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};
