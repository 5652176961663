import React from 'react';
import moment from 'moment';
import { humanizeDuration, normalizeCurrencyByCode } from '../../../../utils';
import { calendarStringsWithFullMonth } from '../../constants';
import { useAppContext } from '../../../../AppContext';

const MIN_AMOUNT = 0.01;

type TableRowProps = {
  date: Date | string;
  duration: number;
  amount?: number;
};

export default function TableRow({ date, duration, amount }: TableRowProps) {
  const { getUserLanguage, configData } = useAppContext();
  const normalizedCurrency = normalizeCurrencyByCode(configData.currency, Number(amount));
  return (
    <div className='usage__table-row' data-test-id='usagePage.tableRow'>
      <p>
        {moment(date)
          .locale(getUserLanguage() === 'ru' ? 'ru' : 'en')
          .calendar(calendarStringsWithFullMonth)}
      </p>
      <p>{humanizeDuration(duration)}</p>
      {amount && (
        <p>
          {amount >= MIN_AMOUNT
            ? normalizedCurrency
            : `< ${normalizeCurrencyByCode(configData.currency, Number(MIN_AMOUNT))}`}
        </p>
      )}
    </div>
  );
}
