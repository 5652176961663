import { useState, useEffect, RefObject } from 'react';

export const useSticky = ({
  refElement,
  treshold,
}: {
  refElement: RefObject<HTMLDivElement>;
  treshold: number;
}): boolean => {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    if (!refElement.current) return;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          setIsSticky(entry.intersectionRatio < treshold);
        });
      },

      { threshold: [treshold] }
    );

    observer.observe(refElement.current);
    const unobserve = refElement.current;

    return function () {
      if (unobserve) {
        observer.unobserve(unobserve);
        observer.disconnect();
      }
    };
  }, [refElement, treshold]);

  return isSticky;
};
