import React from 'react';
import { Icon, Button } from '@just-ai/just-ui';
import { ModelView, PublicModelView } from '../../../../api/facade/client';
import { t } from '../../../../localization';

type ConnectButtonProps = {
  connectedModel: number | undefined;
  version: ModelView | PublicModelView;
  handleClick: () => void;
  actual?: boolean;
  phone?: boolean;
};
export const ConnectButton = ({ connectedModel, version, handleClick, actual, phone }: ConnectButtonProps) => {
  if (version.id === connectedModel)
    return (
      <div className='published-card__connected'>
        <Icon name='falCheck' size='sm' />
        {t('connected')}
      </div>
    );
  if (!phone)
    return (
      <Button
        color='primary'
        outline={actual ? false : true}
        onClick={handleClick}
        data-test-id='publishedPage.connectBtn'
      >
        {t('connectThisVersion')}
      </Button>
    );
  return null;
};
