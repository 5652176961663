import React from 'react';
import { CatalogView, VoiceView } from '../../../../api/facade/client';
import Skeleton from './Skeleton';
import Placeholder from './Placeholder';
import TableRow from './TableRow';
import { CatalogTableHead } from './NewVersion';

interface Props {
  isLoading: boolean;
  notUsedCatalogs: CatalogView[];
  deletingIds: number[];
  voice: VoiceView;
  fetchCatalogs: () => Promise<void>;
  setDeletingIds: (value: number[]) => void;
}
export default function NotUsedTable({
  isLoading,
  notUsedCatalogs = [],
  fetchCatalogs,
  deletingIds,
  setDeletingIds,
  voice,
}: Props) {
  if (isLoading) return <Skeleton />;
  if (notUsedCatalogs.length < 1) return <Placeholder />;
  return (
    <div className='content__table'>
      <CatalogTableHead />
      <div className='table__body'>
        {notUsedCatalogs.map(catalog => (
          <TableRow
            catalog={catalog}
            key={catalog.id}
            fetchCatalogs={fetchCatalogs}
            setDeletingIds={setDeletingIds}
            deletingIds={deletingIds}
            voice={voice}
          />
        ))}
      </div>
    </div>
  );
}
