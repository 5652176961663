import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { ModalBody } from 'reactstrap';
import { t } from '../../../../../localization';
import CustomModal from '../CustomModal';

import WalletImg from '../../../../../img/NoMoneyImg.png';

import './BalanceModals.scss';

type NoMoneyProps = {
  cancelButtonClick: () => void;
};
export const CustomModalNoMoney = ({ cancelButtonClick }: NoMoneyProps) => {
  const history = useHistory();

  const handleModalAction = useCallback(() => {
    history.push('/account');
    cancelButtonClick();
  }, [cancelButtonClick, history]);

  return (
    <>
      <ModalBody className='custom-modal__body'>
        <div className='custom-modal__no-money'>
          <img src={WalletImg} alt='Wallet icon' />
          <p className='tp-3'>{t('Modals:NoMoney:Text')}</p>
        </div>
      </ModalBody>
      <CustomModal.Footer
        cancelButtonTitle={t('cancel')}
        actionButtonTitle={t('Modal:NoMoney:SubmitButton')}
        cancelButtonClick={cancelButtonClick!}
        actionButtonClick={handleModalAction}
      />
    </>
  );
};
