import React from 'react';
import { Icon } from '@just-ai/just-ui';
import './styles.scss';

export type Size = 'big' | 'small' | 'medium' | 'main';

export default function VoicePlaceholder({
  size,
  scale,
  dataTestId,
}: {
  size: Size;
  scale?: boolean;
  dataTestId?: string;
}) {
  return (
    <div
      data-test-id={dataTestId || 'voice.avatar.placeholder'}
      className={`voice-placeholder voice-placeholder--${size} voice-placeholder--${scale ? 'scale' : 'noscale'}`}
    >
      <Icon name='farPodcast' size={size === 'small' || size === 'medium' ? '2x' : '3x'} color='secondary' />
    </div>
  );
}
