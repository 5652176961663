import React, { useCallback } from 'react';
import { ModalBody, UncontrolledTooltip } from 'reactstrap';
import { SwitchToggle, Checkbox } from '@just-ai/just-ui';

import { t } from '../../../../../localization';
import CustomModal from '../CustomModal';
import { AllVoicesPageFilters } from '../../../pages/AllVoicesPage';
import { GenderEnum } from '../../../../../api/facade/client';

import './styles.scss';
import { LanguageFilter } from '../../AllVoicesPage/LanguageFilter';

type MobileFilterProps = {
  cancelDisabled: boolean;
  filter: AllVoicesPageFilters;
  handleFilter: (field: keyof AllVoicesPageFilters, value?: any) => void;
  handleModalAction: () => void;
  cancelButtonClick: () => void;
};

export const CustomModalMobileFilter = ({
  handleModalAction,
  cancelButtonClick,
  filter,
  handleFilter,
  cancelDisabled,
}: MobileFilterProps) => {
  const superVoiceToggle = useCallback(
    event => {
      event.preventDefault();
      handleFilter('super');
    },
    [handleFilter]
  );
  return (
    <>
      <ModalBody className='custom-modal__body mobile-filer'>
        <div className='mobile-filter__supervoice'>
          <div className='voices-catalog__side-filters__super'>
            <div>
              <p className='sp-2'>{t('supervoice')}</p>
              <div className='info-icon' id='supervoiceInfo'>
                i
              </div>
            </div>
            <UncontrolledTooltip placement='top' target='supervoiceInfo'>
              {t('supervoice tooltip')}
            </UncontrolledTooltip>
            <div>
              <SwitchToggle
                size='md'
                switchType='round'
                id='supervoiceSwitch'
                color='primary'
                value={filter.super}
                onClick={superVoiceToggle}
              />
            </div>
          </div>
        </div>
        <div className='mobile-filter__gender'>
          <div className='voices-catalog__side-filters__gender'>
            <p>{t('voiceGender')}</p>
            <Checkbox
              name='male'
              color='primary'
              value={filter.gender.includes(GenderEnum.MALE)}
              label={t('male')}
              onChange={() => handleFilter('gender', GenderEnum.MALE)}
              data-test-id='allVoicesPage.maleFilter'
            />
            <Checkbox
              name='female'
              color='primary'
              value={filter.gender.includes(GenderEnum.FEMALE)}
              label={t('female')}
              onChange={() => handleFilter('gender', GenderEnum.FEMALE)}
              data-test-id='allVoicesPage.femaleFilter'
            />
          </div>
        </div>
        <div className='mobile-filter__language'>
          <LanguageFilter filter={filter} handleFilter={handleFilter} mobile />
        </div>
      </ModalBody>
      <CustomModal.Footer
        cancelButtonTitle={t('reset')}
        actionButtonTitle={t('apply')}
        cancelButtonClick={cancelButtonClick!}
        actionButtonClick={handleModalAction}
        cancelDisabled={cancelDisabled}
      />
    </>
  );
};
