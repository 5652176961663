import React from 'react';
import { InputTextFullUncontrolled } from '@just-ai/just-ui';

type PhoneInputProps = {
  placeholder: string;
  name: string;
  size?: string;
};

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>((props, ref) => {
  const getInputNumbersValue = function (input: EventTarget) {
    return (input as HTMLInputElement).value.replace(/\D/g, '');
  };

  const onPhonePaste = function (event: React.ClipboardEvent<HTMLInputElement>) {
    const input = event.currentTarget;
    const inputNumbersValue = getInputNumbersValue(input);
    const pasted = event.clipboardData;
    if (pasted) {
      const pastedText = pasted.getData('Text');
      if (/\D/g.test(pastedText)) {
        input.value = inputNumbersValue;
        return;
      }
    }
  };

  const onPhoneInput = function (event: React.FormEvent<HTMLInputElement>) {
    const input = event.currentTarget;
    let inputNumbersValue = getInputNumbersValue(input);
    let formattedInputValue = '';

    if (!inputNumbersValue) {
      return (input.value = '');
    }

    if (['7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
      if (inputNumbersValue[0] === '9') inputNumbersValue = '7' + inputNumbersValue;
      const firstSymbols = inputNumbersValue[0] === '8' ? '8' : '+7';
      formattedInputValue = input.value = firstSymbols + ' ';
      if (inputNumbersValue.length > 1) {
        formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
      }
      if (inputNumbersValue.length >= 8) {
        formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
      }
      if (inputNumbersValue.length >= 10) {
        formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
      }
    } else {
      formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
    }
    input.value = formattedInputValue;
  };
  var onPhoneKeyDown = function (event: React.KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value.replace(/\D/g, '');
    if (event.keyCode === 8 && inputValue.length === 1) {
      (event.target as HTMLInputElement).value = '';
    }
  };
  return (
    <InputTextFullUncontrolled
      placeholder={props.placeholder}
      maxLength={18}
      innerRef={ref}
      name={props.name}
      onKeyDown={onPhoneKeyDown}
      onInput={onPhoneInput}
      onPaste={onPhonePaste}
      size={props.size}
    />
  );
});
