import { useState, useCallback, useEffect, useRef } from "react";
export function useForceUpdate() {
    const [, setValue] = useState(true);
    return () => setValue(value => !value);
}
export const normalize = (s) => s ? s.toUpperCase().trim() : '';
export const toggleElementInArray = (array, element) => {
    const newArray = array ? [...array] : [];
    if (newArray.includes(element)) {
        newArray.splice(newArray.indexOf(element), 1);
    }
    else {
        newArray.push(element);
    }
    return newArray;
};
export const useClientRect = (node) => {
    const [rect, setRect] = useState(null);
    /*console.log(node);
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }*/
    useCallback(node => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, []);
    return [rect];
};
const normalizeRangeValue = (initialValue, minValue, maxValue) => typeof initialValue === 'number'
    ? initialValue
    : typeof minValue === 'number'
        ? minValue
        : typeof maxValue === 'number'
            ? maxValue
            : 0;
export const useRange = (initialValue, minValue, maxValue) => {
    const [value, setValue] = useState(() => normalizeRangeValue(initialValue, minValue, maxValue));
    return [
        value,
        (newValue) => {
            if (newValue === value)
                return;
            if (typeof minValue === 'number' && newValue < minValue) {
                setValue(minValue);
            }
            else if (typeof maxValue === 'number' && newValue > maxValue) {
                setValue(maxValue);
            }
            else {
                setValue(newValue);
            }
        }
    ];
};
export const useToggle = (initialValue = false) => {
    const [value, setValue] = useState(initialValue);
    const SetTrueCallback = useCallback(() => {
        setValue(true);
    }, []);
    const SetFalseCallback = useCallback(() => {
        setValue(false);
    }, []);
    const ToggleCallback = useCallback(() => {
        setValue(value => !value);
    }, []);
    return [
        value,
        SetTrueCallback,
        SetFalseCallback,
        ToggleCallback
    ];
};
export const getPosX = (event) => {
    if (event instanceof MouseEvent) {
        return event.pageX || event.clientX;
    }
    else {
        return event.touches[0].pageX || event.touches[0].clientX;
    }
};
export const getPosY = (event) => {
    if (event instanceof MouseEvent) {
        return event.pageY || event.clientY;
    }
    else {
        return event.touches[0].pageY || event.touches[0].clientY;
    }
};
export const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, handler]);
};
export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}
export function prettyJson(value) {
    return JSON.stringify(value, null, 2);
}
export const cancelDefaults = (event) => {
    event.preventDefault();
    event.stopPropagation();
};
