import React, { createContext, useContext } from 'react';
import { Crop } from 'react-image-crop';
import { MAX_AVATAR_SIZE } from '../constants';
import { t } from '../../../localization';

type MobileFileChoiceType = {
  event: React.ChangeEvent<HTMLInputElement>;
  inputRef: React.RefObject<HTMLInputElement>;
  voiceId: string;
  defaultErrorAlert: (error: string) => void;
  openModal: () => void;
};

type AvatarContextType = {
  avatar: string;
  voiceIdForAvatar: string;
  croppedImage: string;
  image: string;
  step: number;
  crop: Crop;
  completedCrop: Crop;
  isLoading: boolean;
  reset: () => void;
  setImage: (image: string, voiceId: string) => void;
  setStep: (step: number) => void;
  setIsLoading: (isLoading: boolean) => void;
  setCroppedImage: (value: string) => void;
  setCompletedCrop: (value: Crop) => void;
  setCrop: (value: Crop) => void;
  setAvatar: (value: string) => void;
  handleTabletFileChoice: ({ event, inputRef, voiceId, defaultErrorAlert }: MobileFileChoiceType) => void;
};

const cropSettings: Crop = {
  unit: '%',
  width: 50,
  x: 25,
  y: 25,
  aspect: 1 / 1,
};

class State {
  avatar = '';
  croppedImage = '';
  voiceIdForAvatar = '';
  image = '';
  step = 1;
  crop = cropSettings;
  completedCrop = {} as Crop;
  isLoading = true;
}

const AvatarContext = createContext<AvatarContextType>({} as AvatarContextType);

export class AvatarContextProvider extends React.Component<{}, State> {
  state = new State();

  reset = () => this.setState({ image: '', step: 1, crop: cropSettings, croppedImage: '' });

  setImage = (image: string, voiceId: string) => this.setState({ image: image, voiceIdForAvatar: voiceId });

  setStep = (value: number) => this.setState({ step: value });

  setIsLoading = (value: boolean) => this.setState({ isLoading: value });

  setCroppedImage = (value: string) => this.setState({ croppedImage: value });

  setAvatar = (value: string) => this.setState({ avatar: value });

  setCompletedCrop = (value: Crop) => this.setState({ completedCrop: value });

  setCrop = (value: Crop) => this.setState({ crop: value });

  handleTabletFileChoice = ({ event, inputRef, voiceId, defaultErrorAlert, openModal }: MobileFileChoiceType) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!(file.type === 'image/jpeg' || file.type === 'image/png') || file.size > MAX_AVATAR_SIZE) {
        inputRef.current!.value = '';
        return defaultErrorAlert(
          `${t('errorLoading')}. ${file.size > MAX_AVATAR_SIZE ? t('avatarSizeError') : t('avatarTypeError')}`
        );
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);

      const setImageReader = () => {
        this.setImage(reader.result as string, voiceId);
        this.setStep(2);
        openModal();
        reader.removeEventListener('load', setImageReader);
      };

      reader.addEventListener('load', setImageReader);
    }
  };

  render() {
    return (
      <AvatarContext.Provider
        value={{
          avatar: this.state.avatar,
          voiceIdForAvatar: this.state.voiceIdForAvatar,
          croppedImage: this.state.croppedImage,
          image: this.state.image,
          step: this.state.step,
          crop: this.state.crop,
          completedCrop: this.state.completedCrop,
          isLoading: this.state.isLoading,
          reset: this.reset,
          setImage: this.setImage,
          setStep: this.setStep,
          setIsLoading: this.setIsLoading,
          setCroppedImage: this.setCroppedImage,
          setCompletedCrop: this.setCompletedCrop,
          setCrop: this.setCrop,
          setAvatar: this.setAvatar,
          handleTabletFileChoice: this.handleTabletFileChoice,
        }}
      >
        {this.props.children}
      </AvatarContext.Provider>
    );
  }
}

export const useAvatarContext = () => useContext(AvatarContext);
