import { Icon, IconButton, useToggle } from '@just-ai/just-ui';
import React, { useCallback } from 'react';
import { PhraseDictionaryView } from '../../../../api/dubber/client';
import useDefaultAlert from '../../../../utils/useAlert';
import { useProjectContext } from '../../../Projects/context/ProjectsContext';
import { ChangeCreateForm } from './ChangeCreateForm';

import styles from './Dictionary.module.scss';

type DictionaryItemProps = {
  dictionary: PhraseDictionaryView;
  getData: () => void;
  synthReq: (text: string, id: string) => Promise<void>;
  playingId: string;
};

export const DictionaryItem = React.memo(({ dictionary, getData, synthReq, playingId }: DictionaryItemProps) => {
  const [isEditMode, , , toggleEditMode] = useToggle(false);
  const { defaultErrorAlert } = useDefaultAlert();
  const { deletePhraseDictionary } = useProjectContext();

  const handleDelete = useCallback(async () => {
    try {
      await deletePhraseDictionary(dictionary.id);
      getData();
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [defaultErrorAlert, deletePhraseDictionary, dictionary.id, getData]);

  return (
    <div className={styles.dictionary__item}>
      {isEditMode ? (
        <ChangeCreateForm
          getDictionarys={getData}
          editMode
          dictionary={dictionary}
          cancelButtonHandle={toggleEditMode}
          handleDeleteButton={handleDelete}
          synthReq={synthReq}
          playingId={playingId}
          getData={getData}
        />
      ) : (
        <div className={styles.dictionary__view}>
          <div className={styles.dictionary__view__word}>
            <p className='tp-4'>{dictionary.original}</p>
            <Icon name='farArrowRight' color='secondary' size='sm' />
            <p className='tp-4'>{dictionary.replacement}</p>
          </div>
          <div className={styles.dictionary__view__buttons}>
            <IconButton name='farPen' onClick={toggleEditMode} size='sm' />
            <IconButton name='falTrashAlt' size='sm' onClick={handleDelete} />
          </div>
        </div>
      )}
    </div>
  );
});
