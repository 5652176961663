import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button } from '@just-ai/just-ui';
import cloneDeep from 'lodash/cloneDeep';
import { useParams } from 'react-router';

import { useToggle } from '../../../../../utils/hooks';
import { CustomModal, CustomModalReplicaHistory } from '../../../../Library/components/CustomModal';
import { useProjectContext } from '../../../context/ProjectsContext';
import useDefaultAlert from '../../../../../utils/useAlert';
import { ReplicasHistoryView } from '../../../../../api/dubber/client';
import { t } from '../../../../../localization';
import { HistoryContent } from './HistoryContent';
import { usePlayer } from '../../../context/PlayerContext';
import { useReplicas } from '../../../context/ProjectDataContex';
import { NEW_LINE_ID } from '../../VoiceLinesBlock/VoiceLinesBlock';
import { MAX_VOICE_LINES_PER_PAGE } from '../../../constants';

import './ReplicaHistory.scss';

export const ReplicaHistory = () => {
  const [isModalOpen, , , toggleModal] = useToggle();
  const [history, setHistory] = useState<ReplicasHistoryView>();
  const [playingHistory, setPlayingHistoryId] = useState<string>('');
  const {
    getHistory,
    synthesizeReplicaFromhistory,
    selectVersion,
    synthesizeReplica,
    getVoiceLines,
  } = useProjectContext();
  const {
    playerRef,
    setPlayerStore,
    deleteSynthBufferRecord,
    playingId,
    historyPlaying,
    setReplicaSynthBuffer,
  } = usePlayer();
  const {
    getSelectedReplica,
    updateHistory,
    setReplicaStore,
    replicas,
    selectedReplicaId,
    updateReplica,
  } = useReplicas();
  const { projectId } = useParams<{ projectId: string }>();
  const historyPlayerRef = useRef<HTMLAudioElement>(null);

  const { defaultErrorAlert } = useDefaultAlert();
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const selectedReplica = getSelectedReplica();

  const fetchHistory = useCallback(async () => {
    if (!selectedReplica) return;
    if (selectedReplica.id.includes(NEW_LINE_ID)) return;
    try {
      const history = await getHistory(selectedReplica.id, 0, 20);
      setHistory(history);
      return history;
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [defaultErrorAlert, getHistory, selectedReplica]);

  useEffect(() => {
    if (!historyPlaying) {
      setPlayingHistoryId('');
      historyPlayerRef.current?.pause();
    }
  }, [historyPlaying]);

  useEffect(() => {
    if (!updateHistory) return;
    fetchHistory();
    setReplicaStore({ updateHistory: false });
  }, [fetchHistory, setReplicaStore, updateHistory]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory, selectedReplicaId]);

  const updateHistoryObject = useCallback(
    (id: string) => {
      const newHistoryObj = cloneDeep(history) as ReplicasHistoryView;
      const newHistory = [...(newHistoryObj?.history || [])];
      const newItemIndex = newHistory.findIndex(item => item.id === id);
      newItemIndex > -1 && newHistory.splice(newItemIndex, 1, { ...newHistory[newItemIndex], hasAudio: true });
      newHistoryObj.history = newHistory;
      setHistory(newHistoryObj);
    },
    [history]
  );

  const handlePlayButton = useCallback(
    async (id: string, unsaved?: boolean) => {
      if (!playerRef || !playerRef.current || !historyPlayerRef.current) return;

      if (!historyPlayerRef.current.paused) {
        playingHistory && setPlayingHistoryId('');
        historyPlayerRef.current?.pause();
        return;
      }
      try {
        playingId && setPlayerStore({ playingId: '' });
        let audiorUrl = '';

        if (unsaved) {
          setPlayerStore({ projectLoading: id });
          audiorUrl = await synthesizeReplica(id);
          setReplicaSynthBuffer({ id, url: audiorUrl });
          setPlayerStore({ projectLoading: '' });
          updateReplica(id);
          playerRef.current.src = audiorUrl;
          setPlayerStore({ playingId: id, playingMode: 'SINGLE' });
          await fetchHistory();
        } else {
          setPlayingHistoryId(id);
          setIsAudioLoading(true);
          audiorUrl = await synthesizeReplicaFromhistory(id);
          setIsAudioLoading(false);
          setPlayerStore({ historyPlaying: true });
          historyPlayerRef.current.src = audiorUrl;
          historyPlayerRef.current.play();
        }

        if (audiorUrl && !unsaved && history?.history) {
          updateHistoryObject(id);
        }
      } catch (error) {
        defaultErrorAlert(error);
        setPlayerStore({ playingId: '', projectLoading: '' });
        setPlayingHistoryId('');
        setIsAudioLoading(false);
      }
    },
    [
      defaultErrorAlert,
      fetchHistory,
      history?.history,
      playerRef,
      playingHistory,
      playingId,
      setPlayerStore,
      setReplicaSynthBuffer,
      synthesizeReplica,
      synthesizeReplicaFromhistory,
      updateHistoryObject,
      updateReplica,
    ]
  );

  const handleHistorySelect = useCallback(
    async (historyId: string) => {
      try {
        await selectVersion(historyId);
        const newPageSize = Math.ceil(replicas.length / MAX_VOICE_LINES_PER_PAGE);
        const newReplicas = await getVoiceLines({
          projectId,
          page: 0,
          pageSize: newPageSize * MAX_VOICE_LINES_PER_PAGE,
        });
        setReplicaStore({
          replicas: newReplicas.replicas,
          maxLinesReached: !newReplicas.replicas.length || newReplicas.replicas.length < MAX_VOICE_LINES_PER_PAGE,
        });
        deleteSynthBufferRecord(selectedReplicaId);
        if (isModalOpen) {
          toggleModal();
        }
      } catch (error) {
        defaultErrorAlert(error);
      }
    },
    [
      defaultErrorAlert,
      deleteSynthBufferRecord,
      getVoiceLines,
      isModalOpen,
      projectId,
      replicas.length,
      selectVersion,
      selectedReplicaId,
      setReplicaStore,
      toggleModal,
    ]
  );

  const synthUnsavedHistory = useCallback(async () => {
    if (!selectedReplica) return;
    handlePlayButton(selectedReplica.id, true);
  }, [handlePlayButton, selectedReplica]);

  const fetchMore = useCallback(async () => {
    if (!selectedReplica) return;

    try {
      const newHistory = await getHistory(selectedReplica.id, 0, (history?.history.length || 0) + 10);
      setHistory(newHistory);
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [defaultErrorAlert, getHistory, history?.history.length, selectedReplica]);

  const clearAudio = useCallback(() => {
    setPlayingHistoryId('');
  }, []);
  if (!history) return null;
  return (
    <>
      <audio ref={historyPlayerRef} controls={false} hidden onEnded={clearAudio} />
      <HistoryContent
        history={history}
        unsavedSynth={synthUnsavedHistory}
        isAudioLoading={isAudioLoading}
        savedSynth={handlePlayButton}
        historySelect={handleHistorySelect}
        setAudioLoading={setIsAudioLoading}
        historyPlaying={playingHistory}
        getData={fetchHistory}
      />
      {history.totalElements > 10 && (
        <Button outline color='secondary' onClick={toggleModal} className='replica-history__show-more'>
          {t('ProjectPage:History:ShowMore')}
        </Button>
      )}
      <CustomModal
        toggle={toggleModal}
        isOpen={isModalOpen}
        title={t('ProjectPage:History:ModalTitle')}
        customClassName='replica-history__modal'
      >
        <CustomModalReplicaHistory
          history={history}
          unsavedSynth={synthUnsavedHistory}
          isAudioLoading={isAudioLoading}
          savedSynth={handlePlayButton}
          historySelect={handleHistorySelect}
          isModal
          fetchMore={fetchMore}
          setAudioLoading={setIsAudioLoading}
          historyPlaying={playingHistory}
          getData={fetchHistory}
        />
      </CustomModal>
    </>
  );
};
