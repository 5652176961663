import React from 'react';
import classNames from 'classnames';
import { Button, Icon, Tooltip } from '@just-ai/just-ui';
import { t } from '../../localization';

type PlayButtonProps = {
  isPlaying: boolean;
  isLoading: boolean;
  wasSynthed?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  id: string;
  textOverflow?: boolean;
};

export const PlayButton = ({
  isPlaying,
  isLoading,
  wasSynthed,
  onClick,
  id,
  disabled,
  textOverflow,
}: PlayButtonProps) => {
  return (
    <div id={`playButton-${id}`} className='play-button__wrapper'>
      <Button
        className={classNames('play-button justui_icon-button btn btn-primary btn-square btn-lg', {
          playing: isPlaying,
          spinner: isLoading,
          'was-synthed': wasSynthed,
        })}
        onClick={onClick}
        disabled={disabled}
        data-test-id={`voiceLine.playButton-${id}`}
      >
        <Icon
          name={isLoading ? 'faCircleNotch' : isPlaying ? 'faPause' : 'faPlay'}
          color='primary'
          className={classNames('play-button', { playing: isPlaying, spinner: isLoading })}
        />
      </Button>
      {textOverflow && (
        <Tooltip
          target={`playButton-${id}`}
          placement='top'
          align='center'
          textAlign='center'
          modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
        >
          {t('ProjectPage:Replica:Lenght:Error')}
        </Tooltip>
      )}
    </div>
  );
};
