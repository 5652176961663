import React from 'react';
import { ModelView, PublicModelView } from '../../../../api/facade/client';
import { t } from '../../../../localization';
import PublishedCard from './PublishedCard';
import { VoiceConsumerView } from '../../../../api/facade/client';

type VersionsListProps = {
  versions: ModelView[] | PublicModelView[];
  fetchData: (open?: boolean) => void;
  tokenConsumerData: VoiceConsumerView | undefined;
};

export default function VersionsList({ versions, fetchData, tokenConsumerData }: VersionsListProps) {
  const actual = versions.find(version => version.isActual);
  return (
    <>
      <div className='published__actual'>
        <PublishedCard version={actual!} connectedModel={tokenConsumerData?.modelId} fetchData={fetchData} />
      </div>
      {versions.length > 1 && (
        <div className='published__not-actual'>
          <h4>{t('prevVoiceVersions')}</h4>
          {versions
            .filter(version => !version.isActual)
            .map(version => (
              <PublishedCard
                version={version}
                key={version.id}
                connectedModel={tokenConsumerData?.modelId}
                fetchData={fetchData}
              />
            ))}
        </div>
      )}
    </>
  );
}
