import React from 'react';
import { t } from '../../../../localization';

export default function Placeholder() {
  return (
    <div className='content__empty' data-test-id='trainingPage.catalogPlaceholder'>
      <h3 className='st-1'>{t('emptyCatalogs')}</h3>
      <p>{t('emptyCatalogsSub')}</p>
      <svg width='150' height='135' xmlns='http://www.w3.org/2000/svg' className='arrow'>
        <defs>
          <marker
            id='arrowhead'
            markerWidth='25'
            markerHeight='16'
            refX='23'
            refY='8'
            orient='auto'
            fill='none'
            stroke='var(--gray-300)'
          >
            <polyline points='0 0, 25 8, 0 16' />
          </marker>
        </defs>
        <path
          d='M0 90 Q100 90, 150 30, 150 90'
          stroke='var(--gray-300)'
          fill='transparent'
          markerEnd='url(#arrowhead)'
        />
      </svg>
    </div>
  );
}
