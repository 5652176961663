import React from 'react';
import { Icon } from '@just-ai/just-ui';
import { SampleView } from '../../../../../api/facade/client';
import classNames from 'classnames';
import { statusesInSampleTableGenerator } from '../../../constants';
import localization, { t } from '../../../../../localization';
import { NavLink, useParams } from 'react-router-dom';
import { generateSampleRoute, removeSpecials } from '../../../../../utils';

type CatalogRowType = {
  sample: SampleView;
  style: React.CSSProperties;
  onClick: () => void;
};

export const CatalogRowMemo = React.memo(({ sample, style, onClick }: CatalogRowType) => {
  const { voiceId, catalogId, sampleId } = useParams<{ voiceId: string; catalogId: string; sampleId: string }>();
  const statusesInSampleTable = statusesInSampleTableGenerator(localization);

  if (!sample) return null;
  return (
    <div style={style} onClick={onClick}>
      <NavLink to={generateSampleRoute(+voiceId, +catalogId, sample.id)}>
        <div
          className={classNames('catalog-page__catalog-row', { active: +sampleId === sample.id })}
          data-test-id='samplePage.sampleRow'
        >
          <Icon
            name={statusesInSampleTable[sample.sampleState].name}
            color={statusesInSampleTable[sample.sampleState].color}
            data-test-id='catalogPage.catalogRowStatus'
          />
          <div className='catalog-page__catalog-row-text'>
            <p>{sample.title || t('untitled')}</p>
            <p>/</p>
            <p>{removeSpecials(String(sample.text))}</p>
          </div>
        </div>
      </NavLink>
    </div>
  );
});
