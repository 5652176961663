import React from 'react';
import { t } from '../../localization';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE } from '../Header/constants';
import { useAppContext } from '../../AppContext';

import './style.scss';

export default function Footer() {
  const { isUSDInstance } = useAppContext();
  const location = useLocation<Location>();
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });

  const darkTheme = location.pathname === '/';
  return (
    <footer className={classNames('footer', { dark: darkTheme })}>
      <div className='footer__container'>
        <a
          className='footer__text-muted'
          href={`${isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}/use-voice/use-voice`}
          target='_blank'
          rel='noreferrer'
        >
          {t('doc.useVoice')}
        </a>
        <a
          className='footer__text-muted'
          href={`${isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}/payments`}
          target='_blank'
          rel='noreferrer'
        >
          {t('doc.payment')}
        </a>
      </div>
      <div className='footer__container'>
        <p className='footer__text-muted'>
          {t(isUSDInstance ? 'copyrightUSD' : 'copyright', new Date().getFullYear())}
        </p>
        <div>
          <a
            href={isUSDInstance ? t('termsOfUseLinkUSD') : t('termsOfUseLink')}
            target='_blank'
            rel='noreferrer'
            className='footer__text-muted'
          >
            {t('termsOfUse')}
          </a>
          {!isPhone && <p className='footer__text-muted'>&#183;</p>}
          <a
            href={isUSDInstance ? t('privacyPolicyLinkUSD') : t('privacyPolicyLink')}
            target='_blank'
            rel='noreferrer'
            className='footer__text-muted'
          >
            {t('privacyPolicy')}
          </a>
        </div>
      </div>
    </footer>
  );
}
