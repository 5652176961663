import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputGroup, InputText, useToggle } from '@just-ai/just-ui';
import debounce from 'lodash/debounce';

import { ChangeCreateForm } from './ChangeCreateForm';
import { PhraseDictionaryView } from '../../../../api/dubber/client';
import { DictionaryItem } from './DictionaryItem';
import { useProjectContext } from '../../../Projects/context/ProjectsContext';
import useDefaultAlert from '../../../../utils/useAlert';
import { useAppContext } from '../../../../AppContext';
import { useAccountContext } from '../../../Header/context/AccountContext';
import { DictionaryPlaceholder } from './DictionaryPlaceholde';

import styles from './Dictionary.module.scss';
import { t } from '../../../../localization';

export const Dictionary = () => {
  const [data, setData] = useState<PhraseDictionaryView[]>([]);
  const [playingId, setPlayingId] = useState<string>('');
  const [searchedItems, setSearchedItems] = useState<PhraseDictionaryView[]>([]);
  const [searchActive, setSearchActive, setSearchNonActive] = useToggle(false);

  const audioRef = useRef<HTMLAudioElement>(null);
  const { defaultErrorAlert } = useDefaultAlert();
  const { getPhraseDictionarys } = useProjectContext();
  const { synthesizePhrase, configData } = useAppContext();
  const { updateWallet } = useAccountContext();

  const getData = useCallback(async () => {
    try {
      const data = await getPhraseDictionarys();
      setData(data);
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [defaultErrorAlert, getPhraseDictionarys]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSynth = useCallback(
    async (text: string, id: string) => {
      if (!audioRef.current) return;
      if (playingId === id) {
        audioRef.current.pause();
        setPlayingId('');
        return;
      }
      try {
        const stringUrl = await synthesizePhrase(text, configData.dictionaryModelId, configData.dictionaryVoiceId);
        updateWallet();
        setPlayingId(id);
        audioRef.current.src = stringUrl || '';
        audioRef.current.play();
      } catch (error) {
        defaultErrorAlert(error);
      }
    },
    [
      configData.dictionaryModelId,
      configData.dictionaryVoiceId,
      defaultErrorAlert,
      playingId,
      synthesizePhrase,
      updateWallet,
    ]
  );

  const onEnd = useCallback(() => {
    if (!audioRef.current) return;
    audioRef.current.removeAttribute('src');
    setPlayingId('');
  }, []);

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (searchValue.length > 0) {
        setSearchActive();
        const searched = data?.filter(
          (dictionaryItem: PhraseDictionaryView) =>
            dictionaryItem.original.toLowerCase().includes(searchValue.toLowerCase()) ||
            dictionaryItem.replacement.toLowerCase().includes(searchValue.toLowerCase())
        );
        setSearchedItems(searched);
      } else {
        setSearchNonActive();
        setSearchedItems([]);
      }
    },
    [data, setSearchActive, setSearchNonActive]
  );

  const debouncedSearch = debounce(handleSearch, 500);

  const getContent = useCallback(() => {
    if ((searchActive && searchedItems.length === 0) || (!searchActive && data.length === 0)) {
      return <DictionaryPlaceholder />;
    }
    if (searchActive && searchedItems.length > 0) {
      searchedItems.map(dictionary => (
        <DictionaryItem
          dictionary={dictionary}
          key={dictionary.id}
          getData={getData}
          synthReq={handleSynth}
          playingId={playingId}
        />
      ));
    }
    return data.map(dictionary => (
      <DictionaryItem
        dictionary={dictionary}
        key={dictionary.id}
        getData={getData}
        synthReq={handleSynth}
        playingId={playingId}
      />
    ));
  }, [data, getData, handleSynth, playingId, searchActive, searchedItems]);

  return (
    <div className={styles.dictionary}>
      <audio ref={audioRef} onEnded={onEnd} />
      <h2 className='mb-6'>{t('Dictionary:Title')}</h2>
      <p className='tp-3 mb-4'>{t('Dictionary:SubTitle')}</p>
      <ChangeCreateForm getDictionarys={getData} synthReq={handleSynth} playingId={playingId} getData={getData} />
      <div className={styles.dictionary__search}>
        <InputGroup
          PrependInner={[
            {
              color: 'secondary',
              name: 'farSearch',
              className: styles.dictionary__search__icon,
            },
          ]}
          size='sm'
        >
          <InputText placeholder={t('Dictionary:Search:Placeholder')} size='sm' onChange={debouncedSearch} />
        </InputGroup>
      </div>
      <div className={styles.dictionary__list}>{getContent()}</div>
    </div>
  );
};
