import React from 'react';
import { Button } from '@just-ai/just-ui';
import { useMediaQuery } from 'react-responsive';

import { t } from '../../../../../localization';
import { SCREEN_WIDTH_TABLET } from '../../../../Header/constants';
import { useAppContext } from '../../../../../AppContext';

import './ProjectsPlaceholder.scss';

export const ProjectsPlaceholder = ({
  type,
  createEmptyProject,
}: {
  type: 'projects' | 'landing';
  createEmptyProject: () => void;
}) => {
  const { productName } = useAppContext();
  const tabletDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  return (
    <div className={`my-page__projects-placeholder placeholder ${type}`} data-test-id='project.placeholder'>
      <div className='placeholder__img-block'>
        <img
          src={
            type === 'landing' ? 'img/MyPage/landingProjectPlaceholder.png' : 'img/MyPage/myPageProjectsPlaceholder.png'
          }
          alt='projects placeholder'
        />
      </div>
      <div className='placeholder__text-block'>
        {type === 'landing' ? (
          <h1>{t('LandingPage:Project:Title', productName)}</h1>
        ) : (
          <h1>
            {t('projectPlaceholderTitleLanding1')}
            <br />
            <span className='orange'>{t('projectPlaceholderTitleLanding2').toLowerCase()}</span>
            <br /> {t('projectPlaceholderTitleLanding3', productName)}
          </h1>
        )}
        {type === 'landing' && <p className='tp-2'>{t('LandingPage:Project:SubTitle')}</p>}
        {!tabletDown && (
          <Button onClick={createEmptyProject} color='primary' data-test-id='project.createProject'>
            {t('voiceText')}
          </Button>
        )}
      </div>
      {tabletDown && (
        <Button onClick={createEmptyProject} color='primary' data-test-id='project.createProject'>
          {t('voiceText')}
        </Button>
      )}
    </div>
  );
};
