import React from 'react';

import { ReplicaHistoryView } from '../../../../../../api/dubber/client';
import { HistoryItemContent } from './HistoryItemContent';

type ReplicaHistoryItemProps = {
  history: ReplicaHistoryView;
  handlePlayerClick: (historyId: string) => void;
  isLoading: boolean;
  downloadHistoryItem: (historyId: string) => void;
  isActual: boolean;
  selectHistory: (historyId: string) => void;
  isModal?: boolean;
  historyPlaying: string;
};

export const ReplicaHistoryItem = ({
  history,
  handlePlayerClick,
  isLoading,
  downloadHistoryItem,
  isActual,
  selectHistory,
  isModal,
  historyPlaying,
}: ReplicaHistoryItemProps) => {
  if (!history) return null;
  return (
    <HistoryItemContent
      text={history.replica.text}
      voiceId={history.replica.voiceId}
      id={history.id}
      handlePlayerClick={handlePlayerClick}
      isLoading={isLoading}
      downloadHistoryItem={downloadHistoryItem}
      isActual={isActual}
      hasAudio={history.hasAudio}
      selectHistory={selectHistory}
      isModal={isModal}
      historyPlaying={historyPlaying}
    />
  );
};
