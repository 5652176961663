import React, { useEffect, useRef } from 'react';
import { IconButton, Button } from '@just-ai/just-ui';
import { Link, useLocation } from 'react-router-dom';
import { t } from '../../../../localization';
import AVLogo from '../../../../img/logos/AV-logo.svg';
import TovieLogo from '../../../../img/logos/tovie-logo.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { useAppContext } from '../../../../AppContext';

export default function SidebarMenu({
  isOpen,
  toggle,
  isLoggedIn,
  handleLogin,
  username,
  handleLogout,
  closeMenu,
}: {
  isOpen: boolean;
  toggle: () => void;
  isLoggedIn: boolean;
  handleLogin: () => void;
  username: string;
  handleLogout: () => Promise<void>;
  closeMenu: () => void;
}) {
  const location = useLocation();
  const prevLocation = useRef(location.pathname);
  const { isUSDInstance, productName, name, email } = useAppContext();

  useEffect(() => {
    if (prevLocation.current !== location.pathname) {
      closeMenu();
    }
  }, [closeMenu, location.pathname]);

  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <>
          <div className='sidebar-menu user-menu' data-test-id='userMenu.sidebar'>
            <div className='sidebar-menu__header'>
              <IconButton name='falTimes' onClick={toggle} flat size='sm' data-test-id='sidebar.close' />
              <div className='sidebar-menu__logo'>
                <img src={isUSDInstance ? TovieLogo : AVLogo} alt={`${productName} logo`} />
              </div>
            </div>
            <div className={`sidebar-menu__content ${isLoggedIn ? 'logged-in' : 'not-logged'}`}>
              {isLoggedIn ? (
                <>
                  <div className='sidebar-menu__account'>
                    {name && <p className='st-1'>{name}</p>}
                    <p className='tp-3'>{email}</p>
                  </div>
                  <div className='sidebar-menu__nav logged-in'>
                    <Link to='/my' data-test-id='userMenu.myVoices'>
                      <p className='tp-3'>{t('Header:Tabs:MyProjects')}</p>
                    </Link>
                    <Link to='/catalog'>
                      <p className='tp-3'>{t('catalog')}</p>
                    </Link>
                    <Link to='/account'>
                      <p className='tp-3'>{t('Header:UserMenu:Account')}</p>
                    </Link>
                    <a href={isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')} target='_blank' rel='noreferrer'>
                      <p className='tp-3'>{t('help')}</p>
                    </a>
                    <p onClick={handleLogout} className='tp-3 grey-text-2' data-test-id='userMenu.logout'>
                      {t('logout')}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className='login-button-block'>
                    <Button onClick={handleLogin} color='secondary' size='lg' flat data-test-id='userMenu.login'>
                      {t('loginButtonCaption')}
                    </Button>
                  </div>
                  <div className='sidebar-menu__nav'>
                    <Link to='/catalog' className='tp-3' data-test-id='userMenu.catalog'>
                      {t('catalog')}
                    </Link>
                    <a
                      href={isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}
                      className='tp-3'
                      rel='noreferrer'
                      target='_blank'
                    >
                      {t('help')}
                    </a>
                  </div>
                </>
              )}
              <div className='sidebar-menu__footer'>
                <a
                  href={isUSDInstance ? t('termsOfUseLinkUSD') : t('termsOfUseLink')}
                  target='_blank'
                  rel='noreferrer'
                  className='tp-5 grey-text-0 privacy-policy'
                >
                  {t('termsOfUse')}
                </a>
                <a
                  href={isUSDInstance ? t('privacyPolicyLinkUSD') : t('privacyPolicyLink')}
                  target='_blank'
                  rel='noreferrer'
                  className='tp-5 grey-text-0 privacy-policy'
                >
                  {t('privacyPolicy')}
                </a>
              </div>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className='modal-backdrop'
            onClick={toggle}
            key='modal'
          />
        </>
      )}
    </AnimatePresence>
  );
}
