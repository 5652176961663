import React, { useState, useCallback, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Icon, DropdownButton, DropdownToggle, DropdownMenu, DropdownItem, useToggle } from '@just-ai/just-ui';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { AnimatePresence } from 'framer-motion';
import { useAmplitude } from 'react-amplitude-hooks';

import { t } from '../../../../localization';
import { useProjectContext } from '../../../Projects/context/ProjectsContext';
import useDefaultAlert from '../../../../utils/useAlert';
import { ProjectView } from '../../../../api/dubber/client';
import { ProjectInputs, ProjectDelete } from '../../../Library/components/MyPage';
import { SCREEN_WIDTH_PHONE } from '../../constants';
import { DynamicTitle } from '../../../Library/components/DynamicTitle/DynamicTitle';
import { useAppContext } from '../../../../AppContext';

import './ProjectHeader.scss';

export const ProjectHeader = () => {
  const [project, setProject] = useState<ProjectView>();
  const [isEditing, setIsEditing] = useState(false);
  const { getProject } = useProjectContext();
  const [isModalOpen, , , toggleModal] = useToggle();
  const phoneOnly = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const { logEvent } = useAmplitude();
  const { productName } = useAppContext();
  const history = useHistory();

  const { defaultErrorAlert } = useDefaultAlert();

  const getProjectId = useCallback(() => {
    const pathnameArr = history.location.pathname.split('/');
    return pathnameArr[pathnameArr.length - 1];
  }, [history.location.pathname]);

  const getProjectData = useCallback(async () => {
    try {
      const projectData = await getProject(getProjectId());
      setProject(projectData);
      return projectData.id;
    } catch (error) {
      defaultErrorAlert(error);
      history.push('/my');
    }
  }, [defaultErrorAlert, getProject, getProjectId, history]);

  const toggleEditing = useCallback(() => {
    setIsEditing(prevState => !prevState);
  }, []);

  useEffect(() => {
    if (!getProjectId()) return history.push('/my');
    const id = getProjectData();
    logEvent('project opened', {
      project_id: id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectData, getProjectId, history]);

  if (!project) return null;
  return (
    <AnimatePresence exitBeforeEnter>
      <DynamicTitle title={t('documentTitle.projectPage', project.name, productName)} />
      {isEditing ? (
        <ProjectInputs
          project={project}
          type='header'
          key='projectInput'
          toggleEditing={toggleEditing}
          getData={getProjectData}
        />
      ) : (
        <>
          <Breadcrumb className='project__header-breadcrumbs'>
            {!phoneOnly && (
              <BreadcrumbItem>
                <p className='tp-3'>{t('project')}</p>
              </BreadcrumbItem>
            )}
            <BreadcrumbItem onClick={toggleEditing}>
              <p className='tp-3' data-test-id='project.projectName'>
                {project?.name}
              </p>
            </BreadcrumbItem>
          </Breadcrumb>
          <DropdownButton direction='down' className='project__dropdown'>
            <DropdownToggle compact data-test-id='project.dropdown'>
              <Icon name='farChevronDown' size='sm' />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={toggleEditing} data-test-id='project.renameProject'>
                {t('rename')}
              </DropdownItem>
              <DropdownItem onClick={toggleModal} data-test-id='project.deleteProject'>
                {t('delete')}
              </DropdownItem>
            </DropdownMenu>
          </DropdownButton>
          <ProjectDelete project={project} isModalOpen={isModalOpen} toggleModal={toggleModal} />
        </>
      )}
    </AnimatePresence>
  );
};
