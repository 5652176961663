import React, { useCallback, useState } from 'react';
import { ModalBody } from 'reactstrap';
import { IconButton, InputText } from '@just-ai/just-ui';

import { useToggle } from '../../../../../utils/hooks';
import { PresetView } from '../../../../../api/dubber/client';
import { useProjectContext } from '../../../../Projects/context/ProjectsContext';
import useDefaultAlert from '../../../../../utils/useAlert';

import './PresetsManagement.scss';
import { useReplicas } from '../../../../Projects/context/ProjectDataContex';
import { useParams } from 'react-router';

export const CustomModalPresetsManagement = ({
  presets,
  getData,
  setSelectedPreset,
  selectedPreset,
}: {
  presets: PresetView[];
  selectedPreset?: PresetView;
  getData: () => void;
  setSelectedPreset: (value?: PresetView) => void;
}) => {
  return (
    <>
      <ModalBody className='custom-modal__body'>
        {presets.map(preset => (
          <ManagementItem
            preset={preset}
            key={preset.id}
            getData={getData}
            setSelectedPreset={setSelectedPreset}
            selectedPreset={selectedPreset}
          />
        ))}
      </ModalBody>
    </>
  );
};

const ManagementItem = ({
  preset,
  getData,
  setSelectedPreset,
  selectedPreset,
}: {
  preset: PresetView;
  selectedPreset?: PresetView;
  getData: () => void;
  setSelectedPreset: (value?: PresetView) => void;
}) => {
  const [isEditing, , , toggleEdit] = useToggle();
  const [name, setName] = useState(preset.name);
  const { updatePreset, deletePreset, getVoiceLines } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();
  const { projectId } = useParams<{ projectId: string }>();
  const { setReplicaStore, replicas } = useReplicas();

  const handlePresetNameChange = useCallback(
    (value: string) => {
      setName(value);
    },
    [setName]
  );

  const deletePresetReq = useCallback(async () => {
    try {
      await deletePreset(preset.id);
      getData();
      const newReplicas = await getVoiceLines({ projectId, page: 0, pageSize: replicas.length });
      setReplicaStore({ replicas: newReplicas.replicas });
      if (preset.id === selectedPreset?.id) {
        setSelectedPreset();
      }
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [
    deletePreset,
    preset.id,
    getData,
    selectedPreset?.id,
    getVoiceLines,
    projectId,
    replicas.length,
    setReplicaStore,
    setSelectedPreset,
    defaultErrorAlert,
  ]);

  const updatePresetReq = useCallback(async () => {
    try {
      await updatePreset(preset.id, name);
      toggleEdit();
      getData();
      if (preset.id === selectedPreset?.id) {
        setSelectedPreset({ ...preset, name });
      }
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [updatePreset, preset, name, toggleEdit, getData, selectedPreset?.id, setSelectedPreset, defaultErrorAlert]);

  const handleUpdatePreset = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      updatePresetReq();
    },
    [updatePresetReq]
  );

  const handleCloseEditing = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setName(preset.name);
      toggleEdit();
    },
    [preset, setName, toggleEdit]
  );

  const handleDeletePreset = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      deletePresetReq();
    },
    [deletePresetReq]
  );

  const handleToggleEditing = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      toggleEdit();
    },
    [toggleEdit]
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      event.stopPropagation();
      if (event.key === 'Enter') {
        event.preventDefault();
        updatePresetReq();
      }
      if (event.key === 'Escape') {
        toggleEdit();
      }
    },
    [toggleEdit, updatePresetReq]
  );

  return (
    <div className='preset-management__item'>
      {isEditing ? (
        <div className='preset-management__item--editing'>
          <InputText value={name} onChange={handlePresetNameChange} onKeyDown={handleKeyPress} autoFocus />
          <div className='preset-management__item-buttons'>
            <IconButton name='falCheck' onClick={handleUpdatePreset} />
            <IconButton name='falTimes' onClick={handleCloseEditing} />
          </div>
        </div>
      ) : (
        <div className='preset-management__item--initial'>
          <p className='tp-4'>{preset.name}</p>
          <div className='preset-management__item-buttons'>
            <IconButton name='farPen' onClick={handleToggleEditing} />
            <IconButton name='falTrashAlt' onClick={handleDeletePreset} />
          </div>
        </div>
      )}
    </div>
  );
};
