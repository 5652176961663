import React, { useCallback, useState, useEffect } from 'react';
import { ModalBody } from 'reactstrap';
import CustomModal from '../CustomModal';
import { t } from '../../../../../localization';
import { ModifyVoiceMember, VoiceMemberView } from '../../../../../api/facade/client';
import { RadioButton } from '@just-ai/just-ui';
import { ROLES } from '../../../constants';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE } from '../../../../Header/constants';
import './styles.scss';

type MobileManagerMenu = {
  data: VoiceMemberView;
  handleModalAction: (value: ModifyVoiceMember & { oldRole: number }) => Promise<void>;
  nestedModalAction: (value: VoiceMemberView) => Promise<void>;
};

export const CustomModalMobileManagerMenu = ({ data, handleModalAction, nestedModalAction }: MobileManagerMenu) => {
  const [targetRole, setTargetRole] = useState<number>(0);
  const [oldRole, setOldRole] = useState<number>(0);
  const [nestedModal, setNestedModal] = useState(false);
  const { accountId, login, role } = data || {};
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });

  useEffect(() => {
    setOldRole(role?.id);
  }, [role?.id]);

  const handleChangeRole = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setTargetRole(+e.target.value), []);

  const actionButtonClick = useCallback(async () => {
    try {
      await handleModalAction({
        accountId: accountId,
        login: login,
        toRole: targetRole,
        oldRole: oldRole,
      });
    } catch (error) {
      console.error('Error deleting voice members', error);
    }
  }, [handleModalAction, accountId, login, targetRole, oldRole]);

  const handleRemoveManager = useCallback(async () => {
    try {
      await nestedModalAction(data);
    } catch (error) {
      console.error('Error deleting voice members', error);
    }
  }, [nestedModalAction, data]);

  const toggleNestedModal = useCallback(() => {
    setNestedModal(prevState => !prevState);
  }, []);
  if (!data) return null;
  return (
    <>
      <ModalBody className='mobile-managers__body'>
        <div className='custom-modal__radiogroup' onChange={handleChangeRole} data-test-id='editManager.radioGroup'>
          <RadioButton
            value={String(ROLES.ADMIN.id)}
            name='modal'
            label={t(`managerRole: ${ROLES.ADMIN.name}`)}
            checked={(targetRole || oldRole) === ROLES.ADMIN.id}
          />
          <p className={isPhone ? 'tp-4' : 'tp-3'}>{t('managerRole: AdministratorDesc')}</p>
          <RadioButton
            value={String(ROLES.MANAGER.id)}
            name='modal'
            label={t(`managerRole: ${ROLES.MANAGER.name}`)}
            checked={(targetRole || oldRole) === ROLES.MANAGER.id}
          />
          <p className={isPhone ? 'tp-4' : 'tp-3'}>{t('managerRole: ManagerDesc')}</p>
          <RadioButton
            value={String(ROLES.SPEAKER.id)}
            name='modal'
            label={t(`managerRole: ${ROLES.SPEAKER.name}`)}
            checked={(targetRole || oldRole) === ROLES.SPEAKER.id}
          />
          <p className={isPhone ? 'tp-4' : 'tp-3'}>{t('managerRole: SpeakerDesc')}</p>
        </div>
      </ModalBody>
      <CustomModal
        isOpen={Boolean(nestedModal)}
        toggle={isPhone ? undefined : toggleNestedModal}
        title={`${t(`VoicePage:MobileControl:RemoveManager`)}?`}
        customClassName='mobile-managers__delete'
        position='center'
      >
        <CustomModal.Footer
          cancelButtonTitle={t('cancel')}
          actionButtonTitle={t('delete')}
          actionButtonClick={handleRemoveManager}
          cancelButtonClick={toggleNestedModal}
        />
      </CustomModal>
      <CustomModal.Footer
        cancelButtonTitle={t('VoicePage:MobileControl:RemoveManager')}
        actionButtonTitle={t('save')}
        actionButtonClick={actionButtonClick}
        cancelButtonClick={toggleNestedModal}
        disabled={!targetRole}
      />
    </>
  );
};
