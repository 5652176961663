import React, { useState, useCallback, useEffect } from 'react';
import { Icon } from '@just-ai/just-ui';
import { UncontrolledTooltip } from 'reactstrap';
import { ModifyVoiceMember, VoiceMemberView, VoiceView } from '../../../../../api/facade/client';
import { t } from '../../../../../localization';
import {
  CustomModal,
  CustomModalEditManager,
  CustomModalRemoveManager,
  CustomModalMobileManagerMenu,
} from '../../CustomModal';
import useAlert from '../../../../../utils/useAlert';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_TABLET } from '../../../../Header/constants';
import { convertNameToPlaceholder } from '../../../../../utils';
import { useRouteMatch } from 'react-router';
import { useAmplitude } from 'react-amplitude-hooks';
import { useAppContext } from '../../../../../AppContext';
import { getRoleNameFromId } from '../../../../../utils';

type MembersControlsTypes = {
  member: VoiceMemberView;
  tabletModal: VoiceMemberView;
  voice: VoiceView;
  updateMember: (value: ModifyVoiceMember & { oldRole: number }) => void;
  removeMember: (value: VoiceMemberView) => void;
  setTabletModal: (value: VoiceMemberView) => void;
};

export const MembersControlsMemo = React.memo(function MembersControls({
  member,
  tabletModal,
  updateMember,
  removeMember,
  setTabletModal,
  voice,
}: MembersControlsTypes) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState({} as VoiceMemberView & { type: 'EditManager' | 'RemoveManager' });
  const { defaultErrorAlert } = useAlert();
  const match = useRouteMatch();
  const { logEvent } = useAmplitude();
  const { id } = useAppContext();
  const isTablet = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });

  const handleModalCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (tabletModal.accountId === member.accountId) {
      setIsOpen(true);
    }
  }, [member.accountId, tabletModal]);

  useEffect(() => {
    if (!isOpen) {
      setTabletModal({} as VoiceMemberView);
    }
  }, [isOpen, setTabletModal]);

  const handleModalOpen = useCallback(
    (type: 'EditManager' | 'RemoveManager') => {
      setModalOpen({ type: type, ...member });
      setIsOpen(true);
    },
    [member]
  );

  const handleMemberUpdate = useCallback(
    async (memberData?: ModifyVoiceMember & { oldRole: number }) => {
      if (!memberData || !memberData.toRole) return;
      const logEventObj = {
        page_url: match.url,
        member_role: voice.currentVoiceMemberRole.name,
        old_updated_member_role: getRoleNameFromId(memberData.oldRole || 0),
        new_updated_member_role: getRoleNameFromId(memberData.toRole),
        user_id: id,
        result: '',
      };
      try {
        await updateMember(memberData);
        logEventObj.result = 'success';
        logEvent('Voice member role updated', logEventObj);
        setIsOpen(false);
      } catch (error) {
        logEventObj.result = 'failed';
        logEvent('Voice member role updated', logEventObj);
        defaultErrorAlert(error);
      }
    },
    [defaultErrorAlert, id, logEvent, match.url, updateMember, voice.currentVoiceMemberRole.name]
  );

  const handleMemberRemove = useCallback(
    async (memberData?: VoiceMemberView) => {
      if (!memberData) return;
      const logEventObj = {
        page_url: match.url,
        result: '',
        member_role: voice.currentVoiceMemberRole.name,
        deleted_member_role: member.role.name,
        user_id: id,
      };
      try {
        await removeMember(memberData);

        logEventObj.result = 'success';
        logEvent('Voice member deleted', logEventObj);
        setIsOpen(false);
      } catch (error) {
        logEventObj.result = 'failed';
        logEvent('Voice member deleted', logEventObj);

        defaultErrorAlert(error);
      }
    },
    [defaultErrorAlert, id, logEvent, match.url, member.role.name, removeMember, voice.currentVoiceMemberRole.name]
  );
  return (
    <>
      <div className='managers__icons'>
        {isTablet ? (
          <Icon name='falChevronRight' />
        ) : (
          <>
            <UncontrolledTooltip trigger='hover' placement='top' target='managers-editing'>
              {t('edit')}
            </UncontrolledTooltip>
            <Icon
              id='managers-editing'
              name='faCog'
              size='sm'
              onClick={() => handleModalOpen('EditManager')}
              data-test-id='controlPage.editManager'
            />
            <UncontrolledTooltip trigger='hover' placement='top' target='manager-remove'>
              {t('manager: Remove')}
            </UncontrolledTooltip>
            <Icon
              id='manager-remove'
              name='faTimes'
              size='sm'
              onClick={() => handleModalOpen('RemoveManager')}
              data-test-id='controlPage.deleteManager'
            />
          </>
        )}
      </div>

      <MediaQuery maxWidth={SCREEN_WIDTH_TABLET}>
        {match =>
          match ? (
            <CustomModal
              isOpen={Boolean(isOpen)}
              title={
                <div className='mobile-managers__header'>
                  <div className='mobile-managers__close' onClick={handleModalCancel}>
                    <Icon name='falArrowLeft' />
                  </div>
                  <div className='mobile-managers__voice-placeholder tp-4'>
                    {member.accountName && convertNameToPlaceholder(member.accountName)}
                  </div>
                  <p>{member.accountName && member.accountName}</p>
                </div>
              }
              fullscreen={isTablet}
              customClassName='mobile-managers'
            >
              <CustomModalMobileManagerMenu
                data={tabletModal}
                handleModalAction={handleMemberUpdate}
                nestedModalAction={handleMemberRemove}
              />
            </CustomModal>
          ) : (
            <CustomModal
              isOpen={Boolean(isOpen)}
              toggle={handleModalCancel}
              title={t(`VoicePage:Control:${modalOpen.type}`)}
              modalCard={modalOpen?.type === 'RemoveManager'}
            >
              {modalOpen?.type === 'EditManager' ? (
                <CustomModalEditManager
                  data={modalOpen}
                  cancelButtonClick={handleModalCancel}
                  handleModalAction={handleMemberUpdate}
                />
              ) : (
                <CustomModalRemoveManager
                  data={modalOpen}
                  cancelButtonClick={handleModalCancel}
                  handleModalAction={handleMemberRemove}
                />
              )}
            </CustomModal>
          )
        }
      </MediaQuery>
    </>
  );
});
