import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProgressBar } from '@just-ai/just-ui/dist/AudioPlayer/ProgressBar';
import '@just-ai/just-ui/dist/AudioPlayer/AudioPlayer.scss';
import { WAVEFORM_SVG_ID } from '../../../../App';
import { Icon } from '@just-ai/just-ui';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE } from '../../../Header/constants';
import classNames from 'classnames';

type SimplePlayerProps = {
  audioUrl: string;
  playing: boolean;
  handlePlayer: ({ event, playing }: { event: React.SyntheticEvent; playing: boolean }) => void;
  noProgress?: boolean;
};

export default function SimpleHystogramPlayer(props: SimplePlayerProps) {
  const audioElemRef = useRef<HTMLAudioElement>();
  const [isLoading, setIsLoading] = useState(false);
  const { playing, audioUrl, handlePlayer, noProgress } = props;
  const phone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });

  useEffect(() => {
    return () => {
      if (audioElemRef.current) {
        audioElemRef.current.pause();
        audioElemRef.current.currentTime = 0;
      }
    };
  }, []);

  useEffect(() => {
    if (playing && audioElemRef.current?.paused) {
      audioElemRef.current?.play();
      return;
    } else {
      audioElemRef.current?.pause();
    }
  }, [playing]);

  const handlePlayerLocal = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!audioElemRef.current) {
        setIsLoading(true);
        const audio = new Audio(`${audioUrl}?${Date.now()}`);
        audio.load();
        audio.oncanplaythrough = () => {
          handlePlayer({ event: e, playing: true });
          setIsLoading(false);
        };
        audio.onended = () => handlePlayer({ event: e, playing: false });
        audioElemRef.current = audio;
      } else {
        handlePlayer({ event: e, playing: playing ? false : true });
      }
    },
    [audioUrl, handlePlayer, playing]
  );
  return (
    <div
      onClick={e => e.stopPropagation()}
      className='voice-card__player player__container player__container--catalog justui-audio-player'
    >
      <div onClick={handlePlayerLocal} className='play-icon-container'>
        <Icon
          className={classNames({ 'fa-spin': isLoading })}
          color='primary'
          name={isLoading ? 'faCircleNotch' : playing ? 'faPause' : 'faPlay'}
          size='sm'
        />
      </div>
      {!phone && !noProgress && (
        <ProgressBar
          histogram={true}
          histogramId={WAVEFORM_SVG_ID}
          audio={audioElemRef.current as HTMLAudioElement}
          progressUpdateInterval={10}
          ShowFilledProgress
        />
      )}
    </div>
  );
}
