import React, { useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useRouteMatch } from 'react-router';
import { useToggle, Icon, Button } from '@just-ai/just-ui';
import { useAmplitude } from 'react-amplitude-hooks';

import { VoiceAvatar } from '../../components/VoiceAvatar';
import TokenInput from '../../components/PublicVoice/TokenInput';
import { SCREEN_WIDTH_PHONE } from '../../../Header/constants';
import { t } from '../../../../localization';
import { PublishedVoiceViewWithUsedVersion } from '../../../../api/facade/client';
import SimpleHystogramPlayer from '../../components/HystoPlayer';
import { useAppContext } from '../../../../AppContext';
import { useProjectContext } from '../../../Projects/context/ProjectsContext';
import useDefaultAlert from '../../../../utils/useAlert';
import { normalizeCurrencyByCode } from '../../../../utils';

type VoiceCardProps = {
  voice: PublishedVoiceViewWithUsedVersion & { price: string };
  fetchPublicVoice: () => void;
};
export const PublicVoicePageCard = ({ voice, fetchPublicVoice }: VoiceCardProps) => {
  const [playing, , , togglePlay] = useToggle();
  const [tokenCopied, setTokenCopied] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const history = useHistory();
  const { isLoggedIn, configData, productName } = useAppContext();
  const { getProjects } = useProjectContext();
  const { createProject } = useProjectContext();
  const observerRef = useRef<HTMLDivElement>(null);
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();
  const { defaultErrorAlert } = useDefaultAlert();

  const pushToLogin = useCallback(
    (type: 'token' | 'project') => {
      if (!isLoggedIn) {
        window.location.href = `${configData?.authPath}${window.location.origin}${history.location.pathname}?${type}`;
      }
    },
    [configData, history.location.pathname, isLoggedIn]
  );
  const fetchProjectCount = useCallback(async () => {
    try {
      const { count } = await getProjects(0, 1);
      return count;
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [defaultErrorAlert, getProjects]);

  const handleProjectClick = useCallback(async () => {
    if (!isLoggedIn) pushToLogin('project');
    const projectsCount = await fetchProjectCount();
    if (!projectsCount) {
      try {
        const createdProject = await createProject(t('untitled'));
        logEvent('project created', {
          project_id: createdProject.id,
          result: 'success',
        });
        history.push(`/my/project/${createdProject.id}`);
      } catch (error) {
        defaultErrorAlert(error);
        logEvent('project created', {
          result: 'failed',
        });
      }
    }
    if (projectsCount && projectsCount > 0) {
      history.push('/my');
    }
  }, [createProject, defaultErrorAlert, fetchProjectCount, history, isLoggedIn, logEvent, pushToLogin]);

  const togglePlayerWithLogEvent = ({ event, playing }: { event: React.SyntheticEvent; playing: boolean }) => {
    if (event.type !== 'ended') {
      logEvent('voice_sample_audio', {
        page_type: match.path,
        page_url: match.url,
        action: playing ? 'pause' : 'play',
        type: 'public voice page',
        voice_id: voice.id,
      });
    }
    togglePlay();
  };
  return (
    <>
      <div className={classNames('public-voice__column')} ref={observerRef}>
        <div className='public-voice__voice'>
          <div className='public-voice__photo'>
            <VoiceAvatar size='main' voiceId={voice.id} />
            <div className='public-voice__player'>
              <SimpleHystogramPlayer
                audioUrl={`${configData.basePublicVoicePath}/${voice.id}/sample/audio.wav`}
                playing={playing}
                handlePlayer={togglePlayerWithLogEvent}
                noProgress
              />
            </div>
          </div>
          <h4 className='public-voice__name'>{voice.name}</h4>

          {voice.accessRestricted ? (
            <div className='voice-card__license-block'>
              <p>
                <Icon name='faStar' color='warning' size='sm' />
                {t('licensed')}
              </p>
              <p className='grey-text-3 tp-4'>{t('licensedInfo')}</p>
            </div>
          ) : (
            <div className='public-voice__price'>
              <h3>{normalizeCurrencyByCode(configData.currency, Number(voice.price))} </h3>
              <p>{`/ ${t('minute')}`}</p>
            </div>
          )}
        </div>
        {!voice.accessRestricted && (
          <Button className='public-voice__project-button' onClick={handleProjectClick}>
            <div>
              <p>{t('publicPage:project', productName)}</p>
              <p>{t('publicPage:projectSub')}</p>
            </div>
            <Icon name='falArrowRight' size='md' />
          </Button>
        )}
        <div className='public-voice__token-container'>
          <TokenInput
            token={token}
            setToken={setToken}
            pushToLogin={pushToLogin}
            voice={voice}
            fetchVoice={fetchPublicVoice}
            tokenCopied={tokenCopied}
            setTokenCopied={setTokenCopied}
            restricted={voice.accessRestricted}
            isPhone={isPhone}
            handleProjectClick={handleProjectClick}
          />
        </div>
      </div>
    </>
  );
};
