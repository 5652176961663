import React, { useCallback, useEffect, useRef } from 'react';
import { Button, IconButton, InputText, useToggle } from '@just-ai/just-ui';
import { useAmplitude } from 'react-amplitude-hooks';
import { useLocation, useRouteMatch } from 'react-router';

import { useLibraryContext } from '../../context/LibraryContext';
import { PublishedVoiceViewWithUsedVersion } from '../../../../api/facade/client';
import useAlert from '../../../../utils/useAlert';
import { t } from '../../../../localization';
import { CustomModal } from '../CustomModal';
import { RequestModal } from '../CustomModal/RequestModal';
import { useAppContext } from '../../../../AppContext';

type TokenInputProps = {
  voice: PublishedVoiceViewWithUsedVersion;
  token: string | null;
  tokenCopied: boolean;
  pushToLogin: (type: 'token' | 'project') => void;
  fetchVoice: () => void;
  setToken: (value: string | null) => void;
  setTokenCopied: (value: boolean) => void;
  handleProjectClick: () => void;
  restricted?: boolean;
  isPhone?: boolean;
};

const TokenInput = ({
  pushToLogin,
  voice,
  fetchVoice,
  token,
  setToken,
  tokenCopied,
  setTokenCopied,
  restricted,
  isPhone,
  handleProjectClick,
}: TokenInputProps) => {
  const { email, isLoggedIn, isUSDInstance } = useAppContext();
  const { createToken, getToken } = useLibraryContext();
  const [requestModal, , , toggleRequestModal] = useToggle();
  const { defaultErrorAlert } = useAlert();
  const location = useLocation();
  const copyRef = useRef<HTMLInputElement>(null);
  const outsideRef = useRef<HTMLDivElement>(null);
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();

  const togglePopupOpen = useCallback(async () => {
    pushToLogin('token');
    setTokenCopied(false);
    try {
      if (token) return setToken(null);
      if (!voice.usedModel) {
        const generatedToken = await createToken(Number(voice.id));
        setToken(generatedToken);

        fetchVoice();
      } else {
        const generatedToken = await getToken(Number(voice.id));
        setToken(generatedToken);
      }
      logEvent('get_token', {
        page_type: match.path,
        page_url: match.url,
        type: 'public voice page',
        voice_id: voice.id,
      });
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [
    createToken,
    defaultErrorAlert,
    fetchVoice,
    getToken,
    logEvent,
    match,
    pushToLogin,
    setToken,
    setTokenCopied,
    token,
    voice,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (isLoggedIn && params.has('token')) {
      togglePopupOpen();
      window.history.replaceState({}, document.title, window.location.href.slice(0, window.location.href.indexOf('?')));
    }
    if (isLoggedIn && params.has('project')) {
      handleProjectClick();
    }
  }, [handleProjectClick, isLoggedIn, location.search, togglePopupOpen]);

  const handleCopyToken = useCallback(() => {
    if (!(copyRef && copyRef.current)) return;
    navigator.clipboard.writeText(copyRef.current.value);
    setTokenCopied(true);
  }, [setTokenCopied]);

  const handleOutsideClick = useCallback(
    event => {
      if (!token) {
        return;
      }
      if (outsideRef.current && !outsideRef.current.contains(event.target)) {
        setToken(null);
      }
    },
    [setToken, token]
  );

  useEffect(() => {
    if (token) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick, token]);

  if (restricted)
    return (
      <div>
        <Button color='primary' size={isPhone ? 'lg' : 'xl'} onClick={toggleRequestModal}>
          {t('requestAccess')}
        </Button>
        <CustomModal
          customClassName='success-request-info'
          isOpen={requestModal}
          toggle={toggleRequestModal}
          position='center'
          modalCard
        >
          <RequestModal voice={voice} emailPlaceholder={email} handleModalAction={toggleRequestModal} />
        </CustomModal>
      </div>
    );

  return (
    <div ref={outsideRef}>
      <Button color='primary' size='lg' iconRight='falChevronDown' onClick={togglePopupOpen} data-test-id='tokenButton'>
        <div>
          <p>{t('connectAPI')}</p>
          <p>{t('publicPage:connectSub')}</p>
        </div>
      </Button>
      {token && (
        <div className='public-voice__popup'>
          <p>{t('yourToken')}</p>
          <div className='public-voice__token-wrapper'>
            <div className='public-voice__token'>
              <InputText innerRef={copyRef} readOnly value={token} data-test-id='tokenInput' />
              {tokenCopied ? (
                <IconButton color='success' name='faCheck' />
              ) : (
                <IconButton color='primary' name='farCopy' onClick={handleCopyToken} />
              )}
            </div>
            <a
              href={`${isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}/use-voice/request/`}
              target='_blank'
              className='public-voice__popup-doc tp-4'
              rel='noreferrer'
            >
              {t('doc.connectToApi')}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(TokenInput);
