import React from 'react';
import { ModalBody } from 'reactstrap';
import CustomModal from './CustomModal';
import { t } from '../../../../localization';

type DeletecatalogProps = {
  handleModalAction: () => void;
  cancelButtonClick: () => void;
};
export const CustomModalDeleteCatalog = ({ handleModalAction, cancelButtonClick }: DeletecatalogProps) => {
  return (
    <>
      <ModalBody className='custom-modal__body'>{t('deleteCatalogWarning')}</ModalBody>
      <CustomModal.Footer
        cancelButtonTitle={t('cancel')}
        actionButtonTitle={t('delete')}
        actionButtonClick={handleModalAction}
        cancelButtonClick={cancelButtonClick!}
      />
    </>
  );
};
