import { Icon } from '@just-ai/just-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../../../localization';
import { Dictionary } from '../../components/Dictionary';
import styles from './DictionaryPage.module.scss';

export const DictionaryPage = React.memo(() => {
  return (
    <div className={styles['dictionary-page']}>
      <div className={styles['dictionary-page__back-container']}>
        <Link to='/my'>
          <div className={styles['dictionary-page__back']}>
            <Icon name='falArrowLeft' size='sm' />
            <p>{t('myVoices')}</p>
          </div>
        </Link>
      </div>
      <Dictionary />
    </div>
  );
});
