import { useCallback, useEffect, useState } from 'react';
import { VoiceMemberToAdd, ModifyVoiceMember, VoiceMemberView } from '../../../api/facade/client';
import { useLibraryContext } from '../context/LibraryContext';
import { useParams } from 'react-router-dom';

type useMemberReturn = {
  members: VoiceMemberView[];
  addMember: (member: VoiceMemberToAdd) => Promise<void>;
  updateMember: (member: ModifyVoiceMember & { oldRole: number }) => Promise<void>;
  removeMember: (member: VoiceMemberView) => Promise<void>;
};

export default function useMember(): useMemberReturn {
  const [members, setMembers] = useState<VoiceMemberView[]>([] as VoiceMemberView[]);
  const { id: voiceId } = useParams<{ id: string }>();
  const {
    setCurrentVoiceId,
    getVoiceMembers,
    updateVoiceMember,
    deleteVoiceMember,
    addVoiceMember,
  } = useLibraryContext();

  const fetchMembers = useCallback(async () => {
    try {
      await setCurrentVoiceId(+voiceId);
      const members = await getVoiceMembers();
      if (!members || members.length < 1) return;
      const membersProcessed: VoiceMemberView[] = members.map(member => {
        return {
          accountId: member.accountId!,
          accountName: member.accountName || member.login,
          role: member.role,
          login: member.login,
        };
      });
      setMembers(membersProcessed);
    } catch (error) {
      console.error('Error fetching voice members', error);
    }
  }, [getVoiceMembers, setCurrentVoiceId, voiceId]);

  const addMember = useCallback(
    async (member: VoiceMemberToAdd) => {
      try {
        await addVoiceMember(member);
        fetchMembers();
      } catch (error) {
        throw error;
      }
    },
    [addVoiceMember, fetchMembers]
  );

  const updateMember = useCallback(
    async (member: ModifyVoiceMember & { oldRole?: number }) => {
      try {
        await updateVoiceMember(member);
        fetchMembers();
      } catch (error) {
        throw error;
      }
    },
    [fetchMembers, updateVoiceMember]
  );

  const removeMember = useCallback(
    async (member: VoiceMemberView) => {
      try {
        await deleteVoiceMember(member);
        fetchMembers();
      } catch (error) {
        throw error;
      }
    },
    [deleteVoiceMember, fetchMembers]
  );

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  return { members, addMember, updateMember, removeMember };
}
