import React, { useEffect } from 'react';
import { Route, Switch, RouteProps, useLocation } from 'react-router-dom';
import { matchPath, useHistory } from 'react-router';
import { useAppContext } from './AppContext';
import PlaceholderPage from './components/PlaceholderPage';
import Footer from './modules/Footer';
import Header from './modules/Header';
import AccountPage from './modules/Header/pages/AccountPage';
import { CatalogPage } from './modules/Library/pages/CatalogPage';
import AllVoicesPage from './modules/Library/pages/AllVoicesPage';
import { LibraryContextProvider } from './modules/Library/context/LibraryContext';
import { AvatarContextProvider } from './modules/Library/context/AvatarContext';
import { ProjectContextProvider } from './modules/Projects/context/ProjectsContext';
import { AccountContextProvider } from './modules/Header/context/AccountContext';
import { VoicePage } from './modules/Library/pages/VoicePage';
import { PublicVoicePage } from './modules/Library/pages/PublicVoicePage';
import { NewVoicePage } from './modules/Library/pages/NewVoicePage';
import { LandingPage } from './modules/Library/pages/LandingPage';
import PublicVoiceVersionsPage from './modules/Library/pages/PublicVoiceVersionsPage';
import { Error } from './modules/Library/pages/ErrorsPages';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_MD } from './modules/Header/constants';
import ScrollToTop from './modules/Library/components/LandingPage/ScrollToTop';
import { Spinner } from '@just-ai/just-ui';
import { MyPage } from './modules/Library/pages/MyPage';
import ProjectsPage from './modules/Projects';
import amplitude from 'amplitude-js';
import { ReplicasProvider } from './modules/Projects/context/ProjectDataContex';
import { VoicesProvider } from './modules/Projects/context/VoicesContext';
import { SettingsProvider } from './modules/Projects/context/ReplicaSettingsContext';
import { PlayerProvider } from './modules/Projects/context/PlayerContext';
import { DictionaryPage } from './modules/Library/pages/DictionaryPage';
import { BalanceHistoryPage } from './modules/Library/pages/BalanceHistoryPage';

type PrivateRouteProps = RouteProps & {
  checker?: (args: any) => React.Component;
  options?: any;
  name: string;
  data?: any;
};

export const PrivateRoute: (props: PrivateRouteProps) => JSX.Element | null = ({
  component: Component,
  options,
  checker,
  data,
  ...rest
}) => {
  const { accountLoading, appLoading } = useAppContext();

  if (accountLoading || appLoading) return <Spinner />;
  if (!Component) return <div />;

  return (
    <Route
      exact
      {...rest}
      render={props => {
        return <Component {...props} {...data} />;
      }}
    />
  );
};

const LibrarySwitch = () => {
  const history = useHistory<{ status?: number }>();
  const tabletDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_MD}px)` });
  const location = useLocation();
  const { id } = useAppContext();
  const shouldHaveFooter = !matchPath(location.pathname, { path: ['*/new_voice', '*/my/**'] });
  const shouldHaveHeader = !(
    matchPath(location.pathname, { path: ['*/my/**'] }) &&
    tabletDown &&
    !matchPath(location.pathname, { path: '*/my/project/**' })
  );
  return (
    <>
      <LibraryContextProvider>
        <AvatarContextProvider>
          <AccountContextProvider accountId={id}>
            <ProjectContextProvider>
              <ReplicasProvider>
                {shouldHaveHeader && <Header />}
                {history.location.state && history.location.state?.status ? (
                  <Error status={history.location.state.status} />
                ) : (
                  <Switch>
                    <PrivateRoute exact path='/my' name='MyPage' component={MyPage} />
                    <PrivateRoute path='/my/dictionary' component={DictionaryPage} name='Dictionary' />
                    <PrivateRoute
                      exact
                      path={[
                        '/my/voice/:voiceId/training/new_version/:catalogId',
                        '/my/voice/:voiceId/training/new_version/:catalogId/sample/:sampleId',
                      ]}
                      name='Catalog'
                      component={CatalogPage}
                    />
                    <PrivateRoute exact path='/account' name='Account' component={AccountPage} />
                    <Route path='/solutions'>
                      <PlaceholderPage name='Solutions' />
                    </Route>
                    <Route exact path='/catalog'>
                      <AllVoicesPage />
                    </Route>
                    <Route path='/catalog/:id/released' component={PublicVoiceVersionsPage} />

                    <PrivateRoute path='/my/voice/:id' component={VoicePage} name='Voice page' />

                    <PrivateRoute
                      exact
                      path='/account/history'
                      name='Account history page'
                      component={BalanceHistoryPage}
                    />

                    <PrivateRoute exact path='/my/new_voice' name='Library' component={NewVoicePage} />
                    <Route exact path='/catalog/:voiceId'>
                      <PublicVoicePage />
                    </Route>
                    <VoicesProvider>
                      <PlayerProvider>
                        <SettingsProvider>
                          <Route exact path='/'>
                            <LandingPage />
                          </Route>
                          <PrivateRoute path='/my/project/:projectId' name='Project page' component={ProjectsPage} />
                        </SettingsProvider>
                      </PlayerProvider>
                    </VoicesProvider>
                  </Switch>
                )}
              </ReplicasProvider>
              {shouldHaveFooter && <Footer />}
            </ProjectContextProvider>
          </AccountContextProvider>
        </AvatarContextProvider>
      </LibraryContextProvider>
    </>
  );
};

export const AppRouter = () => {
  const { email, isLoggedIn } = useAppContext();
  useEffect(() => {
    if (isLoggedIn) {
      amplitude.getInstance().setGlobalUserProperties({ login: email });
    }
  }, [email, isLoggedIn]);
  return (
    <>
      <ScrollToTop />

      <Switch>
        <LibrarySwitch />
      </Switch>
    </>
  );
};
