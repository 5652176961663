var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef } from "react";
import classNames from "classnames";
import { Input } from "reactstrap";
import { Icon } from "../Icon";
import { Button } from "../index";
export function InputTextFullUncontrolled(_a) {
    var { warning, validPositionLeft, clearable, className, onChange = () => { } } = _a, restProps = __rest(_a, ["warning", "validPositionLeft", "clearable", "className", "onChange"]);
    const buttonRef = useRef(null);
    const handleClear = () => {
        var _a;
        const htmlInput = (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.previousSibling;
        if (htmlInput) {
            htmlInput.value = "";
            htmlInput.focus();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Input, Object.assign({ className: classNames(className, "justui_input-text", {
                "is-warning": warning,
                positionLeft: validPositionLeft,
                clearable: clearable && !restProps.disabled && !restProps.readOnly,
            }) }, restProps)),
        clearable && !restProps.disabled && !restProps.readOnly ? (React.createElement(Button, { onClick: handleClear, flat: true, className: "clear", size: restProps.bsSize, type: "button", innerRef: buttonRef },
            React.createElement(Icon, { name: "faTimesCircle", className: "btn-icon", color: "secondary" }))) : null));
}
