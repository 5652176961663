// tslint:disable
// @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface VoiceUsageSummary
 */
export interface VoiceUsageSummary {
    /**
     * 
     * @type {number}
     * @memberof VoiceUsageSummary
     */
    voicedId: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceUsageSummary
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof VoiceUsageSummary
     */
    duration: number;
}

/**
 * VoiceAnalyticsControllerApi - axios parameter creator
 * @export
 */
export const VoiceAnalyticsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} voiceId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceUsageSummary(voiceId: number, page?: number, pageSize?: number, options: any = {}): RequestArgs {
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling getVoiceUsageSummary.');
            }
            const localVarPath = `/api/statistics/voices/{voice_id}/usage/summary`
                .replace(`{${"voice_id"}}`, encodeURIComponent(String(voiceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoiceAnalyticsControllerApi - functional programming interface
 * @export
 */
export const VoiceAnalyticsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} voiceId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceUsageSummary(voiceId: number, page?: number, pageSize?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoiceUsageSummary>> {
            const localVarAxiosArgs = VoiceAnalyticsControllerApiAxiosParamCreator(configuration).getVoiceUsageSummary(voiceId, page, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VoiceAnalyticsControllerApi - factory interface
 * @export
 */
export const VoiceAnalyticsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} voiceId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceUsageSummary(voiceId: number, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<VoiceUsageSummary>> {
            return VoiceAnalyticsControllerApiFp(configuration).getVoiceUsageSummary(voiceId, page, pageSize, options)(axios, basePath);
        },
    };
};

/**
 * VoiceAnalyticsControllerApi - object-oriented interface
 * @export
 * @class VoiceAnalyticsControllerApi
 * @extends {BaseAPI}
 */
export class VoiceAnalyticsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} voiceId 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoiceAnalyticsControllerApi
     */
    public getVoiceUsageSummary(voiceId: number, page?: number, pageSize?: number, options?: any) {
        return VoiceAnalyticsControllerApiFp(this.configuration).getVoiceUsageSummary(voiceId, page, pageSize, options)(this.axios, this.basePath);
    }

}


