import React from 'react';
import { ModalBody } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { t } from '../../../../../localization';
import { Voice } from '../../../model/Voice';
import { VoiceAvatar } from '../../VoiceAvatar';
import CustomModal from '../CustomModal';
import './styles.scss';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE } from '../../../../Header/constants';

type RemoveFromCatalogProps = {
  data: { price: string; voice: Voice };
  handleModalAction: () => void;
  cancelButtonClick: () => void;
};
export const CustomModalRemoveFromCatalog = ({
  data,
  handleModalAction,
  cancelButtonClick,
}: RemoveFromCatalogProps) => {
  const { id: voiceId } = useParams<{ id: string }>();
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  if (!data) return null;
  const { voice } = data;
  return (
    <>
      <ModalBody className='custom-modal__body'>
        <div className='custom-modal__remove-from-catalog'>
          <VoiceAvatar size='medium' voiceId={voiceId} />
          <h4 className={isPhone ? 'st-1' : ''}>{t('removeVoice', voice.name)}</h4>
          <p className={isPhone ? 'tp-4' : 'tp-3'}>{t('removeVoiceModal')}</p>
        </div>
      </ModalBody>
      <CustomModal.Footer
        cancelButtonTitle={t('cancel')}
        actionButtonTitle={t('hide')}
        cancelButtonClick={cancelButtonClick!}
        actionButtonClick={handleModalAction}
      />
    </>
  );
};
