import React, { Component, createContext, useContext } from 'react';
import { BalanceHistoryView, PackagePrice, PaymentInfo, WalletView } from '../../../api/supervisor/client';
import { AppContext } from '../../../AppContext';
import { t } from '../../../localization';
import AccountService from '../../../service/AccountService';
import { LoadCallback } from '../../../utils/hooks';
import { CustomModal, CustomModalNoMoney } from '../../Library/components/CustomModal';

export type AccountContextType = {
  wallet?: WalletView;
  getWallet: () => Promise<WalletView | undefined>;
  isLoading: boolean;
  error?: string;
  getPackages: () => Promise<PackagePrice[]>;
  buyPackage: (packageName: string) => Promise<PaymentInfo>;
  updateWallet: () => void;
  setNoFundsModal: (value: boolean) => void;
  getBalanceHistory: (
    page: number,
    pageSize: number
  ) => Promise<{
    data: BalanceHistoryView[];
    count: number;
  }>;
};

export const AccountContext = createContext({} as AccountContextType);

class State {
  isLoading: boolean = false;
  wallet?: WalletView;
  error?: string;
  isModalOpen: boolean = false;
}

type AppContextProps = {
  accountId?: number;
};

export class AccountContextProvider extends Component<AppContextProps, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  state = new State();
  accountService: AccountService;

  constructor(props) {
    super(props);
    this.accountService = new AccountService(props.accountId);
  }

  async componentDidMount() {
    this.accountService = new AccountService(this.context.id);
    this.updateWallet();
  }

  async componentDidUpdate(prevProps: AppContextProps) {
    this.accountService = new AccountService(this.context.id);
    if (prevProps.accountId !== this.props.accountId && this.props.accountId) {
      this.accountService = new AccountService(this.props.accountId);
      this.updateWallet();
    }
  }

  load: LoadCallback = promise => {
    this.setState({ isLoading: true });
    return promise.finally(() => this.setState({ isLoading: false }));
  };

  getWalletData = async () => {
    try {
      const walletData = await this.load(this.accountService.getWallet());
      return walletData;
    } catch (error) {
      console.error('error getting wallet data in context', error);
      return { userId: 0, balance: 0 } as WalletView;
    }
  };

  getPackages = async () => {
    try {
      const packages = await this.load(this.accountService.getPackages());
      return packages;
    } catch (error) {
      console.error('error getting wallet data in context', error);
      throw error;
    }
  };

  buyPackage = async (packageName: string) => {
    try {
      const paymentInfo = await this.load(this.accountService.buyPackage(packageName));
      return paymentInfo;
    } catch (error) {
      console.error('error getting wallet data in context', error);
      throw error;
    }
  };

  updateWallet = async () => {
    const walletData = await this.load(this.getWalletData());
    return this.setState({
      wallet: walletData,
    });
  };

  setModal = (value: boolean) => {
    this.setState({ isModalOpen: value });
  };

  setModalFalse = () => {
    this.setModal(false);
  };

  getBalanceHistory = async (page: number, pageSize: number) => {
    try {
      const balanceHistory = await this.load(this.accountService.getBalanceHistory(page, pageSize));
      return balanceHistory;
    } catch (error) {
      console.error('error getting balance history', error);
      throw error;
    }
  };

  render() {
    this.accountService = new AccountService(this.props.accountId!); // didUpdate выполняется снизу вверх, поэтому инициализируем сервис тут
    return (
      <>
        <AccountContext.Provider
          value={{
            wallet: this.state.wallet,
            error: this.state.error,
            isLoading: this.state.isLoading,
            getWallet: this.getWalletData,
            getPackages: this.getPackages,
            buyPackage: this.buyPackage,
            updateWallet: this.updateWallet,
            setNoFundsModal: this.setModal,
            getBalanceHistory: this.getBalanceHistory,
          }}
        >
          {this.props.children}
        </AccountContext.Provider>
        <CustomModal isOpen={this.state.isModalOpen} title={t('Modals:NoMoney:Title')} toggle={this.setModalFalse}>
          <CustomModalNoMoney cancelButtonClick={this.setModalFalse} />
        </CustomModal>
      </>
    );
  }
}

export const useAccountContext = () => useContext(AccountContext);
