import React from 'react';
import { ModalBody } from 'reactstrap';
import CustomModal from '../CustomModal';
import successBg from '../../../../../img/requestSuccess.svg';
import { t } from '../../../../../localization';
import './styles.scss';

type RequestSuccessProps = {
  handleModalAction: () => void;
};

export const RequestSuccessModal = ({ handleModalAction }: RequestSuccessProps) => {
  return (
    <>
      <ModalBody className='custom-modal__body success-request-info'>
        <div>
          <img src={successBg} alt='success' />

          <h3>{t('requestSuccess')}</h3>

          <p className='tp-3'>{t('reachOut')}</p>
        </div>
      </ModalBody>
      <CustomModal.Footer actionButtonTitle={t('great')} actionButtonClick={handleModalAction} />
    </>
  );
};
