import React, { useCallback } from 'react';
import { t } from '../../../../localization';
import SidebarRange from './SidebarRange';
import { Range } from '@just-ai/just-ui';
import { useReplicas } from '../../context/ProjectDataContex';
import { useSettings } from '../../context/ReplicaSettingsContext';
import { StyleName } from '../../../../api/dubber/client';

export const ReplicaSettings = () => {
  const { getSelectedReplica } = useReplicas();
  const { rate, pitch, volume, style, setSetting } = useSettings();
  const selectedReplicaMarkup = getSelectedReplica()?.markup;
  const currentStyleValue = style.styles.findIndex(style => style === selectedReplicaMarkup?.style?.style);
  const defaultStyleValue = style.styles.findIndex(defaultStyles => defaultStyles === String(style.defaultValue));

  const tempoStepsLabel = useCallback((value: number) => {
    switch (value) {
      case -1:
        return t('ProjectsSidebar:VoicelineSettings:tempo:slow');
      case 0:
        return t('ProjectsSidebar:VoicelineSettings:tempo:normal');
      case 1:
        return t('ProjectsSidebar:VoicelineSettings:tempo:fast');

      default:
        return t('ProjectsSidebar:VoicelineSettings:tempo:normal');
    }
  }, []);

  const volumeStepsLabel = useCallback((value: number) => {
    switch (value) {
      case -1:
        return t('ProjectsSidebar:VoicelineSettings:volume:quiet');
      case 0:
        return t('ProjectsSidebar:VoicelineSettings:volume:normal');
      case 1:
        return t('ProjectsSidebar:VoicelineSettings:volume:loud');

      default:
        return t('ProjectsSidebar:VoicelineSettings:volume:normal');
    }
  }, []);
  const styleStepsLabel = useCallback((value: StyleName) => {
    switch (value) {
      case StyleName.NORMAL:
        return t('ProjectsSidebar:VoicelineSettings:style:normal');
      case StyleName.SAD:
        return t('ProjectsSidebar:VoicelineSettings:style:sad');
      case StyleName.ANGRY:
        return t('ProjectsSidebar:VoicelineSettings:style:angry');
      case StyleName.HAPPY:
        return t('ProjectsSidebar:VoicelineSettings:style:happy');

      default:
        return t('ProjectsSidebar:VoicelineSettings:style:normal');
    }
  }, []);

  const pitchStepsLabel = useCallback((value: number) => {
    switch (value) {
      case -1:
        return t('ProjectsSidebar:VoicelineSettings:pitch:low');
      case 0:
        return '';
      case 1:
        return t('ProjectsSidebar:VoicelineSettings:pitch:high');

      default:
        return t('ProjectsSidebar:VoicelineSettings:pitch:low');
    }
  }, []);
  return (
    <>
      <p className='st-2'>{t('ProjectsSidebar:VoicelineSettings:tempo')}</p>
      <SidebarRange
        name='rate'
        labelFunction={tempoStepsLabel}
        rawValue={selectedReplicaMarkup?.rate?.value || Number(rate.defaultValue)}
        setValue={value => setSetting({ setting: 'rate', value })}
        max={rate.to}
        min={rate.from}
        defaultValue={Number(rate.defaultValue)}
      />
      <p className='st-2 mt-7'>{t('ProjectsSidebar:VoicelineSettings:volume')}</p>
      <SidebarRange
        name='volume'
        labelFunction={volumeStepsLabel}
        rawValue={selectedReplicaMarkup?.volume?.value || Number(volume.defaultValue)}
        setValue={value => setSetting({ setting: 'volume', value })}
        max={volume.to}
        min={volume.from}
        defaultValue={Number(volume.defaultValue)}
      />
      <p className='st-2 mt-7'>{t('ProjectsSidebar:VoicelineSettings:pitch')}</p>
      <SidebarRange
        name='pitch'
        labelFunction={pitchStepsLabel}
        rawValue={selectedReplicaMarkup?.pitch?.value || Number(pitch.defaultValue)}
        setValue={value => setSetting({ setting: 'pitch', value })}
        max={pitch.to}
        min={pitch.from}
        defaultValue={Number(pitch.defaultValue)}
      />
      <p className='st-2 mt-7'>{t('ProjectsSidebar:VoicelineSettings:style')}</p>
      <Range
        name='style'
        value={currentStyleValue || defaultStyleValue || 0}
        onChange={value => setSetting({ setting: 'style', value })}
        min={0}
        max={style.styles.length - 1}
        step={1}
        visibleStep={1}
        visibleStepLabel={value => styleStepsLabel(style.styles[value])}
      />
    </>
  );
};
