import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Spinner, useToggle } from '@just-ai/just-ui';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { t } from '../../../../localization';
import { useReplicas } from '../../context/ProjectDataContex';
import { useProjectContext } from '../../context/ProjectsContext';
import { Prompt, useParams } from 'react-router';
import { reorderList } from '../../../../utils';
import { useAmplitude } from 'react-amplitude-hooks';
import DeletingLine from './DeletingLine';
import { getErrorMessageFromReason } from '../../../../utils/error';
import { DraggableVoiceLine } from './DraggableVoiceLine';
import SpeakerBlock, { SpeakerBlockProps } from '../../../../components/VoiceLineSynthBlock/VoiceLineBlock';
import { MAX_VOICE_LINES_PER_PAGE, MAX_VOICE_LINE_LENGTH } from '../../constants';
import { usePlayer } from '../../context/PlayerContext';
import { useVoices } from '../../context/VoicesContext';
import { useSettings } from '../../context/ReplicaSettingsContext';
import useDefaultAlert from '../../../../utils/useAlert';
import {
  Markup,
  ProcessProjectReplicasRequest,
  ReplicaView,
  UpdateReplicaRequest,
} from '../../../../api/dubber/client';
import { SynthBuffer } from '../../model/VoiceLines';

import './style.scss';
import { waitForElm } from '../../utils';
import { SCREEN_WIDTH_TABLET } from '../../../Header/constants';
import { useMediaQuery } from 'react-responsive';
import { getTextLenght } from '../Tiptap/HtmlService';

export type ExtendedVoiceLine = ReplicaView & {
  isDeleting?: boolean;
  error?: string;
  synthBuffer?: SynthBuffer;
  markup?: Markup;
};

export const NEW_LINE_ID = 'newLine';
export const PLACEHOLDER_ID = 'placeholder';

type ReplicasBlockProps = {
  wrapperRef: React.RefObject<HTMLDivElement>;
  openWriter: () => void;
};

export const ReplicasBlock = ({ wrapperRef, openWriter }: ReplicasBlockProps) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | number>();
  const unblockHandle = useRef<any>();
  const [dragInProgress, , , toggleDragInProgress] = useToggle();
  const {
    fetchReplicas,
    loading,
    currentPage,
    selectedReplicaId,
    maxLinesReached,
    linesToDelete,
    placeholder,
    replicas,
    getReplicaById,
    isDirty,
    setReplicaStore,
    checkTextLength,
    initialSsmlCheck,
  } = useReplicas();
  const {
    offlineMode,
    bulkUpdate,
    deleteVoiceLine,
    updateVoiceLine,
    createVoiceLine,
    returnedFromOffline,
    handleReturnedFromOffline,
    getVoiceLines,
  } = useProjectContext();
  const { projectId } = useParams<{ projectId: string }>();
  const { logEvent } = useAmplitude();
  const { playerRef, playingId, deleteSynthBufferRecord, setPlayerStore, deleteProjectBuffer } = usePlayer();
  const { generateMarkupUpdate, getSsmlText, getSsmlTextByVoiceId } = useSettings();
  const {
    availableVoices,
    setVoicesStore,
    fetchAvailableVoices,
    maxVoicesReached,
    voicesLoading,
    voiceCurrentPage,
  } = useVoices();
  const { defaultErrorAlert } = useDefaultAlert();
  const [editedLine, setEditedLine] = useState('');
  const isTablet = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });

  const selectedReplica = replicas.find(replica => replica.id === selectedReplicaId);

  const loadMoreVoices = useCallback(() => {
    if (voicesLoading || maxVoicesReached) return;
    setVoicesStore({ loading: true });

    try {
      fetchAvailableVoices(voiceCurrentPage + 1, true);
      setVoicesStore({ currentPage: voiceCurrentPage + 1 });
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [voicesLoading, maxVoicesReached, setVoicesStore, fetchAvailableVoices, voiceCurrentPage, defaultErrorAlert]);

  const loadMoreReplicas = useCallback(() => {
    if (loading || maxLinesReached) return;
    fetchReplicas(currentPage + 1, false, true);
    setReplicaStore({ currentPage: currentPage + 1 });
  }, [currentPage, setReplicaStore, fetchReplicas, maxLinesReached, loading]);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement> | WheelEvent) => {
      event.stopPropagation();
      if (!scrollableRef.current) return;
      const myDiv = event.currentTarget as HTMLDivElement;
      if (!myDiv.classList.contains('scrolling')) {
        myDiv.classList.add('scrolling');
        timeoutRef.current = setTimeout(() => {
          if (myDiv.classList.contains('scrolling')) myDiv.classList.remove('scrolling');
        }, 1000);
      }
      // 10 is a scrolling trigger area and fallback
      if (myDiv.offsetHeight + myDiv.scrollTop + 10 >= myDiv.scrollHeight && !dragInProgress) {
        loadMoreReplicas();
      }
    },
    [scrollableRef, dragInProgress, loadMoreReplicas]
  );

  const handleUnloadEvent = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!isDirty) return;
      //     //the text of the prompt itself won't change in modern browsers https://developer.chrome.com/blog/chrome-51-deprecations/#remove_custom_messages_in_onbeforeunload_dialogs
      var confirmationMessage = t('leaveWarning');
      (event || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    },
    [isDirty]
  );

  useEffect(() => {
    const wrapperRefCurrent = wrapperRef.current;
    const unblockCurrent = unblockHandle.current;
    window.addEventListener('beforeunload', handleUnloadEvent);
    wrapperRefCurrent?.addEventListener('wheel', handleScroll);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current as number);
      window.removeEventListener('beforeunload', handleUnloadEvent);
      wrapperRefCurrent?.removeEventListener('wheel', handleScroll);

      if (unblockCurrent) {
        unblockCurrent();
      }
    };
  }, [handleScroll, handleUnloadEvent, wrapperRef]);

  const updateProjectWhenNoSsml = useCallback(async () => {
    if (initialSsmlCheck === 0) return;
    const fullProject = await getVoiceLines({ projectId, page: 0, pageSize: initialSsmlCheck });
    const isNoSsmlExist = fullProject.replicas.find(replica => !replica.hasOwnProperty('ssmlText'));
    if (!isNoSsmlExist) return;
    const updatedReplicas = await Promise.all(
      fullProject.replicas.map(async replica => {
        if (!replica.ssmlText) {
          replica.ssmlText = replica.voiceId
            ? await getSsmlTextByVoiceId(replica.text, replica.voiceId, replica.markup)
            : replica.text;
          return replica;
        }
        return replica;
      })
    );
    bulkUpdate(projectId, {
      updated: updatedReplicas,
    });
    setReplicaStore({
      replicas: updatedReplicas.slice(0, MAX_VOICE_LINES_PER_PAGE),
      initialSsmlCheck: 0,
    });
  }, [bulkUpdate, getSsmlTextByVoiceId, getVoiceLines, initialSsmlCheck, projectId, setReplicaStore]);

  const updateProjectWhenOnline = useCallback(async () => {
    const linesWithIndexes: Array<ExtendedVoiceLine & { savedIndex: number }> = [...replicas].map(replica => ({
      ...replica,
      savedIndex: replica.index,
    }));

    const filteredLines = linesWithIndexes.reduce<{
      linesToCreate: ProcessProjectReplicasRequest['created'];
      linesToUpdate: ProcessProjectReplicasRequest['updated'];
      linesToDelete: ProcessProjectReplicasRequest['deleted'];
    }>(
      (acc, replica) => {
        if (linesToDelete.includes(replica.id)) {
          acc.linesToDelete?.push({ id: replica.id });
          return acc;
        }
        if (replica.id.includes('offlineModeCreated')) {
          acc.linesToCreate?.push({ ...replica, index: replica.savedIndex });
          return acc;
        }
        if (!replica.id.includes('offlineModeCreated')) {
          acc.linesToUpdate?.push({ ...replica, index: replica.savedIndex });
          return acc;
        }
        return acc;
      },
      { linesToCreate: [], linesToUpdate: [], linesToDelete: [] }
    );

    try {
      await bulkUpdate(projectId, {
        created: filteredLines.linesToCreate,
        updated: filteredLines.linesToUpdate,
        deleted: filteredLines.linesToDelete,
      });
      fetchReplicas(currentPage, true);

      logEvent('replicas_bulk_update', {
        project_id: projectId,
        result: 'success',
        updates_count:
          filteredLines.linesToUpdate!.length +
          filteredLines.linesToCreate!.length +
          filteredLines.linesToDelete!.length,
      });
      setReplicaStore({ isDirty: false });
    } catch (error) {
      defaultErrorAlert(error);

      logEvent('replicas_bulk_update', {
        project_id: projectId,
        result: 'failed',
        updates_count:
          filteredLines.linesToUpdate!.length +
          filteredLines.linesToCreate!.length +
          filteredLines.linesToDelete!.length,
      });
    }
  }, [
    replicas,
    linesToDelete,
    bulkUpdate,
    projectId,
    fetchReplicas,
    currentPage,
    logEvent,
    setReplicaStore,
    defaultErrorAlert,
  ]);

  useEffect(() => {
    if (!returnedFromOffline) return;
    updateProjectWhenOnline();
    handleReturnedFromOffline(false);
  }, [handleReturnedFromOffline, returnedFromOffline, updateProjectWhenOnline]);

  useEffect(() => {
    updateProjectWhenNoSsml();
  }, [updateProjectWhenNoSsml]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const startIndex = result.source.index;
      const endIndex = result.destination.index;

      if (startIndex === endIndex) return;

      const initialReplicas = [...replicas];

      const reorderedReplicas = reorderList({
        list: [...initialReplicas],
        startIndex,
        endIndex,
      });

      reorderedReplicas.forEach((replica, index) => {
        replica.index = index;
      });

      setReplicaStore({ replicas: reorderedReplicas });
      toggleDragInProgress();

      const updatedSubarray =
        startIndex > endIndex
          ? reorderedReplicas.slice(endIndex, startIndex + 1)
          : reorderedReplicas.slice(startIndex, endIndex + 1);

      if (
        scrollableRef.current &&
        scrollableRef.current.offsetHeight + scrollableRef.current.scrollTop >= scrollableRef.current.scrollHeight
      ) {
        loadMoreReplicas();
      }

      const replicasToUpdate = updatedSubarray.filter(line => !line.id.includes(NEW_LINE_ID));
      const replicasToCreate = updatedSubarray.filter(line => line.id.includes(NEW_LINE_ID));
      const createdObj = replicasToCreate.map(replica => {
        return {
          text: replica.text,
          projectId: projectId,
          index: replica.index,
          voiceId: Number(replica.voiceId),
          modelId: 0,
          ssmlText: getSsmlText(replica.text) || replica.text,
        };
      });

      if (!offlineMode) {
        logEvent('project_replica_reordered', {
          project_id: projectId,
          replica_id: result.draggableId,
          result: 'success',
          old_position: result.source.index,
          new_position: result.destination.index,
        });

        bulkUpdate(projectId, {
          created: createdObj.length > 0 ? createdObj : [],
          updated: replicasToUpdate,
        });
        setReplicaStore({ isDirty: false });
        deleteProjectBuffer();
      }
    },
    [
      bulkUpdate,
      deleteProjectBuffer,
      getSsmlText,
      loadMoreReplicas,
      logEvent,
      offlineMode,
      projectId,
      replicas,
      setReplicaStore,
      toggleDragInProgress,
    ]
  );

  const deleteReplica = useCallback(
    async (replicaId: string) => {
      let newReplicasArr = [...replicas];
      const { replicaIndex } = getReplicaById(replicaId);
      if (replicaIndex === -1) return;
      try {
        if (offlineMode) {
          setReplicaStore({ linesToDelete: [...linesToDelete, replicaId] });
        }
        if (!offlineMode) {
          logEvent('project_replica_deleted', {
            project_id: projectId,
            replica_id: replicaId,
            result: 'success',
          });
          await deleteVoiceLine(replicaId);
          deleteProjectBuffer();
        }
        newReplicasArr = newReplicasArr.filter(replica => replica.id !== replicaId);
      } catch (error) {
        logEvent('project_replica_deleted', {
          project_id: projectId,
          replica_id: replicaId,
          result: 'failed',
        });
        if (newReplicasArr[replicaIndex]) {
          newReplicasArr[replicaIndex].error = getErrorMessageFromReason(error, t, t('voiceLineNotDeleted'));
          newReplicasArr[replicaIndex].isDeleting = false;
        }
      } finally {
        if (selectedReplicaId === replicaId) setReplicaStore({ selectedReplicaId: '' });
        setReplicaStore({ replicas: newReplicasArr, isDirty: false });
      }
    },
    [
      replicas,
      getReplicaById,
      offlineMode,
      setReplicaStore,
      linesToDelete,
      logEvent,
      projectId,
      deleteVoiceLine,
      deleteProjectBuffer,
      selectedReplicaId,
    ]
  );
  const cancelReplicaDeleting = useCallback(
    (replicaId: string) => {
      const newReplicasArr = [...replicas];
      const { replica, replicaIndex } = getReplicaById(replicaId);
      if (!replica || replicaIndex === -1) return;

      newReplicasArr[replicaIndex] = { ...replica, isDeleting: false };
      setReplicaStore({ replicas: newReplicasArr });
    },
    [getReplicaById, replicas, setReplicaStore]
  );

  const handleCreateReplica = useCallback(
    async ({ addedReplica, index }: { addedReplica: ExtendedVoiceLine; index: number }) => {
      try {
        const markupUpdate =
          addedReplica.id.includes(NEW_LINE_ID) && addedReplica.text.length > 0 ? generateMarkupUpdate() : {};

        const replica = await createVoiceLine({
          index,
          projectId,
          text: addedReplica.text,
          modelId: addedReplica.modelId,
          voiceId: addedReplica.voiceId,
          markupUpdate,
          ssmlText: getSsmlText(addedReplica.text) || addedReplica.text,
        });
        deleteProjectBuffer();

        logEvent('project_replica_created', {
          project_id: projectId,
          result: 'success',
          replica_id: replica.id,
          text_len: replica.text.length,
          voiceId: addedReplica.voiceId || 'empty',
        });

        return replica;
      } catch (error) {
        logEvent('project_replica_created', {
          project_id: projectId,
          result: 'failed',
          voiceId: addedReplica.voiceId || 'empty',
        });
      }
    },
    [createVoiceLine, deleteProjectBuffer, generateMarkupUpdate, getSsmlText, logEvent, projectId]
  );

  const handleBlur = useCallback(
    async (replicaId: string) => {
      const newReplicasArr = [...replicas];
      const isPlaceholder = replicaId.includes(PLACEHOLDER_ID);
      const isNewLine = replicaId.includes(NEW_LINE_ID);
      if (isPlaceholder && (!placeholder.text || placeholder.error)) return;
      let newReplicaIndex: number | null = null;
      let addedReplica: ExtendedVoiceLine | null = null;

      if (isPlaceholder) {
        newReplicaIndex = newReplicasArr.length;
        addedReplica = { ...placeholder };
        newReplicasArr.push(addedReplica);
      }

      if (isNewLine) {
        newReplicaIndex = newReplicasArr.findIndex(replica => replica.id === replicaId);
        newReplicasArr.forEach((replica, index) => {
          replica.index = index;
        });
        addedReplica = { ...newReplicasArr[newReplicaIndex] };
      }
      if (!addedReplica) return;
      addedReplica.id = offlineMode ? `offlineModeCreated-${newReplicaIndex}` : addedReplica.id;
      if (!isNewLine && !isPlaceholder) return;
      try {
        const createdVoiceLine = offlineMode
          ? addedReplica
          : await handleCreateReplica({
              addedReplica,
              index: isPlaceholder ? newReplicaIndex || 0 : addedReplica.index,
            });
        if (!createdVoiceLine?.id || newReplicaIndex === -1 || newReplicaIndex === null) return;
        newReplicasArr[newReplicaIndex].id = createdVoiceLine.id;
        const newPlaceholderText = placeholder.text ? placeholder.text.replace(addedReplica.text, '') : '';

        setReplicaStore({
          replicas: newReplicasArr,
          placeholder: {
            ...placeholder,
            text: newPlaceholderText,
            ssmlText: newPlaceholderText,
            error: getTextLenght(newPlaceholderText) > MAX_VOICE_LINE_LENGTH ? checkTextLength(newPlaceholderText) : '',
          },
          isDirty: false,
        });
      } catch (error) {
        console.error('error in line blur', error);
        if (newReplicaIndex && newReplicasArr[newReplicaIndex])
          newReplicasArr[newReplicaIndex].error = getErrorMessageFromReason(error, t, t('voiceLineNotSaved'));
        setReplicaStore({ replicas: newReplicasArr });
      }
    },
    [replicas, placeholder, offlineMode, handleCreateReplica, setReplicaStore, checkTextLength]
  );

  const handleSpeakerSelect: SpeakerBlockProps['handleAvatarClick'] = useCallback(
    async (lineId, voiceId, modelId) => {
      const newReplicasArr = [...replicas];

      const { replica, replicaIndex } = getReplicaById(lineId);

      if (lineId.includes(PLACEHOLDER_ID) && modelId && voiceId) {
        setReplicaStore({ placeholder: { ...placeholder, modelId, voiceId } });
        handleBlur(lineId);
      }

      if (!replica || replicaIndex === -1 || !voiceId) return;

      try {
        if (!offlineMode) {
          logEvent('project_replica_voice_edited', {
            project_id: projectId,
            result: 'success',
            replica_id: lineId,
            voice_id: voiceId,
          });
          if (lineId.includes(NEW_LINE_ID)) {
            handleBlur(lineId);
            return;
          }
          const markupUpdate = generateMarkupUpdate(true);
          const updatedData = await updateVoiceLine(lineId, {
            text: replica.text,
            index: replicaIndex,
            modelId,
            voiceId: voiceId,
            markupUpdate,
            ssmlText: getSsmlText(replica.text) || replica.text,
          });
          newReplicasArr[replicaIndex] = { ...updatedData, markup: {} };

          setReplicaStore({ replicas: newReplicasArr });
          deleteSynthBufferRecord(lineId);
        }
      } catch (error) {
        logEvent('project_replica_voice_edited', {
          project_id: projectId,
          result: 'failed',
          replica_id: lineId,
          voice_id: voiceId,
        });
        const newReplicasArr = [...replicas];
        newReplicasArr[replicaIndex] = {
          ...newReplicasArr[replicaIndex],
          error: getErrorMessageFromReason(error, t, t('voiceLineNotSaved')),
        };
        setReplicaStore({ replicas: newReplicasArr });
      }
    },
    [
      replicas,
      getReplicaById,
      setReplicaStore,
      placeholder,
      handleBlur,
      offlineMode,
      logEvent,
      projectId,
      generateMarkupUpdate,
      updateVoiceLine,
      getSsmlText,
      deleteSynthBufferRecord,
    ]
  );

  const handlePlayerClick: SpeakerBlockProps['handlePlayerClick'] = useCallback(
    ({ lineId, phraseUrl, voiceId }) => {
      if (!playerRef?.current) return;
      if (playerRef.current.paused) {
        if (playerRef.current.src !== phraseUrl && phraseUrl) {
          playerRef.current.src = phraseUrl;
        }
        setPlayerStore({ playingId: lineId, playingMode: 'SINGLE' });
        setReplicaStore({ updateHistory: true });
        logEvent('project_replica_synthesized', {
          project_id: projectId,
          replica_id: lineId,
          result: 'success',
          voice_id: voiceId,
        });
      } else {
        setPlayerStore({ playingId: '' });
      }
    },
    [logEvent, playerRef, projectId, setPlayerStore, setReplicaStore]
  );
  const handleLineBlur = useCallback(
    async (replicaId: string) => {
      if (replicaId.includes(PLACEHOLDER_ID) || replicaId.includes(NEW_LINE_ID)) return handleBlur(replicaId);
      const newReplicasArr = [...replicas];
      const { replica, replicaIndex } = getReplicaById(replicaId);
      try {
        if (replicaIndex === -1 || !replica) return;
        if (!offlineMode && isDirty && !replica.error) {
          const markupUpdate = replica.voiceId ? generateMarkupUpdate() : {};
          const updatedReplica: UpdateReplicaRequest = {
            ...replica,
            text: replica.text,
            modelId: replica.modelId,
            voiceId: replica.voiceId,
            index: replicaIndex,
            ssmlText: replica.voiceId ? getSsmlText(replica.text) || replica.text : replica.text,
          };
          if (markupUpdate) {
            updatedReplica.markupUpdate = markupUpdate;
          }
          const updated = await updateVoiceLine(replicaId, updatedReplica);
          const anyErrorInState = newReplicasArr.find(replica => replica.error);
          const stillDirty = Boolean(newReplicasArr.find(replica => replica.id.includes(NEW_LINE_ID)));
          newReplicasArr[replicaIndex] = updated;
          setReplicaStore({
            replicas: newReplicasArr,
            isDirty: anyErrorInState || stillDirty ? true : false,
            updateHistory: true,
          });
          logEvent('project_replica_text_edited', {
            project_id: projectId,
            result: 'success',
            replica_id: replicaId,
            text_len: replica.text.length,
          });
        }
      } catch (error) {
        const newReplicasArr = [...replicas];
        if (newReplicasArr[replicaIndex])
          newReplicasArr[replicaIndex].error = getErrorMessageFromReason(error, t, t('voiceLineNotSaved'));
        setReplicaStore({ replicas: newReplicasArr });

        logEvent('project_replica_text_edited', {
          project_id: projectId,
          result: 'failed',
          replica_id: replicaId,
        });
      }
    },
    [
      handleBlur,
      replicas,
      offlineMode,
      isDirty,
      generateMarkupUpdate,
      updateVoiceLine,
      getSsmlText,
      logEvent,
      projectId,
      getReplicaById,
      setReplicaStore,
    ]
  );

  const handleSpeakerMenuScroll = useCallback(
    async (event: React.UIEvent<HTMLDivElement>) => {
      event.stopPropagation();
      const myDiv = event.currentTarget;
      // 10 is a scrolling trigger area and fallback
      const loadCondition = myDiv.offsetHeight + myDiv.scrollTop + 10 >= myDiv.scrollHeight;
      if (loadCondition) {
        loadMoreVoices();
      }
    },
    [loadMoreVoices]
  );

  const handleFocusOnElement = useCallback(async (id: string) => {
    const element = await waitForElm(id);
    if (element && element instanceof HTMLElement) {
      element.children[0].classList.add('ProseMirror-focused');
      (element.children[0] as HTMLElement).focus();
      window?.getSelection()?.selectAllChildren(element.children[0]);
      window?.getSelection()?.collapseToEnd();
    }
  }, []);

  const handlePasteSplit = useCallback(
    async (arrToSplit: string[], replicaId: string) => {
      const newReplicasArr = [...replicas];
      const splitArrCopy = [...arrToSplit];
      const indexOfLine = replicaId.includes(PLACEHOLDER_ID)
        ? newReplicasArr.length - 1
        : newReplicasArr.findIndex(line => line.id === replicaId);
      const itemByIndex = replicaId.includes(PLACEHOLDER_ID) ? placeholder : newReplicasArr[indexOfLine];

      const convertedStringsToReplicas = splitArrCopy.map((text, index) => ({
        ...itemByIndex,
        text,
        id: `${NEW_LINE_ID}-${indexOfLine ? indexOfLine + 1 + index : newReplicasArr.length + index}`,
        ssmlText: getSsmlText(text) || text,
      }));

      if (replicaId.includes(PLACEHOLDER_ID)) {
        newReplicasArr.push(...convertedStringsToReplicas);
      } else {
        newReplicasArr.splice(indexOfLine + 1, 0, ...convertedStringsToReplicas.splice(1));
      }
      newReplicasArr.forEach((replica, index) => {
        replica.index = index;
      });
      const arrToCreate = newReplicasArr
        .filter(replica => replica.id.includes(NEW_LINE_ID))
        .map(replica => {
          return {
            index: replica.index,
            projectId,
            text: replica.text,
            modelId: replica.modelId,
            voiceId: replica.voiceId,
            markup: replica.markup,
            ssmlText: getSsmlText(replica.text) || replica.text,
          };
        });
      const arrToUpdate = newReplicasArr
        .slice(indexOfLine + convertedStringsToReplicas.length + 1)
        .filter(replica => !replica.id.includes(NEW_LINE_ID));

      await bulkUpdate(projectId, {
        created: arrToCreate,
        updated: arrToUpdate,
      });
      const newReplicas = await getVoiceLines({ projectId, page: currentPage, pageSize: MAX_VOICE_LINES_PER_PAGE });
      if (!replicaId.includes(PLACEHOLDER_ID)) {
        const newText = newReplicasArr[indexOfLine].text + splitArrCopy[0];
        newReplicas.replicas[indexOfLine].text = newText;
        newReplicas.replicas[indexOfLine].ssmlText = getSsmlText(newText) || newText;
      }
      setReplicaStore({
        replicas: newReplicas.replicas,
        maxLinesReached: !newReplicas.replicas.length || newReplicas.replicas.length < MAX_VOICE_LINES_PER_PAGE,
        isDirty: !replicaId.includes(PLACEHOLDER_ID),
      });
    },
    [bulkUpdate, currentPage, getSsmlText, getVoiceLines, placeholder, projectId, replicas, setReplicaStore]
  );

  const removeLineBlock = useCallback(
    (lineId: string) => {
      const newReplicaArr = [...replicas];
      const { replica, replicaIndex } = getReplicaById(lineId);

      if (!replica || replicaIndex === -1) return;

      newReplicaArr[replicaIndex] = { ...replica, isDeleting: true };
      if (playerRef?.current && !playerRef.current.paused) {
        playerRef.current.pause();
        playerRef.current.removeAttribute('src');
        setPlayerStore({ playingId: '' });
      }
      setReplicaStore({ replicas: newReplicaArr });
    },
    [setReplicaStore, setPlayerStore, playerRef, getReplicaById, replicas]
  );

  const handleEditingState = useCallback(
    (lineId: string) => {
      if (editedLine !== lineId) {
        setEditedLine(lineId);
      }
    },
    [editedLine, setEditedLine]
  );

  const handlePlaceholderFocus = useCallback(() => {
    if (isTablet) return;
    handleFocusOnElement(PLACEHOLDER_ID);
  }, [handleFocusOnElement, isTablet]);

  return (
    <div
      className='projects-page__lines-block'
      data-test-id='voiceLines.scrollWrapper'
      onScroll={handleScroll}
      ref={scrollableRef}
      id='projectVoicelinesScrollableBlock'
      style={{ position: 'relative' }}
    >
      <div className='projects-page__replica-header'>
        <p className='st-1'>{t('ProjectPage:Replicas:Header')}</p>
        <Button iconLeft='farStars' color='secondary' size='sm' onClick={openWriter}>
          {t('AiWriter:Header:Title')}
        </Button>
      </div>
      <Prompt when={isDirty && offlineMode} message={t('leaveWarning')} />
      <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={toggleDragInProgress}>
        <Droppable droppableId='droppable'>
          {(droppableProvided, snapshotDrop) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              className='projects-voicelines__droppable-area'
            >
              {replicas.map((replica, index) => {
                if (replica.isDeleting) {
                  return (
                    <DeletingLine
                      key={replica.id}
                      handleFinalDelete={deleteReplica}
                      handleCancelDelete={cancelReplicaDeleting}
                      lineId={replica.id}
                    />
                  );
                }
                return (
                  <Draggable key={replica.id} draggableId={replica.id} index={index}>
                    {(provided, snapshot) => (
                      <DraggableVoiceLine
                        provided={provided}
                        snapshot={snapshot}
                        line={replica}
                        selectedVoiceLine={selectedReplica}
                      >
                        <SpeakerBlock
                          text={replica.text}
                          voiceId={replica.voiceId}
                          modelId={replica.modelId}
                          playingId={playingId}
                          handleAvatarClick={handleSpeakerSelect}
                          handlePlayerClick={handlePlayerClick}
                          handleInputBlur={handleLineBlur}
                          removeLineBlock={removeLineBlock}
                          lineId={replica.id}
                          error={replica.error}
                          speakersList={availableVoices}
                          handleSpeakerMenuScroll={handleSpeakerMenuScroll}
                          projectId={projectId}
                          index={index}
                          voicesLoading={loading}
                          synthBuffer={replica.synthBuffer}
                          lastLine={index === replicas.length - 1}
                          editedLine={editedLine}
                          setEditingProp={handleEditingState}
                          handleSplitOnPaste={handlePasteSplit}
                          isPlaying={!playerRef?.current?.paused && Boolean(playingId)}
                          focusOn={handleFocusOnElement}
                        />
                      </DraggableVoiceLine>
                    )}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {loading && <Spinner style={{ position: 'static' }} size='4x' color='secondary' />}
      {maxLinesReached && (
        <>
          <SpeakerBlock
            lineId={placeholder.id}
            text={placeholder.text}
            voiceId={placeholder.voiceId}
            modelId={placeholder.modelId}
            handleAvatarClick={handleSpeakerSelect}
            handlePlayerClick={handlePlayerClick}
            playingId={playingId}
            handleInputBlur={handleBlur}
            className='project-voiceline__placeholder'
            speakersList={availableVoices}
            error={placeholder.error}
            handleSpeakerMenuScroll={handleSpeakerMenuScroll}
            projectId={projectId}
            lastLine={true}
            editedLine={editedLine}
            setEditingProp={handleEditingState}
            handleSplitOnPaste={handlePasteSplit}
            focusOn={handleFocusOnElement}
          />
          <div className='placeholder-focus-block' onClick={handlePlaceholderFocus} />
        </>
      )}
    </div>
  );
};
