var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Card as RsCard, CardBody } from 'reactstrap';
import './Card.scss';
import classNames from 'classnames';
import { Theme } from '../Common';
export function Card(_a) {
    var { children, className, disableBorderTop = false, disableBorderBottom = false, disableBorderLeft = false, disableBorderRight = false, disableBorderTopLeftRadius = false, disableBorderTopRightRadius = false, disableBorderBottomRightRadius = false, disableBorderBottomLeftRadius = false, disableBorder = false, disableBorderRadius = false, iconComponent, padding = 'md', paddingX, paddingY, noPadding, forceHover, withHover, withSpoiler, theme = Theme.light } = _a, props = __rest(_a, ["children", "className", "disableBorderTop", "disableBorderBottom", "disableBorderLeft", "disableBorderRight", "disableBorderTopLeftRadius", "disableBorderTopRightRadius", "disableBorderBottomRightRadius", "disableBorderBottomLeftRadius", "disableBorder", "disableBorderRadius", "iconComponent", "padding", "paddingX", "paddingY", "noPadding", "forceHover", "withHover", "withSpoiler", "theme"]);
    return (React.createElement(RsCard, Object.assign({}, props, { className: classNames(className, 'just-ui-card', {
            'no-b-t': disableBorderTop,
            'no-b-b': disableBorderBottom,
            'no-b-l': disableBorderLeft,
            'no-b-r': disableBorderRight,
            'no-b-t-l': disableBorderTopLeftRadius,
            'no-b-t-r': disableBorderTopRightRadius,
            'no-b-b-r': disableBorderBottomRightRadius,
            'no-b-b-l': disableBorderBottomLeftRadius,
            'no-border-radius': disableBorderRadius,
            'no-border': disableBorder
        }, {
            'p-y-lg': padding === 'lg' || paddingY === 'lg',
            'p-x-lg': padding === 'lg' || paddingX === 'lg',
            'p-y-md': padding === 'md' || paddingY === 'md',
            'p-x-md': padding === 'md' || paddingX === 'md',
            'p-y-sm': padding === 'sm' || paddingY === 'sm',
            'p-x-sm': padding === 'sm' || paddingX === 'sm',
            'p-y-xs': padding === 'xs' || paddingY === 'xs',
            'p-x-xs': padding === 'xs' || paddingX === 'xs',
            'noPadding': noPadding
        }, { 'just-ui-card_with-spoiler': withSpoiler }, { 'just-ui-card--dark': theme === Theme.dark }, { 'just-ui-card--with-hover': withHover }, { 'just-ui-card--force-hover': forceHover }) }),
        React.createElement(CardBody, null, children),
        iconComponent
            ? React.createElement("div", { className: 'just-ui-card_icon-component' }, iconComponent)
            : null));
}
