import React from 'react';
import { Icon, Button } from '@just-ai/just-ui';
import './style.scss';

export type PaginationProps = {
  currentPage: number;
  maxPage: number;
  onPageChange: (page: number) => void;
};

export default function Pagination(props: PaginationProps) {
  const { currentPage, maxPage, onPageChange } = props;
  return (
    <div className='pagination__container'>
      {currentPage > 1 && (
        <Button
          flat
          color='secondary'
          className='pagination__button nav_button prev-button'
          onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
        >
          <Icon name='farChevronLeft' />
        </Button>
      )}
      <Button
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
        flat
        color='secondary'
        className='pagination__button first-button'
      >
        1
      </Button>
      {currentPage > 4 && <div className='pagination__dots-block'>...</div>}
      {currentPage > 3 && currentPage < 5 && (
        <Button flat color='secondary' className='pagination__button' onClick={() => onPageChange(currentPage - 2)}>
          {currentPage - 2}
        </Button>
      )}
      {currentPage > 2 && (
        <Button flat color='secondary' className='pagination__button' onClick={() => onPageChange(currentPage - 1)}>
          {currentPage - 1}
        </Button>
      )}
      {currentPage !== 1 && currentPage !== maxPage && (
        <Button flat disabled color='secondary' className='pagination__button'>
          {currentPage}
        </Button>
      )}
      {maxPage - currentPage > 1 && (
        <Button
          flat
          color='secondary'
          className='pagination__button'
          onClick={() => currentPage < maxPage && onPageChange(currentPage + 1)}
        >
          {currentPage + 1}
        </Button>
      )}
      {maxPage - currentPage > 3 && <div className='pagination__dots-block'>...</div>}
      {maxPage - currentPage > 2 && maxPage - currentPage > 1 && maxPage - currentPage < 4 && (
        <Button
          flat
          color='secondary'
          className='pagination__button'
          onClick={() => currentPage < maxPage && onPageChange(currentPage + 2)}
        >
          {currentPage + 2}
        </Button>
      )}
      <Button
        onClick={() => onPageChange(maxPage)}
        disabled={currentPage === maxPage}
        flat
        color='secondary'
        className='pagination__button last-button'
      >
        {maxPage}
      </Button>
      {currentPage < maxPage && (
        <Button
          flat
          color='secondary'
          className='pagination__button nav_button forward-button'
          onClick={() => currentPage < maxPage && onPageChange(currentPage + 1)}
        >
          <Icon name='farChevronRight' />
        </Button>
      )}
    </div>
  );
}
