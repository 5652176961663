import React, { useState } from 'react';
import './MobileNotification.scss';
import { Icon, IconButton } from '@just-ai/just-ui';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE } from '../../../../Header/constants';
import { t } from '../../../../../localization';

export const MobileNotification = () => {
  const [isOpen, setIsOpen] = useState(true);
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });

  const handleNotificationClose = () => {
    setIsOpen(false);
  };

  return isOpen ? (
    <div className='training_mobile-notification'>
      <Icon name='falDesktop' size={isPhone ? 'lg' : '2x'} />
      <div style={{ width: 'fill-available' }}>
        <div>
          <p className={isPhone ? 'st-3' : 'st-1'}>{t('mobileNotificationTitle')}</p>
          {isPhone && <IconButton name='falTimes' onClick={handleNotificationClose} size='sm' />}
        </div>
        <p className={isPhone ? 'tp-5' : 'tp-3'}>{t('mobileNotificationSubtitle')}</p>
      </div>
      {!isPhone && <IconButton name='falTimes' onClick={handleNotificationClose} size='sm' />}
    </div>
  ) : null;
};
