import React, { Fragment } from 'react';
import { Progress } from 'reactstrap';
import { Tooltip } from '@just-ai/just-ui';
import { t } from '../../../../localization';

enum ProgressTypes {
  sucessful,
  processing,
  failed,
  noAudio,
}

type CatalogProgressProps = {
  sucessful: number;
  processing: number;
  failed: number;
  noAudio: number;
  total: number;
  catalogId: number;
};
const colorMap = {
  sucessful: 'success',
  processing: 'warning',
  failed: 'alert',
  noAudio: 'transparent',
};

const translationsMap = {
  sucessful: 'processedNeutral',
  processing: 'processing',
  failed: 'error',
  noAudio: 'noAudioError',
};

const CatalogProgressBar = (props: CatalogProgressProps) => {
  return (
    <Progress id={`progress-bar-${props.catalogId}`} multi>
      {Object.keys(ProgressTypes)
        .map(
          progressType =>
            props[progressType] > 0 && (
              <Fragment key={`progress_${progressType}_${props.catalogId}`}>
                <Progress bar color={colorMap[progressType]} value={(props[progressType] / props.total) * 100}>
                  <div className='progress-bar-tooltip-target' id={`${progressType}-progress-bar-${props.catalogId}`} />
                </Progress>
                <Tooltip placement='top' target={`${progressType}-progress-bar-${props.catalogId}`}>
                  {t(`${translationsMap[progressType]}`)} {props[progressType]} / {props.total}
                </Tooltip>
              </Fragment>
            )
        )
        .filter(element => Boolean(element))}
    </Progress>
  );
};

export default CatalogProgressBar;
