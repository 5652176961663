import { useEffect, useMemo, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useAmplitude } from 'react-amplitude-hooks';

import { NEW_LINE_ID, ReplicasBlock } from './components/VoiceLinesBlock/VoiceLinesBlock';
import { useAppContext } from '../../AppContext';
import { Markup } from '../../api/dubber/client';
import { replicasInitialValues, useReplicas } from './context/ProjectDataContex';
import ProjectsSidebar from './components/ProjectsSidebar';
import { SidebarPlaceholder } from './components/SidebarPlaceholder';
import { useVoices } from './context/VoicesContext';
import { AiWriter } from './components/AiWriter/AiWriter';
import { useToggle } from '@just-ai/just-ui';
import { ProjectPlayer } from './components/ProjectPlayer';

import './style.scss';
import ReactDOM from 'react-dom';

export type SelectedLine = {
  lineId: string;
  voiceId?: number;
  index?: number;
  text: string;
  modelId?: number;
  markupData?: Markup;
  presetId?: string;
};

export default function ProjectsPage() {
  let wrapperRef = useRef<HTMLDivElement>(null);
  const { logEvent } = useAmplitude();
  const { id } = useAppContext();
  const { fetchInitialReplicas, getSelectedReplica, setReplicaStore } = useReplicas();
  const { fetchInitialAvailableVoices } = useVoices();
  const match = useRouteMatch();
  const [aiWriterOpen, , , toggleAiWriter] = useToggle();

  useEffect(() => {
    fetchInitialReplicas();
    fetchInitialAvailableVoices();
    return () => {
      setReplicaStore(replicasInitialValues);
    };
  }, [fetchInitialAvailableVoices, fetchInitialReplicas, setReplicaStore]);

  useEffect(() => {
    logEvent('Page opened', {
      page_url: match.url,
      page_type: match.path,
      user_id: id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarShown = useMemo(() => {
    const selectedReplica = getSelectedReplica();

    return Boolean(
      selectedReplica?.voiceId && (!selectedReplica?.id.includes(NEW_LINE_ID) || selectedReplica?.text.length > 0)
    );
  }, [getSelectedReplica]);

  return (
    <div className='projects-page__container' ref={wrapperRef}>
      <ReplicasBlock wrapperRef={wrapperRef} openWriter={toggleAiWriter} />
      {sidebarShown ? <ProjectsSidebar /> : <SidebarPlaceholder />}
      <ProjectPlayer />
      {ReactDOM.createPortal(<AiWriter toggle={toggleAiWriter} isOpen={aiWriterOpen} />, document.body)}
    </div>
  );
}
