import React from 'react';
import { Helmet } from 'react-helmet';
import { AppContext } from '../AppContext';
import { t } from '../localization';

const documentData = {
  main: {
    keywords: 'озвучка текста синтезатор речи преобразовать текст синтез речи',
  },
  catalog: {
    keywords: '"синтезированный голос каталог голосов голос бота голоса для синтеза"',
  },
  library: {
    keywords: 'как зарабатывать голосом синтезировать свой голос как продавать свой голос',
  },
};

type PagesList = 'main' | 'library' | 'catalog' | 'createVoice' | 'account';

export default function withDocumentData(Component, page: PagesList) {
  return class extends React.Component {
    render() {
      return (
        <AppContext.Consumer>
          {state => (
            <>
              <Helmet>
                <title>{t(`documentTitle.${page}`, state.productName)}</title>
                {page !== 'account' && page !== 'createVoice' && (
                  <meta name='description' content={t(`documentDescription.${page}`)} />
                )}
                {page !== 'account' && page !== 'createVoice' && (
                  <meta name='keywords' content={documentData[page].keywords} />
                )}
              </Helmet>
              <Component {...this.props} />
            </>
          )}
        </AppContext.Consumer>
      );
    }
  };
}
