import React, { useCallback, useEffect, useReducer } from 'react';
import { t } from '../../../../localization';
import Banner from '../../components/Voice/Banner/Banner';
import Pagination from '../../../../components/Pagination';
import { Placeholder, TableRow } from '../../components/VoiceUsage';
import { Voice } from '../../model/Voice';
import { MAX_LOGS_PER_PAGE } from '../../constants';
import { SCREEN_WIDTH_MD } from '../../../Header/constants';
import { useLibraryContext } from '../../context/LibraryContext';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import { VoiceUsageSummary } from '../../../../api/analytics/client';
import useAlert from '../../../../utils/useAlert';
import { useMediaQuery } from 'react-responsive';
import { DynamicTitle } from '../../components/DynamicTitle/DynamicTitle';
import { useAppContext } from '../../../../AppContext';
import { useAccountContext } from '../../../Header/context/AccountContext';
import { BalanceHistoryView } from '../../../../api/supervisor/client';

import './UsagePage.scss';

type UsagePageProps = {
  voice?: Voice;
  fetchVoice?: () => Promise<void>;
  usageType: 'voiceUsage' | 'balanceUsage';
};

type UsageStateTypes = {
  maxPage: number;
  currentPage: number;
  logs: VoiceUsageSummary[] | BalanceHistoryView[];
  isLoading: boolean;
};
type UsageActionTypes =
  | { type: 'SET_MAX_PAGE'; payload: number }
  | { type: 'SET_CURRENT_PAGE'; payload: number }
  | { type: 'SET_LOGS_AND_MAX_PAGE'; payload: { data: VoiceUsageSummary[] | BalanceHistoryView[]; count: number } }
  | { type: 'SET_LOADING'; payload: boolean };

const initialState: UsageStateTypes = {
  maxPage: 0,
  currentPage: 0,
  logs: [],
  isLoading: true,
};

const usagePageReducer = (state: UsageStateTypes, action: UsageActionTypes) => {
  switch (action.type) {
    case 'SET_CURRENT_PAGE': {
      return { ...state, currentPage: action.payload };
    }
    case 'SET_MAX_PAGE': {
      return { ...state, maxPage: action.payload };
    }
    case 'SET_LOGS_AND_MAX_PAGE': {
      return { ...state, logs: action.payload.data, maxPage: Math.ceil(action.payload.count / MAX_LOGS_PER_PAGE) };
    }
    case 'SET_LOADING': {
      return { ...state, isLoading: action.payload };
    }
  }
};

export default function UsagePage({ voice, fetchVoice, usageType }: UsagePageProps) {
  const [state, dispatch] = useReducer(usagePageReducer, initialState);
  const { maxPage, currentPage, logs, isLoading } = state;
  const { getVoiceUsage } = useLibraryContext();
  const { id: voiceId } = useParams<{ id: string }>();
  const { logEvent } = useAmplitude();
  const { defaultErrorAlert } = useAlert();
  const mdScreenDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_MD}px)` });
  const match = useRouteMatch();
  const { id, productName } = useAppContext();
  const { getBalanceHistory } = useAccountContext();

  useEffect(() => {
    logEvent('Page opened', { page_url: match.url, page_type: match.path, user_id: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const data =
        usageType === 'voiceUsage'
          ? await getVoiceUsage(Number(voiceId), currentPage, MAX_LOGS_PER_PAGE)
          : await getBalanceHistory(currentPage, MAX_LOGS_PER_PAGE);
      dispatch({ type: 'SET_LOGS_AND_MAX_PAGE', payload: data });
    } catch (error) {
      defaultErrorAlert(error);
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [currentPage, defaultErrorAlert, getBalanceHistory, getVoiceUsage, usageType, voiceId]);

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData]);

  const isPagination = logs && logs.length > 0 && maxPage > 1 && !isLoading;

  const pageChangeHandler = useCallback(
    (currentPage: number) => {
      dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 });
      logEvent('Page changed', {
        userId: id,
        page: currentPage,
      });
    },
    [id, logEvent]
  );
  return (
    <div className='voice-page__usage usage'>
      <DynamicTitle
        title={t('documentTitle.usage', usageType === 'voiceUsage' ? voice && voice.name : t('account'), productName)}
      />
      {!mdScreenDown && usageType === 'voiceUsage' && <h1 className='tp-1'>{t('usageHistory')}</h1>}
      {usageType === 'balanceUsage' && <h1 className='tp-1'>{t('AccountPage:BalanceHistory:PageTitle')}</h1>}
      {!isLoading &&
        (logs.length > 0 ? (
          <>
            <div className='usage__table'>
              <div className='usage__table-header'>
                <div className='usage__table-row'>
                  <p>{t('date')}</p>
                  <p>{t('duration')}</p>
                  {usageType === 'balanceUsage' && <p>{t('amount')}</p>}
                </div>
              </div>
              <div className='usage__table-body'>
                {logs.map((log: BalanceHistoryView | VoiceUsageSummary) => {
                  if ('amount' in log) {
                    return <TableRow key={log.duration} duration={log.duration} date={log.date} amount={log.amount} />;
                  }
                  return <TableRow key={log.duration} duration={log.duration} date={log.date} />;
                })}
              </div>
            </div>
            {isPagination && (
              <Pagination maxPage={maxPage} currentPage={currentPage + 1} onPageChange={pageChangeHandler} />
            )}
            {usageType === 'voiceUsage' && voice && voice.isAvailableForPublish && !voice.isPublished && fetchVoice && (
              <div className='usage__banner'>
                <Banner voice={voice} fetchVoice={fetchVoice} type='usage' />
              </div>
            )}
          </>
        ) : (
          <Placeholder balancePlaceholder={usageType === 'balanceUsage'} />
        ))}
    </div>
  );
}
