import React, { createRef } from 'react';
import { Button, Icon, IconButton, Tooltip } from '@just-ai/just-ui';
import { withRouter, RouteComponentProps } from 'react-router';
import { SampleStateEnum, SampleView } from '../../../../../api/facade/client';
import { LibraryContext } from '../../../context/LibraryContext';
import { withAppContext, AppContextType } from '../../../../../AppContext';
import { statusesInSampleTableGenerator } from '../../../constants';
import localization, { t } from '../../../../../localization';
import withAmplitude, { AmplitudeHOCProps } from '../../../../../components/HOC/withAmplutide';
import { convertSampleReferenceToAudioLink, generateSampleRoute } from '../../../../../utils';
import SamplePlayer from '../../SamplePlayer';
import SampleRecord from './SampleRecord';
import { NoMediaPermission } from './NoMediaPermission';
import { SampleTitleInput, SampleCommentInput, SampleTextInput, Glass } from '../';
import { CustomModal, CustomModalDataLoss, CustomModalDeleteSample } from '../../CustomModal';
import './Sample.scss';

type SampleProps = RouteComponentProps<{ sampleId: string }> &
  AppContextType & {
    voiceId: number;
    samples: SampleView[];
    selectedSampleIndex: number;
    autoScrollIndex: number;
    catalogId: number;
    showErrors: boolean;
    currentTab: string;
    pageBlocked: boolean;
    setPageBlocked: (value: boolean) => void;
    fetchData: () => void;
    fetchSamples: (samples?: number) => void;
    goToSample: (direction: 'prev' | 'same' | 'next') => void;
    setProcessingStatus: (sampleId: number) => void;
    setSampleIndex: (sample: SampleView) => void;
    changeTab: (value: string) => void;
  } & AmplitudeHOCProps;

class State {
  isLoading: boolean = true;
  sampleData?: SampleView;
  sampleText: string = '';
  playingId?: number;
  playing: boolean = false;
  localAudio: Blob | null = null;
  localAudioUrl: string = '';
  modalOpen: boolean = false;
  dataLossModal: boolean = false;
  isRecording: boolean = false;
  currentDevice?: MediaDeviceInfo;
  inputDevices: MediaDeviceInfo[] = [];
  cancelClicked: boolean = false;
  saveAudioLocally: boolean = false;
  hasPermission: boolean = true;
  uploadAudioInProgress: boolean = false;
}

class Sample extends React.Component<SampleProps, State> {
  static contextType = LibraryContext;
  context!: React.ContextType<typeof LibraryContext>;
  pageBlock?: () => void;

  targetLink = createRef() as React.MutableRefObject<any>;

  state = new State();

  componentDidMount() {
    this.fetchSample();
    document.body.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (prevProps.match.params.sampleId !== this.props.match.params.sampleId) {
      this.setLocalAudio(null);
      this.fetchSample();
    }
    if ((this.state.localAudio && !prevState.localAudio) || (this.state.isRecording && !prevState.isRecording)) {
      this.props.setPageBlocked(true);
      this.pageBlock = this.props.history.block((targetLocation: any) => {
        this.targetLink.current = targetLocation;
        this.setState({ dataLossModal: true });
        if (this.state.isRecording) this.setState({ saveAudioLocally: true });
        return false;
      });
    }
    if (!this.state.cancelClicked && prevState.cancelClicked) {
      this.pageBlock && this.pageBlock();
      this.props.setPageBlocked(false);
    }
    if (
      this.props.currentTab !== prevProps.currentTab &&
      /^(blocked)/g.test(this.props.currentTab) &&
      this.props.pageBlocked &&
      !prevProps.pageBlocked
    ) {
      this.setState({ dataLossModal: true });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.handleKeyDown);
  }

  fetchSample = async () => {
    this.setState({ isLoading: true });
    try {
      const sample = await this.context.getSample(this.props.voiceId, Number(this.props.match.params.sampleId));
      const sampleText = await this.context.getSampleText(Number(this.props.match.params.sampleId), this.props.voiceId);
      this.setState({ sampleData: sample?.sampleData, sampleText });
    } catch (error) {
      this.props.defaultAlert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  updateText = async (value: string) => {
    try {
      await this.context.updateSampleText(+this.props.match.params.sampleId, value);
      await Promise.all([this.props.fetchSamples(this.props.samples.length), this.fetchSample()]);
      if (this.props.samples.length > 0 && this.props.showErrors) {
        this.props.history.push(
          generateSampleRoute(this.props.voiceId, this.props.catalogId, this.props.samples[0]?.id)
        );
      }
      this.updateSampleAmplitude('success');
    } catch (error) {
      this.updateSampleAmplitude('failed');
      this.props.defaultAlert(error);
    }
  };

  updateTitle = async (value: string) => {
    if (!this.state.sampleData) return;
    try {
      await this.context.updateSample(+this.props.match.params.sampleId, {
        comment: this.state.sampleData.comment,
        catalogId: this.props.catalogId,
        title: value,
      });
      this.updateSampleAmplitude('success');
      this.props.fetchSamples(this.props.samples.length);
    } catch (error) {
      this.updateSampleAmplitude('failed');
      this.props.defaultAlert(error);
    }
  };

  updateComment = async (value: string) => {
    if (!this.state.sampleData) return;
    try {
      await this.context.updateSample(+this.props.match.params.sampleId, {
        comment: value,
        catalogId: this.props.catalogId,
        title: this.state.sampleData.title,
      });
      this.updateSampleAmplitude('success');
    } catch (error) {
      this.updateSampleAmplitude('failed');
      this.props.defaultAlert(error);
    }
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (!this.state.sampleData || this.state.isLoading) return;
    if (
      event.target &&
      ['sampleText', 'sampleTitle', 'sampleComment', 'catalogTitle'].includes(
        (event.target as HTMLInputElement | HTMLTextAreaElement).name
      )
    ) {
      return;
    }
    if (event.key === ' ') {
      event.stopPropagation();
      event.preventDefault();
      if (this.state.localAudio || this.state.sampleData.hasAudio) {
        this.setState((prevState: State) => ({ playing: !prevState.playing }));
      } else {
        if (!this.state.isRecording) {
          this.toggleRecording();
        } else {
          this.toggleSaveAudioLocally();
        }
      }
    }
    if (event.key === 'Backspace') {
      if (this.state.isRecording) {
        this.setState({ isRecording: false, cancelClicked: true });
      }
      if (this.state.localAudio) {
        this.clearLocalAudio();
      } else if (
        this.state.sampleData.sampleState !== SampleStateEnum.PROCESSING &&
        this.state.sampleData.sampleState !== SampleStateEnum.NOAUDIO
      ) {
        this.deleteAudio();
      }
    }
    if (event.key === 'Enter' && this.state.localAudio) {
      this.updateSampleAudio();
    }
  };

  setLocalAudio = (localAudio: Blob | null) => {
    this.setState({ localAudio, localAudioUrl: localAudio ? URL.createObjectURL(localAudio) : '' });
  };

  toggleModal = () => {
    if (this.state.isRecording) {
      this.setState({ isRecording: false, cancelClicked: true });
    }
    this.setState((prevState: State) => ({ modalOpen: !prevState.modalOpen }));
  };

  toggleDataLossModal = async () => {
    this.setState((prevState: State) => ({
      dataLossModal: !prevState.dataLossModal,
      localAudio: null,
      isRecording: false,
      cancelClicked: false,
    }));
    this.pageBlock && this.pageBlock();
    await this.props.setPageBlocked(false);
    if (/^(blocked)/g.test(this.props.currentTab)) {
      this.props.changeTab(this.props.currentTab.split('blocked')[1]);
    } else {
      this.props.history.push(this.targetLink.current);
    }
  };

  handleDeleteSample = async () => {
    if (!this.state.sampleData) return;

    try {
      await this.context.deleteSample(this.state.sampleData.id);
      this.props.successAlert(`${t('deleteSampleAlert', this.state.sampleData.title || t('untitled'))}`);
      this.pageBlock && this.pageBlock();
      this.props.setPageBlocked(false);
      this.setState({ localAudio: null });
      await Promise.all([this.props.fetchData(), this.props.fetchSamples()]);
      if (this.props.selectedSampleIndex === this.props.samples.length) {
        this.props.goToSample('prev');
      } else {
        this.props.goToSample('same');
      }
    } catch (error) {
      this.props.defaultAlert(error);
    } finally {
      this.toggleModal();
    }
  };

  updateSampleAudio = async () => {
    if (!this.state.sampleData) return;
    try {
      this.setState({ isLoading: true });
      document.body.style.pointerEvents = 'none';

      await this.context.uploadRecordToSample(this.state.sampleData.id, this.state.localAudio);
      await this.setState({ localAudio: null });
      this.props.successAlert(t('recordSaved'));
      this.props.logEvent('Save sample recording', {
        userId: this.props.id,
        catalogId: this.props.catalogId,
        sampleId: this.state.sampleData.id,
        url: this.props.match.url,
        result: 'success',
      });
      await this.fetchSample();
      this.props.setProcessingStatus(this.state.sampleData.id);
      this.pageBlock && this.pageBlock();
      await this.props.setPageBlocked(false);

      if (this.props.autoScrollIndex !== this.props.samples.length - 1 && !this.state.dataLossModal) {
        await this.props.setSampleIndex(this.state.sampleData);
        this.props.goToSample('next');
      }
    } catch (error) {
      this.props.defaultAlert(error);
      this.props.logEvent('Save sample recording', {
        userId: this.props.id,
        catalogId: this.props.catalogId,
        sampleId: this.state.sampleData.id,
        url: this.props.match.url,
        result: 'failed',
      });
    } finally {
      this.setState({ isLoading: false });
      document.body.style.pointerEvents = 'all';
      if (this.state.dataLossModal) {
        this.setState({ dataLossModal: false });
        /^(blocked)/g.test(this.props.currentTab)
          ? this.props.changeTab(this.props.currentTab.split('blocked')[1])
          : this.props.history.push(this.targetLink.current);
      }
    }
  };

  clearLocalAudio = () => {
    this.setState({ localAudio: null });
    this.pageBlock && this.pageBlock();
    this.props.setPageBlocked(false);
    this.props.logEvent('Remove just recorded audio', {
      userId: this.props.id,
      catalogId: this.props.catalogId,
      sampleId: this.state.sampleData?.id,
      url: this.props.match.url,
    });
  };

  deleteAudio = async () => {
    if (!this.state.sampleData) return;
    try {
      await this.context.deleteRecordFromSample(this.state.sampleData.id);
      this.fetchSample();
      this.props.logEvent('Remove recorded audio', {
        userId: this.props.id,
        catalogId: this.props.catalogId,
        sampleId: this.state.sampleData?.id,
        url: this.props.match.url,
        result: 'success',
      });
    } catch (error) {
      this.props.defaultAlert(error);
      this.props.logEvent('Remove just recorded audio', {
        userId: this.props.id,
        catalogId: this.props.catalogId,
        sampleId: this.state.sampleData?.id,
        url: this.props.match.url,
        result: 'failed',
      });
    }
  };

  toggleRecording = () => {
    this.setState(
      (prevState: State) => ({ isRecording: !prevState.isRecording }),
      () => {
        if (this.state.isRecording) {
          this.props.logEvent('Start sample recording', {
            userId: this.props.id,
            catalogId: this.props.catalogId,
            sampleId: this.state.sampleData?.id,
            url: this.props.match.url,
          });
        }
      }
    );
  };

  setCurrentDevice = (value: MediaDeviceInfo) => {
    this.setState({ currentDevice: value });
  };

  cancelClicked = (value: boolean) => {
    this.setState({ cancelClicked: value });
  };

  checkPermission = () => {
    const permissionName = 'microphone' as PermissionName;
    if (window.navigator.userAgent.indexOf('Chrome') > -1) {
      navigator.permissions.query({ name: permissionName }).then(async obj => {
        if (obj.state === 'prompt') {
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(() => this.getDevices())
            .catch(() => this.setState({ hasPermission: false }));
        }
        if (obj.state === 'granted') {
          this.getDevices();
        }
        if (obj.state === 'denied') {
          this.setState({ hasPermission: false });
        }
      });
    } else {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => this.getDevices())
        .catch(() => this.setState({ hasPermission: false }));
    }
  };

  getDevices = async () => {
    const rawDevices = await navigator.mediaDevices.enumerateDevices();
    const filteredDevices = rawDevices.filter(device => device.kind === 'audioinput');
    this.setState(
      { inputDevices: [...filteredDevices] },
      () => !this.state.currentDevice && this.setState({ currentDevice: filteredDevices[0] })
    );
  };

  toggleSaveAudioLocally = () => {
    this.setState(prevState => ({
      saveAudioLocally: !prevState.saveAudioLocally,
    }));
  };

  updateSampleAmplitude = (result: 'success' | 'failed') => {
    this.props.logEvent('Edit sample', {
      userId: this.props.id,
      catalogId: this.props.catalogId,
      sampleId: this.state.sampleData?.id,
      url: this.props.match.url,
      result,
    });
  };
  render() {
    if (!this.state.sampleData) return null;
    const statusesInSampleTable = statusesInSampleTableGenerator(localization);
    return (
      <div className='catalog-page__sample' data-test-id='samplePage.sample'>
        <Glass isLoading={this.state.isLoading} />
        <div className='catalog-page__sample-header'>
          <div>
            <Icon
              name={statusesInSampleTable[this.state.sampleData.sampleState].name}
              color={statusesInSampleTable[this.state.sampleData.sampleState].color}
              id='sampleStatus'
            />
            <Tooltip placement='top' target='sampleStatus' data-test-id='samplePage.sampleStatusTooltip'>
              {statusesInSampleTable[this.state.sampleData.sampleState].text}
            </Tooltip>
            <SampleTitleInput defaultValue={this.state.sampleData.title || t('untitled')} save={this.updateTitle} />
          </div>
          <Button
            outline
            color='secondary'
            onClick={() => this.props.goToSample('prev')}
            disabled={this.props.selectedSampleIndex === 0}
          >
            Prev
          </Button>
          <Button
            outline
            color='secondary'
            onClick={() => this.props.goToSample('next')}
            disabled={this.props.selectedSampleIndex === this.props.samples.length - 1}
          >
            Next
          </Button>
          <span id='sampleDelete'>
            <IconButton
              flat
              square
              name='falTrashAlt'
              color='secondary'
              disabled={this.state.sampleData.sampleState === SampleStateEnum.PROCESSING}
              className='catalog-page__sample-delete'
              onClick={this.toggleModal}
            />
            {this.state.sampleData.sampleState === SampleStateEnum.PROCESSING && (
              <Tooltip placement='left' target='sampleDelete' textAlign='center'>
                {t('waitUntilEnd')}
              </Tooltip>
            )}
          </span>
        </div>
        <SampleCommentInput defaultValue={this.state.sampleData.comment || ''} save={this.updateComment} />
        <div className='catalog-page__sample-media'>
          <SampleTextInput defaultValue={this.state.sampleText!} save={this.updateText} />
          {this.state.hasPermission &&
            ((this.state.sampleData.hasAudio && this.state.sampleData.reference) || this.state.localAudio ? (
              <SamplePlayer
                sampleId={this.state.sampleData.id}
                playingId={this.state.playingId}
                setPlayingId={(playing: boolean) => {
                  this.setState({ playingId: this.state.sampleData?.id });
                }}
                audioUrl={
                  this.state.localAudioUrl || convertSampleReferenceToAudioLink(this.state.sampleData.reference)
                }
                isPlaying={this.state.playing}
                saveAudio={this.updateSampleAudio}
                clearAudio={this.state.localAudio ? this.clearLocalAudio : this.deleteAudio}
                sampleState={this.state.sampleData.sampleState}
                isLocal={!!this.state.localAudio}
                uploadInProgress={this.state.uploadAudioInProgress}
              />
            ) : (
              <SampleRecord
                setLocalAudio={this.setLocalAudio}
                isRecording={this.state.isRecording}
                toggleRecording={this.toggleRecording}
                setCurrentDevice={this.setCurrentDevice}
                inputDevices={this.state.inputDevices}
                currentDevice={this.state.currentDevice}
                localAudio={this.state.localAudio}
                setCancelClick={this.cancelClicked}
                isCancelClicked={this.state.cancelClicked}
                checkPermission={this.checkPermission}
                saveAudioLocally={this.state.saveAudioLocally}
                toggleSaveAudioLocally={this.toggleSaveAudioLocally}
              />
            ))}
          {!this.state.hasPermission && <NoMediaPermission />}
        </div>
        <CustomModal
          isOpen={this.state.modalOpen}
          toggle={this.toggleModal}
          title={`${t('deleteExactSample', this.state.sampleData.title || t('untitled'))}`}
          modalCard
        >
          <CustomModalDeleteSample cancelButtonClick={this.toggleModal} actionButtonClick={this.handleDeleteSample} />
        </CustomModal>
        <CustomModal
          position='center'
          isOpen={this.state.dataLossModal}
          toggle={this.toggleDataLossModal}
          title={`${t('saveAudio')}?`}
          modalCard
        >
          <CustomModalDataLoss
            cancelButtonClick={this.toggleDataLossModal}
            actionButtonClick={this.updateSampleAudio}
          />
        </CustomModal>
      </div>
    );
  }
}

export default withAmplitude({})(withRouter(withAppContext(Sample)));
