import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { t } from '../../../../../localization';
import { Button } from '@just-ai/just-ui';
import { Voice } from '../../../model/Voice';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE } from '../../../../Header/constants';

export default function BannerClosed({
  voice,
  handlePanelOpen,
}: {
  voice: Voice | undefined;
  handlePanelOpen: () => void;
}) {
  const { id: voiceId } = useParams<{ id: string }>();
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  return (
    <div className='banner__inner'>
      <div className='banner__text'>
        {isPhone ? <h4>{t('makeMoney')}</h4> : <h3>{t('makeMoney')}</h3>}
        {voice?.isAvailableForPublish ? <p>{t('publishVoice: Desc')}</p> : <p>{t('publishVoice: toLearn')}</p>}
      </div>
      {voice?.isAvailableForPublish ? (
        <Button onClick={handlePanelOpen} color='primary' data-test-id='controlPage.publishBtn'>
          {t('publishVoice: Button')}
        </Button>
      ) : (
        <Link to={`/my/voice/${voiceId}/training`}>
          <Button outline color='secondary'>
            {t('toVoiceLearn')}
          </Button>
        </Link>
      )}
    </div>
  );
}
