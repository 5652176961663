import { Checkbox } from '@just-ai/just-ui';
import React from 'react';
import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';
import { languagesMap } from '../../constants';
import { AllVoicesPageFilters } from '../../pages/AllVoicesPage';

type LanguageFilterProps = {
  filter: AllVoicesPageFilters;
  handleFilter: (field: keyof AllVoicesPageFilters, value?: any) => void;
  mobile?: boolean;
};

export const LanguageFilter = ({ filter, handleFilter, mobile }: LanguageFilterProps) => {
  const { configData } = useAppContext();
  return (
    <div className='voices-catalog__side-filters__language'>
      <p className={`${!mobile && 'st-2'}`}>{t('language')}</p>
      {configData.supportedVoiceLanguages.map(language => (
        <Checkbox
          name={language}
          key={language}
          color='primary'
          label={t(languagesMap[language])}
          value={filter.languages.includes(language)}
          onChange={() => handleFilter('languages', language)}
          data-test-id={`allVoicesPage.${language}filter`}
        />
      ))}
    </div>
  );
};
