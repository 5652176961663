import { Button, Icon } from '@just-ai/just-ui';
import classNames from 'classnames';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { t } from '../../../../localization';
import { AllVoicesPageFilters } from '../../pages/AllVoicesPage';
import { CustomModal, CustomModalMobileFilter } from '../CustomModal';
import { useSticky } from '../../hooks/useSticky';
import { SCREEN_WIDTH_MD } from '../../../Header/constants';
import { getActiveFilters } from '../../../../utils';

type VoicesFilterProps = {
  filter: AllVoicesPageFilters;
  initialFilters: AllVoicesPageFilters;
  setFilter: (filter: AllVoicesPageFilters) => void;
};

export const MobileFilter = ({ filter, setFilter, initialFilters }: VoicesFilterProps) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [savedFilters, setSavedFilters] = useState<AllVoicesPageFilters>(initialFilters);
  const filterBufferRef = useRef<AllVoicesPageFilters>();
  const mdScreenDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_MD}px)` });
  const observerRef = useRef<HTMLDivElement>(null);
  const isSticky = useSticky({ refElement: observerRef, treshold: 1 });

  useEffect(() => {
    setSavedFilters(filter);
  }, [filter]);

  useEffect(() => {
    filterBufferRef.current = filter;
    setSavedFilters(filter);
  }, [filter]);

  const closeFilterWithoutSave = useCallback(() => {
    if (!filterBufferRef.current) return;
    setSavedFilters(filterBufferRef.current);
    setFilter(filterBufferRef.current);
    setFilterOpen(false);
  }, [setFilter]);

  const handleModalFilter = useCallback((field: keyof AllVoicesPageFilters, value?: any) => {
    if (field === 'super') {
      setSavedFilters(prevState => ({ ...prevState, super: !prevState.super }));
    }
    if (field === 'gender') {
      setSavedFilters(prevState => ({
        ...prevState,
        gender: prevState.gender?.includes(value)
          ? [...prevState.gender].filter(item => item !== value)
          : [...prevState.gender, value],
      }));
    }
    if (field === 'languages') {
      setSavedFilters(prevState => ({
        ...prevState,
        languages: prevState.languages.includes(value)
          ? [...prevState.languages].filter(language => language !== value)
          : [...prevState.languages, value],
      }));
    }
  }, []);

  const handleConfirm = useCallback(() => {
    setFilter(savedFilters);
    setFilterOpen(false);
  }, [savedFilters, setFilter]);

  const handleReset = useCallback(() => {
    setSavedFilters(initialFilters);
  }, [initialFilters]);

  return (
    <div className={classNames('voices-catalog__side-filters-mobile', { sticky: isSticky })} ref={observerRef}>
      <div>
        <Button color='primary' outline onClick={() => setFilterOpen(true)} data-test-id='allVoices.mobileFilter'>
          <Icon name='falFilter' />
          {t('allFilters')}
          <div className='active-filters' data-test-id='allVoices.activeFilters'>
            {savedFilters && getActiveFilters({ initialObject: initialFilters, currentObject: savedFilters })}
          </div>
        </Button>
        {/* TODO return when working <Button color='primary' outline>
          <Icon name='falSortAlt' size='sm' />
          {t('sort:Popularity').charAt(0).toUpperCase() + t('sort:Popularity').slice(1)}
        </Button> */}
      </div>
      <CustomModal
        isOpen={filterOpen}
        toggle={closeFilterWithoutSave}
        title={
          <div className='custom-modal__catalog-filter'>
            <h4>{t('filter')}</h4>
            <div className='active-filters'>
              {savedFilters && getActiveFilters({ initialObject: initialFilters, currentObject: savedFilters })}
            </div>
          </div>
        }
        fullscreen={mdScreenDown}
        customClassName='catalog-filter'
      >
        <CustomModalMobileFilter
          filter={savedFilters}
          handleFilter={handleModalFilter}
          cancelButtonClick={handleReset}
          handleModalAction={handleConfirm}
          cancelDisabled={
            !(savedFilters && getActiveFilters({ initialObject: initialFilters, currentObject: savedFilters }))
          }
        />
      </CustomModal>
    </div>
  );
};
