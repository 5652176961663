import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import createFastContext from '../../../utils/createFastContext';
import { NO_FUNDS_ERROR_CODE } from '../../../utils/error';
import useDefaultAlert from '../../../utils/useAlert';
import { DownloadTypes } from '../constants';
import { SynthBuffer } from '../model/VoiceLines';
import { useProjectContext } from './ProjectsContext';

export type PlayingModes = 'SINGLE' | 'ALL';
export const FULL_PROJECT_ID = ' fullProject';

type PlayerStore = {
  playingId: string;
  loading: boolean;
  synthBuffer: Pick<SynthBuffer, 'id' | 'synthUrl'>[];
  playerRef: React.RefObject<HTMLAudioElement> | null;
  playingMode: PlayingModes;
  projectLoading: string;
  projectDownloading: boolean;
  historyPlaying: boolean;
};

const playerInitialValues: PlayerStore = {
  playingId: '',
  loading: false,
  synthBuffer: [],
  playerRef: null,
  playingMode: 'ALL',
  projectLoading: '',
  projectDownloading: false,
  historyPlaying: false,
};

const { Provider, useStore } = createFastContext(playerInitialValues);

export const usePlayerStore = () => useStore(store => store);

export const PlayerProvider = ({ children }: { children: React.ReactNode }) => {
  return <Provider>{children}</Provider>;
};

export const usePlayer = () => {
  const [
    { playerRef, playingId, loading, projectDownloading, synthBuffer, playingMode, projectLoading, historyPlaying },
    setStore,
  ] = useStore(store => store);
  const { projectId } = useParams<{ projectId: string }>();
  const { downloadProject: downloadProjectReq, getDownloadStatus, getActiveDownloads } = useProjectContext();

  const { defaultErrorAlert } = useDefaultAlert();

  const setPlayerLoading = useCallback(
    (value: boolean) => {
      setStore({ loading: value });
    },
    [setStore]
  );

  const awaitForStatus = useCallback(
    async (downloadId: string) => {
      try {
        const statusData = await getDownloadStatus(projectId, downloadId);
        switch (statusData.status) {
          case 'IN_PROGRESS': {
            await new Promise(resolve => setTimeout(resolve, 3000));
            return await awaitForStatus(downloadId);
          }
          case 'DONE': {
            return statusData.fileUrl;
          }
          case 'CANCELED': {
            break;
          }
          case 'NO_FUNDS': {
            if (statusData.fileUrl) {
              defaultErrorAlert(NO_FUNDS_ERROR_CODE);
              return statusData.fileUrl;
            }
            throw new Error(NO_FUNDS_ERROR_CODE);
          }
          case 'ERROR': {
            throw new Error('There was an error during synthesis');
          }
        }
      } catch (error) {
        throw error;
      }
    },
    [defaultErrorAlert, getDownloadStatus, projectId]
  );

  const setPlayingMode = useCallback(
    (value: PlayingModes) => {
      if (value !== playingMode && playerRef?.current) {
        playerRef.current.removeAttribute('src');
      }
      setStore({ playingMode: value, playingId: '' });
    },
    [playerRef, playingMode, setStore]
  );

  const setPlayerRef = useCallback(
    (ref: React.RefObject<HTMLAudioElement>) => {
      setStore({ playerRef: ref });
    },
    [setStore]
  );

  const setPlayingId = useCallback(
    (value: string) => {
      setStore({ playingId: value });
    },
    [setStore]
  );
  const setReplicaSynthBuffer = useCallback(
    ({ id, url }: { id: string; url: string }) => {
      const newSynthArr = [...synthBuffer];
      const indexOfItem = newSynthArr.findIndex(synthObj => synthObj.id === id);
      if (indexOfItem === -1) {
        newSynthArr.push({ id, synthUrl: url });
      } else {
        newSynthArr[indexOfItem].synthUrl = url;
      }
      setStore({ synthBuffer: newSynthArr });
    },
    [setStore, synthBuffer]
  );
  const deleteProjectBuffer = useCallback(() => {
    const newSynthArr = [...synthBuffer];
    const indexOfFullProject = newSynthArr.findIndex(synthObj => synthObj.id === FULL_PROJECT_ID);
    if (indexOfFullProject === -1) return;
    newSynthArr.splice(indexOfFullProject, 1);
    setStore({ synthBuffer: newSynthArr });
  }, [setStore, synthBuffer]);

  const deleteSynthBufferRecord = useCallback(
    (id: string) => {
      const newSynthArr = [...synthBuffer];
      const indexOfItem = newSynthArr.findIndex(synthObj => synthObj.id === id);
      const indexOfFullProject = newSynthArr.findIndex(synthObj => synthObj.id === FULL_PROJECT_ID);
      if (indexOfItem === -1 && indexOfFullProject === -1) return;
      newSynthArr.splice(indexOfItem, 1);
      setStore({ synthBuffer: newSynthArr });
      deleteProjectBuffer();
    },
    [deleteProjectBuffer, setStore, synthBuffer]
  );

  const getSynthBufferById = useCallback(
    (id: string) => {
      const indexOfItem = synthBuffer.findIndex(synthObj => synthObj.id === id);
      return synthBuffer[indexOfItem];
    },
    [synthBuffer]
  );

  const downloadFile = useCallback((fileUrl: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.click();
  }, []);

  const getFullProject = useCallback(
    async (format: DownloadTypes) => {
      try {
        const { downloadId } = await downloadProjectReq(projectId, format);
        const projectUrl = await awaitForStatus(downloadId);
        if (projectUrl && format !== 'ZIP') {
          setReplicaSynthBuffer({ url: projectUrl, id: FULL_PROJECT_ID });
        }
        return projectUrl || '';
      } catch (error) {
        throw error;
      }
    },
    [awaitForStatus, downloadProjectReq, projectId, setReplicaSynthBuffer]
  );

  const downloadProject = useCallback(
    async (format: DownloadTypes) => {
      try {
        setStore({ projectDownloading: true });
        const fileUrl = (await getFullProject(format)) || '';
        downloadFile(fileUrl);
        setStore({ projectDownloading: false });
      } catch (error) {
        setStore({ projectDownloading: false });
        defaultErrorAlert(error);
      }
    },
    [defaultErrorAlert, downloadFile, getFullProject, setStore]
  );
  const initialCheck = useCallback(async () => {
    try {
      const downloadsArr = await getActiveDownloads(projectId);
      if (!downloadsArr[0] || !playerRef?.current) return;
      const audioUrl = await awaitForStatus(downloadsArr[0]);
      playerRef.current.src = audioUrl || '';
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [awaitForStatus, defaultErrorAlert, getActiveDownloads, playerRef, projectId]);

  const findReplicaIdBySynthBuffer = useCallback(
    (url: string) => {
      const itemIndex = synthBuffer.findIndex(buffer => buffer.synthUrl === url);
      if (itemIndex === -1) return '';
      return synthBuffer[itemIndex].id;
    },
    [synthBuffer]
  );
  return {
    setPlayerLoading,
    setPlayerRef,
    setPlayingId,
    setPlayerStore: setStore,
    playerRef,
    playingId,
    playerLoading: loading,
    synthBuffer,
    setReplicaSynthBuffer,
    deleteSynthBufferRecord,
    getSynthBufferById,
    getFullProject,
    playingMode,
    setPlayingMode,
    downloadFile,
    downloadProject,
    initialCheck,
    projectLoading,
    findReplicaIdBySynthBuffer,
    projectDownloading,
    historyPlaying,
    deleteProjectBuffer,
  };
};
