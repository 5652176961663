import React, { useCallback, useEffect, useRef } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Icon, Button } from '@just-ai/just-ui';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { AnimatePresence, motion } from 'framer-motion';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useAmplitude } from 'react-amplitude-hooks';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import { useAvatarContext } from '../../context/AvatarContext';
import { useLibraryContext } from '../../context/LibraryContext';
import { t } from '../../../../localization';
import { getCroppedImg } from '../../../../utils';
import { MAX_AVATAR_SIZE } from '../../constants';
import { SCREEN_WIDTH_TABLET, SCREEN_WIDTH_PHONE } from '../../../Header/constants';
import useAlert from '../../../../utils/useAlert';
import { VoiceView } from '../../../../api/facade/client';
import { useAppContext } from '../../../../AppContext';

import './AvatarModal.scss';

type AvatarModalProps = {
  isOpen: boolean;
  voice: VoiceView;
  toggle: () => void;
};

export default function AvatarModal({ isOpen, toggle, voice }: AvatarModalProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>();
  const { defaultErrorAlert, successAlert } = useAlert();
  const { id: voiceId } = useParams<{ id: string }>();
  const { uploadVoiceAvatar } = useLibraryContext();
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const { logEvent } = useAmplitude();
  const { id } = useAppContext();
  const match = useRouteMatch();
  const isTablet = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const {
    croppedImage,
    image,
    step,
    crop,
    completedCrop,
    isLoading,
    setStep,
    reset,
    setImage,
    setIsLoading,
    setCrop,
    setCompletedCrop,
    setCroppedImage,
    setAvatar,
  } = useAvatarContext();

  const handleCloseModal = useCallback(() => {
    toggle();
    reset();
  }, [reset, toggle]);

  const setUpImage = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      const setImageReader = () => {
        setImage(reader.result as string, voiceId);
        setStep(2);
        reader.removeEventListener('load', setImageReader);
      };

      reader.addEventListener('load', setImageReader);
    },
    [setImage, setStep, voiceId]
  );

  const validateFile = useCallback(
    (file: File) => {
      if (!(file.type === 'image/jpeg' || file.type === 'image/png') || file.size > MAX_AVATAR_SIZE) {
        inputRef.current!.value = '';
        return defaultErrorAlert(
          `${t('errorLoading')}. ${file.size > MAX_AVATAR_SIZE ? t('avatarSizeError') : t('avatarTypeError')}`
        );
      }
      setUpImage(file);
    },
    [defaultErrorAlert, setUpImage]
  );

  const handleFileDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const file = event.dataTransfer.files[0];
      validateFile(file);
    },
    [validateFile]
  );

  const handleFileChoice = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        validateFile(file);
      }
    },
    [validateFile]
  );

  useEffect(() => {
    if (!imageRef.current) return;
    const cropSideLength =
      imageRef.current.clientWidth > imageRef.current.clientHeight
        ? imageRef.current.clientHeight * 0.8
        : imageRef.current.clientWidth * 0.8;
    const cropStartX = (imageRef.current.clientWidth - cropSideLength) * 0.5;
    const cropStartY = (imageRef.current.clientHeight - cropSideLength) * 0.5;
    setCrop({
      ...crop,
      width: cropSideLength,
      height: cropSideLength,
      x: cropStartX,
      y: cropStartY,
    });
  }, [crop, setCrop]);

  const handleOnDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const onLoad = useCallback(
    (img: HTMLImageElement) => {
      imageRef.current = img;
      setIsLoading(false);
    },
    [setIsLoading]
  );

  const handleSaveImage = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      try {
        if (!imageRef.current) return;
        const image = await getCroppedImg(imageRef.current, completedCrop, 'image');
        setCroppedImage(URL.createObjectURL(image));
        await setStep(3);
        await uploadVoiceAvatar(+voiceId, image);
        logEvent('Voice photo updated', {
          url: match.url,
          member_role: voice.currentVoiceMemberRole.name,
          result: 'success',
          user_id: id,
        });
        setAvatar(URL.createObjectURL(image));
        toggle();
        reset();
        successAlert(t('voiceAvatarUpdated'));
      } catch (error) {
        logEvent('Voice photo updated', {
          url: match.url,
          member_role: voice.currentVoiceMemberRole.name,
          result: 'failed',
          user_id: id,
        });
        setStep(2);
        defaultErrorAlert(`${t('saveError')}. ${t('pleaseTryAgain')}.`);
      }
    },
    [
      completedCrop,
      setCroppedImage,
      setStep,
      uploadVoiceAvatar,
      voiceId,
      logEvent,
      match.url,
      voice.currentVoiceMemberRole.name,
      id,
      setAvatar,
      toggle,
      reset,
      successAlert,
      defaultErrorAlert,
    ]
  );

  const handleSetCrop = useCallback(
    (crop: Crop) => {
      setCrop(crop);
    },

    [setCrop]
  );

  const handleCompleteCrop = useCallback(
    (crop: Crop) => {
      setCompletedCrop(crop);
    },
    [setCompletedCrop]
  );

  const handleButtonClick = useCallback(() => {
    inputRef.current && inputRef.current.click();
  }, [inputRef]);

  const animatedBlockHeight = () => {
    if (step === 1) return step1Ref.current?.offsetHeight;
    if (step === 2) return isTablet ? '100%' : step2Ref.current?.offsetHeight;
    if (step === 3) return step3Ref.current?.offsetHeight;
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleCloseModal}
      data-test-id='voice.avatar.modal'
      wrapClassName='avatar-modal-wrapper'
      className={classNames('avatar__container custom-modal__container', { fullscreen: isTablet })}
      centered
    >
      <Form
        className={`avatar__modal avatar__modal-${step} animated-avatar-modal`}
        onSubmit={handleSaveImage}
        style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        transition={{ duration: 10 }}
      >
        <AnimatePresence>
          <motion.div
            animate={{
              height: animatedBlockHeight(),
            }}
            transition={{ type: 'spring', bounce: 0.1, duration: 0.2 }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {step === 1 && (
              <div ref={step1Ref} className='avatar__step-wrapper'>
                <ModalHeader
                  toggle={handleCloseModal}
                  className='avatar__header custom-modal__header'
                  charCode={<Icon name='falTimes' data-test-id='avatar.modal.closeButton' />}
                  tag='h3'
                >
                  {t('changeVoiceAvatar')}
                </ModalHeader>
                <ModalBody className='avatar__body'>
                  <div className='avatar__upload-container' onDrop={handleFileDrop} onDragOver={handleOnDragOver}>
                    <input
                      data-test-id='voice.avatar.modal.input'
                      type='file'
                      accept='.png, .jpg, .jpeg'
                      ref={inputRef}
                      onChange={handleFileChoice}
                    />
                    <div className='avatar__upload'>
                      <Icon name='faCloudUploadAlt' />
                      <p className='tp-3'>{t('dropImage')}</p>
                    </div>
                  </div>
                  <div className='avatar__upload-button'>
                    <p className='tp-3'>{t('or')}</p>
                    <Button
                      outline
                      size='lg'
                      color='secondary'
                      onClick={handleButtonClick}
                      data-test-id='voice.avatar.uploadButton'
                    >
                      {t('uploadFromPC')}
                    </Button>
                  </div>
                </ModalBody>
              </div>
            )}
            {step === 2 && (
              <div
                ref={step2Ref}
                className='avatar__step-wrapper'
                style={{ height: isTablet ? '100%' : '', display: 'flex', flexDirection: 'column' }}
              >
                <ModalHeader
                  toggle={handleCloseModal}
                  className='avatar__header avatar__crop-header custom-modal__header'
                  charCode={<Icon name='falTimes' data-test-id='avatar.modal.closeButton' />}
                  tag='div'
                >
                  <Icon
                    name='falArrowLeft'
                    size='lg'
                    wrapperClassName='avatar__crop-header-back'
                    onClick={isTablet ? handleCloseModal : reset}
                    data-test-id='voice.avatar.backButton'
                  />
                  {isTablet ? (
                    <h4 className={isPhone ? 'st-1' : ''}>{t(isPhone ? 'cropImageShort' : 'cropImage')}</h4>
                  ) : (
                    <h3 className={isPhone ? 'st-1' : ''}>{t('cropImage')}</h3>
                  )}
                </ModalHeader>
                <ModalBody className='avatar__crop-body' data-test-id='voice.avatar.cropWrapper'>
                  <div>
                    {isLoading && (
                      <Icon
                        name='faCircleNotch'
                        className='fa-spin'
                        color='primary'
                        wrapperClassName='avatar__crop-body-loader'
                      />
                    )}
                    <ReactCrop
                      src={image}
                      onImageLoaded={onLoad}
                      crop={crop}
                      onChange={handleSetCrop}
                      onComplete={handleCompleteCrop}
                      minWidth={54}
                      keepSelection
                      data-test-id='test'
                    />
                  </div>
                </ModalBody>
                <ModalFooter className='avatar__crop-footer'>
                  <Button
                    color='primary'
                    onClick={handleSaveImage}
                    size={isTablet ? 'lg' : 'xl'}
                    data-test-id='voice.avatar.saveButton'
                  >
                    {t('save')}
                  </Button>
                </ModalFooter>
              </div>
            )}
            {step === 3 && (
              <div ref={step3Ref} className='avatar__step-wrapper'>
                <ModalBody className='avatar__crop-finishing'>
                  <div className='avatar__image-uploading' data-test-id='voice.avatar.imageLoading'>
                    <img src={croppedImage} alt='Voice avatar' />
                    <div className='avatar__border-uploading' />
                  </div>
                  <p>{`${t('savingAvatar')}...`}</p>
                </ModalBody>
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </Form>
    </Modal>
  );
}
