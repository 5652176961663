import React from 'react';
import { Icon, IconButton, ModalBody } from '@just-ai/just-ui';
import { ProgressBar } from '@just-ai/just-ui/dist/AudioPlayer/ProgressBar';
import { VolumeControls } from '@just-ai/just-ui/dist/AudioPlayer/VolumeBar';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import { withRouter, RouteComponentProps } from 'react-router';

import { PublishedVoiceView } from '../../../../api/facade/client';
import { SynthesizeTextRequest } from '../../../../api/supervisor/client';
import { t } from '../../../../localization';
import { getSecondsToMinutesAndSeconds } from '../../../../utils';
import { CustomModal } from '../CustomModal';
import SpeakerSelectModal from '../SpeakerSelectModal';
import withAlert from '../../../../components/HOC/withAlert';
import { CaptchaHOCProps } from '../../../../components/HOC/withCaptcha';
import { AppContext } from '../../../../AppContext';
import { SpeakerBlockProps, SpeakerProps } from '../../../../components/VoiceLineSynthBlock/VoiceLineBlock';
import { DefaultAlertReturnType } from '../../../../utils/useAlert';
import { SCREEN_WIDTH_TABLET } from '../../../Header/constants';
import withAmplitude, { AmplitudeHOCProps } from '../../../../components/HOC/withAmplutide';
import { SynthBuffer } from '../../../Projects/model/VoiceLines';
import { MIN_SYNTH_TEXT_LENGTH } from '../../constants';
import { ReplicaBlock } from './ReplicaBlock';

const MAX_SPEAKERS = 7;

type DialogSynthProps = {
  availableSpeakers: PublishedVoiceView[];
  captchaEnabled?: boolean;
} & DefaultAlertReturnType &
  AmplitudeHOCProps &
  RouteComponentProps &
  CaptchaHOCProps;

type DialogSynthState = {
  speakers: Array<SpeakerProps & { synthBuffer?: Omit<SynthBuffer, 'id'> }>;
  loading: boolean;
  playing: boolean;
  dialogSrc: string;
  audioDuration: number;
  isDirty: boolean;
  modalOpen: boolean;
  editedSpeaker: number | string;
  playingId: string;
  lastPlayedIndex: string;
  playerDisabled: boolean;
};

const addNewSpeakerPlaceholder = {
  voiceId: -1,
  avatar: 'placeholder',
  text: '',
  modelId: -1,
  lineId: 'placeholder',
};

class DialogSynth extends React.PureComponent<DialogSynthProps, DialogSynthState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  audioRef: React.RefObject<HTMLAudioElement>;
  audioRefSpeaker: React.RefObject<HTMLAudioElement>;
  constructor(props: DialogSynthProps) {
    super(props);
    this.state = {
      speakers: [addNewSpeakerPlaceholder],
      loading: false,
      playing: false,
      dialogSrc: '',
      audioDuration: 0,
      isDirty: true,
      modalOpen: false,
      editedSpeaker: '',
      playingId: '',
      lastPlayedIndex: '',
      playerDisabled: true,
    };
    this.audioRef = React.createRef();
    this.audioRefSpeaker = React.createRef();
  }

  async componentDidUpdate(prevProps: DialogSynthProps, prevState: DialogSynthState) {
    if (prevState.playing !== this.state.playing) {
      this.state.playing ? this.audioRef.current?.play() : this.audioRef.current?.pause();
    }
    if (!prevState.isDirty && this.state.isDirty && this.audioRef.current) {
      this.audioRef.current.src = '';
      this.setState({ playing: false, audioDuration: 0 });
    }
    if (prevState.speakers !== this.state.speakers) {
      this.state.speakers.find(speaker => Boolean(speaker.text) && speaker.voiceId)
        ? this.setState({ playerDisabled: false })
        : this.setState({ playerDisabled: true });
    }
  }

  modalToggle = () => this.setState(prevState => ({ modalOpen: !prevState.modalOpen }));

  handlePlayerClick = async () => {
    if (
      this.state.speakers.find(
        speaker => speaker.voiceId !== -1 && speaker.text && speaker.text.length < MIN_SYNTH_TEXT_LENGTH
      )
    ) {
      return this.props.defaultErrorAlert(t('textForTestLengthDialog', MIN_SYNTH_TEXT_LENGTH));
    }
    const speakersForRequest: SynthesizeTextRequest[] = this.state.speakers
      .filter(speaker => speaker.voiceId && speaker.text.length > 3)
      .map(speaker => ({
        text: speaker.text,
        modelId: speaker.modelId!,
        voiceId: speaker.voiceId!,
      }));
    if (this.state.loading || speakersForRequest.length < 1 || !this.audioRef.current) return;
    if (!this.state.isDirty) {
      return this.setState(prevState => ({ playing: !prevState.playing }));
    }
    this.audioRef.current.currentTime = 0;
    this.setState({ loading: true, audioDuration: 0 });
    try {
      const token = this.props.captchaEnabled ? await this.props.executeCaptcha() : undefined;
      const newDialogUrl = await this.context.synthesizeDialog(speakersForRequest, token);
      this.setState(prevState => ({
        dialogSrc: newDialogUrl,
        playing: !prevState.playing,
        isDirty: false,
      }));
      this.props.logEvent('synthesize', {
        result: 'success',
        page_type: this.props.match.path,
        page_url: this.props.match.url,
        type: 'dialog',
      });
    } catch (error) {
      this.props.logEvent('synthesize', {
        result: 'failed',
        page_type: this.props.match.path,
        page_url: this.props.match.url,
        type: 'dialog',
      });
      this.props.defaultErrorAlert(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSpeakerText = ({ lineId, value }) => {
    const newSpeakers = [...this.state.speakers];
    const updatedSpeaker = newSpeakers.find(speaker => speaker.lineId === lineId);
    const updatedSpeakerIndex = newSpeakers.findIndex(speaker => speaker.lineId === lineId);
    if (!updatedSpeaker || updatedSpeakerIndex < 0) return;
    newSpeakers[updatedSpeakerIndex] = { ...updatedSpeaker, text: value };
    return this.setState({ speakers: newSpeakers, isDirty: true });
  };

  handleSpeakerClick: SpeakerBlockProps['handleAvatarClick'] = lineId => {
    const updatedSpeakerIndex = this.state.speakers.findIndex(speaker => speaker.lineId === lineId);
    if (this.props.availableSpeakers.length < 1) return this.props.defaultErrorAlert('Нет доступных спикеров');
    this.setState({ modalOpen: true, editedSpeaker: updatedSpeakerIndex });
  };

  handleSpeakerSelect = (id: number) => {
    this.setState({ modalOpen: false });
    this.props.logEvent('dialog_phrase_voice_edited');
    const selectedSpeaker = this.props.availableSpeakers.find(voice => voice.id === id);
    if (!selectedSpeaker) return;
    const updatedSpeakers = [...this.state.speakers];
    updatedSpeakers[this.state.editedSpeaker] = {
      ...updatedSpeakers[this.state.editedSpeaker],
      lineId: `${selectedSpeaker.id}-${updatedSpeakers.length - 1}`,
      modelId: selectedSpeaker.modelId,
      voiceId: selectedSpeaker.id,
    };
    const isPlaceholderNeeded =
      updatedSpeakers.length < MAX_SPEAKERS && !updatedSpeakers.find(speaker => speaker.voiceId === -1);
    if (isPlaceholderNeeded) {
      this.props.logEvent('dialog_phrase_created');
      updatedSpeakers.push(addNewSpeakerPlaceholder);
    }
    return this.setState({ speakers: updatedSpeakers, isDirty: true });
  };

  handleSpeakerPlayerClick = ({ lineId, phraseUrl }: { lineId: string; phraseUrl?: string }) => {
    if (!this.audioRefSpeaker.current) return;
    if (this.audioRefSpeaker.current.src !== phraseUrl && !!phraseUrl) {
      this.audioRefSpeaker.current.src = phraseUrl;
    }
    if (this.audioRefSpeaker.current.paused) {
      this.audioRefSpeaker.current.play();
      this.setState({ playingId: lineId, lastPlayedIndex: lineId });
    } else {
      this.audioRefSpeaker.current.pause();
      this.setState({ playingId: '' });
    }
  };

  handlePlayerMeta = () => {
    if (!this.audioRef.current) return;
    this.setState({ audioDuration: Math.round(this.audioRef.current.duration) || 0 });
  };

  getUpdatedSpeakerDataById = (lineId: string) => {
    const newLines = [...this.state.speakers];
    const updatedLine = newLines.find(line => line.lineId === lineId);
    const updatedLineIndex = newLines.findIndex(line => line.lineId === lineId);
    return { newLines, updatedLine, updatedLineIndex };
  };

  setSynthBuffer = ({ lineId, payload }: { lineId: string; payload: Omit<SynthBuffer, 'id'> }) => {
    const { newLines, updatedLineIndex } = this.getUpdatedSpeakerDataById(lineId);
    if (updatedLineIndex < 0) return;
    newLines[updatedLineIndex].synthBuffer = payload;
    return this.setState({ speakers: newLines });
  };

  render() {
    const { speakers, loading, playing, dialogSrc, audioDuration } = this.state;
    return (
      <div className='dialog-synth__container'>
        <h1 className='display-2'>{t('LandingPage:Dialog:Header')}</h1>
        <div className='dialog-synth__speakers-wrapper'>
          {speakers.map((speaker, index) => (
            <React.Fragment key={`${speaker.voiceId}-${index}`}>
              <ReplicaBlock
                {...speaker}
                handleText={this.handleSpeakerText}
                handleAvatarClick={this.handleSpeakerClick}
                handlePlayerClick={this.handleSpeakerPlayerClick}
                playingId={this.state.playingId}
                executeCaptcha={this.props.executeCaptcha}
                setSynthBuffer={this.setSynthBuffer}
              />
            </React.Fragment>
          ))}
        </div>
        <div className='dialog-synth__player justui-audio-player'>
          <audio
            ref={this.audioRef}
            src={dialogSrc}
            controls={false}
            hidden
            preload='metadata'
            onLoadedMetadata={this.handlePlayerMeta}
            onEnded={() => this.setState({ playing: false })}
          />
          <audio
            ref={this.audioRefSpeaker}
            controls={false}
            preload='metadata'
            onEnded={() => this.setState({ playingId: '' })}
            hidden
          />
          <MediaQuery minWidth={900}>
            <p className='tp-3'>{t('LandingPage:Dialog:PlayerListen')}</p>
          </MediaQuery>
          <IconButton
            name={loading ? 'faCircleNotch' : playing ? 'faPause' : 'faPlay'}
            onClick={this.handlePlayerClick}
            color='primary'
            square
            size='lg'
            className={classNames('play-button', { playing, spinner: loading })}
            disabled={this.state.playerDisabled}
          />
          <ProgressBar
            audio={this.audioRef.current as HTMLAudioElement}
            progressUpdateInterval={10}
            ShowFilledProgress
          />
          <p className='tp-3 duration'>{getSecondsToMinutesAndSeconds(audioDuration)}</p>
          <MediaQuery minWidth={900}>
            <div className='volume-container'>
              <Icon name='faVolumeUp' />
              <VolumeControls alignment='horizontal' volume={1} audio={this.audioRef.current as HTMLAudioElement} />
            </div>
          </MediaQuery>
        </div>
        <MediaQuery maxWidth={SCREEN_WIDTH_TABLET}>
          {match => {
            return (
              <CustomModal
                isOpen={this.state.modalOpen}
                toggle={this.modalToggle}
                title={t('LandingPage:Dialog:SpeakerModalHeader')}
                customClassName='dialog-synth__speaker-modal'
                fullscreen={match}
              >
                <ModalBody>
                  <SpeakerSelectModal
                    speakersList={this.props.availableSpeakers}
                    handleSubmit={this.handleSpeakerSelect}
                  />
                </ModalBody>
              </CustomModal>
            );
          }}
        </MediaQuery>
      </div>
    );
  }
}

export default withAmplitude({})(withRouter(withAlert(DialogSynth)));
