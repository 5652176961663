import React, { useCallback } from 'react';
import { ModalBody } from 'reactstrap';
import CustomModal from './CustomModal';
import { t } from '../../../../localization';
import { convertNameToPlaceholder } from '../../../../utils';
import { VoiceMemberView } from '../../../../api/facade/client';

type RemoveManagerProps = {
  data: VoiceMemberView;
  handleModalAction: (value: VoiceMemberView) => Promise<void>;
  cancelButtonClick: () => void;
};

export const CustomModalRemoveManager = ({ data, cancelButtonClick, handleModalAction }: RemoveManagerProps) => {
  const { accountName } = data || {};
  const actionButtonClick = useCallback(async () => {
    try {
      await handleModalAction(data);
    } catch (error) {
      console.error('Error deleting voice members', error);
    }
  }, [handleModalAction, data]);

  if (!data) return null;
  return (
    <>
      <ModalBody className='custom-modal__body'>
        <div className='custom-modal__delete'>
          <p className='custom-modal__delete-user'>{accountName && convertNameToPlaceholder(accountName)}</p>
          <p>{t('removeManagerModal', accountName)}</p>
        </div>
      </ModalBody>
      <CustomModal.Footer
        cancelButtonTitle={t('cancel')}
        actionButtonTitle={t('exclude')}
        actionButtonClick={actionButtonClick}
        cancelButtonClick={cancelButtonClick!}
      />
    </>
  );
};
