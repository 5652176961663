import { mapKeys } from 'lodash';
import { commonLocalization } from './common.loc';

const Localize = require('localize');
require('decliner/dist/decliner.min');
const radix = 10;

const localization = new Localize({ ...commonLocalization });

localization.addTranslations = translate => {
  mapKeys(translate, key => {
    // eslint-disable-next-line no-prototype-builtins
    if (!localization.getTranslations().hasOwnProperty(key)) {
      localization.loadTranslations(translate);
    }
  });
};

localization.decliner = (arr, count, insert) => {
  return arr.decline(parseInt(count, radix), insert);
};

localization.checkExistKey = key => {
  return key !== localization.translate(key);
};

localization.throwOnMissingTranslation(false);
export default localization;

export const { translate: t } = localization;
