import { useEffect, useState, useCallback, useRef } from 'react';
import { NavLink, Switch, useRouteMatch, useParams, useHistory, Route, matchPath } from 'react-router-dom';
import classNames from 'classnames';
import { Spinner, Icon } from '@just-ai/just-ui';
import { useMediaQuery } from 'react-responsive';
import { Amplitude } from 'react-amplitude-hooks';
import { AxiosError } from 'axios';

import { Voice } from '../../model/Voice';
import { useLibraryContext } from '../../context/LibraryContext';
import { Control } from '../../components/Voice';
import { t } from '../../../../localization';
import Training from '../TrainingPage';
import { PublishedPage } from '../PublishedPage';
import { NewVersion } from '../../components/VoiceTraining';
import useAlert from '../../../../utils/useAlert';
import { VoiceAvatar } from '../../components/VoiceAvatar';
import { UsagePage } from '../UsagePage';
import { useSticky } from '../../hooks/useSticky';
import { SCREEN_WIDTH_MD } from '../../../Header/constants';

import './VoicePage.scss';

function VoicePage() {
  const { getVoice, setCurrentVoiceId, versionReleasedRecently } = useLibraryContext();
  const [voice, setVoice] = useState<Voice>();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { defaultErrorAlert } = useAlert();
  const mdScreenDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_MD}px)` });
  const observerRef = useRef<HTMLDivElement>(null);
  const isSticky = useSticky({ refElement: observerRef, treshold: 1 });

  const fetchVoice = useCallback(async () => {
    await setCurrentVoiceId(Number(id));
    try {
      if (!Number(id)) return history.push('/my');
      const voice = await getVoice(Number(id));
      setVoice(voice);
    } catch (error) {
      defaultErrorAlert(error);
      if ((error as AxiosError).response?.status === 403 || (error as AxiosError).response?.status === 404) {
        history.push('/my');
      }
    }
  }, [defaultErrorAlert, getVoice, history, id, setCurrentVoiceId]);

  useEffect(() => {
    fetchVoice();
  }, [fetchVoice, history.location]);

  const backToMyVoices = () => {
    history.push('/my');
  };

  const handleNavClick = ({ event, route }: { event; route: string }) => {
    if (!mdScreenDown || matchPath(history.location.pathname, { path: `*/${route}` })) return;
    event.target.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  };

  if (!voice) return <Spinner />;
  return (
    <div className='voice-page__wrapper'>
      <div className='voice-page__container'>
        <aside className={classNames('voice-page__menu', { sticky: isSticky })} ref={observerRef}>
          <div>
            {mdScreenDown && <Icon name='falArrowLeft' onClick={backToMyVoices} />}
            <VoiceAvatar size='small' voiceId={id} />
            <div className='menu__caption'>
              <p className='caption__title st-1'>{voice?.name}</p>
              <p className='caption__subtitle tp-3'>{t('voice')}</p>
            </div>
          </div>
          <ul className='menu__list' data-test-id='controlPage.nav'>
            <NavLink
              activeClassName='active st-1'
              to={`${url}/control`}
              className='menu__item tp-3'
              isActive={(match, { pathname }) => pathname === url || pathname === `${url}/control`}
              onClick={event => handleNavClick({ event, route: 'control' })}
              data-test-id='controlPage.navControl'
            >
              {t('control')}
            </NavLink>
            <NavLink
              activeClassName='active st-1'
              to={`${url}/training`}
              className='menu__item tp-3'
              onClick={event => handleNavClick({ event, route: 'training' })}
              data-test-id='controlPage.navTraining'
            >
              {t('trainingNoun')}
            </NavLink>
            <NavLink
              activeClassName='active st-1'
              to={`${url}/released`}
              className={classNames('menu__item tp-3', { 'shadow-pulse': versionReleasedRecently })}
              onClick={event => handleNavClick({ event, route: 'released' })}
              data-test-id='controlPage.navReleased'
            >
              <div className='dot' />
              {t('publishedVersions')}
            </NavLink>
            <NavLink
              activeClassName='active st-1'
              to={`${url}/usage`}
              className='menu__item tp-3'
              onClick={event => handleNavClick({ event, route: 'usage' })}
              data-test-id='controlPage.navUsage'
            >
              {t('using')}
            </NavLink>
          </ul>
        </aside>
        <Switch>
          <Amplitude eventProperties={{ member_role: voice.currentVoiceMemberRole.name }}>
            <Route
              exact
              path={[`${path}/control`, path]}
              render={routeProps => <Control {...routeProps} voice={voice} id={id} fetchVoice={fetchVoice} />}
            />
            <Route exact path={`${path}/training`} render={routeProps => <Training {...routeProps} voice={voice} />} />
            <Route
              path={`${path}/training/new_version`}
              render={routeProps => <NewVersion {...routeProps} voice={voice} />}
            />
            <Route
              path={`${path}/released`}
              render={routeProps => <PublishedPage {...routeProps} voiceName={voice.name} />}
            />
            <Route
              path={`${path}/usage`}
              render={routeProps => (
                <UsagePage {...routeProps} voice={voice} fetchVoice={fetchVoice} usageType='voiceUsage' />
              )}
            />
          </Amplitude>
        </Switch>
      </div>
    </div>
  );
}

export default VoicePage;
