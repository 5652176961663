import React, { useState, useCallback } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { VoiceView, CatalogView } from '../../../../../api/facade/client';
import { t } from '../../../../../localization';
import './CatalogBreadcrumbs.scss';
import { CatalogPageHeader } from '..';
import { CatalogTitleInput } from '../Inputs/CatalogTitleInput';
import { useLibraryContext } from '../../../context/LibraryContext';
import useAlert from '../../../../../utils/useAlert';

type BreadcrumbsProps = {
  voiceData: VoiceView;
  catalogVersion?: number;
  catalogData: CatalogView;
  fetchData: () => void;
  isLoading: boolean;
};

export const CatalogBreadcrumbs = ({
  voiceData,
  catalogVersion,
  catalogData,
  fetchData,
  isLoading,
}: BreadcrumbsProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { updateCatalog } = useLibraryContext();
  const { defaultErrorAlert } = useAlert();

  const linkToVoice = `/my/voice/${voiceData.id}/control`;
  const linkToVersions = `/my/voice/${voiceData.id}/training`;
  const linkToCatalogList = `/my/voice/${voiceData.id}/training/new_version`;

  const setEditing = useCallback((value: boolean) => {
    setIsEditing(value);
  }, []);

  const saveTitle = async (newTitle: string) => {
    try {
      setIsEditing(false);
      if (newTitle.length === 0) {
        return setHasError(true);
      }
      await updateCatalog(catalogData.id, { title: newTitle });
      fetchData();
    } catch (error) {
      defaultErrorAlert(error);
    }
  };

  const setError = useCallback((value: boolean) => {
    setHasError(value);
  }, []);
  return (
    <div className='catalog-page__breadcrumbs-wrapper'>
      <div className='catalog-page__breadcrumbs'>
        {isEditing ? (
          <div className='breadcrumbs-container'>
            <CatalogTitleInput
              defaultValue={catalogData.title}
              save={saveTitle}
              hasError={hasError}
              setError={setError}
              setEditing={setEditing}
            />
          </div>
        ) : (
          <Breadcrumb className='breadcrumbs-container'>
            <BreadcrumbItem>
              <Link to={linkToVoice}>
                <p>{voiceData.name}</p>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={linkToVersions} data-test-id='catalogPage.trainingLink'>
                {t('trainingNoun')}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={linkToCatalogList}>{t('versionNumber', catalogVersion)}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <CatalogPageHeader
                catalogData={catalogData}
                voiceData={voiceData}
                isLoading={isLoading}
                fetchData={fetchData}
                onClick={setEditing}
              />
            </BreadcrumbItem>
          </Breadcrumb>
        )}
      </div>
    </div>
  );
};
