import React, { useRef, useEffect, useState } from 'react';
import { useMotionValue, motion } from 'framer-motion';

const SliderWrap = ({ children, sliderRef, x, sliderConstraints, bounceStiffness, bounceDamping }) => {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <motion.div
        ref={sliderRef}
        drag='x'
        initial={{ x: 0 }}
        style={{ x, display: 'flex', gap: '1rem' }}
        dragConstraints={{
          left: -sliderConstraints,
          right: 0,
        }}
        dragTransition={{ bounceStiffness, bounceDamping }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export const DragSlider = ({ children, bounceStiffness = 50, bounceDamping = 10 }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const x = useMotionValue(0);

  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0);
  const [sliderConstraints, setSliderConstraints] = useState(0);

  useEffect(() => {
    if (!sliderRef.current) return;

    const calcSliderChildrenWidth = () => {
      if (!sliderRef.current) return;
      setSliderChildrenWidth(sliderRef.current.scrollWidth);
    };

    calcSliderChildrenWidth();

    const calcSliderWidth = () => {
      if (!sliderRef.current) return;
      setSliderWidth(sliderRef.current.clientWidth);
    };

    calcSliderWidth();
    window.addEventListener('resize', calcSliderWidth);

    const calcSliderConstraints = () => {
      setSliderConstraints(sliderChildrenWidth - sliderWidth);
    };

    calcSliderConstraints();
    window.addEventListener('resize', calcSliderConstraints);

    return () => {
      window.removeEventListener('resize', calcSliderWidth);
      window.removeEventListener('resize', calcSliderConstraints);
    };
  }, [sliderRef, sliderChildrenWidth, sliderWidth]);
  return (
    <SliderWrap
      sliderRef={sliderRef}
      x={x}
      sliderConstraints={sliderConstraints}
      bounceStiffness={bounceStiffness}
      bounceDamping={bounceDamping}
    >
      {children}
    </SliderWrap>
  );
};
