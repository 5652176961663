import React from 'react';
import { Helmet } from 'react-helmet';

export const DynamicTitle = ({ title }: { title: string }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
