import React from 'react';
import { motion, Variants } from 'framer-motion';
import { Button } from '@just-ai/just-ui';
import { t } from '../../../../localization';
import { useAppContext } from '../../../../AppContext';

type SliderLastItemProps = {
  variants: Variants;
  direction: number;
  handleAllVoicesClick: () => void;
  motionActive: boolean;
};

export const SliderLastItem = ({ variants, direction, handleAllVoicesClick, motionActive }: SliderLastItemProps) => {
  const { productName } = useAppContext();
  return motionActive ? (
    <motion.div
      variants={variants}
      initial='enter'
      animate='center'
      exit='exit'
      custom={direction}
      transition={{
        x: { type: 'spring', stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
      className='landing-page__slider-last'
    >
      <h2>{t('LandingPage:SliderLastCard:Title')}</h2>
      <p>{t('LandingPage:SliderLastCard:SubTitle')}</p>
      <Button color='primary' onClick={handleAllVoicesClick}>
        {t('allVoices', productName)}
      </Button>
    </motion.div>
  ) : (
    <div className='landing-page__slider-last'>
      <h2>{t('LandingPage:SliderLastCard:Title')}</h2>
      <p>{t('LandingPage:SliderLastCard:SubTitle')}</p>
      <Button color='primary' onClick={handleAllVoicesClick}>
        {t('allVoices', productName)}
      </Button>
    </div>
  );
};
