import React from 'react';
import { useHistory } from 'react-router';
import { useAmplitude } from 'react-amplitude-hooks';

import { CustomModalDeleteProject, CustomModal } from '../../../Library/components/CustomModal';
import useDefaultAlert from '../../../../utils/useAlert';
import { useProjectContext } from '../../../../modules/Projects/context/ProjectsContext';
import { ProjectView } from '../../../../api/dubber/client';
import { t } from '../../../../localization';

type ProjectDeleteTypes = {
  isModalOpen: boolean;
  project: ProjectView;
  card?: boolean;
  toggleModal: () => void;
  getData?: () => void;
};

export const ProjectDelete = ({ project, isModalOpen, toggleModal, getData, card }: ProjectDeleteTypes) => {
  const { deleteProject } = useProjectContext();
  const { defaultErrorAlert, successAlert } = useDefaultAlert();
  const history = useHistory();
  const { logEvent } = useAmplitude();

  const handleDeleteProject = async () => {
    try {
      await deleteProject(project.id);
      logEvent('project deleted', {
        project_id: project.id,
        result: 'success',
      });
      getData ? getData() : history.push('/my');
      successAlert(`${t('projectDeleted')}`);
    } catch (error) {
      defaultErrorAlert(error);
      logEvent('project deleted', {
        project_id: project.id,
        result: 'failed',
      });
      history.push('/my');
    } finally {
      toggleModal();
    }
  };
  return (
    <CustomModal isOpen={isModalOpen} toggle={toggleModal} title={`${t('deleteProject')}?`} position='center' modalCard>
      <CustomModalDeleteProject
        cancelButtonClick={toggleModal}
        handleModalAction={handleDeleteProject}
        projectName={project?.name}
      />
    </CustomModal>
  );
};
