import React, { useState, useCallback, useRef, useLayoutEffect, useEffect } from 'react';
import { TextareaAutosize, InputText, InputGroup } from '@just-ai/just-ui';
import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { useAmplitude } from 'react-amplitude-hooks';

import { useProjectContext } from '../../../../modules/Projects/context/ProjectsContext';
import { ProjectView } from '../../../../api/dubber/client';
import useDefaultAlert from '../../../../utils/useAlert';
import { t } from '../../../../localization';
import { SCREEN_WIDTH_MD } from '../../../Header/constants';
import axios from 'axios';
type ProjectInputsProps = {
  project: ProjectView;
  type: 'card' | 'header';
  toggleEditing: () => void;
  getData: () => void;
};
const maxTitleLength = 60;

export const ProjectInputs = ({ project, type, toggleEditing, getData }: ProjectInputsProps) => {
  const [projectTitle, setProjectTitle] = useState('');
  const { updateProject } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();
  const history = useHistory();
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const mdScreenDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_MD}px)` });
  const { logEvent } = useAmplitude();

  const handleBlur = useCallback(
    async (event?: React.FormEvent) => {
      event?.preventDefault();
      if (projectTitle.length > maxTitleLength) return;
      if (projectTitle.length === 0) return toggleEditing();
      try {
        await updateProject(project.id, projectTitle);
        toggleEditing();
        logEvent('project rename', {
          project_id: project.id,
          result: 'success',
        });
        getData();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          defaultErrorAlert(error);
          logEvent('project rename', {
            project_id: project.id,
            result: 'failed',
          });
          if (error.response?.status === 404) history.push('/my');
          type === 'card' && getData();
        }
      }
    },
    [defaultErrorAlert, getData, history, logEvent, project.id, projectTitle, toggleEditing, type, updateProject]
  );

  const handleChange = useCallback(
    (value: string) => {
      if (mdScreenDown && value.length >= maxTitleLength && type === 'header') return;
      setProjectTitle(value);
    },
    [mdScreenDown, type]
  );

  const getInputWidth = useCallback(() => {
    if (!divRef.current || !inputRef.current || !spanRef.current || !projectTitle) return '';

    const maxWidth = divRef.current.clientWidth - 20;
    const borderPaddingSumWidth = 42;

    if (inputRef.current.scrollWidth > maxWidth) return (inputRef.current.style.width = `${maxWidth}px`);

    return (inputRef.current.style.width = spanRef.current?.offsetWidth + borderPaddingSumWidth + 'px');
  }, [projectTitle]);

  const handleFocus = useCallback(event => {
    setTimeout(() => {
      event.target.select();
    }, 0);
  }, []);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.code === 'Enter') {
        event.preventDefault();
        handleBlur();
      }
    },
    [handleBlur]
  );

  const onMouseDown = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      toggleEditing();
    },
    [toggleEditing]
  );

  useLayoutEffect(() => {
    setProjectTitle(project.name);
  }, [project.name]);

  useEffect(() => {
    getInputWidth();
  }, [getInputWidth, projectTitle]);

  if (type === 'card') {
    return (
      <>
        <TextareaAutosize
          bsSize='lg'
          value={projectTitle}
          onChange={handleChange}
          autoFocus
          onFocus={handleFocus}
          onBlur={handleBlur}
          rowsMax={mdScreenDown ? 2 : 3}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
          className={`${projectTitle.length > maxTitleLength ? 'error' : ''}`}
          data-test-id='project.titleInput'
          onKeyPress={handleKeyPress}
        />
        {projectTitle.length > maxTitleLength && <ErrorString projectTitle={projectTitle} />}
      </>
    );
  }

  return mdScreenDown ? (
    <motion.div
      initial={{ backgroundColor: 'rgba(2, 23, 48, 0)' }}
      animate={{ backgroundColor: 'rgba(2, 23, 48, 0.28)' }}
      exit={{ backgroundColor: 'rgba(2, 23, 48, 0)' }}
      transition={{ duration: '.2' }}
      className={`project__header-mobile ${projectTitle.length > maxTitleLength ? 'error' : ''}`}
    >
      <form onSubmit={handleBlur}>
        <InputGroup PrependInner={[{ color: 'primary', name: 'falTimes', onMouseDown: onMouseDown }]}>
          <InputText
            value={projectTitle}
            autoFocus
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`${projectTitle.length > maxTitleLength && 'error'}`}
            data-test-id='project.titleInput'
          />
        </InputGroup>
      </form>
      {projectTitle.length > maxTitleLength && <ErrorString projectTitle={projectTitle} />}
    </motion.div>
  ) : (
    <div className={`project__header-input ${projectTitle.length > maxTitleLength && 'error'}`} ref={divRef}>
      <div style={{ maxWidth: `${divRef.current?.clientWidth}`, height: '56px' }}>
        <span ref={spanRef} className='project__header-support'>
          {projectTitle}
        </span>
        <InputText
          value={projectTitle}
          onBlur={handleBlur}
          autoFocus
          onFocus={handleFocus}
          onChange={handleChange}
          className={`${projectTitle.length > maxTitleLength ? 'error' : ''}`}
          innerRef={inputRef}
          data-test-id='project.titleInput'
          onKeyPress={handleKeyPress}
        />

        {projectTitle.length > maxTitleLength && <ErrorString projectTitle={projectTitle} />}
      </div>
    </div>
  );
};

const ErrorString = ({ projectTitle }: { projectTitle: string }) => {
  return (
    <p className='tp-5' data-test-id='project.inputError'>
      {t('decreaseProjectName', projectTitle.length - maxTitleLength)}
    </p>
  );
};
