import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UploadDatasetModal from '../../components/AddLearningDataModal/UploadDatasetModal';
import { withRouter, RouteComponentProps, matchPath } from 'react-router';
import { VoiceView, NewVersionView, CatalogView, SampleView, SampleStateEnum } from '../../../../api/facade/client';
import { AppContextType, withAppContext } from '../../../../AppContext';
import { LibraryContext } from '../../context/LibraryContext';
import {
  CatalogBreadcrumbs,
  Catalog,
  CreateSample,
  Placeholder,
  Sample,
  CatalogHeader,
} from '../../components/CatalogPage';
import withAmplitude, { AmplitudeHOCProps } from '../../../../components/HOC/withAmplutide';
import { MAX_SAMPLES_PER_PAGE } from '../../constants';
import { generateSampleRoute } from '../../../../utils';
import './CatalogPage.scss';
import { AxiosError } from 'axios';
import { DynamicTitle } from '../../components/DynamicTitle/DynamicTitle';
import { t } from '../../../../localization';

type CatalogPageProps = RouteComponentProps<{ voiceId: string; catalogId: string; sampleId: string }> &
  AppContextType &
  AmplitudeHOCProps;

class State {
  isLoading: boolean = false;
  voice?: VoiceView;
  catalogVersion?: NewVersionView;
  catalogData?: CatalogView;
  currentTab: string = 'notVoiced';
  uploadModal: boolean = false;
  samples: SampleView[] = [];
  selectedSampleIndex: number = 0;
  autoScrollIndex: number = 0;
  rowsCount: number = 0;
  showErrors: boolean = false;
  pageBlocked: boolean = false;
}
class CatalogPage extends React.Component<CatalogPageProps, State> {
  static contextType = LibraryContext;
  context!: React.ContextType<typeof LibraryContext>;

  state = new State();

  async componentDidMount() {
    await this.fetchInitialData();
    this.props.logEvent('Page opened', {
      page_url: this.props.match.url,
      page_type: this.props.match.path,
      user_id: this.props.id,
    });
  }

  async componentDidUpdate(prevProps, prevState: State) {
    const { voiceId, catalogId } = this.props.match.params;
    if (prevState.currentTab !== this.state.currentTab) {
      if (
        matchPath(this.props.location.pathname, { path: '*/sample/create' }) &&
        this.state.catalogData?.samples === 0
      ) {
        return;
      }
      await Promise.all([this.fetchCatalogData(), this.fetchSamples()]);
      this.setState({ selectedSampleIndex: 0 });
      if (this.state.samples.length > 0) {
        this.props.history.push(generateSampleRoute(+voiceId, +catalogId, this.state.samples[0]?.id));
      } else {
        this.props.history.push(`/my/voice/${voiceId}/training/new_version/${catalogId}`);
      }
    }
  }

  fetchInitialData = async () => {
    const { setCurrentVoiceId, getVoice, getActualVersionData, getCatalogData } = this.context;
    try {
      await setCurrentVoiceId(Number(this.props.match.params.voiceId));
      const response = await Promise.all([
        getVoice(Number(this.props.match.params.voiceId)),
        getActualVersionData(),
        getCatalogData(Number(this.props.match.params.catalogId)),
      ]);
      this.setState({ voice: response[0], catalogVersion: response[1], catalogData: response[2] });
    } catch (error) {
      this.props.defaultAlert(error);

      if ((error as AxiosError).response?.status === 403 || (error as AxiosError).response?.status === 404) {
        this.props.history.push(`/my/voice/${this.props.match.params.voiceId}/training/new_version`);
      }
    }
  };

  fetchCatalogData = async () => {
    const { getCatalogData } = this.context;
    try {
      const catalogData = await getCatalogData(Number(this.props.match.params.catalogId));
      this.setState({ catalogData });
    } catch (error) {
      this.props.defaultAlert(error);
    }
  };

  fetchSamples = async (samples?: number) => {
    try {
      const { samplesWithStatuses, count } = await this.context.getSamples(
        Number(this.props.match.params.voiceId),
        0,
        samples || MAX_SAMPLES_PER_PAGE,
        Number(this.props.match.params.catalogId),
        this.state.currentTab === 'voiced' ? true : false,
        this.state.showErrors
      );
      this.setState({
        samples: samplesWithStatuses,
        rowsCount: count,
      });
    } catch (error) {
      this.props.defaultAlert(error);
    }
  };

  changeTab = (value: string) => {
    this.setState({ currentTab: this.state.pageBlocked ? 'blocked' + value : value });
  };

  handleFileUpload = async (file: File) => {
    try {
      if (this.state.catalogData) {
        await this.context.uploadFileToCatalog(Number(this.state.catalogData?.id), file);
        this.props.successAlert(`Обучающий материал "${file.name} добавлен"`);
      }
    } catch (error) {
      if (error instanceof Error) {
        this.props.defaultAlert(error);
      }
    } finally {
      await Promise.all([this.fetchSamples(), this.fetchCatalogData()]);
    }
  };

  toggleUploadModal = () => {
    this.setState((prevState: State) => ({ uploadModal: !prevState.uploadModal }));
  };

  setSampleIndex = (sample: SampleView) => {
    const arrIds = this.state.samples.map(sample => sample.id);
    const index = arrIds.indexOf(sample.id);
    if (this.state.autoScrollIndex !== 0) {
      this.setState({ selectedSampleIndex: index, autoScrollIndex: 0 });
    } else {
      this.setState({ selectedSampleIndex: index });
    }

    this.props.logEvent('Sample visited', {
      userId: this.props.id,
      catalogId: sample.catalogIds,
      sampleId: sample.id,
      url: this.props.match.url,
    });
  };

  goToSample = (direction: 'prev' | 'same' | 'next') => {
    const sampleDirection = {
      prev: -1,
      same: 0,
      next: 1,
    };
    const { voiceId, catalogId } = this.props.match.params;
    if (this.state.samples[this.state.selectedSampleIndex + sampleDirection[direction]]) {
      if (direction === 'next') {
        this.setState({ autoScrollIndex: this.state.selectedSampleIndex + sampleDirection[direction] });
      }
      return this.props.history.push(
        generateSampleRoute(
          voiceId,
          catalogId,
          this.state.samples[this.state.selectedSampleIndex + sampleDirection[direction]].id
        )
      );
    }
    if (this.state.samples.length === 0) {
      this.changeTab(this.state.currentTab === 'voiced' ? 'notVoiced' : 'voiced');
    }
  };

  setLoading = (value: boolean) => {
    this.setState({ isLoading: value });
  };

  setProcessingStatus = (sampleId: number) => {
    const arrIds = this.state.samples.map(sample => sample.id);
    const index = arrIds.indexOf(sampleId);
    const newArr = { ...this.state, samples: [...this.state.samples] };
    newArr.samples[index].sampleState = SampleStateEnum.PROCESSING;
    this.setState(newArr);
  };

  setShowError = (value: boolean) => {
    this.setState({ showErrors: value });
  };
  setSamples = (value: SampleView[]) => {
    this.setState({ samples: value });
  };

  setPageBlocked = (value: boolean) => {
    this.setState({ pageBlocked: value });
  };

  render() {
    const {
      voice,
      catalogVersion,
      catalogData,
      isLoading,
      samples,
      currentTab,
      uploadModal,
      selectedSampleIndex,
      rowsCount,
    } = this.state;

    if (!catalogData || !catalogVersion || !voice) return null;
    return (
      <main className='catalog-page'>
        <DynamicTitle
          title={t(
            'documentTitle.speakerPlace',
            catalogData.title,
            voice.name,
            catalogVersion.version,
            this.props.productName
          )}
        />
        <div className='catalog-page__container'>
          <CatalogBreadcrumbs
            voiceData={voice}
            catalogVersion={catalogVersion.version}
            catalogData={catalogData}
            fetchData={this.fetchCatalogData}
            isLoading={isLoading}
          />

          {catalogData.samples === 0 && !matchPath(this.props.location.pathname, { path: '*/create' }) ? (
            <Placeholder toggleUploadModal={this.toggleUploadModal} />
          ) : (
            <div className='catalog-page__catalog-container'>
              <div className='catalog-page__catalog-column'>
                <CatalogHeader
                  isLoading={isLoading}
                  currentTab={currentTab}
                  catalogData={catalogData}
                  toggleUploadModal={this.toggleUploadModal}
                  changeTab={this.changeTab}
                  isEmptyCatalog={!!this.state.catalogData?.samples}
                />
                <Catalog
                  isLoading={isLoading}
                  fetchSamples={this.fetchSamples}
                  errorsCount={catalogData.errorCount}
                  samples={samples}
                  setSamples={this.setSamples}
                  audio={currentTab === 'voiced' ? true : false}
                  currentTab={currentTab}
                  setSampleIndex={this.setSampleIndex}
                  rowsCount={rowsCount}
                  selectedSampleIndex={selectedSampleIndex}
                  showErrors={this.state.showErrors}
                  setShowError={this.setShowError}
                />
              </div>
              <div className='catalog-page__catalog-column'>
                <Switch>
                  <Route path={`/my/voice/${voice.id}/training/new_version/${catalogData.id}/sample/create`}>
                    <CreateSample
                      fetchCatalogData={this.fetchCatalogData}
                      catalogId={Number(catalogData.id)}
                      voiceId={Number(voice.id)}
                      fetchSamples={this.fetchSamples}
                      setPageBlocked={this.setPageBlocked}
                      changeTab={this.changeTab}
                      currentTab={this.state.currentTab}
                      pageBlocked={this.state.pageBlocked}
                    />
                  </Route>
                  <Route exact path={`/my/voice/${voice.id}/training/new_version/${catalogData.id}/sample/:sampleId`}>
                    <Sample
                      voiceId={voice.id}
                      catalogId={catalogData.id}
                      fetchData={this.fetchCatalogData}
                      fetchSamples={this.fetchSamples}
                      goToSample={this.goToSample}
                      samples={samples}
                      showErrors={this.state.showErrors}
                      selectedSampleIndex={selectedSampleIndex}
                      setProcessingStatus={this.setProcessingStatus}
                      setSampleIndex={this.setSampleIndex}
                      autoScrollIndex={this.state.autoScrollIndex}
                      setPageBlocked={this.setPageBlocked}
                      changeTab={this.changeTab}
                      currentTab={this.state.currentTab}
                      pageBlocked={this.state.pageBlocked}
                    />
                  </Route>
                </Switch>
              </div>
            </div>
          )}
        </div>
        <UploadDatasetModal
          processUploadedFile={async file => this.handleFileUpload(file)}
          isOpen={uploadModal}
          toggleOpen={this.toggleUploadModal}
          catalogId={this.state.catalogData?.id}
        />
      </main>
    );
  }
}

export default withAmplitude({})(withRouter(withAppContext(CatalogPage)));
