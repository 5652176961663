import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from '@just-ai/just-ui';
import { useMediaQuery } from 'react-responsive';
import { PublishedVoiceView } from '../../../../api/facade/client';
import { useAmplitude } from 'react-amplitude-hooks';
import { useRouteMatch } from 'react-router';
import { useAppContext } from '../../../../AppContext';

type SliderControlsTypes = {
  voice: PublishedVoiceView;
  page: number;
  playingId: number | null;
  setPlayingId: (value: number | null) => void;
  paginate: (value: number) => void;
};

export const SliderControls = ({ voice, page, paginate, playingId, setPlayingId }: SliderControlsTypes) => {
  const landingBreakpoint940 = useMediaQuery({ query: `(max-width: 940px)` });
  const { configData } = useAppContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();

  const handlePlayer = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (e.type !== 'ended') {
      logEvent('voice_sample_audio', {
        page_type: match.path,
        page_url: match.url,
        action: isPlaying ? 'pause' : 'play',
        type: 'landing voice',
        voice_id: playingId,
      });
    }
    if (isPlaying) {
      setIsPlaying(false);
      setPlayingId(null);
      return;
    }
    setIsPlaying(true);
    setPlayingId(voice.id);
  };

  useEffect(() => {
    if (isPlaying && audioRef.current?.paused) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (playingId && playingId !== voice.id) {
      setIsPlaying(false);
    }
  }, [voice.id, playingId]);
  return (
    <div className={classNames('landing-page__slider-controls-wrapper', { last: page === 3 })}>
      {page < 3 && (
        <div className='landing-page__slider-play'>
          <audio
            ref={audioRef}
            src={`${configData.basePublicVoicePath}/${voice.id}/sample/audio.wav`}
            onEnded={handlePlayer}
          />
          <div onClick={handlePlayer}>
            <Icon name={isPlaying ? 'faPause' : 'faPlay'} />
          </div>
          <h4>{voice.name}</h4>
        </div>
      )}
      {!landingBreakpoint940 && (
        <div className='landing-page__slider-controls'>
          <Icon className={page === 0 ? 'disabled' : ''} name='falArrowLeft' size='lg' onClick={() => paginate(-1)} />
          <Icon className={page === 3 ? 'disabled' : ''} name='falArrowRight' size='lg' onClick={() => paginate(1)} />
        </div>
      )}
    </div>
  );
};
