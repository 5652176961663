import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@just-ai/just-ui';
import { t } from '../../../../localization';

export default function Placeholder() {
  const { id: voiceId } = useParams<{ id: string }>();
  return (
    <div className='published__empty' data-test-id='pagePlaceholder'>
      <h3>{t('publishedEmptyTitle')}</h3>
      <p className='tp-3'>{t('publishedEmptySubTitle')}</p>
      <Link to={`/my/voice/${voiceId}/training`} data-test-id='pagePlaceholder.link'>
        <Button outline color='secondary' size='xl'>
          {t('backToTrainPage')}
        </Button>
      </Link>
    </div>
  );
}
