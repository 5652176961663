import classNames from 'classnames';
import { isEqual } from 'lodash';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import MediaQuery from 'react-responsive';

import { GenderEnum, PublishedVoiceView } from '../../../../api/facade/client';
import withAmplitude, { AmplitudeHOCProps } from '../../../../components/HOC/withAmplutide';
import { t } from '../../../../localization';
import PublicVoiceCard from '../../components/VoiceCard';
import { LibraryContext } from '../../context/LibraryContext';
import { VoicesFilter, Placeholder } from '../../components/AllVoicesPage';
import { ALLOWED_VOICES_PER_PUBLIC_PAGE } from '../../constants';
import Pagination from '../../../../components/Pagination';
import { SCREEN_WIDTH_MD } from '../../../Header/constants';
import { getActiveFilters } from '../../../../utils/index';
import withDocumentData from '../../../../utils/withDocumentData';
import { AppContextType, withAppContext } from '../../../../AppContext';
import { CustomModal } from '../../components/CustomModal';
import './style.scss';

type AllVoicesPageProps = {} & RouteComponentProps & AmplitudeHOCProps & AppContextType;

export type AllVoicesPageFilters = {
  purpose: 'all' | 'games' | 'sales' | 'calls';
  super?: boolean;
  gender: Array<GenderEnum | 'OTHER'>;
  cost: { min: number; max: number };
  languages: string[];
};
type VoiceViewWithPrice = PublishedVoiceView & { price: string };

const initialFilters: AllVoicesPageFilters = {
  purpose: 'all',
  cost: {
    min: 0,
    max: 2568,
  },
  gender: [],
  super: false,
  languages: [],
};

type AllVoicesPageState = {
  voices: VoiceViewWithPrice[];
  sortBy: 'popular' | 'cost' | 'time';
  filter: AllVoicesPageFilters;
  playingId: number | null;
  maxPage: number;
  currentPage: number;
  voicesCount: number;
  showLicenseModal: boolean;
};

class AllVoicesPage extends React.PureComponent<AllVoicesPageProps, AllVoicesPageState> {
  static contextType = LibraryContext;

  constructor(props) {
    super(props);
    this.state = {
      voices: [],
      playingId: null,
      sortBy: 'time',
      filter: initialFilters,
      maxPage: 0,
      currentPage: 0,
      voicesCount: 0,
      showLicenseModal: false,
    };
  }

  componentDidMount() {
    this.props.logEvent('Page opened', {
      page_url: this.props.match.url,
      page_type: this.props.match.path,
    });
    this.getData();
  }

  async componentDidUpdate(prevprops, prevState) {
    if (this.context.allVoicesList && this.context.allVoicesList.length > 0) {
      const voices = this.context.allVoicesList;

      if (!isEqual(voices, this.state.voices)) {
        this.setState({ voices });
      }
    }
    if (this.state.currentPage !== prevState.currentPage) {
      this.getData();
    }
  }

  setData = (voices: VoiceViewWithPrice[], count: number) => {
    this.setState({
      voices: voices,
      maxPage: Math.ceil(count / ALLOWED_VOICES_PER_PUBLIC_PAGE),
      voicesCount: count,
    });
  };

  getData = async () => {
    const { publishedVoices, count } = await this.context.getPublishedVoices(
      this.state.currentPage,
      ALLOWED_VOICES_PER_PUBLIC_PAGE,
      this.state.filter.gender.length === 1 ? this.state.filter.gender[0] : [],
      'NEWEST',
      this.state.filter.super && ['supervoice'],
      this.state.filter.languages
    );
    const voicesWithPrice = await Promise.all<VoiceViewWithPrice>(
      publishedVoices.map(async (voice: PublishedVoiceView) => {
        const price = await this.context.getVoicePrice(voice.id);
        return { ...voice, price };
      })
    );
    this.setData(voicesWithPrice, count);
  };

  handleFilter = (field: keyof AllVoicesPageFilters, value?: any) => {
    if (field === 'cost') {
      if (+value.min > +value.max) return;
    }
    if (field === 'super') {
      return this.setState(
        prevState => ({ filter: { ...prevState.filter, super: !prevState.filter.super }, currentPage: 0 }),
        () => this.getData()
      );
    }
    if (field === 'gender') {
      return this.setState(
        prevState => ({
          filter: {
            ...prevState.filter,
            gender: prevState.filter.gender?.includes(value)
              ? [...prevState.filter.gender].filter(item => item !== value)
              : [...prevState.filter.gender, value],
          },
          currentPage: 0,
        }),
        () => this.getData()
      );
    }
    if (field === 'languages') {
      return this.setState(
        prevState => ({
          filter: {
            ...prevState.filter,
            languages: prevState.filter.languages.includes(value)
              ? [...prevState.filter.languages].filter(language => language !== value)
              : [...prevState.filter.languages, value],
          },
          currentPage: 0,
        }),
        () => this.getData()
      );
    }
  };

  handleSlider = (values: readonly number[]) => {
    this.setState({ filter: { ...this.state.filter, cost: { min: values[0], max: values[1] } } });
  };

  setFilter = (filter: AllVoicesPageFilters) => {
    this.setState(
      prevState => ({ ...prevState, filter, currentPage: 0 }),
      () => this.getData()
    );
  };

  resetFilter = () => {
    this.setFilter(initialFilters);
  };

  toggleLicenseModal = (event?: React.SyntheticEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.setState(prevState => ({ ...prevState, showLicenseModal: !prevState.showLicenseModal }));
  };

  // handleVoiceLike = (id: number) => {
  //   if (this.context.allVoicesList.find(voice => voice.id === id)?.favorite) {
  //     this.context.unlikeVoice(id);
  //     return;
  //   }
  //   this.context.likeVoice(id);
  // };

  render() {
    return (
      <div className='voices-catalog__container'>
        <div className='page-width-container'>
          <aside className='voices-catalog__side-filters'>
            <MediaQuery maxWidth={SCREEN_WIDTH_MD}>
              {match =>
                !match && (
                  <VoicesFilter
                    filter={this.state.filter}
                    voicesAmount={this.state.voicesCount}
                    handleFilter={this.handleFilter}
                    handleSlider={this.handleSlider}
                    initialFilters={initialFilters}
                  />
                )
              }
            </MediaQuery>
          </aside>
          <main className='voices-catalog__voices-list'>
            <MediaQuery maxWidth={SCREEN_WIDTH_MD}>
              <VoicesFilter
                filter={this.state.filter}
                voicesAmount={this.state.voicesCount}
                handleFilter={this.handleFilter}
                handleSlider={this.handleSlider}
                setFilter={this.setFilter}
                resetFilter={this.resetFilter}
                initialFilters={initialFilters}
              />
            </MediaQuery>
            <MediaQuery maxWidth={SCREEN_WIDTH_MD}>
              {match =>
                !match && (
                  <div className='voices-catalog__top-sorters'>
                    <span className='text-muted'>{t('sort')}:</span>
                    {/* <span
                      className={classNames({ selected: this.state.sortBy === 'popular' })}
                      onClick={() => this.setState({ sortBy: 'popular' })}
                    >
                      {t('sort:Popularity')}
                    </span>
                    <span
                      className={classNames({ selected: this.state.sortBy === 'cost' })}
                      onClick={() => this.setState({ sortBy: 'cost' })}
                    >
                      {t('sort:Cost')}
                    </span> */}
                    <span
                      className={classNames({ selected: this.state.sortBy === 'time' })}
                      onClick={() => this.setState({ sortBy: 'time' })}
                    >
                      {t('sort:Time')}
                    </span>
                  </div>
                )
              }
            </MediaQuery>
            <div className='voices-catalog__voices-block'>
              {Number(this.state.voicesCount) === 0 &&
                Boolean(getActiveFilters({ initialObject: initialFilters, currentObject: this.state.filter })) && (
                  <Placeholder resetFilter={this.resetFilter} />
                )}
              {this.state.voices.map(voiceItem => (
                <PublicVoiceCard
                  {...voiceItem}
                  key={voiceItem.id}
                  setPlayingId={id => this.setState({ playingId: id })}
                  playingId={this.state.playingId}
                  audioSampleUrl={`${this.props.configData.basePublicVoicePath}/${voiceItem.id}/sample/audio.wav`}
                  openPhoneModal={this.toggleLicenseModal}
                />
              ))}
            </div>
            {this.state.voices.length > 0 && this.state.maxPage > 1 && (
              <Pagination
                maxPage={this.state.maxPage}
                currentPage={this.state.currentPage + 1}
                onPageChange={currentPage => {
                  this.setState({ currentPage: currentPage - 1 });
                }}
              />
            )}
          </main>
        </div>
        <CustomModal
          customClassName='license-voice-modal'
          isOpen={this.state.showLicenseModal}
          toggle={this.toggleLicenseModal}
          fullscreen
          title={
            <div className='license-voice-modal__header'>
              <p className='st-1'>
                <Icon name='farInfoCircle' color='secondary' />
                {t('licensed')} {t('voice').toLowerCase()}
              </p>
            </div>
          }
        >
          <p className='tp-4 pb-5'>{t('licensedInfoModal')}</p>
        </CustomModal>
      </div>
    );
  }
}

export default withAmplitude({})(withAppContext(withDocumentData(withRouter(AllVoicesPage), 'catalog')));
