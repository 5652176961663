import React, { createRef } from 'react';
import { IconButton } from '@just-ai/just-ui';
import InputWrapper from './InputWrapper';
import { t } from '../../../../../localization';
import classNames from 'classnames';

export class CatalogTitleInput extends InputWrapper {
  titleRef = createRef<HTMLInputElement>();

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value.length > 0 && this.props.setError) {
      this.props.setError(false);
    }
    this.setState({ value: event.target.value });
  };

  componentDidMount() {
    this.setState({ value: this.props.defaultValue });
    this.titleRef.current?.focus();
  }
  render() {
    return (
      <div
        style={{ width: `calc(${this.state.value.length}ch + 85px)` }}
        className={classNames('catalog-page__header-input', { error: this.props.hasError })}
      >
        <input
          id='catalogHeaderTooltip'
          ref={this.titleRef}
          style={{ maxWidth: `calc(${this.state.value && this.state.value.length}ch + 75px)` }}
          maxLength={50}
          name='catalogTitle'
          onFocus={this.selectText}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={() =>
            this.state.value.trim().length > 0 ? this.throttledAction('save') : this.throttledAction('reset')
          }
        />
        {this.props.hasError && <small>{t('enterTitle')}</small>}
        <div className='catalog-page__header-buttons'>
          <IconButton flat name='falCheck' size='sm' color='primary' onMouseDown={() => this.throttledAction('save')} />
          <IconButton
            flat
            name='falTimes'
            size='sm'
            color='primary'
            onMouseDown={() => this.throttledAction('reset')}
          />
        </div>
      </div>
    );
  }
}
