import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Icon, Button } from '@just-ai/just-ui';

import { LibraryContext } from '../../context/LibraryContext';
import { withAppContext, AppContextType } from '../../../../AppContext';
import { CatalogView, NewVersionView, VoiceView } from '../../../../api/facade/client';
import { t } from '../../../../localization';
import { getErrorMessageFromReason } from '../../../../utils/error';
import withAmplitude, { AmplitudeHOCProps } from '../../../../components/HOC/withAmplutide';
import NotUsedTable from './NotUsedTable';
import TableRow from './TableRow';
import { AccordeonSkeleton } from './Skeleton';
import { CustomModal, CustomModalStartTraining } from '../CustomModal';
import { humanizeDuration } from '../../../../utils';
import { DynamicTitle } from '../../components/DynamicTitle/DynamicTitle';
import { StepsForCreating } from './StepsForCreating/StepsForCreating';

type NewVersionProps = RouteComponentProps<{
  id: string;
}> &
  AmplitudeHOCProps &
  AppContextType & {
    voice: VoiceView;
  };
class State {
  isLoading: boolean = true;
  usedCatalogsLoading: boolean = false;
  accordeonOpen: boolean = false;
  usedCatalogs: CatalogView[] = [];
  notUsedCatalogs: CatalogView[] = [];
  allCatalogsCount: number = 0;
  addModal: boolean = false;
  trainModal: boolean = false;
  newVersionData?: NewVersionView;
  deletingIds: number[] = [];
}
class NewVersion extends React.Component<NewVersionProps, State> {
  static contextType = LibraryContext;
  context!: React.ContextType<typeof LibraryContext>;

  state = new State();

  async componentDidMount() {
    await this.fetchData();

    this.props.logEvent(`Page opened`, {
      page_url: this.props.match.url,
      page_type: this.props.match.path,
      user_id: this.props.id,
    });
  }

  fetchData = async () => {
    const { match } = this.props;
    this.setState({ isLoading: true });
    try {
      await this.context.setCurrentVoiceId(+match.params.id);
      const [notUsedCatalogs, newVersionData] = await Promise.all([
        this.context.getAllCatalogs(false),
        this.context.getActualVersionData(),
      ]);
      if (!notUsedCatalogs) return;
      if (this.state.deletingIds.length > 0) {
        const notUsedCatalogsIds = notUsedCatalogs.catalogsList.map(catalog => catalog.id);
        const notDeletedYet = this.state.deletingIds.filter(id => notUsedCatalogsIds.includes(id));
        return this.setState({
          notUsedCatalogs: notUsedCatalogs?.catalogsList || [],
          allCatalogsCount: notUsedCatalogs?.count || 0,
          isLoading: false,
          newVersionData,
          deletingIds: notDeletedYet,
        });
      }

      this.setState({
        notUsedCatalogs: notUsedCatalogs?.catalogsList || [],
        allCatalogsCount: notUsedCatalogs?.count || 0,
        isLoading: false,
        newVersionData,
      });
    } catch (error) {
      if (error instanceof Error) {
        this.props.addAlert({
          type: 'error',
          message: getErrorMessageFromReason(error) || error.message,
          time: Date.now(),
          showed: true,
          temporal: true,
        });
      }
    }
  };

  fetchUsedCatalogs = async () => {
    try {
      this.setState({ usedCatalogsLoading: true });
      const used = await this.context.getAllCatalogs(true);
      if (used) {
        this.setState({ usedCatalogs: used?.catalogsList || [], allCatalogsCount: used?.count });
      }
    } catch (error) {
      if (error instanceof Error) {
        this.props.addAlert({
          type: 'error',
          message: getErrorMessageFromReason(error) || error.message,
          time: Date.now(),
          showed: true,
          temporal: true,
        });
      }
    } finally {
      this.setState({ usedCatalogsLoading: false });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.accordeonOpen !== prevState.accordeonOpen && this.state.usedCatalogs.length === 0) {
      this.fetchUsedCatalogs();
    }
  }

  toggleAccordeon = async () => {
    this.setState({ accordeonOpen: !this.state.accordeonOpen });
  };

  handleTrainVoice = async () => {
    const { match } = this.props;
    try {
      await this.context.trainVoice(+match.params.id);
      this.fetchData();
      this.toggleTrainModal();
    } catch (error) {
      if (error instanceof Error) {
        const alertMessage = getErrorMessageFromReason(error);
        this.props.addAlert({
          type: 'error',
          message: alertMessage || error.message,
          time: Date.now(),
          showed: true,
          temporal: true,
        });
      }
    }
  };

  createCatalog = async () => {
    try {
      const newCatalogId = await this.context.createCatalog({ title: t('untitled') });
      this.props.history.push(`${this.props.location.pathname}/${newCatalogId}`);
      this.props.logEvent('Voice dataset created', {
        page_url: this.props.match.url,
        result: 'success',
        member_role: this.props.voice.currentVoiceMemberRole.name,
        user_id: this.props.id,
      });
    } catch (error) {
      this.props.logEvent('Voice dataset created', {
        page_url: this.props.match.url,
        result: 'failed',
        member_role: this.props.voice.currentVoiceMemberRole.name,
        user_id: this.props.id,
      });
    }
  };

  toggleAddModal = () => this.setState({ addModal: !this.state.addModal });
  toggleTrainModal = () => this.setState({ trainModal: !this.state.trainModal });

  setDeletingIds = (value: number[]) => {
    this.setState({ deletingIds: value });
  };
  render() {
    const { match } = this.props;
    const { newVersionData, isLoading } = this.state;
    const isTrainingAvailable = newVersionData && newVersionData.seconds >= newVersionData.secondsLimit;

    return (
      <div className='voice-page__training training'>
        <DynamicTitle title={t('documentTitle.newVersion', this.props.voice.name, this.props.productName)} />
        <div className='training__new-voice'>
          <div className='new-voice__go-back'>
            <Link to={`/my/voice/${match.params.id}/training`}>
              <Icon name='falArrowLeft' size='sm' />
              <p>{t('backToTrainPage')}</p>
            </Link>
          </div>
          <div className='new-voice__header'>
            <div>
              <div className='new-voice__title'>
                <h3>{t('newVoiceVersion')}</h3>
                <h3 className='grey-text-1'>№{this.state.newVersionData?.version || 1}</h3>
              </div>
              {!isLoading && (
                <p className='text-secondary'>
                  {newVersionData &&
                    t(
                      `newVersionTrainingSub:${isTrainingAvailable ? 'canTrain' : 'noTrain'}`,
                      humanizeDuration(newVersionData.secondsLimit * 1000, true)
                    )}
                </p>
              )}
            </div>
            <div className='new-voice__buttons'>
              <Button
                disabled={!isTrainingAvailable}
                onClick={this.toggleTrainModal}
                color='primary'
                data-test-id='trainingPage.startTraining'
              >
                {t('startTraining')}
              </Button>
            </div>
          </div>
          <div className='new-voice__content-wrapper'>
            <div className='new-voice__content'>
              <div className='content__title'>
                <h4>{t('trainingMaterials')}</h4>
                <Button
                  outline
                  color='secondary'
                  iconLeft='falPlus'
                  onClick={this.createCatalog}
                  data-test-id='trainingPage.createCatalog'
                >
                  {t('uploadSet')}
                </Button>
              </div>
              <NotUsedTable
                isLoading={this.state.isLoading}
                notUsedCatalogs={this.state.notUsedCatalogs}
                fetchCatalogs={this.fetchData}
                deletingIds={this.state.deletingIds}
                setDeletingIds={this.setDeletingIds}
                voice={this.props.voice}
              />
            </div>
            {!this.state.isLoading && this.state.notUsedCatalogs?.length !== this.state.allCatalogsCount && (
              <div className='new-voice__content new-voice__accordeon'>
                <div className='accordeon__head'>
                  <div className='accordeon__title'>
                    <h3 className='st-1'>{t('prevSets')}</h3>
                    <p>{t('prevSetsSub1')}</p>
                    <p>{t('prevSetsSub2')}</p>
                  </div>
                  <Icon
                    name={this.state.accordeonOpen ? 'falChevronUp' : 'falChevronDown'}
                    color='secondary'
                    onClick={this.toggleAccordeon}
                  />
                </div>

                {this.state.accordeonOpen && (
                  <div className='content__table accordeon__body'>
                    {this.state.usedCatalogsLoading ? (
                      <AccordeonSkeleton />
                    ) : (
                      <>
                        <CatalogTableHead />
                        <div className='table__body'>
                          {this.state.usedCatalogs &&
                            this.state.usedCatalogs.length > 0 &&
                            this.state.usedCatalogs.map(catalog => (
                              <TableRow
                                catalog={catalog}
                                key={catalog.id}
                                fetchCatalogs={this.fetchUsedCatalogs}
                                deletingIds={this.state.deletingIds}
                                setDeletingIds={this.setDeletingIds}
                                voice={this.props.voice}
                              />
                            ))}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {!isLoading && <StepsForCreating />}
        <CustomModal
          isOpen={this.state.trainModal}
          toggle={this.toggleTrainModal}
          title={`${t('startTraining')}?`}
          position='center'
          modalCard
        >
          <CustomModalStartTraining
            handleModalAction={this.handleTrainVoice}
            cancelButtonClick={this.toggleTrainModal}
          />
        </CustomModal>
      </div>
    );
  }
}

export const CatalogTableHead = () => (
  <div className='table__head'>
    <div className='table__row'>
      <p>{t('title')}</p>
      <p>{t('changed')}</p>
      <p>{t('audioVolume')}</p>
      <p>{t('status')}</p>
    </div>
  </div>
);

export default withAmplitude({})(withRouter(withAppContext(withAppContext(NewVersion))));
