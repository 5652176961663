import React, { useEffect, useState } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { ModalBody, ModalHeader } from 'reactstrap';
import CustomModal from './CustomModal';
import { t } from '../../../../localization';
import { Icon, InputTextFullUncontrolled } from '@just-ai/just-ui';
import { useForm } from 'react-hook-form';
import { PublishedVoiceViewWithUsedVersion } from '../../../../api/facade/client';
import useAlert from '../../../../utils/useAlert';
import { RequestSuccessModal } from '.';
import { useAmplitude } from 'react-amplitude-hooks';
import { emailRegex } from '../../constants';
import { PhoneInput } from '../PhoneInput';
import { useAppContext } from '../../../../AppContext';
import { useCaptcha } from '../../../../utils/hooks';

type RequestProps = {
  voice?: PublishedVoiceViewWithUsedVersion;
  userId?: string;
  handleModalAction: () => void;
  emailPlaceholder?: string;
};

export const RequestModal = ({
  handleModalAction,
  emailPlaceholder = 'example@example.com',
  voice,
  userId,
}: RequestProps) => {
  const { register, handleSubmit, errors } = useForm<{ email: string; phone: string }>();
  const [success, setSuccess] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const { recaptchaInstance, verifyCallback, executeCaptcha, resetCaptcha, captchaState } = useCaptcha();

  const { defaultErrorAlert } = useAlert();
  const { logEvent } = useAmplitude();
  const { getUserLanguage, makeSlackRequest, configData, id } = useAppContext();

  useEffect(() => {
    //this is needed to show captcha widget in correct place after modal animations complete
    setTimeout(() => {
      setCaptchaVisible(true);
    }, 1000);
  }, []);

  const innerHandler = async () => {
    handleSubmit(async ({ email, phone }) => {
      try {
        if (voice) {
          if (configData.captcha?.enabled) {
            await executeCaptcha();
            await makeSlackRequest({
              type: 'access',
              requestBody: { email, voiceId: voice.id, voiceName: voice.name, phoneNumber: phone },
              recaptchaResponse: captchaState.gRecaptchaResponse,
            });
            logEvent('lysed_voice_request', {
              voice_id: voice?.id,
              email: email,
            });
          }
        } else if (userId) {
          if (configData.captcha?.enabled) {
            await executeCaptcha();
            await makeSlackRequest({
              type: 'server',
              requestBody: { email, phoneNumber: phone },
              recaptchaResponse: captchaState.gRecaptchaResponse,
            });
            logEvent('Dedicated server request', {
              userId: id,
              email,
              phone,
              result: 'success',
            });
          }
        }

        setSuccess(true);
      } catch (error) {
        defaultErrorAlert(t('requestError'));
        logEvent('Dedicated server request', {
          userId: id,
          email,
          phone,
          result: 'failed',
        });
      }
    })();
  };

  if (success) return <RequestSuccessModal handleModalAction={handleModalAction} />;

  return (
    <>
      <ModalHeader
        className='custom-modal__header request-modal'
        toggle={handleModalAction}
        charCode={<Icon name='falTimes' />}
        tag='h3'
      >
        {t('enterRequestMail')}
      </ModalHeader>
      <ModalBody className='custom-modal__body request-modal'>
        <div className='input-wrapper'>
          <InputTextFullUncontrolled
            name='email'
            type='email'
            placeholder='E-mail*'
            maxLength={70}
            required
            size='lg'
            defaultValue={emailPlaceholder}
            invalid={errors.email}
            innerRef={register({
              required: t('fieldRequired'),
              pattern: { value: emailRegex, message: t('invalidEmail') },
            })}
          />
          {errors.email && <p className='error-text small-text'>{errors.email.message}</p>}
        </div>
        <PhoneInput placeholder={t('phone')} ref={register()} name='phone' size='lg' />
      </ModalBody>
      <CustomModal.Footer
        actionButtonTitle={t('sendRequest')}
        buttonSize='xl'
        actionButtonClick={innerHandler}
        disabled={!captchaVisible}
      />
      <div className='modal-link-wrapper grey-text-1 small-text'>
        {t('agreementPolicy1')}{' '}
        <a
          href='https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf'
          target='_blank'
          rel='noreferrer'
          className='underline'
        >
          {t('agreementPolicy2')}
        </a>
      </div>
      {captchaVisible && configData?.captcha?.siteKey && configData?.captcha?.enabled && (
        <Recaptcha
          data-test-id='RequestModal.recaptcha'
          ref={recaptchaInstance}
          sitekey={configData?.captcha?.siteKey || '6Lek0PwUAAAAAFuLtQW3TnEBCddnkmV95IdGY6FE'}
          className='captcha'
          size='invisible'
          badge='bottomleft'
          onChange={verifyCallback}
          onReset={resetCaptcha}
          hl={getUserLanguage().substr(0, 2).toUpperCase()}
        />
      )}
    </>
  );
};
