import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { Textarea, Icon } from '@just-ai/just-ui';
import { ProgressBar } from '@just-ai/just-ui/dist/AudioPlayer/ProgressBar';
import classNames from 'classnames';
import { WAVEFORM_SVG_ID } from '../../../../App';
import { t } from '../../../../localization';

import './style.scss';
import { getErrorMessageFromReason } from '../../../../utils/error';
import { MIN_SYNTH_TEXT_LENGTH } from '../../constants';

type SynthAreaProps = {
  handler: (text: string) => Promise<any>;
  placeholderText?: string;
  textChangeWatcher?: (text: string) => void;
  initialText?: string;
  disabled?: boolean;
};

type SynthAreaState = {
  testText: string;
  synthedText: string;
  error: string;
  loading: boolean;
  playing: boolean;
};

type SynthAreaAction =
  | {
      type: 'SET_TEXT';
      payload: string;
    }
  | {
      type: 'SET_SYNTHED_TEXT';
      payload: string;
    }
  | {
      type: 'SET_PLAYING';
      payload: boolean;
    }
  | {
      type: 'SET_ERROR';
      payload: string;
    }
  | {
      type: 'SET_LOADING';
      payload: boolean;
    }
  | {
      type: 'RESET';
    };

const initialSynthAreaState: SynthAreaState = {
  testText: '',
  error: '',
  synthedText: '',
  playing: false,
  loading: false,
};

function synthAreaReducer(state: SynthAreaState, action: SynthAreaAction): SynthAreaState {
  switch (action.type) {
    case 'SET_TEXT':
      return { ...state, testText: action.payload };
    case 'SET_SYNTHED_TEXT':
      return { ...state, synthedText: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_PLAYING':
      return { ...state, playing: action.payload };
    case 'RESET':
      return initialSynthAreaState;
    default:
      throw new Error('Error in synth area reducer');
  }
}

export default function SynthArea(props: SynthAreaProps) {
  const audioElemRef = useRef<HTMLAudioElement>(null);
  const { textChangeWatcher, disabled } = props;
  const [state, dispatch] = useReducer(synthAreaReducer, initialSynthAreaState, state => ({
    ...state,
    testText: props.initialText || '',
  }));
  const { playing, synthedText, testText, error, loading } = state;
  const audioToggle = useCallback(() => {
    if (playing) {
      audioElemRef.current?.pause();
      return dispatch({ type: 'SET_PLAYING', payload: false });
    }
    audioElemRef.current?.play();
    dispatch({ type: 'SET_PLAYING', payload: true });
  }, [playing]);

  const handlePlayerClick = useCallback(async () => {
    dispatch({ type: 'SET_ERROR', payload: '' });
    if (loading || !testText || testText.length < 4) return;
    if (synthedText === testText) {
      return audioToggle();
    }
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      if (testText.length < MIN_SYNTH_TEXT_LENGTH) throw new Error('Text is too short');
      const newAudioUrl = await props.handler(testText);
      dispatch({ type: 'SET_SYNTHED_TEXT', payload: testText });
      if (audioElemRef.current) {
        audioElemRef.current.currentTime = 0;
        audioElemRef.current.src = newAudioUrl;
      }
      audioToggle();
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: getErrorMessageFromReason(error) });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [audioToggle, props, synthedText, testText, loading]);

  useEffect(() => {
    audioElemRef.current?.pause();
    dispatch({ type: 'SET_PLAYING', payload: false });
    dispatch({ type: 'SET_ERROR', payload: '' });
  }, [testText]);

  const handlePlayend = useCallback(() => {
    dispatch({ type: 'SET_PLAYING', payload: false });
    if (audioElemRef && audioElemRef.current) {
      audioElemRef.current.currentTime = 0;
    }
  }, [audioElemRef]);

  const handleTextAreaChange = value => {
    dispatch({ type: 'SET_TEXT', payload: value });
    textChangeWatcher && textChangeWatcher(value);
    if (audioElemRef.current) audioElemRef.current.currentTime = 0;
  };

  return (
    <div className={classNames('syntharea__container', { disabled })}>
      <audio ref={audioElemRef} controls={false} onEnded={handlePlayend} />
      <div className='synth-area__textarea'>
        <Textarea
          value={testText}
          onChange={handleTextAreaChange}
          rows='5'
          maxLength='200'
          placeholder={props.placeholderText || t('textForTestLength', 50)}
          disabled={loading || disabled}
          data-test-id='synthArea.textarea'
        />
        <div className='synth-area__textarea-controls'>
          {error && (
            <div className='error-text small-text'>
              <Icon color='danger' name='faTimesCircle' size='sm' /> {error}
            </div>
          )}
          <div className='voice-card__player my-0 player__container player__container--catalog justui-audio-player'>
            <div
              className={classNames('play-icon-container', {
                disabled: !testText || testText.length < MIN_SYNTH_TEXT_LENGTH,
              })}
              onClick={handlePlayerClick}
              data-test-id='synthArea.synthButton'
            >
              <Icon
                className={classNames({ 'fa-spin': loading })}
                color='primary'
                name={loading ? 'faCircleNotch' : playing ? 'faPause' : 'faPlay'}
                size='sm'
              />
            </div>
            <ProgressBar
              histogram
              histogramId={WAVEFORM_SVG_ID}
              audio={audioElemRef.current as HTMLAudioElement}
              progressUpdateInterval={10}
              ShowFilledProgress
            />
          </div>
        </div>
      </div>
    </div>
  );
}
