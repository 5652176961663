import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';

import { Button } from '@just-ai/just-ui';
import useDefaultAlert from '../../../../utils/useAlert';
import { useProjectContext } from '../../context/ProjectsContext';
import { useReplicas } from '../../context/ProjectDataContex';
import { t } from '../../../../localization';

import classes from './AiWriter.module.scss';

export const AiWriterItem = ({ story }: { story: string }) => {
  const [storyCopied, setStoryCopied] = useState(false);
  const [storyAdded, setStoryAdded] = useState(false);
  const { defaultErrorAlert } = useDefaultAlert();
  const { bulkUpdate } = useProjectContext();
  const { replicas, currentPage, fetchReplicas } = useReplicas();
  const { projectId } = useParams<{ projectId: string }>();

  const getTransformedText = useCallback((text: string) => {
    return text
      .split('\n')
      .map(storyPart => storyPart.trim())
      .filter(storyPart => storyPart.length > 0);
  }, []);

  const handleStoryCopy = useCallback(() => {
    navigator.clipboard.writeText(getTransformedText(story).join('\n'));
    !storyCopied && setStoryCopied(true);
  }, [getTransformedText, story, storyCopied]);

  const handleStoryAdd = useCallback(async () => {
    try {
      const replicasToCreate = getTransformedText(story).map((replicaText, index) => {
        return {
          text: replicaText,
          projectId,
          index: replicas.length + index,
        };
      });
      await bulkUpdate(projectId, { created: replicasToCreate });
      await fetchReplicas(currentPage, true, false);
      !storyAdded && setStoryAdded(true);
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [
    bulkUpdate,
    currentPage,
    defaultErrorAlert,
    fetchReplicas,
    getTransformedText,
    projectId,
    replicas.length,
    story,
    storyAdded,
  ]);

  return (
    <div className={classes['AiWriter__item-wrapper']}>
      <div className={classes['AiWriter__item-buttons']}>
        <Button color={storyCopied ? 'success' : 'secondary'} size='sm' onClick={handleStoryCopy}>
          {t(storyCopied ? 'AiWriter:Response:Item:CopyButton:Copied' : 'AiWriter:Response:Item:CopyButton:Copy')}
        </Button>
        <Button color={storyAdded ? 'success' : 'secondary'} size='sm' onClick={handleStoryAdd}>
          {t(storyAdded ? 'AiWriter:Response:Item:AddButton:Added' : 'AiWriter:Response:Item:AddButton:Add')}
        </Button>
      </div>
      <p className={cn(classes['AiWriter__item-story'], 'tp-4')}>{story}</p>
      <p className={cn(classes['AiWriter__item-characters'], 'tp-5')}>
        {t('AiWriter:Response:Item:Characters', story.length)}
      </p>
    </div>
  );
};
