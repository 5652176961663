export const commonLocalization = {
  catalog: {
    eng: 'Catalog of voices',
    ru: 'Каталог голосов',
  },
  voice: {
    eng: 'Voice',
    ru: 'Голос',
  },
  voices: {
    eng: 'Voices',
    ru: 'Голоса',
  },
  sort: {
    eng: 'Sort',
    ru: 'Сортировать',
  },
  help: {
    eng: 'Help',
    ru: 'Справка',
  },
  myVoices: {
    eng: 'My page',
    ru: 'Моя страница',
  },
  createVoice: {
    eng: 'Create voice',
    ru: 'Создать голос',
  },
  tags: {
    eng: 'Tags',
    ru: 'Теги',
  },
  download: {
    eng: 'Download',
    ru: 'Скачать',
  },
  delete: {
    eng: 'Delete',
    ru: 'Удалить',
  },
  voiceName: {
    eng: 'Voice name',
    ru: 'Название голоса',
  },
  voiceGender: {
    eng: 'Gender',
    ru: 'Пол',
  },
  description: {
    eng: 'Description',
    ru: 'Описание',
  },
  create: {
    eng: 'Create',
    ru: 'Создать',
  },
  cancel: {
    eng: 'Cancel',
    ru: 'Отмена',
  },
  male: {
    eng: 'Male',
    ru: 'Мужской',
  },
  female: {
    eng: 'Female',
    ru: 'Женский',
  },
  aboutVoice: {
    eng: 'About',
    ru: 'О голосе',
  },
  learningData: {
    eng: 'Training data',
    ru: 'Обучающие наборы',
  },
  edit: {
    eng: 'Edit',
    ru: 'Изменить',
  },
  createSetInfo: {
    eng: 'The set allows you to structure samples (voice examples) by categories',
    ru: 'Набор позволяет структурировать семплы (примеры вашего голоса) по темам',
  },
  orDragHere: {
    eng: 'Drag a ZIP archive here ',
    ru: 'Перетащите ZIP-архив сюда ',
  },
  materialSet: {
    eng: 'Set of materials',
    ru: 'Набор материалов',
  },
  status: {
    eng: 'Status',
    ru: 'Статус',
  },
  uploading: {
    eng: 'Uploading',
    ru: 'Загружается',
  },
  processing: {
    eng: 'Processing',
    ru: 'Обрабатывается',
  },
  processed: {
    eng: 'Processed',
    ru: 'Обработан',
  },
  processedNeutral: {
    eng: 'Processed',
    ru: 'Обработано',
  },
  error: {
    eng: 'Error',
    ru: 'Ошибка',
  },
  learningError: {
    eng: 'Training error',
    ru: 'Ошибка обучения',
  },
  inQueue: {
    eng: 'In queue',
    ru: 'В очереди',
  },
  training: {
    eng: 'Training',
    ru: 'Обучается',
  },
  trained: {
    eng: 'Trained',
    ru: 'Обучена',
  },
  trainedMale: {
    eng: 'Trained',
    ru: 'Обучен',
  },
  published: {
    eng: 'Published',
    ru: 'Опубликована',
  },
  train: {
    eng: 'Train',
    ru: 'Обучить',
  },
  startTraining: {
    eng: 'Start training',
    ru: 'Начать обучение',
  },
  audioVolume: {
    eng: 'Audio data amount',
    ru: 'Объем аудио',
  },
  publish: {
    eng: 'Publish',
    ru: 'Опубликовать',
  },
  allVoices: {
    eng: 'All $[1] voices',
    ru: 'Все голоса $[1]',
  },
  trainingNoun: {
    eng: 'Training',
    ru: 'Обучение',
  },
  dataCollecting: {
    eng: 'Data collecting',
    ru: 'Сбор данных',
  },
  account: {
    eng: 'Account',
    ru: 'Аккаунт',
  },
  loginButtonCaption: {
    eng: 'Sign in',
    ru: 'Войти',
  },
  phone: {
    eng: 'Phone number',
    ru: 'Телефон',
  },
  optional: {
    eng: 'optional',
    ru: 'необязательно',
  },
  privacyPolicy: {
    eng: 'Privacy policy',
    ru: 'Политика конфиденциальности',
  },
  privacyPolicyLink: {
    eng: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf',
    ru: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf',
  },
  privacyPolicyLinkUSD: {
    eng: 'https://tovie.ai/eng/docs/pp.pdf',
    ru: 'https://tovie.ai/eng/docs/pp.pdf',
  },
  copyright: {
    eng: '© 2017-$[1] Mind Craft LLC',
    ru: '© 2017-$[1] ООО «Маинд Крафт»',
  },
  copyrightUSD: {
    eng: '© 2018-$[1] Tovie AI Limited',
    ru: '© 2018-$[1] Tovie AI Limited',
  },
  logout: {
    eng: 'Sign out',
    ru: 'Выйти из аккаунта',
  },
  uploadSet: {
    eng: 'Add set',
    ru: 'Создать набор',
  },
  recordSet: {
    eng: 'Record set',
    ru: 'Записать сет',
  },
  aboutAllowedFormats: {
    eng: 'Detailed file requirements',
    ru: 'Подробные требования к файлу',
  },
  deleteCatalogWarning: {
    eng: 'You will not be able to restore deleted data',
    ru: 'Удаленный набор нельзя будет восстановить',
  },
  trainingSetDescription: {
    eng: 'Training materials we will use to train the voice',
    ru: 'Наборы материалов, на которых мы будем обучать голос',
  },
  createSetDescription: {
    eng: 'Create a set of training materials',
    ru: 'Создайте набор обучающих материалов',
  },
  enterTitle: {
    eng: 'Enter the name',
    ru: 'Введите название',
  },
  enterText: {
    eng: 'Enter text',
    ru: 'Введите текст',
  },
  uploadDate: {
    eng: 'Upload date',
    ru: 'Дата загрузки',
  },
  uploadArchiveListItem1: {
    eng: 'Add audio and text files having the same names to a ZIP archive',
    ru: 'Объедините пары текстовых и аудиофайлов с одинаковыми названиями в ZIP-архив',
  },
  uploadArchiveListItem2: {
    eng: 'Number of file pairs in one archive is unlimited',
    ru: 'Количество пар в архиве не ограничено',
  },
  uploadArchiveListItem3: {
    eng: 'You can upload text files only and voice them later on the website',
    ru: 'Вы можете загрузить только текстовые файлы и озвучить их прямо на сайте',
  },
  nameDesc: {
    eng: 'Name / Description',
    ru: 'Название / Описание',
  },
  uploadMaterial: {
    eng: 'Upload training data',
    ru: 'Загрузите обучающие материалы',
  },
  upload: {
    eng: 'Upload',
    ru: 'Загрузить',
  },
  versionNumber: {
    eng: 'Version $[1]',
    ru: 'Версия $[1]',
  },
  enabled: {
    eng: 'Enabled',
    ru: 'Включен',
  },
  open: {
    eng: 'Open',
    ru: 'Открыть',
  },
  trainingMaterials: {
    eng: 'Training materials',
    ru: 'Обучающие материалы',
  },
  createSample: {
    eng: 'Create sample',
    ru: 'Создать семпл',
  },
  textForRecord: {
    eng: 'Text for recording',
    ru: 'Текст для озвучивания',
  },
  title: {
    eng: 'Name',
    ru: 'Название',
  },
  noData: {
    eng: 'No data',
    ru: 'Нет данных',
  },
  errorLoading: {
    eng: 'Uploading error',
    ru: 'Ошибка загрузки',
  },
  fieldRequired: {
    eng: 'This field is required',
    ru: 'Обязательное поле',
  },
  invalidEmail: {
    eng: 'Enter valid email',
    ru: 'Введите корректный адрес',
  },
  placeholderHeader: {
    eng: 'We have almost launched this page!',
    ru: 'Мы почти запустили эту страницу!',
  },
  returnSoon: {
    eng: 'Come back soon',
    ru: 'Обязательно возвращайтесь',
  },
  'amount found': {
    eng: '$[1] found',
    ru: '$[1] найдено',
  },
  'sort:Popularity': {
    eng: 'by popularity',
    ru: 'по популярности',
  },
  'sort:Cost': {
    eng: 'by price',
    ru: 'по цене',
  },
  'sort:Time': {
    eng: 'newest first',
    ru: 'по новизне',
  },
  'for games': {
    eng: 'For games',
    ru: 'Для игр',
  },
  'for sales': {
    eng: 'For sales',
    ru: 'Для продаж',
  },
  'for calls': {
    eng: 'For calls',
    ru: 'Для обзвонов',
  },
  supervoice: {
    eng: 'Supervoice',
    ru: 'Суперголос',
  },
  other: {
    eng: 'Other',
    ru: 'Другой',
  },
  price: {
    eng: 'Price',
    ru: 'Цена',
  },
  from: {
    eng: 'From',
    ru: 'От',
  },
  to: {
    eng: 'To',
    ru: 'До',
  },
  'catalog promo header': {
    eng: 'Create your voice',
    ru: 'Создайте свой голос',
  },
  'catalog promo text': {
    eng: 'Create your voices and earn money',
    ru: 'Создавайте собственные голоса и зарабатывайте',
  },
  'catalog promo button caption': {
    eng: 'Start',
    ru: 'Перейти к созданию',
  },
  'hours amount recorded1': {
    eng: '$[1] hour recorded',
    ru: '$[1] час озвучен',
  },
  'hours amount recorded2': {
    eng: '$[1] hours recorded',
    ru: '$[1] часа озвучено',
  },
  'hours amount recorded5': {
    eng: '$[1] hours recorded',
    ru: '$[1] часов озвучено',
  },
  thousands: {
    eng: '$[1] thousands',
    ru: '$[1] тыс.',
  },
  'supervoice tooltip': {
    eng: 'Voice quality is guaranteed',
    ru: 'Гарантируем качество голоса',
  },
  'add to favorite': {
    eng: 'Add to favorites',
    ru: 'Добавить в избранное',
  },
  voiceTraining: {
    eng: 'Voice training',
    ru: 'Обучение голоса',
  },
  'voiceTrainingSub:noTrain': {
    eng: 'Upload the required amount of training materials and train the version',
    ru: 'Загрузите необходимое количество обучающих материалов и обучите версию',
  },
  'voiceTrainingSub:canTrain': {
    eng:
      'The required amount of training materials has been uploaded\nNow you can start training the new voice version',
    ru: 'Загружено необходимое количество материалов\nТеперь вы можете начать обучение новой версии голоса',
  },
  'voiceTrainingSub:mobile': {
    eng: 'Upload the required amount of training materials and train the version',
    ru: 'Загрузите необходимое количество обучающих материалов для продолжения обучения',
  },
  continueCreation: {
    eng: 'Continue',
    ru: 'Продолжить создание',
  },
  newVoiceVersion: {
    eng: 'New version of the voice',
    ru: 'Новая версия голоса',
  },
  backToTrainPage: {
    eng: 'To the training page',
    ru: 'На страницу обучения',
  },
  backToVoicePage: {
    eng: 'To the voice page',
    ru: 'На страницу голоса',
  },
  changed: {
    eng: 'Modified',
    ru: 'Изменен',
  },
  emptyCatalogs: {
    eng: 'The list of training materials is empty',
    ru: 'Список обучающих материалов пуст',
  },
  emptyCatalogsSub: {
    eng: 'To process materials fast and convenient, we combine them into sets',
    ru: 'Для удобства и скорости обработки материалов мы объединяем их в наборы',
  },
  prevSets: {
    eng: 'Previous training materials',
    ru: 'Предыдущие обучающие материалы',
  },
  prevSetsSub1: {
    eng: 'Training sets used for previous voice versions',
    ru: 'Обучающие наборы предыдущих моделей голоса',
  },
  prevSetsSub2: {
    eng: 'They will be used when training a new version',
    ru: 'Они будут использоваться при обучении новой версии',
  },
  today: {
    eng: 'Today',
    ru: 'Сегодня',
  },
  yesterday: {
    eng: 'Yesterday',
    ru: 'Вчера',
  },
  changesSaved: {
    eng: 'Changes saved successfully',
    ru: 'Изменения успешно сохранены',
  },
  saveChanges: {
    eng: 'Save changes',
    ru: 'Сохранить изменения',
  },
  managers: {
    eng: 'Managers',
    ru: 'Менеджеры',
  },
  'manager: Creator': {
    eng: 'Creator',
    ru: 'Создатель',
  },
  'manager: Edit': {
    eng: 'Edit',
    ru: 'Редактировать',
  },
  'managerRole: administrator': {
    eng: 'Administrator',
    ru: 'Администратор',
  },
  'managerRole: AdministratorDesc': {
    eng: 'Can train and record the voice, edit its name, description, and price',
    ru: 'Может обучать и озвучивать голос, редактировать название, описание и стоимость',
  },
  'managerRole: manager': {
    eng: 'Manager',
    ru: 'Менеджер',
  },
  'managerRole: ManagerDesc': {
    eng: 'Can train the voice and edit its name, description, and price',
    ru: 'Может обучать голос и редактировать название, описание и стоимость',
  },
  'managerRole: speaker': {
    eng: 'Speaker',
    ru: 'Диктор',
  },
  'managerRole: SpeakerDesc': {
    eng: 'Can record the voice',
    ru: 'Может озвучивать голос',
  },
  'manager: Remove': {
    eng: 'Delete from the list',
    ru: 'Удалить из списка',
  },
  'manager: Add': {
    eng: 'Add manager',
    ru: 'Добавить менеджера',
  },
  makeMoney: {
    eng: 'Earn on your voice',
    ru: 'Зарабатывайте на своем голосе',
  },
  catalogPlacement: {
    eng: 'Publishing in the catalog',
    ru: 'Размещение в каталоге',
  },
  'publishVoice: Desc': {
    eng: 'Publish your voice in the catalog to profit from its use by other people',
    ru: 'Разместите голос в каталоге, чтобы получать прибыль с его использования другими людьми',
  },
  'publishVoice: Button': {
    eng: 'Publish in the catalog',
    ru: 'Разместить в каталоге',
  },
  publicPage: {
    eng: 'Public page',
    ru: 'Публичная страница',
  },
  voiceAvailabilities: {
    eng: 'Voice is available to all users',
    ru: 'Голос доступен всем пользователям',
  },
  hideFromCatalog: {
    eng: 'Hide from catalog',
    ru: 'Скрыть из каталога',
  },
  voicePrice: {
    eng: 'Voice price',
    ru: 'Стоимость голоса',
  },
  minVoicePrice: {
    eng: 'Minimum voice price is $[1] per minute',
    ru: 'Минимальная стоимость голоса 0,5 ₽/мин',
  },
  minVoicePricePlaceholder: {
    eng: 'minimum $[1]',
    ru: 'минимум $[1]',
  },
  usuallyVoicePrice: {
    eng: 'Usually voices cost from $[1] per minute',
    ru: 'Обычно голоса стоят от 0,7 до 2,9 ₽/мин',
  },
  rublesPerMinute: {
    eng: 'rubles per minute',
    ru: 'рублей в минуту',
  },
  priceWarning: {
    eng: 'The minimum price is $[1] per minute. You will receive 50% of your markup to this amount.',
    ru: 'Минимальная стоимость — $[1] ₽/мин. Вы будете получать 50% от вашей наценки к этой сумме.',
  },
  textForTestLength: {
    eng: 'Enter text with at least $[1] character',
    ru: 'Введите текст не менее $[1] символа',
  },
  textForTestLengthDialog: {
    eng: 'Each phrase must contain at least 1 characterы',
    ru: 'В каждой реплике должно быть не менее 1 символа',
  },
  voiceDesc: {
    eng: 'Describe the voice',
    ru: 'Опишите голос',
  },
  enterEmail: {
    eng: 'Enter your email',
    ru: 'Введите email',
  },
  invite: {
    eng: 'Invite',
    ru: 'Пригласить',
  },
  exclude: {
    eng: 'Exclude',
    ru: 'Исключить',
  },
  save: {
    eng: 'Save',
    ru: 'Сохранить',
  },
  removeVoice: {
    eng: 'Remove “$[1]” voice from the catalog?',
    ru: 'Скрыть голос «$[1]» из каталога?',
  },
  removeVoiceModal: {
    eng:
      'The voice will become unavailable to users and will not be displayed in the catalog. You can publish it again at any moment on the management page.',
    ru:
      'Голос станет недоступен пользователям и не будет отображаться в каталоге. Вы сможете снова опубликовать его в любое время на странице управления.',
  },
  hide: {
    eng: 'Remove',
    ru: 'Скрыть',
  },
  control: {
    eng: 'Management',
    ru: 'Управление',
  },
  onControlPage: {
    eng: 'the management page',
    ru: 'странице управления',
  },
  publishedVersions: {
    eng: 'Published versions',
    ru: 'Опубликованные версии',
  },
  using: {
    eng: 'Use',
    ru: 'Использование',
  },
  voicePublished: {
    eng: 'The voice has been published in the catalog',
    ru: 'Голос размещен в каталоге',
  },
  voicePublishedFromControl: {
    eng: 'It is now available to other users',
    ru: 'Теперь он доступен для других пользователей',
  },
  voicePublishedFromUsage: {
    eng: 'You can change its parameters on',
    ru: 'Вы можете изменять его параметры на',
  },
  removeFromCatalog: {
    eng: 'The voice has been removed from the catalog. It is now unavailable to other users.',
    ru: 'Голос скрыт из каталога. Теперь он недоступен для других пользователей',
  },
  minute: {
    eng: 'min',
    ru: 'мин',
  },
  'VoicePage:Control:AddManager': {
    eng: 'Grant access',
    ru: 'Предоставить доступ',
  },
  'VoicePage:Control:RemoveManager': {
    eng: 'Delete manager',
    ru: 'Удалить менеджера',
  },
  'VoicePage:MobileControl:RemoveManager': {
    eng: 'Delete manager',
    ru: 'Удалить менеджера',
  },
  'VoicePage:Control:EditManager': {
    eng: 'Edit access',
    ru: 'Изменить доступ',
  },
  publishVoiceDesc: {
    eng: 'This version will replace the latest one and become the current voice version',
    ru: 'Эта версия заменит последнюю и станет текущей версией голоса',
  },
  hoursShort: {
    eng: 'h',
    ru: 'ч',
  },
  noAudioError: {
    eng: 'No audio',
    ru: 'Нет аудио',
  },
  notRecognizedError: {
    eng: 'Text not recognized',
    ru: 'Текст не распознан',
  },
  noiseError: {
    eng: 'Noisy recording',
    ru: 'Шумная запись',
  },
  noMaterialsError: {
    eng: 'No materials',
    ru: 'Нет материалов',
  },
  publishedEmptyTitle: {
    eng: 'There are no published versions of the voice',
    ru: 'Пока ни одна версия голоса не опубликована',
  },
  publishedEmptySubTitle: {
    eng: 'The versions that you trained and published for use will be displayed here',
    ru: 'Здесь будут отображаться версии, которые вы обучили и опубликовали для использования',
  },
  actual: {
    eng: 'Current',
    ru: 'Текущая',
  },
  prevVoiceVersions: {
    eng: 'Previous versions',
    ru: 'Предыдущие версии',
  },
  startTrainingModal: {
    eng: 'When the training starts, you will not be able to add new training materials to this version of the voice.',
    ru: 'Когда обучение запустится, вы не сможете добавить в нее новые обучающие материалы',
  },
  start: {
    eng: 'Start',
    ru: 'Начать',
  },
  toVoiceLearn: {
    eng: 'To voice training',
    ru: 'К обучению голоса',
  },
  'publishVoice: toLearn': {
    eng: 'Train and publish a version of the voice to place it in the catalog',
    ru: 'Обучите и опубликуйте версию голоса, чтобы разместить его в каталоге',
  },
  publishVoice: {
    eng: 'Publish voice',
    ru: 'Опубликовать голос',
  },
  moveToSection: {
    eng: 'Now it is in the section',
    ru: 'Теперь она в разделе',
  },
  testItDesc: {
    eng: 'This version has been trained! Now you can try it out',
    ru: 'Версия обучена! Теперь вы можете ее протестировать',
  },
  'createVoice: inputPlaceholder': {
    eng: 'Give your voice a name',
    ru: 'Дайте название голосу',
  },
  'createVoice: textareaPlaceholder': {
    eng: 'Describe the voice: its timbre, tonality, mood',
    ru: 'Опишите голос: его тембр, тональность, настроение',
  },
  voiceCreated: {
    eng: 'Voice “$[1]” created',
    ru: 'Голос «$[1]» создан',
  },
  pleaseTryAgain: {
    eng: 'Try again',
    ru: 'Попробуйте еще раз',
  },
  usageHistory: {
    eng: 'Usage history',
    ru: 'История использования',
  },
  versionHistory: {
    eng: 'Version history',
    ru: 'История версий',
  },
  usageEmptyTitle: {
    eng: 'No one has used this voice yet',
    ru: 'Этот голос пока никто не использовал',
  },
  usageEmptySubTitle: {
    eng: 'Train your voice to start using it',
    ru: 'Обучите голос, чтобы начать его использовать',
  },
  toTrain: {
    eng: 'Go to training',
    ru: 'Перейти к обучению',
  },
  date: {
    eng: 'Date',
    ru: 'Дата',
  },
  duration: {
    eng: 'Duration',
    ru: 'Длительность',
  },
  changeVoiceAvatar: {
    eng: 'Change voice image',
    ru: 'Изменить аватар голоса',
  },
  dropImage: {
    eng: 'Drag the image here',
    ru: 'Перетащите фото сюда',
  },
  or: {
    eng: 'or',
    ru: 'или',
  },
  uploadFromPC: {
    eng: 'Upload from device',
    ru: 'Загрузить с устройства',
  },
  cropImage: {
    eng: 'Cropping image',
    ru: 'Обрезка изображения',
  },
  cropImageShort: {
    eng: 'Cropping',
    ru: 'Обрезка',
  },
  savingAvatar: {
    eng: 'Saving the voice image',
    ru: 'Сохранение аватара голоса',
  },
  avatarSizeError: {
    eng: 'Make sure you upload a photo no larger than 5 MB and try again.',
    ru: 'Убедитесь, что загружаете фото размером не более 5 Мбайт, и повторите попытку.',
  },
  avatarTypeError: {
    eng: 'Make sure you upload a JPG, JPEG, or PNG file and try again.',
    ru: 'Убедитесь, что загружаете файл JPG, JPEG или PNG, и повторите попытку.',
  },
  saveError: {
    eng: 'Saving error',
    ru: 'Произошла ошибка при сохранении',
  },
  createSampleError: {
    eng: 'Error creating the sample',
    ru: 'Произошла ошибка при создании семпла',
  },
  tryVoice: {
    eng: 'Try out the voice',
    ru: 'Попробуйте голос',
  },
  enterTextForSynth: {
    eng: 'Enter the phrase you want to listen to…',
    ru: 'Введите фразу, которую хотите прослушать…',
  },
  voiceAvatarUpdated: {
    eng: 'Voice image updated',
    ru: 'Аватар голоса обновлен',
  },
  publishSynthHeader: {
    eng: 'Text for voice testing',
    ru: 'Текст для тестирования голоса',
  },
  publishSynthDetails: {
    eng: 'It will be displayed as a sample on the voice card in the catalog, so users can try out how the voice sounds',
    ru: 'Будет размещен на карточке в каталоге в виде семпла, чтобы пользователи могли оценить звучание голоса',
  },
  deleteCatalog: {
    eng: 'Delete set',
    ru: 'Удалить набор',
  },
  successDeleteCatalog: {
    eng: 'Set “$[1]” deleted',
    ru: 'Набор «$[1]» удален',
  },
  connectedVersion: {
    eng: 'Version $[1] connected',
    ru: 'Версия $[1] подключена',
  },
  yourToken: {
    eng: 'Your token',
    ru: 'Ваш токен',
  },
  connectAPI: {
    eng: 'Connect using the API',
    ru: 'Подключить по API',
  },
  connected: {
    eng: 'Connected',
    ru: 'Подключена',
  },
  versionFromDate: {
    eng: '$[1] from $[2]',
    ru: '$[1] от $[2]',
  },
  actualVersion: {
    eng: 'Current version',
    ru: 'Текущая версия',
  },
  publicationUpdate: {
    eng: 'Voice updated successfully',
    ru: 'Голос успешно обновлен',
  },
  notVoiced: {
    eng: 'Not voiced',
    ru: 'Не озвучены',
  },
  voiced: {
    eng: 'Voiced',
    ru: 'Озвучены',
  },
  rename: {
    eng: 'Rename',
    ru: 'Переименовать',
  },
  noLearningData: {
    eng: 'You have not uploaded any training materials yet',
    ru: 'Пока нет ни одного обучающего материала',
  },
  noLearningDataDesc: {
    eng: 'Create or upload materials from your device as text and audio',
    ru: 'Создайте или загрузите материалы с устройства в текстовом и аудиоформате',
  },
  addDescription: {
    eng: 'Add description',
    ru: 'Добавить описание',
  },
  addSample: {
    eng: '“$[1]” training material added',
    ru: 'Обучающий материал «$[1]» добавлен',
  },
  pressButton: {
    eng: 'Press',
    ru: 'Нажмите',
  },
  untitled: {
    eng: 'Untitled',
    ru: 'Без названия',
  },
  doNotSave: {
    eng: 'Do not save',
    ru: 'Не сохранять',
  },
  saveSample: {
    eng: 'Save sample “$[1]”',
    ru: 'Сохранить семпл «$[1]»',
  },
  pressSpaceToRecord: {
    eng: 'Press the space bar to start recording',
    ru: 'Нажмите пробел, чтобы начать запись',
  },
  deleteExactSample: {
    eng: 'Delete sample “$[1]”',
    ru: 'Удалить семпл «$[1]»',
  },
  recordSaved: {
    eng: 'Recording saved',
    ru: 'Запись сохранена',
  },
  downloadRecord: {
    eng: 'Download recording',
    ru: 'Скачать запись',
  },
  deleteRecord: {
    eng: 'Delete recording',
    ru: 'Удалить запись',
  },
  waitUntilEnd: {
    eng: 'Wait until the processing is completed to delete the recording',
    ru: 'Дождитесь окончания обработки, чтобы удалить запись',
  },
  uploadArchiveTooltip: {
    eng: 'Upload archive',
    ru: 'Загрузить архив',
  },
  saveAudio: {
    eng: 'Save audio',
    ru: 'Сохранить аудио',
  },
  myToken: {
    eng: 'My API token',
    ru: 'Мой токен API',
  },
  connectThisVersion: {
    eng: 'Connect this version',
    ru: 'Подключить эту версию',
  },
  promoStep1: {
    eng: 'Create and train your voice',
    ru: 'Создайте и обучите голос',
  },
  promoStep1Desc: {
    eng: 'Record 10 h of audio voice samples and start training',
    ru: 'Запишите 10 ч аудио примеров голоса и запустите обучение',
  },
  promoStep2: {
    eng: 'Connect to your project and use the voice',
    ru: 'Подключите к своему проекту и используйте',
  },
  promoStep2Desc: {
    eng: 'Choose the appropriate integration — synthesize on the website, connect using the API or to partner services',
    ru: 'Выберите подходящую интеграцию — синтезируйте на сайте, подключите по API или к партнерским сервисам',
  },
  promoStep3: {
    eng: 'Publish the voice in the catalog and earn money',
    ru: 'Разместите голос в каталоге и получайте деньги',
  },
  promoStep3Desc: {
    eng: 'You can share your voice with other users and earn money',
    ru: 'Вы можете предоставить доступ к голосу другим пользователям и получать доход',
  },
  banner: {
    eng: 'Ask a question / order voice development',
    ru: 'Задать вопрос / заказать разработку голоса',
  },
  allVersion: {
    eng: 'All versions',
    ru: 'Все версии',
  },
  myBalance: {
    eng: 'My balance',
    ru: 'Мой баланс',
  },
  enterTextForRecord: {
    eng: 'Enter the text to be recorded',
    ru: 'Введите текст для озвучивания',
  },
  deleteSampleAlert: {
    eng: '“$[1]” training material deleted',
    ru: 'Обучающий материал «$[1]» удален',
  },
  noMediaPermission: {
    eng: 'You have denied access to the microphone',
    ru: 'Вы запретили доступ к микрофону',
  },
  noMediaPermissionDesc: {
    eng: 'To grant access, click the microphone icon on the top bar of your browser and reload this page',
    ru: 'Чтобы разрешить доступ, нажмите на иконку микрофона в верхней панели браузера и перезагрузите эту страницу',
  },
  createVoiceTitle1: {
    eng: 'Create your',
    ru: 'Создайте свой',
  },
  unique: {
    eng: 'Unique',
    ru: 'Уникальный',
  },
  createVoiceTitle2: {
    eng: 'Voice and earn',
    ru: 'Голос и зарабатывайте',
  },
  useInProject: {
    eng: 'Use in your projects',
    ru: 'Используйте в своих проектах',
  },
  sell: {
    eng: 'Sell ​​to other $[1] users',
    ru: 'Продавайте другим пользователям $[1]',
  },
  soundAnyContent: {
    eng: 'Voice any content',
    ru: 'Озвучивайте любой контент',
  },
  videosAndFilms: {
    eng: 'Videos and films',
    ru: 'Видеоролики и фильмы',
  },
  audiobooks: {
    eng: 'Audiobooks',
    ru: 'Аудиокниги',
  },
  contactCenter: {
    eng: 'Contact centers',
    ru: 'Контакт-центры',
  },
  podcasts: {
    eng: 'Podcasts',
    ru: 'Подкасты',
  },
  videogames: {
    eng: 'Video games',
    ru: 'Видеоигры',
  },
  placeInMarketplace: {
    eng: 'Publish your voice in the marketplace',
    ru: 'Разместите голос в каталоге',
  },
  createLandingSubtitle: {
    eng: 'Creating a voice is easy',
    ru: 'Создать голос — просто',
  },
  createLandingSubtitleDesc: {
    eng: 'You can create an artificial voice based on your own or order development from us',
    ru: 'Вы можете создать искусственный голос на основе своего или заказать разработку у нас',
  },
  reset: {
    eng: 'Reset',
    ru: 'Сбросить',
  },
  apply: {
    eng: 'Apply',
    ru: 'Применить',
  },
  allFilters: {
    eng: 'All filters',
    ru: 'Все фильтры',
  },
  filter: {
    eng: 'Filter',
    ru: 'Фильтр',
  },
  voicesNotFound: {
    eng: 'No voices found',
    ru: 'Голоса не найдены',
  },
  resetFilters: {
    eng: 'Reset all filters',
    ru: 'Сбросить все фильтры',
  },
  resetFiltersDesc: {
    eng: 'Try setting other filter options',
    ru: 'Попробуйте установить другие параметры фильтров',
  },
  oops: {
    eng: 'Oops',
    ru: 'Упс',
  },
  oopsTitle: {
    eng: 'Something went wrong',
    ru: 'Что-то пошло не так',
  },
  oopsDesc: {
    eng: 'Refresh the page or come back later',
    ru: 'Попробуйте обновить страницу или зайдите позже',
  },
  '404Title': {
    eng: 'Such page does not exist',
    ru: 'Такой страницы нет',
  },
  '404Desc': {
    eng: 'Perhaps this page was deleted or the link is invalid',
    ru: 'Возможно, эта страница удалена или в ссылке ошибка',
  },
  goMain: {
    eng: 'Go to main page',
    ru: 'Перейти на главную',
  },
  enterPhrase: {
    eng: 'Enter the text you want to voice',
    ru: 'Введите текст, который хотите озвучить',
  },
  selectSpeaker: {
    eng: 'Select voice',
    ru: 'Выберите голос',
  },
  buyPackage: {
    eng: 'Replenish by',
    ru: 'Пополнить на сумму',
  },
  serverRent: {
    eng: 'Dedicated server rental',
    ru: 'Аренда выделенного сервера',
  },
  serverRentInfo: {
    eng: 'For stable operation under high load conditions',
    ru: 'Для стабильной работы при высоких нагрузках',
  },
  requestAccess: {
    eng: 'Submit request',
    ru: 'Оставить заявку',
  },
  requestError: {
    eng: 'Error sending the request. Please try again.',
    ru: 'Ошибка при отправке заявки. Попробуйте еще раз.',
  },
  earnWithUs: {
    eng: 'Earn on your voice with us',
    ru: 'Зарабатывайте на своем голосе вместе с нами',
  },
  earnWithUsDesc: {
    eng: 'Join $[1] — we are happy to partner with live speakers and voice actors',
    ru: 'Присоединяйтесь к $[1] — мы рады сотрудничать с дикторами и актерами озвучки',
  },
  createVoiceVerb: {
    eng: 'Create a voice',
    ru: 'Создайте голос',
  },
  placeInCatalogVerb: {
    eng: 'Publish in the catalog',
    ru: 'Разместите в каталоге',
  },
  profit: {
    eng: 'Get up to 50% revenue for every minute when your voice is used',
    ru: 'Получайте прибыль до 50% с каждой минуты использования голоса',
  },
  wantUniqueVoice: {
    eng: 'I want a unique voice for my project',
    ru: 'Хочу уникальный голос для своего проекта',
  },
  wantUniqueVoiceDesc: {
    eng: 'It will be used only by you and will not be published in the catalog',
    ru: 'Его будете использовать только вы, без публикации в каталоге',
  },
  orderVoice: {
    eng: 'Order voice',
    ru: 'Заказать голос',
  },
  agreementPolicy1: {
    eng: 'By clicking the button, you accept',
    ru: 'Нажимая на кнопку, вы соглашаетесь с',
  },
  agreementPolicy2: {
    eng: 'the privacy policy',
    ru: 'политикой конфиденциальности',
  },
  requestSuccess: {
    eng: 'Request accepted!',
    ru: 'Заявка принята!',
  },
  reachOut: {
    eng: 'We will contact you within 24 hours',
    ru: 'Мы свяжемся с вами в течение 24 часов',
  },
  enterRequestMail: {
    eng: 'How can we contact you?',
    ru: 'Как с вами связаться?',
  },
  sendRequest: {
    eng: 'Send request',
    ru: 'Отправить заявку',
  },
  great: {
    eng: 'Great',
    ru: 'Отлично',
  },
  termsOfUse: {
    eng: 'Terms of use',
    ru: 'Пользовательское соглашение',
  },
  termsOfUseLink: {
    eng: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/terms-of-service/Aimyvoice.pdf',
    ru: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/terms-of-service/Aimyvoice.pdf',
  },
  termsOfUseLinkUSD: {
    eng: 'https://tovie.ai/eng/docs/voice.pdf',
    ru: 'https://tovie.ai/eng/docs/voice.pdf',
  },
  you: {
    eng: 'You',
    ru: 'Вы',
  },
  'auth.error': {
    eng: 'You do not have access to this page. Please, log in or sign up.',
    ru: 'У вас нет доступа к этой странице. Войдите или зарегистрируйтесь.',
  },
  'voice.not.found': {
    eng: 'Voice not found.',
    ru: 'Голос не найден.',
  },
  'voice.access.denied': {
    eng: 'You do not have access to this voice anymore.',
    ru: 'У вас больше нет доступа к этому голосу.',
  },
  'catalog.not.found': {
    eng: 'Such training material set does not exist, or it was deleted.',
    ru: 'Такого набора обучающих материалов не существует, или он был удален.',
  },
  'sample.not.found': {
    eng: 'Such training material does not exist, or it was deleted.',
    ru: 'Такого обучающего материала не существует, или он был удален.',
  },
  'account.not.found': {
    eng: 'No user with this email has been found.',
    ru: 'Пользователь с таким email не найден.',
  },
  'account.role.not.found': {
    eng: 'This user has already been removed.',
    ru: 'Пользователь уже был удален.',
  },
  ValidationError: {
    eng: 'Text contains invalid characters.',
    ru: 'Текст содержит недопустимые символы.',
  },
  'account.role.duplicated': {
    eng: 'This user already manages the voice.',
    ru: 'Этот пользователь уже управляет голосом.',
  },
  'too.many.requests': {
    eng: 'Speech synthesis requests exceeded. Please try again in an hour.',
    ru: 'Превышено максимальное количество запросов на синтез речи. Повторите попытку через час.',
  },
  'documentTitle.main': {
    eng: 'Voicing text and speech synthesis by different voices in Russian — $[1]',
    ru: 'Озвучка текста и синтез речи разными голосами на русском языке — $[1]',
  },
  'documentDescription.main': {
    eng:
      'The $[1] platform will help you convert text to speech (TTS) with different voices and use them in your projects.',
    ru:
      'Платформа $[1] поможет преобразовать текст в речь (TTS) разнообразными голосами и позволит использовать их в своих проектах.',
  },
  'documentTitle.catalog': {
    eng: 'Catalog of synthesized voices for any purpose — $[1]',
    ru: 'Каталог синтезированных голосов для любых задач — $[1]',
  },
  'documentDescription.catalog': {
    eng:
      'A wide catalog of various voices for speech synthesis. You can find female, male, child, and even famous character voices that you can use for bots and other content.',
    ru:
      'Большой каталог разнообразных голосов для синтеза речи. Есть женские, мужские, детские и даже голоса знаменитых персонажей, которые можно использовать для ботов и другого контента.',
  },
  'documentTitle.library': {
    eng: 'Earn with your voice in $[1]',
    ru: 'Зарабатывать своим голосом с $[1]',
  },
  'documentDescription.library': {
    eng: 'Create your voice in $[1], set its price, and get money when others use it.',
    ru: 'Создайте свой голос на платформе $[1], назначьте цену и получайте деньги за его использование.',
  },
  'documentTitle.account': {
    eng: 'Account — $[1]',
    ru: 'Аккаунт — $[1]',
  },
  'documentTitle.createVoice': {
    eng: 'Create voice — $[1]',
    ru: 'Создание голоса — $[1]',
  },
  'documentTitle.publicVoice': {
    eng: '$[1] — Voice from the $[2] catalog',
    ru: '$[1] — Голос из каталога $[2]',
  },
  'documentTitle.control': {
    eng: '$[1] — Management — $[2]',
    ru: '$[1] — Управление — $[2]',
  },
  'documentTitle.training': {
    eng: '$[1] — Training — $[2]',
    ru: '$[1] — Обучение — $[2]',
  },
  'documentTitle.newVersion': {
    eng: 'New version — $[1] — Training — $[2]',
    ru: 'Новая версия — $[1] — Обучение — $[2]',
  },
  'documentTitle.speakerPlace': {
    eng: '$[1] — $[2] — Version $[3] — Training — $[4]',
    ru: '$[1] — $[2] — Версия $[3] — Обучение — $[4]',
  },
  'documentTitle.published': {
    eng: '$[1] — Published versions — $[2]',
    ru: '$[1] — Опубликованные версии — $[2]',
  },
  'documentTitle.usage': {
    eng: '$[1] — Use — $[2]',
    ru: '$[1] — Использование — $[2]',
  },
  'zip.extension.wrong': {
    eng: 'Select a ZIP archive',
    ru: 'Выберите ZIP-файл',
  },
  'doc.connectToApi': {
    eng: 'How to connect a voice using the API?',
    ru: 'Как подключить голос по API?',
  },
  'doc.createVoice': {
    eng: 'How to create a voice',
    ru: 'Как создать голос',
  },
  'doc.useVoice': {
    eng: 'How to use a voice',
    ru: 'Как использовать голос',
  },
  'doc.payment': {
    eng: 'Payments',
    ru: 'Баланс и оплата',
  },
  'voice.empty.name': {
    eng: 'The name cannot consist of spaces only. Enter another name.',
    ru: 'Название не может состоять только из пробелов. Введите другое название.',
  },
  uploadDatasetHref: {
    eng: '/create-voice/upload-training-sets',
    ru: '/create-voice/upload-training-sets',
  },
  mobileNotificationTitle: {
    eng: 'Open the website on a computer',
    ru: 'Откройте сайт на компьютере',
  },
  mobileNotificationSubtitle: {
    eng: 'To move on to creating and training a voice',
    ru: 'Чтобы перейти к созданию и обучению голоса',
  },
  licensed: {
    eng: 'Licensed',
    ru: 'Лицензионный',
  },
  licensedInfo: {
    eng: 'price and access to synthesis — after agreement with the copyright holder',
    ru: 'цена и доступ к синтезу — после согласования с правообладателем',
  },
  licensedInfoModal: {
    eng:
      'The price and synthesis of this voice will become available after agreement with the copyright holder. Submit a request to use the voice.',
    ru:
      'Цена и синтез этого голоса станут доступны после согласования с правообладателем. Оставьте заявку на использование голоса.',
  },
  controlVoices: {
    eng: 'Managing voices',
    ru: 'Управляю голосами',
  },
  loadVoices: {
    eng: 'Show more $[1] of $[2]',
    ru: 'Показать еще $[1] из $[2]',
  },
  moreNoun: {
    eng: 'More',
    ru: 'Еще',
  },
  select: {
    eng: 'Select',
    ru: 'Выберите',
  },
  fromCatalog: {
    eng: 'From catalog',
    ru: 'Из каталога',
  },
  and: {
    eng: 'And',
    ru: 'И',
  },
  connect: {
    eng: 'Connect',
    ru: 'Подключить',
  },
  viaApi: {
    eng: 'using the API',
    ru: 'по API',
  },
  createVerb: {
    eng: 'Create',
    ru: 'Создайте',
  },
  yourUnique: {
    eng: 'Your unique',
    ru: 'Свой уникальный',
  },
  earn: {
    eng: 'Earn',
    ru: 'Зарабатывайте',
  },
  goToVoiceActing: {
    eng: 'Go to voicing',
    ru: 'Перейти к озвучиванию',
  },
  project: {
    eng: 'Project',
    ru: 'Проект',
  },
  whenChanged: {
    eng: 'Edited $[1]',
    ru: 'Изменен $[1]',
  },
  deleteProject: {
    eng: 'Delete project',
    ru: 'Удалить проект',
  },
  decreaseProjectName1: {
    eng: 'Shorten the name by $[1] character',
    ru: 'Сократите название на $[1] символ',
  },
  decreaseProjectName2: {
    eng: 'Shorten the name by $[1] characters',
    ru: 'Сократите название на $[1] символа',
  },
  decreaseProjectName5: {
    eng: 'Shorten the name by $[1] characters',
    ru: 'Сократите название на $[1] символов',
  },
  projectPlaceholderTitleLanding1: {
    eng: 'Create a project ',
    ru: 'Создайте проект ',
  },
  projectPlaceholderTitleLanding2: {
    eng: 'And voice the text',
    ru: 'И озвучивайте текст',
  },
  projectPlaceholderTitleLanding3: {
    eng: 'by $[1]',
    ru: 'голосами $[1]',
  },
  voiceText: {
    eng: 'Voice text',
    ru: 'Озвучить текст',
  },
  deleteProjectModal: {
    eng: 'You are about to delete the project',
    ru: 'Вы собираетесь удалить проект',
  },
  myProjects: {
    eng: 'My projects',
    ru: 'Мои проекты',
  },
  createProject: {
    eng: 'Create project',
    ru: 'Создать проект',
  },
  projectDeleted: {
    eng: 'Project deleted',
    ru: 'Проект удален',
  },
  'project.not.found': {
    eng: 'The project does not exist or you do not have access to it',
    ru: 'Проект не существует или у вас нет к нему доступа',
  },
  'project.name.blank': {
    eng: 'The project name cannot consist of spaces only. Enter another name.',
    ru: 'Название проекта не может состоять только из пробелов. Введите другое название.',
  },
  'replica.not.found': {
    eng: 'Phrase not found',
    ru: 'Реплика не найдена',
  },
  'replica.text.blank': {
    eng: 'Phrases cannot consist of spaces only',
    ru: 'Реплика не может состоять только из пробелов',
  },
  'replica.text.too.long': {
    eng: 'Phrases can contain up to 500 characters',
    ru: 'Реплика может содержать до 500 символов',
  },
  offlineModeInfo: {
    eng: 'Changes will be saved when the internet connection is restored',
    ru: 'Изменения будут сохранены, когда подключение к интернету восстановится',
  },
  projectDirty: {
    eng: 'There are unsaved changes',
    ru: 'Есть несохраненные изменения',
  },
  saving: {
    eng: 'Saving',
    ru: 'Сохранение',
  },
  textMarkup: {
    eng: 'Text markup',
    ru: 'Разметка текста',
  },
  pauses: {
    eng: 'Pauses',
    ru: 'Паузы',
  },
  wordStress: {
    eng: 'Word stress',
    ru: 'Ударения в словах',
  },
  phraseStress: {
    eng: 'Sentence stress',
    ru: 'Фразовые ударения',
  },
  loudnessPace: {
    eng: 'Volume and speech rate',
    ru: 'Громкость и темп',
  },
  voiceOverRules: {
    eng: 'Pronunciation rules',
    ru: 'Правила произношения',
  },
  voiceLineTooLong1: {
    eng: 'Shorten the phrase by $[1] character or split it into several phrases',
    ru: 'Сократите реплику на $[1] символ или разделите ее на несколько реплик',
  },
  voiceLineTooLong2: {
    eng: 'Shorten the phrase by $[1] characters or split it into several phrases',
    ru: 'Сократите реплику на $[1] символа или разделите ее на несколько реплик',
  },
  voiceLineTooLong5: {
    eng: 'Shorten the phrase by $[1] characters or split it into several phrases',
    ru: 'Сократите реплику на $[1] символов или разделите ее на несколько реплик',
  },
  voiceLine: {
    eng: 'Phrase',
    ru: 'Реплика',
  },
  voiceLineNotSaved: {
    eng: 'Error saving phrase',
    ru: 'Произошла ошибка, реплика не сохранена',
  },
  voiceLineNotRepositioned: {
    eng: 'Error moving phrase',
    ru: 'Произошла ошибка, реплика не перемещена',
  },
  voiceLineNotDeleted: {
    eng: 'Error deleting phrase',
    ru: 'Произошла ошибка, реплика не удалена',
  },
  voiceLineDeleted: {
    eng: 'Phrase deleted',
    ru: 'Реплика удалена',
  },
  'inference.access.denied': {
    eng: 'Speech synthesis is unavailable for this voice',
    ru: 'Синтез этого голоса недоступен',
  },
  selectVoice: {
    eng: 'Select voice',
    ru: 'Выбрать голос',
  },
  textMarkupShort: {
    eng: 'How to configure pauses, stress, volume, etc.',
    ru: 'Настройка пауз, ударений, громкости и др.',
  },
  leaveWarning: {
    eng: 'There are unsaved changes.\nIf you leave the page now, your changes will be lost.',
    ru: 'У вас есть несохраненные изменения.\nЕсли вы покинете страницу сейчас, изменения не будут сохранены.',
  },
  readMore: {
    eng: 'Read more',
    ru: 'Читать подробнее',
  },
  'createVoiceSteps.title': {
    eng: '4 steps for voice training',
    ru: '4 шага для обучения голоса',
  },
  'createVoiceSteps.subtitle': {
    eng: 'Keep creating new versions and follow these steps to improve your voice',
    ru: 'Продолжайте создавать новые версии и выполнять эти шаги, чтобы улучшать голос',
  },
  'createVoiceSteps.step1.title': {
    eng: 'Create a voice version',
    ru: 'Создайте версию голоса',
  },
  'createVoiceSteps.step1.subtitle': {
    eng: 'Each version consists of a set of training materials in the text and audio format',
    ru: 'Каждая версия состоит из набора обучающих материалов в текстовом и аудиоформате',
  },
  'createVoiceSteps.step2.title': {
    eng: 'Upload training materials',
    ru: 'Загрузите обучающие материалы',
  },
  'createVoiceSteps.step2.subtitle': {
    eng: 'You can upload ready-made sets, voice your text files or use the ready-made ones',
    ru: 'Вы можете загрузить готовые наборы, озвучить свои тексты или тексты от $[1]',
  },
  'createVoiceSteps.step3.title': {
    eng: 'Start training',
    ru: 'Запустите обучение',
  },
  'createVoiceSteps.step3.subtitle': {
    eng: 'Training is based on newly uploaded training materials and those from previous versions',
    ru: 'Обучение происходит на основе новых обучающих материалов и материалов из предыдущих версий',
  },
  'createVoiceSteps.step4.title': {
    eng: 'Publish version',
    ru: 'Опубликуйте версию',
  },
  'createVoiceSteps.step4.subtitle': {
    eng: 'When the training is completed, you can publish the version and it will become the current one',
    ru: 'Когда обучение будет завершено, вы сможете опубликовать версию и она станет текущей',
  },
  'managerRole: owner': {
    eng: 'Owner',
    ru: 'Владелец',
  },
  'newVersionTrainingSub:noTrain': {
    eng: 'Upload at least $[1] of training materials to start training the voice',
    ru: 'Чтобы начать обучение, загрузите не менее $[1] новых материалов',
  },
  'newVersionTrainingSub:canTrain': {
    eng: 'The required amount of data has been uploaded',
    ru: 'Загружено необходимое количество материалов',
  },
  removeManagerModal: {
    eng: 'You are about to remove $[1] from the list of managers',
    ru: 'Вы собираетесь исключить $[1] из списка менеджеров',
  },
  'publicPage:connectSub': {
    eng: 'to a side project',
    ru: 'к стороннему проекту',
  },
  'publicPage:project': {
    eng: 'Create a project in $[1]',
    ru: 'Создать проект в $[1]',
  },
  'publicPage:projectSub': {
    eng: 'and use this voice for your content',
    ru: 'и озвучивать тексты этим голосом',
  },
  'documentTitle.myPage': {
    eng: 'My page - $[1]',
    ru: 'Моя страница - $[1]',
  },
  'documentTitle.projectPage': {
    eng: '$[1] - Project - $[2]',
    ru: '$[1] - Проект - $[2]',
  },
  dollarsPerMinute: {
    eng: 'USD per minute',
    ru: 'долларов в минуту',
  },
  voicePricePerMinute: {
    eng: 'Voice cost per minute',
    ru: 'Стоимость голоса в минуту',
  },
  notEnoughMoney: {
    eng: 'Insufficient funds for synthesis',
    ru: 'Недостаточно средств для синтеза',
  },
  addFunds: {
    eng: 'Top up the account',
    ru: 'Пополнить счет',
  },
  language: {
    eng: 'Language',
    ru: 'Язык',
  },
  languageEnGB: {
    eng: 'English (UK)',
    ru: 'Английский (UK)',
  },
  languageEnUS: {
    eng: 'English (US)',
    ru: 'Английский (US)',
  },
  languageHiIN: {
    eng: 'Hindi',
    ru: 'Хинди',
  },
  languagePtPT: {
    eng: 'Portuguese',
    ru: 'Португальский',
  },
  languageRuRU: {
    eng: 'Russian',
    ru: 'Русский',
  },
  languageEsES: {
    eng: 'Spanish',
    ru: 'Испанский',
  },
  languagePtBR: {
    eng: 'Portuguese (BR)',
    ru: 'Португальский (BR)',
  },
  languageFrFR: {
    eng: 'French',
    ru: 'Французский',
  },
  languageItIT: {
    eng: 'Italian',
    ru: 'Итальянский',
  },
  languageDeDE: {
    eng: 'German',
    ru: 'Немецкий',
  },
  languageZhCN: {
    eng: 'Chinese (Simplified)',
    ru: 'Китайский (Упрощенный)',
  },
  chooseLanguage: {
    eng: 'Select voice language',
    ru: 'Выберите язык голоса',
  },
  'Projects:synthDocsLink': {
    eng: '/use-voice/ssml/',
    ru: '/use-voice/ssml/',
  },
  'ProjectsSidebar:Tab:voiceLine': {
    eng: 'Phrase',
    ru: 'Реплика',
  },
  'ProjectsSidebar:Tab:replicaHistory': {
    eng: 'History',
    ru: 'История',
  },

  'ProjectsSidebar:VoicelineSettings:tempo': {
    eng: 'Speech pace',
    ru: 'Темп',
  },
  'ProjectsSidebar:VoicelineSettings:volume': {
    eng: 'Volume',
    ru: 'Громкость',
  },
  'ProjectsSidebar:VoicelineSettings:style': {
    eng: 'Style',
    ru: 'Стиль',
  },
  'ProjectsSidebar:VoicelineSettings:pitch': {
    eng: 'Pitch',
    ru: 'Тон',
  },
  'ProjectsSidebar:VoicelineSettings:tempo:slow': {
    eng: 'Slow',
    ru: 'Медленный',
  },
  'ProjectsSidebar:VoicelineSettings:tempo:normal': {
    eng: 'Normal',
    ru: 'Нормальный',
  },
  'ProjectsSidebar:VoicelineSettings:tempo:fast': {
    eng: 'Fast',
    ru: 'Быстрый',
  },
  'ProjectsSidebar:VoicelineSettings:volume:quiet': {
    eng: 'Quiet',
    ru: 'Тихая',
  },
  'ProjectsSidebar:VoicelineSettings:volume:normal': {
    eng: 'Normal',
    ru: 'Нормальная',
  },
  'ProjectsSidebar:VoicelineSettings:volume:loud': {
    eng: 'Loud',
    ru: 'Громкая',
  },
  'ProjectsSidebar:VoicelineSettings:style:sad': {
    eng: 'Sad',
    ru: 'Грустный',
  },
  'ProjectsSidebar:VoicelineSettings:style:normal': {
    eng: 'Neutral',
    ru: 'Нейтральный',
  },
  'ProjectsSidebar:VoicelineSettings:style:happy': {
    eng: 'Cheerful',
    ru: 'Веселый',
  },
  'ProjectsSidebar:VoicelineSettings:style:angry': {
    eng: 'Angry',
    ru: 'Злой',
  },
  'ProjectsSidebar:VoicelineSettings:pitch:quiet': {
    eng: 'Quiet',
    ru: 'Тихий',
  },
  'ProjectsSidebar:VoicelineSettings:pitch:high': {
    eng: 'High',
    ru: 'Высокий',
  },
  'ProjectsSidebar:VoicelineSettings:pitch:low': {
    eng: 'Low',
    ru: 'Низкий',
  },
  'TrainingPage:SimulateTrain': {
    eng: 'Simulate training',
    ru: 'Имитировать обучие',
  },
  'TrainingPage:Connect:SimulatedTrain': {
    eng: 'Connect model',
    ru: 'Подключить модель',
  },
  'TrainingPage:Modal:ConnectModel:ModelPlaceholder': {
    eng: 'Enter model name',
    ru: 'Введите имя модели',
  },
  'TrainingPage:Modal:ConnectModel:ProviderPlaceholder': {
    eng: 'Enter provider name',
    ru: 'Введите имя провайдера',
  },
  'ProjectSidebar:Tab:history': {
    eng: 'Phrase history',
    ru: 'История реплики',
  },
  'SamplePage:Samples:SamplesWithError': {
    eng: '$[1] with an error',
    ru: '$[1] c ошибкой',
  },
  'ProjectPage:Presets:Button:ManagePreset': {
    eng: 'Manage',
    ru: 'Управлять',
  },
  'ProjectPage:Presets:Input:Placeholder': {
    eng: 'Select from the list',
    ru: 'Выберите из списка',
  },
  'ProjectPage:Presets:Title': {
    eng: 'Phrase presets',
    ru: 'Пресеты реплик',
  },
  'ProjectPage:Presets:ManageModal:Title': {
    eng: 'Managing presets',
    ru: 'Управление пресетами',
  },
  'ProjectPage:Presets:CreateModal:Title': {
    eng: 'Saving a preset',
    ru: 'Новый пресет',
  },
  'ProjectPage:Presets:NoPresets': {
    eng: 'No presets created',
    ru: 'Нет созданных пресетов',
  },
  'preset.not.found': {
    eng: 'Preset is not found',
    ru: 'Пресет не найден',
  },
  'ProjectPage:Presets:Info': {
    eng: 'Presets are a saved configuration of voice settings that can be applied to any phrase',
    ru: 'Пресеты — это сохраненная конфигурация настроек голоса, которую можно применить для любой реплики',
  },
  'ProjectPage:History:Play': {
    eng: 'Play',
    ru: 'Воспроизвести',
  },
  oneDay: {
    eng: 'day',
    ru: 'день',
  },
  fewDays: {
    eng: 'days',
    ru: 'дня',
  },
  manyDays: {
    eng: 'days',
    ru: 'дней',
  },
  otherDays: {
    eng: 'days',
    ru: 'дней',
  },

  'ProjectPage:History:DaysToDelete': {
    eng: '$[1] before removal',
    ru: '$[1] до удаления',
  },
  'ProjectPage:History:Synth': {
    eng: 'Voice',
    ru: 'Озвучить',
  },
  'ProjectPage:History:ExpandText': {
    eng: 'Reveal',
    ru: 'Раскрыть',
  },
  'ProjectPage:History:HideText': {
    eng: 'Hide',
    ru: 'Скрыть',
  },
  'ProjectPage:History:ActualVersion': {
    eng: 'Version in the editor',
    ru: 'Версия в редакторе',
  },
  'ProjectPage:History:ChooseVersion': {
    eng: 'Select version',
    ru: 'Выбрать версию',
  },
  'ProjectPage:History:ShowMore': {
    eng: 'Show more',
    ru: 'Показать ещё',
  },
  'ProjectPage:History:ModalTitle': {
    eng: 'History of synthesis',
    ru: 'История синтеза',
  },
  'ProjectPage:HistorySidebar:ActualVersion': {
    eng: 'Current version',
    ru: 'Текущая версия',
  },
  HelpCenter: {
    eng: 'https://help.aimyvoice.com/docs/ru',
    ru: 'https://help.aimyvoice.com/docs/ru',
  },
  HelpCenterUSD: {
    eng: 'https://help.voice.tovie.ai/#/docs/en',
    ru: 'https://help.voice.tovie.ai/#/docs/en',
  },
  synthError: {
    eng: 'An error occurred during synthesis',
    ru: 'Во время синтеза произошла ошибка',
  },
  'ProjectPage:Player:PlayTooltip': {
    eng: 'Listen to the phrase',
    ru: 'Прослушать реплику',
  },
  'ProjectPage:Player:DropdownButton:All': {
    eng: 'All phrases',
    ru: 'Все реплики',
  },
  'ProjectPage:Player:DropdownButton:Single': {
    eng: 'Single phrase',
    ru: 'Одна реплика',
  },
  'ProjectPage:Player:DropdownItem:All': {
    eng: 'Listen to all phrases',
    ru: 'Прослушать все реплики',
  },
  'ProjectPage:Player:DropdownItem:Single': {
    eng: 'Listen to a single phrase',
    ru: 'Прослушать одну реплику',
  },
  'ProjectPage:Player:Download:Zip': {
    eng: 'Download all phrases separately',
    ru: 'Скачать все реплики по отдельности',
  },
  'ProjectPage:Player:Download:SingleWav': {
    eng: 'Download all phrases in one audio file',
    ru: 'Скачать все реплики одним аудиофайлом',
  },
  'ProjectPage:Player:Download:Wav': {
    eng: 'Download the phrase',
    ru: 'Скачать реплику',
  },
  'text.empty': {
    eng: 'Synthesized text must not be empty',
    ru: 'Синтезируемый текст не должен быть пустым',
  },
  'project.download.empty.error': {
    eng: 'No phrases available for playback',
    ru: 'Нет реплик, доступных для воспроизведения',
  },
  'ProjectPage:Replica:Duration:Seconds': {
    eng: 'Audio duration: $[1] sec',
    ru: 'Длина аудио: $[1] сек',
  },
  'ProjectPage:Replica:Lenght:Error': {
    eng: 'The phrase contains more than 500 characters. To listen to it, delete or move the extra characters',
    ru: 'Реплика содержит больше 500 символов. Чтобы прослушать ее удалите или перенесите лишнее символы',
  },
  'ProjectPage:Replica:Lenght': {
    eng: 'Characters: $[1] out of $[2]',
    ru: 'Символов: $[1] из $[2]',
  },
  'AiWriter:ContentType:productexplainer': {
    eng: 'Product explainer',
    ru: 'Рассказ о продукте',
  },
  'AiWriter:ContentType:productadsvideo': {
    eng: 'Product ads video',
    ru: 'Рекламное видео продукта',
  },
  'AiWriter:ContentType:pitchdeck': {
    eng: 'Pitch deck',
    ru: 'Питч',
  },
  'AiWriter:ContentType:promo': {
    eng: 'Promo',
    ru: 'Промо',
  },
  'AiWriter:ContentType:salesdemo': {
    eng: 'Sales demo',
    ru: 'Демо продукта',
  },
  'AiWriter:ContentType:creativestory': {
    eng: 'Creative story',
    ru: 'Креативная история',
  },
  'AiWriter:ContentType:training': {
    eng: 'Training',
    ru: 'Обучение',
  },
  'AiWriter:ContentType:explainer': {
    eng: 'Explainer',
    ru: 'Объяснение',
  },
  'AiWriter:ContentType:conversation': {
    eng: 'Conversation',
    ru: 'Разговор',
  },
  'AiWriter:ContentType:paraphrase': {
    eng: 'Paraphrase',
    ru: 'Перефразирование',
  },
  'AiWriter:ContentTone:professional': {
    eng: 'Professional',
    ru: 'Профессиональный',
  },
  'AiWriter:ContentTone:casual': {
    eng: 'Casual',
    ru: 'Повседневный',
  },
  'AiWriter:ContentTone:friendly': {
    eng: 'Friendly',
    ru: 'Дружелюбный',
  },
  'AiWriter:ContentTone:storytelling': {
    eng: 'Storytelling',
    ru: 'Повествовательный',
  },
  'AiWriter:ContentTone:formal': {
    eng: 'Formal',
    ru: 'Официальный',
  },
  'AiWriter:ContentTone:informal': {
    eng: 'Informal',
    ru: 'Неформальный',
  },
  'AiWriter:Header:Title': {
    eng: 'AI Creator',
    ru: 'AI Creator',
  },
  'AiWriter:Request:ContentType': {
    eng: 'Type of content',
    ru: 'Тип контента',
  },
  'AiWriter:Request:BrandName': {
    eng: 'Brand or product name',
    ru: 'Название бренда или продукта',
  },
  'AiWriter:Request:Description': {
    eng: 'What do you want to tell people about?',
    ru: 'О чем вы хотите рассказать?',
  },
  'AiWriter:Request:ContentTone': {
    eng: 'Text tone',
    ru: 'Тон текста',
  },
  'AiWriter:Request:Variants': {
    eng: 'Number of options',
    ru: 'Количество вариантов',
  },
  'AiWriter:Request:VariantsMax': {
    eng: 'Maximum — $[1]',
    ru: 'Максимально — $[1]',
  },
  'AiWriter:Request:Submit': {
    eng: 'Generate',
    ru: 'Сгенерировать',
  },
  'AiWriter:Response:Variants': {
    eng: 'Options',
    ru: 'Варианты',
  },
  'AiWriter:Response:Placeholder:Step1': {
    eng: 'Fill out the fields in the left panel',
    ru: 'Заполните поля на левой панели',
  },
  'AiWriter:Response:Placeholder:Step2': {
    eng: 'Click “Generate” to get the list of phrases',
    ru: 'Нажмите «Сгенерировать», чтобы получить список реплик',
  },
  'AiWriter:Response:Item:CopyButton:Copy': {
    eng: 'Copy text',
    ru: 'Скопировать текст',
  },
  'AiWriter:Response:Item:CopyButton:Copied': {
    eng: 'Copied',
    ru: 'Скопировано',
  },
  'AiWriter:Response:Item:AddButton:Add': {
    eng: 'Add to the project',
    ru: 'Добавить в проект',
  },
  'AiWriter:Response:Item:AddButton:Added': {
    eng: 'Added',
    ru: 'Добавлено',
  },
  'AiWriter:Response:Item:Characters': {
    eng: 'Characters: $[1]',
    ru: 'Символов: $[1]',
  },
  'AiWriter:ModalCloseAlert': {
    eng: 'Do you really want to close the window? Unsaved data will be lost',
    ru: 'Вы действительно хотите закрыть окно? Несохраненные данные будут утеряны',
  },
  'AiWriter:Request:Characters': {
    eng: 'Characters in the option',
    ru: 'Символов в варианте',
  },
  'ProjectPage:Replicas:Header': {
    eng: 'List of phrases',
    ru: 'Список реплик',
  },
  'synthesis.max.text.length.exceeded': {
    eng:
      'Synthesized text is too long. Break up the text into several phrases if there are a lot of numerals, abbreviations, and dates.',
    ru:
      'Синтезируемый текст слишком длинный. Разбейте текст на несколько реплик, если в нем много числительных, сокращений и дат.',
  },
  'Header:UserMenu:Account': {
    eng: 'My account and balance',
    ru: 'Аккаунт и баланс',
  },
  'Header:Tabs:MyProjects': {
    eng: 'My projects',
    ru: 'Мои проекты',
  },
  'Modals:NoMoney:Title': {
    eng: 'Insufficient funds',
    ru: 'Недостаточно средств',
  },
  'Modals:NoMoney:Text': {
    eng: 'Your account has insufficient funds for synthesis',
    ru: 'На вашем счете недостаточно средств для синтеза.',
  },
  'Modal:NoMoney:SubmitButton': {
    eng: 'Top up your balance',
    ru: 'Пополнить баланс',
  },
  'Dictionary:Title': {
    eng: 'Dictionary',
    ru: 'Словарь',
  },
  'Dictionary:SubTitle': {
    eng:
      'Save the words that are pronounced differently than they are spelled, such as abbreviations, acronyms, or homographs in the dictionary. ',
    ru:
      'Сохраняйте в словаре слова, произношение которых отличается от написания, например, аббревиатуры или слова омографы.',
  },
  'Dictionary:Form:Word:Title': {
    eng: 'Word',
    ru: 'Слово',
  },
  'Dictionary:Form:Word:Placeholder': {
    eng: 'For example, BFF',
    ru: 'Например, замок',
  },
  'Dictionary:Form:Pronounce:Title': {
    eng: 'Pronounce as',
    ru: 'Произнести как',
  },
  'Dictionary:Form:Pronounce:Placeholder': {
    eng: 'Best friends forever',
    ru: 'за+мок',
  },
  'Dictionary:Search:Placeholder': {
    eng: 'Search by word or pronunciation',
    ru: 'Поиск в словаре по слову или произношению',
  },
  'Dictionary:Placeholder:Text': {
    eng: 'No words added',
    ru: 'Нет добавленных слов',
  },
  add: {
    eng: 'Add',
    ru: 'Добавить',
  },
  'phrase.is_not_unique': {
    eng: 'This word is already in the dictionary',
    ru: ' Такое слово уже есть в словаре',
  },
  'phrase.dictionary.not.found': {
    eng: 'The word was not found in the dictionary',
    ru: ' Слово не было найдено в словаре',
  },
  'AccountPage:BalanceHistory:PageTitle': {
    eng: 'Charge history',
    ru: 'История списаний',
  },
  amount: {
    eng: 'Amount',
    ru: 'Сумма',
  },
  'balanceHistory:Placeholder:Title': {
    eng: 'You haven’t started using voices yet',
    ru: 'Вы еще не начали использовать голоса',
  },
  'balanceHistory:Placeholde:Button': {
    eng: 'Voice text',
    ru: 'Озвучить текст',
  },
  'writer.generate.story.error': {
    eng: 'Unfortunately, an error occurred while generating the text. Please try again later.',
    ru: 'К сожалению, во время генерации текста произошла ошибка. Повторите попытку позже.',
  },
  '': {
    eng: '',
    ru: '',
  },
};
