import { create } from 'xmlbuilder2';

export const getTextLenght = (ssmlString: string) => {
  const newString = `<temp>${ssmlString}</temp>`;
  try {
    const doc = create(newString);
    return doc.root().node.textContent?.length || ssmlString.length;
  } catch (error) {
    return ssmlString.length;
  }
};
