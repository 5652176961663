import React from 'react';
import { Progress } from 'reactstrap';
import localization from '../../../../localization';
import { statusesInModelTableGenerator } from '../../constants';
import { ModelStatus } from '../../model/Voice';
import { Icon } from '@just-ai/just-ui';

export const ProgressBarWithStatusMemo = React.memo(function ProgressBarWithStatus({
  status,
}: {
  status: ModelStatus;
}) {
  const statusesInModelTable = statusesInModelTableGenerator(localization);
  return (
    <div
      className={`training__version-progressBar training__version-progressBar--${statusesInModelTable[status].color}`}
    >
      <div>
        <p data-test-id='trainingPage.versionCardStatus'>{statusesInModelTable[status].text}</p>
        {statusesInModelTable[status].name && (
          <Icon name={statusesInModelTable[status].name} color={statusesInModelTable[status].color} size='sm' />
        )}
      </div>
      <Progress color={statusesInModelTable[status].color} value={statusesInModelTable[status].value} />
    </div>
  );
});
