import React, { useCallback } from 'react';
import { Button } from '@just-ai/just-ui';
import { useController, useForm } from 'react-hook-form';
import cn from 'classnames';

import { useProjectContext } from '../../../Projects/context/ProjectsContext';
import {
  CreateDictionaryPhraseRequest,
  PhraseDictionaryView,
  UpdateDictionaryPhraseRequest,
} from '../../../../api/dubber/client';
import useDefaultAlert from '../../../../utils/useAlert';
import { WordForm } from './WordForm';
import { t } from '../../../../localization';

import styles from './Dictionary.module.scss';

export const MAX_DICTIONARY_LENGTH = 250;

export type Inputs = {
  word: string;
  pronounce: string;
};

type ChangeCreateFormProps = {
  getDictionarys: () => void;
  editMode?: boolean;
  dictionary?: PhraseDictionaryView;
  cancelButtonHandle?: () => void;
  handleDeleteButton?: () => void;
  synthReq: (text: string, id: string) => Promise<void>;
  getData: () => void;
  playingId: string;
};

export const ChangeCreateForm = ({
  getDictionarys,
  editMode,
  dictionary,
  cancelButtonHandle,
  handleDeleteButton,
  synthReq,
  playingId,
  getData,
}: ChangeCreateFormProps) => {
  const { createPhraseDictionary, updatePhraseDictionary } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();
  const { handleSubmit, control, reset } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      word: editMode ? dictionary?.original : '',
      pronounce: editMode ? dictionary?.replacement : '',
    },
  });

  const {
    field: word,
    meta: { invalid: wordError },
  } = useController({
    name: 'word',
    control,
    rules: {
      required: true,
      maxLength: MAX_DICTIONARY_LENGTH,
    },
  });
  const {
    field: pronounce,
    meta: { invalid: pronounceError },
  } = useController({
    name: 'pronounce',
    control,
    rules: {
      required: true,
      maxLength: MAX_DICTIONARY_LENGTH,
    },
  });
  const handleCreateDictionary = useCallback(
    async (data: Inputs) => {
      try {
        const createObj: CreateDictionaryPhraseRequest = {
          original: data.word,
          replacement: data.pronounce,
        };
        await createPhraseDictionary(createObj);
        reset();
        getDictionarys();
      } catch (error) {
        defaultErrorAlert(error);
      }
    },
    [createPhraseDictionary, defaultErrorAlert, getDictionarys, reset]
  );

  const handleUpdatePhrase = useCallback(async () => {
    if (!dictionary?.id) return;
    try {
      const updateObj: UpdateDictionaryPhraseRequest = {
        original: word.value,
        replacement: pronounce.value,
      };
      await updatePhraseDictionary(dictionary.id, updateObj);
      getData();
      cancelButtonHandle?.();
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [
    cancelButtonHandle,
    defaultErrorAlert,
    dictionary?.id,
    getData,
    pronounce.value,
    updatePhraseDictionary,
    word.value,
  ]);

  const isCreateButtonDisabled =
    wordError || pronounceError || word.value.trim().length === 0 || pronounce.value.trim().length === 0;

  const updateDisabled =
    (editMode && dictionary?.original === word.value && dictionary?.replacement === pronounce.value) ||
    isCreateButtonDisabled;

  return (
    <div className={cn(styles.dictionary__content, { edit: editMode })}>
      <div className={cn(editMode ? styles.dictionary__edit : styles.dictionary__add)}>
        <WordForm
          field={word}
          synthReq={synthReq}
          editMode={editMode}
          playingId={playingId}
          id={editMode ? `word-${dictionary?.id}` : 'create-word'}
        />
        <WordForm
          field={pronounce}
          synthReq={synthReq}
          editMode={editMode}
          playingId={playingId}
          id={editMode ? `pronounce-${dictionary?.id}` : 'create-pronounce'}
        />
        {editMode ? (
          <div className={styles.dictionary__edit__buttons}>
            <Button color='secondary' onClick={handleDeleteButton}>
              {t('delete')}
            </Button>
            <div>
              <Button color='secondary' onClick={cancelButtonHandle}>
                {t('cancel')}
              </Button>
              <Button color='primary' disabled={updateDisabled} onClick={handleUpdatePhrase}>
                {t('save')}
              </Button>
            </div>
          </div>
        ) : (
          <Button color='secondary' disabled={isCreateButtonDisabled} onClick={handleSubmit(handleCreateDictionary)}>
            {t('add')}
          </Button>
        )}
      </div>
    </div>
  );
};
