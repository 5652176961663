import React, { useCallback, useRef } from 'react';
import { Icon, Tooltip } from '@just-ai/just-ui';

import { PresetView } from '../../../../../../api/dubber/client';
import { useReplicas } from '../../../../context/ProjectDataContex';

export const PresetSelectItem = ({
  preset,
  onClick,
  style,
}: {
  preset: PresetView;
  style: React.CSSProperties;
  onClick: (markup: PresetView) => void;
}) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const { getSelectedReplica } = useReplicas();
  const selectedReplica = getSelectedReplica();
  const handleClick = useCallback(() => {
    onClick(preset);
  }, [preset, onClick]);

  const isOverflowing = ref.current && ref.current.clientWidth < ref.current.scrollWidth;
  return (
    <div className='preset-select__list-item' onClick={handleClick} style={style}>
      <div className='preset-select__list-icon'>
        {selectedReplica?.presetId === preset.id && <Icon name='farCheck' color='success' />}
      </div>
      <p className='tp-3' id={`preset-${preset.id}`} ref={ref}>
        {preset.name}
      </p>
      {isOverflowing && (
        <Tooltip placement='bottom' target={`preset-${preset.id}`} className='preset-select__tooltip' autohide={false}>
          {preset.name}
        </Tooltip>
      )}
    </div>
  );
};
