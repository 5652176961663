import React, { createContext, useContext, Component } from 'react';
import {
  SampleView,
  VoiceView,
  VoiceCreateOrUpdate,
  SampleUpdate,
  CatalogCreateOrUpdate,
  CatalogView,
  ModelView,
  VoiceMemberToAdd,
  VoiceMemberToRemove,
  VoiceMemberView,
  ModifyVoiceMember,
  NewVersionView,
  GenderEnum,
  PublishedVoiceViewWithUsedVersion,
  PublishedVoiceView,
  VoiceConsumerView,
  PublicModelView,
  VoicePriceCreate,
  LanguageEnum,
} from '../../../api/facade/client';
import { VoiceModel } from '../model/Voice';
import { SampleCreate } from '../../../api/dataset-loader/client';
import { LearningDataItem, SampleMetaData, Voice } from '../model/Voice';
import VoiceService from '../../../service/VoiceService';
import SampleService from '../../../service/SampleService';
import CatalogService from '../../../service/CatalogService';
import { AppContext } from '../../../AppContext';
import ModelService from '../../../service/ModelService';
import { LoadCallback } from '../../../utils/hooks';
import { VoiceUsageSummary } from '../../../api/analytics/client';

export type LibraryContextType = {
  voicesList: Voice[];
  allVoicesList: Voice[];
  isLoading: boolean;
  samplesToRecord: SampleView[];
  voicesCount: number;
  load: LoadCallback;
  createVoice: (voice: VoiceCreateOrUpdate) => Promise<any>;
  updateVoice: (voiceId: number, voiceData: VoiceCreateOrUpdate) => Promise<void>;
  deleteVoice: (voiceId: number) => Promise<void>;
  getVoice: (voiceId: number) => Promise<VoiceView>;
  getVoices: (
    page: number,
    pageSize: number
  ) => Promise<{
    voices: VoiceView[];
    count: number;
  }>;
  getPublicVoice: (voiceId: number) => Promise<PublishedVoiceViewWithUsedVersion | undefined>;
  publishVoice: (voiceId: number, text: string) => Promise<void>;
  unpublishVoice: (voiceId: number) => Promise<void>;
  uploadVoiceAvatar: (voiceId: number, image: File) => Promise<void>;
  getPublishedVoices: (
    page: number,
    pageSize: number,
    gender?: GenderEnum,
    sort?: 'NEWEST',
    tags?: string[],
    languages?: LanguageEnum[]
  ) => Promise<{ publishedVoices: PublishedVoiceView[]; count: number }>;
  createSample: (catalogId: number, sample: SampleCreate) => Promise<number | null>;
  updateSample: (sampleId: number, sample: SampleUpdate, catalogId?: number) => Promise<void>;
  getSample: (
    voiceId: number,
    sampleId: number,
    withMeta?: boolean
  ) => Promise<{ sampleData: SampleView; metaData: SampleMetaData } | undefined>;
  getSamples: (
    voiceId: number,
    page: number,
    pageOffset: number,
    catalogId: number,
    hasAudio?: boolean,
    hasError?: boolean
  ) => Promise<{ samplesWithStatuses: LearningDataItem[]; count: number; withAudioOffset: number }>;
  getSampleText: (sampleId: number, voiceId: number) => Promise<string>;
  updateSampleText: (sampleId: number, text: string, voiceId?: number) => Promise<void>;
  deleteSample: (sampleId: number) => Promise<void>;
  selectRecordSample: (sampleId: number, file: any) => Promise<void>;
  uploadFileToCatalog: (catalogId: number, file: any) => Promise<void>;
  uploadRecordToSample: (sampleId: number, file: any) => Promise<void>;
  deleteRecordFromSample: (sampleId: number) => Promise<void>;
  setCurrentVoiceId: (id: number) => void;
  getAllCatalogs: (isUsed?: boolean) => Promise<{ catalogsList: CatalogView[]; count: number } | undefined>;
  getCatalogData: (catalogId: number) => Promise<CatalogView | undefined>;
  createCatalog: (catalogData: CatalogCreateOrUpdate) => Promise<number | undefined>;
  removeCatalog: (catalogId: number) => Promise<void>;
  catalogsList: CatalogView[];
  trainVoice: (voiceId: number) => Promise<void>;
  getVoiceModels: (voiceId: number, isReleased?: boolean) => Promise<VoiceModel[]>;
  getPublicVoiceModels: (voiceId: number, isReleased?: boolean) => Promise<PublicModelView[]>;
  getVoiceModelData: (modelId: number) => Promise<ModelView>;
  releaseVoiceModel: (voiceId: number, modelId: number) => Promise<void>;
  getVoiceMembers: () => Promise<VoiceMemberView[]>;
  addVoiceMember: (member: VoiceMemberToAdd) => Promise<void>;
  deleteVoiceMember: (member: VoiceMemberToRemove) => Promise<void>;
  updateVoiceMember: (member: ModifyVoiceMember) => Promise<void>;
  getRoles: () => Promise<any>;
  getActualVersionData: () => Promise<NewVersionView | undefined>;
  versionReleasedRecently: boolean;
  getToken: (voiceId: number) => Promise<string>;
  createToken: (voiceId: number, modelId?: number) => Promise<string>;
  removeToken: (voiceId: number) => Promise<void>;
  updateToken: (voiceId: number, modelId: number) => Promise<void>;
  tokenConsumer: (voiceId: number) => Promise<VoiceConsumerView>;
  getVoiceUsage: (
    voiceId: number,
    page: number,
    pageSize: number
  ) => Promise<{ data: VoiceUsageSummary[]; count: number }>;
  getVoicePrice: (voiceId: number) => Promise<string>;
  setVoicePrice: (voiceId: number, price: VoicePriceCreate) => Promise<void>;
  updateCatalog: (catalogId: number, catalogData: CatalogCreateOrUpdate) => Promise<void>;
  simulateTraining: (voiceId: number) => void;
  connectSimulatedVersion: (voiceId: number, modelName: string, provider: string) => void;
};

export const LibraryContext = createContext({} as LibraryContextType);

class State {
  isLoading: boolean = false;
  voicesList: Voice[] = [];
  allVoicesList: Voice[] = [];
  catalogsList: CatalogView[] = [];
  error?: string;
  selectedVoiceId: number = 0;
  samplesToRecord: SampleView[] = [];
  voicesCount: number = 0;
  versionReleasedRecently: boolean = false;
}

type LibraryContextProps = {
  accountId?: number;
};

export class LibraryContextProvider extends Component<LibraryContextProps, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  state = new State();
  voiceService: VoiceService;
  catalogService?: CatalogService;
  sampleService: SampleService = new SampleService();
  modelService: ModelService = new ModelService();
  timeRef: React.MutableRefObject<NodeJS.Timeout | null>;

  constructor(props) {
    super(props);
    this.voiceService = new VoiceService(props.accountId);
    this.timeRef = React.createRef();
  }

  componentDidUpdate(prevProps: LibraryContextProps, prevState: State) {
    this.voiceService = new VoiceService(this.context.id);
    if (this.state.selectedVoiceId !== prevState.selectedVoiceId) {
      this.catalogService = new CatalogService(this.state.selectedVoiceId);
    }
    if (this.state.versionReleasedRecently === true) {
      this.timeRef.current = setTimeout(() => this.setState({ versionReleasedRecently: false }), 3000);
    }
  }

  componentWillUnmount() {
    if (this.timeRef.current) {
      clearTimeout(this.timeRef.current);
    }
  }

  getVoicesTotal = async (page: number, pageSize: number) => {
    try {
      const newAllVoices = await this.load(this.voiceService.getAllVoices(page, pageSize));
      this.setState({ voicesCount: Number(newAllVoices?.headers['content-range']) || 0 });
      return { voices: newAllVoices?.voices || [], count: parseInt(newAllVoices?.headers['content-range'] || '') || 0 };
    } catch (error) {
      console.error('error getting data in context', error);
      throw error;
    }
  };

  load: LoadCallback = promise => {
    this.setState({ isLoading: true });
    return promise.finally(() => this.setState({ isLoading: false }));
  };

  getVoice = async (id: number) => {
    const voice = await this.load(this.voiceService.getVoice(id));
    return voice;
  };

  getPublicVoice = async (voiceId: number) => {
    try {
      const publicVoice = await this.load(this.voiceService.getPublicVoice(voiceId));
      return publicVoice;
    } catch (error) {
      console.error('error getting data in context', error);
      throw error;
    }
  };

  setCurrentVoiceId = async (id: number) => {
    return await this.setState({ selectedVoiceId: id });
  };

  createVoice = async (voice: VoiceCreateOrUpdate) => {
    const createdVoice = await this.voiceService.createVoice(voice);
    return createdVoice;
  };

  updateVoice = async (voiceId: number, voiceData: VoiceCreateOrUpdate) => {
    await this.load(this.voiceService.updateVoice(voiceId, voiceData));
  };

  deleteVoice = async (voiceId: number) => {
    await this.load(this.voiceService.deleteVoice(voiceId));
  };

  publishVoice = async (voiceId: number, text: string) => {
    await this.load(this.voiceService.publishVoice(voiceId, text));
  };

  uploadVocieAvatar = async (voiceId: number, image: File) => {
    await this.load(this.voiceService.uploadVoiceAvatar(voiceId, image));
  };

  unpublishVoice = async (voiceId: number) => {
    await this.load(this.voiceService.unpublishVoice(voiceId));
  };

  getPublishedVoices = async (
    page: number,
    pageSize: number,
    gender?: GenderEnum,
    sort?: 'NEWEST',
    tags?: string[],
    languages?: LanguageEnum[]
  ) => {
    const data = await this.load(this.voiceService.getPublishedVoices(page, pageSize, gender, sort, tags, languages));
    return data;
  };

  createSample = async (catalogId: number, sample: SampleCreate) => {
    const sampleId = await this.load(this.sampleService.createSample(catalogId, this.state.selectedVoiceId, sample));
    return sampleId;
  };

  updateSample = async (sampleId: number, sample: SampleUpdate) => {
    await this.load(this.sampleService.updateSample(sampleId, this.state.selectedVoiceId, sample));
  };

  getSample = async (voiceId: number, sampleId: number) => {
    const dataset = await this.load(this.sampleService.getSample(sampleId, voiceId));
    return dataset;
  };

  getSamples = async (
    voiceId: number,
    page: number = 0,
    pageOffset: number = 25,
    catalogId: number,
    hasAudio?: boolean,
    hasError?: boolean
  ) => {
    const dataset = await this.load(
      this.sampleService.getSamplesForVoicePaginated(voiceId, page, pageOffset, catalogId, hasAudio, hasError)
    );
    return {
      samplesWithStatuses: dataset.samplesWithStatuses,
      count: parseInt(dataset.headers['content-range'] || '') || 0,
      withAudioOffset: parseInt(dataset.headers['content-location'] || '') || 0,
    };
  };

  getSampleText = async (sampleId: number, voiceId: number) => {
    const data = await this.load(this.sampleService.getSampleText(sampleId, voiceId));
    return data;
  };
  updateSampleText = async (sampleId: number, text: string, voiceId?: number) => {
    await this.load(this.sampleService.updateSampleText(sampleId, this.state.selectedVoiceId, text));
  };

  deleteSample = async (sampleId: number) => {
    await this.load(this.sampleService.deleteSample(sampleId, this.state.selectedVoiceId));
  };

  selectRecordSample = async (sampleId: number, file: any) => {
    await this.load(this.sampleService.sampleFileUpload(sampleId, this.state.selectedVoiceId, file));
  };

  uploadFileToCatalog = async (datasetId: number, file: any) => {
    await this.load(this.sampleService.sampleFileUpload(datasetId, this.state.selectedVoiceId, file));
  };

  uploadRecordToSample = async (sampleId: number, file: any) => {
    await this.load(this.sampleService.sampleRecordUpload(this.state.selectedVoiceId, sampleId, file));
  };

  deleteRecordFromSample = async (sampleId: number) => {
    await this.load(this.sampleService.deleteSampleRecord(sampleId, this.state.selectedVoiceId));
  };

  createCatalog = async (catalogData: CatalogCreateOrUpdate) => {
    if (!this.catalogService) return;
    const catalogId = await this.load(this.catalogService.createCatalog(catalogData));
    return catalogId;
  };

  getAllCatalogs = async (isUsed?: boolean) => {
    if (!this.catalogService) return;
    const catalogsList = await this.load(this.catalogService.getAllCatalogs(isUsed));
    return catalogsList;
  };

  getCatalogData = async (catalogId: number) => {
    if (!this.catalogService) return;
    const catalogData = await this.load(this.catalogService.getCatalog(catalogId));
    return catalogData;
  };

  removeCatalog = async (catalogId: number) => {
    if (!this.catalogService) return;
    await this.load(this.catalogService.deleteCatalog(catalogId));
  };

  trainVoice = async (voiceId: number) => {
    await this.load(this.modelService.trainVoiceModel(voiceId));
  };

  getVoiceModels = async (voiceId: number, isReleased?: boolean) => {
    const { modelsWithStatuses } = await this.load(this.modelService.getVoiceModels(voiceId, isReleased));
    return modelsWithStatuses;
  };

  getPublicVoiceModels = async (voiceId: number) => {
    const publicModels = await this.load(this.voiceService.getPublicVoiceModels(voiceId));
    return publicModels;
  };

  releaseVoiceModel = async (voiceId: number, modelId: number) => {
    await this.modelService.releaseVoiceModel(voiceId, modelId);
    this.setState({ versionReleasedRecently: true });
  };

  getVoiceModelData = async (modelId: number) => {
    const voiceModelData = await this.load(this.modelService.getModelData(this.state.selectedVoiceId, modelId));
    return voiceModelData;
  };

  getVoiceMembers = async () => {
    if (!this.state.selectedVoiceId) return [];
    const voiceMembers = await this.load(this.voiceService.getVoiceMembers(this.state.selectedVoiceId));
    return voiceMembers;
  };

  addVoiceMember = async (member: VoiceMemberToAdd) => {
    await this.voiceService.addVoiceMember(this.state.selectedVoiceId, member);
  };

  deleteVoiceMember = async (member: VoiceMemberToRemove) => {
    await this.voiceService.deleteVoiceMember(this.state.selectedVoiceId, member);
  };

  updateVoiceMember = async (member: ModifyVoiceMember) => {
    await this.load(this.voiceService.updateVoiceMember(this.state.selectedVoiceId, member));
  };

  getRoles = async () => {
    const { data } = await this.load(this.voiceService.getRoles());
    return data;
  };

  getActualVersionData = async () => {
    if (!this.state.selectedVoiceId) return;
    const actualVersionData = await this.load(this.voiceService.getActualVersionData(this.state.selectedVoiceId));
    return actualVersionData;
  };

  getToken = async (voiceId: number) => {
    const token = await this.load(this.voiceService.getToken(voiceId));
    return token;
  };

  createToken = async (voiceId: number, modelId?: number) => {
    const token = await this.load(this.voiceService.createToken(voiceId, modelId));
    return token;
  };

  removeToken = async (voiceId: number) => {
    await this.voiceService.removeToken(voiceId);
  };

  updateToken = async (voiceId: number, modelId: number) => {
    await this.voiceService.updateToken(modelId, voiceId);
  };

  tokenConsumer = async (voiceId: number) => {
    const consumer = await this.voiceService.tokenConsumer(voiceId);
    return consumer;
  };

  getVoiceUsage = async (voiceId: number, page: number, pageSize: number) => {
    const data = await this.load(this.voiceService.getVoiceUsage(voiceId, page, pageSize));
    return data;
  };

  getVoicePrice = async (voiceId: number) => {
    const data = await this.load(this.voiceService.getVoicePrice(voiceId));
    return data;
  };

  setVoicePrice = async (voiceId: number, price: VoicePriceCreate) => {
    this.voiceService.setVoicePrice(voiceId, price);
  };
  updateCatalog = async (catalogId: number, catalogData: CatalogCreateOrUpdate) => {
    await this.catalogService?.updateCatalog(catalogId, catalogData);
  };

  simulateTraining = (voiceId: number) => {
    this.modelService.simulateTraining(voiceId);
  };

  connectSimulatedVersion = (voiceId: number, modelName: string, provider: string) => {
    this.voiceService.connectSimulatedVersion(voiceId, modelName, provider);
  };

  render() {
    this.voiceService = new VoiceService(this.props.accountId!); // didUpdate выполняется снизу вверх, поэтому инициализируем сервис тут
    return (
      <LibraryContext.Provider
        value={{
          voicesList: this.state.voicesList,
          allVoicesList: this.state.allVoicesList,
          isLoading: this.state.isLoading,
          voicesCount: this.state.voicesCount,
          samplesToRecord: this.state.samplesToRecord,
          load: this.load,
          createVoice: this.createVoice,
          updateVoice: this.updateVoice,
          uploadVoiceAvatar: this.uploadVocieAvatar,
          deleteVoice: this.deleteVoice,
          getVoice: this.getVoice,
          getVoices: this.getVoicesTotal,
          getPublicVoice: this.getPublicVoice,
          getPublishedVoices: this.getPublishedVoices,
          createSample: this.createSample,
          updateSample: this.updateSample,
          getSample: this.getSample,
          getSamples: this.getSamples,
          getSampleText: this.getSampleText,
          updateSampleText: this.updateSampleText,
          deleteSample: this.deleteSample,
          selectRecordSample: this.selectRecordSample,
          uploadFileToCatalog: this.uploadFileToCatalog,
          setCurrentVoiceId: this.setCurrentVoiceId,
          createCatalog: this.createCatalog,
          catalogsList: this.state.catalogsList,
          removeCatalog: this.removeCatalog,
          getAllCatalogs: this.getAllCatalogs,
          getCatalogData: this.getCatalogData,
          trainVoice: this.trainVoice,
          getVoiceModels: this.getVoiceModels,
          getVoiceModelData: this.getVoiceModelData,
          releaseVoiceModel: this.releaseVoiceModel,
          uploadRecordToSample: this.uploadRecordToSample,
          deleteRecordFromSample: this.deleteRecordFromSample,
          addVoiceMember: this.addVoiceMember,
          deleteVoiceMember: this.deleteVoiceMember,
          getVoiceMembers: this.getVoiceMembers,
          updateVoiceMember: this.updateVoiceMember,
          getRoles: this.getRoles,
          getActualVersionData: this.getActualVersionData,
          publishVoice: this.publishVoice,
          unpublishVoice: this.unpublishVoice,
          versionReleasedRecently: this.state.versionReleasedRecently,
          getToken: this.getToken,
          createToken: this.createToken,
          removeToken: this.removeToken,
          updateToken: this.updateToken,
          tokenConsumer: this.tokenConsumer,
          getVoiceUsage: this.getVoiceUsage,
          updateCatalog: this.updateCatalog,
          getPublicVoiceModels: this.getPublicVoiceModels,
          getVoicePrice: this.getVoicePrice,
          setVoicePrice: this.setVoicePrice,
          simulateTraining: this.simulateTraining,
          connectSimulatedVersion: this.connectSimulatedVersion,
        }}
      >
        {this.props.children}
      </LibraryContext.Provider>
    );
  }
}

export const useLibraryContext = () => useContext(LibraryContext);
