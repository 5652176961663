import { useCallback, useEffect, useState } from 'react';
import { Icon, useToggle } from '@just-ai/just-ui';
import { AnimatePresence, motion } from 'framer-motion';
import { UncontrolledTooltip } from 'reactstrap';

import { useProjectContext } from '../../../context/ProjectsContext';
import { PresetSelect } from './PresetSelect/PresetSelect';
import { t } from '../../../../../localization';
import { PresetView } from '../../../../../api/dubber/client';
import useDefaultAlert from '../../../../../utils/useAlert';
import { useReplicas } from '../../../context/ProjectDataContex';
import { useSettings } from '../../../context/ReplicaSettingsContext';

import './Presets.scss';

export const Presets = () => {
  const [isPresetsOpen, , , togglePresets] = useToggle(false);
  const { settingLoading } = useSettings();
  const [selectedPreset, setSelectedPreset] = useState<PresetView>();
  const { getPresetById } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();
  const { getSelectedReplica } = useReplicas();

  const selectedReplica = getSelectedReplica();

  const getSelectedPreset = useCallback(async () => {
    if (selectedReplica?.presetId === selectedPreset?.id || !selectedReplica?.presetId) return;
    if (!selectedReplica?.presetId) {
      setSelectedPreset(undefined);
      return;
    }
    if (selectedReplica?.presetId) {
      try {
        const preset = await getPresetById(selectedReplica?.presetId);
        setSelectedPreset(preset);
        !isPresetsOpen && togglePresets();
      } catch (error) {
        defaultErrorAlert(error);
      }
    }
  }, [selectedReplica?.presetId, selectedPreset?.id, isPresetsOpen, togglePresets, getPresetById, defaultErrorAlert]);

  useEffect(() => {
    if (settingLoading) return;
    getSelectedPreset();
  }, [settingLoading, getSelectedPreset]);

  return (
    <div className=' projects-page__sidebar__body project-page__presets presets'>
      <div className='presets__header' onClick={togglePresets}>
        <div className='presets__header-title'>
          <p className='st-2'>{t('ProjectPage:Presets:Title')}</p>
          <Icon name='faInfoCircle' color='secondary' id='preset-info' />
          <UncontrolledTooltip target='preset-info' placement='top'>
            {t('ProjectPage:Presets:Info')}
          </UncontrolledTooltip>
        </div>
        <Icon name={isPresetsOpen ? 'falChevronUp' : 'falChevronDown'} size='sm' />
      </div>
      <AnimatePresence initial={false} exitBeforeEnter>
        {isPresetsOpen && (
          <motion.div
            initial={{ height: 0, marginTop: 0, opacity: 0 }}
            animate={{ height: 'auto', marginTop: '.5rem', opacity: 1 }}
            exit={{ height: 0, marginTop: 0, opacity: 0 }}
            className='presets__dropdown'
            transition={{ type: 'just' }}
          >
            <PresetSelect selectedPreset={selectedPreset} setSelectedPreset={setSelectedPreset} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
