import React, { useCallback, useRef, useState } from 'react';
import { Button, IconButton, InputText, JustSelect, Spinner, Textarea } from '@just-ai/just-ui';
import { StyleEnum, TypeEnum } from '../../../../api/dubber/client';
import { AnimatePresence, motion } from 'framer-motion';
import { useForm, useController } from 'react-hook-form';
import { CanceledError } from 'axios';
import cn from 'classnames';

import { t } from '../../../../localization';
import { languagesMap } from '../../../Library/constants';
import { useAppContext } from '../../../../AppContext';
import { SupportedVoiceLanguagesEnum } from '../../../../api/facade/client';
import { useProjectContext } from '../../context/ProjectsContext';
import useDefaultAlert from '../../../../utils/useAlert';
import { AiWriterItem } from './AiWriterItem';
import { AiWriterPlaceholder } from './AiWriterPlaceholder';
import { capitalize } from '../../../../utils';

import classes from './AiWriter.module.scss';

const DESCRIPTION_LEGHT = 500;
const VARIANTS_LENGHT = 3;
const VARIANTS_DEFAULT_VALUE = 1;
const CHARACTERS_LENGHT = 1000;
const CHARACTERS_DEFAULT_VALUE = 500;
const BRAND_LENGHT = 100;

const modalSlide = {
  hidden: {
    x: '100vw',
    width: 0,
    opacity: 0,
  },
  visible: {
    x: '0',
    width: '60%',
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    width: 0,
    opacity: 0,
    origin: 1,
  },
};

type Inputs = {
  contentType: keyof typeof TypeEnum;
  brandName: string;
  description: string;
  contentTone: keyof typeof StyleEnum;
  variants: number;
  characters: number;
  language: SupportedVoiceLanguagesEnum;
};

export const AiWriter = ({ toggle, isOpen }: { isOpen: boolean; toggle: () => void }) => {
  const { isUSDInstance, configData } = useAppContext();
  const { handleSubmit, control, reset } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      contentType: Object.keys(TypeEnum)[0] as Inputs['contentType'],
      brandName: '',
      description: '',
      contentTone: Object.keys(StyleEnum)[0] as Inputs['contentTone'],
      variants: VARIANTS_DEFAULT_VALUE,
      characters: CHARACTERS_DEFAULT_VALUE,
      language: isUSDInstance ? SupportedVoiceLanguagesEnum['EnUS'] : SupportedVoiceLanguagesEnum['RuRU'],
    },
  });
  const { generateStory } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();
  const [createdStories, setStories] = useState<string[]>([]);
  const [storiesLoading, setLoading] = useState(false);
  const cancelRef = useRef<AbortController>();

  const { field: contentType } = useController({
    name: 'contentType',
    control,
    rules: {
      required: true,
    },
  });

  const {
    field: brandField,
    meta: { invalid: brandInvalid },
  } = useController({
    name: 'brandName',
    control,
    rules: {
      maxLength: BRAND_LENGHT,
    },
  });

  const {
    field: description,
    meta: { invalid: descriptionInvalid },
  } = useController({
    name: 'description',
    control,
    rules: {
      required: true,
      maxLength: DESCRIPTION_LEGHT,
    },
  });

  const { field: contentTone } = useController({
    name: 'contentTone',
    control,
    rules: {
      required: true,
    },
  });

  const { field: language } = useController({
    name: 'language',
    control,
    rules: {
      required: true,
    },
  });

  const {
    field: variants,
    meta: { invalid: variantsInvalid },
  } = useController({
    name: 'variants',
    control,
    rules: {
      required: true,
      validate: {
        outOfRange: value => value > 0 && value <= VARIANTS_LENGHT,
      },
    },
  });

  const {
    field: characters,
    meta: { invalid: charactersInvalid },
  } = useController({
    name: 'characters',
    control,
    rules: {
      required: true,
      validate: {
        outOfRange: value => value > 0 && value <= CHARACTERS_LENGHT,
      },
    },
  });

  const contentTypeLabels = Object.keys(TypeEnum).map(type => {
    return {
      label: `${t(`AiWriter:ContentType:${type.toLowerCase()}`)}`,
      value: type,
    };
  });
  const contentToneLabels = Object.keys(StyleEnum).map(style => {
    return {
      label: `${t(`AiWriter:ContentTone:${style.toLowerCase()}`)}`,
      value: style,
    };
  });
  const languageLabels = configData.supportedVoiceLanguages.map(language => {
    return {
      label: t(languagesMap[language]),
      value: language,
    };
  });

  const onbSubmit = useCallback(
    async (data: Inputs) => {
      try {
        setLoading(true);
        cancelRef.current = new AbortController();
        const stories = await generateStory(
          {
            content: data.description,
            language: data.language,
            style: StyleEnum[data.contentTone],
            type: TypeEnum[data.contentType],
            brand: data.brandName,
            versionCount: data.variants,
            charactersCount: data.characters,
          },
          { signal: cancelRef.current.signal }
        );
        setStories(stories.response);
      } catch (error) {
        if (!(error instanceof CanceledError)) {
          defaultErrorAlert(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [defaultErrorAlert, generateStory]
  );

  const getContent = useCallback(() => {
    switch (true) {
      case storiesLoading:
        return <Spinner />;
      case createdStories.length > 0:
        return createdStories.map(story => <AiWriterItem story={story} />);
      default:
        return <AiWriterPlaceholder />;
    }
  }, [createdStories, storiesLoading]);

  const handleWriterClose = useCallback(() => {
    const result = window.confirm(t('AiWriter:ModalCloseAlert'));
    if (!result) return;
    setStories([]);
    toggle();
    reset();
    cancelRef.current && cancelRef.current.abort();
  }, [reset, toggle]);
  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <>
          <motion.div
            className={cn('project-page__ai-writer', classes.AiWriter)}
            variants={modalSlide}
            initial='hidden'
            animate='visible'
            exit='exit'
          >
            <div className={classes.AiWriter__header}>
              <h3>{t('AiWriter:Header:Title')}</h3>
              <IconButton name='falTimes' color='secondary' size='sm' onClick={handleWriterClose} />
            </div>
            <div className={classes.AiWriter__body}>
              <div className={classes.AiWriter__request}>
                <div className={classes['AiWriter__input-block']}>
                  <p>{t('AiWriter:Request:ContentType')}</p>
                  <JustSelect
                    dropdownIconName='falChevronDown'
                    listAutoPosition
                    position='fixed'
                    options={contentTypeLabels}
                    value={contentType.value}
                    onChange={value => contentType.onChange(Array.isArray(value) ? value[0] : value)}
                  />
                </div>
                <div className={classes['AiWriter__input-block']}>
                  <p>{t('AiWriter:Request:BrandName')}</p>
                  <InputText
                    size='sm'
                    name={brandField.name}
                    onChange={value => brandField.onChange(value)}
                    invalid={brandInvalid}
                  />
                  <div className={classes['AiWriter__input-smalls']}>
                    <small>{capitalize(t('optional'))}</small>
                    <small className={classes['AiWriter__input-count']}>{`${
                      brandField.value ? String(brandField.value).length : 0
                    } / ${BRAND_LENGHT}`}</small>
                  </div>
                </div>
                <div className={classes['AiWriter__input-block']}>
                  <p>{t('AiWriter:Request:Description')}</p>
                  <Textarea
                    name={description.name}
                    onChange={value => description.onChange(value)}
                    invalid={descriptionInvalid}
                  />
                  <small className={classes['AiWriter__input-count']}>{`${
                    description.value ? String(description.value).length : 0
                  } / ${DESCRIPTION_LEGHT}`}</small>
                </div>
                <div className={classes['AiWriter__input-block']}>
                  <p>{t('AiWriter:Request:Variants')}</p>
                  <InputText
                    name={variants.name}
                    onChange={value => variants.onChange(parseInt(value))}
                    invalid={variantsInvalid}
                    defaultValue={variants.value}
                    type='number'
                    size='sm'
                  />
                  <small>{t('AiWriter:Request:VariantsMax', VARIANTS_LENGHT)}</small>
                </div>
                <div className={classes['AiWriter__input-block']}>
                  <p>{t('AiWriter:Request:Characters')}</p>
                  <InputText
                    name={characters.name}
                    onChange={value => characters.onChange(parseInt(value))}
                    invalid={charactersInvalid}
                    type='number'
                    size='sm'
                    defaultValue={characters.value}
                  />
                  <small>{t('AiWriter:Request:VariantsMax', CHARACTERS_LENGHT)}</small>
                </div>
                <div className={classes['AiWriter__input-block']}>
                  <p>{t('AiWriter:Request:ContentTone')}</p>
                  <JustSelect
                    dropdownIconName='falChevronDown'
                    listAutoPosition
                    position='fixed'
                    options={contentToneLabels}
                    value={contentTone.value}
                    onChange={value => contentTone.onChange(Array.isArray(value) ? value[0] : value)}
                  />
                </div>
                <div className={classes['AiWriter__input-block']}>
                  <p>{t('language')}</p>
                  <JustSelect
                    dropdownIconName='falChevronDown'
                    listAutoPosition
                    position='fixed'
                    options={languageLabels}
                    value={language.value}
                    onChange={value => language.onChange(Array.isArray(value) ? value[0] : value)}
                  />
                </div>
                <Button onClick={handleSubmit(onbSubmit)} color='primary' disabled={storiesLoading}>
                  {t('AiWriter:Request:Submit')}
                </Button>
              </div>
              <div className={classes.AiWriter__response}>
                <div className={classes['AiWriter__response-header']}>
                  <p className='st-1'>{t('AiWriter:Response:Variants')}</p>
                </div>
                <div className={classes['AiWriter__response-wrapper']}>{getContent()}</div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className='modal-backdrop'
            style={{ zIndex: 10040 }}
            onClick={handleWriterClose}
            key='modal'
          />
        </>
      )}
    </AnimatePresence>
  );
};
