import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { history } from '../App';
import { matchPath } from 'react-router';
import { baseAuthPath } from '../modules/Library/constants';

const timezone = new Date().getTimezoneOffset();

export const validateStatus = status => {
  if (status === 401) {
    localStorage.removeItem('CURRENT_USER');
    window.location.href = `${baseAuthPath}${window.location.origin}${history.location.pathname.replace(/\/+$/, '')}`;
  }
  if (status === 403) {
    if (
      matchPath(history.location.pathname, { path: '/my', exact: true }) ||
      matchPath(history.location.pathname, { path: '/account', exact: true })
    ) {
      history.push('/catalog');
    }
    if (matchPath(history.location.pathname, { path: 'my/*' })) {
      history.push('/my');
    }
  }
  if (status >= 500) {
    history.push(history.location.pathname, { status: status });
  }
  return status >= 200 && status < 300;
};

let axiosWithSessionCheck = axios.create({
  headers: {
    Product: String(process.env),
    Timezone: timezone,
  },
  validateStatus: validateStatus,
});

//@ts-ignore
axiosWithSessionCheck._get = (url: string, options: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    axiosWithSessionCheck
      .get(url, options)
      .then(response => {
        response.config = { ...response.config, ...options };
        resolve(response);
      })
      .catch(reject);
  });
};
const postPutDeleteInterceptor = (method: 'post' | 'put' | 'patch' | 'delete') => (
  url: string,
  data: any,
  config: AxiosRequestConfig
) => {
  return new Promise((resolve, reject) => {
    //@ts-ignore
    axiosWithSessionCheck[method](url, data, config)
      .then((response: AxiosResponse) => {
        response.config = { ...response.config, ...config };
        resolve(response);
      })
      .catch(reject);
  });
};
//@ts-ignore
axiosWithSessionCheck._post = postPutDeleteInterceptor('post');
//@ts-ignore
axiosWithSessionCheck._put = postPutDeleteInterceptor('put');
//@ts-ignore
axiosWithSessionCheck._delete = postPutDeleteInterceptor('delete');

export function setAxiosHeadersLanguage(val: string) {
  axiosWithSessionCheck.defaults.headers.language = val;
}

export { axiosWithSessionCheck as axiosWrapped };
