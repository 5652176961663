//@ts-nocheck
import { ModelApi } from '../api/facade/client';
import { InferenceControllerApi, SynthesizeTextRequest } from '../api/supervisor/client';
import { ReplicaSynthesisControllerApi, ReplicasAudioControllerApi } from '../api/dubber/client';
import { axiosWrapped, validateStatus } from '../pipes/functions';
import { convertModelStatus } from '../utils';

export default class ModelService {
  static readonly BASE_PATH = '';
  private ModelApi: ModelApi;
  private InferenceControllerApi: InferenceControllerApi;
  private ReplicaSynthesisControllerApi: ReplicaSynthesisControllerApi;
  private ReplicasAudioControllerApi: ReplicasAudioControllerApi;

  constructor() {
    this.ModelApi = new ModelApi({}, ModelService.BASE_PATH, axiosWrapped);
    this.InferenceControllerApi = new InferenceControllerApi({}, ModelService.BASE_PATH, axiosWrapped);
    this.ReplicaSynthesisControllerApi = new ReplicaSynthesisControllerApi({}, ModelService.BASE_PATH, axiosWrapped);
    this.ReplicasAudioControllerApi = new ReplicasAudioControllerApi({}, ModelService.BASE_PATH, axiosWrapped);
  }

  trainVoiceModel = async (voiceId: number) => {
    try {
      await this.ModelApi.trainModel(voiceId);
      return true;
    } catch (error) {
      console.log('Error training voice model' + voiceId, error);
      throw error;
    }
  };
  getVoiceModels = async (voiceId: number, isReleased?: boolean, page?: number, pageSize?: number) => {
    try {
      const { data: modelsList } = await this.ModelApi.getModels(voiceId, page, pageSize, isReleased);
      const modelsWithStatuses = modelsList.map(model => ({
        ...model,
        status: convertModelStatus({
          phase: model.phase,
          status: model.phaseStatus,
        }),
      }));

      return { modelsWithStatuses };
    } catch (error) {
      console.log('Error getting voice models' + voiceId, error);
      throw error;
    }
  };
  getModelData = async (voiceId: number, modelId: number) => {
    const { data: modelData } = await this.ModelApi.getModel(modelId, voiceId);
    return modelData;
  };

  releaseVoiceModel = async (voiceId: number, modelId: number) => {
    try {
      await this.ModelApi.releaseVersion(voiceId, modelId);
    } catch (error) {
      console.log('Error while publish voice model' + modelId, error);
      throw error;
    }
  };

  synthesize = async (voiceId: number, modelId: number, text: string) => {
    try {
      const { data: wavFile } = await this.InferenceControllerApi.uiInference(voiceId, modelId, text, {
        responseType: 'blob',
        validateStatus: status => {
          if (status === 403) {
            return false;
          }
          return validateStatus(status);
        },
      });
      return window.URL.createObjectURL(wavFile);
    } catch (error) {
      const parsedError = {
        ...error,
        response: { ...error.response, data: JSON.parse(await error.response.data.text()) },
      };
      throw parsedError;
    }
  };

  synthesizeFromHistory = async (historyId: string) => {
    try {
      const { data: wavFile } = await this.ReplicaSynthesisControllerApi.replicaSynthesisFromHistory(historyId, {
        responseType: 'blob',
        validateStatus: status => {
          if (status === 403) {
            return false;
          }
          return validateStatus(status);
        },
      });
      return window.URL.createObjectURL(wavFile);
    } catch (error) {
      const parsedError = {
        ...error,
        response: { ...error.response, data: JSON.parse(await error.response.data.text()) },
      };
      throw parsedError;
    }
  };
  synthesizeVoicePhrase = async (replicaId: string) => {
    try {
      const { data } = await this.ReplicaSynthesisControllerApi.replicaSynthesis(replicaId);
      return data.audioUrl;
    } catch (error) {
      const parsedError = {
        ...error,
        response: { ...error.response, data: error.response.data },
      };
      throw parsedError;
    }
  };

  downloadReplicaAudio = async (replicaId: string, fileName: string) => {
    try {
      const { data } = await this.ReplicasAudioControllerApi.downloadReplicaAudio(replicaId, fileName);
      return data.audioUrl;
    } catch (error) {
      throw error;
    }
  };
  downloadHistoryAudio = async (historyId: string, fileName: string) => {
    try {
      const { data } = await this.ReplicasAudioControllerApi.downloadHistoryAudio(historyId, fileName);
      return data.audioUrl;
    } catch (error) {
      throw error;
    }
  };
  synthesizeFromHistory = async (historyId: string) => {
    try {
      const { data } = await this.ReplicaSynthesisControllerApi.replicaSynthesisFromHistory(historyId);
      return data.audioUrl;
    } catch (error) {
      const parsedError = {
        ...error,
        response: { ...error.response, data: error.response.data },
      };
      throw parsedError;
    }
  };

  synthesizeDialog = async (speakersArr: Array<SynthesizeTextRequest>, captchaResponse?: string) => {
    try {
      const { data: wavFile } = await this.InferenceControllerApi.uiDialogInference(
        { synthesizeTextRequests: speakersArr },
        captchaResponse,
        {
          responseType: 'blob',
        }
      );
      return window.URL.createObjectURL(wavFile);
    } catch (error) {
      const parsedError = {
        ...error,
        response: { ...error.response, data: JSON.parse(await error.response.data.text()) },
      };
      throw parsedError;
    }
  };

  simulateTraining = (voiceId: number) => {
    this.ModelApi.simulateModelTraining(voiceId);
  };
}
