import React from 'react';
import './Loader.scss';

export const CatalogLoader = () => {
  return (
    <div className='loader'>
      <div className='catalog-page__catalog'>
        {new Array(12).fill('-').map((item, index) => (
          <div key={index} className='catalog-page__catalog-row animated-pulse' />
        ))}
      </div>
    </div>
  );
};
