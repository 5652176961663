import React, { useState, useCallback, useEffect, useRef } from 'react';
import { DropdownMenu, Dropdown, DropdownToggle, DropdownItem, Icon } from '@just-ai/just-ui';
import { formatDistanceToNowStrict } from 'date-fns';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';

import { useToggle } from '../../../../../utils/hooks';
import { t } from '../../../../../localization';
import { ProjectView } from '../../../../../api/dubber/client';
import { ProjectInputs } from '../ProjectInputs';
import { ProjectDelete } from '../ProjectDelete';
import { ProjectVoices } from '../ProjectVoices/ProjectVoices';
import { SCREEN_WIDTH_TABLET } from '../../../../Header/constants';
import { useAppContext } from '../../../../../AppContext';

import './ProjectCard.scss';

export const ProjectCard = ({ project, getData }: { project: ProjectView; getData: () => void }) => {
  const { getUserLanguage } = useAppContext();
  const [isModalOpen, , , toggleModal] = useToggle();
  const [isDropdownOpen, , , dropdownToggle] = useToggle();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const history = useHistory();
  const tabletDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  const blockHistory = useRef<any>();

  const handleTitleToggle = useCallback(() => {
    if (isEditOpen && blockHistory.current) blockHistory.current();
    setIsEditOpen(prevState => !prevState);
  }, [isEditOpen]);

  const handleNavigate = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      history.push(`/my/project/${project.id}`);
    },
    [history, project.id]
  );

  useEffect(() => {
    if (isEditOpen) {
      blockHistory.current = history.block(() => {
        return false;
      });
    }
  }, [isEditOpen, history]);

  return (
    <div className='project-link' data-test-id='project.projectCard' onClick={handleNavigate}>
      <div className={`my-page__project-card project-card ${isEditOpen && 'editing'}`}>
        <div className='project-card__title'>
          {isEditOpen ? (
            <ProjectInputs project={project} type='card' toggleEditing={handleTitleToggle} getData={getData} />
          ) : (
            <>
              <h4 data-test-id='project.projectName'>{project.name}</h4>
              {!tabletDown && (
                <Dropdown
                  direction='down'
                  isOpen={isDropdownOpen}
                  toggle={dropdownToggle}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <DropdownToggle compact data-test-id='project.dropdown'>
                    <Icon name='farEllipsisH' />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={handleTitleToggle} data-test-id='project.renameProject'>
                      {t('rename')}
                    </DropdownItem>
                    <DropdownItem onClick={toggleModal} data-test-id='project.deleteProject'>
                      {t('delete')}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </>
          )}
        </div>
        <p className='project-card__changed tp-4'>
          {t(
            'whenChanged',
            formatDistanceToNowStrict(new Date(project.lastUsedAt), {
              locale: getUserLanguage() === 'ru' ? ru : en,
              addSuffix: true,
            })
          )}
        </p>
        <div className='project-card__voices'>
          <ProjectVoices voicesId={project.voices} />
        </div>
      </div>
      <ProjectDelete project={project} isModalOpen={isModalOpen} toggleModal={toggleModal} getData={getData} card />
    </div>
  );
};
