import React from 'react';
import { throttle } from 'lodash';
import './Inputs.scss';

type InputProps = {
  defaultValue: string;
  save: (value: string) => void;
  hasError?: boolean;
  setError?: (value: boolean) => void;
  setEditing?: (value: boolean) => void;
};

type State = {
  value: string;
  showError: boolean;
  textFieldActive: boolean;
};

class InputWrapper extends React.Component<InputProps, State> {
  constructor(props: InputProps) {
    super(props);
    this.state = { value: this.props.defaultValue, showError: false, textFieldActive: false };
  }

  componentDidMount() {
    this.setState({ value: this.props.defaultValue });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }
  throttledAction = throttle(
    (type: 'save' | 'reset') => {
      if (type === 'reset') {
        this.reset();
      } else {
        this.props.save(this.state.value.trim());
      }
      this.props.setEditing && this.props.setEditing(false);
      this.setState({ textFieldActive: false });
    },
    200,
    { trailing: false }
  );

  reset = () => {
    this.setState({ value: this.props.defaultValue });
  };

  selectText = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.state.showError && event.target.value.length > 0) {
      this.setState({ showError: false });
    }
    this.setState({ value: event.target.value });
  };
}

export default InputWrapper;
