import React from 'react';
import { Button } from '@just-ai/just-ui';
import { t } from '../../../../localization';

export const Placeholder = ({ resetFilter }: { resetFilter: () => void }) => {
  return (
    <div className='placeholder'>
      <h3>{t('voicesNotFound')}</h3>
      <p>{t('resetFiltersDesc')}</p>
      <Button color='primary' outline onClick={resetFilter}>
        {t('resetFilters')}
      </Button>
    </div>
  );
};
