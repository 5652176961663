import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import createFastContext from '../../../utils/createFastContext';
import { ExtendedVoiceLine } from '../components/VoiceLinesBlock/VoiceLinesBlock';
import { useProjectContext } from './ProjectsContext';
import { MAX_VOICE_LINES_PER_PAGE, MAX_VOICE_LINE_LENGTH } from '../constants';
import useDefaultAlert from '../../../utils/useAlert';
import { t } from '../../../localization';
import { getTextLenght } from '../components/Tiptap/HtmlService';

const PLACEHOLDER_ID = 'placeholder';

export type ReplicasStore = {
  replicas: ExtendedVoiceLine[];
  currentPage: number;
  maxLinesReached: boolean;
  loading: boolean;
  linesToDelete: ExtendedVoiceLine['id'][];
  selectedReplicaId: string;
  placeholder: ExtendedVoiceLine;
  isDirty: boolean;
  updateHistory: boolean;
  initialSsmlCheck: number;
};

export const replicasInitialValues: ReplicasStore = {
  replicas: [],
  currentPage: 0,
  maxLinesReached: false,
  loading: false,
  linesToDelete: [],
  selectedReplicaId: '',
  placeholder: { id: PLACEHOLDER_ID, isDeleting: false } as ExtendedVoiceLine,
  isDirty: false,
  updateHistory: false,
  initialSsmlCheck: 0,
};

const { Provider, useStore } = createFastContext(replicasInitialValues);

export const useReplicaStore = () => useStore(store => store);

export const ReplicasProvider = ({ children }: { children: React.ReactNode }) => {
  return <Provider>{children}</Provider>;
};

export const useReplicas = () => {
  const [
    {
      replicas,
      selectedReplicaId,
      loading,
      currentPage,
      linesToDelete,
      maxLinesReached,
      placeholder,
      isDirty,
      updateHistory,
      initialSsmlCheck,
    },
    setStore,
  ] = useStore(store => store);
  const { getVoiceLines, getVoiceLine } = useProjectContext();
  const { projectId } = useParams<{ projectId: string }>();
  const { defaultErrorAlert } = useDefaultAlert();

  const setReplicasLoading = useCallback(
    (value: boolean) => {
      setStore({ loading: value });
    },
    [setStore]
  );

  const setSelectedReplica = useCallback(
    (replicaId: string) => {
      setStore({ selectedReplicaId: replicaId });
    },
    [setStore]
  );

  const fetchInitialReplicas = useCallback(async () => {
    try {
      setReplicasLoading(true);
      const rawVoiceLines = await getVoiceLines({ projectId, page: 0, pageSize: MAX_VOICE_LINES_PER_PAGE });
      setStore({
        replicas: rawVoiceLines.replicas,
        loading: false,
        maxLinesReached: !rawVoiceLines.replicas.length || rawVoiceLines.replicas.length < MAX_VOICE_LINES_PER_PAGE,
        initialSsmlCheck: rawVoiceLines.totalElements,
      });
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [defaultErrorAlert, getVoiceLines, projectId, setReplicasLoading, setStore]);

  const fetchReplicas = useCallback(
    async (page?: number, refresh?: boolean, add?: boolean) => {
      try {
        setReplicasLoading(true);
        const rawVoiceLines = await getVoiceLines({
          projectId: projectId,
          page: refresh ? replicasInitialValues.currentPage : page,
          pageSize: refresh ? MAX_VOICE_LINES_PER_PAGE * (currentPage + 1) : MAX_VOICE_LINES_PER_PAGE,
        });
        setStore({
          replicas: add ? [...replicas, ...rawVoiceLines.replicas] : rawVoiceLines.replicas,
          loading: false,
          currentPage: currentPage + 1,
          maxLinesReached:
            !Boolean(rawVoiceLines.replicas.length) || rawVoiceLines.replicas.length < MAX_VOICE_LINES_PER_PAGE,
        });
      } catch (error) {
        defaultErrorAlert(error);
      }
    },
    [setStore, defaultErrorAlert, getVoiceLines, projectId, setReplicasLoading, currentPage, replicas]
  );

  const loadMoreReplicas = useCallback(() => {
    if (loading || maxLinesReached) return;

    fetchReplicas(currentPage + 1, false, true);
  }, [fetchReplicas, loading, maxLinesReached, currentPage]);

  const getSelectedReplica = useCallback(() => {
    return replicas.find(replica => replica.id === selectedReplicaId);
  }, [replicas, selectedReplicaId]);

  const getReplicaById = useCallback(
    (replicaId: string) => {
      const newReplicas = [...replicas];
      const replicaIndex = newReplicas.findIndex(replica => replica.id === replicaId);
      const replica = newReplicas[replicaIndex];
      return { replica, replicaIndex };
    },
    [replicas]
  );

  const setPresetToSelectedLine = useCallback(
    (presetId: string) => {
      const { replica, replicaIndex } = getReplicaById(selectedReplicaId);
      if (!replica || replicaIndex === -1) return;
      const newReplicasArr = [...replicas];
      newReplicasArr[replicaIndex] = { ...replica, presetId };
      setStore({ replicas: newReplicasArr });
    },
    [setStore, getReplicaById, replicas, selectedReplicaId]
  );

  const getVoiceLineLocalizationString = useCallback((amount: number) => {
    return ([
      t('voiceLineTooLong1', amount),
      t('voiceLineTooLong2', amount),
      t('voiceLineTooLong5', amount),
    ] as any).decline(amount);
  }, []);

  const checkTextLength = useCallback(
    (value: string) => {
      if (getTextLenght(value) - MAX_VOICE_LINE_LENGTH > 0) {
        return getVoiceLineLocalizationString(value.length - MAX_VOICE_LINE_LENGTH);
      }
      return '';
    },
    [getVoiceLineLocalizationString]
  );

  const getCurrentReplicas = useCallback(() => {
    return [...replicas];
  }, [replicas]);

  const updateReplica = useCallback(
    async (replicaId: string) => {
      const newReplicas = [...replicas];
      const { replicaIndex } = getReplicaById(replicaId);

      try {
        const updatedReplica = await getVoiceLine(replicaId);
        newReplicas[replicaIndex] = updatedReplica;
        setStore({ replicas: newReplicas });
      } catch (error) {
        defaultErrorAlert(error);
      }
    },
    [defaultErrorAlert, getReplicaById, getVoiceLine, replicas, setStore]
  );

  return {
    setReplicasLoading,
    fetchReplicas,
    loadMoreReplicas,
    setSelectedReplica,
    setReplicaStore: setStore,
    setPresetToSelectedLine,
    getReplicaById,
    getSelectedReplica,
    replicas,
    selectedReplicaId,
    loading,
    maxLinesReached,
    placeholder,
    linesToDelete,
    currentPage,
    getCurrentReplicas,
    fetchInitialReplicas,
    checkTextLength,
    isDirty,
    updateHistory,
    initialSsmlCheck,
    updateReplica,
  };
};
