import React from 'react';
import { motion, Variants } from 'framer-motion';

type SliderItemTypes = {
  imageSrc: string;
  key: number;
  direction: number;
  variants: Variants;
};

export const SliderItem = ({ imageSrc, key, direction, variants }: SliderItemTypes) => {
  return (
    <div className='landing-page__slider-item'>
      <motion.img
        key={key}
        src={imageSrc}
        custom={direction}
        variants={variants}
        initial='enter'
        animate='center'
        exit='exit'
        transition={{
          x: { type: 'spring', stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
      />
    </div>
  );
};
