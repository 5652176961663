import React, { useState, useEffect, useCallback, useRef } from 'react';
import { InputText, Textarea, Button, Icon } from '@just-ai/just-ui';
import { GenderEnum } from '../../../../../api/facade/client';
import { useLibraryContext } from '../../../context/LibraryContext';
import { t } from '../../../../../localization';
import { Voice } from '../../../model/Voice';
import { useAmplitude } from 'react-amplitude-hooks';
import Banner from '../Banner/Banner';
import useDefaultAlert from '../../../../../utils/useAlert';
import { MembersMemo } from '../Members/Members';
import { RouteProps, useRouteMatch } from 'react-router';
import { AvatarModal } from '../../AvatarModal';
import { VoiceAvatar } from '../../VoiceAvatar';
import { DynamicTitle } from '../../DynamicTitle/DynamicTitle';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useAvatarContext } from '../../../context/AvatarContext';
import { SCREEN_WIDTH_TABLET } from '../../../../Header/constants';
import { useAppContext } from '../../../../../AppContext';
import './styles.scss';
import { languagesMap, MAX_VOICE_DESCRIPTION_LEGHT } from '../../../constants';

export type ChangeProfile = {
  name: string;
  description: string;
  gender: GenderEnum;
};

type ControlProps = {
  voice: Voice;
  id: string;
  fetchVoice: () => Promise<void>;
} & RouteProps;

export default function Control({ voice, id, fetchVoice }: ControlProps) {
  const [changeProfile, setChangeProfile] = useState({} as ChangeProfile);
  const { updateVoice } = useLibraryContext();
  const { id: accountId, productName } = useAppContext();
  const [avatarModal, setAvatarModal] = useState(false);
  const { logEvent } = useAmplitude();
  const { defaultErrorAlert, successAlert } = useDefaultAlert();
  const { handleTabletFileChoice } = useAvatarContext();
  const isTablet = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  const inputRef = useRef<HTMLInputElement>(null);
  const match = useRouteMatch();

  useEffect(() => {
    logEvent(`Page opened`, {
      page_url: match.url,
      page_type: match.path,
      user_id: accountId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setChangeProfile({
      name: voice?.name || '',
      description: voice?.description || '',
      gender: voice?.gender || GenderEnum.MALE,
    });
  }, [voice]);

  const handleLocalChangeProfile = useCallback(
    (value, name) => {
      setChangeProfile(prevState => ({ ...prevState, [name]: value }));
    },
    [setChangeProfile]
  );

  const handleProfileGenderChange = useCallback(() => {
    setChangeProfile(prevState => ({
      ...prevState,
      gender: prevState.gender === GenderEnum.FEMALE ? GenderEnum.MALE : GenderEnum.FEMALE,
    }));
  }, [setChangeProfile]);

  const handleProfileChange = useCallback(async () => {
    try {
      await updateVoice(+id, changeProfile);
      successAlert(t(`changesSaved`));
      logEvent('Voice information updated', {
        url: match.url,
        member_role: voice.currentVoiceMemberRole.name,
        result: 'success',
        user_id: accountId,
      });
      await fetchVoice();
    } catch (error) {
      defaultErrorAlert(error);
      logEvent('Voice information updated', {
        url: match.url,
        member_role: voice.currentVoiceMemberRole.name,
        result: 'failed',
        user_id: accountId,
      });
    }
  }, [
    updateVoice,
    id,
    changeProfile,
    successAlert,
    logEvent,
    match.url,
    voice.currentVoiceMemberRole.name,
    accountId,
    fetchVoice,
    defaultErrorAlert,
  ]);

  const handleAvatarModal = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setAvatarModal(prevState => !prevState);
  }, []);
  const handleFileChoice = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleTabletFileChoice({
        event,
        inputRef,
        voiceId: String(voice.id),
        defaultErrorAlert,
        openModal: () => setAvatarModal(true),
      });
    },
    [defaultErrorAlert, handleTabletFileChoice, voice.id]
  );
  const handleButtonClick = useCallback(() => {
    inputRef.current && inputRef.current.click();
  }, [inputRef]);
  return (
    <>
      <main className='voice-page__main'>
        <DynamicTitle title={t('documentTitle.control', voice.name, productName)} />
        <div className='main__profile-wrapper'>
          <div className='main__profile'>
            <div
              className='main__img'
              data-test-id='voice.avatar.uploader'
              onClick={isTablet ? handleButtonClick : handleAvatarModal}
            >
              <VoiceAvatar size='medium' voiceId={id} />
              {isTablet && (
                <input
                  style={{ display: 'none' }}
                  type='file'
                  accept='.png, .jpg, .jpeg'
                  ref={inputRef}
                  onChange={handleFileChoice}
                  data-test-id='voice.avatar.modal.input'
                />
              )}
              <Icon name='faCamera' />
            </div>
            <div className='main__form'>
              <label className='tp-4' htmlFor='inputText'>
                {t('title')}
              </label>
              <InputText
                name='name'
                id='inputText'
                maxLength={70}
                value={changeProfile?.name}
                onChange={handleLocalChangeProfile}
                placeholder={t('enterTitle')}
                data-test-id='controlPage.voiceTitle'
              />
              <label className='tp-4' htmlFor='inputTextAre'>
                {t('description')}
              </label>
              <Textarea
                id='inputTextAre'
                value={changeProfile?.description}
                name='description'
                rows='4'
                maxLength={MAX_VOICE_DESCRIPTION_LEGHT}
                onChange={handleLocalChangeProfile}
                placeholder={t('voiceDesc')}
                data-test-id='controlPage.voiceDescription'
              />
              <p className='form__radioGroup-label'>{t('voiceGender')}</p>
              <label className='new-voice__switch switch'>
                <div className='switch__labels'>
                  <span>{t('male')}</span>
                  <span>{t('female')}</span>
                </div>
                <input
                  type='checkbox'
                  checked={changeProfile.gender === GenderEnum.FEMALE}
                  onClick={handleProfileGenderChange}
                  data-test-id='controlPage.voiceGender'
                />
                <div className='switch__slider'>
                  {GenderEnum.MALE === changeProfile.gender ? t('male') : t('female')}
                </div>
              </label>
              <label className='tp-4' htmlFor='languageInput'>
                {t('language')}
              </label>
              <InputText
                disabled
                value={t(languagesMap[voice.language])}
                id='languageInput'
                data-test-id='controlPage.language'
              />
              <div>
                <Button
                  color='primary'
                  className='form__submit'
                  disabled={
                    (changeProfile.name === voice?.name &&
                      changeProfile.description === voice?.description &&
                      changeProfile.gender === voice?.gender) ||
                    changeProfile.name?.length === 0
                  }
                  onClick={handleProfileChange}
                  data-test-id='controlPage.submitButton'
                >
                  {t('saveChanges')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <MediaQuery maxWidth={1440}>
          <MembersMemo voice={voice} />
        </MediaQuery>
        <Banner voice={voice} fetchVoice={fetchVoice} />
      </main>
      <MediaQuery minWidth={1440 + 1}>
        <MembersMemo voice={voice} />
      </MediaQuery>
      <AvatarModal isOpen={avatarModal} toggle={handleAvatarModal} voice={voice} />
    </>
  );
}
