import { StyleSettings } from '../../../api/dubber/client';
export const waitForElm = (selector: string) => {
  return new Promise(resolve => {
    if (document.getElementById(selector)) {
      return resolve(document.getElementById(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (document.getElementById(selector)) {
        resolve(document.getElementById(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const addMarkupToText = ({
  rate,
  volume,
  style,
  pitch,
  text,
  styleSettings,
}: {
  rate?: number;
  volume?: number;
  style?: number;
  pitch?: number;
  text: string;
  styleSettings?: Omit<StyleSettings, 'default' | 'applyDefault'>;
}) => {
  if (!volume && !rate && !pitch && !style) return text;
  const volumePart = volume ? `volume="${volume.toFixed()}"` : '';
  const ratePart = rate ? `rate="${rate.toFixed()}"` : '';
  const pitchPart = pitch ? `pitch="${pitch.toFixed()}"` : '';
  const returnString =
    volumePart || ratePart || pitch ? `<prosody ${volumePart} ${ratePart} ${pitchPart}>${text}</prosody>` : text;
  if ((typeof style === 'number' && style >= 0 && styleSettings) || pitch) {
    const styleKey = style && styleSettings?.styles[style];
    const styleString = styleKey && styleSettings?.description[styleKey]?.name;
    if (styleString) return `<style name="${styleString}">${returnString}</style>`;
  }
  return returnString;
};
