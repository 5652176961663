import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Icon, IconButton, Tooltip } from '@just-ai/just-ui';
import { ProgressBar } from '@just-ai/just-ui/dist/AudioPlayer/ProgressBar';
import { useAmplitude } from 'react-amplitude-hooks';

import { SampleStateEnum } from '../../../../api/facade/client';
import { useToggle } from '../../../../utils/hooks';
import { humanizeDuration } from '../../../../utils';
import { t } from '../../../../localization';
import { useAppContext } from '../../../../AppContext';

import './style.scss';
import { useRouteMatch } from 'react-router';

type PlayerProps = {
  sampleState: SampleStateEnum;
  sampleId: number;
  audioUrl: string;
  playingId?: number;
  uploadInProgress: boolean;
  setPlayingId: (playing: boolean) => void;
  saveAudio: () => void;
  clearAudio: () => void;
  isPlaying: boolean;
  isLocal: boolean;
};

export default function SamplePlayer(props: PlayerProps) {
  const audioElemRef = useRef<HTMLAudioElement>(null);
  const [playing, startPlay, stopPlay, togglePlaying] = useToggle();
  const [currentTime, setCurrentTime] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [loading] = useState(false);
  const { logEvent } = useAmplitude();
  const { id } = useAppContext();
  const match = useRouteMatch();

  useEffect(() => {
    if (playing) {
      audioElemRef.current?.play();
    }
    if (!playing) {
      audioElemRef.current?.pause();
    }
  }, [playing]);

  useEffect(() => {
    if (props.isPlaying) {
      startPlay();
    } else {
      stopPlay();
    }
  }, [props.isPlaying, startPlay, stopPlay]);

  useEffect(() => {
    if (props.sampleId !== props.playingId) {
      stopPlay();
    }
  }, [props.playingId, props.sampleId, stopPlay]);

  const handleMetadata = useCallback(() => {
    try {
      if (!audioElemRef.current) return;
      const duration = Math.floor(audioElemRef.current ? audioElemRef.current.duration : 0);
      if (duration < Infinity) {
        setCurrentDuration(duration);
      } else {
        //this is a workaround to fix infinite duration chromium bug https://bugs.chromium.org/p/chromium/issues/detail?id=642012
        audioElemRef.current.addEventListener(
          'durationchange',
          function (e) {
            try {
              if (!audioElemRef.current) return;
              if (this.duration < Infinity) {
                setCurrentDuration(Math.floor(this.duration));
                audioElemRef.current.pause();
                audioElemRef.current.currentTime = 0;
                audioElemRef.current.muted = false;
              }
            } catch (error) {
              console.error('error in duration change in sampleplayer', error);
            }
          },
          false
        );
        audioElemRef.current.currentTime = 24 * 60 * 60;
        audioElemRef.current.muted = true;
        audioElemRef.current.play();
      }
    } catch (error) {
      console.error('error in handlemeta in sampleplayer', error);
    }
  }, [audioElemRef]);

  const handleTimeupdate = useCallback(
    event => {
      if (audioElemRef.current && audioElemRef.current.currentTime < 24 * 60 * 60) {
        setCurrentTime(Math.floor(audioElemRef.current.currentTime));
      }
    },
    [audioElemRef]
  );

  const handlePlayend = useCallback(() => {
    stopPlay();
    logEvent('Audio ended', {
      userId: id,
      sampleId: props.sampleId,
      url: match.url,
    });
    setCurrentTime(0);
    if (audioElemRef && audioElemRef.current) audioElemRef.current.currentTime = 0;
  }, [stopPlay, logEvent, id, props.sampleId, match.url]);

  const handlePlayer = useCallback(() => {
    if (playing) {
      logEvent(props.isLocal ? 'Pause just recorded audio' : 'Pause sample audio from sample', {
        userId: id,
        sampleId: props.sampleId,
        url: match.url,
      });
    } else {
      logEvent(props.isLocal ? 'Play just recorded audio' : 'Play sample audio from sample', {
        userId: id,
        sampleId: props.sampleId,
        url: match.url,
      });
    }
    togglePlaying();
  }, [id, logEvent, match.url, playing, props.isLocal, props.sampleId, togglePlaying]);

  return (
    <div hidden={loading} className='catalog-page__sample-player justui-audio-player' data-test-id='samplePage.player'>
      <audio
        onLoadedMetadata={handleMetadata}
        onTimeUpdate={e => handleTimeupdate(e)}
        ref={audioElemRef}
        controls={false}
        src={props.audioUrl}
        onEnded={handlePlayend}
      />
      <div className='catalog-page__sample-player-progress'>
        <p>{humanizeDuration(currentTime * 1000)}</p>
        <ProgressBar audio={audioElemRef.current as HTMLAudioElement} progressUpdateInterval={10} ShowFilledProgress />
        <p>{humanizeDuration(currentDuration * 1000)}</p>
      </div>
      <div className='catalog-page__sample-player-controls'>
        <div className='catalog-page__sample-player-delete'>
          <div id='deleteSample'>
            <Button
              outline
              color='secondary'
              onClick={props.clearAudio}
              disabled={props.sampleState === SampleStateEnum.PROCESSING ? true : false}
              style={{ pointerEvents: props.sampleState === SampleStateEnum.PROCESSING ? 'none' : 'all' }}
            >
              {t('deleteRecord')} <span>/</span> <Icon name='falBackspace' />
            </Button>
            {props.sampleState === SampleStateEnum.PROCESSING && (
              <Tooltip
                placement='top'
                target='deleteSample'
                modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                textAlign='center'
              >
                {t('waitUntilEnd')}
              </Tooltip>
            )}
          </div>
        </div>
        <div className='catalog-page__sample-player-play'>
          <button onClick={handlePlayer}>
            {playing ? (
              <Icon name='faPause' color='primary' />
            ) : (
              <Icon name='faPlay' color='primary' style={{ paddingLeft: '2px' }} />
            )}
          </button>
        </div>

        <div className='catalog-page__sample-player-download' data-test-id='samplePage.saveBlock'>
          <a href={props.audioUrl} download>
            <IconButton flat name='falArrowToBottom' color='primary' id='downloadSample' size='lg' />
          </a>
          <Tooltip target='downloadSample' placement='left' textAlign='center'>
            {t('downloadRecord')}
          </Tooltip>
          {props.isLocal && (
            <Button
              color='primary'
              onClick={!props.uploadInProgress ? props.saveAudio : undefined}
              data-test-id='samplePage.saveSample'
            >
              {t('save')} <span>/</span>
              <Icon name='farLevelDownAlt' size='sm' style={{ transform: 'rotate(90deg)' }} />
            </Button>
          )}
        </div>
      </div>
      <div className='catalog-page__sample-player-space'>
        {t('pressButton').toLowerCase()} <img src='/img/space.svg' alt='Space button icon' />
      </div>
    </div>
  );
}
