import React, { useReducer, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button, Icon, IconButton, Spinner } from '@just-ai/just-ui';
import { useAmplitude } from 'react-amplitude-hooks';

import { useLibraryContext } from '../../context/LibraryContext';
import { PublishedVoiceView } from '../../../../api/facade/client';
import { VoiceCard, ProjectsPlaceholder, ProjectCard, ManagePlaceholder } from '../../components/MyPage';
import { Voice } from '../../model/Voice';
import { SCREEN_WIDTH_TABLET, SCREEN_WIDTH_LG, SCREEN_WIDTH_PHONE } from '../../../Header/constants';
import { t } from '../../../../localization';
import { ProjectView } from '../../../../api/dubber/client';
import useDefaultAlert from '../../../../utils/useAlert';
import { useProjectContext } from '../../../Projects/context/ProjectsContext';
import { useAppContext } from '../../../../AppContext';
import { DynamicTitle } from '../../components/DynamicTitle/DynamicTitle';
import { PermissionsEnum } from '../../constants';

import './MyPage.scss';

export type VoiceViewWithPrice = PublishedVoiceView & { price: string };

type myPageStateTypes = {
  manageVoices: {
    items: Voice[];
    count: number;
  };

  projects: {
    items: ProjectView[];
    count: number;
  };
  currentPages: {
    manage: number;
    projects: number;
  };
  isLoading: boolean;
};

const initialState: myPageStateTypes = {
  manageVoices: {
    items: [],
    count: 0,
  },

  projects: {
    items: [],
    count: 0,
  },
  currentPages: {
    manage: 0,
    projects: 0,
  },

  isLoading: true,
};

type myPageActionTypes =
  | {
      type: 'SET_NEW_DATA';
      payload: any;
    }
  | { type: 'INC_PAGE'; payload: getTypes }
  | { type: 'SET_LOADING'; payload: boolean }
  | {
      type: 'SET_DATA';
      payload: any;
    }
  | { type: 'RESET_PAGE'; payload: 'manage' | 'projects' };

const myPageReducer = (state: myPageStateTypes, action: myPageActionTypes): myPageStateTypes => {
  switch (action.type) {
    case 'SET_NEW_DATA':
      return {
        ...state,
        [action.payload.type]: {
          items: action.payload.type === 'projects' ? action.payload.projects : action.payload.voices,
          count: +action.payload.count,
        },
      };
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.type]: {
          items:
            action.payload.type === 'projects'
              ? [...state.projects.items, ...action.payload.projects]
              : [...state.manageVoices.items, ...action.payload.voices],
          count: +action.payload.count,
        },
      };

    case 'INC_PAGE':
      return {
        ...state,
        currentPages: { ...state.currentPages, [action.payload]: state.currentPages[action.payload] + 1 },
      };

    case 'SET_LOADING': {
      return { ...state, isLoading: action.payload };
    }
    case 'RESET_PAGE': {
      return { ...state, currentPages: { ...state.currentPages, [action.payload]: 0 } };
    }
  }
};
export type getTypes = 'manageVoices' | 'projects';

export const MyPage = () => {
  const [state, dispatch] = useReducer(myPageReducer, initialState);
  const { getVoices } = useLibraryContext();
  const { getProjects, createProject } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();
  const { logEvent } = useAmplitude();
  const { id, productName, permissions } = useAppContext();
  const match = useRouteMatch();
  const history = useHistory();
  const lgScreenDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_LG}px)` });
  const tabletDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  const phoneOnly = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });

  const getAllowedVoices = useCallback(
    (type: getTypes) => {
      if (tabletDown) return 6;
      if (lgScreenDown && type === 'manageVoices') return 8;
      return 9;
    },
    [lgScreenDown, tabletDown]
  );

  const reqData = useCallback(
    (type: getTypes, add?: boolean) => {
      if (type === 'manageVoices') {
        return getVoices(
          add ? state.currentPages.manage + 1 : 0,
          add ? getAllowedVoices('manageVoices') : (state.currentPages.manage + 1) * getAllowedVoices('manageVoices')
        );
      }
      if (type === 'projects') {
        return getProjects(
          add ? state.currentPages.projects + 1 : 0,
          add ? getAllowedVoices('projects') : (state.currentPages.projects + 1) * getAllowedVoices('projects')
        );
      }
    },
    [getAllowedVoices, getProjects, getVoices, state.currentPages.manage, state.currentPages.projects]
  );

  const getData = useCallback(
    async (type: getTypes, add?: boolean) => {
      try {
        const data = await reqData(type, add);
        if (add) {
          dispatch({ type: 'SET_DATA', payload: { ...data, type } });
        } else {
          dispatch({ type: 'SET_NEW_DATA', payload: { ...data, type } });
        }

        dispatch({ type: 'SET_LOADING', payload: false });
      } catch (error) {
        defaultErrorAlert(error);
      }
    },
    [defaultErrorAlert, reqData]
  );

  useEffect(() => {
    logEvent('Page opened', {
      page_url: match.url,
      page_type: match.path,
      user_id: id,
    });
  }, [id, logEvent, match.path, match.url]);

  useEffect(() => {
    if (state.projects.count > 0) return;
    getData('projects');
  }, [getData, state.projects.count]);

  useEffect(() => {
    if (state.manageVoices.count > 0) return;
    getData('manageVoices');
  }, [getData, state.manageVoices.count]);

  const calcLoadedItems = ({
    totalCount,
    currentCount,
    type,
  }: {
    totalCount: number;
    currentCount: number;
    type: getTypes;
  }) => {
    if (totalCount - currentCount > getAllowedVoices(type)) return getAllowedVoices(type);
    return totalCount - currentCount;
  };

  const handleButtonClick = useCallback(
    async (type: getTypes) => {
      dispatch({ type: 'INC_PAGE', payload: type });
      getData(type, true);
      logEvent(`More ${type} loaded`, {
        userId: id,
        page: state.currentPages[type] + 1,
      });
    },
    [getData, id, logEvent, state.currentPages]
  );

  const handleVoiceCreate = useCallback(() => {
    history.push('/my/new_voice');
  }, [history]);

  const createEmptyProject = async () => {
    try {
      const createdProject = await createProject(
        state.projects.count > 0 ? `${t('untitled')} (${state.projects.count})` : t('untitled')
      );
      logEvent('project created', {
        project_id: createdProject.id,
        result: 'success',
      });
      history.push(`${history.location.pathname}/project/${createdProject.id}`);
    } catch (error) {
      defaultErrorAlert(error);
      logEvent('project created', {
        result: 'failed',
      });
    }
  };

  const shouldDisplayLastCard = ({
    totalCount,
    currentCount,
    type,
  }: {
    totalCount: number;
    currentCount: number;
    type: getTypes;
  }) => {
    if (+totalCount === getAllowedVoices(type)) return false;
    return !((totalCount - currentCount) / getAllowedVoices(type) > 0);
  };

  const handleDictionaryOpen = useCallback(() => {
    history.push('/my/dictionary');
  }, [history]);

  if (state.isLoading) return <Spinner />;
  return (
    <div className='my-page__wrapper'>
      <DynamicTitle title={t('documentTitle.myPage', productName)} />
      <div className={`my-page__projects ${!state.projects.count && state.manageVoices.count ? 'order-2' : 'order-1'}`}>
        <div className='my-page__projects-header'>
          <h2>{t('myProjects')}</h2>
          {state.projects.count > 0 &&
            (phoneOnly ? (
              <div className='my-page__projects-header__buttons'>
                <IconButton name='farBookOpen' color='secondary' outline onClick={handleDictionaryOpen} />
                <IconButton
                  name='falPlus'
                  color='secondary'
                  outline
                  onClick={createEmptyProject}
                  data-test-id='project.createProject'
                />
              </div>
            ) : (
              <div className='my-page__projects-header__buttons'>
                <Button iconLeft='farBookOpen' color='secondary' flat onClick={handleDictionaryOpen}>
                  {t('Dictionary:Title')}
                </Button>
                <Button
                  iconLeft='falPlus'
                  color='primary'
                  onClick={createEmptyProject}
                  data-test-id='project.createProject'
                >
                  {t('createProject')}
                </Button>
              </div>
            ))}
        </div>
        {state.projects.count > 0 ? (
          <>
            <div className='my-page__projects-container'>
              {state.projects.items.map(project => (
                <ProjectCard project={project} key={project.id} getData={() => getData('projects')} />
              ))}
              {shouldDisplayLastCard({
                totalCount: state.projects.count,
                currentCount: state.projects.items.length,
                type: 'projects',
              }) && (
                <div
                  className='my-page__manage-create'
                  onClick={createEmptyProject}
                  data-test-id='project.createProjectCard'
                >
                  <Icon name='falPlus' size='lg' data-test-id='project.createProject' /> <h4>{t('createProject')}</h4>
                </div>
              )}
            </div>
            {state.projects.count > getAllowedVoices('projects') &&
              +state.projects.count > state.projects.items.length && (
                <div className='my-page__manage-moreBtn'>
                  <Button outline color='primary' size='xl' onClick={() => handleButtonClick('projects')}>
                    {t(
                      'loadVoices',
                      calcLoadedItems({
                        totalCount: state.projects.count,
                        currentCount: state.projects.items.length,
                        type: 'projects',
                      }),
                      state.projects.count - state.projects.items.length
                    )}
                  </Button>
                </div>
              )}
          </>
        ) : (
          <ProjectsPlaceholder type='projects' createEmptyProject={createEmptyProject} />
        )}
      </div>
      {(permissions?.includes(PermissionsEnum.VoiceCreate) || state.manageVoices.count > 0) && (
        <div className={`my-page__manage ${state.projects.count && !state.manageVoices.count ? 'order-2' : 'order-1'}`}>
          <div className='my-page__manage-header'>
            <h2>{t('controlVoices')}</h2>
            {state.manageVoices.count > 0 &&
              (phoneOnly ? (
                <IconButton
                  name='falPlus'
                  color='secondary'
                  outline
                  data-test-id='createVoiceBtn'
                  onClick={handleVoiceCreate}
                />
              ) : (
                <Button iconLeft='falPlus' color='primary' data-test-id='createVoiceBtn' onClick={handleVoiceCreate}>
                  {t('createVoice')}
                </Button>
              ))}
          </div>
          {state.manageVoices.count > 0 ? (
            <div className='my-page__manage-container'>
              {state.manageVoices.items.map(voice => (
                <VoiceCard key={voice.id} voice={voice} type='manage' />
              ))}
              {shouldDisplayLastCard({
                totalCount: state.manageVoices.count,
                currentCount: state.manageVoices.items.length,
                type: 'manageVoices',
              }) && (
                <div className='my-page__manage-create' onClick={handleVoiceCreate}>
                  <Icon name='falPlus' size='lg' data-test-id='createVoiceBtn' /> <h4>{t('createVoice')}</h4>
                </div>
              )}
            </div>
          ) : (
            <ManagePlaceholder />
          )}
          {state.manageVoices.count > getAllowedVoices('manageVoices') &&
            +state.manageVoices.count > state.manageVoices.items.length && (
              <div className='my-page__manage-moreBtn'>
                <Button outline color='primary' size='xl' onClick={() => handleButtonClick('manageVoices')}>
                  {t(
                    'loadVoices',
                    calcLoadedItems({
                      totalCount: state.manageVoices.count,
                      currentCount: state.manageVoices.items.length,
                      type: 'manageVoices',
                    }),
                    state.manageVoices.count - state.manageVoices.items.length
                  )}
                </Button>
              </div>
            )}
        </div>
      )}
    </div>
  );
};
