import { useCallback } from 'react';
import { ModalBody } from 'reactstrap';
import { InputTextFullUncontrolled } from '@just-ai/just-ui';
import { useForm } from 'react-hook-form';

import { CustomModal } from '..';
import { t } from '../../../../../localization';
import { useProjectContext } from '../../../../Projects/context/ProjectsContext';
import { getErrorMessageFromReason } from '../../../../../utils/error';
import { PresetView } from '../../../../../api/dubber/client';
import { useSettings } from '../../../../Projects/context/ReplicaSettingsContext';

import './PresetCreate.scss';

export const CustomModalCreatePreset = ({
  updateVoiceLine,
  toggleModal,
  getData,
}: {
  updateVoiceLine: (preset: PresetView) => void;
  toggleModal: () => void;
  getData: () => void;
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{ name: string }>();
  const { createPreset } = useProjectContext();
  const { generateMarkupUpdate } = useSettings();

  const handleActionButton = useCallback(() => {
    handleSubmit(async ({ name }) => {
      try {
        const markup = generateMarkupUpdate(false, true);
        const createdPreset = await createPreset(name, markup);
        updateVoiceLine(createdPreset);
        getData();
        toggleModal();
      } catch (error) {
        setError('name', {
          message: getErrorMessageFromReason(error),
        });
      }
    })();
  }, [generateMarkupUpdate, createPreset, updateVoiceLine, getData, toggleModal, setError, handleSubmit]);

  return (
    <>
      <ModalBody className='custom-modal__body create-preset'>
        <div className='input-wrapper'>
          <InputTextFullUncontrolled
            name='name'
            type='text'
            placeholder={t('enterTitle')}
            required
            size='lg'
            invalid={errors.name}
            innerRef={register({
              required: t('fieldRequired'),
            })}
          />
          {errors.name?.message && <p className='error-text small-text'>{errors.name?.message}</p>}
        </div>
      </ModalBody>
      <CustomModal.Footer actionButtonTitle={t('save')} buttonSize='xl' actionButtonClick={handleActionButton} />
    </>
  );
};
