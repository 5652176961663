import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { DropdownItem, Dropdown, DropdownToggle, DropdownMenu, Icon, IconButton } from '@just-ai/just-ui';
import { matchPath, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import SidebarMenu from '../SidebarMenu';
import { useToggle } from '../../../../utils/hooks';
import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';
import { SCREEN_WIDTH_MD, SCREEN_WIDTH_TABLET } from '../../constants';
import { getCCLinkWithNavigate } from '../../../../utils';

import './UserMenu.scss';

export default function UserMenu({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [isOpen, , closeMenu, toggle] = useToggle();
  const { logoutHandler, name, configData, email } = useAppContext();
  const mdScreenDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_MD}px)` });
  const tabletDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  const history = useHistory();
  const isProjectPage = matchPath(history.location.pathname, { path: '*/my/project/**' });

  const logout = useCallback(async () => {
    try {
      await logoutHandler();
      closeMenu();
    } catch (error) {
      console.error('logout failed');
    }
  }, [closeMenu, logoutHandler]);

  const handleLoginButton = useCallback(() => {
    closeMenu();
    window.location.href = getCCLinkWithNavigate(configData.authPath, history.location.pathname);
  }, [closeMenu, configData.authPath, history.location.pathname]);

  const handleAccountButton = useCallback(() => {
    history.push('/account');
  }, [history]);

  if (isProjectPage && mdScreenDown) return null;
  return tabletDown ? (
    <>
      <IconButton
        onClick={toggle}
        className='header-navbar__mobile-menu'
        name='falBars'
        size='xl'
        color='none'
        flat
        data-test-id='userMenu.mobile'
      />
      {ReactDOM.createPortal(
        <CSSTransition in={isOpen} timeout={200} classNames='slide' unmountOnExit>
          <SidebarMenu
            isOpen={isOpen}
            handleLogout={logout}
            username={name || ''}
            isLoggedIn={isLoggedIn}
            toggle={toggle}
            handleLogin={handleLoginButton}
            closeMenu={closeMenu}
          />
        </CSSTransition>,
        document.body
      )}
    </>
  ) : (
    <>
      <div className='user-menu' data-test-id='userMenu'>
        {isLoggedIn ? (
          <Dropdown
            className='header-dropdown header-menu'
            isOpen={isOpen}
            toggle={toggle}
            data-test-id='userMenu.dropdown'
          >
            <DropdownToggle color='none' className='header-menu__toggle'>
              <div className={cn('header-menu__user', { active: isOpen })}>
                <Icon name='farUserCircle' />
                <p className='tp-3'>{name || email}</p>
              </div>
            </DropdownToggle>
            <DropdownMenu right>
              <div className='header-menu__user-info'>
                {name && <p className='st-1'>{name}</p>}
                <p className='tp-3'>{email}</p>
              </div>
              <DropdownItem
                onClick={handleAccountButton}
                style={{ color: 'var(--gray-700)' }}
                data-test-id='userMenu.account'
              >
                <p className='tp-3'>{t('Header:UserMenu:Account')}</p>
              </DropdownItem>
              <DropdownItem onClick={logout} style={{ color: 'var(--gray-700)' }} data-test-id='userMenu.logout'>
                <p className='tp-3'>{t('logout')}</p>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <a
            data-test-id='userMenu.login'
            className='user-menu__login'
            href={getCCLinkWithNavigate(configData.authPath, history.location.pathname)}
          >
            {t('loginButtonCaption')}
          </a>
        )}
      </div>
    </>
  );
}
