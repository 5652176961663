import React from 'react';
import { Button } from '@just-ai/just-ui';
import { Link, useParams } from 'react-router-dom';
import { t } from '../../../../../localization';
import './Placeholder.scss';

type PlaceholderTypes = {
  toggleUploadModal: () => void;
};

export const Placeholder = ({ toggleUploadModal }: PlaceholderTypes) => {
  const { voiceId, catalogId } = useParams<{ voiceId: string; catalogId: string }>();
  return (
    <div className='catalog-page__placeholder' data-test-id='catalogPage.placeholder'>
      <div className='catalog-page__placeholder-content'>
        <h2>{t('noLearningData')}</h2>
        <p>{t('noLearningDataDesc')}</p>
        <div className='catalog-page__placeholder-buttons'>
          <Link
            to={`/my/voice/${voiceId}/training/new_version/${catalogId}/sample/create`}
            data-test-id='catalogPage.placeholderCreate'
          >
            <Button outline color='secondary'>
              {t('create')}
            </Button>
          </Link>
          <Button outline color='secondary' onClick={toggleUploadModal} id='uploadArchive'>
            {t('upload')}
          </Button>
        </div>
      </div>
    </div>
  );
};
