import { CatalogApi, CatalogCreateOrUpdate } from '../api/facade/client';
import { axiosWrapped } from '../pipes/functions';

export default class CatalogService {
  static readonly BASE_PATH = '';
  private voiceId: number | undefined;
  private CatalogApi: CatalogApi;
  constructor(voiceId: number) {
    this.voiceId = voiceId;
    this.CatalogApi = new CatalogApi({}, CatalogService.BASE_PATH, axiosWrapped);
  }

  getAllCatalogs = async (isUsed?: boolean) => {
    if (!this.voiceId) return;
    const { data: catalogsList, headers } = await this.CatalogApi.getCatalogs(
      this.voiceId,
      undefined,
      undefined,
      isUsed
    );
    return { catalogsList: catalogsList || [], count: parseInt(headers['content-range'] || '') };
  };

  createCatalog = async (catalogData: CatalogCreateOrUpdate) => {
    if (!this.voiceId) return;
    try {
      const { data: catalogId } = await this.CatalogApi.createCatalog(this.voiceId, catalogData);
      return catalogId;
    } catch (error) {
      console.error('error creating catalog ', error);
      throw error;
    }
  };

  getCatalog = async (catalogId: number) => {
    if (!this.voiceId) return;
    try {
      const { data: catalogData } = await this.CatalogApi.getCatalog(this.voiceId, catalogId);
      return catalogData;
    } catch (error) {
      console.error('error fetching catalog ' + catalogId, error);
      throw error;
    }
  };

  updateCatalog = async (catalogId: number, catalogData: CatalogCreateOrUpdate) => {
    if (!this.voiceId) return;
    try {
      await this.CatalogApi.patchCatalog(this.voiceId, catalogId, catalogData);
    } catch (error) {
      console.error('error updating catalog ' + catalogId, error);
      throw error;
    }
  };

  deleteCatalog = async (catalogId: number) => {
    if (!this.voiceId) return;
    try {
      await this.CatalogApi.removeCatalog(this.voiceId, catalogId);
    } catch (error) {
      console.error('error deleting catalog ' + catalogId, error);
      throw error;
    }
  };
}
