import React from 'react';
import { t } from '../../../../localization';
import { AllVoicesPageFilters } from '../../pages/AllVoicesPage';
import MediaQuery from 'react-responsive';
import { DesktopFilter } from './DesktopFilter';
import { MobileFilter } from './MobileFilter';
import { SCREEN_WIDTH_MD } from '../../../Header/constants';

//TODO uncommit components - return all content

// const domain = [0, 5000] as any;

type VoicesFilterProps = {
  voicesAmount: number;
  filter: AllVoicesPageFilters;
  initialFilters: AllVoicesPageFilters;
  handleFilter?: (field: keyof AllVoicesPageFilters, value?: any) => void;
  handleSlider?: (values: readonly number[]) => void;
  setFilter?: (filter: AllVoicesPageFilters) => void;
  resetFilter?: () => void;
};

export default function VoicesFilter({
  filter,
  voicesAmount,
  setFilter,
  handleFilter,
  resetFilter,
  initialFilters,
}: VoicesFilterProps) {
  return (
    <>
      <div className='voices-catalog__side-filters__header'>
        <h1>{t('voices')}</h1>
        <span className='grey-text-2 tp-3'>{t('amount found', voicesAmount)}</span>
      </div>
      <MediaQuery maxWidth={SCREEN_WIDTH_MD}>
        {match =>
          match
            ? setFilter &&
              resetFilter && <MobileFilter filter={filter} setFilter={setFilter} initialFilters={initialFilters} />
            : handleFilter && <DesktopFilter handleFilter={handleFilter} filter={filter} />
        }
        {}
      </MediaQuery>
    </>
  );
}
