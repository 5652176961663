import { useCallback, useState } from 'react';
import { InputText } from '@just-ai/just-ui';
import { ModalBody } from 'reactstrap';

import { t } from '../../../../localization';
import useDefaultAlert from '../../../../utils/useAlert';
import { useLibraryContext } from '../../context/LibraryContext';
import CustomModal from './CustomModal';

export const CustomModalConnectSimulated = ({ voiceId, toggle }: { voiceId: number; toggle: () => void }) => {
  const [data, setData] = useState({ provider: '', modelName: '' });
  const { connectSimulatedVersion } = useLibraryContext();
  const { defaultErrorAlert } = useDefaultAlert();

  const handleModalName = useCallback(
    (value: string, name?: string) => {
      if (!name) return;
      setData({
        ...data,
        [name]: value,
      });
    },
    [data]
  );

  const handleConfirm = useCallback(() => {
    try {
      connectSimulatedVersion(voiceId, data.modelName, data.provider);
      toggle();
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [connectSimulatedVersion, voiceId, data, toggle, defaultErrorAlert]);

  return (
    <>
      <ModalBody className='custom-modal__body'>
        <InputText
          placeholder={t('TrainingPage:Modal:ConnectModel:ModelPlaceholder')}
          size='lg'
          name='modelName'
          defaultValue={data.modelName}
          onChange={handleModalName}
        />
        <InputText
          placeholder={t('TrainingPage:Modal:ConnectModel:ProviderPlaceholder')}
          size='lg'
          name='provider'
          defaultValue={data.provider}
          onChange={handleModalName}
        />
      </ModalBody>
      <CustomModal.Footer actionButtonTitle='Connect' actionButtonClick={handleConfirm} />
    </>
  );
};
