import React from 'react';
import classNames from 'classnames';
import { Spinner } from '@just-ai/just-ui';

export const Glass = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div className={classNames('loader__glass', { active: isLoading })} data-test-id='glass'>
      {isLoading && <Spinner color='primary' />}
    </div>
  );
};
