import React, { createRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { t } from '../../../../localization';
import { ModelView, PublicModelView, VoiceConsumerView } from '../../../../api/facade/client';
import { LibraryContext } from '../../context/LibraryContext';
import withAmplitude, { AmplitudeHOCProps } from '../../../../components/HOC/withAmplutide';
import { withAppContext, AppContextType } from '../../../../AppContext';
import { Placeholder, VersionsList } from '../../components/VoicePublish';
import { Icon, IconButton, InputText } from '@just-ai/just-ui';
import classNames from 'classnames';
import { DynamicTitle } from '../../components/DynamicTitle/DynamicTitle';

import './PublishedPage.scss';

type PublishedProps = RouteComponentProps<
  {
    id: string;
  },
  any,
  { versionClicked: number }
> &
  AmplitudeHOCProps &
  AppContextType & {
    public?: boolean;
    loginStatus?: boolean;
    voiceName: string;
  };

class State {
  isLoading: boolean = false;
  versions: ModelView[] | PublicModelView[] = [];
  token?: string | null;
  tokenCopied?: boolean;
  tokenConsumer: undefined | VoiceConsumerView;
}
class PublishedPage extends React.Component<PublishedProps, State> {
  static contextType = LibraryContext;
  context!: React.ContextType<typeof LibraryContext>;
  state = new State();
  copyRef = createRef<HTMLInputElement>();
  outsideRef = createRef<HTMLDivElement>();

  async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    if (params.has('version')) {
      await this.fetchWithPopup(Number(params.get('version')));
      window.history.replaceState({}, document.title, window.location.href.slice(0, window.location.href.indexOf('?')));
    } else {
      this.fetchData();
    }
    document.addEventListener('mousedown', this.handleOutsideClick);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  fetchWithPopup = async (version: number) => {
    if (this.state.tokenConsumer && this.state.tokenConsumer.modelId === version) return;
    this.state.tokenConsumer
      ? await this.context.updateToken(+this.props.match.params.id, version)
      : await this.context.createToken(+this.props.match.params.id, version);
    this.fetchData();
    this.toggleTokenInput();
    window.history.replaceState({}, document.title);
  };

  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      const [versionData, tokenConsumer] = await Promise.all([
        this.props.public
          ? await this.context.getPublicVoiceModels(+this.props.match.params.id)
          : await this.context.getVoiceModels(+this.props.match.params.id, true),
        this.props.public && !this.props.loginStatus
          ? undefined
          : await this.context.tokenConsumer(+this.props.match.params.id),
      ]);
      this.setState({ versions: versionData, tokenConsumer });
      this.props.logEvent('Page opened', {
        page_url: this.props.match.url,
        page_type: this.props.match.path,
        voice_id: this.props.id,
      });
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  fetchTokenData = async (open?: boolean) => {
    const tokenConsumer = await this.context.tokenConsumer(+this.props.match.params.id);
    if (open) {
      this.toggleTokenInput();
    }
    this.setState({ tokenConsumer });
  };

  handleCopyToken = (event: React.MouseEvent<Node>) => {
    event.stopPropagation();
    if (!(this.copyRef && this.copyRef.current)) return;
    navigator.clipboard.writeText(this.copyRef.current.value);
    this.setState({ tokenCopied: true });
  };

  toggleTokenInput = async () => {
    this.setState({ tokenCopied: false });
    if (!this.state.token) {
      const token = await this.context.getToken(+this.props.match.params.id);
      this.setState({ token });
      this.props.logEvent('get_token', {
        page_type: this.props.match.path,
        page_url: this.props.match.url,
        type: 'private voice page',
        user_id: this.props.id,
      });
    } else {
      this.setState({ token: null });
    }
  };

  handleOutsideClick = event => {
    if (!this.state.token) return;
    if (this.outsideRef.current && !this.outsideRef.current.contains(event.target)) {
      this.toggleTokenInput();
    }
  };
  render() {
    return (
      <div className='voice-page__published published'>
        <DynamicTitle title={t('documentTitle.published', this.props.voiceName, this.props.productName)} />
        <div className='published__header'>
          <h1 className='tp-1'>{t(this.props.public ? 'versionHistory' : 'publishedVersions')}</h1>
          {this.state.tokenConsumer?.modelId && (
            <div
              className={classNames('published__token', { active: this.state.token })}
              ref={this.outsideRef}
              data-test-id='publishedPage.token'
            >
              <div className='published__token-button' onClick={this.toggleTokenInput}>
                <p>{t('myToken')}</p>
                <Icon name='falChevronDown' size='sm' />
              </div>
              {this.state.token && (
                <div className='published__token-popup'>
                  <div>
                    <InputText
                      innerRef={this.copyRef}
                      readOnly
                      value={this.state.token}
                      data-test-id='publishedPage.tokenInput'
                    />
                    {this.state.tokenCopied ? (
                      <IconButton color='success' name='faCheck' />
                    ) : (
                      <IconButton color='primary' name='farCopy' onClick={this.handleCopyToken} />
                    )}
                  </div>
                  <a
                    href={`${this.props.isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}/use-voice/request/`}
                    target='_blank'
                    className='public-voice__popup-doc tp-4'
                    rel='noreferrer'
                  >
                    {t('doc.connectToApi')}
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
        {!this.state.isLoading &&
          (this.state.versions.length === 0 ? (
            <Placeholder />
          ) : (
            <VersionsList
              versions={this.state.versions}
              fetchData={this.fetchTokenData}
              tokenConsumerData={this.state.tokenConsumer}
            />
          ))}
      </div>
    );
  }
}

export default withAmplitude({})(withRouter(withAppContext(PublishedPage)));
