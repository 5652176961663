import React from 'react';
import CustomModal from './CustomModal';
import { t } from '../../../../localization';

type DeleteSampleProps = {
  actionButtonClick: () => void;
  cancelButtonClick: () => void;
};

export const CustomModalDeleteSample = ({ actionButtonClick, cancelButtonClick }: DeleteSampleProps) => {
  return (
    <CustomModal.Footer
      cancelButtonTitle={t('cancel')}
      actionButtonTitle={t('delete')}
      actionButtonClick={actionButtonClick}
      cancelButtonClick={cancelButtonClick!}
    />
  );
};
