import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, DropdownToggle, DropdownMenu, DropdownItem, DropdownButton } from '@just-ai/just-ui';

import { t } from '../../../../../localization';
import { useToggle } from '../../../../../utils/hooks';

const linksNameAccordingToRole = {
  '2': ['trainingNoun', 'control', 'publishedVersions', 'using'],
  '3': ['goToVoiceActing'],
  '4': ['trainingNoun', 'control', 'publishedVersions', 'using'],
  '5': ['trainingNoun', 'control', 'publishedVersions', 'using'],
};

const linksPathAccordingToName = {
  trainingNoun: function (voiceId: number) {
    return `/my/voice/${voiceId}/training`;
  },
  control: function (voiceId: number) {
    return `/my/voice/${voiceId}/control`;
  },
  publishedVersions: function (voiceId: number) {
    return `/my/voice/${voiceId}/released`;
  },
  using: function (voiceId: number) {
    return `/my/voice/${voiceId}/usage`;
  },
  goToVoiceActing: function (voiceId: number) {
    return `/my/voice/${voiceId}/training/new_version`;
  },
};

export const VoiceLinkGenerator = ({ roleId, voiceId }: { roleId: number; voiceId: number }) => {
  return (
    <div className='my-page__card-links'>
      {linksNameAccordingToRole[roleId].map((link, index) => {
        if (index < 2) {
          return (
            <Link key={`${link}${index}`} to={linksPathAccordingToName[link](voiceId)} data-test-id={`myVoice.${link}`}>
              {t(link)}
            </Link>
          );
        }
        return null;
      })}
      {linksNameAccordingToRole[roleId].length > 2 && (
        <MoreButton
          remainingLinks={linksNameAccordingToRole[roleId].slice(2, linksNameAccordingToRole[roleId].length)}
          voiceId={voiceId}
        />
      )}
    </div>
  );
};

const MoreButton = ({ remainingLinks, voiceId }: { remainingLinks: string[]; voiceId: number }) => {
  const [isOpen, , , toggle] = useToggle();

  return (
    <DropdownButton isOpen={isOpen} toggle={toggle}>
      <DropdownToggle compact>
        {t('moreNoun')} <Icon name='falChevronDown' size='sm' />
      </DropdownToggle>
      <DropdownMenu>
        {remainingLinks.map(link => (
          <Link to={linksPathAccordingToName[link](voiceId)} key={link}>
            <DropdownItem>{t(link)}</DropdownItem>
          </Link>
        ))}
      </DropdownMenu>
    </DropdownButton>
  );
};
