import {
  VoiceApi,
  VoiceCreateOrUpdate,
  VoiceRoleApi,
  VoiceMemberToAdd,
  VoiceMemberToRemove,
  RoleApi,
  ModifyVoiceMember,
  GenderEnum,
  TokenControllerApi,
  VoiceConsumerControllerApi,
  VoicePriceCreate,
  LanguageEnum,
} from '../api/facade/client/api';

import { axiosWrapped } from '../pipes/functions';

import { ResourcesLoaderEndpointApi } from '../api/dataset-loader/client/api';
import { VoiceAnalyticsControllerApi } from '../api/analytics/client/api';

export default class VoiceService {
  static readonly BASE_PATH = '';
  private accountId: number;
  private VoiceApi: VoiceApi;
  private VoiceRoleApi: VoiceRoleApi;
  private RoleApi: RoleApi;
  private ResourcesLoaderEndpointApi: ResourcesLoaderEndpointApi;
  private TokenControllerApi: TokenControllerApi;
  private VoiceAnalyticsControllerApi: VoiceAnalyticsControllerApi;
  private VoiceConsumerControllerApi: VoiceConsumerControllerApi;

  constructor(accountId: number) {
    this.accountId = accountId;
    this.VoiceApi = new VoiceApi({}, VoiceService.BASE_PATH, axiosWrapped);
    this.VoiceRoleApi = new VoiceRoleApi({}, VoiceService.BASE_PATH, axiosWrapped);
    this.RoleApi = new RoleApi({}, VoiceService.BASE_PATH, axiosWrapped);
    this.ResourcesLoaderEndpointApi = new ResourcesLoaderEndpointApi({}, VoiceService.BASE_PATH, axiosWrapped);
    this.TokenControllerApi = new TokenControllerApi({}, VoiceService.BASE_PATH, axiosWrapped);
    this.VoiceAnalyticsControllerApi = new VoiceAnalyticsControllerApi({}, VoiceService.BASE_PATH, axiosWrapped);
    this.VoiceConsumerControllerApi = new VoiceConsumerControllerApi({}, VoiceService.BASE_PATH, axiosWrapped);
  }

  getAllVoices = async (page: number, pageSize: number = 25) => {
    try {
      const { data: voices, headers } = await this.VoiceApi.getVoices(page, pageSize);
      return { voices, headers };
    } catch (error) {
      console.error('Error fetching all voices', error);
    }
  };
  getVoice = async (id: number) => {
    try {
      const { data: voiceData } = await this.VoiceApi.getVoice(id);
      return voiceData;
    } catch (error) {
      console.error('Error fetching voice data', error);
      throw error;
    }
  };

  getPublicVoice = async (voiceId: number) => {
    try {
      const { data } = await this.VoiceApi.getPublishedVoice(voiceId);
      return data;
    } catch (error) {
      console.error('Error fetching voice data', error);
    }
  };

  updateVoice = async (voiceId: number, voice: VoiceCreateOrUpdate) => {
    await this.VoiceApi.patchVoice(voiceId, voice);
  };
  createVoice = async (voice: VoiceCreateOrUpdate) => {
    const result = await this.VoiceApi.createVoice(voice);
    return result.data;
  };
  deleteVoice = async (voiceId: number) => {
    await this.VoiceApi.archiveVoice(voiceId);
    return;
  };

  getVoiceMembers = async (voiceId: number) => {
    const { data: voiceMembers } = await this.VoiceRoleApi.getVoiceMembers(voiceId);
    return voiceMembers;
  };
  addVoiceMember = async (voiceId: number, member: VoiceMemberToAdd) => {
    await this.VoiceRoleApi.addMemberToVoice(voiceId, member);
  };
  deleteVoiceMember = async (voiceId: number, member: VoiceMemberToRemove) => {
    await this.VoiceRoleApi.removeMemberFromVoice(voiceId, member);
  };
  updateVoiceMember = async (voiceId: number, member: ModifyVoiceMember) => {
    await this.VoiceRoleApi.modifyVoiceMember(voiceId, member);
  };

  getRoles = async () => {
    const data = await this.RoleApi.getRoles();
    return data;
  };

  getActualVersionData = async (id: number) => {
    const { data: versionData } = await this.VoiceApi.getNewVersionInfo(id);
    return versionData;
  };

  getPublishedVoices = async (
    page: number = 0,
    pageSize: number = 25,
    gender?: GenderEnum,
    sort?: 'NEWEST',
    tags?: string[],
    languages?: LanguageEnum[]
  ) => {
    const { data: publishedVoices, headers } = await this.VoiceApi.getPublishedVoices(
      tags,
      gender,
      languages,
      sort,
      page,
      pageSize
    );
    return { publishedVoices, count: parseInt(headers['content-range'] || '') || 0 };
  };

  publishVoice = async (voiceId: number, text: string) => {
    await this.VoiceApi.publishVoice(voiceId, { previewText: text, accessRestricted: false });
  };

  unpublishVoice = async (voiceId: number) => {
    await this.VoiceApi.unpublishVoice(voiceId);
  };

  uploadVoiceAvatar = async (voiceId: number, image: File) => {
    await this.ResourcesLoaderEndpointApi.uploadVoicePictureUsingPOST(voiceId, image);
  };

  getToken = async (voiceId: number) => {
    const { data } = await this.TokenControllerApi.getToken(voiceId);
    return data;
  };

  createToken = async (voiceId: number, modelId?: number) => {
    const { data } = await this.TokenControllerApi.generateToken(voiceId, modelId);
    return data;
  };

  removeToken = async (voiceId: number) => {
    await this.TokenControllerApi.removeToken(voiceId);
  };

  updateToken = async (modelId: number, voiceId: number) => {
    await this.TokenControllerApi.updateTokenSetting(modelId, voiceId);
  };

  tokenConsumer = async (voiceId: number) => {
    const { data } = await this.VoiceConsumerControllerApi.getVoiceConsumer(voiceId);
    return data;
  };
  getVoiceUsage = async (voiceId: number, page: number, pageSize: number) => {
    const { data, headers } = await this.VoiceAnalyticsControllerApi.getVoiceUsageSummary(voiceId, page, pageSize);
    return { data, count: parseInt(headers['content-range'] || '') || 0 };
  };

  getPublicVoiceModels = async (voiceId: number) => {
    const { data: models } = await this.VoiceApi.getPublicReleaseModels(voiceId);
    return models;
  };

  getVoicePrice = async (voiceId: number) => {
    const { data } = await this.VoiceApi.getVoicePrice1(voiceId);
    return data;
  };

  setVoicePrice = async (voiceId: number, price: VoicePriceCreate) => {
    await this.VoiceApi.setVoicePrice(voiceId, price);
  };

  getAllVoicesForSynth = async (page?: number, pageSize?: number) => {
    const { data } = await this.VoiceApi.getUserSynthesizableVoices(page, pageSize);
    return data;
  };

  connectSimulatedVersion = (voiceId: number, modelName: string, provider: string) => {
    this.VoiceApi.connectVoiceToModel(voiceId, modelName, provider);
  };
}
