import React from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, Icon, DropdownButton } from '@just-ai/just-ui';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppContextType, withAppContext } from '../../../../../AppContext';
import { CatalogView, VoiceView } from '../../../../../api/facade/client';
import { LibraryContext } from '../../../context/LibraryContext';
import { t } from '../../../../../localization';
import { CustomModal, CustomModalDeleteCatalog } from '../../CustomModal';
import { CatalogPageHeaderLoader } from '..';
import withAmplitude, { AmplitudeHOCProps } from '../../../../../components/HOC/withAmplutide';
import './CatalogPageHeader.scss';

type CatalogPageHeaderProps = RouteComponentProps<{ voiceId: string; catalogId: string }> &
  AmplitudeHOCProps &
  AppContextType & {
    catalogData: CatalogView;
    voiceData: VoiceView;
    isLoading: boolean;
    fetchData: () => void;
    onClick: (value: boolean) => void;
  };

class State {
  modalOpen: boolean = false;
  hasError: boolean = false;
}

class CatalogPageHeader extends React.Component<CatalogPageHeaderProps, State> {
  static contextType = LibraryContext;
  context!: React.ContextType<typeof LibraryContext>;

  state = new State();

  deleteCatalog = async () => {
    try {
      await this.context.removeCatalog(this.props.catalogData.id);
      this.props.successAlert(t('successDeleteCatalog', this.props.catalogData.title));
      this.props.history.push(`/my/voice/${this.props.match.params.voiceId}/training/new_version`);
      this.toggleModal();
      this.props.logEvent('Voice dataset deleted', {
        page_url: this.props.match.url,
        result: 'success',
        member_role: this.props.voiceData.currentVoiceMemberRole.name,
        samples: this.props.catalogData.samples,
        inProcessingSamples: this.props.catalogData.inProcessingSamples,
        noAudioSamples: this.props.catalogData.noAudioSamples,
        annotationSuccessSamples: this.props.catalogData.annotationSuccessSamples,
        user_id: this.props.id,
      });
    } catch (error) {
      this.props.defaultAlert(error);
      this.props.logEvent('Voice dataset deleted', {
        page_url: this.props.match.url,
        result: 'failed',
        member_role: this.props.voiceData.currentVoiceMemberRole.name,
        samples: this.props.catalogData.samples,
        inProcessingSamples: this.props.catalogData.inProcessingSamples,
        noAudioSamples: this.props.catalogData.noAudioSamples,
        annotationSuccessSamples: this.props.catalogData.annotationSuccessSamples,
        user_id: this.props.id,
      });
    }
  };

  toggleModal = () => {
    this.setState(prevState => ({ ...prevState, modalOpen: !prevState.modalOpen }));
  };

  setError = (value: boolean) => {
    this.setState({ hasError: value });
  };
  render() {
    if (this.props.isLoading) return <CatalogPageHeaderLoader />;
    return (
      <div className='catalog-page__header-wrapper'>
        <div className='catalog-page__header'>
          <p onClick={() => this.props.onClick(true)} data-test-id='catalogPage.title'>
            {this.props.catalogData.title}
          </p>
          <DropdownButton direction='down'>
            <DropdownToggle>
              <Icon name='falChevronDown' size='sm' />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={this.toggleModal} disabled={this.props.catalogData.inProcessingSamples !== 0}>
                {t('delete')}
              </DropdownItem>
            </DropdownMenu>
          </DropdownButton>
          <CustomModal
            isOpen={this.state.modalOpen}
            title={`${t('deleteCatalog')}?`}
            position='center'
            toggle={this.toggleModal}
            modalCard
          >
            <CustomModalDeleteCatalog cancelButtonClick={this.toggleModal} handleModalAction={this.deleteCatalog} />
          </CustomModal>
        </div>
      </div>
    );
  }
}

export default withAmplitude({})(withRouter(withAppContext(CatalogPageHeader)));
