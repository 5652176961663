import React, { createRef } from 'react';
import { IconButton } from '@just-ai/just-ui';
import InputWrapper from './InputWrapper';
import { t } from '../../../../../localization';

export class SampleCommentInput extends InputWrapper {
  textareaRef = createRef<HTMLTextAreaElement>();

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const numberOfLines = (event.target.value.match(/\n/g) || []).length + 1;
    if (numberOfLines > 2) return;
    this.setState({ value: event.target.value });
  };

  componentDidMount() {
    this.setState({ value: this.props.defaultValue });
    if (!this.textareaRef.current) return;
    this.textareaRef.current.style.height = this.textareaRef.current?.scrollHeight + 1 + 'px';
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
    if (
      this.textareaRef.current &&
      parseInt(this.textareaRef.current.style.height, 10) !== this.textareaRef.current?.scrollHeight &&
      this.state.value !== prevState.value
    ) {
      this.textareaRef.current.style.height = 'auto';
      this.textareaRef.current.style.height = this.textareaRef.current.scrollHeight + 1 + 'px';
    }
  }
  render() {
    return (
      <div className='catalog-page__sample-description'>
        <div>
          <textarea
            style={{ height: '25px' }}
            placeholder={`${t('addDescription')}...`}
            name='sampleComment'
            maxLength={150}
            onChange={this.handleChange}
            onKeyPress={(event: React.KeyboardEvent<HTMLTextAreaElement>) =>
              event.key === 'Enter' && event.preventDefault()
            }
            value={this.state.value}
            onBlur={() => this.throttledAction('save')}
            ref={this.textareaRef}
            data-test-id='samplePage.sampleDescription'
          />
        </div>

        <div className='catalog-page__sample-description-buttons'>
          <IconButton
            flat
            name='falCheck'
            color='primary'
            onClick={event => {
              event.currentTarget.blur();
              this.throttledAction('save');
            }}
          />
          <IconButton
            flat
            name='falTimes'
            color='primary'
            onClick={event => {
              event.currentTarget.blur();
              this.throttledAction('reset');
            }}
          />
        </div>
      </div>
    );
  }
}
