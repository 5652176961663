import React from 'react';
import { t } from '../../../../../localization';

export const NoMediaPermission = () => {
  return (
    <div className='catalog-page__sample-no-permission-container'>
      <div className='catalog-page__sample-no-permission'>
        <h4>{t('noMediaPermission')}</h4>
        <p>{t('noMediaPermissionDesc')}</p>
      </div>
    </div>
  );
};
