import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '@just-ai/just-ui';
import { AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { useAmplitude } from 'react-amplitude-hooks';
import Recaptcha from 'react-google-recaptcha';

import { PublishedVoiceView } from '../../../../api/facade/client/api';
import { DragSlider, SliderControls, SliderItem, SliderLastItem, PurposeItem } from '../../components/LandingPage';
import { CustomModal, RequestSuccessModal } from '../../components/CustomModal';
import DialogSynth from '../../components/LandingPage/DialogSynth';
import { useAppContext } from '../../../../AppContext';
import { useLibraryContext } from '../../context/LibraryContext';
import { useCaptcha } from '../../../../utils/hooks';
import { ProjectsPlaceholder } from '../../components/MyPage';
import { baseAuthPath } from '../../constants';
import localize, { t } from '../../../../localization';
import { landingPageLocalizaion } from '../../../../localization/decomposed/landingPage.loc';
import classNames from 'classnames';

import './LandingPage.scss';
const variants = {
  enter: (direction: number) => {
    return {
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      opacity: 0,
    };
  },
};

localize.addTranslations(landingPageLocalizaion);

const LandingPage = () => {
  const [voices, setVoices] = useState<PublishedVoiceView[]>([]);
  const [dialogVoices, setDialogVoices] = useState<PublishedVoiceView[]>([]);
  const [[page, direction], setPage] = useState([0, 0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playingId, setPlayingId] = useState<number | null>(null);
  const { logEvent } = useAmplitude();
  const { recaptchaInstance, executeCaptcha } = useCaptcha();
  const { getPublishedVoices } = useLibraryContext();
  const history = useHistory();
  const match = useRouteMatch();
  const dialogRef = useRef<HTMLDivElement>(null);
  const landingBreakpoint940 = useMediaQuery({ query: `(max-width: 940px)` });
  const landingBreakpoint685 = useMediaQuery({ query: '(max-width: 770px)' });
  const { isLoggedIn, appLoading, id, configData, getUserLanguage, isUSDInstance } = useAppContext();
  const logEventAllowed = useRef(false);

  useEffect(() => {
    logEvent('Page opened', {
      page_type: match.path,
      page_url: match.url,
    });
  }, [logEvent, match.path, match.url]);

  useEffect(() => {
    if (isLoggedIn) history.push('/catalog');
  }, [history, history.location.pathname, id, isLoggedIn, appLoading]);

  const checkS3Image = useCallback(
    async (publishedVoices: PublishedVoiceView[]) => {
      const items: PublishedVoiceView[] = [];
      await Promise.all(
        publishedVoices.map(async voice => {
          try {
            await axios.get(`${configData.baseAvatarPath}/voice/${voice.id}/landing/image.jpeg`);
            items.push(voice);
          } catch (error) {
            return null;
          }
        })
      );
      return items;
    },
    [configData.baseAvatarPath]
  );

  const fetchVoicesWithFilter = useCallback(async () => {
    const { publishedVoices } = await getPublishedVoices(0, 200, undefined, 'NEWEST', ['supervoice']);
    const voiceWithImage = await checkS3Image(publishedVoices);
    const voicesForDialog = voiceWithImage.filter(voice => !voice.accessRestricted);
    setVoices(voiceWithImage);
    setDialogVoices(voicesForDialog);
  }, [checkS3Image, getPublishedVoices]);

  const handleAllVoicesClick = useCallback(
    (type: 'choose_voice' | 'all_voices' | 'go_to_catalog') => {
      logEvent('landing_button_click', {
        button_name: type,
      });
      history.push('/catalog');
    },
    [history, logEvent]
  );

  const scrollToDialog = useCallback(() => {
    logEvent('landing_button_click', {
      button_name: 'try',
    });
    dialogRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [logEvent]);

  const toggleModal = () => {
    setIsModalOpen(prevState => !prevState);
  };

  const createEmptyProject = async () => {
    if (!isLoggedIn) {
      window.location.href = `${baseAuthPath}${window.location.origin}${history.location.pathname.replace(
        /\/+$/,
        ''
      )}?createProject`;
    }
  };

  useEffect(() => {
    if (!configData.publicBucketUrl) return;

    fetchVoicesWithFilter();
  }, [configData.publicBucketUrl, fetchVoicesWithFilter]);

  const paginate = (newDirection: number) => {
    if (page + newDirection > voices.length - 1) {
      return setPage([0, 1]);
    }
    if (page + newDirection < 0) {
      return setPage([voices.length - 1, -1]);
    }
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    if (logEventAllowed.current) {
      logEvent('lending_sample_switch', {
        action: direction === 1 ? 'next' : 'previous',
        next_voice_id: voices[page + 1]?.id && page < 2 ? voices[page + 1]?.id : 'last',
        previous_voice_id: voices[page - 1]?.id || null,
      });
    } else {
      logEventAllowed.current = true;
    }
  }, [page, direction, logEvent, voices]);

  if (voices.length === 0) return null;
  return (
    <div className='landing-page__wrapper'>
      <div className='landing-page__wrapper-inner'>
        <div className='landing-page__main-block' data-test-id='landingPage.mainBlock'>
          <div className='landing-page__main'>
            <h1>{t('LandingPage:MainTitle')}</h1>
            <div className='landing-page__main-buttons'>
              <Button size='lg' color='info' onClick={scrollToDialog}>
                {t('LandingPage:Button:Try')}
              </Button>
              <Button size='lg' outline color='primary' onClick={() => handleAllVoicesClick('choose_voice')}>
                {`${t('LandingPage:Button:SelectVoice')}`}
              </Button>
            </div>
          </div>
          {!landingBreakpoint940 && (
            <div className='landing-page__slider'>
              <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
                {page === 3 ? (
                  <SliderLastItem
                    variants={variants}
                    handleAllVoicesClick={() => handleAllVoicesClick('all_voices')}
                    direction={direction}
                    motionActive
                  />
                ) : (
                  <SliderItem
                    imageSrc={`${configData.baseAvatarPath}/voice/${voices[page].id}/landing/image.jpeg`}
                    key={page}
                    direction={direction}
                    variants={variants}
                  />
                )}
              </AnimatePresence>
              <SliderControls
                key={voices[page].id}
                voice={voices[page]}
                page={page}
                paginate={paginate}
                setPlayingId={setPlayingId}
                playingId={voices[page].id}
              />
            </div>
          )}
        </div>
        {landingBreakpoint940 && (
          <div className='landing-page__mobile-slider'>
            <DragSlider>
              {voices.map((voice, index) => {
                if (index < 3) {
                  return (
                    <div className='landing-page__mobile-container' key={voice.id}>
                      <img src={`${configData.baseAvatarPath}/voice/${voice.id}/landing/image.jpeg`} alt='' />
                      <SliderControls
                        voice={voice}
                        page={index}
                        paginate={paginate}
                        setPlayingId={setPlayingId}
                        playingId={playingId}
                      />
                    </div>
                  );
                }
                if (index === 3) {
                  return (
                    <SliderLastItem
                      variants={variants}
                      direction={direction}
                      handleAllVoicesClick={() => handleAllVoicesClick('all_voices')}
                      motionActive={false}
                      key={index}
                    />
                  );
                }
                return null;
              })}
            </DragSlider>
          </div>
        )}
        <div className='landing-page__purpose'>
          {new Array(3).fill('_').map((item, index) => (
            <PurposeItem purpose={index} key={index} />
          ))}
        </div>
      </div>
      <div className='landing-page__dialog' ref={dialogRef}>
        <DialogSynth
          captchaEnabled={configData?.captcha?.enabled}
          executeCaptcha={executeCaptcha}
          availableSpeakers={dialogVoices}
        />
        {configData?.captcha?.siteKey && configData?.captcha?.enabled && (
          <Recaptcha
            data-test-id='LandingPage.recaptcha'
            ref={recaptchaInstance}
            sitekey={configData?.captcha?.siteKey || '6Lek0PwUAAAAAFuLtQW3TnEBCddnkmV95IdGY6FE'}
            className='captcha'
            size='invisible'
            badge='bottomleft'
            hl={getUserLanguage().substr(0, 2).toUpperCase()}
          />
        )}
      </div>
      <div className='landing-page__projects'>
        <ProjectsPlaceholder type='landing' createEmptyProject={createEmptyProject} />
      </div>
      <div className='landing-page__advantages'>
        <div className='landing-page__advantages-title'>
          {landingBreakpoint685 ? (
            <>
              <div>
                {isUSDInstance && <h2>tovie</h2>}
                {!isUSDInstance && <h2>aimy &mdash;</h2>}
                <h2>voice</h2>
              </div>
              <p>{t('LandingPage:Advantages:SubTitle')}</p>
            </>
          ) : (
            <>
              <div>
                {isUSDInstance && <h2>tovie </h2>}
                {!isUSDInstance && <h2>aimy &mdash; </h2>}
                <div>
                  <p>{t('LandingPage:Advantages:SubTitle')}</p>
                </div>
              </div>
              <h2>voice</h2>
            </>
          )}
        </div>
        <div className='landing-page__advantages-wrapper'>
          <div className={classNames('landing-page__advantages-item', { 'usd-images': getUserLanguage() === 'eng' })}>
            <h3>{t('LandingPage:Advantages:VoiceSelection')}</h3>
            <p>{t('LandingPage:Advantages:VoiceSelectionDescription')}</p>
          </div>
          <div className='landing-page__advantages-item'>
            <h3>{t('LandingPage:Advantages:QuickStart')}</h3>
            <p>{t('LandingPage:Advantages:QuickStartDescription')}</p>
          </div>
          <div className='landing-page__advantages-item'>
            <h3>{t('LandingPage:Advantages:DifferentTasks')}</h3>
            <p>{t('LandingPage:Advantages:DifferentTasksDescription')}</p>
          </div>
          <div className='landing-page__advantages-item'>
            <h3>{t('LandingPage:Advantages:Cheaper')}</h3>
            <p>{t('LandingPage:Advantages:CheaperDescription')}</p>
          </div>
        </div>
        <Button color='info' onClick={() => handleAllVoicesClick('go_to_catalog')}>
          {t('LandingPage:Buton:GoToCatalog')}
        </Button>
      </div>
      <CustomModal customClassName='success-request-info' isOpen={isModalOpen} toggle={toggleModal} position='center'>
        <RequestSuccessModal handleModalAction={toggleModal} />
      </CustomModal>
    </div>
  );
};

export default LandingPage;
