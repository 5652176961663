import {
  UserApi,
  ConfigControllerApi,
  ApplicationFormsEndpointApi,
  DedicatedServerRequest,
  LicensedVoiceRequest,
  VoiceCreationRequest,
} from '../api/facade/client/api';
import { PackageEndpointApi, WalletEndpointApi, BalanceHistoryControllerApi } from '../api/supervisor/client';
import { axiosWrapped } from '../pipes/functions';

export type SlackRequestTypes = 'access' | 'create' | 'server';

export default class AccountService {
  static readonly BASE_PATH = '';
  private accountId: number | undefined;
  private UserApi: UserApi;
  private WalletApi: WalletEndpointApi;
  private PackagesApi: PackageEndpointApi;
  private ConfigControllerApi: ConfigControllerApi;
  private ApplicationFormsApi: ApplicationFormsEndpointApi;
  private BalanceHistoryControllerApi: BalanceHistoryControllerApi;

  constructor(accountId: number) {
    this.accountId = accountId;
    this.UserApi = new UserApi({}, AccountService.BASE_PATH, axiosWrapped);
    this.WalletApi = new WalletEndpointApi({}, AccountService.BASE_PATH, axiosWrapped);
    this.PackagesApi = new PackageEndpointApi({}, AccountService.BASE_PATH, axiosWrapped);
    this.ConfigControllerApi = new ConfigControllerApi({}, AccountService.BASE_PATH, axiosWrapped);
    this.ApplicationFormsApi = new ApplicationFormsEndpointApi({}, AccountService.BASE_PATH, axiosWrapped);
    this.BalanceHistoryControllerApi = new BalanceHistoryControllerApi({}, AccountService.BASE_PATH, axiosWrapped);
  }

  getConfig = async () => {
    try {
      const { data: configData } = await this.ConfigControllerApi.config();
      return configData;
    } catch (error) {
      console.error('Error getting config data', error);
      throw error;
    }
  };

  getCurrentUser = async () => {
    try {
      const { data: accountData } = await this.UserApi.currentUser();
      return accountData;
    } catch (error) {
      console.error('Error getting account data', error);
      throw error;
    }
  };

  getWallet = async () => {
    if (!this.accountId) return;
    try {
      const { data: walletData } = await this.WalletApi.getWallet();
      return walletData;
    } catch (error) {
      console.error('Error getting wallet data', error);
      throw error;
    }
  };

  getPackages = async () => {
    try {
      const { data: packages } = await this.PackagesApi.getPackages();
      return packages;
    } catch (error) {
      console.error('Error getting packages data', error);
      throw error;
    }
  };

  buyPackage = async (packageName: string) => {
    const { data: packages } = await this.PackagesApi.buyPackage(packageName);
    return packages;
  };

  getBalanceHistory = async (page: number, pageSize: number) => {
    try {
      const { data, headers } = await this.BalanceHistoryControllerApi.balanceHistory(page, pageSize);
      return { data, count: parseInt(headers['content-range'] || '') || 0 };
    } catch (error) {
      console.error('Error getting balance history', error);
      throw error;
    }
  };

  sendSlackRequest = async ({
    type,
    recaptchaResponse,
    requestBody,
  }: {
    type: SlackRequestTypes;
    recaptchaResponse: string;
    requestBody: DedicatedServerRequest | LicensedVoiceRequest | VoiceCreationRequest;
  }) => {
    function isLicensedVoiceRequest(
      requestBody: DedicatedServerRequest | LicensedVoiceRequest | VoiceCreationRequest
    ): requestBody is LicensedVoiceRequest {
      return (requestBody as LicensedVoiceRequest).voiceName !== undefined;
    }

    switch (type) {
      case 'server':
        await this.ApplicationFormsApi.requestDedicatedServerANsYnAY(requestBody, recaptchaResponse);
        break;
      case 'access':
        if (isLicensedVoiceRequest(requestBody)) {
          await this.ApplicationFormsApi.requestLicencedVoice(requestBody, recaptchaResponse);
        }
        break;
      case 'create':
        await this.ApplicationFormsApi.requestVoiceCreationANsYnAY(requestBody, recaptchaResponse);
        break;
      default:
        throw new Error('Request type not supported');
    }
  };
}

const Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  // public method for encoding
  encode: function (input: string) {
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    } // Whend

    return output;
  }, // End Function encode

  // public method for decoding
  decode: function (input: string) {
    let output = '';
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;

    input = input.replace(/[^A-Za-z0-9\\+\\/]/g, '');
    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output += String.fromCharCode(chr1);

      if (enc3 !== 64) {
        output += String.fromCharCode(chr2);
      }

      if (enc4 !== 64) {
        output += String.fromCharCode(chr3);
      }
    } // Whend

    output = Base64._utf8_decode(output);

    return output;
  }, // End Function decode

  // private method for UTF-8 encoding
  _utf8_encode: function (string: string) {
    let utftext = '';
    string = string.replace(/\r\n/g, '\n');

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    } // Next n

    return utftext;
  }, // End Function _utf8_encode

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext: string) {
    let string = '';
    let i = 0;
    let c = 0,
      c2 = 0,
      c3 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    } // Whend

    return string;
  }, // End Function _utf8_decode
};
