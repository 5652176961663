import localization, { t } from '../localization';

export const CONNECTION_ABORTED_CODE = 'ECONNABORTED';
export const UNKNOWN_ERROR_MESSAGE_CODE = 'UnknownError';
export const NO_FUNDS_ERROR_CODE = 'wallet.balance.insufficient.funds';

export const getErrorCodeFromReason = (reason: any): string => {
  if (typeof reason === 'string') return reason;
  if (typeof reason !== 'object' || reason === null) return UNKNOWN_ERROR_MESSAGE_CODE;
  if (reason.code === CONNECTION_ABORTED_CODE) return CONNECTION_ABORTED_CODE;
  if (typeof reason?.response?.data?.error === 'string') return reason.response.data.error;
  if (typeof reason?.response?.data?.error?.errorCode === 'string') return reason.response.data.error.errorCode;
  return UNKNOWN_ERROR_MESSAGE_CODE;
};

export const getErrorMessageFromReason = (
  reason: any,
  translate?: (key: string) => string,
  fallback?: string
): string => {
  if (!translate) translate = t;
  if (!translate) return '';
  const errorCode = getErrorCodeFromReason(reason);
  let errorMessage = reason?.response?.data?.message;
  if (!errorMessage) errorMessage = reason?.response?.data?.error?.errorMessage;
  if (errorCode === UNKNOWN_ERROR_MESSAGE_CODE) return reason.message;
  if (!localization.checkExistKey(errorCode) && !fallback) return errorMessage;
  return localization.checkExistKey(errorCode) ? translate(errorCode) : fallback || '';
  // if (reason?.response?.data?.errors && reason?.response?.data?.errors.length > 0 && !errorMessage) {
  //   if (!errorMessage) {
  //     errorMessage = '';
  //   }
  //   reason.response.data.errors.forEach((error: string) => {
  //     if (typeof error === 'string') {
  //       errorMessage += error + '</br>';
  //     }
  //   });
  // }
  // if (errorCode || errorMessage) {
  //   if (errorMessage && errorCode === UNKNOWN_ERROR_MESSAGE_CODE) return errorMessage;
  //   if (isExistKey(namespaced(errorCode))) return translate(namespaced(errorCode));
  //   return errorMessage || errorCode || translate(namespaced(UNKNOWN_ERROR_MESSAGE_CODE));
  // }
  // return translate(namespaced(UNKNOWN_ERROR_MESSAGE_CODE));
};
