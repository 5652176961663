import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  DropdownButton,
  Icon,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Tooltip,
} from '@just-ai/just-ui';
import { ProgressBar } from '@just-ai/just-ui/dist/AudioPlayer/ProgressBar';
import cn from 'classnames';

import { humanizeDuration } from '../../../../utils';
import { FULL_PROJECT_ID, PlayingModes, usePlayer } from '../../context/PlayerContext';
import { useReplicas } from '../../context/ProjectDataContex';
import { useProjectContext } from '../../context/ProjectsContext';
import { t } from '../../../../localization';
import useDefaultAlert from '../../../../utils/useAlert';

import classes from './ProjectPlayer.module.scss';

export const ProjectPlayer = () => {
  const audioRefSpeaker = useRef<HTMLAudioElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [volume, setVolume] = useState(100);
  const {
    setPlayerRef,
    getFullProject,
    downloadProject,
    playingMode,
    setPlayingMode,
    getSynthBufferById,
    initialCheck,
    projectLoading,
    setPlayingId,
    projectDownloading,
    playingId,
    findReplicaIdBySynthBuffer,
    setReplicaSynthBuffer,
    setPlayerStore,
    downloadFile,
    historyPlaying,
  } = usePlayer();
  const { getSelectedReplica, replicas, updateReplica, fetchReplicas, selectedReplicaId } = useReplicas();
  const { synthesizeReplica } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();

  useEffect(() => {
    initialCheck();
  }, [initialCheck]);

  useEffect(() => {
    if (!audioRefSpeaker.current || playingId.includes('history')) return;
    if (playingId) {
      audioRefSpeaker.current.play();
    }
    if (!playingId) {
      audioRefSpeaker.current.pause();
    }
  }, [playingId]);

  useEffect(() => {
    if (!audioRefSpeaker.current) return;
    setPlayerRef(audioRefSpeaker);
    audioRefSpeaker.current.volume = volume / 100;
  }, [setPlayerRef, volume]);

  const handleDuration = useCallback(() => {
    try {
      if (!audioRefSpeaker.current) return;
      if (audioRefSpeaker.current.duration < Infinity) {
        setCurrentDuration(Math.floor(audioRefSpeaker.current.duration));
        audioRefSpeaker.current.pause();
        audioRefSpeaker.current.currentTime = 0;
        audioRefSpeaker.current.muted = false;
        audioRefSpeaker.current.removeEventListener('durationchange', handleDuration, false);
      }
    } catch (error) {
      console.error('error in duration change in sampleplayer', error);
    }
  }, []);

  const handleMetadata = useCallback(() => {
    try {
      if (!audioRefSpeaker.current) return;
      const duration = Math.floor(audioRefSpeaker.current ? audioRefSpeaker.current.duration : 0);
      if (duration < Infinity) {
        setCurrentDuration(duration);
      } else {
        audioRefSpeaker.current.addEventListener('durationchange', handleDuration, false);
        audioRefSpeaker.current.currentTime = 24 * 60 * 60;
        audioRefSpeaker.current.muted = true;
        audioRefSpeaker.current.play();
      }
    } catch (error) {
      console.error('error in handlemeta in sampleplayer', error);
    }
  }, [audioRefSpeaker, handleDuration]);

  const handleTimeUpdate = useCallback(() => {
    if (audioRefSpeaker.current && audioRefSpeaker.current.currentTime < 24 * 60 * 60) {
      setCurrentTime(Math.floor(audioRefSpeaker.current.currentTime));
    }
  }, [audioRefSpeaker]);

  const handlePlayend = useCallback(() => {
    setPlayingId('');
    setCurrentTime(0);
    if (audioRefSpeaker && audioRefSpeaker.current) audioRefSpeaker.current.currentTime = 0;
  }, [setPlayingId]);

  const handleVolume = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement>) => {
      if (!audioRefSpeaker.current) return;
      const newVolume = Number((event.target as HTMLInputElement).value);
      setVolume(newVolume);
      audioRefSpeaker.current.volume = newVolume / 100;
    },
    [setVolume]
  );
  const reset = useCallback(() => {
    if (!audioRefSpeaker.current) return;
    audioRefSpeaker.current.removeAttribute('src');
    audioRefSpeaker.current.currentTime = 0;
    setCurrentDuration(0);
    setCurrentTime(0);
  }, []);

  const handleModeSwitch = useCallback(
    (value: PlayingModes) => {
      if (value !== playingMode && audioRefSpeaker.current) {
        reset();
      }
      if (!audioRefSpeaker.current?.paused && audioRefSpeaker.current) {
        setPlayerStore({ playingId: '' });
      }
      setPlayingMode(value);
    },
    [playingMode, reset, setPlayerStore, setPlayingMode]
  );

  const isDownloadDisabled = useCallback(() => {
    if (!audioRefSpeaker.current) return true;
    const replicaId = findReplicaIdBySynthBuffer(audioRefSpeaker.current.src);
    if (replicaId) return false;
    return true;
  }, [findReplicaIdBySynthBuffer]);

  const isReplicaNoVoiceId = useCallback(() => {
    if (playingMode === 'ALL') return false;
    if (replicas.length === 0) return true;
    const selectedReplica = getSelectedReplica();
    if (selectedReplicaId !== 'placeholder' && !selectedReplica?.voiceId) return true;
    if ((!selectedReplicaId || selectedReplicaId === 'placeholder') && !replicas[0].voiceId) return true;
    return false;
  }, [getSelectedReplica, playingMode, replicas, selectedReplicaId]);

  const handleDownload = useCallback(() => {
    if (!audioRefSpeaker.current) return;
    downloadFile(audioRefSpeaker.current.src);
  }, [downloadFile]);

  const checkBuffer = useCallback((currentBufferUrl: string) => {
    if (!audioRefSpeaker.current) return;
    if (currentBufferUrl !== audioRefSpeaker.current.src) {
      audioRefSpeaker.current.src = currentBufferUrl;
    }
  }, []);

  const audioPlay = useCallback(
    async (id: string) => {
      if (!audioRefSpeaker.current) return;
      const replicaBuffer = getSynthBufferById(id);
      if (replicaBuffer) {
        checkBuffer(replicaBuffer.synthUrl);
        setPlayerStore({ projectLoading: '', playingId: id });
      } else {
        try {
          reset();
          setPlayerStore({ projectLoading: id });
          const audioUrl = id === FULL_PROJECT_ID ? await getFullProject('MERGED') : await synthesizeReplica(id);
          id === FULL_PROJECT_ID ? await fetchReplicas(0, true) : await updateReplica(id);
          if (id !== FULL_PROJECT_ID) {
            setReplicaSynthBuffer({ id: id, url: audioUrl });
          }
          audioRefSpeaker.current.src = audioUrl;
          setPlayerStore({ projectLoading: '', playingId: id });
        } catch (error) {
          defaultErrorAlert(error);
          setPlayerStore({ projectLoading: '', playingId: '' });
          id !== FULL_PROJECT_ID && audioRefSpeaker.current.removeAttribute('src');
        }
      }
    },
    [
      checkBuffer,
      defaultErrorAlert,
      fetchReplicas,
      getFullProject,
      getSynthBufferById,
      reset,
      setPlayerStore,
      setReplicaSynthBuffer,
      synthesizeReplica,
      updateReplica,
    ]
  );

  const handlePlayer = useCallback(async () => {
    if (!audioRefSpeaker.current) return;

    const selectedReplica = getSelectedReplica();
    if (!audioRefSpeaker.current.paused) {
      setPlayerStore({ playingId: '' });
      return;
    }
    if (historyPlaying) {
      setPlayerStore({ historyPlaying: false });
    }
    if (playingMode === 'SINGLE') {
      const replicaId = findReplicaIdBySynthBuffer(audioRefSpeaker.current.src);

      if (!selectedReplica && replicaId) {
        setPlayerStore({ playingId: replicaId });
        return;
      }
      if (!selectedReplica && !replicaId && replicas.length > 0) {
        const firstReplicaId = replicas[0].id;
        audioPlay(firstReplicaId);
        return;
      }

      if (selectedReplica) {
        audioPlay(selectedReplica.id);
      }
    } else {
      audioPlay(FULL_PROJECT_ID);
    }
  }, [
    audioPlay,
    findReplicaIdBySynthBuffer,
    getSelectedReplica,
    historyPlaying,
    playingMode,
    replicas,
    setPlayerStore,
  ]);

  return (
    <div className={cn('justui-audio-player', 'project-page__player-wrapper', classes.Player)}>
      <audio
        onLoadedMetadata={handleMetadata}
        onTimeUpdate={handleTimeUpdate}
        ref={audioRefSpeaker}
        controls={false}
        onEnded={handlePlayend}
      />
      <div className={classes.Player__content}>
        <div className={classes.Player__play}>
          <div className={classes['Player__play__button-container']}>
            <Button
              className={classes.Player__play__button}
              id='playerPlay'
              onClick={handlePlayer}
              disabled={projectDownloading || Boolean(projectLoading) || isReplicaNoVoiceId()}
            >
              <Icon name={playingId ? 'faPause' : 'faPlay'} color='primary' size='sm' />
            </Button>
            <Tooltip target='playerPlay' placement='top'>
              {t('ProjectPage:Player:PlayTooltip')}
            </Tooltip>
          </div>
          <div className={classes['Player__play__select-container']}>
            <DropdownButton className={classes.Player__play__select__dropdown} direction='up'>
              <DropdownToggle className={classes['Player__play__select__dropdown-toggle']}>
                <Icon name='faCaretDown' className={classes['Player__play__select-arrow']} size='sm' />
                <p className={cn(classes['Player__play__select-text'], 'tp-5')}>
                  {playingMode === 'ALL'
                    ? t('ProjectPage:Player:DropdownButton:All')
                    : t('ProjectPage:Player:DropdownButton:Single')}
                </p>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  iconLeft='faCheck'
                  className={cn(classes['Player__play__select-item'], {
                    [classes.selected]: playingMode === 'ALL',
                  })}
                  onClick={() => handleModeSwitch('ALL')}
                >
                  {t('ProjectPage:Player:DropdownItem:All')}
                </DropdownItem>
                <DropdownItem
                  iconLeft='faCheck'
                  className={cn(classes['Player__play__select-item'], { [classes.selected]: playingMode === 'SINGLE' })}
                  onClick={() => handleModeSwitch('SINGLE')}
                >
                  {t('ProjectPage:Player:DropdownItem:Single')}
                </DropdownItem>
              </DropdownMenu>
            </DropdownButton>
          </div>
        </div>
        <div className={cn(classes.Player__progress, { [classes.loading]: projectLoading }, 'justui-audio-player')}>
          <p className='tp-3'>{humanizeDuration(currentTime * 1000)}</p>
          <ProgressBar
            ShowFilledProgress
            audio={audioRefSpeaker.current as HTMLAudioElement}
            progressUpdateInterval={10}
          />
          <p className='tp-3'>{humanizeDuration(currentDuration * 1000)}</p>
        </div>
        <div className={classes.Player__volume}>
          <Icon name='faVolumeUp' className={classes.Player__volume__icon} />
          <input
            onChange={handleVolume}
            onInput={handleVolume}
            type='range'
            min={0}
            max={100}
            step={1}
            value={volume}
            className={classes.Player__volume__input}
            style={{
              background: `linear-gradient(90deg, var(--gray-500) ${volume}%, var(--gray-200) ${volume}%)`,
            }}
          />
        </div>
        <div className={classes.Player__download}>
          <DropdownButton className={classes.Player__download__dropdown} direction='up'>
            <DropdownToggle
              className={cn(classes['Player__download__dropdown-button'], { [classes.download]: projectDownloading })}
              disabled={projectDownloading}
            >
              <p>{t('download')}</p>
              {projectDownloading ? <Spinner size='sm' /> : <Icon name='farChevronDown' size='sm' />}
            </DropdownToggle>
            <DropdownMenu
              className={cn(classes['Player__download__dropdown-menu'], {
                [classes['two-items']]: playingMode !== 'SINGLE',
              })}
            >
              <DropdownItem onClick={() => downloadProject('MERGED')}>
                {`${t('ProjectPage:Player:Download:SingleWav')} (*.wav)`}
              </DropdownItem>
              <DropdownItem onClick={() => downloadProject('ZIP')}>
                {`${t('ProjectPage:Player:Download:Zip')} (*.zip)`}
              </DropdownItem>

              {playingMode === 'SINGLE' && (
                <DropdownItem
                  className={cn(classes['Player__download__dropdown-item'], {
                    [classes.disabled]: isDownloadDisabled(),
                  })}
                  disabled={isDownloadDisabled() || isReplicaNoVoiceId()}
                  onClick={handleDownload}
                >
                  {`${t('ProjectPage:Player:Download:Wav')} (*.wav)`}
                </DropdownItem>
              )}
            </DropdownMenu>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};
