import React, { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { CatalogView, VoiceView } from '../../../../api/facade/client';
import { t } from '../../../../localization';
import { calendarStrings } from '../../constants';
import CatalogProgressBar from './CatalogProgressBar';
import { Icon, Tooltip, Spinner } from '@just-ai/just-ui';
import { CustomModal, CustomModalDeleteCatalog } from '../CustomModal';
import useAlert from '../../../../utils/useAlert';
import { useLibraryContext } from '../../context/LibraryContext';
import { humanizeDuration } from '../../../../utils';
import classNames from 'classnames';
import { useAmplitude } from 'react-amplitude-hooks';
import { useAppContext } from '../../../../AppContext';

type CatalogTableRowProps = {
  catalog: CatalogView;
  deletingIds: number[];
  voice: VoiceView;
  fetchCatalogs: () => Promise<void>;
  setDeletingIds: (value: number[]) => void;
};

const TableRow = React.memo(function TableRow({
  catalog,
  fetchCatalogs,
  voice,
  deletingIds,
  setDeletingIds,
}: CatalogTableRowProps) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { successAlert, defaultErrorAlert } = useAlert();
  const { removeCatalog } = useLibraryContext();
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();
  const { id } = useAppContext();

  const handlePush = useCallback(() => {
    history.push(`${url}/${catalog.id}`);
  }, [url, catalog.id, history]);

  const deleteStopPropagationWithToggle = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    toggleDeleteCatalogModal();
  }, []);

  const toggleDeleteCatalogModal = () => {
    setIsModalOpen(prevState => !prevState);
  };

  const handleModalAction = useCallback(async () => {
    try {
      toggleDeleteCatalogModal();
      setDeletingIds([...deletingIds, catalog.id]);
      await removeCatalog(catalog.id);
      await fetchCatalogs();
      logEvent('Voice dataset deleted', {
        page_url: match.url,
        result: 'success',
        member_role: voice.currentVoiceMemberRole.name,
        samples: catalog.samples,
        inProcessingSamples: catalog.inProcessingSamples,
        noAudioSamples: catalog.noAudioSamples,
        annotationSuccessSamples: catalog.annotationSuccessSamples,
        user_id: id,
      });
      successAlert(t('successDeleteCatalog', catalog.title));
    } catch (error) {
      logEvent('Voice dataset deleted', {
        page_url: match.url,
        result: 'failed',
        member_role: voice.currentVoiceMemberRole.name,
        samples: catalog.samples,
        inProcessingSamples: catalog.inProcessingSamples,
        noAudioSamples: catalog.noAudioSamples,
        annotationSuccessSamples: catalog.annotationSuccessSamples,
        user_id: id,
      });
      defaultErrorAlert(error);
    }
  }, [
    catalog,
    defaultErrorAlert,
    deletingIds,
    fetchCatalogs,
    id,
    logEvent,
    match.url,
    removeCatalog,
    setDeletingIds,
    successAlert,
    voice,
  ]);
  return (
    <div className='table__row-wrapper'>
      {deletingIds.includes(catalog.id) && <Spinner color='primary' />}
      <div
        className={classNames('table__row', { deleting: deletingIds.includes(catalog.id) })}
        onClick={handlePush}
        data-test-id='trainingPage.catalogRow'
      >
        <p>{catalog.title}</p>
        <p>{moment(catalog.createAt).calendar(calendarStrings)}</p>
        <p className='duration-column'>{humanizeDuration(catalog.annotatedDuration * 1000)}</p>
        <div className='statuses-column'>
          {catalog.samples > 0 ? (
            <CatalogProgressBar
              total={catalog.samples}
              sucessful={catalog.annotationSuccessSamples}
              failed={catalog.errorCount || 0}
              processing={catalog.inProcessingSamples}
              noAudio={catalog.noAudioSamples}
              catalogId={catalog.id}
            />
          ) : (
            <span className='text-secondary'>{t('noMaterialsError')}</span>
          )}
        </div>
        <div className='table__row-delete'>
          {catalog.inProcessingSamples === 0 && (
            <div onClick={deleteStopPropagationWithToggle} id={`delete-catalog-${catalog.id}`}>
              <Icon name='falTimes' />
              <Tooltip placement='top' target={`delete-catalog-${catalog.id}`}>
                {t('deleteCatalog')}
              </Tooltip>
            </div>
          )}
        </div>
        <CustomModal
          isOpen={isModalOpen}
          title={`${t('deleteCatalog')}?`}
          position='center'
          toggle={toggleDeleteCatalogModal}
          modalCard
        >
          <CustomModalDeleteCatalog
            cancelButtonClick={toggleDeleteCatalogModal}
            handleModalAction={handleModalAction}
          />
        </CustomModal>
      </div>
    </div>
  );
});

export default TableRow;
