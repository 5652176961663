import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { SCREEN_WIDTH_MD, SCREEN_WIDTH_TABLET } from '../../../../Header/constants';
import { VoiceAvatar } from '../../VoiceAvatar';

import './ProjectVoices.scss';

export const ProjectVoices = ({ voicesId = [] }: { voicesId: number[] | undefined }) => {
  const mdScreenDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_MD}px)` });
  const tabletDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });

  const getDisplayedVoices = () => {
    if (tabletDown) return 8;
    if (mdScreenDown) return 5;
    return 7;
  };
  return (
    <>
      {voicesId.map((voiceId, index) => {
        if (index < getDisplayedVoices()) return <VoiceAvatar size='small' voiceId={voiceId} />;
        return null;
      })}
      {voicesId.length > getDisplayedVoices() && (
        <div className='project-voices__more'>{`+${voicesId.slice(getDisplayedVoices(), voicesId.length).length}`}</div>
      )}
    </>
  );
};
