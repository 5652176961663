import React, { useCallback, useMemo, useState } from 'react';
import { Tabs, TabValue, Icon } from '@just-ai/just-ui';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { t } from '../../../../localization';
import OfflineModeBlock from '../OfflineModeIndicator';
import { useAppContext } from '../../../../AppContext';
import { useProjectContext } from '../../../Projects/context/ProjectsContext';
import { useReplicas } from '../../../Projects/context/ProjectDataContex';

import './Tabs.scss';

type tabsType = {
  name: TabValue['name'];
  value: string;
  key: string;
};

function HeaderTabs({
  isLoggedIn,
  darkTheme,
  isProjectPage,
  onClick,
}: {
  isLoggedIn: boolean;
  darkTheme: boolean;
  isProjectPage: boolean;
  onClick: () => void;
}) {
  const { pathname } = useLocation();
  const { isUSDInstance } = useAppContext();
  const { offlineMode, loading } = useProjectContext();
  const { isDirty } = useReplicas();

  const handleMyPageClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      onClick();
    },
    [onClick]
  );

  const tabs = useMemo(() => {
    const tabsArr: tabsType[] = [];
    if (!isLoggedIn) {
      tabsArr.push(
        {
          name: (
            <Link to='/my' onClick={handleMyPageClick} data-test-id='myVoiceLink'>
              {t('Header:Tabs:MyProjects')}
            </Link>
          ),
          value: 'voice',
          key: 'tab-voice',
        },
        {
          name: (
            <Link to='/catalog' data-test-id='nav.catalog'>
              {t('catalog')}
            </Link>
          ),
          value: 'catalog',
          key: 'tab-catalog',
        },
        {
          name: (
            <a href={isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')} target='_blank' rel='noreferrer'>
              <Icon name='falQuestionCircle' size='lg' />
            </a>
          ),
          value: 'help',
          key: 'tab-help',
        }
      );
    } else {
      if (isProjectPage) {
        tabsArr.push(
          {
            name: <OfflineModeBlock loading={loading} isDirty={isDirty} offline={offlineMode} />,
            value: 'offlineMode',
            key: 'offline-block',
          },
          {
            name: (
              <a href={isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')} target='_blank' rel='noreferrer'>
                <Icon name='falQuestionCircle' size='lg' />
              </a>
            ),
            value: 'help',
            key: 'tab-help',
          }
        );
      }
      if (matchPath(pathname, { path: '*/new_version/*' })) {
        tabsArr.push(
          {
            name: (
              <Link to='/my' data-test-id='myVoiceLink'>
                {t('myVoices')}
              </Link>
            ),
            value: 'library',
            key: 'tab-library',
          },
          {
            name: (
              <a
                href={isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}
                target='_blank'
                rel='noreferrer'
                style={{ marginRight: '10px', marginLeft: '-6px' }}
              >
                <Icon name='falQuestionCircle' size='lg' />
              </a>
            ),
            value: 'help',
            key: 'tab-help',
          }
        );
      }
      if (!isProjectPage && !matchPath(pathname, { path: '*/new_version/*' })) {
        tabsArr.push(
          {
            name: (
              <Link to='/my' onClick={handleMyPageClick} data-test-id='myVoiceLink'>
                {t('Header:Tabs:MyProjects')}
              </Link>
            ),
            value: 'voice',
            key: 'tab-voice',
          },
          {
            name: (
              <Link to='/catalog' data-test-id='nav.catalog'>
                {t('catalog')}
              </Link>
            ),
            value: 'catalog',
            key: 'tab-catalog',
          },
          {
            name: (
              <a href={isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')} target='_blank' rel='noreferrer'>
                <Icon name='falQuestionCircle' size='lg' />
              </a>
            ),
            value: 'help',
            key: 'tab-help',
          }
        );
      }
    }
    return tabsArr;
  }, [handleMyPageClick, isDirty, isLoggedIn, isProjectPage, isUSDInstance, loading, offlineMode, pathname]);

  const [activeTab, setActiveTab] = useState('');

  const handleTabChange = (value: string, name?: string) => {
    if (value === 'offlineMode') return;
    setActiveTab(value);
  };
  return (
    <div data-test-id='navBar' className={isLoggedIn ? 'logged-in' : 'not-logged'}>
      <Tabs blackBackground={darkTheme} activeTab={activeTab} onChange={handleTabChange} tabs={tabs} />
    </div>
  );
}

export default HeaderTabs;
