import React, { useCallback } from 'react';
import classNames from 'classnames';
import { formatDateString } from '@just-ai/formatutils';
import { t } from '../../../../localization';
import { ModelView, PublicModelView } from '../../../../api/facade/client';
import { Collapse } from '@just-ai/just-ui';
import { useLibraryContext } from '../../context/LibraryContext';
import { useAmplitude } from 'react-amplitude-hooks';
import { matchPath, useHistory, useRouteMatch } from 'react-router-dom';
import useAlert from '../../../../utils/useAlert';
import { ConnectButton } from './ConnectButton';
import { getErrorCodeFromReason } from '../../../../utils/error';
import { pushToLoginWithState } from '../../../../utils';
import { SCREEN_WIDTH_PHONE } from '../../../Header/constants';
import { useMediaQuery } from 'react-responsive';
import { useAppContext } from '../../../../AppContext';
import { useCaptcha } from '../../../../utils/hooks';
import { SynthBlock } from '../SynthArea/SynthBlock';

type CardProps = {
  version: ModelView | PublicModelView;
  connectedModel: number | undefined;
  fetchData: (open?: boolean) => void;
};
export default function PublishedCard({ version, connectedModel, fetchData }: CardProps) {
  const { isLoggedIn, configData, id, synthesizeDialog } = useAppContext();
  const { updateToken, createToken } = useLibraryContext();
  const { logEvent } = useAmplitude();
  const history = useHistory();
  const { successAlert, defaultErrorAlert } = useAlert();
  const phoneOnly = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const match = useRouteMatch();
  const { executeCaptcha, recaptchaInstance } = useCaptcha();

  const synthVoice = async (text: string): Promise<string> => {
    try {
      const token = configData.captchaEnabled ? await executeCaptcha() : undefined;
      const phraseUrl = await synthesizeDialog([{ voiceId: version.voiceId, modelId: version.id, text }], token);
      logEvent('synthesize', {
        result: 'success',
        page_type: match.path,
        page_url: match.url,
        type: 'published_model',
        user_id: id,
      });
      return phraseUrl || '';
    } catch (error) {
      logEvent('synthesize', {
        result: 'failed',
        page_type: match.path,
        page_url: match.url,
        type: 'published_model',
        user_id: id,
      });
      throw error;
    }
  };
  const handleTokenCreateOrUpdate = useCallback(async () => {
    try {
      if (!isLoggedIn) {
        pushToLoginWithState({ history, clickedVersion: version.id, authPath: configData?.authPath || '' });
      }
      if (connectedModel) {
        await updateToken(version.voiceId, version.id);
        fetchData();
      } else {
        await createToken(version.voiceId, version.id);
        fetchData(true);
      }
      successAlert(`Версия ${version.version} подключена`);
    } catch (error) {
      if (getErrorCodeFromReason(error) !== 'auth.error') {
        defaultErrorAlert(error);
      }
    }
  }, [
    configData?.authPath,
    connectedModel,
    createToken,
    defaultErrorAlert,
    fetchData,
    history,
    isLoggedIn,
    successAlert,
    updateToken,
    version,
  ]);

  const isPublic = useCallback(() => {
    return !!matchPath(history.location.pathname, { path: '/catalog/:id/released' });
  }, [history.location.pathname]);

  if (version.isActual)
    return (
      <div
        className={classNames('published-card__container', { actual: version.isActual })}
        data-test-id='publishedPage.card'
      >
        <div className='published-card__header'>
          <div>
            <h4>{t('versionNumber', version.version)}</h4>
            {version.isActual && <h4>{t('actual')}</h4>}
          </div>
          <ConnectButton
            version={version}
            connectedModel={connectedModel}
            handleClick={handleTokenCreateOrUpdate}
            actual
          />
        </div>

        <div className='published-card__synth-area'>
          {!isPublic() && (
            <SynthBlock
              handler={synthVoice}
              placeholderText={t('enterTextForSynth')}
              recaptchaInstance={recaptchaInstance}
            />
          )}
        </div>

        <div className='published-card__time'>
          <p>{`${t('published')}: ${formatDateString(version.releaseDate, 'ru', 'medium')}`}</p>
          <p>{`${t('trained')}: ${formatDateString(version.trainedAt, 'ru', 'medium')}`}</p>
        </div>
      </div>
    );
  return (
    <Collapse
      className={classNames('published-card__container')}
      tag='div'
      label={
        <>
          <h4>{t('versionNumber', version.version)}</h4>
          <ConnectButton
            phone={phoneOnly}
            version={version}
            connectedModel={connectedModel}
            handleClick={handleTokenCreateOrUpdate}
          />
        </>
      }
    >
      <div className='mobile-button-container'>
        {phoneOnly && connectedModel !== version.id ? (
          <ConnectButton version={version} connectedModel={connectedModel} handleClick={handleTokenCreateOrUpdate} />
        ) : null}
      </div>

      <div className='published-card__synth-area'>
        {!isPublic() && (
          <SynthBlock
            handler={synthVoice}
            placeholderText={t('enterTextForSynth')}
            recaptchaInstance={recaptchaInstance}
          />
        )}
      </div>

      <div className='published-card__time'>
        <p>{`${t('published')}: ${formatDateString(version.releaseDate, 'ru', 'medium')}`}</p>
        <p>{`${t('trained')}: ${formatDateString(version.trainedAt, 'ru', 'medium')}`}</p>
      </div>
    </Collapse>
  );
}
