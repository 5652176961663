import React, { useCallback, useState } from 'react';
import { Button } from '@just-ai/just-ui';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useAmplitude } from 'react-amplitude-hooks';

import { t } from '../../../../localization';
import { VersionCardMemo } from './VersionCard';
import { StepsForCreating } from './StepsForCreating/StepsForCreating';
import { MobileNotification } from './MobileNotification';
import { VoiceModel } from '../../model/Voice';
import { NewVersionView, VoiceView } from '../../../../api/facade/client';
import VersionProgress from '../../../../components/VersionProgress';
import { useLibraryContext } from '../../context/LibraryContext';
import { useAppContext } from '../../../../AppContext';
import { CustomModal, CustomModalConnectSimulated, CustomModalStartTraining } from '../CustomModal';
import useAlert from '../../../../utils/useAlert';
import { SCREEN_WIDTH_MD } from '../../../Header/constants';
import { PermissionsEnum } from '../../constants';

type MajorVersionProps = {
  versionData?: NewVersionView;
  models: VoiceModel[];
  voice: VoiceView;
  isLoading: boolean;
  releasedModelsCount: number;
  updateData: () => void;
};

export default function MajorVersion(props: MajorVersionProps) {
  const { url } = useRouteMatch();
  const { id: voiceId } = useParams<{ id: string }>();
  const { id: userId, permissions } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  const { versionData, models, updateData, isLoading } = props;
  const { trainVoice, releaseVoiceModel, simulateTraining } = useLibraryContext();
  const { logEvent } = useAmplitude();
  const { defaultErrorAlert, successAlert } = useAlert();
  const isDesktop = useMediaQuery({ query: `(min-width: ${SCREEN_WIDTH_MD}px)` });

  const isTrainingAvailable = versionData && versionData.seconds >= versionData.secondsLimit;
  const handleTrainVoice = useCallback(async () => {
    try {
      await trainVoice(+voiceId);
      logEvent('Voice model training started', {
        page_url: url,
        result: 'success',
        member_role: props.voice.currentVoiceMemberRole.name,
      });
      updateData();
      setIsOpen(false);
    } catch (error) {
      defaultErrorAlert(error);
      logEvent('Voice model training started', {
        page_url: url,
        result: 'failed',
        member_role: props.voice.currentVoiceMemberRole.name,
      });
    }
  }, [trainVoice, voiceId, logEvent, url, props.voice.currentVoiceMemberRole.name, updateData, defaultErrorAlert]);

  const handleSimulatedTrain = useCallback(async () => {
    try {
      await simulateTraining(Number(voiceId));
      updateData();
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [simulateTraining, voiceId, updateData, defaultErrorAlert]);

  const handleReleaseVoice = useCallback(
    async (model, modalToggle) => {
      try {
        await releaseVoiceModel(+voiceId, model.id);
        updateData();
        successAlert(
          <div>
            {`${t('versionNumber', model.version)} ${t('published').toLowerCase()}. ${t('moveToSection')} `}
            <Link to={`/my/voice/${voiceId}/released`}>{t('publishedVersions')}</Link>
          </div>
        );
        logEvent('Voice trained model released', {
          page_url: url,
          user_id: userId,
          member_role: props.voice.currentVoiceMemberRole.name,
          result: 'success',
        });
        modalToggle();
      } catch (error) {
        defaultErrorAlert(error);
        logEvent('Voice trained model released', {
          page_url: url,
          user_id: userId,
          member_role: props.voice.currentVoiceMemberRole.name,
          result: 'failed',
        });
      }
    },
    [
      releaseVoiceModel,
      voiceId,
      updateData,
      successAlert,
      logEvent,
      url,
      userId,
      props.voice.currentVoiceMemberRole.name,
      defaultErrorAlert,
    ]
  );

  const toggleModal = useCallback(() => setIsOpen(isOpen => !isOpen), []);
  const toggleConnectModal = useCallback(() => setConnectModal(connectModal => !connectModal), []);
  const releasedVersionExist = props.releasedModelsCount > 0;
  return (
    <>
      {isDesktop && (
        <header className='training__header'>
          <h2>{t('voiceTraining')}</h2>
        </header>
      )}
      <main className='training__content'>
        <div className='training__card'>
          <div className='training__card-description'>
            <h4 data-test-id='trainingPage.versionNumber'>{t('versionNumber', versionData?.version || 1)}</h4>
            <p className='tp-3'>
              {isDesktop
                ? t(`voiceTrainingSub:${isTrainingAvailable ? 'canTrain' : 'noTrain'}`)
                : t('voiceTrainingSub:mobile')}
            </p>
          </div>
          <div className='progress-bar__container'>
            <VersionProgress
              currentValue={versionData ? versionData.seconds : 0}
              maxValue={versionData?.secondsLimit || 36000}
              processing={versionData?.isAnnotationInProcess || false}
            />
            <div className='progress-bar__buttons'>
              {isDesktop ? (
                <>
                  <Link to={`${url}/new_version`}>
                    <Button outline color='secondary' data-test-id='trainingPage.continueCreation'>
                      {t('continueCreation')}
                    </Button>
                  </Link>
                  <Button
                    color='primary'
                    disabled={!isTrainingAvailable}
                    onClick={toggleModal}
                    data-test-id='trainingPage.startTrain'
                  >
                    {t('train')}
                  </Button>
                </>
              ) : (
                <Link to={`${url}/new_version`}>
                  <Button outline color='secondary'>
                    {t('trainingMaterials')}
                  </Button>
                </Link>
              )}
            </div>
            {permissions.includes(PermissionsEnum.VoiceConnect) && (
              <Button
                className='mt-3'
                color='primary'
                onClick={releasedVersionExist ? toggleConnectModal : handleSimulatedTrain}
              >
                {releasedVersionExist ? t('TrainingPage:Connect:SimulatedTrain') : t('TrainingPage:SimulateTrain')}
              </Button>
            )}
          </div>
        </div>
        {!isLoading && models.length > 0 && (
          <div className='training__versions-container'>
            {models.map(model => (
              <VersionCardMemo key={model.id} model={model} actionButtonClick={handleReleaseVoice} />
            ))}
          </div>
        )}
        <StepsForCreating />
        {!isDesktop && <MobileNotification />}
      </main>

      <CustomModal isOpen={isOpen} toggle={toggleModal} title={`${t('startTraining')}?`} position='center'>
        <CustomModalStartTraining handleModalAction={handleTrainVoice} cancelButtonClick={toggleModal} />
      </CustomModal>
      <CustomModal
        isOpen={connectModal}
        toggle={toggleConnectModal}
        title={t('TrainingPage:Connect:SimulatedTrain')}
        position='center'
      >
        <CustomModalConnectSimulated voiceId={Number(voiceId)} toggle={toggleConnectModal} />
      </CustomModal>
    </>
  );
}
