import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { VoiceAvatar } from '../../VoiceAvatar';
import { Voice } from '../../../model/Voice';
import { t } from '../../../../../localization';
import { VoiceLinkGenerator } from './VoiceLinkGenerator';
import { SCREEN_WIDTH_PHONE } from '../../../../Header/constants';
import { languagesMap } from '../../../constants';

import './VoiceCard.scss';

export const VoiceCard = ({ type, voice }: { type: 'usage' | 'manage'; voice: Voice }) => {
  const phoneOnly = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });

  if (!voice) return null;
  return (
    <div className='my-page__card'>
      <div className='my-page__card-photo'>
        <VoiceAvatar size='main' voiceId={voice.id} />
      </div>
      <div className='my-page__card-caption'>
        <div className='my-page__card-info'>
          <h4>{voice.name}</h4>
          <p className='tp-4'>{t(languagesMap[voice.language])}</p>
          <p className='tp-4'>{`${t('you')} ${t(
            `managerRole: ${voice.currentVoiceMemberRole.name}`
          ).toLowerCase()}`}</p>
        </div>
        {!phoneOnly && <VoiceLinkGenerator roleId={voice.currentVoiceMemberRole.id} voiceId={voice.id} />}
      </div>
      {phoneOnly && <VoiceLinkGenerator roleId={voice.currentVoiceMemberRole.id} voiceId={voice.id} />}
    </div>
  );
};
