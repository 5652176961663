import {
  CreateReplicaRequest,
  ProcessProjectReplicasRequest,
  ProjectsControllerApi,
  ReplicasControllerApi,
  UpdateReplicaRequest,
  MarkupSettingsControllerApi,
  PresetsControllerApi,
  UpdatePresetRequest,
  MarkupUpdate,
  ReplicasHistoryControllerApi,
  AiWriterControllerApi,
  AIWriterRequest,
  ProjectDownloadControllerApi,
  PhraseDictionaryControllerApi,
  CreateDictionaryPhraseRequest,
  UpdateDictionaryPhraseRequest,
} from '../api/dubber/client';
import { DownloadTypes } from '../modules/Projects/constants';
import { axiosWrapped } from '../pipes/functions';

export default class ProjectsService {
  static readonly BASE_PATH = '';
  private ProjectsControllerApi: ProjectsControllerApi;
  private VoiceLinesControllerApi: ReplicasControllerApi;
  private MarkupSettingsControllerApi: MarkupSettingsControllerApi;
  private PresetsControllerApi: PresetsControllerApi;
  private ReplicasHistoryControllerApi: ReplicasHistoryControllerApi;
  private AiWriterControllerApi: AiWriterControllerApi;
  private ProjectDownloadControllerApi: ProjectDownloadControllerApi;
  private PhraseDictionaryControllerApi: PhraseDictionaryControllerApi;

  constructor() {
    this.ProjectsControllerApi = new ProjectsControllerApi({}, ProjectsService.BASE_PATH, axiosWrapped);
    this.VoiceLinesControllerApi = new ReplicasControllerApi({}, ProjectsService.BASE_PATH, axiosWrapped);
    this.MarkupSettingsControllerApi = new MarkupSettingsControllerApi({}, ProjectsService.BASE_PATH, axiosWrapped);
    this.PresetsControllerApi = new PresetsControllerApi({}, ProjectsService.BASE_PATH, axiosWrapped);
    this.ReplicasHistoryControllerApi = new ReplicasHistoryControllerApi({}, ProjectsService.BASE_PATH, axiosWrapped);
    this.AiWriterControllerApi = new AiWriterControllerApi({}, ProjectsService.BASE_PATH, axiosWrapped);
    this.ProjectDownloadControllerApi = new ProjectDownloadControllerApi({}, ProjectsService.BASE_PATH, axiosWrapped);
    this.PhraseDictionaryControllerApi = new PhraseDictionaryControllerApi({}, ProjectsService.BASE_PATH, axiosWrapped);
  }

  getProjects = async (page: number, pageSize: number) => {
    const { data: projects, headers } = await this.ProjectsControllerApi.findUserProjects(page, pageSize);
    return { projects: projects || [], count: parseInt(headers['content-range'] || '') };
  };

  getProject = async (projectId: string) => {
    const { data } = await this.ProjectsControllerApi.findProjectById(projectId);
    return data;
  };

  deleteProject = async (projectId: string) => {
    await this.ProjectsControllerApi.deleteProject(projectId);
  };

  createProject = async (name: string) => {
    const { data } = await this.ProjectsControllerApi.createProject({ name });
    return data;
  };

  updateProject = async (projectId: string, newName: string) => {
    await this.ProjectsControllerApi.updateProject(projectId, { name: newName });
  };

  updateProjectVoiceLines = async (projectId: string, updateObj: ProcessProjectReplicasRequest) => {
    const { data } = await this.ProjectsControllerApi.updateProjectReplicas(projectId, updateObj);
    return data;
  };

  getProjectVoiceLines = async ({
    projectId,
    pageSize,
    page,
  }: {
    projectId: string;
    pageSize?: number;
    page?: number;
  }) => {
    const { data, headers } = await this.VoiceLinesControllerApi.getReplicasByProjectId(projectId, page, pageSize);
    return { replicas: data, totalElements: parseInt(headers['content-range'] || '') };
  };

  getVoiceLineById = async ({ lineId }: { lineId: string }) => {
    const { data } = await this.VoiceLinesControllerApi.getReplica(lineId);
    return data;
  };

  deleteProjectVoiceLine = async (voiceLineId: string) => {
    await this.VoiceLinesControllerApi.deleteReplica(voiceLineId);
  };

  createProjectVoiceLine = async (reqObj: CreateReplicaRequest) => {
    const { data } = await this.VoiceLinesControllerApi.createReplica(reqObj);
    return data;
  };

  updateProjectVoiceLine = async (lineId: string, reqObj: UpdateReplicaRequest) => {
    const { data } = await this.VoiceLinesControllerApi.updateReplica(lineId, reqObj);
    return data;
  };

  getProjectVoiceSettings = async (voiceId: number) => {
    const { data } = await this.MarkupSettingsControllerApi.getMarkupSettings(voiceId);
    return data;
  };

  createPreset = async (name: string, markupUpdate: MarkupUpdate) => {
    const { data } = await this.PresetsControllerApi.createPreset(
      { name, markupUpdate },
      {
        'content-type': 'application/json',
      }
    );
    return data;
  };

  getPresets = async (page?: number, pageSize?: number) => {
    const { data, headers } = await this.PresetsControllerApi.getPresetsForCurrentUser(page, pageSize);
    return { presets: data, count: parseInt(headers['content-range'] || '') };
  };

  updatePreset = async (id: string, updateObj: UpdatePresetRequest) => {
    await this.PresetsControllerApi.updatePreset(id, updateObj);
  };

  deletePreset = async (id: string) => {
    await this.PresetsControllerApi.deletePreset(id);
  };

  getPresetById = async (id: string) => {
    const { data } = await this.PresetsControllerApi.getPresetById(id);
    return data;
  };

  getHistory = async (replicaId: string, page?: number, pageSize?: number) => {
    try {
      const { data, headers } = await this.ReplicasHistoryControllerApi.getHistory(replicaId, page, pageSize);
      return { ...data, totalElements: parseInt(headers['content-range'] || '') };
    } catch (error) {
      throw error;
    }
  };

  selectVersion = async (historyId: string) => {
    await this.ReplicasHistoryControllerApi.selectVersionFromHistory(historyId);
  };

  generateStory = async (aiWriterRequest: AIWriterRequest, options?: any) => {
    const { data } = await this.AiWriterControllerApi.generateStory(aiWriterRequest, options);
    return data;
  };
  getActiveDownloads = async (projectId: string) => {
    const { data } = await this.ProjectDownloadControllerApi.activeDownloads(projectId);
    return data;
  };

  cancelDownload = (projectId: string, downloadId: string) => {
    this.ProjectDownloadControllerApi.cancelDownload(projectId, downloadId);
  };
  downloadProject = async (projectId: string, format: DownloadTypes) => {
    const { data } = await this.ProjectDownloadControllerApi.downloadProject(projectId, format);
    return data;
  };
  getDownloadStatus = async (projectId: string, downloadId: string) => {
    const { data } = await this.ProjectDownloadControllerApi.downloadStatus(projectId, downloadId);
    return data;
  };

  createPhraseDictionary = (createObj: CreateDictionaryPhraseRequest) => {
    return this.PhraseDictionaryControllerApi.createPhraseDictionary(createObj);
  };

  getPhrasesDictionary = async (page?: number, pageSize?: number) => {
    const { data } = await this.PhraseDictionaryControllerApi.getDictionary(page, pageSize);
    return data;
  };

  updatePhraseDictionary = (id: string, updateObj: UpdateDictionaryPhraseRequest) => {
    return this.PhraseDictionaryControllerApi.updatePhrase(id, updateObj);
  };

  deletePhraseDictionary = (id: string) => {
    return this.PhraseDictionaryControllerApi.deletePhrase(id);
  };
}
