// tslint:disable
// @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AccountView
 */
export interface AccountView {
  /**
   *
   * @type {number}
   * @memberof AccountView
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AccountView
   */
  language: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountView
   */
  permissions: Array<string>;
}
/**
 *
 * @export
 * @interface CatalogCreateOrUpdate
 */
export interface CatalogCreateOrUpdate {
  /**
   *
   * @type {string}
   * @memberof CatalogCreateOrUpdate
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CatalogCreateOrUpdate
   */
  description?: string;
}
/**
 *
 * @export
 * @interface CatalogView
 */
export interface CatalogView {
  /**
   *
   * @type {number}
   * @memberof CatalogView
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CatalogView
   */
  voiceId: number;
  /**
   *
   * @type {string}
   * @memberof CatalogView
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CatalogView
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof CatalogView
   */
  annotatedDuration: number;
  /**
   *
   * @type {number}
   * @memberof CatalogView
   */
  samples: number;
  /**
   *
   * @type {number}
   * @memberof CatalogView
   */
  inProcessingSamples: number;
  /**
   *
   * @type {number}
   * @memberof CatalogView
   */
  noAudioSamples: number;
  /**
   *
   * @type {number}
   * @memberof CatalogView
   */
  annotationSuccessSamples: number;
  /**
   *
   * @type {Date}
   * @memberof CatalogView
   */
  createAt: Date;
  /**
   *
   * @type {Date}
   * @memberof CatalogView
   */
  updateAt: Date;
  /**
   *
   * @type {number}
   * @memberof CatalogView
   */
  errorCount?: number;
}
/**
 *
 * @export
 * @interface CheckpointView
 */
export interface CheckpointView {
  /**
   *
   * @type {number}
   * @memberof CheckpointView
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CheckpointView
   */
  modelId: number;
  /**
   *
   * @type {string}
   * @memberof CheckpointView
   */
  reference: string;
  /**
   *
   * @type {number}
   * @memberof CheckpointView
   */
  epoch: number;
  /**
   *
   * @type {Date}
   * @memberof CheckpointView
   */
  createAt: Date;
}
/**
 *
 * @export
 * @interface CurrentUser
 */
export interface CurrentUser {
  /**
   *
   * @type {AccountView}
   * @memberof CurrentUser
   */
  currentUser?: AccountView;
}
/**
 *
 * @export
 * @interface DedicatedServerRequest
 */
export interface DedicatedServerRequest {
  /**
   *
   * @type {string}
   * @memberof DedicatedServerRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof DedicatedServerRequest
   */
  phoneNumber: string;
}
/**
 *
 * @export
 * @interface EnvironmentConfig
 */
export interface EnvironmentConfig {
  /**
   *
   * @type {string}
   * @memberof EnvironmentConfig
   */
  hostname: string;
  /**
   *
   * @type {string}
   * @memberof EnvironmentConfig
   */
  publicBucketUrl: string;
  /**
   *
   * @type {string}
   * @memberof EnvironmentConfig
   */
  captchaKey: string;
  /**
   *
   * @type {boolean}
   * @memberof EnvironmentConfig
   */
  captchaEnabled: boolean;
  /**
   *
   * @type {string}
   * @memberof EnvironmentConfig
   */
  currency: string;
  /**
   *
   * @type {number}
   * @memberof EnvironmentConfig
   */
  dictionaryVoiceId: number;
  /**
   *
   * @type {number}
   * @memberof EnvironmentConfig
   */
  dictionaryModelId: number;
  /**
   *
   * @type {number}
   * @memberof EnvironmentConfig
   */
  minPrice: number;
  /**
   *
   * @type {Array<string>}
   * @memberof EnvironmentConfig
   */
  supportedVoiceLanguages: Array<SupportedVoiceLanguagesEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum SupportedVoiceLanguagesEnum {
  RuRU = 'ru_RU',
  EnGB = 'en_GB',
  EnUS = 'en_US',
  EsES = 'es_ES',
  PtPT = 'pt_PT',
  PtBR = 'pt_BR',
  HiIN = 'hi_IN',
  ItIT = 'it_IT',
  FrFR = 'fr_FR',
  DeDE = 'de_DE',
  ZhCN = 'zh_CN',
}

/**
 *
 * @export
 * @interface LicensedVoiceRequest
 */
export interface LicensedVoiceRequest {
  /**
   *
   * @type {string}
   * @memberof LicensedVoiceRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof LicensedVoiceRequest
   */
  phoneNumber: string;
  /**
   *
   * @type {number}
   * @memberof LicensedVoiceRequest
   */
  voiceId: number;
  /**
   *
   * @type {string}
   * @memberof LicensedVoiceRequest
   */
  voiceName: string;
}
/**
 *
 * @export
 * @interface ModelView
 */
export interface ModelView {
  /**
   *
   * @type {number}
   * @memberof ModelView
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ModelView
   */
  voiceId: number;
  /**
   *
   * @type {string}
   * @memberof ModelView
   */
  phase: PhaseEnum;
  /**
   *
   * @type {string}
   * @memberof ModelView
   */
  phaseStatus: PhaseStatusEnum;
  /**
   *
   * @type {Array<CheckpointView>}
   * @memberof ModelView
   */
  checkpoints: Array<CheckpointView>;
  /**
   *
   * @type {Date}
   * @memberof ModelView
   */
  createAt: Date;
  /**
   *
   * @type {number}
   * @memberof ModelView
   */
  version: number;
  /**
   *
   * @type {Date}
   * @memberof ModelView
   */
  trainedAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof ModelView
   */
  isActual?: boolean;
  /**
   *
   * @type {Date}
   * @memberof ModelView
   */
  releaseDate?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum PhaseEnum {
  CREATION = 'CREATION',
  WAITTRAINING = 'WAIT_TRAINING',
  TRAINING = 'TRAINING',
  TUNING = 'TUNING',
}
/**
 * @export
 * @enum {string}
 */
export enum PhaseStatusEnum {
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  FAILURE = 'FAILURE',
}

/**
 *
 * @export
 * @interface ModifyVoiceMember
 */
export interface ModifyVoiceMember {
  /**
   *
   * @type {number}
   * @memberof ModifyVoiceMember
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof ModifyVoiceMember
   */
  login?: string;
  /**
   *
   * @type {number}
   * @memberof ModifyVoiceMember
   */
  toRole: number;
}
/**
 *
 * @export
 * @interface NewVersionView
 */
export interface NewVersionView {
  /**
   *
   * @type {number}
   * @memberof NewVersionView
   */
  seconds: number;
  /**
   *
   * @type {number}
   * @memberof NewVersionView
   */
  secondsLimit: number;
  /**
   *
   * @type {number}
   * @memberof NewVersionView
   */
  version: number;
  /**
   *
   * @type {boolean}
   * @memberof NewVersionView
   */
  hasAnyCatalog: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NewVersionView
   */
  isAnnotationInProcess: boolean;
}
/**
 *
 * @export
 * @interface PublicModelView
 */
export interface PublicModelView {
  /**
   *
   * @type {number}
   * @memberof PublicModelView
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof PublicModelView
   */
  voiceId: number;
  /**
   *
   * @type {Date}
   * @memberof PublicModelView
   */
  createAt: Date;
  /**
   *
   * @type {number}
   * @memberof PublicModelView
   */
  version: number;
  /**
   *
   * @type {Date}
   * @memberof PublicModelView
   */
  trainedAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof PublicModelView
   */
  isActual?: boolean;
  /**
   *
   * @type {Date}
   * @memberof PublicModelView
   */
  releaseDate?: Date;
}
/**
 *
 * @export
 * @interface PublicationView
 */
export interface PublicationView {
  /**
   *
   * @type {number}
   * @memberof PublicationView
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof PublicationView
   */
  voiceId: number;
  /**
   *
   * @type {Date}
   * @memberof PublicationView
   */
  createAt: Date;
}
/**
 *
 * @export
 * @interface PublishVoiceRequest
 */
export interface PublishVoiceRequest {
  /**
   *
   * @type {string}
   * @memberof PublishVoiceRequest
   */
  previewText: string;
  /**
   *
   * @type {boolean}
   * @memberof PublishVoiceRequest
   */
  accessRestricted: boolean;
}
/**
 *
 * @export
 * @interface PublishedVoiceView
 */
export interface PublishedVoiceView {
  /**
   *
   * @type {number}
   * @memberof PublishedVoiceView
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceView
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceView
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceView
   */
  language: LanguageEnum;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceView
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof PublishedVoiceView
   */
  accessRestricted: boolean;
  /**
   *
   * @type {number}
   * @memberof PublishedVoiceView
   */
  modelId: number;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceView
   */
  photo?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PublishedVoiceView
   */
  tags: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
/**
 * @export
 * @enum {string}
 */
export enum LanguageEnum {
  RuRU = 'ru_RU',
  EnGB = 'en_GB',
  EnUS = 'en_US',
  EsES = 'es_ES',
  PtPT = 'pt_PT',
  PtBR = 'pt_BR',
  HiIN = 'hi_IN',
  ItIT = 'it_IT',
  FrFR = 'fr_FR',
  DeDE = 'de_DE',
  ZhCN = 'zh_CN',
}

/**
 *
 * @export
 * @interface PublishedVoiceViewWithUsedVersion
 */
export interface PublishedVoiceViewWithUsedVersion {
  /**
   *
   * @type {number}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  language: LanguageEnum;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  accessRestricted: boolean;
  /**
   *
   * @type {SimpleModelView}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  actualModel: SimpleModelView;
  /**
   *
   * @type {SimpleModelView}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  usedModel?: SimpleModelView;
  /**
   *
   * @type {string}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  photo?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PublishedVoiceViewWithUsedVersion
   */
  tags: Array<string>;
}

/**
 *
 * @export
 * @interface Role
 */
export interface Role {
  /**
   *
   * @type {number}
   * @memberof Role
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Role
   */
  name: string;
}
/**
 *
 * @export
 * @interface SampleUpdate
 */
export interface SampleUpdate {
  /**
   *
   * @type {string}
   * @memberof SampleUpdate
   */
  comment?: string;
  /**
   *
   * @type {number}
   * @memberof SampleUpdate
   */
  catalogId?: number;
  /**
   *
   * @type {string}
   * @memberof SampleUpdate
   */
  title?: string;
}
/**
 *
 * @export
 * @interface SampleView
 */
export interface SampleView {
  /**
   *
   * @type {number}
   * @memberof SampleView
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof SampleView
   */
  voiceId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof SampleView
   */
  modelIds?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof SampleView
   */
  catalogIds: Array<number>;
  /**
   *
   * @type {string}
   * @memberof SampleView
   */
  source: SourceEnum;
  /**
   *
   * @type {string}
   * @memberof SampleView
   */
  sampleState: SampleStateEnum;
  /**
   *
   * @type {number}
   * @memberof SampleView
   */
  durationSecond?: number;
  /**
   *
   * @type {number}
   * @memberof SampleView
   */
  annotatedDuration?: number;
  /**
   *
   * @type {string}
   * @memberof SampleView
   */
  reference?: string;
  /**
   *
   * @type {string}
   * @memberof SampleView
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SampleView
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof SampleView
   */
  comment?: string;
  /**
   *
   * @type {Date}
   * @memberof SampleView
   */
  createAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof SampleView
   */
  updateAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof SampleView
   */
  hasAudio: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum SourceEnum {
  DOWNLOAD = 'DOWNLOAD',
  RECORD = 'RECORD',
}
/**
 * @export
 * @enum {string}
 */
export enum SampleStateEnum {
  NOAUDIO = 'NO_AUDIO',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  COMMONERROR = 'COMMON_ERROR',
  NOISEERROR = 'NOISE_ERROR',
  NOTRECOGNIZEDERROR = 'NOT_RECOGNIZED_ERROR',
}

/**
 *
 * @export
 * @interface SimpleModelView
 */
export interface SimpleModelView {
  /**
   *
   * @type {number}
   * @memberof SimpleModelView
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof SimpleModelView
   */
  version: number;
  /**
   *
   * @type {Date}
   * @memberof SimpleModelView
   */
  trainedAt?: Date;
}
/**
 *
 * @export
 * @interface SynthesizableVoice
 */
export interface SynthesizableVoice {
  /**
   *
   * @type {number}
   * @memberof SynthesizableVoice
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SynthesizableVoice
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SynthesizableVoice
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof SynthesizableVoice
   */
  language: LanguageEnum;
  /**
   *
   * @type {string}
   * @memberof SynthesizableVoice
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof SynthesizableVoice
   */
  modelId: number;
  /**
   *
   * @type {string}
   * @memberof SynthesizableVoice
   */
  photo?: string;
}

/**
 *
 * @export
 * @interface VoiceConsumerView
 */
export interface VoiceConsumerView {
  /**
   *
   * @type {number}
   * @memberof VoiceConsumerView
   */
  voiceId: number;
  /**
   *
   * @type {number}
   * @memberof VoiceConsumerView
   */
  modelId?: number;
}
/**
 *
 * @export
 * @interface VoiceCreateOrUpdate
 */
export interface VoiceCreateOrUpdate {
  /**
   *
   * @type {string}
   * @memberof VoiceCreateOrUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof VoiceCreateOrUpdate
   */
  gender?: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof VoiceCreateOrUpdate
   */
  language?: LanguageEnum;
  /**
   *
   * @type {string}
   * @memberof VoiceCreateOrUpdate
   */
  description?: string;
}

/**
 *
 * @export
 * @interface VoiceCreationRequest
 */
export interface VoiceCreationRequest {
  /**
   *
   * @type {string}
   * @memberof VoiceCreationRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof VoiceCreationRequest
   */
  phoneNumber: string;
}
/**
 *
 * @export
 * @interface VoiceMemberToAdd
 */
export interface VoiceMemberToAdd {
  /**
   *
   * @type {number}
   * @memberof VoiceMemberToAdd
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof VoiceMemberToAdd
   */
  login?: string;
  /**
   *
   * @type {number}
   * @memberof VoiceMemberToAdd
   */
  roleId: number;
}
/**
 *
 * @export
 * @interface VoiceMemberToRemove
 */
export interface VoiceMemberToRemove {
  /**
   *
   * @type {number}
   * @memberof VoiceMemberToRemove
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof VoiceMemberToRemove
   */
  login?: string;
}
/**
 *
 * @export
 * @interface VoiceMemberView
 */
export interface VoiceMemberView {
  /**
   *
   * @type {number}
   * @memberof VoiceMemberView
   */
  accountId: number;
  /**
   *
   * @type {string}
   * @memberof VoiceMemberView
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof VoiceMemberView
   */
  login: string;
  /**
   *
   * @type {Role}
   * @memberof VoiceMemberView
   */
  role: Role;
}
/**
 *
 * @export
 * @interface VoicePriceCreate
 */
export interface VoicePriceCreate {
  /**
   *
   * @type {number}
   * @memberof VoicePriceCreate
   */
  price: number;
}
/**
 *
 * @export
 * @interface VoicePriceExtraCharge
 */
export interface VoicePriceExtraCharge {
  /**
   *
   * @type {number}
   * @memberof VoicePriceExtraCharge
   */
  companyPart: number;
  /**
   *
   * @type {number}
   * @memberof VoicePriceExtraCharge
   */
  ownerPart: number;
}
/**
 *
 * @export
 * @interface VoicePriceView
 */
export interface VoicePriceView {
  /**
   *
   * @type {number}
   * @memberof VoicePriceView
   */
  voiceId: number;
  /**
   *
   * @type {number}
   * @memberof VoicePriceView
   */
  basePrice?: number;
  /**
   *
   * @type {number}
   * @memberof VoicePriceView
   */
  totalPrice: number;
  /**
   *
   * @type {VoicePriceExtraCharge}
   * @memberof VoicePriceView
   */
  extraCharge?: VoicePriceExtraCharge;
}
/**
 *
 * @export
 * @interface VoiceView
 */
export interface VoiceView {
  /**
   *
   * @type {number}
   * @memberof VoiceView
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof VoiceView
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof VoiceView
   */
  gender: GenderEnum;
  /**
   *
   * @type {string}
   * @memberof VoiceView
   */
  language: LanguageEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof VoiceView
   */
  tags?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof VoiceView
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof VoiceView
   */
  photo?: string;
  /**
   *
   * @type {PublicationView}
   * @memberof VoiceView
   */
  publication?: PublicationView;
  /**
   *
   * @type {number}
   * @memberof VoiceView
   */
  annotatedDuration: number;
  /**
   *
   * @type {Date}
   * @memberof VoiceView
   */
  createAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof VoiceView
   */
  isAvailableForPublish: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VoiceView
   */
  isPublished: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VoiceView
   */
  accessRestricted: boolean;
  /**
   *
   * @type {Role}
   * @memberof VoiceView
   */
  currentVoiceMemberRole: Role;
  /**
   *
   * @type {string}
   * @memberof VoiceView
   */
  previewText?: string;
}

/**
 * ApplicationFormsEndpointApi - axios parameter creator
 * @export
 */
export const ApplicationFormsEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {DedicatedServerRequest} dedicatedServerRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestDedicatedServerANsYnAY(
      dedicatedServerRequest: DedicatedServerRequest,
      gRecaptchaResponse?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'dedicatedServerRequest' is not null or undefined
      if (dedicatedServerRequest === null || dedicatedServerRequest === undefined) {
        throw new RequiredError(
          'dedicatedServerRequest',
          'Required parameter dedicatedServerRequest was null or undefined when calling requestDedicatedServerANsYnAY.'
        );
      }
      const localVarPath = `/api/public/dedicated-server-request`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (gRecaptchaResponse !== undefined && gRecaptchaResponse !== null) {
        localVarHeaderParameter['g-recaptcha-response'] = String(gRecaptchaResponse);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof dedicatedServerRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(dedicatedServerRequest !== undefined ? dedicatedServerRequest : {})
        : dedicatedServerRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LicensedVoiceRequest} licensedVoiceRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestLicencedVoice(
      licensedVoiceRequest: LicensedVoiceRequest,
      gRecaptchaResponse?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'licensedVoiceRequest' is not null or undefined
      if (licensedVoiceRequest === null || licensedVoiceRequest === undefined) {
        throw new RequiredError(
          'licensedVoiceRequest',
          'Required parameter licensedVoiceRequest was null or undefined when calling requestLicencedVoice.'
        );
      }
      const localVarPath = `/api/public/licenced-voice-request`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (gRecaptchaResponse !== undefined && gRecaptchaResponse !== null) {
        localVarHeaderParameter['g-recaptcha-response'] = String(gRecaptchaResponse);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof licensedVoiceRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(licensedVoiceRequest !== undefined ? licensedVoiceRequest : {})
        : licensedVoiceRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VoiceCreationRequest} voiceCreationRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestVoiceCreationANsYnAY(
      voiceCreationRequest: VoiceCreationRequest,
      gRecaptchaResponse?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'voiceCreationRequest' is not null or undefined
      if (voiceCreationRequest === null || voiceCreationRequest === undefined) {
        throw new RequiredError(
          'voiceCreationRequest',
          'Required parameter voiceCreationRequest was null or undefined when calling requestVoiceCreationANsYnAY.'
        );
      }
      const localVarPath = `/api/public/voice-creation-request`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (gRecaptchaResponse !== undefined && gRecaptchaResponse !== null) {
        localVarHeaderParameter['g-recaptcha-response'] = String(gRecaptchaResponse);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof voiceCreationRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(voiceCreationRequest !== undefined ? voiceCreationRequest : {})
        : voiceCreationRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApplicationFormsEndpointApi - functional programming interface
 * @export
 */
export const ApplicationFormsEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {DedicatedServerRequest} dedicatedServerRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestDedicatedServerANsYnAY(
      dedicatedServerRequest: DedicatedServerRequest,
      gRecaptchaResponse?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ApplicationFormsEndpointApiAxiosParamCreator(
        configuration
      ).requestDedicatedServerANsYnAY(dedicatedServerRequest, gRecaptchaResponse, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {LicensedVoiceRequest} licensedVoiceRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestLicencedVoice(
      licensedVoiceRequest: LicensedVoiceRequest,
      gRecaptchaResponse?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ApplicationFormsEndpointApiAxiosParamCreator(configuration).requestLicencedVoice(
        licensedVoiceRequest,
        gRecaptchaResponse,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {VoiceCreationRequest} voiceCreationRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestVoiceCreationANsYnAY(
      voiceCreationRequest: VoiceCreationRequest,
      gRecaptchaResponse?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = ApplicationFormsEndpointApiAxiosParamCreator(configuration).requestVoiceCreationANsYnAY(
        voiceCreationRequest,
        gRecaptchaResponse,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ApplicationFormsEndpointApi - factory interface
 * @export
 */
export const ApplicationFormsEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {DedicatedServerRequest} dedicatedServerRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestDedicatedServerANsYnAY(
      dedicatedServerRequest: DedicatedServerRequest,
      gRecaptchaResponse?: string,
      options?: any
    ): AxiosPromise<object> {
      return ApplicationFormsEndpointApiFp(configuration).requestDedicatedServerANsYnAY(
        dedicatedServerRequest,
        gRecaptchaResponse,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {LicensedVoiceRequest} licensedVoiceRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestLicencedVoice(
      licensedVoiceRequest: LicensedVoiceRequest,
      gRecaptchaResponse?: string,
      options?: any
    ): AxiosPromise<object> {
      return ApplicationFormsEndpointApiFp(configuration).requestLicencedVoice(
        licensedVoiceRequest,
        gRecaptchaResponse,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {VoiceCreationRequest} voiceCreationRequest
     * @param {string} [gRecaptchaResponse]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestVoiceCreationANsYnAY(
      voiceCreationRequest: VoiceCreationRequest,
      gRecaptchaResponse?: string,
      options?: any
    ): AxiosPromise<object> {
      return ApplicationFormsEndpointApiFp(configuration).requestVoiceCreationANsYnAY(
        voiceCreationRequest,
        gRecaptchaResponse,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ApplicationFormsEndpointApi - object-oriented interface
 * @export
 * @class ApplicationFormsEndpointApi
 * @extends {BaseAPI}
 */
export class ApplicationFormsEndpointApi extends BaseAPI {
  /**
   *
   * @param {DedicatedServerRequest} dedicatedServerRequest
   * @param {string} [gRecaptchaResponse]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationFormsEndpointApi
   */
  public requestDedicatedServerANsYnAY(
    dedicatedServerRequest: DedicatedServerRequest,
    gRecaptchaResponse?: string,
    options?: any
  ) {
    return ApplicationFormsEndpointApiFp(this.configuration).requestDedicatedServerANsYnAY(
      dedicatedServerRequest,
      gRecaptchaResponse,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {LicensedVoiceRequest} licensedVoiceRequest
   * @param {string} [gRecaptchaResponse]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationFormsEndpointApi
   */
  public requestLicencedVoice(licensedVoiceRequest: LicensedVoiceRequest, gRecaptchaResponse?: string, options?: any) {
    return ApplicationFormsEndpointApiFp(this.configuration).requestLicencedVoice(
      licensedVoiceRequest,
      gRecaptchaResponse,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {VoiceCreationRequest} voiceCreationRequest
   * @param {string} [gRecaptchaResponse]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationFormsEndpointApi
   */
  public requestVoiceCreationANsYnAY(
    voiceCreationRequest: VoiceCreationRequest,
    gRecaptchaResponse?: string,
    options?: any
  ) {
    return ApplicationFormsEndpointApiFp(this.configuration).requestVoiceCreationANsYnAY(
      voiceCreationRequest,
      gRecaptchaResponse,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * CatalogApi - axios parameter creator
 * @export
 */
export const CatalogApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Catalog
     * @param {number} voiceId
     * @param {CatalogCreateOrUpdate} catalogCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCatalog(voiceId: number, catalogCreateOrUpdate: CatalogCreateOrUpdate, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling createCatalog.'
        );
      }
      // verify required parameter 'catalogCreateOrUpdate' is not null or undefined
      if (catalogCreateOrUpdate === null || catalogCreateOrUpdate === undefined) {
        throw new RequiredError(
          'catalogCreateOrUpdate',
          'Required parameter catalogCreateOrUpdate was null or undefined when calling createCatalog.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/catalogs`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof catalogCreateOrUpdate !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(catalogCreateOrUpdate !== undefined ? catalogCreateOrUpdate : {})
        : catalogCreateOrUpdate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Catalogs
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalog(voiceId: number, catalogId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError('voiceId', 'Required parameter voiceId was null or undefined when calling getCatalog.');
      }
      // verify required parameter 'catalogId' is not null or undefined
      if (catalogId === null || catalogId === undefined) {
        throw new RequiredError(
          'catalogId',
          'Required parameter catalogId was null or undefined when calling getCatalog.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/catalogs/{catalogId}`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'catalogId'}}`, encodeURIComponent(String(catalogId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Catalogs
     * @param {number} voiceId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {boolean} [isUsed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogs(voiceId: number, page?: number, pageSize?: number, isUsed?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling getCatalogs.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/catalogs`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (isUsed !== undefined) {
        localVarQueryParameter['isUsed'] = isUsed;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Catalog
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {CatalogCreateOrUpdate} catalogCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchCatalog(
      voiceId: number,
      catalogId: number,
      catalogCreateOrUpdate: CatalogCreateOrUpdate,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling patchCatalog.'
        );
      }
      // verify required parameter 'catalogId' is not null or undefined
      if (catalogId === null || catalogId === undefined) {
        throw new RequiredError(
          'catalogId',
          'Required parameter catalogId was null or undefined when calling patchCatalog.'
        );
      }
      // verify required parameter 'catalogCreateOrUpdate' is not null or undefined
      if (catalogCreateOrUpdate === null || catalogCreateOrUpdate === undefined) {
        throw new RequiredError(
          'catalogCreateOrUpdate',
          'Required parameter catalogCreateOrUpdate was null or undefined when calling patchCatalog.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/catalogs/{catalogId}`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'catalogId'}}`, encodeURIComponent(String(catalogId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof catalogCreateOrUpdate !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(catalogCreateOrUpdate !== undefined ? catalogCreateOrUpdate : {})
        : catalogCreateOrUpdate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive Catalog
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeCatalog(voiceId: number, catalogId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling removeCatalog.'
        );
      }
      // verify required parameter 'catalogId' is not null or undefined
      if (catalogId === null || catalogId === undefined) {
        throw new RequiredError(
          'catalogId',
          'Required parameter catalogId was null or undefined when calling removeCatalog.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/catalogs/{catalogId}`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'catalogId'}}`, encodeURIComponent(String(catalogId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CatalogApi - functional programming interface
 * @export
 */
export const CatalogApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Catalog
     * @param {number} voiceId
     * @param {CatalogCreateOrUpdate} catalogCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCatalog(
      voiceId: number,
      catalogCreateOrUpdate: CatalogCreateOrUpdate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
      const localVarAxiosArgs = CatalogApiAxiosParamCreator(configuration).createCatalog(
        voiceId,
        catalogCreateOrUpdate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get All Catalogs
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalog(
      voiceId: number,
      catalogId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogView> {
      const localVarAxiosArgs = CatalogApiAxiosParamCreator(configuration).getCatalog(voiceId, catalogId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get All Catalogs
     * @param {number} voiceId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {boolean} [isUsed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogs(
      voiceId: number,
      page?: number,
      pageSize?: number,
      isUsed?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CatalogView>> {
      const localVarAxiosArgs = CatalogApiAxiosParamCreator(configuration).getCatalogs(
        voiceId,
        page,
        pageSize,
        isUsed,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update Catalog
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {CatalogCreateOrUpdate} catalogCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchCatalog(
      voiceId: number,
      catalogId: number,
      catalogCreateOrUpdate: CatalogCreateOrUpdate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CatalogApiAxiosParamCreator(configuration).patchCatalog(
        voiceId,
        catalogId,
        catalogCreateOrUpdate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Archive Catalog
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeCatalog(
      voiceId: number,
      catalogId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CatalogApiAxiosParamCreator(configuration).removeCatalog(voiceId, catalogId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CatalogApi - factory interface
 * @export
 */
export const CatalogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create Catalog
     * @param {number} voiceId
     * @param {CatalogCreateOrUpdate} catalogCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCatalog(voiceId: number, catalogCreateOrUpdate: CatalogCreateOrUpdate, options?: any): AxiosPromise<number> {
      return CatalogApiFp(configuration).createCatalog(voiceId, catalogCreateOrUpdate, options)(axios, basePath);
    },
    /**
     *
     * @summary Get All Catalogs
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalog(voiceId: number, catalogId: number, options?: any): AxiosPromise<CatalogView> {
      return CatalogApiFp(configuration).getCatalog(voiceId, catalogId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get All Catalogs
     * @param {number} voiceId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {boolean} [isUsed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogs(
      voiceId: number,
      page?: number,
      pageSize?: number,
      isUsed?: boolean,
      options?: any
    ): AxiosPromise<Array<CatalogView>> {
      return CatalogApiFp(configuration).getCatalogs(voiceId, page, pageSize, isUsed, options)(axios, basePath);
    },
    /**
     *
     * @summary Update Catalog
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {CatalogCreateOrUpdate} catalogCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchCatalog(
      voiceId: number,
      catalogId: number,
      catalogCreateOrUpdate: CatalogCreateOrUpdate,
      options?: any
    ): AxiosPromise<void> {
      return CatalogApiFp(configuration).patchCatalog(
        voiceId,
        catalogId,
        catalogCreateOrUpdate,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Archive Catalog
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeCatalog(voiceId: number, catalogId: number, options?: any): AxiosPromise<void> {
      return CatalogApiFp(configuration).removeCatalog(voiceId, catalogId, options)(axios, basePath);
    },
  };
};

/**
 * CatalogApi - object-oriented interface
 * @export
 * @class CatalogApi
 * @extends {BaseAPI}
 */
export class CatalogApi extends BaseAPI {
  /**
   *
   * @summary Create Catalog
   * @param {number} voiceId
   * @param {CatalogCreateOrUpdate} catalogCreateOrUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public createCatalog(voiceId: number, catalogCreateOrUpdate: CatalogCreateOrUpdate, options?: any) {
    return CatalogApiFp(this.configuration).createCatalog(
      voiceId,
      catalogCreateOrUpdate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get All Catalogs
   * @param {number} voiceId
   * @param {number} catalogId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public getCatalog(voiceId: number, catalogId: number, options?: any) {
    return CatalogApiFp(this.configuration).getCatalog(voiceId, catalogId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get All Catalogs
   * @param {number} voiceId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {boolean} [isUsed]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public getCatalogs(voiceId: number, page?: number, pageSize?: number, isUsed?: boolean, options?: any) {
    return CatalogApiFp(this.configuration).getCatalogs(
      voiceId,
      page,
      pageSize,
      isUsed,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update Catalog
   * @param {number} voiceId
   * @param {number} catalogId
   * @param {CatalogCreateOrUpdate} catalogCreateOrUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public patchCatalog(voiceId: number, catalogId: number, catalogCreateOrUpdate: CatalogCreateOrUpdate, options?: any) {
    return CatalogApiFp(this.configuration).patchCatalog(
      voiceId,
      catalogId,
      catalogCreateOrUpdate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Archive Catalog
   * @param {number} voiceId
   * @param {number} catalogId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public removeCatalog(voiceId: number, catalogId: number, options?: any) {
    return CatalogApiFp(this.configuration).removeCatalog(voiceId, catalogId, options)(this.axios, this.basePath);
  }
}

/**
 * ConfigControllerApi - axios parameter creator
 * @export
 */
export const ConfigControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    config(options: any = {}): RequestArgs {
      const localVarPath = `/api/public/config`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ConfigControllerApi - functional programming interface
 * @export
 */
export const ConfigControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    config(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentConfig> {
      const localVarAxiosArgs = ConfigControllerApiAxiosParamCreator(configuration).config(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ConfigControllerApi - factory interface
 * @export
 */
export const ConfigControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    config(options?: any): AxiosPromise<EnvironmentConfig> {
      return ConfigControllerApiFp(configuration).config(options)(axios, basePath);
    },
  };
};

/**
 * ConfigControllerApi - object-oriented interface
 * @export
 * @class ConfigControllerApi
 * @extends {BaseAPI}
 */
export class ConfigControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApi
   */
  public config(options?: any) {
    return ConfigControllerApiFp(this.configuration).config(options)(this.axios, this.basePath);
  }
}

/**
 * LoginAndLogoutApi - axios parameter creator
 * @export
 */
export const LoginAndLogoutApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorize(options: any = {}): RequestArgs {
      const localVarPath = `/api/authorize`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LoginAndLogoutApi - functional programming interface
 * @export
 */
export const LoginAndLogoutApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorize(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountView> {
      const localVarAxiosArgs = LoginAndLogoutApiAxiosParamCreator(configuration).authorize(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * LoginAndLogoutApi - factory interface
 * @export
 */
export const LoginAndLogoutApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorize(options?: any): AxiosPromise<AccountView> {
      return LoginAndLogoutApiFp(configuration).authorize(options)(axios, basePath);
    },
  };
};

/**
 * LoginAndLogoutApi - object-oriented interface
 * @export
 * @class LoginAndLogoutApi
 * @extends {BaseAPI}
 */
export class LoginAndLogoutApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoginAndLogoutApi
   */
  public authorize(options?: any) {
    return LoginAndLogoutApiFp(this.configuration).authorize(options)(this.axios, this.basePath);
  }
}

/**
 * ModelApi - axios parameter creator
 * @export
 */
export const ModelApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Archive Model
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveModel(voiceId: number, modelId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling archiveModel.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling archiveModel.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/models/{modelId}`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Choose Checkpoint
     * @param {number} modelId
     * @param {number} checkpointId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chooseCheckpoint(modelId: number, checkpointId: number, options: any = {}): RequestArgs {
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling chooseCheckpoint.'
        );
      }
      // verify required parameter 'checkpointId' is not null or undefined
      if (checkpointId === null || checkpointId === undefined) {
        throw new RequiredError(
          'checkpointId',
          'Required parameter checkpointId was null or undefined when calling chooseCheckpoint.'
        );
      }
      const localVarPath = `/api/models/{modelId}/checkpoints/{checkpointId}`
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)))
        .replace(`{${'checkpointId'}}`, encodeURIComponent(String(checkpointId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Model
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModel(voiceId: number, modelId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError('voiceId', 'Required parameter voiceId was null or undefined when calling getModel.');
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError('modelId', 'Required parameter modelId was null or undefined when calling getModel.');
      }
      const localVarPath = `/api/voices/{voiceId}/models/{modelId}`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Models
     * @param {number} voiceId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {boolean} [isReleased]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModels(voiceId: number, page?: number, pageSize?: number, isReleased?: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError('voiceId', 'Required parameter voiceId was null or undefined when calling getModels.');
      }
      const localVarPath = `/api/voices/{voiceId}/models`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (isReleased !== undefined) {
        localVarQueryParameter['isReleased'] = isReleased;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Release Model
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseVersion(voiceId: number, modelId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling releaseVersion.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling releaseVersion.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/models/{modelId}/release`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'modelId'}}`, encodeURIComponent(String(modelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Simulate model training
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulateModelTraining(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling simulateModelTraining.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/simulate-training`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainModel(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError('voiceId', 'Required parameter voiceId was null or undefined when calling trainModel.');
      }
      const localVarPath = `/api/voices/{voiceId}/train`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModelApi - functional programming interface
 * @export
 */
export const ModelApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Archive Model
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveModel(
      voiceId: number,
      modelId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ModelApiAxiosParamCreator(configuration).archiveModel(voiceId, modelId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Choose Checkpoint
     * @param {number} modelId
     * @param {number} checkpointId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chooseCheckpoint(
      modelId: number,
      checkpointId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ModelApiAxiosParamCreator(configuration).chooseCheckpoint(
        modelId,
        checkpointId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get Model
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModel(
      voiceId: number,
      modelId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelView> {
      const localVarAxiosArgs = ModelApiAxiosParamCreator(configuration).getModel(voiceId, modelId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get All Models
     * @param {number} voiceId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {boolean} [isReleased]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModels(
      voiceId: number,
      page?: number,
      pageSize?: number,
      isReleased?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelView>> {
      const localVarAxiosArgs = ModelApiAxiosParamCreator(configuration).getModels(
        voiceId,
        page,
        pageSize,
        isReleased,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Release Model
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseVersion(
      voiceId: number,
      modelId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ModelApiAxiosParamCreator(configuration).releaseVersion(voiceId, modelId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Simulate model training
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulateModelTraining(
      voiceId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelView> {
      const localVarAxiosArgs = ModelApiAxiosParamCreator(configuration).simulateModelTraining(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainModel(voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelView> {
      const localVarAxiosArgs = ModelApiAxiosParamCreator(configuration).trainModel(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ModelApi - factory interface
 * @export
 */
export const ModelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Archive Model
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveModel(voiceId: number, modelId: number, options?: any): AxiosPromise<void> {
      return ModelApiFp(configuration).archiveModel(voiceId, modelId, options)(axios, basePath);
    },
    /**
     *
     * @summary Choose Checkpoint
     * @param {number} modelId
     * @param {number} checkpointId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chooseCheckpoint(modelId: number, checkpointId: number, options?: any): AxiosPromise<void> {
      return ModelApiFp(configuration).chooseCheckpoint(modelId, checkpointId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get Model
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModel(voiceId: number, modelId: number, options?: any): AxiosPromise<ModelView> {
      return ModelApiFp(configuration).getModel(voiceId, modelId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get All Models
     * @param {number} voiceId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {boolean} [isReleased]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModels(
      voiceId: number,
      page?: number,
      pageSize?: number,
      isReleased?: boolean,
      options?: any
    ): AxiosPromise<Array<ModelView>> {
      return ModelApiFp(configuration).getModels(voiceId, page, pageSize, isReleased, options)(axios, basePath);
    },
    /**
     *
     * @summary Release Model
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseVersion(voiceId: number, modelId: number, options?: any): AxiosPromise<void> {
      return ModelApiFp(configuration).releaseVersion(voiceId, modelId, options)(axios, basePath);
    },
    /**
     *
     * @summary Simulate model training
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulateModelTraining(voiceId: number, options?: any): AxiosPromise<ModelView> {
      return ModelApiFp(configuration).simulateModelTraining(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trainModel(voiceId: number, options?: any): AxiosPromise<ModelView> {
      return ModelApiFp(configuration).trainModel(voiceId, options)(axios, basePath);
    },
  };
};

/**
 * ModelApi - object-oriented interface
 * @export
 * @class ModelApi
 * @extends {BaseAPI}
 */
export class ModelApi extends BaseAPI {
  /**
   *
   * @summary Archive Model
   * @param {number} voiceId
   * @param {number} modelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public archiveModel(voiceId: number, modelId: number, options?: any) {
    return ModelApiFp(this.configuration).archiveModel(voiceId, modelId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Choose Checkpoint
   * @param {number} modelId
   * @param {number} checkpointId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public chooseCheckpoint(modelId: number, checkpointId: number, options?: any) {
    return ModelApiFp(this.configuration).chooseCheckpoint(modelId, checkpointId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get Model
   * @param {number} voiceId
   * @param {number} modelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public getModel(voiceId: number, modelId: number, options?: any) {
    return ModelApiFp(this.configuration).getModel(voiceId, modelId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get All Models
   * @param {number} voiceId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {boolean} [isReleased]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public getModels(voiceId: number, page?: number, pageSize?: number, isReleased?: boolean, options?: any) {
    return ModelApiFp(this.configuration).getModels(
      voiceId,
      page,
      pageSize,
      isReleased,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Release Model
   * @param {number} voiceId
   * @param {number} modelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public releaseVersion(voiceId: number, modelId: number, options?: any) {
    return ModelApiFp(this.configuration).releaseVersion(voiceId, modelId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Simulate model training
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public simulateModelTraining(voiceId: number, options?: any) {
    return ModelApiFp(this.configuration).simulateModelTraining(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelApi
   */
  public trainModel(voiceId: number, options?: any) {
    return ModelApiFp(this.configuration).trainModel(voiceId, options)(this.axios, this.basePath);
  }
}

/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get All Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoles(options: any = {}): RequestArgs {
      const localVarPath = `/api/roles`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get All Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoles(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>> {
      const localVarAxiosArgs = RoleApiAxiosParamCreator(configuration).getRoles(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get All Roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoles(options?: any): AxiosPromise<Array<Role>> {
      return RoleApiFp(configuration).getRoles(options)(axios, basePath);
    },
  };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
  /**
   *
   * @summary Get All Roles
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoleApi
   */
  public getRoles(options?: any) {
    return RoleApiFp(this.configuration).getRoles(options)(this.axios, this.basePath);
  }
}

/**
 * SampleApi - axios parameter creator
 * @export
 */
export const SampleApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Sample Info
     * @param {number} voiceId
     * @param {number} sampleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSample(voiceId: number, sampleId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError('voiceId', 'Required parameter voiceId was null or undefined when calling getSample.');
      }
      // verify required parameter 'sampleId' is not null or undefined
      if (sampleId === null || sampleId === undefined) {
        throw new RequiredError(
          'sampleId',
          'Required parameter sampleId was null or undefined when calling getSample.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/samples/{sampleId}`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'sampleId'}}`, encodeURIComponent(String(sampleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Sample Info
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {number} page
     * @param {number} pageSize
     * @param {boolean} [hasAudio]
     * @param {boolean} [hasError]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSamples(
      voiceId: number,
      catalogId: number,
      page: number,
      pageSize: number,
      hasAudio?: boolean,
      hasError?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError('voiceId', 'Required parameter voiceId was null or undefined when calling getSamples.');
      }
      // verify required parameter 'catalogId' is not null or undefined
      if (catalogId === null || catalogId === undefined) {
        throw new RequiredError(
          'catalogId',
          'Required parameter catalogId was null or undefined when calling getSamples.'
        );
      }
      // verify required parameter 'page' is not null or undefined
      if (page === null || page === undefined) {
        throw new RequiredError('page', 'Required parameter page was null or undefined when calling getSamples.');
      }
      // verify required parameter 'pageSize' is not null or undefined
      if (pageSize === null || pageSize === undefined) {
        throw new RequiredError(
          'pageSize',
          'Required parameter pageSize was null or undefined when calling getSamples.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/samples`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (catalogId !== undefined) {
        localVarQueryParameter['catalogId'] = catalogId;
      }

      if (hasAudio !== undefined) {
        localVarQueryParameter['hasAudio'] = hasAudio;
      }

      if (hasError !== undefined) {
        localVarQueryParameter['hasError'] = hasError;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Sample Info
     * @param {number} voiceId
     * @param {number} sampleId
     * @param {SampleUpdate} sampleUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchSample(voiceId: number, sampleId: number, sampleUpdate: SampleUpdate, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling patchSample.'
        );
      }
      // verify required parameter 'sampleId' is not null or undefined
      if (sampleId === null || sampleId === undefined) {
        throw new RequiredError(
          'sampleId',
          'Required parameter sampleId was null or undefined when calling patchSample.'
        );
      }
      // verify required parameter 'sampleUpdate' is not null or undefined
      if (sampleUpdate === null || sampleUpdate === undefined) {
        throw new RequiredError(
          'sampleUpdate',
          'Required parameter sampleUpdate was null or undefined when calling patchSample.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/samples/{sampleId}`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'sampleId'}}`, encodeURIComponent(String(sampleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sampleUpdate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sampleUpdate !== undefined ? sampleUpdate : {})
        : sampleUpdate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive Sample
     * @param {number} voiceId
     * @param {number} sampleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeSample(voiceId: number, sampleId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling removeSample.'
        );
      }
      // verify required parameter 'sampleId' is not null or undefined
      if (sampleId === null || sampleId === undefined) {
        throw new RequiredError(
          'sampleId',
          'Required parameter sampleId was null or undefined when calling removeSample.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/samples/{sampleId}`
        .replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)))
        .replace(`{${'sampleId'}}`, encodeURIComponent(String(sampleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SampleApi - functional programming interface
 * @export
 */
export const SampleApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Sample Info
     * @param {number} voiceId
     * @param {number} sampleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSample(
      voiceId: number,
      sampleId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SampleView> {
      const localVarAxiosArgs = SampleApiAxiosParamCreator(configuration).getSample(voiceId, sampleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get All Sample Info
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {number} page
     * @param {number} pageSize
     * @param {boolean} [hasAudio]
     * @param {boolean} [hasError]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSamples(
      voiceId: number,
      catalogId: number,
      page: number,
      pageSize: number,
      hasAudio?: boolean,
      hasError?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SampleView>> {
      const localVarAxiosArgs = SampleApiAxiosParamCreator(configuration).getSamples(
        voiceId,
        catalogId,
        page,
        pageSize,
        hasAudio,
        hasError,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update Sample Info
     * @param {number} voiceId
     * @param {number} sampleId
     * @param {SampleUpdate} sampleUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchSample(
      voiceId: number,
      sampleId: number,
      sampleUpdate: SampleUpdate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SampleApiAxiosParamCreator(configuration).patchSample(
        voiceId,
        sampleId,
        sampleUpdate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Archive Sample
     * @param {number} voiceId
     * @param {number} sampleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeSample(
      voiceId: number,
      sampleId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SampleApiAxiosParamCreator(configuration).removeSample(voiceId, sampleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SampleApi - factory interface
 * @export
 */
export const SampleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get Sample Info
     * @param {number} voiceId
     * @param {number} sampleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSample(voiceId: number, sampleId: number, options?: any): AxiosPromise<SampleView> {
      return SampleApiFp(configuration).getSample(voiceId, sampleId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get All Sample Info
     * @param {number} voiceId
     * @param {number} catalogId
     * @param {number} page
     * @param {number} pageSize
     * @param {boolean} [hasAudio]
     * @param {boolean} [hasError]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSamples(
      voiceId: number,
      catalogId: number,
      page: number,
      pageSize: number,
      hasAudio?: boolean,
      hasError?: boolean,
      options?: any
    ): AxiosPromise<Array<SampleView>> {
      return SampleApiFp(configuration).getSamples(
        voiceId,
        catalogId,
        page,
        pageSize,
        hasAudio,
        hasError,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update Sample Info
     * @param {number} voiceId
     * @param {number} sampleId
     * @param {SampleUpdate} sampleUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchSample(voiceId: number, sampleId: number, sampleUpdate: SampleUpdate, options?: any): AxiosPromise<void> {
      return SampleApiFp(configuration).patchSample(voiceId, sampleId, sampleUpdate, options)(axios, basePath);
    },
    /**
     *
     * @summary Archive Sample
     * @param {number} voiceId
     * @param {number} sampleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeSample(voiceId: number, sampleId: number, options?: any): AxiosPromise<void> {
      return SampleApiFp(configuration).removeSample(voiceId, sampleId, options)(axios, basePath);
    },
  };
};

/**
 * SampleApi - object-oriented interface
 * @export
 * @class SampleApi
 * @extends {BaseAPI}
 */
export class SampleApi extends BaseAPI {
  /**
   *
   * @summary Get Sample Info
   * @param {number} voiceId
   * @param {number} sampleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SampleApi
   */
  public getSample(voiceId: number, sampleId: number, options?: any) {
    return SampleApiFp(this.configuration).getSample(voiceId, sampleId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get All Sample Info
   * @param {number} voiceId
   * @param {number} catalogId
   * @param {number} page
   * @param {number} pageSize
   * @param {boolean} [hasAudio]
   * @param {boolean} [hasError]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SampleApi
   */
  public getSamples(
    voiceId: number,
    catalogId: number,
    page: number,
    pageSize: number,
    hasAudio?: boolean,
    hasError?: boolean,
    options?: any
  ) {
    return SampleApiFp(this.configuration).getSamples(
      voiceId,
      catalogId,
      page,
      pageSize,
      hasAudio,
      hasError,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update Sample Info
   * @param {number} voiceId
   * @param {number} sampleId
   * @param {SampleUpdate} sampleUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SampleApi
   */
  public patchSample(voiceId: number, sampleId: number, sampleUpdate: SampleUpdate, options?: any) {
    return SampleApiFp(this.configuration).patchSample(
      voiceId,
      sampleId,
      sampleUpdate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Archive Sample
   * @param {number} voiceId
   * @param {number} sampleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SampleApi
   */
  public removeSample(voiceId: number, sampleId: number, options?: any) {
    return SampleApiFp(this.configuration).removeSample(voiceId, sampleId, options)(this.axios, this.basePath);
  }
}

/**
 * TokenControllerApi - axios parameter creator
 * @export
 */
export const TokenControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} voiceId
     * @param {number} [modelId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateToken(voiceId: number, modelId?: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling generateToken.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/token`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelId !== undefined) {
        localVarQueryParameter['modelId'] = modelId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError('voiceId', 'Required parameter voiceId was null or undefined when calling getToken.');
      }
      const localVarPath = `/api/voices/{voiceId}/token`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeToken(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling removeToken.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/token`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTokenSetting(voiceId: number, modelId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling updateTokenSetting.'
        );
      }
      // verify required parameter 'modelId' is not null or undefined
      if (modelId === null || modelId === undefined) {
        throw new RequiredError(
          'modelId',
          'Required parameter modelId was null or undefined when calling updateTokenSetting.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/token`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelId !== undefined) {
        localVarQueryParameter['modelId'] = modelId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TokenControllerApi - functional programming interface
 * @export
 */
export const TokenControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} voiceId
     * @param {number} [modelId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateToken(
      voiceId: number,
      modelId?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = TokenControllerApiAxiosParamCreator(configuration).generateToken(
        voiceId,
        modelId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken(voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = TokenControllerApiAxiosParamCreator(configuration).getToken(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeToken(voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = TokenControllerApiAxiosParamCreator(configuration).removeToken(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTokenSetting(
      voiceId: number,
      modelId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = TokenControllerApiAxiosParamCreator(configuration).updateTokenSetting(
        voiceId,
        modelId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TokenControllerApi - factory interface
 * @export
 */
export const TokenControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} voiceId
     * @param {number} [modelId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateToken(voiceId: number, modelId?: number, options?: any): AxiosPromise<string> {
      return TokenControllerApiFp(configuration).generateToken(voiceId, modelId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToken(voiceId: number, options?: any): AxiosPromise<string> {
      return TokenControllerApiFp(configuration).getToken(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeToken(voiceId: number, options?: any): AxiosPromise<void> {
      return TokenControllerApiFp(configuration).removeToken(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {number} modelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTokenSetting(voiceId: number, modelId: number, options?: any): AxiosPromise<void> {
      return TokenControllerApiFp(configuration).updateTokenSetting(voiceId, modelId, options)(axios, basePath);
    },
  };
};

/**
 * TokenControllerApi - object-oriented interface
 * @export
 * @class TokenControllerApi
 * @extends {BaseAPI}
 */
export class TokenControllerApi extends BaseAPI {
  /**
   *
   * @param {number} voiceId
   * @param {number} [modelId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenControllerApi
   */
  public generateToken(voiceId: number, modelId?: number, options?: any) {
    return TokenControllerApiFp(this.configuration).generateToken(voiceId, modelId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenControllerApi
   */
  public getToken(voiceId: number, options?: any) {
    return TokenControllerApiFp(this.configuration).getToken(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenControllerApi
   */
  public removeToken(voiceId: number, options?: any) {
    return TokenControllerApiFp(this.configuration).removeToken(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {number} modelId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenControllerApi
   */
  public updateTokenSetting(voiceId: number, modelId: number, options?: any) {
    return TokenControllerApiFp(this.configuration).updateTokenSetting(
      voiceId,
      modelId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currentUser(options: any = {}): RequestArgs {
      const localVarPath = `/api/public/current-user`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currentUser(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUser> {
      const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).currentUser(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currentUser(options?: any): AxiosPromise<CurrentUser> {
      return UserApiFp(configuration).currentUser(options)(axios, basePath);
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Get current user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public currentUser(options?: any) {
    return UserApiFp(this.configuration).currentUser(options)(this.axios, this.basePath);
  }
}

/**
 * VoiceApi - axios parameter creator
 * @export
 */
export const VoiceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Archive Voice
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveVoice(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling archiveVoice.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Connect voice to TTS model
     * @param {number} voiceId
     * @param {string} modelName
     * @param {string} provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectVoiceToModel(voiceId: number, modelName: string, provider: string, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling connectVoiceToModel.'
        );
      }
      // verify required parameter 'modelName' is not null or undefined
      if (modelName === null || modelName === undefined) {
        throw new RequiredError(
          'modelName',
          'Required parameter modelName was null or undefined when calling connectVoiceToModel.'
        );
      }
      // verify required parameter 'provider' is not null or undefined
      if (provider === null || provider === undefined) {
        throw new RequiredError(
          'provider',
          'Required parameter provider was null or undefined when calling connectVoiceToModel.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/connect-model`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelName !== undefined) {
        localVarQueryParameter['modelName'] = modelName;
      }

      if (provider !== undefined) {
        localVarQueryParameter['provider'] = provider;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Voice
     * @param {VoiceCreateOrUpdate} voiceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVoice(voiceCreateOrUpdate: VoiceCreateOrUpdate, options: any = {}): RequestArgs {
      // verify required parameter 'voiceCreateOrUpdate' is not null or undefined
      if (voiceCreateOrUpdate === null || voiceCreateOrUpdate === undefined) {
        throw new RequiredError(
          'voiceCreateOrUpdate',
          'Required parameter voiceCreateOrUpdate was null or undefined when calling createVoice.'
        );
      }
      const localVarPath = `/api/voices`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof voiceCreateOrUpdate !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(voiceCreateOrUpdate !== undefined ? voiceCreateOrUpdate : {})
        : voiceCreateOrUpdate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Voice info about new version
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewVersionInfo(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling getNewVersionInfo.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/new_version`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Models
     * @param {number} voiceId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicReleaseModels(voiceId: number, page?: number, pageSize?: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling getPublicReleaseModels.'
        );
      }
      const localVarPath = `/api/public/voices/{voiceId}/models`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Voice Info
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVoice(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling getPublishedVoice.'
        );
      }
      const localVarPath = `/api/public/voices/{voiceId}`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Voices Info
     * @param {Array<string>} [tags]
     * @param {'MALE' | 'FEMALE'} [gender]
     * @param {Array<'ru_RU' | 'en_GB' | 'en_US' | 'es_ES' | 'pt_PT' | 'pt_BR' | 'hi_IN' | 'it_IT' | 'fr_FR' | 'de_DE' | 'zh_CN'>} [languages]
     * @param {'NEWEST'} [sort]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVoices(
      tags?: Array<string>,
      gender?: 'MALE' | 'FEMALE',
      languages?: Array<
        'ru_RU' | 'en_GB' | 'en_US' | 'es_ES' | 'pt_PT' | 'pt_BR' | 'hi_IN' | 'it_IT' | 'fr_FR' | 'de_DE' | 'zh_CN'
      >,
      sort?: 'NEWEST',
      page?: number,
      pageSize?: number,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/api/public/voices`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (tags) {
        localVarQueryParameter['tags'] = tags;
      }

      if (gender !== undefined) {
        localVarQueryParameter['gender'] = gender;
      }

      if (languages) {
        localVarQueryParameter['languages'] = languages;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get voices available for synthesis
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSynthesizableVoices(page?: number, pageSize?: number, options: any = {}): RequestArgs {
      const localVarPath = `/api/voices/available-for-synthesis`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Voice Info
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoice(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError('voiceId', 'Required parameter voiceId was null or undefined when calling getVoice.');
      }
      const localVarPath = `/api/voices/{voiceId}`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoicePrice(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling getVoicePrice.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/price`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoicePrice1(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling getVoicePrice1.'
        );
      }
      const localVarPath = `/api/public/voices/{voiceId}/price`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Voices Info
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoices(page?: number, pageSize?: number, options: any = {}): RequestArgs {
      const localVarPath = `/api/voices`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update Voice
     * @param {number} voiceId
     * @param {VoiceCreateOrUpdate} voiceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchVoice(voiceId: number, voiceCreateOrUpdate: VoiceCreateOrUpdate, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError('voiceId', 'Required parameter voiceId was null or undefined when calling patchVoice.');
      }
      // verify required parameter 'voiceCreateOrUpdate' is not null or undefined
      if (voiceCreateOrUpdate === null || voiceCreateOrUpdate === undefined) {
        throw new RequiredError(
          'voiceCreateOrUpdate',
          'Required parameter voiceCreateOrUpdate was null or undefined when calling patchVoice.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof voiceCreateOrUpdate !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(voiceCreateOrUpdate !== undefined ? voiceCreateOrUpdate : {})
        : voiceCreateOrUpdate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {PublishVoiceRequest} publishVoiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishVoice(voiceId: number, publishVoiceRequest: PublishVoiceRequest, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling publishVoice.'
        );
      }
      // verify required parameter 'publishVoiceRequest' is not null or undefined
      if (publishVoiceRequest === null || publishVoiceRequest === undefined) {
        throw new RequiredError(
          'publishVoiceRequest',
          'Required parameter publishVoiceRequest was null or undefined when calling publishVoice.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/publish`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof publishVoiceRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(publishVoiceRequest !== undefined ? publishVoiceRequest : {})
        : publishVoiceRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {VoicePriceCreate} voicePriceCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setVoicePrice(voiceId: number, voicePriceCreate: VoicePriceCreate, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling setVoicePrice.'
        );
      }
      // verify required parameter 'voicePriceCreate' is not null or undefined
      if (voicePriceCreate === null || voicePriceCreate === undefined) {
        throw new RequiredError(
          'voicePriceCreate',
          'Required parameter voicePriceCreate was null or undefined when calling setVoicePrice.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/price`.replace(`{${'voiceId'}}`, encodeURIComponent(String(voiceId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof voicePriceCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(voicePriceCreate !== undefined ? voicePriceCreate : {})
        : voicePriceCreate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unpublishVoice(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling unpublishVoice.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/unpublish`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VoiceApi - functional programming interface
 * @export
 */
export const VoiceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Archive Voice
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveVoice(voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).archiveVoice(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Connect voice to TTS model
     * @param {number} voiceId
     * @param {string} modelName
     * @param {string} provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectVoiceToModel(
      voiceId: number,
      modelName: string,
      provider: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).connectVoiceToModel(
        voiceId,
        modelName,
        provider,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create Voice
     * @param {VoiceCreateOrUpdate} voiceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVoice(
      voiceCreateOrUpdate: VoiceCreateOrUpdate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).createVoice(voiceCreateOrUpdate, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get Voice info about new version
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewVersionInfo(
      voiceId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewVersionView> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).getNewVersionInfo(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get All Models
     * @param {number} voiceId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicReleaseModels(
      voiceId: number,
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicModelView>> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).getPublicReleaseModels(
        voiceId,
        page,
        pageSize,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get Voice Info
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVoice(
      voiceId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishedVoiceViewWithUsedVersion> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).getPublishedVoice(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get All Voices Info
     * @param {Array<string>} [tags]
     * @param {'MALE' | 'FEMALE'} [gender]
     * @param {Array<'ru_RU' | 'en_GB' | 'en_US' | 'es_ES' | 'pt_PT' | 'pt_BR' | 'hi_IN' | 'it_IT' | 'fr_FR' | 'de_DE' | 'zh_CN'>} [languages]
     * @param {'NEWEST'} [sort]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVoices(
      tags?: Array<string>,
      gender?: 'MALE' | 'FEMALE',
      languages?: Array<
        'ru_RU' | 'en_GB' | 'en_US' | 'es_ES' | 'pt_PT' | 'pt_BR' | 'hi_IN' | 'it_IT' | 'fr_FR' | 'de_DE' | 'zh_CN'
      >,
      sort?: 'NEWEST',
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublishedVoiceView>> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).getPublishedVoices(
        tags,
        gender,
        languages,
        sort,
        page,
        pageSize,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get voices available for synthesis
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSynthesizableVoices(
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SynthesizableVoice>> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).getUserSynthesizableVoices(
        page,
        pageSize,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get Voice Info
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoice(voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceView> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).getVoice(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoicePrice(
      voiceId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoicePriceView> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).getVoicePrice(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoicePrice1(voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).getVoicePrice1(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get All Voices Info
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoices(
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoiceView>> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).getVoices(page, pageSize, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update Voice
     * @param {number} voiceId
     * @param {VoiceCreateOrUpdate} voiceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchVoice(
      voiceId: number,
      voiceCreateOrUpdate: VoiceCreateOrUpdate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).patchVoice(
        voiceId,
        voiceCreateOrUpdate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {PublishVoiceRequest} publishVoiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishVoice(
      voiceId: number,
      publishVoiceRequest: PublishVoiceRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).publishVoice(
        voiceId,
        publishVoiceRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {VoicePriceCreate} voicePriceCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setVoicePrice(
      voiceId: number,
      voicePriceCreate: VoicePriceCreate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoicePriceView> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).setVoicePrice(
        voiceId,
        voicePriceCreate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unpublishVoice(voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = VoiceApiAxiosParamCreator(configuration).unpublishVoice(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * VoiceApi - factory interface
 * @export
 */
export const VoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Archive Voice
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveVoice(voiceId: number, options?: any): AxiosPromise<void> {
      return VoiceApiFp(configuration).archiveVoice(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @summary Connect voice to TTS model
     * @param {number} voiceId
     * @param {string} modelName
     * @param {string} provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectVoiceToModel(voiceId: number, modelName: string, provider: string, options?: any): AxiosPromise<void> {
      return VoiceApiFp(configuration).connectVoiceToModel(voiceId, modelName, provider, options)(axios, basePath);
    },
    /**
     *
     * @summary Create Voice
     * @param {VoiceCreateOrUpdate} voiceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVoice(voiceCreateOrUpdate: VoiceCreateOrUpdate, options?: any): AxiosPromise<number> {
      return VoiceApiFp(configuration).createVoice(voiceCreateOrUpdate, options)(axios, basePath);
    },
    /**
     *
     * @summary Get Voice info about new version
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewVersionInfo(voiceId: number, options?: any): AxiosPromise<NewVersionView> {
      return VoiceApiFp(configuration).getNewVersionInfo(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get All Models
     * @param {number} voiceId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicReleaseModels(
      voiceId: number,
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<Array<PublicModelView>> {
      return VoiceApiFp(configuration).getPublicReleaseModels(voiceId, page, pageSize, options)(axios, basePath);
    },
    /**
     *
     * @summary Get Voice Info
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVoice(voiceId: number, options?: any): AxiosPromise<PublishedVoiceViewWithUsedVersion> {
      return VoiceApiFp(configuration).getPublishedVoice(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get All Voices Info
     * @param {Array<string>} [tags]
     * @param {'MALE' | 'FEMALE'} [gender]
     * @param {Array<'ru_RU' | 'en_GB' | 'en_US' | 'es_ES' | 'pt_PT' | 'pt_BR' | 'hi_IN' | 'it_IT' | 'fr_FR' | 'de_DE' | 'zh_CN'>} [languages]
     * @param {'NEWEST'} [sort]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVoices(
      tags?: Array<string>,
      gender?: 'MALE' | 'FEMALE',
      languages?: Array<
        'ru_RU' | 'en_GB' | 'en_US' | 'es_ES' | 'pt_PT' | 'pt_BR' | 'hi_IN' | 'it_IT' | 'fr_FR' | 'de_DE' | 'zh_CN'
      >,
      sort?: 'NEWEST',
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<Array<PublishedVoiceView>> {
      return VoiceApiFp(configuration).getPublishedVoices(
        tags,
        gender,
        languages,
        sort,
        page,
        pageSize,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get voices available for synthesis
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSynthesizableVoices(
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<Array<SynthesizableVoice>> {
      return VoiceApiFp(configuration).getUserSynthesizableVoices(page, pageSize, options)(axios, basePath);
    },
    /**
     *
     * @summary Get Voice Info
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoice(voiceId: number, options?: any): AxiosPromise<VoiceView> {
      return VoiceApiFp(configuration).getVoice(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoicePrice(voiceId: number, options?: any): AxiosPromise<VoicePriceView> {
      return VoiceApiFp(configuration).getVoicePrice(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoicePrice1(voiceId: number, options?: any): AxiosPromise<string> {
      return VoiceApiFp(configuration).getVoicePrice1(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get All Voices Info
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoices(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<VoiceView>> {
      return VoiceApiFp(configuration).getVoices(page, pageSize, options)(axios, basePath);
    },
    /**
     *
     * @summary Update Voice
     * @param {number} voiceId
     * @param {VoiceCreateOrUpdate} voiceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchVoice(voiceId: number, voiceCreateOrUpdate: VoiceCreateOrUpdate, options?: any): AxiosPromise<void> {
      return VoiceApiFp(configuration).patchVoice(voiceId, voiceCreateOrUpdate, options)(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {PublishVoiceRequest} publishVoiceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishVoice(voiceId: number, publishVoiceRequest: PublishVoiceRequest, options?: any): AxiosPromise<void> {
      return VoiceApiFp(configuration).publishVoice(voiceId, publishVoiceRequest, options)(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {VoicePriceCreate} voicePriceCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setVoicePrice(voiceId: number, voicePriceCreate: VoicePriceCreate, options?: any): AxiosPromise<VoicePriceView> {
      return VoiceApiFp(configuration).setVoicePrice(voiceId, voicePriceCreate, options)(axios, basePath);
    },
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unpublishVoice(voiceId: number, options?: any): AxiosPromise<void> {
      return VoiceApiFp(configuration).unpublishVoice(voiceId, options)(axios, basePath);
    },
  };
};

/**
 * VoiceApi - object-oriented interface
 * @export
 * @class VoiceApi
 * @extends {BaseAPI}
 */
export class VoiceApi extends BaseAPI {
  /**
   *
   * @summary Archive Voice
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public archiveVoice(voiceId: number, options?: any) {
    return VoiceApiFp(this.configuration).archiveVoice(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Connect voice to TTS model
   * @param {number} voiceId
   * @param {string} modelName
   * @param {string} provider
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public connectVoiceToModel(voiceId: number, modelName: string, provider: string, options?: any) {
    return VoiceApiFp(this.configuration).connectVoiceToModel(
      voiceId,
      modelName,
      provider,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create Voice
   * @param {VoiceCreateOrUpdate} voiceCreateOrUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public createVoice(voiceCreateOrUpdate: VoiceCreateOrUpdate, options?: any) {
    return VoiceApiFp(this.configuration).createVoice(voiceCreateOrUpdate, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get Voice info about new version
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public getNewVersionInfo(voiceId: number, options?: any) {
    return VoiceApiFp(this.configuration).getNewVersionInfo(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get All Models
   * @param {number} voiceId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public getPublicReleaseModels(voiceId: number, page?: number, pageSize?: number, options?: any) {
    return VoiceApiFp(this.configuration).getPublicReleaseModels(
      voiceId,
      page,
      pageSize,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get Voice Info
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public getPublishedVoice(voiceId: number, options?: any) {
    return VoiceApiFp(this.configuration).getPublishedVoice(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get All Voices Info
   * @param {Array<string>} [tags]
   * @param {'MALE' | 'FEMALE'} [gender]
   * @param {Array<'ru_RU' | 'en_GB' | 'en_US' | 'es_ES' | 'pt_PT' | 'pt_BR' | 'hi_IN' | 'it_IT' | 'fr_FR' | 'de_DE' | 'zh_CN'>} [languages]
   * @param {'NEWEST'} [sort]
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public getPublishedVoices(
    tags?: Array<string>,
    gender?: 'MALE' | 'FEMALE',
    languages?: Array<
      'ru_RU' | 'en_GB' | 'en_US' | 'es_ES' | 'pt_PT' | 'pt_BR' | 'hi_IN' | 'it_IT' | 'fr_FR' | 'de_DE' | 'zh_CN'
    >,
    sort?: 'NEWEST',
    page?: number,
    pageSize?: number,
    options?: any
  ) {
    return VoiceApiFp(this.configuration).getPublishedVoices(
      tags,
      gender,
      languages,
      sort,
      page,
      pageSize,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get voices available for synthesis
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public getUserSynthesizableVoices(page?: number, pageSize?: number, options?: any) {
    return VoiceApiFp(this.configuration).getUserSynthesizableVoices(
      page,
      pageSize,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get Voice Info
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public getVoice(voiceId: number, options?: any) {
    return VoiceApiFp(this.configuration).getVoice(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public getVoicePrice(voiceId: number, options?: any) {
    return VoiceApiFp(this.configuration).getVoicePrice(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public getVoicePrice1(voiceId: number, options?: any) {
    return VoiceApiFp(this.configuration).getVoicePrice1(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get All Voices Info
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public getVoices(page?: number, pageSize?: number, options?: any) {
    return VoiceApiFp(this.configuration).getVoices(page, pageSize, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update Voice
   * @param {number} voiceId
   * @param {VoiceCreateOrUpdate} voiceCreateOrUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public patchVoice(voiceId: number, voiceCreateOrUpdate: VoiceCreateOrUpdate, options?: any) {
    return VoiceApiFp(this.configuration).patchVoice(voiceId, voiceCreateOrUpdate, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {PublishVoiceRequest} publishVoiceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public publishVoice(voiceId: number, publishVoiceRequest: PublishVoiceRequest, options?: any) {
    return VoiceApiFp(this.configuration).publishVoice(
      voiceId,
      publishVoiceRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {VoicePriceCreate} voicePriceCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public setVoicePrice(voiceId: number, voicePriceCreate: VoicePriceCreate, options?: any) {
    return VoiceApiFp(this.configuration).setVoicePrice(voiceId, voicePriceCreate, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceApi
   */
  public unpublishVoice(voiceId: number, options?: any) {
    return VoiceApiFp(this.configuration).unpublishVoice(voiceId, options)(this.axios, this.basePath);
  }
}

/**
 * VoiceConsumerControllerApi - axios parameter creator
 * @export
 */
export const VoiceConsumerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoiceConsumer(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling getVoiceConsumer.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/consumer`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VoiceConsumerControllerApi - functional programming interface
 * @export
 */
export const VoiceConsumerControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoiceConsumer(
      voiceId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoiceConsumerView> {
      const localVarAxiosArgs = VoiceConsumerControllerApiAxiosParamCreator(configuration).getVoiceConsumer(
        voiceId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * VoiceConsumerControllerApi - factory interface
 * @export
 */
export const VoiceConsumerControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoiceConsumer(voiceId: number, options?: any): AxiosPromise<VoiceConsumerView> {
      return VoiceConsumerControllerApiFp(configuration).getVoiceConsumer(voiceId, options)(axios, basePath);
    },
  };
};

/**
 * VoiceConsumerControllerApi - object-oriented interface
 * @export
 * @class VoiceConsumerControllerApi
 * @extends {BaseAPI}
 */
export class VoiceConsumerControllerApi extends BaseAPI {
  /**
   *
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceConsumerControllerApi
   */
  public getVoiceConsumer(voiceId: number, options?: any) {
    return VoiceConsumerControllerApiFp(this.configuration).getVoiceConsumer(voiceId, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * VoiceRoleApi - axios parameter creator
 * @export
 */
export const VoiceRoleApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add voice member
     * @param {number} voiceId
     * @param {VoiceMemberToAdd} voiceMemberToAdd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMemberToVoice(voiceId: number, voiceMemberToAdd: VoiceMemberToAdd, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling addMemberToVoice.'
        );
      }
      // verify required parameter 'voiceMemberToAdd' is not null or undefined
      if (voiceMemberToAdd === null || voiceMemberToAdd === undefined) {
        throw new RequiredError(
          'voiceMemberToAdd',
          'Required parameter voiceMemberToAdd was null or undefined when calling addMemberToVoice.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/members`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof voiceMemberToAdd !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(voiceMemberToAdd !== undefined ? voiceMemberToAdd : {})
        : voiceMemberToAdd || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get voice members
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoiceMembers(voiceId: number, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling getVoiceMembers.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/members`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update voice member role
     * @param {number} voiceId
     * @param {ModifyVoiceMember} modifyVoiceMember
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modifyVoiceMember(voiceId: number, modifyVoiceMember: ModifyVoiceMember, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling modifyVoiceMember.'
        );
      }
      // verify required parameter 'modifyVoiceMember' is not null or undefined
      if (modifyVoiceMember === null || modifyVoiceMember === undefined) {
        throw new RequiredError(
          'modifyVoiceMember',
          'Required parameter modifyVoiceMember was null or undefined when calling modifyVoiceMember.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/members`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof modifyVoiceMember !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(modifyVoiceMember !== undefined ? modifyVoiceMember : {})
        : modifyVoiceMember || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete voice member
     * @param {number} voiceId
     * @param {VoiceMemberToRemove} voiceMemberToRemove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeMemberFromVoice(voiceId: number, voiceMemberToRemove: VoiceMemberToRemove, options: any = {}): RequestArgs {
      // verify required parameter 'voiceId' is not null or undefined
      if (voiceId === null || voiceId === undefined) {
        throw new RequiredError(
          'voiceId',
          'Required parameter voiceId was null or undefined when calling removeMemberFromVoice.'
        );
      }
      // verify required parameter 'voiceMemberToRemove' is not null or undefined
      if (voiceMemberToRemove === null || voiceMemberToRemove === undefined) {
        throw new RequiredError(
          'voiceMemberToRemove',
          'Required parameter voiceMemberToRemove was null or undefined when calling removeMemberFromVoice.'
        );
      }
      const localVarPath = `/api/voices/{voiceId}/members`.replace(
        `{${'voiceId'}}`,
        encodeURIComponent(String(voiceId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof voiceMemberToRemove !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(voiceMemberToRemove !== undefined ? voiceMemberToRemove : {})
        : voiceMemberToRemove || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VoiceRoleApi - functional programming interface
 * @export
 */
export const VoiceRoleApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add voice member
     * @param {number} voiceId
     * @param {VoiceMemberToAdd} voiceMemberToAdd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMemberToVoice(
      voiceId: number,
      voiceMemberToAdd: VoiceMemberToAdd,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = VoiceRoleApiAxiosParamCreator(configuration).addMemberToVoice(
        voiceId,
        voiceMemberToAdd,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get voice members
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoiceMembers(
      voiceId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoiceMemberView>> {
      const localVarAxiosArgs = VoiceRoleApiAxiosParamCreator(configuration).getVoiceMembers(voiceId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update voice member role
     * @param {number} voiceId
     * @param {ModifyVoiceMember} modifyVoiceMember
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modifyVoiceMember(
      voiceId: number,
      modifyVoiceMember: ModifyVoiceMember,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = VoiceRoleApiAxiosParamCreator(configuration).modifyVoiceMember(
        voiceId,
        modifyVoiceMember,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete voice member
     * @param {number} voiceId
     * @param {VoiceMemberToRemove} voiceMemberToRemove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeMemberFromVoice(
      voiceId: number,
      voiceMemberToRemove: VoiceMemberToRemove,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = VoiceRoleApiAxiosParamCreator(configuration).removeMemberFromVoice(
        voiceId,
        voiceMemberToRemove,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * VoiceRoleApi - factory interface
 * @export
 */
export const VoiceRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Add voice member
     * @param {number} voiceId
     * @param {VoiceMemberToAdd} voiceMemberToAdd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMemberToVoice(voiceId: number, voiceMemberToAdd: VoiceMemberToAdd, options?: any): AxiosPromise<void> {
      return VoiceRoleApiFp(configuration).addMemberToVoice(voiceId, voiceMemberToAdd, options)(axios, basePath);
    },
    /**
     *
     * @summary Get voice members
     * @param {number} voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVoiceMembers(voiceId: number, options?: any): AxiosPromise<Array<VoiceMemberView>> {
      return VoiceRoleApiFp(configuration).getVoiceMembers(voiceId, options)(axios, basePath);
    },
    /**
     *
     * @summary Update voice member role
     * @param {number} voiceId
     * @param {ModifyVoiceMember} modifyVoiceMember
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modifyVoiceMember(voiceId: number, modifyVoiceMember: ModifyVoiceMember, options?: any): AxiosPromise<void> {
      return VoiceRoleApiFp(configuration).modifyVoiceMember(voiceId, modifyVoiceMember, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete voice member
     * @param {number} voiceId
     * @param {VoiceMemberToRemove} voiceMemberToRemove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeMemberFromVoice(
      voiceId: number,
      voiceMemberToRemove: VoiceMemberToRemove,
      options?: any
    ): AxiosPromise<void> {
      return VoiceRoleApiFp(configuration).removeMemberFromVoice(
        voiceId,
        voiceMemberToRemove,
        options
      )(axios, basePath);
    },
  };
};

/**
 * VoiceRoleApi - object-oriented interface
 * @export
 * @class VoiceRoleApi
 * @extends {BaseAPI}
 */
export class VoiceRoleApi extends BaseAPI {
  /**
   *
   * @summary Add voice member
   * @param {number} voiceId
   * @param {VoiceMemberToAdd} voiceMemberToAdd
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceRoleApi
   */
  public addMemberToVoice(voiceId: number, voiceMemberToAdd: VoiceMemberToAdd, options?: any) {
    return VoiceRoleApiFp(this.configuration).addMemberToVoice(
      voiceId,
      voiceMemberToAdd,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get voice members
   * @param {number} voiceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceRoleApi
   */
  public getVoiceMembers(voiceId: number, options?: any) {
    return VoiceRoleApiFp(this.configuration).getVoiceMembers(voiceId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update voice member role
   * @param {number} voiceId
   * @param {ModifyVoiceMember} modifyVoiceMember
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceRoleApi
   */
  public modifyVoiceMember(voiceId: number, modifyVoiceMember: ModifyVoiceMember, options?: any) {
    return VoiceRoleApiFp(this.configuration).modifyVoiceMember(
      voiceId,
      modifyVoiceMember,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete voice member
   * @param {number} voiceId
   * @param {VoiceMemberToRemove} voiceMemberToRemove
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VoiceRoleApi
   */
  public removeMemberFromVoice(voiceId: number, voiceMemberToRemove: VoiceMemberToRemove, options?: any) {
    return VoiceRoleApiFp(this.configuration).removeMemberFromVoice(
      voiceId,
      voiceMemberToRemove,
      options
    )(this.axios, this.basePath);
  }
}
