import React from 'react';
import localization from '../../localization';

import './style.scss';
import PlaceholderPageImage from '../../img/PlaceholderPageImage.svg';

export default function PlaceholderPage({ name }) {
  return (
    <div className='placeholder-page__container'>
      <h3>{localization.translate('placeholderHeader')}</h3>
      <h6>{localization.translate('returnSoon')}</h6>
      <div>
        <img src={PlaceholderPageImage} alt='Placeholder' />
      </div>
    </div>
  );
}
