import { Tooltip } from '@just-ai/just-ui/dist/Tooltip';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { t } from '../../../../localization';
import { SCREEN_WIDTH_PHONE } from '../../constants';
import { useMediaQuery } from 'react-responsive';

type OfflineModeProps = {
  loading: boolean;
  isDirty: boolean;
  offline: boolean;
};
export default function OfflineModeIndicator(props: OfflineModeProps) {
  const { offline, loading, isDirty } = props;
  const phoneOnly = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  if (phoneOnly)
    return (
      <div className='header__offline-block'>
        {loading && (
          <div className='loading-block'>
            <Icon name='faCircleNotch' className='fa-spin' color='secondary' />
          </div>
        )}
      </div>
    );
  if (!offline && !loading) return <div className='header__offline-block' />;
  return (
    <div className='header__offline-block'>
      {loading ? (
        <div className='loading-block'>
          <Icon name='faCircleNotch' className='fa-spin' color='secondary' />
          <p className='tp-3 grey-text-1'>{t('saving')}...</p>
        </div>
      ) : (
        <>
          <p id='offlineMode' className='tp-3 grey-text-1'>
            {isDirty && <span className='dirty-indicator' />}
            Offline
          </p>
          <Tooltip placement='bottom' target='offlineMode' className='offline-mode-tooltip' fade={false}>
            {isDirty && <p className='st-3 mb-2'>{t('projectDirty')}</p>}
            {t('offlineModeInfo')}
          </Tooltip>
        </>
      )}
    </div>
  );
}
