import React from 'react';
import { Button } from '@just-ai/just-ui';
import { Link } from 'react-router-dom';

import { t } from '../../../../localization';
import { useAppContext } from '../../../../AppContext';

export const Promo = () => {
  const { isLoggedIn } = useAppContext();
  return (
    <div className='voices-catalog__promo'>
      <h4>{t('catalog promo header')}</h4>
      <p className='tp-4'>{t('catalog promo text')}</p>
      <Link
        to={{
          pathname: isLoggedIn ? '/my' : '/create-voice',
          state: { fromPromo: true },
        }}
      >
        <Button color='primary'>{t('catalog promo button caption')}</Button>
      </Link>
      <div className='gradient-bg-1' />
      <div className='gradient-bg-2' />
    </div>
  );
};
