import React, { useCallback } from 'react';
import AVLogo from '../../../../img/logos/AV-logo.svg';
import AVLogoDark from '../../../../img/logos/AV-logo--dark.svg';
import TovieLogo from '../../../../img/logos/tovie-logo.svg';
import TovieLogoDark from '../../../../img/logos/tovie-logo--dark.svg';
import { useAppContext } from '../../../../AppContext';

const Logo = ({ dark }) => {
  const { isUSDInstance, productName } = useAppContext();

  const getLogo = useCallback(() => {
    if (isUSDInstance) return dark ? TovieLogoDark : TovieLogo;
    return dark ? AVLogoDark : AVLogo;
  }, [isUSDInstance, dark]);

  if (isUSDInstance === null) return null;
  return (
    <div className='logo-container'>
      <img src={getLogo()} alt={`${productName} logo`} />
    </div>
  );
};

export default Logo;
