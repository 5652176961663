import { PhaseStatusEnum, PhaseEnum, ModelView, SampleStateEnum } from '../api/facade/client';
import { t } from '../localization';
import { ModelStatus } from '../modules/Library/model/Voice';
import { emphasisRegex, redWordRegex, slackChannelURL, ROLES } from '../modules/Library/constants';
import * as accessFunctions from './accessFunctions';
import { AllVoicesPageFilters } from '../modules/Library/pages/AllVoicesPage';
import { History } from 'history';
import { StyleSettings } from '../api/dubber/client';

export const convertSampleStatus = (initialStatus: { sampleState: SampleStateEnum }): SampleStateEnum => {
  return SampleStateEnum[initialStatus.sampleState] || 'ERROR';
};

export const convertModelStatus = (initialStatus: {
  phase: PhaseEnum;
  status: ModelView['phaseStatus'];
}): ModelStatus => {
  if (initialStatus.status === PhaseStatusEnum.FAILURE) return 'ERROR';
  if (
    (initialStatus.phase === PhaseEnum.TRAINING && initialStatus.status === PhaseStatusEnum.PROCESSING) ||
    (initialStatus.phase === PhaseEnum.TUNING && initialStatus.status === PhaseStatusEnum.PROCESSING)
  )
    return 'TRAINING';
  if (
    (initialStatus.phase === PhaseEnum.WAITTRAINING && initialStatus.status === PhaseStatusEnum.SUCCESS) ||
    (initialStatus.phase === PhaseEnum.CREATION && initialStatus.status === PhaseStatusEnum.SUCCESS)
  ) {
    return 'IN_QUEUE';
  }
  if (initialStatus.phase === PhaseEnum.TUNING && initialStatus.status === PhaseStatusEnum.SUCCESS) {
    return 'TRAINED';
  }
  return 'ERROR';
};

export const convertSampleReferenceToAudioLink = (reference?: string) =>
  reference ? `${window.origin}/api${reference.startsWith('/') ? reference : `/${reference}`}/download` : '';

export function getGoogleTag() {
  const testEnvTag = 'GTM-PFWDJ2F';
  const prodEnvTag = 'GTM-KHB42L5';
  if (window.location.host.startsWith('localhost')) return '';
  return window.location.host.endsWith('lo.test-ai.net') ? testEnvTag : prodEnvTag;
}

export const getSecondsToMinutesAndSeconds = (time: number) => {
  if (time === 0) {
    return '0:00';
  }
  const minutes = Math.floor(time / 60);
  const seconds = String(time - minutes * 60);
  return `${minutes}:${seconds.length === 1 ? '0' + seconds : seconds}`;
};

export const convertNameToPlaceholder = (name: string) => {
  return name
    .replace(/[^A-Za-zА-Яа-я0-9]/g, '')
    .slice(0, 2)
    .toLocaleUpperCase();
};

export const humanizeDuration = (duration: number, expanded?: boolean) => {
  const calcSeconds = Math.ceil((duration / 1000) % 60);
  const calcMinutes = Math.floor((duration / 1000 / 60) % 60);
  const calcHours = Math.floor(duration / 1000 / 60 / 60);

  const checkForZero = (value: number) => {
    if (value < 10 && value > -10 && !expanded) {
      return `0${value}`;
    }
    return value;
  };

  return expanded
    ? `${checkForZero(calcHours)}\u2009${t('hoursShort')} ${
        checkForZero(calcMinutes) ? checkForZero(calcMinutes) + '\u2009' + t('minute') : ''
      }`.trim()
    : `${checkForZero(calcHours) + ':'}${checkForZero(calcMinutes) + ':'}${checkForZero(calcSeconds)}`.trim();
};

export const generateSampleRoute = (
  voiceId: number | string,
  catalogId: number | string,
  sampleId: number | string
) => {
  return `/my/voice/${voiceId}/training/new_version/${catalogId}/sample/${sampleId}`;
};

export function getCroppedImg(image: HTMLImageElement, crop, fileName: string): Promise<any> {
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio || 1;
  const MAX_CROP_SIZE = 700;

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const cropScale = MAX_CROP_SIZE / crop.width;

  canvas.width = MAX_CROP_SIZE * pixelRatio;
  canvas.height = MAX_CROP_SIZE * pixelRatio;

  const ctx = canvas.getContext('2d');

  if (!ctx) throw new Error();
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    scaleX * crop.width,
    scaleY * crop.height,
    0,
    0,
    crop.width * cropScale,
    crop.height * cropScale
  );
  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        if (!blob) return;
        resolve(blob);
      },
      'image/png',
      1
    );
  });
}

export const pushToLoginWithState = ({
  history,
  clickedVersion,
  authPath,
}: {
  history: History;
  clickedVersion: number;
  authPath: string;
}) => {
  window.location.href = `${authPath}${window.location.origin}${history.location.pathname}?version=${clickedVersion}`;
};

export const parseString = ({ string, ref }: { string: string; ref: React.RefObject<HTMLDivElement> }) => {
  if (!ref.current) return;
  let replaced = string.replace(/\n\r?/g, '<br />');
  while (redWordRegex.test(replaced)) {
    replaced = replaced.replace(redWordRegex, `<span>${replaced.match(redWordRegex)?.[0].split('#').pop()}</span>`);
  }
  while (emphasisRegex.test(replaced)) {
    replaced = replaced.replace(emphasisRegex, `<span>${replaced.match(emphasisRegex)?.[0][0]}&#x301;</span>`);
  }
  ref.current.innerHTML = replaced;
};

export const removeSpecials = (string: string) => {
  let replaced = string || '';
  while (redWordRegex.test(replaced)) {
    replaced = replaced.replace(redWordRegex, `${replaced.match(redWordRegex)?.[0].split('#').pop()}`);
  }
  while (emphasisRegex.test(replaced)) {
    replaced = replaced.replace(emphasisRegex, `${replaced.match(emphasisRegex)?.[0][0]}`);
  }

  return replaced;
};

export const requestContact = async ({ email, phoneNumber }: { email: string; phoneNumber: string }) => {
  const reqBody = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: 'Лендинг: запрос на создание голоса',
          emoji: true,
        },
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Email:*\n ${email}`,
          },
          {
            type: 'mrkdwn',
            text: `*Phone:*\n ${phoneNumber}`,
          },
        ],
      },
      {
        type: 'divider',
      },
    ],
  };
  try {
    await fetch(slackChannelURL, { method: 'POST', body: JSON.stringify(reqBody) });
  } catch (error) {
    console.error('Failed to send request to slack hook', error);
    throw error;
  }
};

export const requestAccessForVoice = async ({
  email,
  voiceId,
  voiceName,
  phone,
}: {
  email: string;
  voiceId: string;
  voiceName: string;
  phone?: string;
}) => {
  const reqBody = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: 'Запрос на лицензионный голос',
          emoji: true,
        },
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Who:*\n ${email}`,
          },
          {
            type: 'mrkdwn',
            text: `*Phone number:*\n ${phone}`,
          },
          {
            type: 'mrkdwn',
            text: `*Voice id:*\n ${voiceId}`,
          },
          {
            type: 'mrkdwn',
            text: `*Voice name:*\n ${voiceName}`,
          },
        ],
      },
      {
        type: 'divider',
      },
    ],
  };
  try {
    await fetch(slackChannelURL, { method: 'POST', body: JSON.stringify(reqBody) });
  } catch (error) {
    console.error('Failed to send request to slack hook', error);
    throw error;
  }
};

export const requestServer = async ({ email, userId, phone }: { email: string; userId: string; phone?: string }) => {
  const reqBody = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: 'Запрос выделенный сервер',
          emoji: true,
        },
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Email:*\n ${email}`,
          },
          {
            type: 'mrkdwn',
            text: `*User_id:*\n ${userId}`,
          },
          {
            type: 'mrkdwn',
            text: `*Phone number:*\n ${phone}`,
          },
        ],
      },
      {
        type: 'divider',
      },
    ],
  };
  try {
    await fetch(slackChannelURL, { method: 'POST', body: JSON.stringify(reqBody) });
  } catch (error) {
    console.error('Failed to send request to slack hook', error);
    throw error;
  }
};

export const getActiveFilters = ({
  initialObject,
  currentObject,
}: {
  initialObject: AllVoicesPageFilters;
  currentObject: AllVoicesPageFilters;
}) => {
  let activeFilters = 0;
  Object.keys(initialObject).map(key => {
    if (typeof initialObject[key] === 'object') {
      if (Array.isArray(initialObject[key]) && initialObject[key].length !== currentObject[key].length) {
        return (activeFilters += 1);
      }
      if (
        !Array.isArray(initialObject[key]) &&
        JSON.stringify(initialObject[key]) !== JSON.stringify(currentObject[key])
      ) {
        return (activeFilters += 1);
      }
    } else if (initialObject[key] !== currentObject[key]) {
      activeFilters += 1;
    }
    return activeFilters;
  });

  return activeFilters;
};

export const getRoleNameFromId = (roleId: number) => {
  const key = Object.keys(ROLES).find(key => ROLES[key].id === roleId) || '';
  return ROLES[key].name;
};

export function reorderList({ list, startIndex, endIndex }: { list: any[]; startIndex: number; endIndex: number }) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const currencyMap = {
  USD: 'en-US',
  RUB: 'ru-RU',
};

export const normalizeCurrencyByCode = (currencyCode: string, value: number = 0) => {
  if (currencyCode !== 'USD' && currencyCode !== 'RUB') return;
  return new Intl.NumberFormat(currencyMap[currencyCode], {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 5,
  })
    .format(isNaN(value) ? 0 : value)
    .replace(/(\.|,)00(\D*$)/g, '$2');
};

export const minVoicePrices = {
  RUB: 0.5,
  USD: 0.007,
};

export const addMarkupToText = ({
  rate,
  volume,
  style,
  pitch,
  text,
  styleSettings,
}: {
  rate?: number;
  volume?: number;
  style?: number;
  pitch?: number;
  text: string;
  styleSettings?: Omit<StyleSettings, 'default' | 'applyDefault'>;
}) => {
  if (!volume && !rate && typeof style !== 'number' && !pitch) return text;
  const volumePart = volume ? `volume="${volume.toFixed()}"` : '';
  const ratePart = rate ? `rate="${rate.toFixed()}"` : '';
  const pitchPart = pitch ? `pitch="${pitch.toFixed()}"` : '';
  const returnString =
    volumePart || ratePart || pitch ? `<prosody ${volumePart} ${ratePart} ${pitchPart}>${text}</prosody>` : text;
  if ((typeof style === 'number' && style >= 0 && styleSettings) || pitch) {
    const styleKey = style && styleSettings?.styles[style];
    const styleString = styleKey && styleSettings?.description[styleKey]?.name;
    if (styleString) return `<style name="${styleString}">${returnString}</style>`;
  }
  return returnString;
};

export function pluralize(language: string, count: number, arrayOfString: string[]) {
  const languageFormat = language === 'ru' ? 'ru' : 'en-US';
  const pluralRules = new Intl.PluralRules(languageFormat);
  const [singular, few, many, other] = arrayOfString;
  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case 'one':
      return count + ' ' + singular;
    case 'few':
      return count + ' ' + few;
    case 'many':
      return count + ' ' + many;
    case 'other':
      return count + ' ' + other;
    default:
      throw new Error('Unknown: ' + grammaticalNumber);
  }
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getCCLinkWithNavigate = (baseAuthUrl: string, currentPathname: string) => {
  return `${baseAuthUrl}${window.location.origin}${currentPathname.replace(/\/+$/, '')}?navigate`;
};

export { accessFunctions };
