import { Icon } from '@just-ai/just-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../../../localization';
import { UsagePage } from '../UsagePage';
import styles from './BalanceHistoryPage.module.scss';

export const BalanceHistoryPage = () => {
  return (
    <div className={styles['balance-history']}>
      <div className={styles['balance-history__back-container']}>
        <Link to='/account'>
          <div className={styles['balance-history__back']}>
            <Icon name='falArrowLeft' size='sm' />
            <p>{t('account')}</p>
          </div>
        </Link>
      </div>

      <UsagePage usageType='balanceUsage' />
    </div>
  );
};
