import { IconButton, Tabs } from '@just-ai/just-ui';
import React from 'react';
import { t } from '../../../../../localization';
import './Loader.scss';

export const CatalogHeaderLoader = () => {
  return (
    <div className='loader'>
      <div className='catalog-page__catalog-header'>
        <Tabs
          activeTab='notVoiced'
          closePlace='right'
          tabs={[
            {
              name: <div className='catalog-page__catalog-tab'>{t('notVoiced')}</div>,
              value: 'notVoiced',
            },
            {
              name: <div className='catalog-page__catalog-tab'>{t('voiced')}</div>,
              value: 'voiced',
            },
          ]}
        />
        <div className='catalog-page__catalog-buttons'>
          <IconButton flat name='falInboxOut' size='lg' color='secondary' />
          <IconButton flat name='falPlusSquare' size='lg' color='secondary' />
        </div>
      </div>
    </div>
  );
};
