import React, { createRef, useEffect, useState } from 'react';
import { Icon, Button, DropdownButton, DropdownToggle, DropdownItem, DropdownMenu } from '@just-ai/just-ui';
import { t } from '../../../../../localization';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone';
import { humanizeDuration } from '../../../../../utils';
import { IMediaRecorder, MediaRecorder } from 'extendable-media-recorder';

type SampleRecordProps = {
  isRecording: boolean;
  currentDevice: MediaDeviceInfo | undefined;
  inputDevices: MediaDeviceInfo[];
  localAudio: Blob | null;
  isCancelClicked: boolean;
  saveAudioLocally: boolean;
  setLocalAudio: (localAudio: Blob | null) => void;
  toggleRecording: () => void;
  checkPermission: () => void;
  setCurrentDevice: (value: MediaDeviceInfo) => void;
  setCancelClick: (value: boolean) => void;
  toggleSaveAudioLocally: () => void;
};
class State {
  inputDevices: MediaDeviceInfo[] = [];
  recordedSample: string = '';
  recordingDuration: number = 0;
}

const RecordTimer = (props: { isRecording: boolean }) => {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (props.isRecording) {
        setCurrentTime(currentTime => currentTime + 1000);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [props.isRecording]);

  return <div className='catalog-page__sample-record-duration'>{humanizeDuration(currentTime)}</div>;
};

class SampleRecord extends React.Component<SampleRecordProps, State> {
  state = new State();
  mediaRecorder?: IMediaRecorder;
  waveSurfer?: WaveSurfer;
  localAudio = createRef() as React.MutableRefObject<any>;

  componentDidMount() {
    if (this.props.inputDevices.length === 0) {
      this.props.checkPermission();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.saveAudioLocally && !prevProps.saveAudioLocally) {
      this.stopRecording();
      this.props.toggleSaveAudioLocally();
    }
    if (this.props.isRecording && !prevProps.isRecording) {
      this.mediaRecorderHandle();
    }
    if (!this.props.isRecording && prevProps.isRecording) {
      this.clearRecorder();
    }
    if (this.props.isCancelClicked && !prevProps.isCancelClicked) {
      this.clearRecorder();
    }
  }

  componentWillUnmount() {
    this.clearRecorder();
  }

  mediaRecorderHandle = () => {
    try {
      this.waveSurfer && this.waveSurfer.destroy();
      this.waveSurfer = WaveSurfer.create({
        container: '#waveform',
        height: 80,
        waveColor: '#FB501D',
        barWidth: 1,
        interact: false,
        barHeight: 80,
        cursorWidth: 0,
        plugins: [
          MicrophonePlugin.create({
            bufferSize: 2048,
            constraints: { audio: { deviceId: this.props.currentDevice?.deviceId } },
          }),
        ],
      });
      const audioChunks: any[] = [];
      this.waveSurfer.microphone.on('deviceReady', stream => {
        this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/wav' });
        this.mediaRecorder.start();
        this.mediaRecorder.addEventListener('dataavailable', (event: any) => {
          audioChunks.push(event.data);
        });
        this.mediaRecorder.addEventListener('stop', () => {
          try {
            this.localAudio.current = new Blob(audioChunks, { type: 'audio/wav' });
            if (this.props.isCancelClicked) {
              return this.props.setCancelClick(false);
            }
          } catch (error) {
            console.log('error');
          }
        });
      });
      this.waveSurfer.microphone.start();
    } catch (error) {}
  };

  getDeviceName = (string: string) => {
    if (!string) return;
    const matchStr = string.match(/\((.*)\)/);
    if (matchStr) {
      return matchStr.pop()?.replace('(', '').replace(')', '');
    } else {
      return 'No devices found';
    }
  };

  clearRecorder = () => {
    if (!this.waveSurfer || !this.mediaRecorder) return;
    this.mediaRecorder.stop();
    this.waveSurfer.microphone.stop();
  };

  cancelrecordingWithClear = () => {
    this.props.setCancelClick(true);
    this.props.toggleRecording();
    this.clearRecorder();
  };

  stopRecording = () => {
    this.mediaRecorder?.stop();
    this.waveSurfer?.microphone.stop();
    setTimeout(async () => {
      this.props.toggleRecording();
      await this.props.setLocalAudio(this.localAudio.current);
    }, 150);
  };

  render() {
    const { inputDevices } = this.props;
    return (
      <div className='catalog-page__sample-record'>
        {this.props.isRecording ? (
          <div className='waveform-container'>
            <div id='waveform' />
            {/* <div className='waveform-corner corner-top-right' />
            <div className='waveform-corner corner-top-left' />
            <div className='waveform-corner corner-bottom-right' />
            <div className='waveform-corner corner-bottom-left' /> */}
            {/* <div className='waveform-baseline' /> */}
          </div>
        ) : (
          <p>{`${t('pressSpaceToRecord')}...`}</p>
        )}
        <div className='catalog-page__sample-record-controls'>
          <div className='catalog-page__header catalog-page__sample-record-microphone'>
            {this.props.isRecording ? (
              <Button outline color='secondary' onClick={this.cancelrecordingWithClear}>
                {t('cancel')}
                <span> / </span>
                <Icon name='falBackspace' />
              </Button>
            ) : (
              <DropdownButton direction='up'>
                <DropdownToggle disabled={!inputDevices.length}>
                  <Icon name='faMicrophone' size='sm' />
                  <Button>
                    {(this.props.currentDevice && this.getDeviceName(this.props.currentDevice.label)) || t('default')}
                  </Button>
                  <Icon name='falChevronDown' size='sm' />
                </DropdownToggle>
                <DropdownMenu>
                  {inputDevices &&
                    inputDevices.map(device => (
                      <DropdownItem
                        active={this.props.currentDevice && this.props.currentDevice.deviceId === device.deviceId}
                        onClick={() => this.props.setCurrentDevice(device)}
                        key={device.deviceId}
                      >
                        {this.props.currentDevice && this.props.currentDevice.deviceId === device.deviceId && (
                          <Icon name='farCheck' size='sm' />
                        )}
                        {device.label}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </DropdownButton>
            )}
          </div>
          <div className='catalog-page__sample-record-start' data-test-id='samplePage.recordButton'>
            {this.props.isRecording ? (
              <button className='catalog-page__sample-record-stop' onClick={this.stopRecording}>
                <Icon name='faStop' color='primary' />
              </button>
            ) : (
              <button disabled={!inputDevices.length} onClick={this.props.toggleRecording}>
                <div>
                  <Icon name='faMicrophone' />
                </div>
              </button>
            )}
          </div>
          <RecordTimer isRecording={this.props.isRecording} />
        </div>
        <div className='catalog-page__sample-player-space'>
          {t('pressButton').toLowerCase()} <img src='/img/space.svg' alt='Space button icon' />
        </div>
      </div>
    );
  }
}

export default SampleRecord;
