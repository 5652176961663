import { useCallback, useMemo } from 'react';
import { Range } from '@just-ai/just-ui';
import { RangeProps } from '@just-ai/just-ui/dist/Range';

export type SidebarRangeProps = {
  rawValue: number;
  defaultValue: number;
  setValue: (value: number) => void;
  labelFunction: (value: number) => string;
} & RangeProps;

export default function SidebarRange(props: SidebarRangeProps) {
  const { rawValue, setValue, labelFunction, name, min, max, defaultValue } = props;

  const processedValue = useMemo(() => {
    if (!min || !max) return 0;
    if (rawValue === defaultValue) return 0;
    if (rawValue < defaultValue) return -1 + (rawValue - min) / (defaultValue - min);
    return 1 - (max - rawValue) / (max - defaultValue);
  }, [rawValue, min, max, defaultValue]);

  const handleChange = useCallback(
    (value: number) => {
      if (!min || !max) return setValue(defaultValue);
      if (value < 0) return setValue(defaultValue + (defaultValue - min) * value);
      return setValue(defaultValue + (max - defaultValue) * value);
    },
    [defaultValue, max, min, setValue]
  );

  return (
    <Range
      name={name}
      value={processedValue}
      onChange={handleChange}
      step={0.01}
      min={-1}
      max={1}
      visibleStep={1}
      visibleStepLabel={labelFunction}
      data-test-id={`sidebar-slider-${name}`}
    />
  );
}
