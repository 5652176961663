// tslint:disable
// @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AIWriterRequest
 */
export interface AIWriterRequest {
  /**
   *
   * @type {string}
   * @memberof AIWriterRequest
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof AIWriterRequest
   */
  language: string;
  /**
   *
   * @type {string}
   * @memberof AIWriterRequest
   */
  style: StyleEnum;
  /**
   *
   * @type {string}
   * @memberof AIWriterRequest
   */
  type: TypeEnum;
  /**
   *
   * @type {string}
   * @memberof AIWriterRequest
   */
  brand?: string;
  /**
   *
   * @type {number}
   * @memberof AIWriterRequest
   */
  versionCount: number;
  /**
   *
   * @type {number}
   * @memberof AIWriterRequest
   */
  charactersCount: number;
}

/**
 * @export
 * @enum {string}
 */
export enum StyleEnum {
  PROFESSIONAL = 'PROFESSIONAL',
  CASUAL = 'CASUAL',
  FRIENDLY = 'FRIENDLY',
  STORYTELLING = 'STORYTELLING',
  FORMAL = 'FORMAL',
  INFORMAL = 'INFORMAL',
}
/**
 * @export
 * @enum {string}
 */
export enum TypeEnum {
  PRODUCTEXPLAINER = 'PRODUCT_EXPLAINER',
  PRODUCTADSVIDEO = 'PRODUCT_ADS_VIDEO',
  PITCHDECK = 'PITCH_DECK',
  PROMO = 'PROMO',
  SALESDEMO = 'SALES_DEMO',
  CREATIVESTORY = 'CREATIVE_STORY',
  TRAINING = 'TRAINING',
  EXPLAINER = 'EXPLAINER',
  CONVERSATION = 'CONVERSATION',
  PARAPHRASE = 'PARAPHRASE',
}

/**
 *
 * @export
 * @interface AIWriterResponseView
 */
export interface AIWriterResponseView {
  /**
   *
   * @type {Array<string>}
   * @memberof AIWriterResponseView
   */
  response: Array<string>;
}
/**
 *
 * @export
 * @interface ActiveDownloadProjectView
 */
export interface ActiveDownloadProjectView {
  /**
   *
   * @type {Array<DownloadProjectView>}
   * @memberof ActiveDownloadProjectView
   */
  downloads: Array<DownloadProjectView>;
}
/**
 *
 * @export
 * @interface AudioView
 */
export interface AudioView {
  /**
   *
   * @type {string}
   * @memberof AudioView
   */
  audioUrl: string;
  /**
   *
   * @type {Date}
   * @memberof AudioView
   */
  expiredAt: Date;
  /**
   *
   * @type {number}
   * @memberof AudioView
   */
  duration?: number;
}
/**
 *
 * @export
 * @interface CreateDictionaryPhraseRequest
 */
export interface CreateDictionaryPhraseRequest {
  /**
   *
   * @type {string}
   * @memberof CreateDictionaryPhraseRequest
   */
  original: string;
  /**
   *
   * @type {string}
   * @memberof CreateDictionaryPhraseRequest
   */
  replacement: string;
}
/**
 *
 * @export
 * @interface CreatePresetRequest
 */
export interface CreatePresetRequest {
  /**
   *
   * @type {string}
   * @memberof CreatePresetRequest
   */
  name: string;
  /**
   *
   * @type {MarkupUpdate}
   * @memberof CreatePresetRequest
   */
  markupUpdate: MarkupUpdate;
}
/**
 *
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
  /**
   *
   * @type {string}
   * @memberof CreateProjectRequest
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateReplicaRequest
 */
export interface CreateReplicaRequest {
  /**
   *
   * @type {string}
   * @memberof CreateReplicaRequest
   */
  projectId: string;
  /**
   *
   * @type {string}
   * @memberof CreateReplicaRequest
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof CreateReplicaRequest
   */
  ssmlText?: string;
  /**
   *
   * @type {number}
   * @memberof CreateReplicaRequest
   */
  index: number;
  /**
   *
   * @type {MarkupUpdate}
   * @memberof CreateReplicaRequest
   */
  markupUpdate?: MarkupUpdate;
  /**
   *
   * @type {string}
   * @memberof CreateReplicaRequest
   */
  presetId?: string;
  /**
   *
   * @type {number}
   * @memberof CreateReplicaRequest
   */
  voiceId?: number;
  /**
   *
   * @type {number}
   * @memberof CreateReplicaRequest
   */
  modelId?: number;
}
/**
 *
 * @export
 * @interface DeleteReplicaDetailRequest
 */
export interface DeleteReplicaDetailRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteReplicaDetailRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface DownloadProjectView
 */
export interface DownloadProjectView {
  /**
   *
   * @type {string}
   * @memberof DownloadProjectView
   */
  downloadId: string;
  /**
   *
   * @type {string}
   * @memberof DownloadProjectView
   */
  status: StatusEnum;
  /**
   *
   * @type {string}
   * @memberof DownloadProjectView
   */
  fileUrl?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum StatusEnum {
  INPROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  NOFUNDS = 'NO_FUNDS',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
}

/**
 *
 * @export
 * @interface Markup
 */
export interface Markup {
  /**
   *
   * @type {Style}
   * @memberof Markup
   */
  style?: Style;
  /**
   *
   * @type {Rate}
   * @memberof Markup
   */
  rate?: Rate;
  /**
   *
   * @type {Volume}
   * @memberof Markup
   */
  volume?: Volume;
  /**
   *
   * @type {Pitch}
   * @memberof Markup
   */
  pitch?: Pitch;
}
/**
 *
 * @export
 * @interface MarkupSettings
 */
export interface MarkupSettings {
  /**
   *
   * @type {StyleSettings}
   * @memberof MarkupSettings
   */
  style?: StyleSettings;
  /**
   *
   * @type {RateSettings}
   * @memberof MarkupSettings
   */
  rate?: RateSettings;
  /**
   *
   * @type {VolumeSettings}
   * @memberof MarkupSettings
   */
  volume?: VolumeSettings;
  /**
   *
   * @type {PitchSettings}
   * @memberof MarkupSettings
   */
  pitch?: PitchSettings;
  /**
   *
   * @type {PauseSettings}
   * @memberof MarkupSettings
   */
  pause?: PauseSettings;
}
/**
 *
 * @export
 * @interface MarkupUpdate
 */
export interface MarkupUpdate {
  /**
   *
   * @type {StyleUpdate}
   * @memberof MarkupUpdate
   */
  style?: StyleUpdate;
  /**
   *
   * @type {RateUpdate}
   * @memberof MarkupUpdate
   */
  rate?: RateUpdate;
  /**
   *
   * @type {VolumeUpdate}
   * @memberof MarkupUpdate
   */
  volume?: VolumeUpdate;
  /**
   *
   * @type {PitchUpdate}
   * @memberof MarkupUpdate
   */
  pitch?: PitchUpdate;
}
/**
 *
 * @export
 * @interface PauseSettings
 */
export interface PauseSettings {
  /**
   *
   * @type {number}
   * @memberof PauseSettings
   */
  from: number;
  /**
   *
   * @type {number}
   * @memberof PauseSettings
   */
  to: number;
  /**
   *
   * @type {string}
   * @memberof PauseSettings
   */
  unit: UnitEnum;
  /**
   *
   * @type {number}
   * @memberof PauseSettings
   */
  _default: number;
  /**
   *
   * @type {number}
   * @memberof PauseSettings
   */
  defaultValue: number;
  /**
   *
   * @type {boolean}
   * @memberof PauseSettings
   */
  applyDefault: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum UnitEnum {
  NANOSECONDS = 'NANOSECONDS',
  MICROSECONDS = 'MICROSECONDS',
  MILLISECONDS = 'MILLISECONDS',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

/**
 *
 * @export
 * @interface PhraseDictionaryView
 */
export interface PhraseDictionaryView {
  /**
   *
   * @type {string}
   * @memberof PhraseDictionaryView
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PhraseDictionaryView
   */
  original: string;
  /**
   *
   * @type {string}
   * @memberof PhraseDictionaryView
   */
  replacement: string;
}
/**
 *
 * @export
 * @interface Pitch
 */
export interface Pitch {
  /**
   *
   * @type {number}
   * @memberof Pitch
   */
  value: number;
}
/**
 *
 * @export
 * @interface PitchSettings
 */
export interface PitchSettings {
  /**
   *
   * @type {number}
   * @memberof PitchSettings
   */
  from: number;
  /**
   *
   * @type {number}
   * @memberof PitchSettings
   */
  to: number;
  /**
   *
   * @type {number}
   * @memberof PitchSettings
   */
  _default: number;
  /**
   *
   * @type {number}
   * @memberof PitchSettings
   */
  defaultValue: number;
  /**
   *
   * @type {boolean}
   * @memberof PitchSettings
   */
  applyDefault: boolean;
}
/**
 *
 * @export
 * @interface PitchUpdate
 */
export interface PitchUpdate {
  /**
   *
   * @type {Pitch}
   * @memberof PitchUpdate
   */
  property?: Pitch;
  /**
   *
   * @type {boolean}
   * @memberof PitchUpdate
   */
  changed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PitchUpdate
   */
  reset?: boolean;
}
/**
 *
 * @export
 * @interface PresetView
 */
export interface PresetView {
  /**
   *
   * @type {string}
   * @memberof PresetView
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PresetView
   */
  name: string;
  /**
   *
   * @type {Markup}
   * @memberof PresetView
   */
  markup: Markup;
}
/**
 *
 * @export
 * @interface ProcessProjectReplicasRequest
 */
export interface ProcessProjectReplicasRequest {
  /**
   *
   * @type {Array<CreateReplicaRequest>}
   * @memberof ProcessProjectReplicasRequest
   */
  created?: Array<CreateReplicaRequest>;
  /**
   *
   * @type {Array<UpdateReplicaDetailRequest>}
   * @memberof ProcessProjectReplicasRequest
   */
  updated?: Array<UpdateReplicaDetailRequest>;
  /**
   *
   * @type {Array<DeleteReplicaDetailRequest>}
   * @memberof ProcessProjectReplicasRequest
   */
  deleted?: Array<DeleteReplicaDetailRequest>;
}
/**
 *
 * @export
 * @interface ProjectView
 */
export interface ProjectView {
  /**
   *
   * @type {string}
   * @memberof ProjectView
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProjectView
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof ProjectView
   */
  lastUsedAt: Date;
  /**
   *
   * @type {Array<number>}
   * @memberof ProjectView
   */
  voices?: Array<number>;
}
/**
 *
 * @export
 * @interface Rate
 */
export interface Rate {
  /**
   *
   * @type {number}
   * @memberof Rate
   */
  value: number;
}
/**
 *
 * @export
 * @interface RateSettings
 */
export interface RateSettings {
  /**
   *
   * @type {number}
   * @memberof RateSettings
   */
  from: number;
  /**
   *
   * @type {number}
   * @memberof RateSettings
   */
  to: number;
  /**
   *
   * @type {number}
   * @memberof RateSettings
   */
  _default: number;
  /**
   *
   * @type {number}
   * @memberof RateSettings
   */
  defaultValue: number;
  /**
   *
   * @type {boolean}
   * @memberof RateSettings
   */
  applyDefault: boolean;
}
/**
 *
 * @export
 * @interface RateUpdate
 */
export interface RateUpdate {
  /**
   *
   * @type {Rate}
   * @memberof RateUpdate
   */
  property?: Rate;
  /**
   *
   * @type {boolean}
   * @memberof RateUpdate
   */
  changed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RateUpdate
   */
  reset?: boolean;
}
/**
 *
 * @export
 * @interface ReplicaHistoryView
 */
export interface ReplicaHistoryView {
  /**
   *
   * @type {string}
   * @memberof ReplicaHistoryView
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof ReplicaHistoryView
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ReplicaHistoryView
   */
  expiredAt: Date;
  /**
   *
   * @type {boolean}
   * @memberof ReplicaHistoryView
   */
  hasAudio: boolean;
  /**
   *
   * @type {SavedReplicaView}
   * @memberof ReplicaHistoryView
   */
  replica: SavedReplicaView;
}
/**
 *
 * @export
 * @interface ReplicaView
 */
export interface ReplicaView {
  /**
   *
   * @type {string}
   * @memberof ReplicaView
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ReplicaView
   */
  index: number;
  /**
   *
   * @type {string}
   * @memberof ReplicaView
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof ReplicaView
   */
  ssmlText?: string;
  /**
   *
   * @type {Markup}
   * @memberof ReplicaView
   */
  markup?: Markup;
  /**
   *
   * @type {string}
   * @memberof ReplicaView
   */
  presetId?: string;
  /**
   *
   * @type {string}
   * @memberof ReplicaView
   */
  projectId: string;
  /**
   *
   * @type {number}
   * @memberof ReplicaView
   */
  voiceId?: number;
  /**
   *
   * @type {number}
   * @memberof ReplicaView
   */
  modelId?: number;
  /**
   *
   * @type {number}
   * @memberof ReplicaView
   */
  duration?: number;
}
/**
 *
 * @export
 * @interface ReplicasHistoryView
 */
export interface ReplicasHistoryView {
  /**
   *
   * @type {SavedReplicaView}
   * @memberof ReplicasHistoryView
   */
  unsaved?: SavedReplicaView;
  /**
   *
   * @type {Array<ReplicaHistoryView>}
   * @memberof ReplicasHistoryView
   */
  history: Array<ReplicaHistoryView>;
  /**
   *
   * @type {number}
   * @memberof ReplicasHistoryView
   */
  totalElements: number;
}
/**
 *
 * @export
 * @interface SavedReplicaView
 */
export interface SavedReplicaView {
  /**
   *
   * @type {string}
   * @memberof SavedReplicaView
   */
  text: string;
  /**
   *
   * @type {number}
   * @memberof SavedReplicaView
   */
  voiceId: number;
  /**
   *
   * @type {number}
   * @memberof SavedReplicaView
   */
  modelId: number;
}
/**
 *
 * @export
 * @interface Style
 */
export interface Style {
  /**
   *
   * @type {StyleName}
   * @memberof Style
   */
  style: StyleName;
}
/**
 *
 * @export
 * @interface StyleDescription
 */
export interface StyleDescription {
  /**
   *
   * @type {string}
   * @memberof StyleDescription
   */
  name: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum StyleName {
  SAD = 'SAD',
  NORMAL = 'NORMAL',
  HAPPY = 'HAPPY',
  ANGRY = 'ANGRY',
}

/**
 *
 * @export
 * @interface StyleSettings
 */
export interface StyleSettings {
  /**
   *
   * @type {Array<StyleName>}
   * @memberof StyleSettings
   */
  styles: Array<StyleName>;
  /**
   *
   * @type {{ [key: string]: StyleDescription; }}
   * @memberof StyleSettings
   */
  description: { [key: string]: StyleDescription };
  /**
   *
   * @type {StyleName}
   * @memberof StyleSettings
   */
  _default: StyleName;
  /**
   *
   * @type {StyleName}
   * @memberof StyleSettings
   */
  defaultValue: StyleName;
  /**
   *
   * @type {boolean}
   * @memberof StyleSettings
   */
  applyDefault: boolean;
}
/**
 *
 * @export
 * @interface StyleUpdate
 */
export interface StyleUpdate {
  /**
   *
   * @type {Style}
   * @memberof StyleUpdate
   */
  property?: Style;
  /**
   *
   * @type {boolean}
   * @memberof StyleUpdate
   */
  changed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof StyleUpdate
   */
  reset?: boolean;
}
/**
 *
 * @export
 * @interface UpdateDictionaryPhraseRequest
 */
export interface UpdateDictionaryPhraseRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateDictionaryPhraseRequest
   */
  original: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDictionaryPhraseRequest
   */
  replacement: string;
}
/**
 *
 * @export
 * @interface UpdatePresetRequest
 */
export interface UpdatePresetRequest {
  /**
   *
   * @type {string}
   * @memberof UpdatePresetRequest
   */
  name: string;
  /**
   *
   * @type {MarkupUpdate}
   * @memberof UpdatePresetRequest
   */
  markupUpdate?: MarkupUpdate;
}
/**
 *
 * @export
 * @interface UpdateProjectRequest
 */
export interface UpdateProjectRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  name: string;
}
/**
 *
 * @export
 * @interface UpdateReplicaDetailRequest
 */
export interface UpdateReplicaDetailRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateReplicaDetailRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateReplicaDetailRequest
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof UpdateReplicaDetailRequest
   */
  ssmlText?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateReplicaDetailRequest
   */
  index: number;
  /**
   *
   * @type {MarkupUpdate}
   * @memberof UpdateReplicaDetailRequest
   */
  markupUpdate?: MarkupUpdate;
  /**
   *
   * @type {string}
   * @memberof UpdateReplicaDetailRequest
   */
  presetId?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateReplicaDetailRequest
   */
  voiceId?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateReplicaDetailRequest
   */
  modelId?: number;
}
/**
 *
 * @export
 * @interface UpdateReplicaRequest
 */
export interface UpdateReplicaRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateReplicaRequest
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof UpdateReplicaRequest
   */
  ssmlText?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateReplicaRequest
   */
  index: number;
  /**
   *
   * @type {MarkupUpdate}
   * @memberof UpdateReplicaRequest
   */
  markupUpdate?: MarkupUpdate;
  /**
   *
   * @type {string}
   * @memberof UpdateReplicaRequest
   */
  presetId?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateReplicaRequest
   */
  voiceId?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateReplicaRequest
   */
  modelId?: number;
}
/**
 *
 * @export
 * @interface Volume
 */
export interface Volume {
  /**
   *
   * @type {number}
   * @memberof Volume
   */
  value: number;
}
/**
 *
 * @export
 * @interface VolumeSettings
 */
export interface VolumeSettings {
  /**
   *
   * @type {number}
   * @memberof VolumeSettings
   */
  from: number;
  /**
   *
   * @type {number}
   * @memberof VolumeSettings
   */
  to: number;
  /**
   *
   * @type {number}
   * @memberof VolumeSettings
   */
  _default: number;
  /**
   *
   * @type {number}
   * @memberof VolumeSettings
   */
  defaultValue: number;
  /**
   *
   * @type {boolean}
   * @memberof VolumeSettings
   */
  applyDefault: boolean;
}
/**
 *
 * @export
 * @interface VolumeUpdate
 */
export interface VolumeUpdate {
  /**
   *
   * @type {Volume}
   * @memberof VolumeUpdate
   */
  property?: Volume;
  /**
   *
   * @type {boolean}
   * @memberof VolumeUpdate
   */
  changed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VolumeUpdate
   */
  reset?: boolean;
}

/**
 * AiWriterControllerApi - axios parameter creator
 * @export
 */
export const AiWriterControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AIWriterRequest} aIWriterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateStory(aIWriterRequest: AIWriterRequest, options: any = {}): RequestArgs {
      // verify required parameter 'aIWriterRequest' is not null or undefined
      if (aIWriterRequest === null || aIWriterRequest === undefined) {
        throw new RequiredError(
          'aIWriterRequest',
          'Required parameter aIWriterRequest was null or undefined when calling generateStory.'
        );
      }
      const localVarPath = `/api/writer/story`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof aIWriterRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(aIWriterRequest !== undefined ? aIWriterRequest : {})
        : aIWriterRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AiWriterControllerApi - functional programming interface
 * @export
 */
export const AiWriterControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AIWriterRequest} aIWriterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateStory(
      aIWriterRequest: AIWriterRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIWriterResponseView> {
      const localVarAxiosArgs = AiWriterControllerApiAxiosParamCreator(configuration).generateStory(
        aIWriterRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AiWriterControllerApi - factory interface
 * @export
 */
export const AiWriterControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {AIWriterRequest} aIWriterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateStory(aIWriterRequest: AIWriterRequest, options?: any): AxiosPromise<AIWriterResponseView> {
      return AiWriterControllerApiFp(configuration).generateStory(aIWriterRequest, options)(axios, basePath);
    },
  };
};

/**
 * AiWriterControllerApi - object-oriented interface
 * @export
 * @class AiWriterControllerApi
 * @extends {BaseAPI}
 */
export class AiWriterControllerApi extends BaseAPI {
  /**
   *
   * @param {AIWriterRequest} aIWriterRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AiWriterControllerApi
   */
  public generateStory(aIWriterRequest: AIWriterRequest, options?: any) {
    return AiWriterControllerApiFp(this.configuration).generateStory(aIWriterRequest, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * MarkupSettingsControllerApi - axios parameter creator
 * @export
 */
export const MarkupSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [voiceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarkupSettings(voiceId?: number, options: any = {}): RequestArgs {
      const localVarPath = `/api/markup/settings`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (voiceId !== undefined) {
        localVarQueryParameter['voice_id'] = voiceId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MarkupSettingsControllerApi - functional programming interface
 * @export
 */
export const MarkupSettingsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [voiceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarkupSettings(
      voiceId?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkupSettings> {
      const localVarAxiosArgs = MarkupSettingsControllerApiAxiosParamCreator(configuration).getMarkupSettings(
        voiceId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MarkupSettingsControllerApi - factory interface
 * @export
 */
export const MarkupSettingsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} [voiceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMarkupSettings(voiceId?: number, options?: any): AxiosPromise<MarkupSettings> {
      return MarkupSettingsControllerApiFp(configuration).getMarkupSettings(voiceId, options)(axios, basePath);
    },
  };
};

/**
 * MarkupSettingsControllerApi - object-oriented interface
 * @export
 * @class MarkupSettingsControllerApi
 * @extends {BaseAPI}
 */
export class MarkupSettingsControllerApi extends BaseAPI {
  /**
   *
   * @param {number} [voiceId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarkupSettingsControllerApi
   */
  public getMarkupSettings(voiceId?: number, options?: any) {
    return MarkupSettingsControllerApiFp(this.configuration).getMarkupSettings(voiceId, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * PhraseDictionaryControllerApi - axios parameter creator
 * @export
 */
export const PhraseDictionaryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateDictionaryPhraseRequest} createDictionaryPhraseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPhraseDictionary(
      createDictionaryPhraseRequest: CreateDictionaryPhraseRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'createDictionaryPhraseRequest' is not null or undefined
      if (createDictionaryPhraseRequest === null || createDictionaryPhraseRequest === undefined) {
        throw new RequiredError(
          'createDictionaryPhraseRequest',
          'Required parameter createDictionaryPhraseRequest was null or undefined when calling createPhraseDictionary.'
        );
      }
      const localVarPath = `/api/editor/dictionary`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createDictionaryPhraseRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createDictionaryPhraseRequest !== undefined ? createDictionaryPhraseRequest : {})
        : createDictionaryPhraseRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePhrase(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deletePhrase.');
      }
      const localVarPath = `/api/editor/dictionary/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDictionary(page?: number, pageSize?: number, options: any = {}): RequestArgs {
      const localVarPath = `/api/editor/dictionary`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDictionaryPhraseById(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getDictionaryPhraseById.'
        );
      }
      const localVarPath = `/api/editor/dictionary/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateDictionaryPhraseRequest} updateDictionaryPhraseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePhrase(
      id: string,
      updateDictionaryPhraseRequest: UpdateDictionaryPhraseRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updatePhrase.');
      }
      // verify required parameter 'updateDictionaryPhraseRequest' is not null or undefined
      if (updateDictionaryPhraseRequest === null || updateDictionaryPhraseRequest === undefined) {
        throw new RequiredError(
          'updateDictionaryPhraseRequest',
          'Required parameter updateDictionaryPhraseRequest was null or undefined when calling updatePhrase.'
        );
      }
      const localVarPath = `/api/editor/dictionary/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateDictionaryPhraseRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateDictionaryPhraseRequest !== undefined ? updateDictionaryPhraseRequest : {})
        : updateDictionaryPhraseRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PhraseDictionaryControllerApi - functional programming interface
 * @export
 */
export const PhraseDictionaryControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateDictionaryPhraseRequest} createDictionaryPhraseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPhraseDictionary(
      createDictionaryPhraseRequest: CreateDictionaryPhraseRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhraseDictionaryView> {
      const localVarAxiosArgs = PhraseDictionaryControllerApiAxiosParamCreator(configuration).createPhraseDictionary(
        createDictionaryPhraseRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePhrase(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = PhraseDictionaryControllerApiAxiosParamCreator(configuration).deletePhrase(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDictionary(
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PhraseDictionaryView>> {
      const localVarAxiosArgs = PhraseDictionaryControllerApiAxiosParamCreator(configuration).getDictionary(
        page,
        pageSize,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDictionaryPhraseById(
      id: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhraseDictionaryView> {
      const localVarAxiosArgs = PhraseDictionaryControllerApiAxiosParamCreator(configuration).getDictionaryPhraseById(
        id,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateDictionaryPhraseRequest} updateDictionaryPhraseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePhrase(
      id: string,
      updateDictionaryPhraseRequest: UpdateDictionaryPhraseRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhraseDictionaryView> {
      const localVarAxiosArgs = PhraseDictionaryControllerApiAxiosParamCreator(configuration).updatePhrase(
        id,
        updateDictionaryPhraseRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PhraseDictionaryControllerApi - factory interface
 * @export
 */
export const PhraseDictionaryControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {CreateDictionaryPhraseRequest} createDictionaryPhraseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPhraseDictionary(
      createDictionaryPhraseRequest: CreateDictionaryPhraseRequest,
      options?: any
    ): AxiosPromise<PhraseDictionaryView> {
      return PhraseDictionaryControllerApiFp(configuration).createPhraseDictionary(
        createDictionaryPhraseRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePhrase(id: string, options?: any): AxiosPromise<void> {
      return PhraseDictionaryControllerApiFp(configuration).deletePhrase(id, options)(axios, basePath);
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDictionary(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<PhraseDictionaryView>> {
      return PhraseDictionaryControllerApiFp(configuration).getDictionary(page, pageSize, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDictionaryPhraseById(id: string, options?: any): AxiosPromise<PhraseDictionaryView> {
      return PhraseDictionaryControllerApiFp(configuration).getDictionaryPhraseById(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateDictionaryPhraseRequest} updateDictionaryPhraseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePhrase(
      id: string,
      updateDictionaryPhraseRequest: UpdateDictionaryPhraseRequest,
      options?: any
    ): AxiosPromise<PhraseDictionaryView> {
      return PhraseDictionaryControllerApiFp(configuration).updatePhrase(
        id,
        updateDictionaryPhraseRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * PhraseDictionaryControllerApi - object-oriented interface
 * @export
 * @class PhraseDictionaryControllerApi
 * @extends {BaseAPI}
 */
export class PhraseDictionaryControllerApi extends BaseAPI {
  /**
   *
   * @param {CreateDictionaryPhraseRequest} createDictionaryPhraseRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhraseDictionaryControllerApi
   */
  public createPhraseDictionary(createDictionaryPhraseRequest: CreateDictionaryPhraseRequest, options?: any) {
    return PhraseDictionaryControllerApiFp(this.configuration).createPhraseDictionary(
      createDictionaryPhraseRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhraseDictionaryControllerApi
   */
  public deletePhrase(id: string, options?: any) {
    return PhraseDictionaryControllerApiFp(this.configuration).deletePhrase(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhraseDictionaryControllerApi
   */
  public getDictionary(page?: number, pageSize?: number, options?: any) {
    return PhraseDictionaryControllerApiFp(this.configuration).getDictionary(
      page,
      pageSize,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhraseDictionaryControllerApi
   */
  public getDictionaryPhraseById(id: string, options?: any) {
    return PhraseDictionaryControllerApiFp(this.configuration).getDictionaryPhraseById(id, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {UpdateDictionaryPhraseRequest} updateDictionaryPhraseRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PhraseDictionaryControllerApi
   */
  public updatePhrase(id: string, updateDictionaryPhraseRequest: UpdateDictionaryPhraseRequest, options?: any) {
    return PhraseDictionaryControllerApiFp(this.configuration).updatePhrase(
      id,
      updateDictionaryPhraseRequest,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * PresetsControllerApi - axios parameter creator
 * @export
 */
export const PresetsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreatePresetRequest} createPresetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPreset(createPresetRequest: CreatePresetRequest, options: any = {}): RequestArgs {
      // verify required parameter 'createPresetRequest' is not null or undefined
      if (createPresetRequest === null || createPresetRequest === undefined) {
        throw new RequiredError(
          'createPresetRequest',
          'Required parameter createPresetRequest was null or undefined when calling createPreset.'
        );
      }
      const localVarPath = `/api/editor/presets`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createPresetRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createPresetRequest !== undefined ? createPresetRequest : {})
        : createPresetRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePreset(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deletePreset.');
      }
      const localVarPath = `/api/editor/presets/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPresetById(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getPresetById.');
      }
      const localVarPath = `/api/editor/presets/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPresetsForCurrentUser(page?: number, pageSize?: number, options: any = {}): RequestArgs {
      const localVarPath = `/api/editor/presets`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePresetRequest} updatePresetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePreset(id: string, updatePresetRequest: UpdatePresetRequest, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updatePreset.');
      }
      // verify required parameter 'updatePresetRequest' is not null or undefined
      if (updatePresetRequest === null || updatePresetRequest === undefined) {
        throw new RequiredError(
          'updatePresetRequest',
          'Required parameter updatePresetRequest was null or undefined when calling updatePreset.'
        );
      }
      const localVarPath = `/api/editor/presets/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updatePresetRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updatePresetRequest !== undefined ? updatePresetRequest : {})
        : updatePresetRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PresetsControllerApi - functional programming interface
 * @export
 */
export const PresetsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreatePresetRequest} createPresetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPreset(
      createPresetRequest: CreatePresetRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresetView> {
      const localVarAxiosArgs = PresetsControllerApiAxiosParamCreator(configuration).createPreset(
        createPresetRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePreset(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = PresetsControllerApiAxiosParamCreator(configuration).deletePreset(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPresetById(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresetView> {
      const localVarAxiosArgs = PresetsControllerApiAxiosParamCreator(configuration).getPresetById(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPresetsForCurrentUser(
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PresetView>> {
      const localVarAxiosArgs = PresetsControllerApiAxiosParamCreator(configuration).getPresetsForCurrentUser(
        page,
        pageSize,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePresetRequest} updatePresetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePreset(
      id: string,
      updatePresetRequest: UpdatePresetRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresetView> {
      const localVarAxiosArgs = PresetsControllerApiAxiosParamCreator(configuration).updatePreset(
        id,
        updatePresetRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PresetsControllerApi - factory interface
 * @export
 */
export const PresetsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {CreatePresetRequest} createPresetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPreset(createPresetRequest: CreatePresetRequest, options?: any): AxiosPromise<PresetView> {
      return PresetsControllerApiFp(configuration).createPreset(createPresetRequest, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePreset(id: string, options?: any): AxiosPromise<void> {
      return PresetsControllerApiFp(configuration).deletePreset(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPresetById(id: string, options?: any): AxiosPromise<PresetView> {
      return PresetsControllerApiFp(configuration).getPresetById(id, options)(axios, basePath);
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPresetsForCurrentUser(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<PresetView>> {
      return PresetsControllerApiFp(configuration).getPresetsForCurrentUser(page, pageSize, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePresetRequest} updatePresetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePreset(id: string, updatePresetRequest: UpdatePresetRequest, options?: any): AxiosPromise<PresetView> {
      return PresetsControllerApiFp(configuration).updatePreset(id, updatePresetRequest, options)(axios, basePath);
    },
  };
};

/**
 * PresetsControllerApi - object-oriented interface
 * @export
 * @class PresetsControllerApi
 * @extends {BaseAPI}
 */
export class PresetsControllerApi extends BaseAPI {
  /**
   *
   * @param {CreatePresetRequest} createPresetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PresetsControllerApi
   */
  public createPreset(createPresetRequest: CreatePresetRequest, options?: any) {
    return PresetsControllerApiFp(this.configuration).createPreset(createPresetRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PresetsControllerApi
   */
  public deletePreset(id: string, options?: any) {
    return PresetsControllerApiFp(this.configuration).deletePreset(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PresetsControllerApi
   */
  public getPresetById(id: string, options?: any) {
    return PresetsControllerApiFp(this.configuration).getPresetById(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PresetsControllerApi
   */
  public getPresetsForCurrentUser(page?: number, pageSize?: number, options?: any) {
    return PresetsControllerApiFp(this.configuration).getPresetsForCurrentUser(
      page,
      pageSize,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {UpdatePresetRequest} updatePresetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PresetsControllerApi
   */
  public updatePreset(id: string, updatePresetRequest: UpdatePresetRequest, options?: any) {
    return PresetsControllerApiFp(this.configuration).updatePreset(
      id,
      updatePresetRequest,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ProjectDownloadControllerApi - axios parameter creator
 * @export
 */
export const ProjectDownloadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activeDownloads(projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling activeDownloads.'
        );
      }
      const localVarPath = `/api/projects/{projectId}/activeDownloads`.replace(
        `{${'projectId'}}`,
        encodeURIComponent(String(projectId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {string} downloadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelDownload(projectId: string, downloadId: string, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling cancelDownload.'
        );
      }
      // verify required parameter 'downloadId' is not null or undefined
      if (downloadId === null || downloadId === undefined) {
        throw new RequiredError(
          'downloadId',
          'Required parameter downloadId was null or undefined when calling cancelDownload.'
        );
      }
      const localVarPath = `/api/projects/{projectId}/download/{downloadId}/cancel`
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'downloadId'}}`, encodeURIComponent(String(downloadId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {'ZIP' | 'MERGED'} format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadProject(projectId: string, format: 'ZIP' | 'MERGED', options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling downloadProject.'
        );
      }
      // verify required parameter 'format' is not null or undefined
      if (format === null || format === undefined) {
        throw new RequiredError(
          'format',
          'Required parameter format was null or undefined when calling downloadProject.'
        );
      }
      const localVarPath = `/api/projects/{projectId}/download`.replace(
        `{${'projectId'}}`,
        encodeURIComponent(String(projectId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {string} downloadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadStatus(projectId: string, downloadId: string, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling downloadStatus.'
        );
      }
      // verify required parameter 'downloadId' is not null or undefined
      if (downloadId === null || downloadId === undefined) {
        throw new RequiredError(
          'downloadId',
          'Required parameter downloadId was null or undefined when calling downloadStatus.'
        );
      }
      const localVarPath = `/api/projects/{projectId}/download/{downloadId}/status`
        .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
        .replace(`{${'downloadId'}}`, encodeURIComponent(String(downloadId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProjectDownloadControllerApi - functional programming interface
 * @export
 */
export const ProjectDownloadControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activeDownloads(
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveDownloadProjectView> {
      const localVarAxiosArgs = ProjectDownloadControllerApiAxiosParamCreator(configuration).activeDownloads(
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {string} downloadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelDownload(
      projectId: string,
      downloadId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectDownloadControllerApiAxiosParamCreator(configuration).cancelDownload(
        projectId,
        downloadId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {'ZIP' | 'MERGED'} format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadProject(
      projectId: string,
      format: 'ZIP' | 'MERGED',
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadProjectView> {
      const localVarAxiosArgs = ProjectDownloadControllerApiAxiosParamCreator(configuration).downloadProject(
        projectId,
        format,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {string} downloadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadStatus(
      projectId: string,
      downloadId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadProjectView> {
      const localVarAxiosArgs = ProjectDownloadControllerApiAxiosParamCreator(configuration).downloadStatus(
        projectId,
        downloadId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProjectDownloadControllerApi - factory interface
 * @export
 */
export const ProjectDownloadControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activeDownloads(projectId: string, options?: any): AxiosPromise<ActiveDownloadProjectView> {
      return ProjectDownloadControllerApiFp(configuration).activeDownloads(projectId, options)(axios, basePath);
    },
    /**
     *
     * @param {string} projectId
     * @param {string} downloadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelDownload(projectId: string, downloadId: string, options?: any): AxiosPromise<void> {
      return ProjectDownloadControllerApiFp(configuration).cancelDownload(
        projectId,
        downloadId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} projectId
     * @param {'ZIP' | 'MERGED'} format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadProject(projectId: string, format: 'ZIP' | 'MERGED', options?: any): AxiosPromise<DownloadProjectView> {
      return ProjectDownloadControllerApiFp(configuration).downloadProject(projectId, format, options)(axios, basePath);
    },
    /**
     *
     * @param {string} projectId
     * @param {string} downloadId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadStatus(projectId: string, downloadId: string, options?: any): AxiosPromise<DownloadProjectView> {
      return ProjectDownloadControllerApiFp(configuration).downloadStatus(
        projectId,
        downloadId,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ProjectDownloadControllerApi - object-oriented interface
 * @export
 * @class ProjectDownloadControllerApi
 * @extends {BaseAPI}
 */
export class ProjectDownloadControllerApi extends BaseAPI {
  /**
   *
   * @param {string} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectDownloadControllerApi
   */
  public activeDownloads(projectId: string, options?: any) {
    return ProjectDownloadControllerApiFp(this.configuration).activeDownloads(projectId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} projectId
   * @param {string} downloadId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectDownloadControllerApi
   */
  public cancelDownload(projectId: string, downloadId: string, options?: any) {
    return ProjectDownloadControllerApiFp(this.configuration).cancelDownload(
      projectId,
      downloadId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} projectId
   * @param {'ZIP' | 'MERGED'} format
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectDownloadControllerApi
   */
  public downloadProject(projectId: string, format: 'ZIP' | 'MERGED', options?: any) {
    return ProjectDownloadControllerApiFp(this.configuration).downloadProject(
      projectId,
      format,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} projectId
   * @param {string} downloadId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectDownloadControllerApi
   */
  public downloadStatus(projectId: string, downloadId: string, options?: any) {
    return ProjectDownloadControllerApiFp(this.configuration).downloadStatus(
      projectId,
      downloadId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ProjectsControllerApi - axios parameter creator
 * @export
 */
export const ProjectsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateProjectRequest} createProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(createProjectRequest: CreateProjectRequest, options: any = {}): RequestArgs {
      // verify required parameter 'createProjectRequest' is not null or undefined
      if (createProjectRequest === null || createProjectRequest === undefined) {
        throw new RequiredError(
          'createProjectRequest',
          'Required parameter createProjectRequest was null or undefined when calling createProject.'
        );
      }
      const localVarPath = `/api/projects`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createProjectRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createProjectRequest !== undefined ? createProjectRequest : {})
        : createProjectRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling deleteProject.'
        );
      }
      const localVarPath = `/api/projects/{projectId}`.replace(
        `{${'projectId'}}`,
        encodeURIComponent(String(projectId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProjectById(projectId: string, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling findProjectById.'
        );
      }
      const localVarPath = `/api/projects/{projectId}`.replace(
        `{${'projectId'}}`,
        encodeURIComponent(String(projectId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUserProjects(page?: number, pageSize?: number, options: any = {}): RequestArgs {
      const localVarPath = `/api/projects`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {UpdateProjectRequest} updateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(projectId: string, updateProjectRequest: UpdateProjectRequest, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateProject.'
        );
      }
      // verify required parameter 'updateProjectRequest' is not null or undefined
      if (updateProjectRequest === null || updateProjectRequest === undefined) {
        throw new RequiredError(
          'updateProjectRequest',
          'Required parameter updateProjectRequest was null or undefined when calling updateProject.'
        );
      }
      const localVarPath = `/api/projects/{projectId}`.replace(
        `{${'projectId'}}`,
        encodeURIComponent(String(projectId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateProjectRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateProjectRequest !== undefined ? updateProjectRequest : {})
        : updateProjectRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {ProcessProjectReplicasRequest} processProjectReplicasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProjectReplicas(
      projectId: string,
      processProjectReplicasRequest: ProcessProjectReplicasRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling updateProjectReplicas.'
        );
      }
      // verify required parameter 'processProjectReplicasRequest' is not null or undefined
      if (processProjectReplicasRequest === null || processProjectReplicasRequest === undefined) {
        throw new RequiredError(
          'processProjectReplicasRequest',
          'Required parameter processProjectReplicasRequest was null or undefined when calling updateProjectReplicas.'
        );
      }
      const localVarPath = `/api/projects/{projectId}`.replace(
        `{${'projectId'}}`,
        encodeURIComponent(String(projectId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof processProjectReplicasRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(processProjectReplicasRequest !== undefined ? processProjectReplicasRequest : {})
        : processProjectReplicasRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProjectsControllerApi - functional programming interface
 * @export
 */
export const ProjectsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateProjectRequest} createProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(
      createProjectRequest: CreateProjectRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectView> {
      const localVarAxiosArgs = ProjectsControllerApiAxiosParamCreator(configuration).createProject(
        createProjectRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(projectId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectsControllerApiAxiosParamCreator(configuration).deleteProject(projectId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProjectById(
      projectId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectView> {
      const localVarAxiosArgs = ProjectsControllerApiAxiosParamCreator(configuration).findProjectById(
        projectId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUserProjects(
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectView>> {
      const localVarAxiosArgs = ProjectsControllerApiAxiosParamCreator(configuration).findUserProjects(
        page,
        pageSize,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {UpdateProjectRequest} updateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(
      projectId: string,
      updateProjectRequest: UpdateProjectRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectView> {
      const localVarAxiosArgs = ProjectsControllerApiAxiosParamCreator(configuration).updateProject(
        projectId,
        updateProjectRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {ProcessProjectReplicasRequest} processProjectReplicasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProjectReplicas(
      projectId: string,
      processProjectReplicasRequest: ProcessProjectReplicasRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ProjectsControllerApiAxiosParamCreator(configuration).updateProjectReplicas(
        projectId,
        processProjectReplicasRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProjectsControllerApi - factory interface
 * @export
 */
export const ProjectsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {CreateProjectRequest} createProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(createProjectRequest: CreateProjectRequest, options?: any): AxiosPromise<ProjectView> {
      return ProjectsControllerApiFp(configuration).createProject(createProjectRequest, options)(axios, basePath);
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProject(projectId: string, options?: any): AxiosPromise<void> {
      return ProjectsControllerApiFp(configuration).deleteProject(projectId, options)(axios, basePath);
    },
    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProjectById(projectId: string, options?: any): AxiosPromise<ProjectView> {
      return ProjectsControllerApiFp(configuration).findProjectById(projectId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUserProjects(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<ProjectView>> {
      return ProjectsControllerApiFp(configuration).findUserProjects(page, pageSize, options)(axios, basePath);
    },
    /**
     *
     * @param {string} projectId
     * @param {UpdateProjectRequest} updateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(
      projectId: string,
      updateProjectRequest: UpdateProjectRequest,
      options?: any
    ): AxiosPromise<ProjectView> {
      return ProjectsControllerApiFp(configuration).updateProject(
        projectId,
        updateProjectRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} projectId
     * @param {ProcessProjectReplicasRequest} processProjectReplicasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProjectReplicas(
      projectId: string,
      processProjectReplicasRequest: ProcessProjectReplicasRequest,
      options?: any
    ): AxiosPromise<void> {
      return ProjectsControllerApiFp(configuration).updateProjectReplicas(
        projectId,
        processProjectReplicasRequest,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ProjectsControllerApi - object-oriented interface
 * @export
 * @class ProjectsControllerApi
 * @extends {BaseAPI}
 */
export class ProjectsControllerApi extends BaseAPI {
  /**
   *
   * @param {CreateProjectRequest} createProjectRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsControllerApi
   */
  public createProject(createProjectRequest: CreateProjectRequest, options?: any) {
    return ProjectsControllerApiFp(this.configuration).createProject(createProjectRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsControllerApi
   */
  public deleteProject(projectId: string, options?: any) {
    return ProjectsControllerApiFp(this.configuration).deleteProject(projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsControllerApi
   */
  public findProjectById(projectId: string, options?: any) {
    return ProjectsControllerApiFp(this.configuration).findProjectById(projectId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsControllerApi
   */
  public findUserProjects(page?: number, pageSize?: number, options?: any) {
    return ProjectsControllerApiFp(this.configuration).findUserProjects(
      page,
      pageSize,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} projectId
   * @param {UpdateProjectRequest} updateProjectRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsControllerApi
   */
  public updateProject(projectId: string, updateProjectRequest: UpdateProjectRequest, options?: any) {
    return ProjectsControllerApiFp(this.configuration).updateProject(
      projectId,
      updateProjectRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} projectId
   * @param {ProcessProjectReplicasRequest} processProjectReplicasRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsControllerApi
   */
  public updateProjectReplicas(
    projectId: string,
    processProjectReplicasRequest: ProcessProjectReplicasRequest,
    options?: any
  ) {
    return ProjectsControllerApiFp(this.configuration).updateProjectReplicas(
      projectId,
      processProjectReplicasRequest,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ReplicaSynthesisControllerApi - axios parameter creator
 * @export
 */
export const ReplicaSynthesisControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} replicaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replicaSynthesis(replicaId: string, options: any = {}): RequestArgs {
      // verify required parameter 'replicaId' is not null or undefined
      if (replicaId === null || replicaId === undefined) {
        throw new RequiredError(
          'replicaId',
          'Required parameter replicaId was null or undefined when calling replicaSynthesis.'
        );
      }
      const localVarPath = `/api/replicas/synthesis/replica/{replicaId}`.replace(
        `{${'replicaId'}}`,
        encodeURIComponent(String(replicaId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} historyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replicaSynthesisFromHistory(historyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'historyId' is not null or undefined
      if (historyId === null || historyId === undefined) {
        throw new RequiredError(
          'historyId',
          'Required parameter historyId was null or undefined when calling replicaSynthesisFromHistory.'
        );
      }
      const localVarPath = `/api/replicas/synthesis/history/{historyId}`.replace(
        `{${'historyId'}}`,
        encodeURIComponent(String(historyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReplicaSynthesisControllerApi - functional programming interface
 * @export
 */
export const ReplicaSynthesisControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} replicaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replicaSynthesis(
      replicaId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioView> {
      const localVarAxiosArgs = ReplicaSynthesisControllerApiAxiosParamCreator(configuration).replicaSynthesis(
        replicaId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} historyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replicaSynthesisFromHistory(
      historyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioView> {
      const localVarAxiosArgs = ReplicaSynthesisControllerApiAxiosParamCreator(
        configuration
      ).replicaSynthesisFromHistory(historyId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReplicaSynthesisControllerApi - factory interface
 * @export
 */
export const ReplicaSynthesisControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} replicaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replicaSynthesis(replicaId: string, options?: any): AxiosPromise<AudioView> {
      return ReplicaSynthesisControllerApiFp(configuration).replicaSynthesis(replicaId, options)(axios, basePath);
    },
    /**
     *
     * @param {string} historyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replicaSynthesisFromHistory(historyId: string, options?: any): AxiosPromise<AudioView> {
      return ReplicaSynthesisControllerApiFp(configuration).replicaSynthesisFromHistory(historyId, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * ReplicaSynthesisControllerApi - object-oriented interface
 * @export
 * @class ReplicaSynthesisControllerApi
 * @extends {BaseAPI}
 */
export class ReplicaSynthesisControllerApi extends BaseAPI {
  /**
   *
   * @param {string} replicaId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicaSynthesisControllerApi
   */
  public replicaSynthesis(replicaId: string, options?: any) {
    return ReplicaSynthesisControllerApiFp(this.configuration).replicaSynthesis(replicaId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} historyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicaSynthesisControllerApi
   */
  public replicaSynthesisFromHistory(historyId: string, options?: any) {
    return ReplicaSynthesisControllerApiFp(this.configuration).replicaSynthesisFromHistory(historyId, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * ReplicasAudioControllerApi - axios parameter creator
 * @export
 */
export const ReplicasAudioControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} historyId
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadHistoryAudio(historyId: string, fileName: string, options: any = {}): RequestArgs {
      // verify required parameter 'historyId' is not null or undefined
      if (historyId === null || historyId === undefined) {
        throw new RequiredError(
          'historyId',
          'Required parameter historyId was null or undefined when calling downloadHistoryAudio.'
        );
      }
      // verify required parameter 'fileName' is not null or undefined
      if (fileName === null || fileName === undefined) {
        throw new RequiredError(
          'fileName',
          'Required parameter fileName was null or undefined when calling downloadHistoryAudio.'
        );
      }
      const localVarPath = `/api/replicas/download/history/{historyId}`.replace(
        `{${'historyId'}}`,
        encodeURIComponent(String(historyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fileName !== undefined) {
        localVarQueryParameter['fileName'] = fileName;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} replicaId
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadReplicaAudio(replicaId: string, fileName: string, options: any = {}): RequestArgs {
      // verify required parameter 'replicaId' is not null or undefined
      if (replicaId === null || replicaId === undefined) {
        throw new RequiredError(
          'replicaId',
          'Required parameter replicaId was null or undefined when calling downloadReplicaAudio.'
        );
      }
      // verify required parameter 'fileName' is not null or undefined
      if (fileName === null || fileName === undefined) {
        throw new RequiredError(
          'fileName',
          'Required parameter fileName was null or undefined when calling downloadReplicaAudio.'
        );
      }
      const localVarPath = `/api/replicas/download/replica/{replicaId}`.replace(
        `{${'replicaId'}}`,
        encodeURIComponent(String(replicaId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fileName !== undefined) {
        localVarQueryParameter['fileName'] = fileName;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReplicasAudioControllerApi - functional programming interface
 * @export
 */
export const ReplicasAudioControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} historyId
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadHistoryAudio(
      historyId: string,
      fileName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioView> {
      const localVarAxiosArgs = ReplicasAudioControllerApiAxiosParamCreator(configuration).downloadHistoryAudio(
        historyId,
        fileName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} replicaId
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadReplicaAudio(
      replicaId: string,
      fileName: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioView> {
      const localVarAxiosArgs = ReplicasAudioControllerApiAxiosParamCreator(configuration).downloadReplicaAudio(
        replicaId,
        fileName,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReplicasAudioControllerApi - factory interface
 * @export
 */
export const ReplicasAudioControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} historyId
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadHistoryAudio(historyId: string, fileName: string, options?: any): AxiosPromise<AudioView> {
      return ReplicasAudioControllerApiFp(configuration).downloadHistoryAudio(
        historyId,
        fileName,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} replicaId
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadReplicaAudio(replicaId: string, fileName: string, options?: any): AxiosPromise<AudioView> {
      return ReplicasAudioControllerApiFp(configuration).downloadReplicaAudio(
        replicaId,
        fileName,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ReplicasAudioControllerApi - object-oriented interface
 * @export
 * @class ReplicasAudioControllerApi
 * @extends {BaseAPI}
 */
export class ReplicasAudioControllerApi extends BaseAPI {
  /**
   *
   * @param {string} historyId
   * @param {string} fileName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicasAudioControllerApi
   */
  public downloadHistoryAudio(historyId: string, fileName: string, options?: any) {
    return ReplicasAudioControllerApiFp(this.configuration).downloadHistoryAudio(
      historyId,
      fileName,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} replicaId
   * @param {string} fileName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicasAudioControllerApi
   */
  public downloadReplicaAudio(replicaId: string, fileName: string, options?: any) {
    return ReplicasAudioControllerApiFp(this.configuration).downloadReplicaAudio(
      replicaId,
      fileName,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ReplicasControllerApi - axios parameter creator
 * @export
 */
export const ReplicasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateReplicaRequest} createReplicaRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReplica(createReplicaRequest: CreateReplicaRequest, options: any = {}): RequestArgs {
      // verify required parameter 'createReplicaRequest' is not null or undefined
      if (createReplicaRequest === null || createReplicaRequest === undefined) {
        throw new RequiredError(
          'createReplicaRequest',
          'Required parameter createReplicaRequest was null or undefined when calling createReplica.'
        );
      }
      const localVarPath = `/api/replicas`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createReplicaRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createReplicaRequest !== undefined ? createReplicaRequest : {})
        : createReplicaRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReplica(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteReplica.');
      }
      const localVarPath = `/api/replicas/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplica(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getReplica.');
      }
      const localVarPath = `/api/replicas/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplicasByProjectId(projectId: string, page?: number, pageSize?: number, options: any = {}): RequestArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling getReplicasByProjectId.'
        );
      }
      const localVarPath = `/api/replicas`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (projectId !== undefined) {
        localVarQueryParameter['projectId'] = projectId;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateReplicaRequest} updateReplicaRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReplica(id: string, updateReplicaRequest: UpdateReplicaRequest, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateReplica.');
      }
      // verify required parameter 'updateReplicaRequest' is not null or undefined
      if (updateReplicaRequest === null || updateReplicaRequest === undefined) {
        throw new RequiredError(
          'updateReplicaRequest',
          'Required parameter updateReplicaRequest was null or undefined when calling updateReplica.'
        );
      }
      const localVarPath = `/api/replicas/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateReplicaRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateReplicaRequest !== undefined ? updateReplicaRequest : {})
        : updateReplicaRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReplicasControllerApi - functional programming interface
 * @export
 */
export const ReplicasControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateReplicaRequest} createReplicaRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReplica(
      createReplicaRequest: CreateReplicaRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplicaView> {
      const localVarAxiosArgs = ReplicasControllerApiAxiosParamCreator(configuration).createReplica(
        createReplicaRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReplica(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ReplicasControllerApiAxiosParamCreator(configuration).deleteReplica(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplica(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplicaView> {
      const localVarAxiosArgs = ReplicasControllerApiAxiosParamCreator(configuration).getReplica(id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} projectId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplicasByProjectId(
      projectId: string,
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReplicaView>> {
      const localVarAxiosArgs = ReplicasControllerApiAxiosParamCreator(configuration).getReplicasByProjectId(
        projectId,
        page,
        pageSize,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateReplicaRequest} updateReplicaRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReplica(
      id: string,
      updateReplicaRequest: UpdateReplicaRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplicaView> {
      const localVarAxiosArgs = ReplicasControllerApiAxiosParamCreator(configuration).updateReplica(
        id,
        updateReplicaRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReplicasControllerApi - factory interface
 * @export
 */
export const ReplicasControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {CreateReplicaRequest} createReplicaRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReplica(createReplicaRequest: CreateReplicaRequest, options?: any): AxiosPromise<ReplicaView> {
      return ReplicasControllerApiFp(configuration).createReplica(createReplicaRequest, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReplica(id: string, options?: any): AxiosPromise<void> {
      return ReplicasControllerApiFp(configuration).deleteReplica(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplica(id: string, options?: any): AxiosPromise<ReplicaView> {
      return ReplicasControllerApiFp(configuration).getReplica(id, options)(axios, basePath);
    },
    /**
     *
     * @param {string} projectId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplicasByProjectId(
      projectId: string,
      page?: number,
      pageSize?: number,
      options?: any
    ): AxiosPromise<Array<ReplicaView>> {
      return ReplicasControllerApiFp(configuration).getReplicasByProjectId(
        projectId,
        page,
        pageSize,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateReplicaRequest} updateReplicaRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReplica(id: string, updateReplicaRequest: UpdateReplicaRequest, options?: any): AxiosPromise<ReplicaView> {
      return ReplicasControllerApiFp(configuration).updateReplica(id, updateReplicaRequest, options)(axios, basePath);
    },
  };
};

/**
 * ReplicasControllerApi - object-oriented interface
 * @export
 * @class ReplicasControllerApi
 * @extends {BaseAPI}
 */
export class ReplicasControllerApi extends BaseAPI {
  /**
   *
   * @param {CreateReplicaRequest} createReplicaRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicasControllerApi
   */
  public createReplica(createReplicaRequest: CreateReplicaRequest, options?: any) {
    return ReplicasControllerApiFp(this.configuration).createReplica(createReplicaRequest, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicasControllerApi
   */
  public deleteReplica(id: string, options?: any) {
    return ReplicasControllerApiFp(this.configuration).deleteReplica(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicasControllerApi
   */
  public getReplica(id: string, options?: any) {
    return ReplicasControllerApiFp(this.configuration).getReplica(id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} projectId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicasControllerApi
   */
  public getReplicasByProjectId(projectId: string, page?: number, pageSize?: number, options?: any) {
    return ReplicasControllerApiFp(this.configuration).getReplicasByProjectId(
      projectId,
      page,
      pageSize,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} id
   * @param {UpdateReplicaRequest} updateReplicaRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicasControllerApi
   */
  public updateReplica(id: string, updateReplicaRequest: UpdateReplicaRequest, options?: any) {
    return ReplicasControllerApiFp(this.configuration).updateReplica(
      id,
      updateReplicaRequest,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ReplicasHistoryControllerApi - axios parameter creator
 * @export
 */
export const ReplicasHistoryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} replicaId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistory(replicaId: string, page?: number, pageSize?: number, options: any = {}): RequestArgs {
      // verify required parameter 'replicaId' is not null or undefined
      if (replicaId === null || replicaId === undefined) {
        throw new RequiredError(
          'replicaId',
          'Required parameter replicaId was null or undefined when calling getHistory.'
        );
      }
      const localVarPath = `/api/replicas/history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (replicaId !== undefined) {
        localVarQueryParameter['replicaId'] = replicaId;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} historyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    selectVersionFromHistory(historyId: string, options: any = {}): RequestArgs {
      // verify required parameter 'historyId' is not null or undefined
      if (historyId === null || historyId === undefined) {
        throw new RequiredError(
          'historyId',
          'Required parameter historyId was null or undefined when calling selectVersionFromHistory.'
        );
      }
      const localVarPath = `/api/replicas/history/selectVersion/{historyId}`.replace(
        `{${'historyId'}}`,
        encodeURIComponent(String(historyId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReplicasHistoryControllerApi - functional programming interface
 * @export
 */
export const ReplicasHistoryControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} replicaId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistory(
      replicaId: string,
      page?: number,
      pageSize?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplicasHistoryView> {
      const localVarAxiosArgs = ReplicasHistoryControllerApiAxiosParamCreator(configuration).getHistory(
        replicaId,
        page,
        pageSize,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} historyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    selectVersionFromHistory(
      historyId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedReplicaView> {
      const localVarAxiosArgs = ReplicasHistoryControllerApiAxiosParamCreator(configuration).selectVersionFromHistory(
        historyId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReplicasHistoryControllerApi - factory interface
 * @export
 */
export const ReplicasHistoryControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} replicaId
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistory(replicaId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ReplicasHistoryView> {
      return ReplicasHistoryControllerApiFp(configuration).getHistory(
        replicaId,
        page,
        pageSize,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} historyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    selectVersionFromHistory(historyId: string, options?: any): AxiosPromise<SavedReplicaView> {
      return ReplicasHistoryControllerApiFp(configuration).selectVersionFromHistory(historyId, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * ReplicasHistoryControllerApi - object-oriented interface
 * @export
 * @class ReplicasHistoryControllerApi
 * @extends {BaseAPI}
 */
export class ReplicasHistoryControllerApi extends BaseAPI {
  /**
   *
   * @param {string} replicaId
   * @param {number} [page]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicasHistoryControllerApi
   */
  public getHistory(replicaId: string, page?: number, pageSize?: number, options?: any) {
    return ReplicasHistoryControllerApiFp(this.configuration).getHistory(
      replicaId,
      page,
      pageSize,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} historyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReplicasHistoryControllerApi
   */
  public selectVersionFromHistory(historyId: string, options?: any) {
    return ReplicasHistoryControllerApiFp(this.configuration).selectVersionFromHistory(historyId, options)(
      this.axios,
      this.basePath
    );
  }
}
