import React, { useCallback } from 'react';
import { SynthesizableVoice } from '../../../api/facade/client';
import createFastContext from '../../../utils/createFastContext';
import useDefaultAlert from '../../../utils/useAlert';
import { MAX_SYNTH_VOICES_PER_PAGE } from '../constants';
import { useProjectContext } from './ProjectsContext';

type VoicesStore = {
  availableVoices: SynthesizableVoice[];
  currentPage: number;
  maxVoicesReached: boolean;
  loading: boolean;
};

const voiceInitialStore: VoicesStore = {
  availableVoices: [],
  currentPage: 0,
  maxVoicesReached: false,
  loading: false,
};

const { Provider, useStore } = createFastContext(voiceInitialStore);

export const useVoicesStore = () => useStore(store => store);

export const VoicesProvider = ({ children }: { children: React.ReactNode }) => {
  return <Provider>{children}</Provider>;
};

export const useVoices = () => {
  const [{ availableVoices, currentPage, maxVoicesReached, loading }, setStore] = useStore(store => store);
  const { getVoicesList } = useProjectContext();
  const { defaultErrorAlert } = useDefaultAlert();

  const fetchInitialAvailableVoices = useCallback(async () => {
    try {
      const speakersData = await getVoicesList(0, MAX_SYNTH_VOICES_PER_PAGE);
      setStore({
        availableVoices: speakersData,
        maxVoicesReached: !Boolean(speakersData.length) || speakersData.length < MAX_SYNTH_VOICES_PER_PAGE,
      });
    } catch (error) {
      defaultErrorAlert(error);
    }
  }, [defaultErrorAlert, getVoicesList, setStore]);

  const fetchAvailableVoices = useCallback(
    async (currentPage?: number, added?: boolean) => {
      try {
        const speakersData = await getVoicesList(currentPage, MAX_SYNTH_VOICES_PER_PAGE);
        setStore({
          availableVoices: added ? [...availableVoices, ...speakersData] : speakersData,
          maxVoicesReached: !Boolean(speakersData.length) || speakersData.length < MAX_SYNTH_VOICES_PER_PAGE,
          loading: false,
        });
      } catch (error) {
        defaultErrorAlert(error);
      }
    },
    [getVoicesList, setStore, availableVoices, defaultErrorAlert]
  );
  return {
    availableVoices,
    voicesLoading: loading,
    voiceCurrentPage: currentPage,
    maxVoicesReached,
    setVoicesStore: setStore,
    fetchAvailableVoices,
    fetchInitialAvailableVoices,
  };
};
