import React from 'react';
import { ModalBody } from 'reactstrap';
import CustomModal from './CustomModal';
import { t } from '../../../../localization';

type DeleteProjectProps = {
  projectName: string;
  handleModalAction: () => void;
  cancelButtonClick: () => void;
};
export const CustomModalDeleteProject = ({ handleModalAction, cancelButtonClick, projectName }: DeleteProjectProps) => {
  return (
    <>
      <ModalBody className='custom-modal__body' style={{ marginBottom: '40px' }}>
        <p className='tp-3' style={{ wordBreak: 'break-all' }}>
          {t('deleteProjectModal')} <span className='st-1'>{projectName}</span>
        </p>
      </ModalBody>
      <CustomModal.Footer
        cancelButtonTitle={t('cancel')}
        actionButtonTitle={t('delete')}
        actionButtonClick={handleModalAction}
        cancelButtonClick={cancelButtonClick!}
        buttonSize='xl'
      />
    </>
  );
};
