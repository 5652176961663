import { useCallback, useEffect, useRef } from 'react';
import { Button, useToggle } from '@just-ai/just-ui';
import { AutoSizer, Index, IndexRange, InfiniteLoader, List } from 'react-virtualized';
import cn from 'classnames';

import { PresetView } from '../../../../../../api/dubber/client';
import { t } from '../../../../../../localization';
import {
  CustomModal,
  CustomModalCreatePreset,
  CustomModalPresetsManagement,
} from '../../../../../Library/components/CustomModal';
import { MAX_PRESETS_PER_PAGE } from '../../../../../Library/constants';
import { PresetSelectItem } from './PresetSelectItem';

const minMenuHeight = 95;

export const PresetSelectMenu = ({
  toggleMenu,
  isMenuOpen,
  presets,
  getData,
  handlePresetSelect,
  updateVoiceLine,
  setSelectedPreset,
  selectedPreset,
  getMoreData,
  selectRef,
}: {
  isMenuOpen: boolean;
  presets: { presets: PresetView[]; count: number };
  selectedPreset?: PresetView;
  selectRef: React.RefObject<HTMLDivElement>;
  toggleMenu: () => void;
  getData: (page?: number, pageSize?: number) => void;
  handlePresetSelect: (markup: PresetView) => void;
  updateVoiceLine: (preset: PresetView) => void;
  setSelectedPreset: (value?: PresetView) => void;
  getMoreData: (page?: number, pageSize?: number) => void;
}) => {
  const outsideRef = useRef<HTMLDivElement>(null);
  const [isManageModalOpen, , , toggleManageModal] = useToggle();
  const [isCreateModalOpen, , , toggleCreateModal] = useToggle();
  const currentPresetsCount = presets && presets.presets?.length;

  const inputHeight = selectRef.current?.getBoundingClientRect().bottom;
  const bodyHeight = document.body.getBoundingClientRect().bottom;
  const presetsInVew = () => {
    if (presets.count === 0) return minMenuHeight;
    return presets.count > 7 ? minMenuHeight + 230 : minMenuHeight + (presets.count - 1) * 40;
  };
  const isOverflowing = inputHeight && bodyHeight && inputHeight + presetsInVew() > bodyHeight;

  const handleOutsideClick = useCallback(
    event => {
      if (selectRef.current && !selectRef.current.contains(event.target) && isMenuOpen) {
        toggleMenu();
      }
    },
    [selectRef, isMenuOpen, toggleMenu]
  );

  useEffect(() => {
    if (isMenuOpen && !isManageModalOpen && !isCreateModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isMenuOpen, isManageModalOpen, isCreateModalOpen, handleOutsideClick]);

  const handleCloseMenuAfterCreate = useCallback(() => {
    toggleCreateModal();
    toggleMenu();
  }, [toggleCreateModal, toggleMenu]);

  const isRowLoaded = useCallback(
    ({ index }: Index) => {
      return !!presets[index];
    },
    [presets]
  );

  const loadMoreRows = useCallback(
    ({ startIndex, stopIndex }: IndexRange) => {
      const currentPage = Math.floor(startIndex / MAX_PRESETS_PER_PAGE);
      const maxPage = Math.floor(presets.count / MAX_PRESETS_PER_PAGE);
      if (currentPage <= maxPage && !presets.presets[stopIndex]) {
        getMoreData(currentPage + 1, MAX_PRESETS_PER_PAGE);
      }
      return new Promise<void>(resolve => resolve());
    },
    [presets, getMoreData]
  );
  return (
    <>
      <div className={cn('preset-select__menu', { overflow: isOverflowing })} ref={outsideRef}>
        <div
          style={{
            height: presets.count > 7 ? '260px' : `${presets.count === 0 ? 40 : presets.count * 40}px`,
            width: '100%',
          }}
        >
          <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={presets.count} threshold={2}>
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width}
                    height={height}
                    rowHeight={40}
                    rowCount={currentPresetsCount || 1}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    scrollToAlignment='start'
                    rowRenderer={({ key, index, style }) => {
                      if (presets.count > 0) {
                        return (
                          <PresetSelectItem
                            preset={presets.presets[index]}
                            onClick={handlePresetSelect}
                            style={style}
                            key={key}
                          />
                        );
                      } else {
                        return (
                          <div className='preset-select__empty' style={style} key={key}>
                            {t('ProjectPage:Presets:NoPresets')}
                          </div>
                        );
                      }
                    }}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
        <div className='preset-select__fixed-part'>
          <div className='preset-select__fixed-buttons'>
            <Button onClick={toggleManageModal} disabled={presets.count === 0}>
              {t('ProjectPage:Presets:Button:ManagePreset')}
            </Button>
            <Button onClick={toggleCreateModal}>{t('save')}</Button>
          </div>
        </div>
      </div>
      {presets?.count > 0 && (
        <CustomModal
          title={t('ProjectPage:Presets:ManageModal:Title')}
          isOpen={isManageModalOpen}
          toggle={toggleManageModal}
          disableKeyboard
        >
          <CustomModalPresetsManagement
            presets={presets.presets}
            getData={getData}
            setSelectedPreset={setSelectedPreset}
            selectedPreset={selectedPreset}
          />
        </CustomModal>
      )}
      <CustomModal
        title={t('ProjectPage:Presets:CreateModal:Title')}
        isOpen={isCreateModalOpen}
        toggle={toggleCreateModal}
        position='center'
      >
        <CustomModalCreatePreset
          updateVoiceLine={updateVoiceLine}
          toggleModal={handleCloseMenuAfterCreate}
          getData={getData}
        />
      </CustomModal>
    </>
  );
};
