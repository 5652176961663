import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@just-ai/just-ui';
import { t } from '../../../../localization';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE } from '../../../Header/constants';

type PlaceholderProps = {
  balancePlaceholder?: boolean;
};

export default function Placeholder({ balancePlaceholder }: PlaceholderProps) {
  const { id: voiceId } = useParams<{ id: string }>();
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });

  return (
    <div className='usage__empty'>
      {!balancePlaceholder && (isPhone ? <h4>{t('usageEmptyTitle')}</h4> : <h3>{t('usageEmptyTitle')}</h3>)}
      {isPhone ? <h4>{t('balanceHistory:Placeholder:Title')}</h4> : <h3>{t('balanceHistory:Placeholder:Title')}</h3>}
      {!balancePlaceholder && <p>{t('usageEmptySubTitle')}</p>}
      <Link to={balancePlaceholder ? '/my' : `/my/voice/${voiceId}/training`}>
        <Button color='primary'>{t(balancePlaceholder ? 'balanceHistory:Placeholde:Button' : 'toTrain')}</Button>
      </Link>
    </div>
  );
}
