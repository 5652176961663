import React from 'react';
import CustomModal from './CustomModal';
import { t } from '../../../../localization';

type DataLossProps = {
  actionButtonClick: () => void;
  cancelButtonClick: () => void;
};

export const CustomModalDataLoss = ({ actionButtonClick, cancelButtonClick }: DataLossProps) => {
  return (
    <CustomModal.Footer
      cancelButtonTitle={t('doNotSave')}
      actionButtonTitle={t('save')}
      actionButtonClick={actionButtonClick}
      cancelButtonClick={cancelButtonClick!}
    />
  );
};
