import React, { createRef } from 'react';
import { Button } from '@just-ai/just-ui';
import InputWrapper from './InputWrapper';
import { t } from '../../../../../localization';
import { parseString } from '../../../../../utils';
import classNames from 'classnames';

export class SampleTextInput extends InputWrapper {
  textareaRef = createRef<HTMLTextAreaElement>();
  divWithParsedTextRef = createRef<HTMLDivElement>();

  componentDidMount() {
    this.setState({ value: this.props.defaultValue }, () =>
      parseString({ string: String(this.state.value), ref: this.divWithParsedTextRef })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue }, () =>
        parseString({ string: String(this.state.value), ref: this.divWithParsedTextRef })
      );
    }
    if (this.state.textFieldActive && !prevState.textFieldActive) {
      this.textareaRef.current?.focus();
      this.textareaRef.current?.setSelectionRange(
        this.textareaRef.current?.value.length,
        this.textareaRef.current?.value.length
      );
    }
    if (!this.state.textFieldActive && prevState.textFieldActive) {
      parseString({ string: String(this.state.value), ref: this.divWithParsedTextRef });
    }
  }

  render() {
    return (
      <div
        className={classNames('catalog-page__sample-text', { changeError: this.state.showError })}
        onClick={() => this.setState({ textFieldActive: true })}
      >
        {this.state.textFieldActive ? (
          <>
            <textarea
              name='sampleText'
              value={this.state.value}
              onChange={this.handleChange}
              onBlur={() => this.throttledAction('save')}
              ref={this.textareaRef}
              data-test-id='samplePage.sampleTextInput'
            ></textarea>
            <div className='catalog-page__sample-text-buttons'>
              <Button
                color='primary'
                onMouseDown={() => this.throttledAction('save')}
                disabled={
                  this.state.value === this.props.defaultValue || this.state.value.length === 0 || this.state.showError
                }
                data-test-id='samplePage.textSubmit'
              >
                {t('save')}
              </Button>
              <Button outline color='secondary' onMouseDown={() => this.throttledAction('reset')}>
                {t('cancel')}
              </Button>
            </div>
          </>
        ) : (
          <div ref={this.divWithParsedTextRef} data-test-id='samplePage.sampleText'></div>
        )}
      </div>
    );
  }
}
