import React from 'react';
import { Modal, Form, ModalHeader, ModalFooter } from 'reactstrap';
import { Button, Icon } from '@just-ai/just-ui';

import './styles.scss';
import classNames from 'classnames';
import { Size } from '@just-ai/just-ui/dist/Common';

type CustomModalTypes = {
  isOpen: boolean;
  title?: string | React.ReactElement;
  position?: 'center';
  children: React.ReactNode;
  toggle?: () => void;
  customClassName?: string;
  size?: Size;
  fullscreen?: boolean;
  modalCard?: boolean;
  disableKeyboard?: boolean;
};

export default function CustomModal({
  isOpen,
  title,
  position,
  children,
  toggle,
  customClassName,
  fullscreen,
  modalCard,
  disableKeyboard,
  ...restProps
}: CustomModalTypes) {
  return (
    <Modal
      isOpen={isOpen}
      centered
      {...restProps}
      className={classNames('custom-modal__container', customClassName, {
        fullscreen: fullscreen,
        'modal-card': modalCard,
      })}
      toggle={toggle}
      data-test-id='modal'
      keyboard={!disableKeyboard}
    >
      <Form
        className={classNames('custom-modal', { 'custom-modal--center': position === 'center' })}
        onSubmit={e => e.preventDefault()}
      >
        <ModalHeader
          className={classNames('custom-modal__header', {
            hidden: !title,
            'custom-modal__header--center': position === 'center',
          })}
          toggle={toggle}
          charCode={<Icon name='falTimes' />}
          {...restProps}
          tag={typeof title === 'string' ? 'h4' : 'div'}
        >
          {title}
        </ModalHeader>
        {children}
      </Form>
    </Modal>
  );
}

CustomModal.Footer = function CustomModalFooter({
  cancelButtonTitle,
  actionButtonTitle,
  actionButtonClick,
  cancelButtonClick,
  cancelDisabled,
  disabled,
  buttonSize = 'lg',
}: {
  cancelButtonTitle?: string;
  actionButtonTitle: string;
  disabled?: boolean;
  cancelDisabled?: boolean;
  actionButtonClick: () => void;
  cancelButtonClick?: () => void;
  buttonSize?: Size;
}) {
  return (
    <ModalFooter className='custom-modal__footer'>
      {cancelButtonTitle && cancelButtonClick && (
        <Button
          disabled={cancelDisabled}
          outline
          color='secondary'
          size={buttonSize}
          onClick={cancelButtonClick}
          data-test-id='modal.cancel'
        >
          {cancelButtonTitle}
        </Button>
      )}
      <Button
        disabled={disabled}
        color='primary'
        size={buttonSize}
        onClick={actionButtonClick}
        data-test-id='modal.submit'
        type='submit'
      >
        {actionButtonTitle}
      </Button>
    </ModalFooter>
  );
};
