// tslint:disable
// @ts-nocheck
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH,  RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    absolute?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    absoluteFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    absolutePath?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    canonicalFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    canonicalPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    directory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    executable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    file?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    freeSpace?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    hidden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    lastModified?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    parent?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    parentFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    path?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    readable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    totalSpace?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    usableSpace?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    writable?: boolean;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    description?: string;
    /**
     * 
     * @type {any}
     * @memberof Resource
     */
    file?: any;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    filename?: string;
    /**
     * 
     * @type {object}
     * @memberof Resource
     */
    inputStream?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    open?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    readable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    uri?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface SampleCreate
 */
export interface SampleCreate {
    /**
     * 
     * @type {string}
     * @memberof SampleCreate
     */
    sampleText: string;
    /**
     * 
     * @type {string}
     * @memberof SampleCreate
     */
    sampleComment?: string;
    /**
     * 
     * @type {string}
     * @memberof SampleCreate
     */
    sampleTitle?: string;
}
/**
 * 
 * @export
 * @interface URI
 */
export interface URI {
    /**
     * 
     * @type {boolean}
     * @memberof URI
     */
    absolute?: boolean;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    authority?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    fragment?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    host?: string;
    /**
     * 
     * @type {boolean}
     * @memberof URI
     */
    opaque?: boolean;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof URI
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawAuthority?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawFragment?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawPath?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawQuery?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawSchemeSpecificPart?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawUserInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    scheme?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    schemeSpecificPart?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    userInfo?: string;
}

/**
 * ResourcesLoaderEndpointApi - axios parameter creator
 * @export
 */
export const ResourcesLoaderEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary uploadVoicePicture
         * @param {number} voiceId voiceId
         * @param {any} image image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVoicePictureUsingPOST(voiceId: number, image: any, options: any = {}): RequestArgs {
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling uploadVoicePictureUsingPOST.');
            }
            // verify required parameter 'image' is not null or undefined
            if (image === null || image === undefined) {
                throw new RequiredError('image','Required parameter image was null or undefined when calling uploadVoicePictureUsingPOST.');
            }
            const localVarPath = `/api/voices/{voiceId}/picture/upload`
                .replace(`{${"voiceId"}}`, encodeURIComponent(String(voiceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourcesLoaderEndpointApi - functional programming interface
 * @export
 */
export const ResourcesLoaderEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary uploadVoicePicture
         * @param {number} voiceId voiceId
         * @param {any} image image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVoicePictureUsingPOST(voiceId: number, image: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ResourcesLoaderEndpointApiAxiosParamCreator(configuration).uploadVoicePictureUsingPOST(voiceId, image, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ResourcesLoaderEndpointApi - factory interface
 * @export
 */
export const ResourcesLoaderEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary uploadVoicePicture
         * @param {number} voiceId voiceId
         * @param {any} image image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadVoicePictureUsingPOST(voiceId: number, image: any, options?: any): AxiosPromise<void> {
            return ResourcesLoaderEndpointApiFp(configuration).uploadVoicePictureUsingPOST(voiceId, image, options)(axios, basePath);
        },
    };
};

/**
 * ResourcesLoaderEndpointApi - object-oriented interface
 * @export
 * @class ResourcesLoaderEndpointApi
 * @extends {BaseAPI}
 */
export class ResourcesLoaderEndpointApi extends BaseAPI {
    /**
     * 
     * @summary uploadVoicePicture
     * @param {number} voiceId voiceId
     * @param {any} image image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesLoaderEndpointApi
     */
    public uploadVoicePictureUsingPOST(voiceId: number, image: any, options?: any) {
        return ResourcesLoaderEndpointApiFp(this.configuration).uploadVoicePictureUsingPOST(voiceId, image, options)(this.axios, this.basePath);
    }

}


/**
 * SamplesLoaderEndpointApi - axios parameter creator
 * @export
 */
export const SamplesLoaderEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createSample
         * @param {number} catalogId catalogId
         * @param {number} voiceId voiceId
         * @param {SampleCreate} sample sample
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSampleUsingPOST(catalogId: number, voiceId: number, sample: SampleCreate, options: any = {}): RequestArgs {
            // verify required parameter 'catalogId' is not null or undefined
            if (catalogId === null || catalogId === undefined) {
                throw new RequiredError('catalogId','Required parameter catalogId was null or undefined when calling createSampleUsingPOST.');
            }
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling createSampleUsingPOST.');
            }
            // verify required parameter 'sample' is not null or undefined
            if (sample === null || sample === undefined) {
                throw new RequiredError('sample','Required parameter sample was null or undefined when calling createSampleUsingPOST.');
            }
            const localVarPath = `/api/voices/{voiceId}/catalogs/{catalogId}/samples/`
                .replace(`{${"catalogId"}}`, encodeURIComponent(String(catalogId)))
                .replace(`{${"voiceId"}}`, encodeURIComponent(String(voiceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof sample !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sample !== undefined ? sample : {}) : (sample || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteSampleAudio
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSampleAudioUsingDELETE(sampleId: number, voiceId: number, options: any = {}): RequestArgs {
            // verify required parameter 'sampleId' is not null or undefined
            if (sampleId === null || sampleId === undefined) {
                throw new RequiredError('sampleId','Required parameter sampleId was null or undefined when calling deleteSampleAudioUsingDELETE.');
            }
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling deleteSampleAudioUsingDELETE.');
            }
            const localVarPath = `/api/voices/{voiceId}/samples/{sampleId}/audio`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)))
                .replace(`{${"voiceId"}}`, encodeURIComponent(String(voiceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadSampleAudio
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSampleAudioUsingGET(sampleId: number, voiceId: number, options: any = {}): RequestArgs {
            // verify required parameter 'sampleId' is not null or undefined
            if (sampleId === null || sampleId === undefined) {
                throw new RequiredError('sampleId','Required parameter sampleId was null or undefined when calling downloadSampleAudioUsingGET.');
            }
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling downloadSampleAudioUsingGET.');
            }
            const localVarPath = `/api/voices/{voiceId}/samples/{sampleId}/download`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)))
                .replace(`{${"voiceId"}}`, encodeURIComponent(String(voiceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSampleText
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleTextUsingGET(sampleId: number, voiceId: number, options: any = {}): RequestArgs {
            // verify required parameter 'sampleId' is not null or undefined
            if (sampleId === null || sampleId === undefined) {
                throw new RequiredError('sampleId','Required parameter sampleId was null or undefined when calling getSampleTextUsingGET.');
            }
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling getSampleTextUsingGET.');
            }
            const localVarPath = `/api/voices/{voiceId}/samples/{sampleId}/text`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)))
                .replace(`{${"voiceId"}}`, encodeURIComponent(String(voiceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSampleAudio
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {any} audio audio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSampleAudioUsingPOST(sampleId: number, voiceId: number, audio: any, options: any = {}): RequestArgs {
            // verify required parameter 'sampleId' is not null or undefined
            if (sampleId === null || sampleId === undefined) {
                throw new RequiredError('sampleId','Required parameter sampleId was null or undefined when calling updateSampleAudioUsingPOST.');
            }
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling updateSampleAudioUsingPOST.');
            }
            // verify required parameter 'audio' is not null or undefined
            if (audio === null || audio === undefined) {
                throw new RequiredError('audio','Required parameter audio was null or undefined when calling updateSampleAudioUsingPOST.');
            }
            const localVarPath = `/api/voices/{voiceId}/samples/{sampleId}/upload`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)))
                .replace(`{${"voiceId"}}`, encodeURIComponent(String(voiceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


            if (audio !== undefined) { 
                localVarFormParams.append('audio', audio as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSampleText
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {string} text text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSampleTextUsingPATCH(sampleId: number, voiceId: number, text: string, options: any = {}): RequestArgs {
            // verify required parameter 'sampleId' is not null or undefined
            if (sampleId === null || sampleId === undefined) {
                throw new RequiredError('sampleId','Required parameter sampleId was null or undefined when calling updateSampleTextUsingPATCH.');
            }
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling updateSampleTextUsingPATCH.');
            }
            // verify required parameter 'text' is not null or undefined
            if (text === null || text === undefined) {
                throw new RequiredError('text','Required parameter text was null or undefined when calling updateSampleTextUsingPATCH.');
            }
            const localVarPath = `/api/voices/{voiceId}/samples/{sampleId}/text`
                .replace(`{${"sampleId"}}`, encodeURIComponent(String(sampleId)))
                .replace(`{${"voiceId"}}`, encodeURIComponent(String(voiceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof text !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(text !== undefined ? text : {}) : (text || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadArchivedSamples
         * @param {number} catalogId catalogId
         * @param {number} voiceId voiceId
         * @param {any} samples samples
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArchivedSamplesUsingPOST(catalogId: number, voiceId: number, samples: any, options: any = {}): RequestArgs {
            // verify required parameter 'catalogId' is not null or undefined
            if (catalogId === null || catalogId === undefined) {
                throw new RequiredError('catalogId','Required parameter catalogId was null or undefined when calling uploadArchivedSamplesUsingPOST.');
            }
            // verify required parameter 'voiceId' is not null or undefined
            if (voiceId === null || voiceId === undefined) {
                throw new RequiredError('voiceId','Required parameter voiceId was null or undefined when calling uploadArchivedSamplesUsingPOST.');
            }
            // verify required parameter 'samples' is not null or undefined
            if (samples === null || samples === undefined) {
                throw new RequiredError('samples','Required parameter samples was null or undefined when calling uploadArchivedSamplesUsingPOST.');
            }
            const localVarPath = `/api/voices/{voiceId}/catalogs/{catalogId}/upload`
                .replace(`{${"catalogId"}}`, encodeURIComponent(String(catalogId)))
                .replace(`{${"voiceId"}}`, encodeURIComponent(String(voiceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


            if (samples !== undefined) { 
                localVarFormParams.append('samples', samples as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SamplesLoaderEndpointApi - functional programming interface
 * @export
 */
export const SamplesLoaderEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createSample
         * @param {number} catalogId catalogId
         * @param {number} voiceId voiceId
         * @param {SampleCreate} sample sample
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSampleUsingPOST(catalogId: number, voiceId: number, sample: SampleCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = SamplesLoaderEndpointApiAxiosParamCreator(configuration).createSampleUsingPOST(catalogId, voiceId, sample, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteSampleAudio
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSampleAudioUsingDELETE(sampleId: number, voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SamplesLoaderEndpointApiAxiosParamCreator(configuration).deleteSampleAudioUsingDELETE(sampleId, voiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary downloadSampleAudio
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSampleAudioUsingGET(sampleId: number, voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SamplesLoaderEndpointApiAxiosParamCreator(configuration).downloadSampleAudioUsingGET(sampleId, voiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getSampleText
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleTextUsingGET(sampleId: number, voiceId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SamplesLoaderEndpointApiAxiosParamCreator(configuration).getSampleTextUsingGET(sampleId, voiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateSampleAudio
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {any} audio audio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSampleAudioUsingPOST(sampleId: number, voiceId: number, audio: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SamplesLoaderEndpointApiAxiosParamCreator(configuration).updateSampleAudioUsingPOST(sampleId, voiceId, audio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateSampleText
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {string} text text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSampleTextUsingPATCH(sampleId: number, voiceId: number, text: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SamplesLoaderEndpointApiAxiosParamCreator(configuration).updateSampleTextUsingPATCH(sampleId, voiceId, text, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary uploadArchivedSamples
         * @param {number} catalogId catalogId
         * @param {number} voiceId voiceId
         * @param {any} samples samples
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArchivedSamplesUsingPOST(catalogId: number, voiceId: number, samples: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SamplesLoaderEndpointApiAxiosParamCreator(configuration).uploadArchivedSamplesUsingPOST(catalogId, voiceId, samples, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SamplesLoaderEndpointApi - factory interface
 * @export
 */
export const SamplesLoaderEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary createSample
         * @param {number} catalogId catalogId
         * @param {number} voiceId voiceId
         * @param {SampleCreate} sample sample
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSampleUsingPOST(catalogId: number, voiceId: number, sample: SampleCreate, options?: any): AxiosPromise<number> {
            return SamplesLoaderEndpointApiFp(configuration).createSampleUsingPOST(catalogId, voiceId, sample, options)(axios, basePath);
        },
        /**
         * 
         * @summary deleteSampleAudio
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSampleAudioUsingDELETE(sampleId: number, voiceId: number, options?: any): AxiosPromise<void> {
            return SamplesLoaderEndpointApiFp(configuration).deleteSampleAudioUsingDELETE(sampleId, voiceId, options)(axios, basePath);
        },
        /**
         * 
         * @summary downloadSampleAudio
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSampleAudioUsingGET(sampleId: number, voiceId: number, options?: any): AxiosPromise<void> {
            return SamplesLoaderEndpointApiFp(configuration).downloadSampleAudioUsingGET(sampleId, voiceId, options)(axios, basePath);
        },
        /**
         * 
         * @summary getSampleText
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleTextUsingGET(sampleId: number, voiceId: number, options?: any): AxiosPromise<string> {
            return SamplesLoaderEndpointApiFp(configuration).getSampleTextUsingGET(sampleId, voiceId, options)(axios, basePath);
        },
        /**
         * 
         * @summary updateSampleAudio
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {any} audio audio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSampleAudioUsingPOST(sampleId: number, voiceId: number, audio: any, options?: any): AxiosPromise<void> {
            return SamplesLoaderEndpointApiFp(configuration).updateSampleAudioUsingPOST(sampleId, voiceId, audio, options)(axios, basePath);
        },
        /**
         * 
         * @summary updateSampleText
         * @param {number} sampleId sampleId
         * @param {number} voiceId voiceId
         * @param {string} text text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSampleTextUsingPATCH(sampleId: number, voiceId: number, text: string, options?: any): AxiosPromise<void> {
            return SamplesLoaderEndpointApiFp(configuration).updateSampleTextUsingPATCH(sampleId, voiceId, text, options)(axios, basePath);
        },
        /**
         * 
         * @summary uploadArchivedSamples
         * @param {number} catalogId catalogId
         * @param {number} voiceId voiceId
         * @param {any} samples samples
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArchivedSamplesUsingPOST(catalogId: number, voiceId: number, samples: any, options?: any): AxiosPromise<void> {
            return SamplesLoaderEndpointApiFp(configuration).uploadArchivedSamplesUsingPOST(catalogId, voiceId, samples, options)(axios, basePath);
        },
    };
};

/**
 * SamplesLoaderEndpointApi - object-oriented interface
 * @export
 * @class SamplesLoaderEndpointApi
 * @extends {BaseAPI}
 */
export class SamplesLoaderEndpointApi extends BaseAPI {
    /**
     * 
     * @summary createSample
     * @param {number} catalogId catalogId
     * @param {number} voiceId voiceId
     * @param {SampleCreate} sample sample
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesLoaderEndpointApi
     */
    public createSampleUsingPOST(catalogId: number, voiceId: number, sample: SampleCreate, options?: any) {
        return SamplesLoaderEndpointApiFp(this.configuration).createSampleUsingPOST(catalogId, voiceId, sample, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary deleteSampleAudio
     * @param {number} sampleId sampleId
     * @param {number} voiceId voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesLoaderEndpointApi
     */
    public deleteSampleAudioUsingDELETE(sampleId: number, voiceId: number, options?: any) {
        return SamplesLoaderEndpointApiFp(this.configuration).deleteSampleAudioUsingDELETE(sampleId, voiceId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary downloadSampleAudio
     * @param {number} sampleId sampleId
     * @param {number} voiceId voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesLoaderEndpointApi
     */
    public downloadSampleAudioUsingGET(sampleId: number, voiceId: number, options?: any) {
        return SamplesLoaderEndpointApiFp(this.configuration).downloadSampleAudioUsingGET(sampleId, voiceId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary getSampleText
     * @param {number} sampleId sampleId
     * @param {number} voiceId voiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesLoaderEndpointApi
     */
    public getSampleTextUsingGET(sampleId: number, voiceId: number, options?: any) {
        return SamplesLoaderEndpointApiFp(this.configuration).getSampleTextUsingGET(sampleId, voiceId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary updateSampleAudio
     * @param {number} sampleId sampleId
     * @param {number} voiceId voiceId
     * @param {any} audio audio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesLoaderEndpointApi
     */
    public updateSampleAudioUsingPOST(sampleId: number, voiceId: number, audio: any, options?: any) {
        return SamplesLoaderEndpointApiFp(this.configuration).updateSampleAudioUsingPOST(sampleId, voiceId, audio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary updateSampleText
     * @param {number} sampleId sampleId
     * @param {number} voiceId voiceId
     * @param {string} text text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesLoaderEndpointApi
     */
    public updateSampleTextUsingPATCH(sampleId: number, voiceId: number, text: string, options?: any) {
        return SamplesLoaderEndpointApiFp(this.configuration).updateSampleTextUsingPATCH(sampleId, voiceId, text, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary uploadArchivedSamples
     * @param {number} catalogId catalogId
     * @param {number} voiceId voiceId
     * @param {any} samples samples
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesLoaderEndpointApi
     */
    public uploadArchivedSamplesUsingPOST(catalogId: number, voiceId: number, samples: any, options?: any) {
        return SamplesLoaderEndpointApiFp(this.configuration).uploadArchivedSamplesUsingPOST(catalogId, voiceId, samples, options)(this.axios, this.basePath);
    }

}


