import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { Icon, InputTextFullUncontrolled, Button, FormText, JustSelect } from '@just-ai/just-ui';
import { useAmplitude } from 'react-amplitude-hooks';
import MediaQuery, { useMediaQuery } from 'react-responsive';

import { GenderEnum, LanguageEnum } from '../../../../api/facade/client';
import { useLibraryContext } from '../../context/LibraryContext';
import useAlert from '../../../../utils/useAlert';
import { t } from '../../../../localization';
import { SCREEN_WIDTH_PHONE, SCREEN_WIDTH_TABLET } from '../../../Header/constants';
import { useAppContext } from '../../../../AppContext';
import withDocumentData from '../../../../utils/withDocumentData';
import { languagesMap, MAX_VOICE_DESCRIPTION_LEGHT } from '../../constants';

import './NewVoicePage.scss';

type Inputs = {
  name: string;
  description: string;
};

function NewVoicePage() {
  const [gender, setGender] = useState(GenderEnum.MALE);
  const [language, setLanguage] = useState<string>();
  const [languageError, setLanguageError] = useState(false);
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const { defaultErrorAlert, successAlert } = useAlert();
  const { createVoice } = useLibraryContext();
  const { logEvent } = useAmplitude();
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const tabletDown = useMediaQuery({ query: `(max-width:${SCREEN_WIDTH_TABLET}px)` });
  const history = useHistory();
  const { isLoggedIn, configData } = useAppContext();
  const match = useRouteMatch();
  const { id } = useAppContext();

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.href = `${configData?.authPath}${history.location.pathname}`;
    }
    logEvent('Page opened', {
      page_url: match.url,
      page_type: match.path,
      user_id: id,
    });
  }, [configData, history.location.pathname, id, isLoggedIn, logEvent, match.path, match.url]);

  useEffect(() => {
    if (errors.name && !language) setLanguageError(true);
  }, [errors, language]);

  const handleResize = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.target.style.height = 'auto';
    const borderHeight = 1;
    event.target.style.height = event.target.scrollHeight + borderHeight + 'px';
  };

  const handleGenderChange = useCallback(() => {
    setGender(oldGender => (oldGender === GenderEnum.MALE ? GenderEnum.FEMALE : GenderEnum.MALE));
  }, []);

  const handleLanguageChange = useCallback(
    value => {
      if (languageError) setLanguageError(false);
      setLanguage(value[0]);
    },
    [languageError]
  );

  const onSubmit = useCallback(
    async (data: Inputs) => {
      if (!language) return setLanguageError(true);
      try {
        const createdVoice = await createVoice({
          name: data.name.trim(),
          description: data.description.trim(),
          gender: gender,
          language: language as LanguageEnum,
        });
        logEvent('Voice created', {
          url: `/my/voice/${createdVoice}/training`,
          result: 'success',
          user_id: id,
        });
        if (createdVoice) {
          history.push(`/my/voice/${createdVoice}/training`);
        }
        successAlert(t('voiceCreated', data.name));
      } catch (error) {
        defaultErrorAlert(error);
        logEvent('Voice created', {
          result: 'failed',
          url: `${match.url}`,
          user_id: id,
        });
      }
    },
    [createVoice, defaultErrorAlert, gender, history, id, language, logEvent, match.url, successAlert]
  );

  const labelsAndValuesSelect = configData.supportedVoiceLanguages.reduce<{ label: string; value: string }[]>(
    (acc, language) => {
      acc.push({ label: t(languagesMap[language]), value: language });
      return acc;
    },
    []
  );

  if (!isLoggedIn) return null;
  return (
    <div className='new-voice__wrapper'>
      <div className='new-voice__container'>
        <div className='new-voice__back-container'>
          <Link to='/my'>
            <div className='new-voice__back'>
              <Icon name='falArrowLeft' size='sm' />
              {!isPhone && <p>{t('myVoices')}</p>}
            </div>
          </Link>
          <MediaQuery maxWidth={SCREEN_WIDTH_TABLET}>
            <h4>{t('createVoice')}</h4>
          </MediaQuery>
        </div>
        <div className='new-voice__create-wrapper'>
          <div className='new-voice__create'>
            {!tabletDown && <h3>{t('createVoice')}</h3>}
            <form className='new-voice__form' onSubmit={handleSubmit(onSubmit)}>
              <div className='new-voice__input'>
                <InputTextFullUncontrolled
                  name='name'
                  placeholder={t('createVoice: inputPlaceholder')}
                  maxLength={70}
                  bsSize='sm'
                  innerRef={register({ required: t('fieldRequired') })}
                  invalid={!!errors.name}
                  autoComplete='off'
                  data-test-id='newVoice.title'
                />
                {errors.name && <FormText color='danger'>{errors.name.message}</FormText>}
              </div>
              <label className='new-voice__switch switch'>
                <div className='switch__labels'>
                  <span>{t('male')}</span>
                  <span>{t('female')}</span>
                </div>
                <input type='checkbox' onClick={handleGenderChange} data-test-id='newVoice.gender' />
                <div className='switch__slider'>{GenderEnum.MALE === gender ? t('male') : t('female')}</div>
              </label>
              <textarea
                maxLength={MAX_VOICE_DESCRIPTION_LEGHT}
                name='description'
                placeholder={t('createVoice: textareaPlaceholder')}
                ref={register}
                rows={1}
                onChange={handleResize}
                data-test-id='newVoice.description'
              />
              <div className='new-voice__language'>
                <JustSelect
                  value={language}
                  onChange={handleLanguageChange}
                  inputPlaceholder={t('chooseLanguage')}
                  options={labelsAndValuesSelect}
                  fullWidth
                  invalid={languageError}
                  dropdownIconName='falChevronDown'
                  data-test-id='newVoice.language'
                  listAutoPosition
                  position='fixed'
                />
                {languageError && <FormText color='danger'>{t('fieldRequired')}</FormText>}
              </div>
              <Button
                size='lg'
                color='primary'
                type='submit'
                onSubmit={handleSubmit(onSubmit)}
                data-test-id='newVoice.createBtn'
              >
                {t('createVoice')}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withDocumentData(NewVoicePage, 'createVoice');
