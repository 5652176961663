import React from 'react';

import { t } from '../../../../../localization';
import { Step } from './Step';

import './StepsForCreating.scss';

type stepsArrTypes = {
  title: string;
  subTitle: string;
  link: string;
};

const stepsArr: stepsArrTypes[] = [
  {
    title: 'createVoiceSteps.step1.title',
    subTitle: 'createVoiceSteps.step1.subtitle',
    link: '',
  },
  {
    title: 'createVoiceSteps.step2.title',
    subTitle: 'createVoiceSteps.step2.subtitle',
    link: '',
  },
  {
    title: 'createVoiceSteps.step3.title',
    subTitle: 'createVoiceSteps.step3.subtitle',
    link: '',
  },
  {
    title: 'createVoiceSteps.step4.title',
    subTitle: 'createVoiceSteps.step4.subtitle',
    link:
      '/create-voice/voice-history/#%D0%BA%D0%B0%D0%BA-%D0%BE%D0%BF%D1%83%D0%B1%D0%BB%D0%B8%D0%BA%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%8E',
  },
];

export const StepsForCreating = () => {
  return (
    <div className='training-page__steps-wrapper'>
      <div className='training-page__steps-header'>
        <h3>{t('createVoiceSteps.title')}</h3>
        <p className='tp-3'>{t('createVoiceSteps.subtitle')}</p>
      </div>
      <div className='training-page__steps-container'>
        {stepsArr.map((item, index) => (
          <Step title={item.title} subTitle={item.subTitle} link={item.link} number={index + 1} />
        ))}
      </div>
    </div>
  );
};
