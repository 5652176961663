import React, { Component, createContext, useContext } from 'react';
import ProjectsService from '../../../service/ProjectsService';
import {
  AIWriterRequest,
  AIWriterResponseView,
  ActiveDownloadProjectView,
  CreateDictionaryPhraseRequest,
  CreateReplicaRequest,
  DownloadProjectView,
  MarkupSettings,
  MarkupUpdate,
  PhraseDictionaryView,
  PresetView,
  ProcessProjectReplicasRequest,
  ProjectView,
  ReplicasHistoryView,
  ReplicaView,
  UpdateDictionaryPhraseRequest,
  UpdateReplicaRequest,
} from '../../../api/dubber/client';
import VoiceService from '../../../service/VoiceService';
import { SynthesizableVoice } from '../../../api/facade/client/api';
import { LoadCallback } from '../../../utils/hooks';
import { getErrorCodeFromReason } from '../../../utils/error';
import { history } from '../../../App';
import ModelService from '../../../service/ModelService';
import { DownloadTypes } from '../constants';

export type ProjectContextType = {
  createProject: (name: string) => Promise<ProjectView>;
  updateProject: (projectId: string, newName: string) => Promise<void>;
  getProjects: (page: number, pageSize: number) => Promise<{ projects: ProjectView[]; count: number }>;
  getProject: (projectId: string) => Promise<ProjectView>;
  deleteProject: (projectId: string) => Promise<void>;
  getVoicesList: (page?: number, pageSize?: number) => Promise<SynthesizableVoice[]>;
  getVoiceLines: ({
    projectId,
    page,
    pageSize,
  }: {
    page?: number;
    pageSize?: number;
    projectId: string;
  }) => Promise<{ replicas: ReplicaView[]; totalElements: number }>;
  getVoiceLine: (voiceLineId: string) => Promise<ReplicaView>;
  updateVoiceLine: (voiceLineId: string, updateObj: UpdateReplicaRequest) => Promise<ReplicaView>;
  deleteVoiceLine: (voiceLineId: string) => Promise<void>;
  bulkUpdate: (projectId: string, updateObj: ProcessProjectReplicasRequest) => Promise<void>;
  createVoiceLine: (reqObj: CreateReplicaRequest) => Promise<ReplicaView>;
  offlineMode: boolean;
  loading: boolean;
  getVoiceSettings: (voiceId: number) => Promise<MarkupSettings>;
  getPresets: (page?: number, pageSize?: number) => Promise<{ presets: PresetView[]; count: number }>;
  createPreset: (name: string, markupUpdate: MarkupUpdate) => Promise<PresetView>;
  updatePreset: (id: string, name: string, markupUpdate?: MarkupUpdate) => Promise<void>;
  deletePreset: (id: string) => Promise<void>;
  getPresetById: (id: string) => Promise<PresetView>;
  getHistory: (replicaId: string, page?: number, pageSize?: number) => Promise<ReplicasHistoryView>;
  selectVersion: (historyId: string) => void;
  synthesizeReplica: (replicaId: string) => Promise<string>;
  synthesizeReplicaFromhistory: (replicaId: string) => Promise<string>;
  downloadReplicaAudio: (replicaId: string, fileName: string) => Promise<string>;
  downloadHistoryAudio: (historyId: string, fileName: string) => Promise<string>;
  returnedFromOffline: boolean;
  handleReturnedFromOffline: (value: boolean) => void;
  generateStory: (aiWriterRequest: AIWriterRequest, options?: any) => Promise<AIWriterResponseView>;
  getActiveDownloads: (projectId: string) => Promise<ActiveDownloadProjectView>;
  cancelDownload: (projectId: string, downloadId: string) => void;
  downloadProject: (projectId: string, format: DownloadTypes) => Promise<DownloadProjectView>;
  getDownloadStatus: (projectId: string, downloadId: string) => Promise<DownloadProjectView>;
  createPhraseDictionary: (createObj: CreateDictionaryPhraseRequest) => Promise<void>;
  getPhraseDictionarys: (page?: number, pageSize?: number) => Promise<PhraseDictionaryView[]>;
  updatePhraseDictionary: (id: string, updateObj: UpdateDictionaryPhraseRequest) => Promise<void>;
  deletePhraseDictionary: (id: string) => Promise<void>;
};

type ProjectContextProps = {
  accountId?: number;
};

class State {
  offlineMode: boolean = false;
  returnedFromOffline = false;
  loading: boolean = false;
}

export const ProjectContext = createContext({} as ProjectContextType);

export class ProjectContextProvider extends Component<ProjectContextProps, State> {
  ProjectService: ProjectsService = new ProjectsService();
  VoiceService: VoiceService;
  ModelService: ModelService = new ModelService();
  state = new State();

  constructor(props) {
    super(props);
    this.VoiceService = new VoiceService(props.accountId);
  }

  load: LoadCallback = promise => {
    this.setState({ loading: true });
    return promise.finally(() => this.setState({ loading: false }));
  };

  hanldeOffline = (value: boolean) => {
    this.setState({ offlineMode: value });
  };
  handleReturnedFromOffline = (value: boolean) => {
    this.setState({ returnedFromOffline: value });
  };

  componentDidMount() {
    window.addEventListener('offline', () => this.hanldeOffline(true));
    window.addEventListener('online', () => this.hanldeOffline(false));
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (prevState.offlineMode && !this.state.offlineMode) {
      this.setState({ returnedFromOffline: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('offline', () => this.hanldeOffline(true));
    window.removeEventListener('online', () => this.hanldeOffline(false));
  }

  createProject = async (name: string) => {
    const projectView = await this.ProjectService.createProject(name);
    return projectView;
  };

  updateProject = async (projectId: string, newName: string) => {
    await this.ProjectService.updateProject(projectId, newName);
  };

  getProjects = async (page: number, pageSize: number) => {
    const data = await this.ProjectService.getProjects(page, pageSize);
    return data;
  };

  getProject = async (projectId: string) => {
    const data = await this.ProjectService.getProject(projectId);
    return data;
  };

  deleteProject = async (projectId: string) => {
    await this.ProjectService.deleteProject(projectId);
  };

  getVoicesList = async (page?: number, pageSize?: number) => {
    return await this.VoiceService.getAllVoicesForSynth(page, pageSize);
  };

  getVoiceLines = async ({ projectId, page, pageSize }: { page?: number; pageSize?: number; projectId: string }) => {
    try {
      return await this.ProjectService.getProjectVoiceLines({ projectId, page, pageSize });
    } catch (error) {
      const errorCode = getErrorCodeFromReason(error);
      if (errorCode === 'project.not.found') {
        history.push('/my');
      }
      throw error;
    }
  };

  getVoiceLine = async (lineId: string) => {
    try {
      return await this.ProjectService.getVoiceLineById({ lineId });
    } catch (error) {
      const errorCode = getErrorCodeFromReason(error);
      if (errorCode === 'project.not.found') {
        history.push('/my');
      }
      throw error;
    }
  };

  createVoiceLine = async (reqObj: CreateReplicaRequest) => {
    try {
      return await this.load(this.ProjectService.createProjectVoiceLine(reqObj));
    } catch (error) {
      const errorCode = getErrorCodeFromReason(error);
      if (errorCode === 'project.not.found') {
        history.push('/my');
      }
      throw error;
    }
  };

  updateVoiceLine = async (voiceLineId: string, updateObj: UpdateReplicaRequest) => {
    try {
      return await this.load(this.ProjectService.updateProjectVoiceLine(voiceLineId, updateObj));
    } catch (error) {
      const errorCode = getErrorCodeFromReason(error);
      if (errorCode === 'project.not.found') {
        history.push('/my');
      }
      throw error;
    }
  };

  deleteVoiceLine = async (voiceLineId: string) => {
    try {
      await this.load(this.ProjectService.deleteProjectVoiceLine(voiceLineId));
    } catch (error) {
      const errorCode = getErrorCodeFromReason(error);
      if (errorCode === 'project.not.found') {
        history.push('/my');
      }
      throw error;
    }
  };

  bulkUpdate = async (projectId: string, updateObj: ProcessProjectReplicasRequest) => {
    try {
      return await this.load(this.ProjectService.updateProjectVoiceLines(projectId, updateObj));
    } catch (error) {
      const errorCode = getErrorCodeFromReason(error);
      if (errorCode === 'project.not.found') {
        history.push('/my');
      }
      throw error;
    }
  };

  getVoiceSettings = async (voiceId: number) => {
    return await this.ProjectService.getProjectVoiceSettings(voiceId);
  };

  getPresets = (page?: number, pageSize?: number) => {
    return this.ProjectService.getPresets(page, pageSize);
  };

  createPreset = async (name: string, markupUpdate: MarkupUpdate) => {
    return await this.ProjectService.createPreset(name, markupUpdate);
  };

  updatePreset = async (id: string, name: string, markupUpdate?: MarkupUpdate) => {
    await this.ProjectService.updatePreset(id, { name, markupUpdate });
  };

  deletePreset = async (id: string) => {
    await this.ProjectService.deletePreset(id);
  };

  getPresetById = async (id: string) => {
    return await this.ProjectService.getPresetById(id);
  };

  getHistory = async (replicaId: string, page?: number, pageSize?: number) => {
    return await this.ProjectService.getHistory(replicaId, page, pageSize);
  };

  selectVersion = async (historyId: string) => {
    await this.ProjectService.selectVersion(historyId);
  };

  synthesizeReplica = async (replicaId: string) => {
    const phraseUrl = await this.ModelService.synthesizeVoicePhrase(replicaId);
    return phraseUrl;
  };
  synthesizeReplicaFromHistory = async (replicaId: string) => {
    const phraseUrl = await this.ModelService.synthesizeFromHistory(replicaId);
    return phraseUrl;
  };

  downloadReplicaAudio = async (replicaId: string, fileName: string) => {
    const audio = await this.ModelService.downloadReplicaAudio(replicaId, fileName);
    return audio;
  };
  downloadHistoryAudio = async (replicaId: string, fileName: string) => {
    const audio = await this.ModelService.downloadHistoryAudio(replicaId, fileName);
    return audio;
  };

  generateStory = async (aiWriterRequest: AIWriterRequest, options?: any) => {
    return await this.ProjectService.generateStory(aiWriterRequest, options);
  };
  getActiveDownloads = async (projectId: string) => {
    return await this.ProjectService.getActiveDownloads(projectId);
  };

  cancelDownload = (projectId: string, downloadId: string) => {
    this.ProjectService.cancelDownload(projectId, downloadId);
  };
  downloadProject = async (projectId: string, format: DownloadTypes) => {
    return await this.ProjectService.downloadProject(projectId, format);
  };
  getDownloadStatus = async (projectId: string, downloadId: string) => {
    return await this.ProjectService.getDownloadStatus(projectId, downloadId);
  };
  createPhraseDictionary = async (createObj: CreateDictionaryPhraseRequest) => {
    await this.ProjectService.createPhraseDictionary(createObj);
  };
  getPhraseDictionarys = async (page?: number, pageSize?: number) => {
    return await this.ProjectService.getPhrasesDictionary(page, pageSize);
  };

  updatePhraseDictionary = async (id: string, updateObj: UpdateDictionaryPhraseRequest) => {
    await this.ProjectService.updatePhraseDictionary(id, updateObj);
  };

  deletePhraseDictionary = async (id: string) => {
    await this.ProjectService.deletePhraseDictionary(id);
  };

  render() {
    return (
      <ProjectContext.Provider
        value={{
          createProject: this.createProject,
          updateProject: this.updateProject,
          getProjects: this.getProjects,
          getProject: this.getProject,
          deleteProject: this.deleteProject,
          getVoicesList: this.getVoicesList,
          getVoiceLines: this.getVoiceLines,
          createVoiceLine: this.createVoiceLine,
          updateVoiceLine: this.updateVoiceLine,
          deleteVoiceLine: this.deleteVoiceLine,
          bulkUpdate: this.bulkUpdate,
          offlineMode: this.state.offlineMode,
          loading: this.state.loading,
          getVoiceLine: this.getVoiceLine,
          getVoiceSettings: this.getVoiceSettings,
          getPresets: this.getPresets,
          createPreset: this.createPreset,
          updatePreset: this.updatePreset,
          deletePreset: this.deletePreset,
          getPresetById: this.getPresetById,
          getHistory: this.getHistory,
          selectVersion: this.selectVersion,
          synthesizeReplica: this.synthesizeReplica,
          synthesizeReplicaFromhistory: this.synthesizeReplicaFromHistory,
          downloadHistoryAudio: this.downloadHistoryAudio,
          downloadReplicaAudio: this.downloadReplicaAudio,
          returnedFromOffline: this.state.returnedFromOffline,
          handleReturnedFromOffline: this.handleReturnedFromOffline,
          generateStory: this.generateStory,
          getActiveDownloads: this.getActiveDownloads,
          cancelDownload: this.cancelDownload,
          downloadProject: this.downloadProject,
          getDownloadStatus: this.getDownloadStatus,
          createPhraseDictionary: this.createPhraseDictionary,
          getPhraseDictionarys: this.getPhraseDictionarys,
          updatePhraseDictionary: this.updatePhraseDictionary,
          deletePhraseDictionary: this.deletePhraseDictionary,
        }}
      >
        {this.props.children}
      </ProjectContext.Provider>
    );
  }
}

export const withProjectContext = (Component: any) => (props: any) => (
  <ProjectContext.Consumer>{contexts => <Component {...props} {...contexts} />}</ProjectContext.Consumer>
);

export const useProjectContext = () => useContext(ProjectContext);
