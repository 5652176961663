import React, { useState, useEffect, useMemo, useCallback } from 'react';
import VoicePlaceholder, { Size } from '../VoicePlaceholder';
import { useAvatarContext } from '../../context/AvatarContext';
import axios from 'axios';
import { useAppContext } from '../../../../AppContext';

type VoiceAvatarProps = {
  voiceId: string | number;
  size: Size;
};

enum avatarSizes {
  small = '64x64',
  medium = '208x208',
  big = '700x700',
  main = '700x700',
}

export const VoiceAvatar = React.memo(function VoiceAvatar({ voiceId, size }: VoiceAvatarProps) {
  const [isDataExist, setIsDataExist] = useState('');
  const { configData } = useAppContext();
  const { avatar: localAvatar, voiceIdForAvatar } = useAvatarContext();

  const avatarPath = useMemo(
    () => `${configData.baseAvatarPath}/voice/${voiceId}/${avatarSizes[size]}/image.jpeg?${Date.now()}`,
    [configData.baseAvatarPath, size, voiceId]
  );

  const checkIsAvatarAvailable = useCallback(async () => {
    try {
      const { data } = await axios.get(avatarPath, { responseType: 'blob' });
      if (data) setIsDataExist(window.URL.createObjectURL(data));
    } catch (error) {}
  }, [avatarPath]);

  useEffect(() => {
    setIsDataExist('');
    checkIsAvatarAvailable();
  }, [checkIsAvatarAvailable, voiceId]);

  if (Number(voiceIdForAvatar) === Number(voiceId) && localAvatar) {
    return <img src={localAvatar} alt='Voice avatar' data-test-id='voice.avatar.userAvatar' />;
  }

  if (isDataExist) return <img src={isDataExist} alt='Voice avatar' data-test-id='voice.avatar.userAvatar' />;
  return <VoicePlaceholder size={size} />;
});
