import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs } from '@just-ai/just-ui';

import { t } from '../../../../localization';
import { Glass } from '../../../Library/components/CatalogPage';
import { Presets } from './Presets/Presets';
import { ReplicaSettings } from './ReplicaSettings';
import { useSettings } from '../../context/ReplicaSettingsContext';
import { useReplicas } from '../../context/ProjectDataContex';
import { ReplicaHistory } from './History/ReplicaHistory';

import './style.scss';

const TabsObj = {
  REPLICASETTINGS: 'replicaSettings',
  REPLICAHISTORY: 'replicaHistory',
};

export default function ProjectsSidebar() {
  const [activeTab, setActiveTab] = useState('replicaSettings');
  const { settingLoading, fetchDefaultSettings } = useSettings();
  const { getSelectedReplica, setReplicaStore } = useReplicas();
  const selectedReplica = getSelectedReplica();

  const tabs = useMemo(
    () => [
      {
        name: t('ProjectsSidebar:Tab:voiceLine'),
        value: TabsObj.REPLICASETTINGS,
      },
      {
        name: t('ProjectsSidebar:Tab:replicaHistory'),
        value: TabsObj.REPLICAHISTORY,
      },
    ],
    []
  );

  useEffect(() => {
    if (!selectedReplica?.voiceId) return;
    fetchDefaultSettings(selectedReplica.voiceId);
  }, [selectedReplica?.voiceId, fetchDefaultSettings]);

  const handleTabChange = useCallback(
    (value: string) => {
      setActiveTab(TabsObj[value.toUpperCase()]);
      if (TabsObj[value.toUpperCase()] === TabsObj.REPLICAHISTORY) {
        setReplicaStore({ updateHistory: true });
      }
    },
    [setReplicaStore]
  );

  return (
    <div className='projects-page__sidebar' id='voicelineSidebar'>
      <Glass isLoading={settingLoading} />
      <Tabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} />
      {activeTab === TabsObj.REPLICASETTINGS ? (
        !settingLoading && (
          <div className='project-page__setting-container'>
            <div className='projects-page__sidebar__body'>
              <ReplicaSettings />
            </div>
            <Presets />
          </div>
        )
      ) : (
        <div className='projects-page__sidebar__body replica-history'>
          <ReplicaHistory />
        </div>
      )}
    </div>
  );
}
