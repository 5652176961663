import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@just-ai/just-ui';

import { t } from '../../../../localization';

export default function DeletingLine({
  lineId,
  handleFinalDelete,
  handleCancelDelete,
}: {
  lineId: string;
  handleFinalDelete: any;
  handleCancelDelete: any;
}) {
  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(timeLeft => timeLeft - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      handleFinalDelete(lineId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineId, timeLeft]);

  const cancelDelete = useCallback(() => {
    handleCancelDelete(lineId);
  }, [handleCancelDelete, lineId]);

  return (
    <div data-test-id={`voiceLine.deleteBlock-${lineId}`} className='project__voice-line--deleting'>
      <p>
        <span className='countdown'>
          <span className='countdown-number tp-4'>{timeLeft}</span>
          <svg>
            <circle r='9' cx='10' cy='10'></circle>
          </svg>
        </span>
        {t('voiceLineDeleted')}
      </p>
      <Button data-test-id={`voiceLine.cancelDeleteButton-${lineId}`} onClick={cancelDelete}>
        {t('cancel')}
      </Button>
    </div>
  );
}
