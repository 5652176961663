import { Icon } from '@just-ai/just-ui';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAppContext } from '../../../../AppContext';
import { t } from '../../../../localization';
import useDefaultAlert from '../../../../utils/useAlert';
import { SCREEN_WIDTH_MD } from '../../../Header/constants';
import { useLibraryContext } from '../../context/LibraryContext';
import { useSticky } from '../../hooks/useSticky';
import { PublishedPage } from '../PublishedPage';
import { PublishedVoiceViewWithUsedVersion } from '../../../../api/facade/client';
import './index.scss';

export default function PublicVoiceVersionsPage(props: any) {
  const { id } = useParams<{ id: string; restricted: string }>();
  const { id: accountId } = useAppContext();
  const { getPublicVoice } = useLibraryContext();
  const [voice, setVoice] = useState<PublishedVoiceViewWithUsedVersion>();
  const observerRef = useRef<HTMLDivElement>(null);
  const isSticky = useSticky({ refElement: observerRef, treshold: 1 });
  const { defaultErrorAlert } = useDefaultAlert();
  const { push } = useHistory();

  useEffect(() => {
    const fetchVoice = async () => {
      try {
        const voiceData = await getPublicVoice(+id);
        setVoice(voiceData);
        if (!voiceData || voiceData.accessRestricted) {
          return push('/catalog/' + id);
        }
      } catch (error) {
        defaultErrorAlert(error);
      }
    };
    fetchVoice();
  }, [defaultErrorAlert, getPublicVoice, id, push]);

  const mdDown = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_MD}px)` });
  return (
    <div className='public-voice__wrapper public-voice__versions'>
      <div className={classNames('public-voice__back-link', { sticky: isSticky })} ref={observerRef}>
        <Link to={`/catalog/${id}`}>
          <Icon name='falArrowLeft' />
          <p>{t('backToVoicePage')}</p>
        </Link>
        {mdDown && <h4>{t('versionHistory')}</h4>}
      </div>
      <PublishedPage public loginStatus={Boolean(accountId)} voiceName={voice?.name} />
    </div>
  );
}
