import React from 'react';

export default function Skeleton() {
  return (
    <div className='animated-pulse'>
      <div className='skeleton-text-large' />
      <div className='skeleton-text-large' />
      <div className='skeleton-text-large' />
      <div className='skeleton-text-large' />
      <div className='skeleton-text-large' />
    </div>
  );
}

export function AccordeonSkeleton() {
  return (
    <div className='animated-pulse'>
      <div className='skeleton-text-large' />
      <div className='skeleton-text-short' />
      <div className='skeleton-text-large' />
    </div>
  );
}
