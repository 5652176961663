import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { ControllerRenderProps } from 'react-hook-form';

import { Icon } from '@just-ai/just-ui';
import { t } from '../../../../localization';
import { Inputs, MAX_DICTIONARY_LENGTH } from './ChangeCreateForm';

import styles from './Dictionary.module.scss';

const MIN_LENGTH_TO_SHOW_WARNING = 50;

type WordFormProps = {
  id: string;
  field: ControllerRenderProps<Inputs>;
  synthReq: (text: string, id: string) => Promise<void>;
  editMode?: boolean;
  playingId?: string;
};

export const WordForm = React.memo(({ field, synthReq, editMode, playingId, id }: WordFormProps) => {
  const [isLoading, setLoading] = useState(false);

  const hadleSynthClick = useCallback(async () => {
    !playingId && setLoading(true);
    await synthReq(field.value, id);
    setLoading(false);
  }, [field.value, id, playingId, synthReq]);

  const showWarning = MAX_DICTIONARY_LENGTH - field.value.length <= MIN_LENGTH_TO_SHOW_WARNING;

  return (
    <div className={styles.dictionary__word}>
      <p className='tp-4'>
        {t(field.name === 'word' ? 'Dictionary:Form:Word:Title' : 'Dictionary:Form:Pronounce:Title')}
      </p>
      <div className={styles.dictionary__word__form}>
        <div className={styles.dictionary__word__input}>
          <TextareaAutosize
            placeholder={t(
              field.name === 'word' ? 'Dictionary:Form:Word:Placeholder' : 'Dictionary:Form:Pronounce:Placeholder'
            )}
            defaultValue={field.value}
            name={field.name}
            onChange={field.onChange}
            value={field.value}
          />
          {showWarning && (
            <small className={styles.dictionary__add__error}>{`${field.value.length}/${MAX_DICTIONARY_LENGTH}`}</small>
          )}
        </div>
        <Icon
          className={cn(styles.dictionary__synth, {
            spinner: isLoading,
            [styles.disabled]: field.value.length === 0 || field.value.length > MAX_DICTIONARY_LENGTH,
          })}
          name={isLoading ? 'faCircleNotch' : playingId === id ? 'faPause' : 'faPlay'}
          color='primary'
          onClick={hadleSynthClick}
        />
        {((editMode && field.name === 'word') || !editMode) && (
          <Icon name='farArrowRight' className={styles.dictionary__arrow} size='sm' />
        )}
      </div>
    </div>
  );
});
