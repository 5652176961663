import React, { useCallback } from 'react';
import { ModalBody } from '@just-ai/just-ui/';
import { Tooltip } from '@just-ai/just-ui/dist/Tooltip';
import { Dropdown, DropdownToggle, DropdownMenu } from '@just-ai/just-ui/dist/Dropdown';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { Button } from '@just-ai/just-ui/dist/Button';

import { useToggle } from '../../utils/hooks';
import { VoiceAvatar } from '../../modules/Library/components/VoiceAvatar';
import SpeakerSelectModal from '../../modules/Library/components/SpeakerSelectModal';
import { SpeakerBlockProps } from './VoiceLineBlock';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_PHONE, SCREEN_WIDTH_TABLET } from '../../modules/Header/constants';
import { t } from '../../localization';
import { CustomModal } from '../../modules/Library/components/CustomModal';

type SpeakerSelectListProps = Partial<SpeakerBlockProps> & {
  dialogInputOnBlur: (event: React.FocusEvent) => void;
  avatarClickCallback: (voiceId?: number | undefined, modelId?: number | undefined) => void;
};

export default function SpeakerSelectList(props: SpeakerSelectListProps) {
  const {
    lineId,
    voiceId,
    dialogInputOnBlur,
    avatarClickCallback,
    handleSpeakerMenuScroll,
    speakersList,
    voicesLoading,
  } = props;
  const [isSpeakersOpen, , , toggleSpeakers] = useToggle();
  const isTablet = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });

  const avatarToggleBlur = useCallback(
    (event: React.FocusEvent) => {
      if (lineId?.includes('placeholder')) {
        if (!event.currentTarget.contains(event.relatedTarget as Node)) {
          toggleSpeakers();
          return dialogInputOnBlur(event);
        }
      }
    },
    [dialogInputOnBlur, lineId, toggleSpeakers]
  );

  const handleSelectInModal = useCallback(
    (id: number, modelId?: number) => {
      avatarClickCallback(id, modelId);
      toggleSpeakers();
    },
    [avatarClickCallback, toggleSpeakers]
  );
  if (isPhone)
    return (
      <>
        <Button
          id={!voiceId ? `avatarPlaceholder-${lineId}` : 'avatarPlaceholder'}
          onClick={toggleSpeakers}
          className='add-speaker-avatar'
          size='sm'
          color='none'
        >
          {!voiceId ? (
            <>
              <Icon name='farPlus' size='sm' />
              {!isTablet && (
                <Tooltip placement='bottom' target={`avatarPlaceholder-${lineId}`}>
                  {t('selectVoice')}
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <VoiceAvatar voiceId={voiceId} size='small' />
              <div className='avatar-edit-overlay'>
                <Icon name='faPen' color='none' size='sm' />
              </div>
            </>
          )}
        </Button>
        <CustomModal
          isOpen={isSpeakersOpen}
          toggle={toggleSpeakers}
          title={t('selectSpeaker')}
          customClassName='dialog-synth__speaker-modal'
          fullscreen={true}
        >
          <ModalBody onScroll={handleSpeakerMenuScroll}>
            <SpeakerSelectModal
              handleSubmit={handleSelectInModal}
              speakersList={speakersList || []}
              voicesLoading={voicesLoading}
            />
          </ModalBody>
        </CustomModal>
      </>
    );
  return (
    <Dropdown
      onBlur={avatarToggleBlur}
      isOpen={isSpeakersOpen}
      toggle={toggleSpeakers}
      data-test-id={`voiceLine.speakersList-${lineId}`}
      direction='right'
    >
      <DropdownToggle
        className='add-speaker-avatar'
        size='sm'
        color='none'
        id={!voiceId || voiceId === -1 ? `avatarPlaceholder-${lineId}` : 'avatarPlaceholder'}
      >
        {!voiceId || voiceId === -1 ? (
          <>
            <Icon name='farPlus' size='sm' />
            {!isTablet && (
              <Tooltip placement='bottom' target={`avatarPlaceholder-${lineId}`}>
                {t('selectVoice')}
              </Tooltip>
            )}
          </>
        ) : (
          <>
            <VoiceAvatar voiceId={voiceId} size='small' />
            <div className='avatar-edit-overlay'>
              <Icon name='faPen' color='none' size='sm' />
            </div>
          </>
        )}
      </DropdownToggle>
      <DropdownMenu
        data-test-id={`voiceLine.speakersMenu-${lineId}`}
        className='project-voiceline__speakers-dropdown'
        onScroll={handleSpeakerMenuScroll}
      >
        {isSpeakersOpen && (
          <SpeakerSelectModal
            handleSubmit={avatarClickCallback}
            speakersList={speakersList || []}
            usageType='dropdown'
            voicesLoading={voicesLoading}
          />
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
