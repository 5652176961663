import React from 'react';
import classNames from 'classnames';
import { AutoSizer, InfiniteLoader, List, Index, IndexRange } from 'react-virtualized';
import { withRouter, RouteComponentProps } from 'react-router';

import { LibraryContext } from '../../../context/LibraryContext';
import { SampleView } from '../../../../../api/facade/client';
import { CatalogRowMemo } from './CatalogRow';
import { AppContextType, withAppContext } from '../../../../../AppContext';
import { generateSampleRoute } from '../../../../../utils';
import { CatalogLoader } from '../';
import { MAX_SAMPLES_PER_PAGE } from '../../../constants';
import { t } from '../../../../../localization';
import './Catalog.scss';

type CatalogProps = RouteComponentProps<{ voiceId: string; catalogId: string; sampleId: string }> &
  AppContextType & {
    isLoading: boolean;
    samples: SampleView[];
    currentTab: 'voiced' | 'notVoiced';
    errorsCount: number;
    audio: boolean;
    rowsCount: number;
    autoScrollIndex: number;
    showErrors: boolean;
    setShowError: (value: boolean) => void;
    fetchSamples: () => void;
    setSampleIndex: (sample: SampleView) => void;
    setSamples: (value: SampleView[]) => void;
  };

class State {
  maxPage: number = 0;
  currentPage: number = 0;
  rowLoading: boolean = false;
}

class Catalog extends React.Component<CatalogProps, State> {
  static contextType = LibraryContext;
  context!: React.ContextType<typeof LibraryContext>;

  state = new State();

  async componentDidMount() {
    await this.props.fetchSamples();
    if (this.props.samples.length > 0) {
      this.props.history.push(
        generateSampleRoute(
          +this.props.match.params.voiceId,
          +this.props.match.params.catalogId,
          this.props.samples[0].id
        )
      );
    }
  }

  async componentDidUpdate(prevProps: CatalogProps, prevState: State) {
    if (prevProps.showErrors !== this.props.showErrors) {
      await this.props.fetchSamples();
      if (this.props.samples.length > 0) {
        this.props.history.push(
          generateSampleRoute(
            +this.props.match.params.voiceId,
            +this.props.match.params.catalogId,
            this.props.samples[0].id
          )
        );
      }
    }
    if (prevProps.currentTab !== this.props.currentTab || this.props.errorsCount === 0) {
      this.props.setShowError(false);
    }
  }

  fetchNewSamples = async (page: number) => {
    this.setState({ rowLoading: true });

    try {
      const { samplesWithStatuses } = await this.context.getSamples(
        Number(this.props.match.params.voiceId),
        page,
        MAX_SAMPLES_PER_PAGE,
        Number(this.props.match.params.catalogId),
        this.props.currentTab === 'voiced' ? true : false,
        this.props.showErrors
      );

      this.props.setSamples([...this.props.samples, ...samplesWithStatuses]);
    } catch (error) {
      this.props.defaultAlert(error as any);
    } finally {
      this.setState({ rowLoading: false });
    }
  };

  isRowLoaded = ({ index }: Index) => {
    return !!this.props.samples[index];
  };

  loadMoreRows = ({ startIndex, stopIndex }: IndexRange) => {
    const currentPage = Math.floor(startIndex / MAX_SAMPLES_PER_PAGE);
    const maxPage = Math.floor(this.props.rowsCount / MAX_SAMPLES_PER_PAGE);
    if (currentPage <= maxPage && !this.props.samples[stopIndex]) {
      this.fetchNewSamples(currentPage);
    }
    return new Promise<void>(resolve => resolve());
  };

  render() {
    if (this.props.isLoading) return <CatalogLoader />;
    return (
      <div className={classNames('catalog-page__catalog', { scroll: this.props.samples.length > 14 })}>
        {this.props.audio && this.props.errorsCount && (
          <div className='catalog-page__catalog-errors'>
            <div
              className={this.props.showErrors ? 'active' : ''}
              onClick={() => this.props.setShowError(this.props.showErrors ? false : true)}
            >
              {t('SamplePage:Samples:SamplesWithError', this.props.errorsCount)} <div />
            </div>
          </div>
        )}
        {/*Place virtual list inside block to prevent overflow, if virtual list flex child it cause overflow */}
        <div style={{ height: '100%', width: '100%' }}>
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={this.props.rowsCount}
            threshold={2}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width}
                    height={height}
                    rowHeight={44}
                    rowCount={this.props.samples.length}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    scrollToAlignment='start'
                    rowRenderer={({ key, index, style }) => (
                      <CatalogRowMemo
                        key={key}
                        onClick={() => this.props.setSampleIndex(this.props.samples[index])}
                        sample={this.props.samples[index]}
                        style={style}
                      />
                    )}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      </div>
    );
  }
}

export default withRouter(withAppContext(Catalog));
