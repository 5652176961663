import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import { useAmplitude } from 'react-amplitude-hooks';
import { Icon } from '@just-ai/just-ui/dist/Icon';

import { SCREEN_WIDTH_TABLET } from '../../Header/constants';
import { t } from '../../../localization';
import { useAppContext } from '../../../AppContext';

export const SidebarPlaceholder = () => {
  const newLocal = SCREEN_WIDTH_TABLET - 1;
  const tabletDown = useMediaQuery({ query: `(max-width: ${newLocal}px)` });
  const { projectId } = useParams<{ projectId: string }>();
  const { logEvent } = useAmplitude();
  const { isUSDInstance } = useAppContext();

  const handleDocsNavigate = () => {
    logEvent('project_synthesiz_wiki_opened', {
      project_id: projectId,
    });
  };
  return (
    <aside className='projects-page__docs-link'>
      <a
        href={`${isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}${t('Projects:synthDocsLink')}`}
        onClick={handleDocsNavigate}
        target='_blank'
        rel='noreferrer'
      >
        <div className='links-container'>
          {tabletDown ? (
            <p className='tp-5 grey-text-3'>
              {t('textMarkupShort')}
              <Icon name='farArrowUp' />
            </p>
          ) : (
            <>
              <p className='st-2'>
                {t('textMarkup')}
                <Icon name='farArrowUp' size='sm' />
              </p>
              <ul>
                <li>
                  <Icon name='falCheck' size='sm' /> <p className='tp-4 grey-text-3'>{t('pauses')}</p>
                </li>
                <li>
                  <Icon name='falCheck' size='sm' /> <p className='tp-4 grey-text-3'>{t('wordStress')}</p>
                </li>
                <li>
                  <Icon name='falCheck' size='sm' /> <p className='tp-4 grey-text-3'>{t('phraseStress')}</p>
                </li>
                <li>
                  <Icon name='falCheck' size='sm' /> <p className='tp-4 grey-text-3'>{t('loudnessPace')}</p>
                </li>
                <li>
                  <Icon name='falCheck' size='sm' /> <p className='tp-4 grey-text-3'>{t('voiceOverRules')}</p>
                </li>
              </ul>
            </>
          )}
        </div>
      </a>
    </aside>
  );
};
