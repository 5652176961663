import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { Button } from '@just-ai/just-ui';
import { t } from '../../../../localization';
import { calendarStrings } from '../../constants';
import { ProgressBarWithStatusMemo } from './ProgressBarWithStatus';
import { VoiceModel } from '../../model/Voice';
import { CustomModal, CustomModalPublishVoice } from '../CustomModal';
import SynthArea from '../SynthArea';
import { useAmplitude } from 'react-amplitude-hooks';
import { useRouteMatch } from 'react-router-dom';
import { useAppContext } from '../../../../AppContext';
import partyPopper from '../../../../img/partyPopper.png';

type VersionCardProps = {
  model: VoiceModel;
  actionButtonClick: (value: VoiceModel, toggleModal: () => void) => void;
};

export const VersionCardMemo = React.memo(function VersionCard({ model, actionButtonClick }: VersionCardProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();
  const { id, synthesizeDialog } = useAppContext();

  const toggle = useCallback(() => setIsOpen(isOpen => !isOpen), []);

  const handleActionButtonClick = useCallback(() => actionButtonClick(model, toggle), [
    actionButtonClick,
    model,
    toggle,
  ]);

  const synthVoice = async (text: string): Promise<string> => {
    try {
      const phraseUrl = await synthesizeDialog([{ text: text, modelId: model.id, voiceId: model.voiceId }]);
      logEvent('synthesize', {
        result: 'success',
        page_type: match.path,
        page_url: match.url,
        type: 'trained_model',
        user_id: id,
      });
      return phraseUrl || '';
    } catch (error) {
      logEvent('synthesize', {
        result: 'failed',
        page_type: match.path,
        page_url: match.url,
        type: 'trained_model',
        user_id: id,
      });
      throw error;
    }
  };

  return (
    <div className='training__version-card' data-test-id='trainingPage.versionCard'>
      <div className='training__version-header'>
        <h3 className='st-1'>{t('versionNumber', model.version)}</h3>
        <p>{moment(model.createAt).calendar(calendarStrings)}</p>
        <ProgressBarWithStatusMemo status={model.status} />
        {model.status === 'TRAINED' && (
          <Button color='primary' size='lg' onClick={toggle} data-test-id='trainingPage.releaseModel'>
            {t('publish')}
          </Button>
        )}
      </div>
      {model.status === 'TRAINED' && (
        <div className='training__synth-area'>
          <div className='training__success-note'>
            <img src={partyPopper} alt='Tada!' />
            <p>{t('testItDesc')}</p>
          </div>
          <div className='synth-area-wrapper'>
            <SynthArea handler={synthVoice} />
          </div>
        </div>
      )}
      <CustomModal isOpen={isOpen} toggle={toggle} title={`${t('publishVoice')}?`} position='center' modalCard>
        <CustomModalPublishVoice handleModalAction={handleActionButtonClick} cancelButtonClick={toggle} />
      </CustomModal>
    </div>
  );
});
