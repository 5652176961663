import React from 'react';
import { Icon } from '@just-ai/just-ui/dist/Icon';

import { t } from '../../../../localization';

export default function VoiceLicenseBlock({
  isPhone,
  openPhoneModal,
}: {
  isPhone: boolean;
  openPhoneModal: (e: React.SyntheticEvent) => void;
}) {
  return (
    <div className='voice-card__license-block'>
      <p>
        <Icon name='faStar' color='warning' size='sm' />
        {t('licensed')}
      </p>
      {isPhone ? (
        <Icon
          name='falQuestionCustom'
          className='question-icon'
          wrapperClassName='question-icon-wrapper'
          onClick={openPhoneModal}
        />
      ) : (
        <p className='grey-text-2 tp-5'>{t('licensedInfo')}</p>
      )}
    </div>
  );
}
