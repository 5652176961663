export const landingPageLocalizaion = {
  'LandingPage:MainTitle': {
    eng: 'We synthesize live voices for your projects',
    ru: 'Синтезируем живые голоса для ваших проектов',
  },
  'LandingPage:Button:Try': {
    eng: 'Try',
    ru: 'Попробовать',
  },
  'LandingPage:SliderLastCard:Title': {
    eng: 'A wide variety of voices in the catalog',
    ru: 'Большой выбор голосов в каталоге',
  },
  'LandingPage:SliderLastCard:SubTitle': {
    eng: 'Female, male, child, and even character voices',
    ru: 'Женские, мужские, детские и даже голоса персонажей',
  },
  'LandingPage:Button:SelectVoice': {
    eng: 'Select voice',
    ru: 'Выберите голос',
  },
  'LandingPage:Purpose:VoiceContent': {
    eng: 'Voice content',
    ru: 'Озвучивайте контент',
  },
  'LandingPage:Purpose:VoiceContentDescription': {
    eng: 'Choose voices in the catalog and voice your content',
    ru: 'Выбирайте голоса в каталоге и озвучивайте ваш контент',
  },
  'LandingPage:Purpose:ForCalls': {
    eng: 'Use for calls',
    ru: 'Используйте для обзвонов',
  },
  'LandingPage:Purpose:ForCallsDescription': {
    eng: 'Boost marketing performance and user confidence with different voices',
    ru: 'Повышайте эффективность маркетинга и доверие пользователей с помощью разных голосов',
  },
  'LandingPage:Purpose:BrandVoice': {
    eng: 'Create a brand voice',
    ru: 'Создайте голос бренда',
  },
  'LandingPage:Purpose:BrandVoiceDescription': {
    eng: 'Order the development of a corporate voice for your company',
    ru: 'Закажите разработку фирменного голоса для вашей компании',
  },
  'LandingPage:Dialog:Header': {
    eng: 'Create your dialog',
    ru: 'Создайте свой диалог',
  },
  'LandingPage:Dialog:PlayerListen': {
    eng: 'Listen',
    ru: 'Прослушать',
  },
  'LandingPage:Dialog:SpeakerModalHeader': {
    eng: 'Select voice',
    ru: 'Выберите голос',
  },
  'LandingPage:Project:Title': {
    eng: 'More options for voicing text in $[1] projects',
    ru: 'Больше возможностей озвучивания текстов — в проектах $[1]',
  },
  'LandingPage:Project:SubTitle': {
    eng: 'Use any voice from the catalog and download audio',
    ru: 'Используйте любые голоса из каталога и скачивайте аудио',
  },
  'LandingPage:Advantages:SubTitle': {
    eng: 'Why you will like it:',
    ru: 'Почему вам понравится:',
  },
  'LandingPage:Advantages:VoiceSelection': {
    eng: 'A wide variety of voices',
    ru: 'Большой выбор голосов',
  },
  'LandingPage:Advantages:VoiceSelectionDescription': {
    eng: 'Female, male, child, and even character voices',
    ru: 'Женские, мужские, детские и даже голоса персонажей',
  },
  'LandingPage:Advantages:QuickStart': {
    eng: 'Get started quickly',
    ru: 'Быстрый запуск',
  },
  'LandingPage:Advantages:QuickStartDescription': {
    eng: 'No need to look for live speakers or agencies, agree on recordings, and wait for the result',
    ru: 'Не нужно искать диктора или агентство, согласовывать записи и ждать результат',
  },
  'LandingPage:Advantages:DifferentTasks': {
    eng: 'For different tasks',
    ru: 'Для разных задач',
  },
  'LandingPage:Advantages:DifferentTasksDescription': {
    eng: 'Use voices in telephony, video, games, and other content',
    ru: 'Используйте голоса в телефонии, видео, играх и другом контенте',
  },
  'LandingPage:Advantages:Cheaper': {
    eng: 'Cheaper than studio recording',
    ru: 'Дешевле, чем запись в студии',
  },
  'LandingPage:Advantages:CheaperDescription': {
    eng: 'You only pay for using voices without recording expenses',
    ru: 'Вы платите только за использование голоса без затрат на звукозапись',
  },
  'LandingPage:Buton:GoToCatalog': {
    eng: 'Go to catalog',
    ru: 'Перейти в каталог',
  },
};
