import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Markup,
  MarkupSettings,
  MarkupUpdate,
  PitchSettings,
  RateSettings,
  StyleName,
  StyleSettings,
  VolumeSettings,
} from '../../../api/dubber/client';
import createFastContext from '../../../utils/createFastContext';
import { addMarkupToText } from '../utils';
import { useReplicas } from './ProjectDataContex';
import { useProjectContext } from './ProjectsContext';
import { debounce } from 'lodash';
import { usePlayer } from './PlayerContext';
type Settings = 'rate' | 'volume' | 'pitch' | 'style';

type SettingsStore = {
  rate: RateSettings;
  volume: VolumeSettings;
  pitch: PitchSettings;
  style: StyleSettings;
  loading: boolean;
};

const voiceInitialStore: SettingsStore = {
  rate: {} as RateSettings,
  volume: {} as VolumeSettings,
  pitch: {} as PitchSettings,
  style: {} as StyleSettings,
  loading: true,
};

const { Provider, useStore } = createFastContext(voiceInitialStore);

export const useSettingsStore = () => useStore(store => store);

export const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  return <Provider>{children}</Provider>;
};

export const useSettings = () => {
  const [{ rate, pitch, volume, style, loading }, setStore] = useStore(store => store);
  const { getVoiceSettings, updateVoiceLine } = useProjectContext();
  const { getSelectedReplica, replicas, getReplicaById, selectedReplicaId, setReplicaStore } = useReplicas();
  const [settingChange, setSettingChange] = useState(false);
  const { deleteSynthBufferRecord } = usePlayer();

  const initialMarkup = useRef<Markup & { replicaId: string }>();
  const selectedReplica = getSelectedReplica();

  useEffect(() => {
    if (selectedReplicaId === initialMarkup.current?.replicaId) return;
    initialMarkup.current = { replicaId: selectedReplicaId, ...selectedReplica?.markup };
  }, [getSelectedReplica, selectedReplica?.markup, selectedReplicaId]);

  const fetchDefaultSettings = useCallback(
    async (voiceId: number) => {
      setStore({ loading: true });
      try {
        const defaultMarkupData = await getVoiceSettings(voiceId);
        setStore({
          rate: defaultMarkupData.rate,
          volume: defaultMarkupData.volume,
          pitch: defaultMarkupData.pitch,
          style: defaultMarkupData.style,
          loading: false,
        });
      } catch (error) {
        console.error('Failed to update voice context settings', error);
        setStore({ loading: false });
      }
    },
    [setStore, getVoiceSettings]
  );

  const isSettingChanged = useCallback(
    (setting?: Settings, compareWithDefault?: boolean) => {
      if (!selectedReplica?.markup) return;
      if (setting && initialMarkup.current?.[setting] && !compareWithDefault) {
        return setting === 'style'
          ? selectedReplica.markup[setting]?.style !== String(initialMarkup.current[setting]?.style)
          : selectedReplica.markup[setting]?.value !== Number(initialMarkup.current[setting]?.value);
      }
      if (setting) {
        if (!selectedReplica.markup[setting]) return false;
        const storeData = { rate, volume, pitch, style };
        return setting === 'style'
          ? selectedReplica.markup[setting]?.style !== String(storeData[setting]?.defaultValue)
          : selectedReplica.markup[setting]?.value !== Number(storeData[setting]?.defaultValue);
      }
    },
    [pitch, rate, selectedReplica, style, volume]
  );

  const generateMarkupUpdate = useCallback(
    (reset?: boolean, compareWithDefault?: boolean) => {
      const markupUpdate: MarkupUpdate = {};
      if (isSettingChanged('rate', compareWithDefault)) {
        markupUpdate.rate = {
          changed: true,
          property: {
            value: reset
              ? Number(rate.defaultValue)
              : selectedReplica?.markup?.rate?.value || Number(rate.defaultValue),
          },
        };
      }

      if (isSettingChanged('volume', compareWithDefault))
        markupUpdate.volume = {
          changed: true,
          property: {
            value: reset
              ? Number(volume.defaultValue)
              : selectedReplica?.markup?.volume?.value || Number(volume.defaultValue),
          },
        };

      if (isSettingChanged('pitch', compareWithDefault))
        markupUpdate.pitch = {
          changed: true,
          property: {
            value: reset
              ? Number(pitch.defaultValue)
              : selectedReplica?.markup?.pitch?.value || Number(pitch.defaultValue),
          },
        };
      if (isSettingChanged('style', compareWithDefault))
        markupUpdate.style = {
          changed: true,
          property: {
            style: reset
              ? (String(style.defaultValue) as StyleName)
              : selectedReplica?.markup?.style?.style || (String(style.defaultValue) as StyleName),
          },
        };
      return markupUpdate;
    },
    [rate, volume, selectedReplica, pitch, style, isSettingChanged]
  );

  const setSetting = useCallback(
    ({ setting, value }: { setting: Settings; value: number }) => {
      const newReplicas = [...replicas];
      const { replica, replicaIndex } = getReplicaById(selectedReplicaId);
      if (!replica) return;
      const replicaMarkup = {
        ...replica.markup,
        [setting]: {
          [setting === 'style' ? 'style' : 'value']: setting === 'style' ? style.styles[value] : value,
        },
      };
      replica.markup = replicaMarkup;
      replica.presetId = undefined;
      newReplicas[replicaIndex] = replica;
      setReplicaStore({ replicas: newReplicas, isDirty: true });
      deleteSynthBufferRecord(selectedReplicaId);
      !settingChange && setSettingChange(true);
    },
    [deleteSynthBufferRecord, getReplicaById, replicas, selectedReplicaId, setReplicaStore, settingChange, style.styles]
  );

  const addMarkup = useCallback(
    (text: string, defaultSettings: MarkupSettings, markup?: Markup) => {
      const rateValue = markup?.rate?.value === defaultSettings.rate?.defaultValue ? undefined : markup?.rate?.value;
      const volumeValue =
        markup?.volume?.value === defaultSettings.volume?.defaultValue ? undefined : markup?.volume?.value;
      const pitchValue =
        markup?.pitch?.value === defaultSettings.pitch?.defaultValue ? undefined : markup?.pitch?.value;
      const styleValue =
        markup?.style?.style === defaultSettings.style?.defaultValue ? undefined : markup?.style?.style;
      return addMarkupToText({
        rate: rateValue,
        volume: volumeValue,
        pitch: pitchValue,
        text,
        styleSettings: style,
        style: defaultSettings.style?.styles?.findIndex(style => style === styleValue),
      });
    },
    [style]
  );

  const getSsmlTextByVoiceId = useCallback(
    async (text: string, voiceId: number, replicaMarkup?: Markup) => {
      const defaultSettingsById = await getVoiceSettings(voiceId);
      return addMarkup(text, defaultSettingsById, replicaMarkup);
    },
    [addMarkup, getVoiceSettings]
  );

  const getSsmlText = useCallback(
    (text: string) => {
      if (!selectedReplica) return;
      return addMarkup(text, { rate, volume, pitch, style }, selectedReplica.markup);
    },
    [addMarkup, pitch, rate, selectedReplica, style, volume]
  );

  const updateReplicaWithSetting = useCallback(async () => {
    if (!selectedReplica) return;

    const markupUpdate = generateMarkupUpdate();
    const { markup, ...restOfSelectedReplica } = selectedReplica;
    await updateVoiceLine(selectedReplica.id, {
      ...restOfSelectedReplica,
      markupUpdate,
      ssmlText: getSsmlText(selectedReplica.text) || selectedReplica.text,
    });
    setSettingChange(false);
    setReplicaStore({ isDirty: false });
  }, [generateMarkupUpdate, getSsmlText, selectedReplica, setReplicaStore, updateVoiceLine]);

  const debounceSetSetting = useMemo(() => {
    return debounce(() => {
      updateReplicaWithSetting();
    }, 500);
  }, [updateReplicaWithSetting]);

  useEffect(() => {
    if (settingChange) {
      debounceSetSetting();
    }
  }, [debounceSetSetting, settingChange]);

  return {
    rate,
    volume,
    pitch,
    style,
    setSettingsStore: setStore,
    fetchDefaultSettings,
    generateMarkupUpdate,
    getSsmlText,
    settingLoading: loading,
    setSetting: setSetting,
    getSsmlTextByVoiceId,
  };
};
