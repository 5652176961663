import React from 'react';
import { Progress } from 'reactstrap';
import { t } from '../../localization';
import './style.scss';

type VersionProgressProps = {
  currentValue: number;
  maxValue: number;
  processing: boolean;
};

const PROGRESS_LIMIT = 87;
const PROGRESS_LIMIT_WITH_PROCESSING = 91.5;

export default function VersionProgress(props: VersionProgressProps) {
  const { currentValue, maxValue, processing } = props;

  let processedCurrentValue = (currentValue / maxValue) * PROGRESS_LIMIT;

  if (!!processedCurrentValue && processedCurrentValue > PROGRESS_LIMIT)
    processedCurrentValue = PROGRESS_LIMIT_WITH_PROCESSING;

  const headerText = t('processedNeutral') + ' ' + (currentValue / 3600).toFixed(1) + t('hoursShort');

  return (
    <div className='progress-bar__container'>
      <div className='progress-bar__info'>
        <p className='tp-3'>{headerText}</p>
        <p className='tp-3'>{(maxValue / 3600).toFixed(1) + t('hoursShort')}</p>
      </div>
      <div className='progress-bar__limiter' />
      <Progress multi>
        <Progress color='primary' value={String(processedCurrentValue)} bar />
        {processing && <Progress className='progress-bar--incoming' value='4.8' bar />}
      </Progress>
    </div>
  );
}
