import React, { useState } from 'react';
import useMember from '../../../hooks/useMember';
import { CustomModal, CustomModalAddManager } from '../../CustomModal';
import useDefaultAlert from '../../../../../utils/useAlert';
import { convertNameToPlaceholder, getRoleNameFromId } from '../../../../../utils';
import { MembersControlsMemo } from './MembersControls';
import { t } from '../../../../../localization';
import { Icon } from '@just-ai/just-ui';
import { VoiceMemberToAdd, VoiceMemberView, VoiceView } from '../../../../../api/facade/client';
import { useMediaQuery } from 'react-responsive';
import { SCREEN_WIDTH_LG, SCREEN_WIDTH_TABLET, SCREEN_WIDTH_PHONE } from '../../../../Header/constants/index';
import { useAppContext } from '../../../../../AppContext';
import { useAmplitude } from 'react-amplitude-hooks';
import { useRouteMatch } from 'react-router';

import './styles.scss';

export const MembersMemo = React.memo(({ voice }: { voice: VoiceView }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tabletModal, setTabletModal] = useState({} as VoiceMemberView);
  const { defaultErrorAlert } = useDefaultAlert();
  const { id } = useAppContext();
  const { members, addMember, updateMember, removeMember } = useMember();
  const { logEvent } = useAmplitude();
  const match = useRouteMatch();
  const isLarge = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_LG}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleModalCancel = () => {
    setIsOpen(false);
  };

  const handleTabletModal = (member: VoiceMemberView) => {
    setTabletModal(member);
  };

  const handleMemberAdd = async (memberData?: VoiceMemberToAdd) => {
    if (!memberData || !memberData.roleId) return;
    try {
      await addMember(memberData);
      logEvent('Voice member added', {
        url: match.url,
        result: 'success',
        member_role: voice.currentVoiceMemberRole.name,
        added_member_role: getRoleNameFromId(memberData.roleId),
        user_id: id,
      });
      setIsOpen(false);
    } catch (error) {
      logEvent('Voice member added', {
        url: match.url,
        result: 'failed',
        member_role: voice.currentVoiceMemberRole.name,
        added_member_role: getRoleNameFromId(memberData.roleId),
        user_id: id,
      });
      defaultErrorAlert(error);
    }
  };
  return (
    <>
      <aside className='voice-page__managers' data-test-id='controlPage.members'>
        <div className='managers__title'>
          {isLarge ? (
            <>
              <h4>{t('managers')}</h4>
              <h4 data-test-id='controlPage.membersLength'>{members?.length}</h4>
            </>
          ) : (
            <>
              <h3 className='st-1'>{t('managers')}</h3>
              <span className='st-1' data-test-id='controlPage.membersLength'>
                {members?.length}
              </span>
            </>
          )}
        </div>
        <div className='managers__item-wrapper'>
          {members?.map(member => (
            <div
              className='managers__item'
              key={member.accountId}
              onClick={isTablet && member.role.id !== 5 ? () => handleTabletModal(member) : undefined}
              data-test-id='controlPage.memberItem'
            >
              <div className='manager__photo'>
                <p className='tp-4'>{convertNameToPlaceholder(member.accountName)}</p>
              </div>
              <div className='managers__caption'>
                <p>{`${member.accountName} ${member.accountId === id ? `(${t('you')})` : ''}`}</p>
                <p data-test-id='controlPage.memberRole'>{t(`managerRole: ${member.role.name}`)}</p>
              </div>
              {member.role.id !== 5 && (
                <MembersControlsMemo
                  member={member}
                  updateMember={updateMember}
                  removeMember={removeMember}
                  tabletModal={tabletModal}
                  setTabletModal={setTabletModal}
                  voice={voice}
                />
              )}
            </div>
          ))}
        </div>
        <p className='managers__add' onClick={handleModalOpen} data-test-id='controlPage.addManager'>
          <Icon color='secondary' name='faPlus' size='sm' />
          {t('manager: Add')}
        </p>
      </aside>
      <CustomModal
        isOpen={!!isOpen}
        title={
          isTablet ? (
            <div className='add-manager__header'>
              <h4 className={isPhone ? 'st-1' : ''}>{t(`VoicePage:Control:AddManager`)}</h4>
            </div>
          ) : (
            t(`VoicePage:Control:AddManager`)
          )
        }
        fullscreen={isTablet}
        customClassName='add-manager'
        toggle={handleModalCancel}
      >
        <CustomModalAddManager handleModalAction={handleMemberAdd} cancelButtonClick={handleModalCancel} />
      </CustomModal>
    </>
  );
});
