import React from 'react';
import { IconButton, Tooltip } from '@just-ai/just-ui';
import InputWrapper from './InputWrapper';
import { t } from '../../../../../localization';

export class SampleTitleInput extends InputWrapper {
  render() {
    return (
      <div className='catalog-page__sample-input '>
        <input
          id='catalogHeaderTooltip'
          name='sampleTitle'
          style={{ maxWidth: `${this.state.value && this.state.value.length + 3}ch ` }}
          onChange={this.handleChange}
          onFocus={this.selectText}
          value={this.state.value}
          maxLength={20}
          onBlur={() => this.throttledAction('save')}
          onClick={event => event.stopPropagation()}
          data-test-id='samplePage.sampleTitle'
        />
        <div className='catalog-page__header-buttons'>
          <IconButton flat name='falCheck' color='primary' onMouseDown={() => this.throttledAction('save')} />
          <IconButton flat name='falTimes' color='primary' onMouseDown={() => this.throttledAction('reset')} />
        </div>
        <Tooltip placement='bottom' target='catalogHeaderTooltip'>
          {t('rename')}
        </Tooltip>
      </div>
    );
  }
}
