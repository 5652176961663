import { AlertNotification } from '@just-ai/just-ui/dist/AlertNotification';
import { Locale } from '@just-ai/just-ui/dist/Common';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import React, { FunctionComponent } from 'react';

type Props = {
  alerts: AlertNotificationItemProps[];
  dismissAllAlerts: () => void;
  locale: Locale;
};

const GlobalAlerts: FunctionComponent<Props> = ({ alerts, dismissAllAlerts, locale }) => {
  return (
    <AlertNotification
      alerts={alerts}
      locale={locale}
      //@ts-ignore
      dismissAlert={() => {}}
      dismissAllAlerts={dismissAllAlerts}
      //@ts-ignore
      animationType='fadeIn'
      type='toasts'
    />
  );
};

export default GlobalAlerts;
