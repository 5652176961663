import React from 'react';
import { LibraryContext } from '../../context/LibraryContext';
import { MajorVersion } from '../../components/VoiceTraining';
import { withAppContext, AppContextType } from '../../../../AppContext';
import { withRouter, RouteComponentProps } from 'react-router';
import withAmplitude, { AmplitudeHOCProps } from '../../../../components/HOC/withAmplutide';
import { getErrorMessageFromReason } from '../../../../utils/error';
import { VoiceModel } from '../../model/Voice';
import { NewVersionView, VoiceView } from '../../../../api/facade/client';
import { DynamicTitle } from '../../components/DynamicTitle/DynamicTitle';
import { t } from '../../../../localization';
import { PermissionsEnum } from '../../constants';

import './styles.scss';

type TrainingProps = RouteComponentProps<{
  id: string;
}> &
  AmplitudeHOCProps &
  AppContextType & {
    voice: VoiceView;
    executeCaptch: () => Promise<any>;
  };

class State {
  isLoading: boolean = true;
  voiceModels: VoiceModel[] = [];
  newVersionData?: NewVersionView;
  releasedModelsCount: number = 0;
}

class Training extends React.Component<TrainingProps, State> {
  static contextType = LibraryContext;
  context!: React.ContextType<typeof LibraryContext>;
  state = new State();

  fetchData = async () => {
    const { match } = this.props;
    let releasedModels: VoiceModel[] = [];
    this.setState({ isLoading: true });
    try {
      await this.context.setCurrentVoiceId(Number(match.params.id));
      const modelsData = await this.context.getVoiceModels(Number(match.params.id), false);
      const newVersionData = await this.context.getActualVersionData();
      if (this.props.permissions.includes(PermissionsEnum.VoiceConnect)) {
        releasedModels = await this.context.getVoiceModels(Number(match.params.id), true);
      }
      this.setState({ voiceModels: modelsData, newVersionData, releasedModelsCount: releasedModels.length });
    } catch (error) {
      this.props.addAlert({
        type: 'error',
        message: getErrorMessageFromReason(error) || (error as any).message,
        time: Date.now(),
        showed: true,
        temporal: true,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    this.fetchData();

    this.props.logEvent('Page opened', {
      page_url: this.props.match.url,
      page_type: this.props.match.path,
      user_id: this.props.id,
    });
  }

  render() {
    return (
      <div className='voice-page__training training'>
        <DynamicTitle title={t('documentTitle.training', this.props.voice.name, this.props.productName)} />
        <MajorVersion
          versionData={this.state.newVersionData}
          models={this.state.voiceModels}
          updateData={this.fetchData}
          voice={this.props.voice}
          isLoading={this.state.isLoading}
          releasedModelsCount={this.state.releasedModelsCount}
        />
      </div>
    );
  }
}

export default withAmplitude({})(withRouter(withAppContext(Training)));
