import React, { useCallback, useState, useEffect } from 'react';
import { ModalBody } from 'reactstrap';
import CustomModal from './CustomModal';
import { t } from '../../../../localization';
import { convertNameToPlaceholder } from '../../../../utils';
import { ModifyVoiceMember, VoiceMemberView } from '../../../../api/facade/client';
import { RadioButton } from '@just-ai/just-ui';
import { ROLES } from '../../constants';

type RemoveManagerProps = {
  data: VoiceMemberView;
  handleModalAction: (value: ModifyVoiceMember & { oldRole: number }) => Promise<void>;
  cancelButtonClick: () => void;
};

export const CustomModalEditManager = ({ data, cancelButtonClick, handleModalAction }: RemoveManagerProps) => {
  const [targetRole, setTargetRole] = useState<number>(0);
  const [oldRole, setOldRole] = useState<number>(0);
  const { accountId, login, role, accountName } = data || {};
  useEffect(() => {
    setOldRole(role?.id);
  }, [role?.id]);

  const handleChangeRole = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setTargetRole(+e.target.value), []);

  const actionButtonClick = useCallback(async () => {
    try {
      await handleModalAction({
        accountId: accountId,
        login: login,
        toRole: targetRole,
        oldRole: oldRole,
      });
    } catch (error) {
      console.error('Error deleting voice members', error);
    }
  }, [handleModalAction, accountId, login, targetRole, oldRole]);
  if (!data) return null;
  return (
    <>
      <ModalBody className='custom-modal__body'>
        <div className='custom-modal__delete custom-modal__editing'>
          <p className='custom-modal__editing-user'>{accountName && convertNameToPlaceholder(accountName)}</p>
          <p>{accountName}</p>
        </div>
        <div className='custom-modal__radiogroup' onChange={handleChangeRole} data-test-id='editManager.radioGroup'>
          <RadioButton
            value={String(ROLES.ADMIN.id)}
            name='modal'
            label={t(`managerRole: ${ROLES.ADMIN.name}`)}
            checked={(targetRole || oldRole) === ROLES.ADMIN.id}
          />
          <p>{t('managerRole: AdministratorDesc')}</p>
          <RadioButton
            value={String(ROLES.MANAGER.id)}
            name='modal'
            label={t(`managerRole: ${ROLES.MANAGER.name}`)}
            checked={(targetRole || oldRole) === ROLES.MANAGER.id}
          />
          <p>{t('managerRole: ManagerDesc')}</p>
          <RadioButton
            value={String(ROLES.SPEAKER.id)}
            name='modal'
            label={t(`managerRole: ${ROLES.SPEAKER.name}`)}
            checked={(targetRole || oldRole) === ROLES.SPEAKER.id}
          />
          <p>{t('managerRole: SpeakerDesc')}</p>
        </div>
      </ModalBody>
      <CustomModal.Footer
        cancelButtonTitle={t('cancel')}
        actionButtonTitle={t('save')}
        actionButtonClick={actionButtonClick}
        cancelButtonClick={cancelButtonClick!}
      />
    </>
  );
};
