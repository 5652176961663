import React, { useCallback } from 'react';
import { Checkbox, Icon, SwitchToggle } from '@just-ai/just-ui';
import { UncontrolledTooltip } from 'reactstrap';

import { GenderEnum } from '../../../../api/facade/client';
import { t } from '../../../../localization';
import { AllVoicesPageFilters } from '../../pages/AllVoicesPage';
import { LanguageFilter } from './LanguageFilter';

type VoicesFilterProps = {
  filter: AllVoicesPageFilters;
  handleFilter: (field: keyof AllVoicesPageFilters, value?: any) => void;
};

export const DesktopFilter = ({ filter, handleFilter }: VoicesFilterProps) => {
  const superVoiceToggle = useCallback(
    event => {
      event.preventDefault();
      handleFilter('super');
    },
    [handleFilter]
  );

  return (
    <>
      {/* <div className='voices-catalog__side-filters__purpose'>
        <div
          onClick={() => handleFilter('purpose', 'all')}
          className={classNames({ selected: filter.purpose === 'all' }, 'purpose-filter')}
        >
          {t('allVoices')}
        </div>
        <div
          onClick={() => handleFilter('purpose', 'games')}
          className={classNames({ selected: filter.purpose === 'games' }, 'purpose-filter')}
        >
          {t('for games')}
        </div>
        <div
          onClick={() => handleFilter('purpose', 'sales')}
          className={classNames({ selected: filter.purpose === 'sales' }, 'purpose-filter')}
        >
          {t('for sales')}
        </div>
        <div
          onClick={() => handleFilter('purpose', 'calls')}
          className={classNames({ selected: filter.purpose === 'calls' }, 'purpose-filter')}
        >
          {t('for calls')}
        </div>
      </div> */}
      <div className='voices-catalog__side-filters__super'>
        <p className='st-2'>{t('supervoice')}</p>

        <Icon name='faInfoCircle' color='secondary' id='supervoiceInfo' />
        <UncontrolledTooltip placement='top' target='supervoiceInfo'>
          {t('supervoice tooltip')}
        </UncontrolledTooltip>
        <SwitchToggle
          size='md'
          switchType='round'
          id='supervoiceSwitch'
          color='primary'
          value={filter.super}
          onClick={superVoiceToggle}
        />
      </div>
      <div className='voices-catalog__side-filters__gender'>
        <p className='st-2'>{t('voiceGender')}</p>
        <Checkbox
          name='male'
          color='primary'
          value={filter.gender.includes(GenderEnum.MALE)}
          label={t('male')}
          onChange={() => handleFilter('gender', GenderEnum.MALE)}
          data-test-id='allVoicesPage.maleFilter'
        />
        <Checkbox
          name='female'
          color='primary'
          value={filter.gender.includes(GenderEnum.FEMALE)}
          label={t('female')}
          onChange={() => handleFilter('gender', GenderEnum.FEMALE)}
          data-test-id='allVoicesPage.femaleFilter'
        />
        {/* <Checkbox
        name='other'
        color='primary'
        value={filter.gender.includes('OTHER')}
        label={t('other')}
        onChange={() => handleFilter('gender', 'OTHER')}
      /> */}
      </div>
      <LanguageFilter filter={filter} handleFilter={handleFilter} />

      {/* <div className='voices-catalog__side-filters__price'>
      <p>
        <b>{t('price')}</b>
      </p>
      <div className='price-slider-container'>
        <PriceSlider domain={domain} max={filter.cost.max} min={filter.cost.min} handleSlider={handleSlider} />
      </div>
      <div className='price-inputs-block'>
        <div>
          <span>{t('from').toLowerCase()}</span>
          <InputText
            className='price-inputs-block'
            value={String(filter.cost.min)}
            onChange={value => handleFilter('cost', { ...filter.cost, min: value })}
            type='number'
          />
        </div>
        <div>
          <span>{t('to').toLowerCase()}</span>
          <InputText
            className='price-inputs-block'
            value={String(filter.cost.max)}
            onChange={value => handleFilter('cost', { ...filter.cost, min: value })}
            type='number'
          />
        </div>
      </div>
    </div> */}
    </>
  );
};
