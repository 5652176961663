import { SampleUpdate, SampleApi } from '../api/facade/client/api';
import { SampleCreate } from '../api/dataset-loader/client/api';
import { SamplesLoaderEndpointApi } from '../api/dataset-loader/client';
import { axiosWrapped } from '../pipes/functions';
import { convertSampleStatus } from '../utils';

export default class SampleService {
  static readonly BASE_PATH = '';
  private SampleApi: SampleApi;
  private DatasetLoaderApi: SamplesLoaderEndpointApi;
  constructor() {
    this.SampleApi = new SampleApi({}, SampleService.BASE_PATH, axiosWrapped);
    this.DatasetLoaderApi = new SamplesLoaderEndpointApi({}, SampleService.BASE_PATH, axiosWrapped);
  }
  createSample = async (
    catalogId: number,
    voiceId: number,
    sample: SampleCreate = { sampleText: '', sampleComment: '', sampleTitle: '' }
  ) => {
    try {
      const { data: datasetId } = await this.DatasetLoaderApi.createSampleUsingPOST(catalogId, voiceId, sample);
      return datasetId;
    } catch (error) {
      console.error('Error creating sample', error);
      return null;
    }
  };

  getSample = async (sampleId: number, voiceId: number) => {
    const { data: sampleData, headers } = await this.SampleApi.getSample(voiceId, sampleId);
    return {
      sampleData,
      metaData: {
        totalSamplesAmount: parseInt(headers['content-range'] || ''),
        currentSamplePosition: parseInt(headers['content-location'] || ''),
        nextSampleId: parseInt(headers['next-sample'] || ''),
        prevSampleId: parseInt(headers['previous-sample'] || ''),
      },
    };
  };

  getSamplesForVoicePaginated = async (
    voiceId: number,
    page: number = 0,
    pageSize: number = 25,
    catalogId: number,
    hasAudio?: boolean,
    hasError?: boolean
  ) => {
    const { data: samples, headers } = await this.SampleApi.getSamples(
      voiceId,
      catalogId,
      page,
      pageSize,
      hasAudio,
      hasError
    );
    const samplesWithStatuses = samples.map(sample => ({
      ...sample,
      status: convertSampleStatus({ sampleState: sample.sampleState }),
    }));
    return { samplesWithStatuses, headers };
  };

  getSampleText = async (sampleId: number, voiceId: number) => {
    const { data } = await this.DatasetLoaderApi.getSampleTextUsingGET(sampleId, voiceId);
    return data;
  };
  updateSampleText = async (sampleId: number, voiceId: number, text: string) => {
    try {
      await this.DatasetLoaderApi.updateSampleTextUsingPATCH(sampleId, voiceId, text);
    } catch (error) {
      console.error('Error updating sample text with id ' + sampleId, error);
      throw error;
    }
  };
  updateSample = async (sampleId: number, voiceId: number, sample: SampleUpdate) => {
    try {
      const { data: updatedSample } = await this.SampleApi.patchSample(voiceId, sampleId, sample);
      return updatedSample;
    } catch (error) {
      console.error('Error updating sample with id ' + sampleId, error);
      throw error;
    }
  };
  sampleFileUpload = async (catalogId: number, voiceId: number, file: File) => {
    await this.DatasetLoaderApi.uploadArchivedSamplesUsingPOST(catalogId, voiceId, file);
    return true;
  };
  sampleRecordUpload = async (voiceId: number, sampleId: number, file: File) => {
    await this.DatasetLoaderApi.updateSampleAudioUsingPOST(sampleId, voiceId, file);
  };
  deleteSample = async (sampleId: number, voiceId: number) => {
    try {
      await this.SampleApi.removeSample(voiceId, sampleId);
      return true;
    } catch (error) {
      console.error('Error archiving sample with id ' + sampleId, error);
      throw error;
    }
  };
  deleteSampleRecord = async (sampleId: number, voiceId: number) => {
    await this.DatasetLoaderApi.deleteSampleAudioUsingDELETE(sampleId, voiceId);
    return true;
  };
}
