import React from 'react';
import { DropdownButton, DropdownToggle, Icon } from '@just-ai/just-ui';
import './Loader.scss';

export const CatalogPageHeaderLoader = () => {
  return (
    <div className='loader'>
      <div className='catalog-page__header-wrapper'>
        <div className='catalog-page__header'>
          <div className='catalog-page__header-input animated-pulse'></div>
          <DropdownButton direction='down'>
            <DropdownToggle>
              <Icon name='falEllipsisH' />
            </DropdownToggle>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};
