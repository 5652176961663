import React, { useState, useCallback } from 'react';
import { InputText, RadioButton } from '@just-ai/just-ui';
import { ModalBody } from 'reactstrap';
import { ROLES, emailRegex } from '../../../constants';
import CustomModal from '../CustomModal';
import { t } from '../../../../../localization';
import { VoiceMemberToAdd } from '../../../../../api/facade/client';
import './styles.scss';

type AddManagerProps = {
  handleModalAction: (value: VoiceMemberToAdd) => Promise<void>;
  cancelButtonClick: () => void;
};

export const CustomModalAddManager = ({ handleModalAction, cancelButtonClick }: AddManagerProps) => {
  const [email, setEmail] = useState<string>();
  const [roleId, setRoleId] = useState<number>(0);

  const handleChangeRole = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setRoleId(+e.target.value), []);

  const handleChangeEmail = useCallback(value => {
    setEmail(value);
  }, []);

  const actionButtonClick = useCallback(async () => {
    try {
      await handleModalAction({ login: email, roleId: roleId });
    } catch (error) {
      console.error(`Error add voice member: ${error}`);
    }
  }, [email, handleModalAction, roleId]);
  return (
    <>
      <ModalBody className='custom-modal__body'>
        <InputText placeholder={t('enterEmail')} onChange={handleChangeEmail} data-test-id='addManager.email' />
        <div className='custom-modal__radiogroup' onChange={handleChangeRole} data-test-id='addManager.radioGroup'>
          <RadioButton
            value={String(ROLES.ADMIN.id)}
            name='modal'
            label={t(`managerRole: ${ROLES.ADMIN.name}`)}
            checked={roleId === ROLES.ADMIN.id}
          />
          <p>{t('managerRole: AdministratorDesc')}</p>
          <RadioButton
            value={String(ROLES.MANAGER.id)}
            name='modal'
            label={t(`managerRole: ${ROLES.MANAGER.name}`)}
            checked={roleId === ROLES.MANAGER.id}
          />
          <p>{t('managerRole: ManagerDesc')}</p>
          <RadioButton
            value={String(ROLES.SPEAKER.id)}
            name='modal'
            label={t(`managerRole: ${ROLES.SPEAKER.name}`)}
            checked={roleId === ROLES.SPEAKER.id}
          />
          <p>{t('managerRole: SpeakerDesc')}</p>
        </div>
      </ModalBody>
      <CustomModal.Footer
        cancelButtonTitle={t('cancel')}
        actionButtonTitle={t('invite')}
        disabled={!(roleId && email && emailRegex.test(email))}
        actionButtonClick={actionButtonClick}
        cancelButtonClick={cancelButtonClick!}
      />
    </>
  );
};
