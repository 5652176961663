import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';

import { Icon } from '@just-ai/just-ui/dist/Icon';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from '@just-ai/just-ui/dist/Dropdown';
import { useAmplitude } from 'react-amplitude-hooks';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { Button, IconButton } from '@just-ai/just-ui/dist/Button';
import useAlert from '../../utils/useAlert';
import { t } from '../../localization';
import { useToggle } from '../../utils/hooks';
import { SynthesizableVoice } from '../../api/facade/client';
import { SynthBuffer } from '../../modules/Projects/model/VoiceLines';
import SpeakerSelectList from './SpeakerSelectList';
import { SCREEN_WIDTH_PHONE, SCREEN_WIDTH_TABLET } from '../../modules/Header/constants';
import { useAccountContext } from '../../modules/Header/context/AccountContext';
import { Markup } from '../../api/dubber/client';
import { MIN_SYNTH_TEXT_LENGTH } from '../../modules/Library/constants';
import { PlayButton } from './PlayButton';
import { useReplicas } from '../../modules/Projects/context/ProjectDataContex';
import { FULL_PROJECT_ID, usePlayer } from '../../modules/Projects/context/PlayerContext';
import { TiptapMethods } from '../../modules/Projects/components/Tiptap/Tiptap';
import { PLACEHOLDER_ID, NEW_LINE_ID } from '../../modules/Projects/components/VoiceLinesBlock/VoiceLinesBlock';
import { useSettings } from '../../modules/Projects/context/ReplicaSettingsContext';
import { useProjectContext } from '../../modules/Projects/context/ProjectsContext';

import './style.scss';

const TiptapEditor = React.lazy(() =>
  import('../../modules/Projects/components/Tiptap/Tiptap').then(module => ({ default: module.TiptapEditor }))
);

export type SpeakerBlockProps = SpeakerProps & {
  handleAvatarClick: (lineId: string, voiceId?: number, modelId?: number) => void;
  handlePlayerClick: ({ lineId, phraseUrl, voiceId }: { lineId: string; phraseUrl?: string; voiceId?: number }) => void;
  playingId?: string;
  handleInputBlur?: (lineId: string) => void;
  removeLineBlock?: (id: string) => void;
  error?: string;
  className?: string;
  speakersList?: SynthesizableVoice[];
  handleSpeakerMenuScroll?: (event: React.UIEvent<HTMLDivElement>) => Promise<void>;
  projectId?: string;
  index?: number;
  voicesLoading?: boolean;
  synthBuffer?: Omit<SynthBuffer, 'id'>;
  lastLine?: boolean;
  editedLine?: string;
  setEditingProp?: (lineId: string) => void;
  handleTextSplit?: ({
    text,
    voiceId,
    modelId,
    lineId,
  }: {
    text?: string;
    voiceId?: number;
    modelId?: number;
    lineId: string;
  }) => void;
  isPlaying?: boolean;
  handleLineMarkupUpdate?: (lineid: string, markupData: Partial<Markup>) => void;
  handleSplitOnPaste?: (arrToSplit: string[], lineId: string) => void;
  focusOn?: (id: string) => void;
};

export type SpeakerProps = {
  voiceId?: number;
  text: string;
  modelId?: number;
  lineId: string;
};

function SpeakerBlock(props: SpeakerBlockProps) {
  const {
    voiceId,
    text,
    handleAvatarClick,
    playingId,
    handlePlayerClick,
    handleInputBlur,
    removeLineBlock,
    error,
    lineId,
    className,
    speakersList = [],
    index,
    voicesLoading,
    handleSpeakerMenuScroll,
    synthBuffer,
    setEditingProp,
    editedLine,
    handleSplitOnPaste,
    isPlaying,
    focusOn,
  } = props;
  const isTablet = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_TABLET}px)` });
  const isPhone = useMediaQuery({ query: `(max-width: ${SCREEN_WIDTH_PHONE}px)` });
  const [isOpen, , , toggle] = useToggle();
  const { defaultErrorAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [playerDisabled, setPlayerDisabled] = useState(true);
  const { logEvent } = useAmplitude();
  const { synthesizeReplica, downloadReplicaAudio, createVoiceLine, bulkUpdate } = useProjectContext();
  const { getSsmlText, generateMarkupUpdate } = useSettings();

  const { updateWallet } = useAccountContext();
  const { projectId } = useParams<{ projectId: string }>();

  const {
    setReplicaStore,
    replicas,
    getReplicaById,
    checkTextLength,
    placeholder,
    selectedReplicaId,
    updateReplica,
  } = useReplicas();
  const {
    playerRef,
    setPlayingId,
    setReplicaSynthBuffer,
    deleteSynthBufferRecord,
    getSynthBufferById,
    projectLoading,
    setPlayerStore,
    projectDownloading,
    historyPlaying,
  } = usePlayer();
  const edtiorRef = useRef<TiptapMethods>(null);

  const editing = useMemo(() => editedLine === lineId, [editedLine, lineId]);

  useEffect(() => {
    if (!text || !voiceId || voiceId === -1 || error) {
      return setPlayerDisabled(true);
    }
    setPlayerDisabled(false);
  }, [voiceId, text, editedLine, lineId, error]);

  const handleText = useCallback(
    (value: string) => {
      if (lineId.includes(PLACEHOLDER_ID)) {
        const error = checkTextLength(value);
        setReplicaStore({ placeholder: { ...placeholder, text: value, error }, isDirty: true });
        return;
      }

      const { replica, replicaIndex } = getReplicaById(lineId);
      if (!replica || replicaIndex === -1) return;

      const newReplicasArr = [...replicas];
      const error = checkTextLength(value);
      newReplicasArr[replicaIndex] = { ...replica, text: value, error };
      if (lineId === playingId && playerRef?.current && !playerRef?.current) {
        playerRef?.current.pause();
        setPlayingId('');
      }

      setReplicaStore({ replicas: newReplicasArr, isDirty: true });
      deleteSynthBufferRecord(lineId);
    },
    [
      checkTextLength,
      deleteSynthBufferRecord,
      getReplicaById,
      lineId,
      placeholder,
      playerRef,
      playingId,
      replicas,
      setPlayingId,
      setReplicaStore,
    ]
  );

  const localSynthesize = useCallback(async () => {
    setLoading(true);
    if (playerRef?.current) {
      setPlayerStore({ projectLoading: lineId });
      playerRef.current.removeAttribute('src');
      playerRef.current.currentTime = 0;
    }
    if (!navigator.onLine) {
      throw new Error('Offline mode');
    }
    const phraseUrl = (await synthesizeReplica(lineId)) || '';

    setReplicaSynthBuffer({ id: lineId, url: phraseUrl });
    setPlayerStore({ projectLoading: '' });
    updateReplica(lineId);
    return phraseUrl;
  }, [playerRef, setPlayerStore, lineId, synthesizeReplica, setReplicaSynthBuffer, updateReplica]);

  const isResynthNeeded = useCallback(() => {
    const synthBuffer = getSynthBufferById(lineId);
    return !Boolean(synthBuffer);
  }, [getSynthBufferById, lineId]);

  const handlePlayerClickLocal = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (historyPlaying) {
        setPlayerStore({ historyPlaying: false });
      }
      if (!text) return;

      if (voiceId === -1 || !lineId) return;

      if (text.length < MIN_SYNTH_TEXT_LENGTH) return defaultErrorAlert(t('textForTestLength', 4));

      if (!isResynthNeeded()) {
        const bufferUrl = getSynthBufferById(lineId).synthUrl;
        return handlePlayerClick({ lineId, voiceId, phraseUrl: bufferUrl });
      }

      if (isPlaying) {
        return handlePlayerClick({ lineId });
      }
      try {
        const phraseUrl = await localSynthesize();
        handlePlayerClick({ lineId, phraseUrl, voiceId });
      } catch (error) {
        setPlayerStore({ playingId: '', projectDownloading: false, projectLoading: '' });
        logEvent('project_replica_synthesized', {
          project_id: props.projectId,
          replica_id: lineId,
          result: 'failed',
          voice_id: voiceId,
        });

        defaultErrorAlert(error);
      } finally {
        updateWallet();
        setLoading(false);
      }
    },
    [
      historyPlaying,
      text,
      voiceId,
      lineId,
      defaultErrorAlert,
      isResynthNeeded,
      isPlaying,
      getSynthBufferById,
      handlePlayerClick,
      localSynthesize,
      logEvent,
      setPlayerStore,
      props.projectId,
      updateWallet,
    ]
  );

  const defaultBlurFlow = useCallback(() => {
    handleInputBlur && handleInputBlur(lineId);
  }, [handleInputBlur, lineId]);

  const dialogInputOnBlur = useCallback(
    (event: React.FocusEvent) => {
      const blurIgnoreCondition =
        (isPhone && event.relatedTarget && (event.relatedTarget as HTMLDivElement).classList.contains('fade')) ||
        (event.relatedTarget && (event.relatedTarget as HTMLButtonElement).id === `avatarPlaceholder-${lineId}`) ||
        (event.relatedTarget &&
          (event.relatedTarget as HTMLButtonElement | HTMLDivElement).classList.contains('phone-button'));

      if (blurIgnoreCondition) {
        return;
      }
      isTablet && setEditingProp && setEditingProp('');

      defaultBlurFlow();
    },
    [isPhone, lineId, isTablet, setEditingProp, defaultBlurFlow]
  );
  const avatarClickCallback = useCallback(
    (voiceId?: number, modelId?: number) => {
      if (!lineId) return;
      if (playingId === lineId) handlePlayerClick({ lineId });
      handleAvatarClick(lineId, voiceId, modelId);
      if (index === undefined) return;
      setReplicaStore({ selectedReplicaId: lineId });
    },
    [handleAvatarClick, handlePlayerClick, index, lineId, playingId, setReplicaStore]
  );

  const downloadVoiceLine = useCallback(async () => {
    setLoading(true);
    setPlayerStore({ projectDownloading: true });
    try {
      const audioUrl = await downloadReplicaAudio(
        lineId,
        String(isNaN(index!) ? 'placeholder.wav' : `${index! + 1}.wav`)
      );
      const a = document.createElement('a');
      a.href = audioUrl;
      a.click();
      let audioExist = Boolean(synthBuffer?.synthUrl);
      logEvent('project_replica_downloaded', {
        project_id: props.projectId,
        replica_id: lineId,
        result: 'success',
        audio_exist_in_app: audioExist,
      });
    } catch (error) {
      logEvent('project_replica_downloaded', {
        project_id: props.projectId,
        replica_id: lineId,
        result: 'failed',
        audio_exist_in_app: false,
      });

      defaultErrorAlert(error);
    } finally {
      setLoading(false);
      setPlayerStore({ projectDownloading: false });
    }
  }, [
    setPlayerStore,
    downloadReplicaAudio,
    lineId,
    index,
    synthBuffer?.synthUrl,
    logEvent,
    props.projectId,
    defaultErrorAlert,
  ]);

  const deleteLine = useCallback(() => {
    removeLineBlock && removeLineBlock(lineId);
  }, [lineId, removeLineBlock]);

  const handleSplitOnEnter = useCallback(
    async ({ text, anchorPosition }: { text: string; anchorPosition: number }) => {
      const isSplit = text.substring(anchorPosition).length > 0;
      const newReplicasArr = [...replicas];
      const { replica, replicaIndex } = getReplicaById(lineId);

      if (replicaIndex === newReplicasArr.length - 1 && !isSplit && focusOn) {
        focusOn(PLACEHOLDER_ID);
        return;
      }

      let newItem;
      let createdReplica;
      if (lineId.includes(PLACEHOLDER_ID) && !isSplit && checkTextLength(text)) return;
      if (lineId.includes(PLACEHOLDER_ID)) {
        const stringToCreate = text.substring(0, anchorPosition);
        const textToKeep = text.substring(anchorPosition);

        if (stringToCreate.length > 0) {
          newItem = {
            ...placeholder,
            text: stringToCreate,
            error: checkTextLength(stringToCreate),
            index: newReplicasArr.length,
            id: `${NEW_LINE_ID}-${newReplicasArr.length}`,
            projectId,
          };
        }
        if (!newItem.error) {
          createdReplica = await createVoiceLine(newItem);
        }

        newReplicasArr.push(createdReplica || newItem);
        const isStillDirty = newReplicasArr.find(replica => replica.error);
        setReplicaStore({
          placeholder: {
            ...placeholder,
            text: textToKeep || '',
            error: checkTextLength(text.substring(anchorPosition)),
          },
          replicas: newReplicasArr,
          isDirty: textToKeep.length > 0 || Boolean(isStillDirty),
        });
        focusOn && focusOn(PLACEHOLDER_ID);
        return;
      } else {
        newItem = {
          voiceId: replica.voiceId,
          text: text.substring(anchorPosition) || '',
          id: `${NEW_LINE_ID}-${replicaIndex + 1}`,
          modelId: replica.modelId,
          markup: isSplit ? newReplicasArr[replicaIndex].markup : {},
          projectId: projectId,
          error: checkTextLength(text.substring(anchorPosition)),
          index: replicaIndex + 1,
          ssmlText: voiceId
            ? getSsmlText(text.substring(anchorPosition) || '') || text.substring(anchorPosition) || ''
            : text.substring(anchorPosition) || '',
        };
        const markupUpdate = generateMarkupUpdate();
        const { markup, ...objToCreate } = newItem;
        let createdReplica;
        if (!newItem.error) {
          createdReplica = await createVoiceLine({ ...objToCreate, markupUpdate });
        }

        newReplicasArr.splice(replicaIndex + 1, 0, createdReplica || newItem);
        newReplicasArr[replicaIndex].text = text.substring(0, anchorPosition);
        newReplicasArr[replicaIndex].ssmlText = voiceId
          ? getSsmlText(text.substring(0, anchorPosition) || '') || text.substring(0, anchorPosition) || ''
          : text.substring(0, anchorPosition) || '';
        newReplicasArr[replicaIndex].error = checkTextLength(text.substring(0, anchorPosition));
        newReplicasArr.forEach((replica, index) => (replica.index = index));
        const updatedPart =
          replicaIndex + 1 !== newReplicasArr.length - 1 ? newReplicasArr.slice(replicaIndex + 1) : [];
        updatedPart.length > 0 && bulkUpdate(projectId, { updated: updatedPart });
        setReplicaStore({
          replicas: newReplicasArr,
          isDirty: true,
          selectedReplicaId: createdReplica?.id || newItem.id,
        });
        focusOn && focusOn(createdReplica?.id || newItem.id);
      }
    },
    [
      bulkUpdate,
      checkTextLength,
      createVoiceLine,
      focusOn,
      generateMarkupUpdate,
      getReplicaById,
      getSsmlText,
      lineId,
      placeholder,
      projectId,
      replicas,
      setReplicaStore,
      voiceId,
    ]
  );

  const confirmChangesMobile = useCallback(() => {
    handleInputBlur && handleInputBlur(lineId);
  }, [handleInputBlur, lineId]);

  const handleFocus = useCallback(() => {
    setEditingProp && setEditingProp(lineId);
    if (selectedReplicaId === lineId) return;
    setReplicaStore({ selectedReplicaId: lineId });
  }, [setEditingProp, lineId, selectedReplicaId, setReplicaStore]);
  return (
    <div
      className={classNames('dialog-synth__speaker-block', 'project__voice-line', className)}
      data-test-id={`voiceLine.wrapper-${lineId}`}
    >
      {(!lineId.includes('placeholder') || voiceId) && (
        <SpeakerSelectList
          avatarClickCallback={avatarClickCallback}
          dialogInputOnBlur={dialogInputOnBlur}
          lineId={lineId}
          voiceId={voiceId}
          handleSpeakerMenuScroll={handleSpeakerMenuScroll}
          voicesLoading={voicesLoading}
          speakersList={speakersList}
        />
      )}
      <div
        className={classNames('voice-line__state-wrapper', {
          'non-selectable': !editing,
        })}
      >
        <React.Suspense fallback={null}>
          <TiptapEditor
            onBlur={dialogInputOnBlur}
            placeholderText={t('enterPhrase') + '...'}
            handleText={handleText}
            lineId={lineId}
            name='voiceLine'
            handleEnterButton={handleSplitOnEnter}
            defaultBlurFlow={defaultBlurFlow}
            handleSplitOnPaste={handleSplitOnPaste}
            ref={edtiorRef}
            handleFocus={handleFocus}
          />
        </React.Suspense>
        {isTablet && editing && (
          <div className='voice-line__edit-menu'>
            <Button onClick={downloadVoiceLine} disabled={playerDisabled} flat className='phone-button'>
              {t('download')}
            </Button>
            <Button onClick={deleteLine} disabled={lineId.includes('placeholder')} flat className='phone-button'>
              {t('delete')}
            </Button>
            <div className='confirm-wrapper phone-button'>
              <IconButton
                flat
                name='farCheck'
                size='sm'
                color='primary'
                onClick={confirmChangesMobile}
                id='confirmButton'
              />
            </div>
          </div>
        )}
      </div>
      {!!text && !lineId.includes('placeholder') && (
        <PlayButton
          onClick={handlePlayerClickLocal}
          isPlaying={playingId === lineId}
          isLoading={projectLoading === lineId || loading}
          disabled={playerDisabled || projectDownloading || projectLoading === FULL_PROJECT_ID}
          textOverflow={Boolean(checkTextLength(text))}
          wasSynthed={Boolean(getSynthBufferById(lineId)?.synthUrl)}
          id={lineId}
        />
      )}
      {removeLineBlock && lineId && !isTablet && (
        <Dropdown
          className='speaker-block__menu-dropdown'
          isOpen={isOpen}
          toggle={toggle}
          data-test-id={`voiceLine.menuDropdown-${lineId}`}
        >
          <DropdownToggle className='speaker-block__menu-button' size='sm' color='none'>
            <Icon name='falEllipsisH' />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={downloadVoiceLine}
              data-test-id='voiceLineMenu.download'
              disabled={playerDisabled || projectDownloading}
            >
              <p className='tp-4'>{t('download')}</p>
            </DropdownItem>
            <DropdownItem onClick={deleteLine} data-test-id='voiceLineMenu.delete'>
              <p className='tp-4'>{t('delete')}</p>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  );
}

export default React.memo(SpeakerBlock);
