import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FileUpload } from '@just-ai/just-ui/dist/FileUpload';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { Modal } from '@just-ai/just-ui/dist/Modal';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { Button } from '@just-ai/just-ui/dist/Button';
import { useAmplitude } from 'react-amplitude-hooks';
import { AxiosError } from 'axios';

import { t } from '../../../../localization';
import { getErrorMessageFromReason } from '../../../../utils/error';
import PaperImg from '../../../../img/paper.png';
import NoteImg from '../../../../img/note.png';
import BoxImg from '../../../../img/box.png';
import { useAppContext } from '../../../../AppContext';

import './style.scss';

export default function UploadDatasetModal(props: {
  isOpen: boolean;
  toggleOpen: () => void;
  processUploadedFile: (file: File) => Promise<void>;
  catalogId?: number;
}) {
  const { isOpen, toggleOpen, processUploadedFile, catalogId } = props;
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>();
  const [error, setError] = useState('');
  const loadingTimer = useRef<any>();
  const { id, isUSDInstance } = useAppContext();
  const { logEvent } = useAmplitude();

  const handleFileUpload = (files: FileList) => {
    if (!files) return;
    const filesArr = Array.from(files);
    if (!filesArr || filesArr.length < 1) return;
    try {
      setLoading(true);
      loadingTimer.current = setTimeout(() => {
        if (!filesArr[0].type.includes('zip')) {
          setError(t('zip.extension.wrong'));
          return setLoading(false);
        }
        setUploadedFile(filesArr[0]);
        setLoading(false);
        setError('');
      }, 500);
    } catch (error) {
      setLoading(false);
      setError((error as AxiosError).message);
    }
  };

  useEffect(() => clearTimeout(loadingTimer.current), []);

  const uploadFile = useCallback(() => {
    setLoading(true);
    uploadedFile &&
      processUploadedFile(uploadedFile)
        .then(() => {
          toggleOpen();
          setUploadedFile(null);
          setError('');
          logEvent('Archive uploaded', {
            userId: id,
            caalogId: catalogId,
            result: 'success',
          });
        })
        .catch(error => {
          setError(getErrorMessageFromReason(error));
          logEvent('Archive uploaded', {
            userId: id,
            caalogId: catalogId,
            result: 'failed',
          });
        })
        .finally(() => {
          setLoading(false);
        });
  }, [catalogId, id, logEvent, processUploadedFile, toggleOpen, uploadedFile]);
  return (
    <Modal
      isOpen={isOpen}
      disableActionButtonAutoFocus
      buttonCancelColor='secondary'
      onCancelClick={() => {
        toggleOpen();
        setUploadedFile(null);
        setError('');
      }}
      onActionClick={() => {
        uploadFile();
      }}
      headerTag='div'
      buttonSubmitDisabled={!uploadedFile}
      title={<h3 className='learning-modal__heading'>{t('uploadMaterial')}</h3>}
      buttonCancelText={t('cancel')}
      buttonSubmitText={t('upload')}
      buttonCancelSize='lg'
      buttonSubmitSize='lg'
      className='learning-data-modal custom-modal custom-modal__container'
      data-bodyTestId='samplePage.uploadArchiveBody'
      buttonSubmitTestId='samplePage.uploadArchiveSubmit'
    >
      <>
        <div className='learning-modal__content'>
          <ul className='tp-3'>
            <li>{t('uploadArchiveListItem1')}</li>
            <li className='my-3'>{t('uploadArchiveListItem2')}</li>
            <li>{t('uploadArchiveListItem3')}</li>
          </ul>
          <div className='upload-flow-demo'>
            <div className='demo-block mr-4'>
              <div className='bg-block' />
              <div className='bg-block bg-block--2' />
              <div className='demo-block'>
                <img src={PaperImg} alt='text' />
                <p className='tp-5'>{t('title')}.txt</p>
              </div>
            </div>
            <div className='demo-block'>
              <div className='bg-block' />
              <div className='bg-block bg-block--2' />
              <div className='demo-block'>
                <img src={NoteImg} alt='sound' />
                <p className='tp-5'>{t('title')}.wav</p>
              </div>
            </div>
            <Icon name='farArrowRight' />
            <div className='demo-block'>
              <img src={BoxImg} alt='box' />
              <p className='tp-5'>{t('materialSet')}.zip</p>
            </div>
          </div>
          <a
            className='tp-3'
            href={`${isUSDInstance ? t('HelpCenterUSD') : t('HelpCenter')}${t('uploadDatasetHref')}`}
            target='_blank'
            rel='noreferrer'
          >
            {t('aboutAllowedFormats')} <Icon name='farArrowUp' size='sm' />
          </a>
          <FileUpload
            onBlur={() => setLoading(false)}
            className={error ? 'error' : ''}
            accept='.zip'
            onChange={handleFileUpload}
          >
            {loading ? (
              <Spinner size='4x' color='primary' />
            ) : uploadedFile ? (
              <div className='uploaded-file-block'>
                <p>{uploadedFile.name}</p>
                <Icon
                  name='falTimes'
                  color='secondary'
                  onClick={event => {
                    event.stopPropagation();
                    setUploadedFile(null);
                  }}
                />
              </div>
            ) : (
              <>
                <Icon name='faFileArchive' color='secondary' size='lg' />
                <p>{t('orDragHere')}</p>
                <Button onClick={event => event.preventDefault()} color='secondary' size='sm'>
                  {t('uploadFromPC')}
                </Button>
              </>
            )}
          </FileUpload>
          <p className='error-text mt-2'>{error}</p>
        </div>
      </>
    </Modal>
  );
}
